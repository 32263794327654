import { makeStyles } from "@toolkit/ui";

export const useMarketplaceOrderCardStyles = makeStyles()(theme => ({
  root: {
    marginTop: "10px",
    paddingInline: 10,
    marginInline: 10,
    paddingTop: 15,
  },
  content: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    gap: 10,
    padding: 5,
  },
  footer: {
    display: "flex",
    borderTop: "1px solid",
    borderColor: theme.palette.divider,
    paddingBlock: 15,
    marginTop: 10,
    gap: 0,
  },
  reorderButton: {
    height: "45px",
    borderRadius: "22.5px",
    "& svg": {
      fill: theme.palette.common.white,
      viewBox: "0 0 24 24",
    },
    "& .MuiButton-startIcon": {
      marginInlineEnd: 2.5,
    },
  },
  cancelButton: {
    height: "45px",
    borderRadius: "22.5px",
  },
  vendorAvatarContainer: {
    "& svg": {
      width: 50,
      height: 50,
    },
  },
  contentInfoContainer: {
    flex: 1,
    textAlign: "start",
  },
  mainInfoText: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  subInfoText: {
    color: theme.palette.stale.main,
  },
  expandButton: {
    height: "100%",
    justifySelf: "end",
    alignSelf: "center",
    position: "relative",
    top: -10,
    left: 5,
    "& svg": {
      fill: theme.palette.primary.main,
      width: 8,
      height: 15,
    },
  },
  reasonsTextField: {
    width: "100%",
    marginTop: "20px",
    "& .MuiInputBase-root": {
      boxShadow: theme.mixins.shadows.md,
    },
  },
}));
