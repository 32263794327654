import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "hidden",
  },
  title: {
    marginBlock: "10px 5px",
    marginInline: "10px",
    fontSize: theme.mixins.fonts.fontSize.xxl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "start",
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.mixins.shadows.md,
    borderRadius: 40,
    padding: 15,
    borderWidth: 0,
  },
  content: {
    color: theme.palette.primary.main,
    borderRadius: 15,
    background: theme.palette.common.white,
    padding: 16,
    margin: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  text: {
    textAlign: "start",
  },
  footer: {
    position: "sticky",
    bottom: "0px",
    left: "0px",
    height: "74px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  button: {
    height: "48px",
    borderRadius: "24px",
    paddingInline: "40px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  syncButton: {
    textDecoration: "underline",
    padding: 0,
    ":disabled": {
      color: theme.palette.primary.main,
    },
  },
  syncButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 20px)",
    justifyContent: "center",
    position: "relative",
    "& .MuiAvatar-root": {
      width: "100px",
      height: "100px",
    },
  },
  actionButton: {
    borderRadius: "24px",
    marginLeft: 0,
    marginRight: 5,
  },
  name: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
