import { makeStyles } from "@toolkit/ui";

export const useHealthPackageGeneralInfoStyle = makeStyles()(theme => ({
  generalInfo: {
    display: "flex",
    justifyContent: "space-between",
    paddingBlock: "10px",
    alignItems: "center",
  },
  healthPackageTitle: {},
  provider: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  healthPackageShortDescription: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.normal,
    color: theme.palette.text.secondary,
    marginBottom: "10px",
    whiteSpace: "pre-wrap",
    textAlign: "start",
  },
}));
