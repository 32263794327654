import { Chat } from "../../schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FC, RefObject } from "react";
import { useChatListItemMenuHook } from "./ChatListItemMenu.hooks";
import { Typography, Menu, MenuItem, useTheme, CustomDialog, Box, Button, ErrorIcon, DialogContent, DialogContentText } from "@toolkit/ui";
import { useContextMenu } from "@toolkit/core";

type ChatListItemMenuProps = {
  chat: Chat;
  isOutgoing: boolean;
  anchorRef: RefObject<HTMLElement>;
};

export const ChatListItemMenu: FC<ChatListItemMenuProps> = ({ anchorRef, isOutgoing, chat }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { contextMenu, handleClose } = useContextMenu(anchorRef);
  const {
    enabled,
    handleEdit,
    handleCopy,
    handleDeleteForYourself,
    handleDeleteForEveryone,
    handleReply,
    handleToggleConfirmDelete,
    openDeleteConfirmation,
  } = useChatListItemMenuHook({
    isOutgoing,
    chat,
    onClose: handleClose,
  });

  if (!enabled) {
    return null;
  }

  return (
    <>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem onClick={handleReply}>
          <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t("Reply")}</Typography>
        </MenuItem>
        {isOutgoing && (
          <MenuItem onClick={handleEdit}>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t("Edit")}</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleCopy}>
          <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t("Copy Text")}</Typography>
        </MenuItem>
        {isOutgoing && (
          <MenuItem onClick={handleToggleConfirmDelete}>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t("Delete Message")}</Typography>
          </MenuItem>
        )}
      </Menu>

      {openDeleteConfirmation && (
        <CustomDialog
          type='base'
          open={openDeleteConfirmation}
          maxWidth={"xs"}
          DialogTitleProps={{
            hasCloseButton: false,
            title: (
              <Typography
                color={theme.palette.error.main}
                fontSize={theme.mixins.fonts.fontSize.md}
                fontWeight={theme.mixins.fonts.fontWeight.medium}
              >
                {t("Delete Message")}
              </Typography>
            ),
          }}
          DialogActionsProps={{
            children: (
              <>
                <Box flex={1} />
                <Button variant='text' onClick={handleToggleConfirmDelete}>
                  {t("Cancel")}
                </Button>

                {isOutgoing && (
                  <Button color={"error"} variant='outlined' onClick={handleDeleteForEveryone}>
                    {t("Delete for everyone")}
                  </Button>
                )}

                <Button color={"error"} variant='outlined' onClick={handleDeleteForYourself}>
                  {t("Delete for me")}
                </Button>
              </>
            ),
          }}
        >
          <DialogContent>
            <DialogContentText>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center" }}>
                <ErrorIcon fill={theme.palette.error.main} sx={{ width: 60, height: 60 }} />
                <Typography color={theme.palette.error.main} fontSize={theme.mixins.fonts.fontSize.lg} marginTop={3} textAlign='center'>
                  {t("Are you sure you want to delete this message?")}
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </CustomDialog>
      )}
    </>
  );
};
