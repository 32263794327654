import { AppointmentsIcon, Box, format } from "@toolkit/ui";
import { FC } from "react";
import { MarketplaceOrderDetails } from "../../others/types";
import { useMarketplaceOrderGeneralInfoCardStyle } from "./useMarketplaceOrderGeneralInfoCards.styles";
import { SingleInfoWithIconDetailCard } from "@/shared/components";
import { useTranslation } from "@/i18n/i18n.config";
import { CartIcon, OkIcon, StoreIcon } from "@/shared/icons";
import { pickLocalizedValue } from "@toolkit/i18n";
import { marketplaceOrderStatusOptionsMap } from "@health/enum-options";
export type MarketplaceOrderGeneralInfoCardsProps = {
  order: MarketplaceOrderDetails;
};
export const MarketplaceOrderGeneralInfoCards: FC<MarketplaceOrderGeneralInfoCardsProps> = props => {
  const { order } = props;
  const { t } = useTranslation("consumer");
  const { classes } = useMarketplaceOrderGeneralInfoCardStyle();
  return (
    <Box>
      <SingleInfoWithIconDetailCard
        className={classes.infoCard}
        title={t("Order ID")}
        content={"#" + order?.id}
        classes={{
          iconContentContainer: classes.iconContentContainer,
          title: classes.title,
        }}
        startIcon={
          <Box className={classes.iconContainer}>
            <CartIcon />
          </Box>
        }
      />
      <SingleInfoWithIconDetailCard
        className={classes.infoCard}
        title={t("Order Date")}
        content={format(order?.createdDate)}
        classes={{
          iconContentContainer: classes.iconContentContainer,
          title: classes.title,
        }}
        startIcon={
          <Box className={classes.iconContainer}>
            <AppointmentsIcon />
          </Box>
        }
      />
      <SingleInfoWithIconDetailCard
        className={classes.infoCard}
        title={t("Provider")}
        content={pickLocalizedValue(order?.vendor?.name, order?.vendor?.nameAr)}
        classes={{
          iconContentContainer: classes.iconContentContainer,
          title: classes.title,
        }}
        startIcon={
          <Box className={classes.iconContainer}>
            <StoreIcon />
          </Box>
        }
      />
      <SingleInfoWithIconDetailCard
        className={classes.infoCard}
        title={t("Status")}
        content={marketplaceOrderStatusOptionsMap[order?.status]?.label}
        classes={{
          iconContentContainer: classes.iconContentContainer,
          title: classes.title,
        }}
        startIcon={
          <Box className={classes.iconContainer}>
            <OkIcon />
          </Box>
        }
      />
    </Box>
  );
};
