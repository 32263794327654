import { User } from "@/schema/types";
import { ZoomCallInstance } from "../ZoomVideo";
import { AntMediaCallInstance } from "../AntMedia";

export enum CallState {
  NoCall = "no_call",
  Started = "call_started",
  Timeout = "call_timeout",
  Ended = "call_ended",
  Dropped = "call_dropped",
  InCall = "in_call",
}

export type CallParticipant = {
  id?: number | string;
  info?: Partial<User>;
  displayName?: string;
  userIdentity?: number | string;
  isCurrentUser?: boolean;
  state: { audioMuted: boolean; videoMuted: boolean; screenMuted: boolean };
  videoSettings?: { width: number; height: number };
};

export enum ConnectionState {
  Connected = "connected",
  Closed = "closed",
  Fail = "fail",
  Reconnecting = "reconnecting",
}

export type CallInstance = ZoomCallInstance | AntMediaCallInstance;

export { useAntMediaCall, AntMediaProvider, AntMediaView, AntMediaPublisherView } from "../AntMedia";
export { useZoomCall, ZoomProvider, ZoomView, ZoomPublisherView } from "../ZoomVideo";
export { useSanarCall } from "../Sanar";
