import { toInteger } from "lodash";
import { useState, useEffect, useRef } from "react";
import { useAddToast } from "@toolkit/ui";
import { useRequestOtpForLoginMutation } from "../../graphql/mutations";
import { formatGraphQLError } from "@toolkit/apollo";

type OTPVerificationProps = {
  initialResetTime?: number;
  mobile: string;
  handleSessionToken: (sessionToken: string) => void;
  handleOtpChange: (otp: string) => void;
};
export const useOTPVerification = ({ initialResetTime = 10, mobile, handleSessionToken, handleOtpChange }: OTPVerificationProps) => {
  const [resetRemainingTime, setResetRemainingTime] = useState(initialResetTime);
  const ref = useRef<NodeJS.Timeout | null>(null);
  const { failed } = useAddToast();
  const [sendOtp] = useRequestOtpForLoginMutation({
    onCompleted: data => {
      if (data?.requestOtp?.sessionToken) {
        handleSessionToken(data?.requestOtp.sessionToken);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleChange = (value: string) => {
    handleOtpChange(value);
  };

  const handleResend = () => {
    if (resetRemainingTime === 0) {
      sendOtp({
        variables: {
          phoneNumber: mobile,
        },
      });
      setResetRemainingTime(initialResetTime);
    }
  };

  useEffect(() => {
    if (resetRemainingTime > 0) {
      ref.current = setInterval(() => {
        setResetRemainingTime(prevTime => prevTime - 1);
      }, 1000);

      return () => {
        if (ref.current) clearInterval(ref.current);
      };
    }
  }, [resetRemainingTime, initialResetTime]);

  const formattedTime = `${String(toInteger(resetRemainingTime / 60)).padStart(2, "0")}:${String(resetRemainingTime % 60).padStart(
    2,
    "0"
  )}`;

  return {
    resetRemainingTime: formattedTime,
    isDisabled: resetRemainingTime > 0,
    handleChange,
    handleResend,
  };
};
