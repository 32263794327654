import { useCallback, useState } from "react";

import { ExecutedResponse, MobileVideoFacingMode } from "./types";
import { useZoom } from "./useZoom";
import { formatExecutedFailure } from "./utils";

export const useFlipCamera = () => {
  const { mediaStream } = useZoom();
  const [facingMode, setFacingMode] = useState(MobileVideoFacingMode.User);

  const flipCamera = useCallback(async (): Promise<ExecutedResponse> => {
    if (!mediaStream) {
      return { type: "CLIENT_NOT_READY" };
    }

    try {
      const mode = facingMode === MobileVideoFacingMode.Environment ? MobileVideoFacingMode.User : MobileVideoFacingMode.Environment;
      mediaStream.switchCamera(mode);
      setFacingMode(mode);
      return { success: true };
    } catch (error) {
      console.error("Meeting: Unable to switch camera due to the error", error);
      return formatExecutedFailure(error);
    }
  }, [mediaStream, facingMode]);

  const enabled = mediaStream && mediaStream.isCapturingVideo() && mediaStream.getCameraList().length > 1;

  return { flipCamera: enabled ? flipCamera : undefined };
};
