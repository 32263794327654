import { i18n } from "@toolkit/i18n";
import { MyOrdersTabsEnum } from "../../others/types";
import { MarketplaceOrderStatus } from "@/schema/types";

export const myOrdersTabs = [
  {
    label: i18n.t("Order Placed", { ns: "consumer" }),
    routeParameterValue: MyOrdersTabsEnum.orderPlaced,
    statusFiltersValues: [MarketplaceOrderStatus.New] as Array<MarketplaceOrderStatus>,
    message: i18n.t("No orders placed yet", { ns: "consumer" }),
    subMessage: i18n.t("You currently have no orders placed. Start by ordering from the marketplace.", { ns: "consumer" }),
  },
  {
    label: i18n.t("On The Way", { ns: "consumer" }),
    routeParameterValue: MyOrdersTabsEnum.onTheWay,
    statusFiltersValues: [MarketplaceOrderStatus.Accepted, MarketplaceOrderStatus.OutForDelivery] as Array<MarketplaceOrderStatus>,
    message: i18n.t("No orders on the way", { ns: "consumer" }),
    subMessage: i18n.t("You currently have no orders on the way. Start by ordering from the marketplace.", { ns: "consumer" }),
  },
  {
    label: i18n.t("Delivered", { ns: "consumer" }),
    routeParameterValue: MyOrdersTabsEnum.delivered,
    statusFiltersValues: [MarketplaceOrderStatus.Delivered, MarketplaceOrderStatus.Consumed] as Array<MarketplaceOrderStatus>,
    message: i18n.t("No orders delivered", { ns: "consumer" }),
    subMessage: i18n.t("You currently have no orders delivered. Start by ordering from the marketplace.", { ns: "consumer" }),
  },
  {
    label: i18n.t("Cancelled", { ns: "consumer" }),
    routeParameterValue: MyOrdersTabsEnum.cancelled,
    statusFiltersValues: [MarketplaceOrderStatus.Cancelled, MarketplaceOrderStatus.Rejected] as Array<MarketplaceOrderStatus>,
    message: i18n.t("No orders cancelled", { ns: "consumer" }),
    subMessage: i18n.t("You currently have no orders cancelled. Start by ordering from the marketplace.", { ns: "consumer" }),
  },
] as const;
