import { routesPaths } from "@/routing/routesPaths";
import { EmptyMessageLayout } from "@/shared/components";
import { EmptyNotificationIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { Button, makeStyles } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles()(theme => ({
  button: {
    width: "140px",
    height: "45px",
    borderRadius: "24px",
    borderColor: theme.palette.primary.main,
  },
}));

export const EmptyNotificationMessage: FC = () => {
  const { classes } = useStyle();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(routesPaths.home);
  };
  return (
    <EmptyMessageLayout
      icon={<EmptyNotificationIcon />}
      message={t("No notifications yet")}
      subMessage={t("When you get notifications, they will be show up here")}
      action={
        <Button onClick={handleClose} className={classes.button} variant='outlined'>
          {t("Close")}
        </Button>
      }
    />
  );
};
