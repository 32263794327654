import { GenericFilterForm } from "@/shared/components";
import { LanguagesAutocomplete } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import {
  doctorListFiltersFormDefaultValues,
  DoctorsListFiltersFormSchema,
  IDoctorsListFiltersFormValues,
} from "../DoctorsListFiltersFormSchema";
import { DoctorFilterFormProps } from "../types";

export const LanguagesFilterForm: React.FC<DoctorFilterFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IDoctorsListFiltersFormValues>
      schema={DoctorsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='languages'
      dialogTitle={t("Languages")}
      formLabel={t("Languages")}
      render={name => <LanguagesAutocomplete name={name} multiple />}
      defaultValues={doctorListFiltersFormDefaultValues}
    />
  );
};
