import { CustomDialog } from "@toolkit/ui";
import { FC } from "react";
import { BaseConfirmationDialog } from "../types";
import { BlobStatusIcons } from "../../BlobStatusIcons/BlobStatusIcons";

export const ConfirmationBaseDialog: FC<BaseConfirmationDialog> = props => {
  const { color, icon } = props;
  const defaultIcon = icon || <BlobStatusIcons status={color} />;
  return <CustomDialog type='base' variant='consumer' {...props} icon={defaultIcon} />;
};
