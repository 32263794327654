import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const FoodCaloriesCalculatorIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.common.white;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='65.079' height='63.986' viewBox='0 0 65.079 63.986' {...props}>
      <g id='icons8-healthy-food-calories-calculator' transform='translate(-4.3 -5)'>
        <path
          id='Path_28407'
          data-name='Path 28407'
          d='M25.76,61.019a2.356,2.356,0,0,1-.41-.036c-5.7-1.008-11.129-5.1-15.278-11.518C6.458,43.872,4.3,37.245,4.3,31.737A18.854,18.854,0,0,1,35.2,17.192a18.845,18.845,0,0,1,25.738,1.57,2.341,2.341,0,0,1-3.4,3.217A14.167,14.167,0,0,0,36.905,22,2.341,2.341,0,0,1,33.5,22a14.036,14.036,0,0,0-10.313-4.47,14.218,14.218,0,0,0-14.2,14.2c0,4.574,1.925,10.393,5.023,15.188,3.434,5.314,7.753,8.67,12.16,9.448a2.341,2.341,0,0,1-.4,4.647Z'
          transform='translate(0 4.403)'
          fill={fill}
        />
        <path
          id='Path_28408'
          data-name='Path 28408'
          d='M25.181,25.556a2.342,2.342,0,0,1-2.272-1.785,22.544,22.544,0,0,1-.529-4.825A13.97,13.97,0,0,1,25.154,9.9c1.519-1.843,4.557-4.161,10.4-4.742a4.9,4.9,0,0,1,5.378,4.435,22.873,22.873,0,0,1,.1,2.535,2.341,2.341,0,0,1-4.681-.088,18.17,18.17,0,0,0-.076-2.013.226.226,0,0,0-.081-.16.224.224,0,0,0-.171-.049c-1.923.191-5.422.843-7.251,3.061-2.778,3.369-1.325,9.716-1.31,9.779a2.343,2.343,0,0,1-2.276,2.9Z'
          transform='translate(10.136 0.075)'
          fill={fill}
        />
        <path
          id='Path_28409'
          data-name='Path 28409'
          d='M29.246,24.336a2.341,2.341,0,0,1-2.334-2.191,18.107,18.107,0,0,0-2.375-8.656c-1.661-2.562-4.178-3.808-7.7-3.808a2.341,2.341,0,1,1,0-4.682,14.591,14.591,0,0,1,7.066,1.656,12.61,12.61,0,0,1,4.558,4.287,22.608,22.608,0,0,1,3.119,10.9A2.341,2.341,0,0,1,29.4,24.332Q29.322,24.336,29.246,24.336Z'
          transform='translate(5.719 0)'
          fill={fill}
        />
        <path
          id='Path_28410'
          data-name='Path 28410'
          d='M51.872,54.349H30.023A7.031,7.031,0,0,1,23,47.326V33.45a2.341,2.341,0,0,1,4.682,0V47.326a2.344,2.344,0,0,0,2.341,2.341H51.872a2.344,2.344,0,0,0,2.341-2.341V45.765a2.341,2.341,0,1,1,4.682,0v1.561A7.031,7.031,0,0,1,51.872,54.349Z'
          transform='translate(10.484 14.638)'
          fill={fill}
        />
        <path
          id='Path_28411'
          data-name='Path 28411'
          d='M56.554,43.953a2.341,2.341,0,0,1-2.341-2.341V27.023a2.344,2.344,0,0,0-2.341-2.341H30.023a2.344,2.344,0,0,0-2.341,2.341v2.986a2.341,2.341,0,1,1-4.682,0V27.023A7.031,7.031,0,0,1,30.023,20H51.872a7.031,7.031,0,0,1,7.023,7.023V41.612A2.341,2.341,0,0,1,56.554,43.953Z'
          transform='translate(10.484 8.41)'
          fill={fill}
        />
        <path
          id='Path_28412'
          data-name='Path 28412'
          d='M44.606,32.243H30.561A1.561,1.561,0,0,1,29,30.682V27.561A1.561,1.561,0,0,1,30.561,26H44.606a1.561,1.561,0,0,1,1.561,1.561v3.121A1.561,1.561,0,0,1,44.606,32.243Z'
          transform='translate(13.848 11.773)'
          fill={fill}
        />
        <circle
          id='Ellipse_3521'
          data-name='Ellipse 3521'
          cx='2.341'
          cy='2.341'
          r='2.341'
          transform='translate(41.287 47.137)'
          fill={fill}
        />
        <circle
          id='Ellipse_3522'
          data-name='Ellipse 3522'
          cx='2.341'
          cy='2.341'
          r='2.341'
          transform='translate(49.09 47.137)'
          fill={fill}
        />
        <circle
          id='Ellipse_3523'
          data-name='Ellipse 3523'
          cx='2.341'
          cy='2.341'
          r='2.341'
          transform='translate(41.287 54.941)'
          fill={fill}
        />
        <circle
          id='Ellipse_3524'
          data-name='Ellipse 3524'
          cx='2.341'
          cy='2.341'
          r='2.341'
          transform='translate(49.09 54.941)'
          fill={fill}
        />
        <path
          id='Path_28413'
          data-name='Path 28413'
          d='M42.682,34.341a2.341,2.341,0,0,0-4.682,0v7.8a2.341,2.341,0,0,0,4.682,0Z'
          transform='translate(18.894 15.137)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
