import { Box, Button, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useConfirmDoctorTime } from "./useConfirmDoctorTime";

import { PaymentSummary } from "@/domains/payment";
import { CustomCard, MultiLineDetailCard } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { AppointmentDoctorCard } from "../../components/AppointmentDoctorCard/AppointmentDoctorCard";
import SlotsSection from "../../components/SlotsSection/SlotsSection";
import { useCommonStyles } from "../CommonContainer.styles";

export const AppointmentConfirmDoctorTimeContainer: FC = () => {
  const location = useLocation();
  const { t } = useTranslation("consumer");

  const { classes } = useCommonStyles();
  const {
    isSubmitting,
    selectedTimeSlot,
    paymentInfo,
    appointmentDate,
    timeFrame,
    doctorInfo,
    handleTimeSlotSelect,
    handleAppointmentConfirmation,
    suggestedTimeSlots,
  } = useConfirmDoctorTime(location);

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography className={classes.title}>{t("Select a date")}</Typography>
        <CustomCard
          sx={{
            "& .MuiButton-root": {
              boxShadow: theme => theme.mixins.shadows.xs,
              border: "2px solid",
              borderColor: theme => theme.palette.gray[200],
            },
            paddingBlock: "10px 16px",
            paddingInline: "16px",
          }}
        >
          <SlotsSection
            isLoading={false}
            timeSlots={suggestedTimeSlots}
            selectedTimeSlots={selectedTimeSlot ? [selectedTimeSlot] : []}
            onSelect={handleTimeSlotSelect}
            label={""}
            icon={""}
          />
        </CustomCard>

        <Typography className={classes.title} marginTop='10px'>
          {t("Appointment Information's")}
        </Typography>
        <MultiLineDetailCard
          title=''
          items={[
            { label: t("Appointment Date"), value: appointmentDate },
            { label: t("Requested TimeFrame"), value: timeFrame || "-" },
          ]}
        />
        <PaymentSummary
          priceBeforeVat={paymentInfo?.totalAmount || undefined}
          priceAfterVat={paymentInfo?.priceAmountAfterVat || undefined}
        />
        <AppointmentDoctorCard {...doctorInfo} />
      </Box>
      <Box className={classes.footer}>
        <Button disabled={isSubmitting || !selectedTimeSlot} onClick={handleAppointmentConfirmation} className={classes.button} fullWidth>
          {t("Confirm")}
        </Button>
      </Box>
    </Box>
  );
};
