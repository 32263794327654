/* eslint-disable max-statements */
import { MutableRefObject, useCallback, useEffect, useState } from "react";

import { Adaptor, CustomMediaStreamTrack, ExecutedResponse, MediaStreamTrackType } from "./types";
import { formatExecutedFailure, getUserMedia } from "./utils";

type MutedParam = boolean | ((muted: boolean) => boolean);

export const useMuteScreen = ({ adaptorRef, localStream }: { adaptorRef: MutableRefObject<Adaptor | null>; localStream: MediaStream }) => {
  const [screenMuted, setScreenMuted] = useState(true);
  const [screenTrack, setScreenTrack] = useState<MediaStreamTrack>();

  const resetScreen = useCallback(() => {
    setScreenMuted(true);
    setScreenTrack(undefined);
  }, []);

  const muteScreen = useCallback(
    async (value: MutedParam): Promise<ExecutedResponse> => {
      try {
        if (!localStream) {
          return { success: false };
        }

        const muted = typeof value === "function" ? value(screenMuted === undefined ? true : !!screenMuted) : value;

        screenTrack?.stop();
        screenTrack?.dispatchEvent(new Event("ended"));

        if (!muted) {
          const result = await getUserMedia({ video: true }, false);
          if (!result.success) {
            return result;
          }

          const [track] = result.stream.getVideoTracks() as CustomMediaStreamTrack[];
          track._type = MediaStreamTrackType.screen;

          setScreenTrack(track);
        }

        setScreenMuted(muted);
        return { success: true };
      } catch (error) {
        console.error("Meeting: Unable mute screen due to the error", error);
        return formatExecutedFailure(error);
      }
    },
    [localStream, screenMuted, screenTrack]
  );

  useEffect(() => {
    if (!screenTrack) {
      return;
    }

    localStream.addTrack(screenTrack);
    adaptorRef.current?.replaceTrack(screenTrack);
    const onEnded = () => {
      localStream.removeTrack(screenTrack);
      const [track] = localStream.getVideoTracks();
      track && adaptorRef.current?.replaceTrack(track);

      setScreenMuted(true);
      setScreenTrack(undefined);
    };
    screenTrack.addEventListener("ended", onEnded);

    return () => {
      screenTrack.removeEventListener("ended", onEnded);
    };
  }, [adaptorRef, localStream, screenTrack]);

  return { resetScreen, muteScreen, screenMuted: screenMuted !== false };
};
