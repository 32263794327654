import { wellnessRoutes } from "@/domains/wellness/routes";
import { MainPageWithAppBarLayout } from "@/shared/components";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const WellnessRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageWithAppBarLayout containerBreakpoint='sm' />}>
        <Route path={wellnessRoutes.root.route} Component={wellnessRoutes.root.component} />
      </Route>
    </Routes>
  );
};
