import { pickLocalizedValue } from "@toolkit/i18n";
import { TruncateTypography } from "@toolkit/ui";
import { FC, ReactNode } from "react";
import { SelectedCardProductPropsType } from "../../types";
import { PriceCalculationResult } from "../../utils";
import { MarketPlaceCardLayout } from "../MarketplaceCardLayout/MarketplaceCardLayout";
import { MarketplaceItemAvatar } from "../MarketplaceItemAvatar/MarketplaceItemAvatar";
import { PriceDisplay } from "../PriceDisplay/PriceDisplay";
import { useProductSelectedCardStyle } from "./ProductSelectedCardStyles";

type ProductSelectedCardProps = {
  product?: SelectedCardProductPropsType;
  actionsNode?: ReactNode;
  priceInfo: PriceCalculationResult;
  handleOnClick?: () => void;
  classes?: Partial<ReturnType<typeof useProductSelectedCardStyle>["classes"]>;
  infoExtraNode?: ReactNode;
};

export const ProductSelectedCard: FC<ProductSelectedCardProps> = props => {
  const { product, actionsNode, classes: _classes, infoExtraNode, handleOnClick, priceInfo } = props;

  const { classes, cx } = useProductSelectedCardStyle();

  return (
    <MarketPlaceCardLayout
      onClick={handleOnClick}
      classes={{
        card: cx(classes.card, _classes?.card),
        itemAvatarContainer: cx(classes.itemAvatarContainer, _classes?.itemAvatarContainer),
        infoContainer: cx(classes.infoContainer, _classes?.infoContainer),
        actionsContainer: cx(classes.actionsContainer, _classes?.actionsContainer),
        textColumn: cx(classes.textColumn, _classes?.textColumn),
      }}
      infoNode={
        <>
          <TruncateTypography
            maxWidth='90%'
            className={cx(classes.itemName, _classes?.itemName)}
            text={pickLocalizedValue(product?.name, product?.nameAr) || ""}
          />
          <TruncateTypography
            maxWidth='90%'
            maxLines={3}
            className={cx(classes.itemDiscretion, _classes?.itemDiscretion)}
            text={pickLocalizedValue(product?.shortDescription, product?.shortDescriptionAr) || ""}
          />
          <PriceDisplay increaseFontSizeBy={1} amount={priceInfo?.discountedPrice} type='discounted-price' />
          {priceInfo?.hasActiveDiscount && <PriceDisplay increaseFontSizeBy={1} amount={priceInfo?.basePrice} type='base-price' />}
          {infoExtraNode}
        </>
      }
      actionsNode={actionsNode}
      itemAvatarNode={
        <MarketplaceItemAvatar
          classes={{
            img: cx(classes.image, _classes?.image),
            root: cx(classes.imageRoot, _classes?.imageRoot),
          }}
          imageUrl={product?.mainImageUrl}
          variant='square'
          hidden
          marketplaceItemType='product'
        />
      }
    />
  );
};
