import { ExtractNodeType } from "@toolkit/apollo";
import {
  ActiveOrderGetQuery,
  ConsumerMedicationsOrdersQuery,
  DayDeliveryTimeSlotGetQuery,
  PatientHealthProgramMembersAutocompleteQuery,
  PrescriptionGetQuery,
} from "../gql/queries";
import { DeliveryTypes } from "@/schema/types";
import { ValidatePrescriptionMutation } from "../gql/mutations";
import { AddressFragmentFragment } from "@/domains/address/gql/fragments";
import { Moment } from "moment";

export type PatientHealthProgramMembersAutocomplete = ExtractNodeType<PatientHealthProgramMembersAutocompleteQuery>;
export type PrescriptionsMedicationsSummary = NonNullable<
  ValidatePrescriptionMutation["validatePrescription"]
>["prescription"]["medications"];

export type OrderAndDeliverPrescriptionContainerDataType = {
  prescriptionReference: string;
  memberID: string;
  prescriptionID: string;
  allowedDeliveryTypes: Array<DeliveryTypes>;
  medications: PrescriptionsMedicationsSummary;
};

export type AddressSelectionContainerDataProps = Omit<
  OrderAndDeliverPrescriptionContainerDataType,
  "medications" | "allowedDeliveryTypes"
> & {
  selectedDeliveryType: DeliveryTypes;
};

export type DeliveryTimeSelectionContainerDataProps = AddressSelectionContainerDataProps & {
  selectedAddress: AddressFragmentFragment;
};

export type TimeSlot = NonNullable<DayDeliveryTimeSlotGetQuery["deliveryTimeSlots"]>["edges"][number]["node"];

export type DataAndSlot = {
  timeSlots: TimeSlot;
  selectedDate: Moment;
};

export type OrderMedicationContextProps = {
  shippingAddress?: AddressFragmentFragment;
  handleShippingAddressChange: (address: AddressFragmentFragment) => void;
  selectedDateAndSlot?: DataAndSlot;
  handleSelectedDateAndSlotChange: (date: Moment, slot: TimeSlot) => void;
  deliveryType?: DeliveryTypes;
  handleDeliveryTypeChange: (deliveryType: DeliveryTypes) => void;
  prescriptionId?: string;
  handlePrescriptionIdChange: (prescriptionId: string) => void;
};

export type PrescriptionDetailsSummary = NonNullable<PrescriptionGetQuery["prescription"]>;
export type ActiveOrder = NonNullable<ActiveOrderGetQuery["order"]>;
export type ActiveOrderChild = NonNullable<NonNullable<ActiveOrder["children"]>[number]>;

export type OrderTrackingViewProps = {
  order?: ActiveOrder;
  prescription?: PrescriptionDetailsSummary;
};

export type SelectedPharmacy = NonNullable<NonNullable<NonNullable<ActiveOrder>["children"]>[number]>["branch"];

export type PaymentSummaryContainerDataProps = {
  order?: ActiveOrder;
  prescription?: PrescriptionDetailsSummary;
};

export type PrescriptionMedicationDetails = PrescriptionDetailsSummary["medications"][number];

export enum OrderMedicationContainerReferenceNumberSourceTypes {
  application = "application",
  external = "external",
  pickupSms = "pickupSms",
}
export type ApplicationOrderMedicationUrlParamsType = {
  referenceId: string | undefined;
};
export type ExternalOrderMedicationUrlParamsType = { erx: string; nid: string; mobile: string; mid: string };

export type PrescriptionDetailsExternalUrlParams = {
  erx: string;
  nid: string;
  mobile: string;
  mid: string;
  oid: string;
  pid: string;
};

export type OrderMedicationUrlApplicationUrlInfo = {
  referenceId: string | undefined;
  sourceType: OrderMedicationContainerReferenceNumberSourceTypes.application;
  params: ApplicationOrderMedicationUrlParamsType;
  mobileNumber: undefined;
  memberId: undefined;
  nationalId: undefined;
};

export type OrderMedicationPickupSmsUrlInfo = {
  referenceId: string;
  sourceType: OrderMedicationContainerReferenceNumberSourceTypes.pickupSms;
  params: PrescriptionDetailsExternalUrlParams;
  mobileNumber: string;
  memberId: string;
  nationalId: string;
  loginByOtpRedirectUrl: string;
};

export type OrderMedicationUrlExternalUrlInfo = {
  referenceId: string;
  sourceType: OrderMedicationContainerReferenceNumberSourceTypes.external;
  params: ExternalOrderMedicationUrlParamsType;
  mobileNumber: string;
  memberId: string;
  nationalId: string;
};

export type OrderMedicationUrlInfo =
  | OrderMedicationUrlApplicationUrlInfo
  | OrderMedicationUrlExternalUrlInfo
  | OrderMedicationPickupSmsUrlInfo;

export type TrackingMedicationsOrderData = NonNullable<
  NonNullable<ConsumerMedicationsOrdersQuery["me"]>["orders"]
>["edges"][number]["node"];

export type PrescriptionDetailsApplicationUrlParams = {
  prescriptionId: string;
  orderId: string;
};

export type PrescriptionDetailsUrlInfo =
  | {
      referenceId: string;
      sourceType: typeof OrderMedicationContainerReferenceNumberSourceTypes.application;
      params: PrescriptionDetailsApplicationUrlParams;
      mobileNumber?: undefined;
      memberId?: undefined;
      nationalId?: undefined;
      prescriptionId?: string;
      orderId?: string;
    }
  | {
      referenceId: string;
      sourceType: typeof OrderMedicationContainerReferenceNumberSourceTypes.external;
      params: PrescriptionDetailsExternalUrlParams;
      mobileNumber: string;
      memberId: string;
      nationalId: string;
      prescriptionId?: string;
      orderId?: string;
    };
