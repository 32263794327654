import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/queries/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceReviewsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.MarketplaceReviewFilterInput>;
}>;


export type MarketplaceReviewsQuery = { __typename?: 'Query', marketplaceReviews?: { __typename?: 'MarketplaceReviewConnection', edges: Array<{ __typename?: 'MarketplaceReviewEdge', node: { __typename?: 'MarketplaceReview', comment?: string | null, rating: number, user: { __typename?: 'User', fullName?: string | null } } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const MarketplaceReviewsDocument = gql`
    query MarketplaceReviews($after: String, $first: Int, $filter: MarketplaceReviewFilterInput) {
  marketplaceReviews(after: $after, first: $first, filter: $filter) {
    edges {
      node {
        comment
        rating
        user {
          fullName
        }
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useMarketplaceReviewsQuery__
 *
 * To run a query within a React component, call `useMarketplaceReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceReviewsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMarketplaceReviewsQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceReviewsQuery, MarketplaceReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceReviewsQuery, MarketplaceReviewsQueryVariables>(MarketplaceReviewsDocument, options);
      }
export function useMarketplaceReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceReviewsQuery, MarketplaceReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceReviewsQuery, MarketplaceReviewsQueryVariables>(MarketplaceReviewsDocument, options);
        }
export function useMarketplaceReviewsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplaceReviewsQuery, MarketplaceReviewsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceReviewsQuery, MarketplaceReviewsQueryVariables>(MarketplaceReviewsDocument, options);
        }
export type MarketplaceReviewsQueryHookResult = ReturnType<typeof useMarketplaceReviewsQuery>;
export type MarketplaceReviewsLazyQueryHookResult = ReturnType<typeof useMarketplaceReviewsLazyQuery>;
export type MarketplaceReviewsSuspenseQueryHookResult = ReturnType<typeof useMarketplaceReviewsSuspenseQuery>;
export type MarketplaceReviewsQueryResult = Apollo.QueryResult<MarketplaceReviewsQuery, MarketplaceReviewsQueryVariables>;