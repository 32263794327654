import { InMemoryCacheConfig } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const cacheUtils: InMemoryCacheConfig = {
  typePolicies: {
    User: {
      merge: true,
      fields: {
        branches: relayStylePagination(),
      },
    },
    SiteSettings: {
      merge(existing = {}, incoming = {}) {
        return { ...existing, ...incoming };
      },
    },
    Me: {
      merge: true,
      fields: {
        patient: {
          merge: true,
        },
      },
    },
  },
};
