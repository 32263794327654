import { makeStyles, Typography } from "@toolkit/ui";
import { FC } from "react";
import { Box } from "@toolkit/ui";
import { ChatMessageDraft } from "../ChatProvider";
import { useChatDraftView } from "./ChatDraftView.hooks";
import { ChatDraftViewMedia } from "./ChatDraftViewMedia";

export const useStyles = makeStyles()(theme => ({
  root: {
    maxHeight: 200,
    backgroundColor: "white",
    overflow: "auto",
    padding: "8px 12px",
    margin: "0 12px",
    display: "flex",
    borderLeft: `0.2rem solid ${theme.palette.stale[300]}`,
  },
  media: {
    maxWidth: 80,
    maxHeight: 120,
    marginRight: 8,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
}));

type ChatDraftViewProps = {
  draft: ChatMessageDraft;
};

export const ChatDraftView: FC<ChatDraftViewProps> = ({ draft }) => {
  const { classes, theme } = useStyles();
  const { senderName, message, attachments } = useChatDraftView(draft);

  return (
    <Box className={classes.root}>
      {attachments.length > 0 && (
        <Box className={classes.media}>
          <ChatDraftViewMedia attachments={attachments} />
        </Box>
      )}
      <Box className={classes.content}>
        {senderName && <Typography color={theme.palette.secondary.main}>{senderName}</Typography>}
        <Typography>{message}</Typography>
      </Box>
    </Box>
  );
};
