import { MarketplaceProductSortingField } from "@/schema/types";
import { zodSchema } from "@health/autocompletes";
import { z } from "zod";

export const ProductsListFiltersFormSchema = z.object({
  vendor: zodSchema.vendor.nullish(),
  brands: z.array(zodSchema.marketplaceBrand.nullish()).optional(),
  price: z.array(z.number()).length(2).optional(),
  sortingField: z.nativeEnum(MarketplaceProductSortingField).optional(),
});

export type IProductsListFiltersFormValues = z.infer<typeof ProductsListFiltersFormSchema>;

export const productsListFiltersFormDefaultValues: Partial<IProductsListFiltersFormValues> = {
  vendor: undefined,
  brands: undefined,
  price: [0, 1200],
  sortingField: undefined,
};
