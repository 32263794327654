import { MarketplaceProductDetails } from "@/domains/marketplace-products/types";
import { HtmlRenderer } from "@/shared/components";
import { pickLocalizedValue } from "@toolkit/i18n";
import { FC } from "react";

type ProductTabBodyProps = {
  product: MarketplaceProductDetails;
};
export const HowToUseBody: FC<ProductTabBodyProps> = props => {
  const { product } = props;
  return <HtmlRenderer htmlContent={pickLocalizedValue(product?.howToUse, product?.howToUseAr) || ""} />;
};
