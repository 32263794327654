import { useHeaderContext } from "@/shared/context";
import { ArrowIcon } from "@/shared/icons";
import { Box, Container, IconButton, makeStyles, TruncateTypography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "./PageHeader.styles";

const useCustomHeaderStyles = makeStyles()(theme => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
    minHeight: "50px",
    height: "max-content",
  },
  root: {
    padding: "10px!important",
  },
  titleContainer: {
    position: "absolute",
    transform: "translateX(-40px)",
    width: "100%",
    paddingInlineStart: "80px",
    display: "flex",
    justifyContent: "center",
  },
  titleText: {
    fontSize: theme.mixins.fonts.fontSize.lg,
  },
}));

type PageHeaderWithBackProps = {
  hideBack?: boolean;
};

export const PageHeaderWithBack: FC<PageHeaderWithBackProps> = props => {
  const { hideBack } = props;
  const { classes, cx } = useStyles({});
  const navigate = useNavigate();
  const { headerTitle, backPath, setBackPath } = useHeaderContext();
  const { classes: customClasses } = useCustomHeaderStyles();

  const onBackClick = () => {
    if (typeof backPath === "string") {
      navigate(backPath);
    } else {
      navigate(-1);
    }
    setBackPath(undefined);
  };

  return (
    <Container maxWidth='sm' className={cx(classes.container, customClasses.root)}>
      <Box className={customClasses.headerContainer}>
        <Box className={customClasses.titleContainer}>
          <TruncateTypography maxWidth='100%' text={headerTitle} className={customClasses.titleText} />
        </Box>
        {!hideBack && (
          <IconButton onClick={onBackClick} className={classes.iconButton}>
            <ArrowIcon direction='left' />
          </IconButton>
        )}
      </Box>
    </Container>
  );
};
