import React from "react";
import { Box, BoxProps } from "@toolkit/ui";
import { makeStyles } from "@toolkit/ui";

const useStyles = makeStyles()(theme => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(0.3),
  },
  dot: {
    width: 14,
    height: 14,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    "@keyframes pulse": {
      "0%, 50%, 100%": {
        transform: "scale(0.6)",
      },
      "40%": {
        transform: "scale(1)",
        opacity: 1,
      },
    },
    animation: "pulse .8s infinite ease-in-out",
  },
  dotTwo: {
    animationDelay: "0.2s",
  },
  dotThree: {
    animationDelay: "0.4s",
  },
}));

interface DotLoaderProps extends BoxProps {}
export const DotLoader: React.FC<DotLoaderProps> = ContainerProps => {
  const { classes, cx } = useStyles();

  return (
    <Box {...ContainerProps} className={cx(classes.loader, ContainerProps.className)}>
      <Box className={classes.dot} />
      <Box className={cx(classes.dot, classes.dotTwo)} />
      <Box className={cx(classes.dot, classes.dotThree)} />
    </Box>
  );
};

export default DotLoader;
