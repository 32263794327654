import { useCallback, useContext, useMemo } from "react";
import { ChatContext } from "./ChatProvider";

export const useChatUnreadMessages = (id?: string | null) => {
  const { unreadMessages, markAsRead: markChatAsRead } = useContext(ChatContext);
  const unreadCount = useMemo(() => (id ? unreadMessages[id] : 0) || 0, [id, unreadMessages]);
  const markAsRead = useCallback(() => {
    if (id) {
      markChatAsRead(id);
    }
  }, [id, markChatAsRead]);

  return { unreadCount, markAsRead };
};
