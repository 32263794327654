import { makeStyles } from "@toolkit/ui";

export const useSignInByOTPModalStyles = makeStyles()(theme => ({
  containerBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "20px",
  },
  title: {
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: theme.spacing(2),
    width: "100%",
  },
  button: {
    width: "100%",
    borderRadius: "24px",
  },
  secondary: {
    color: theme.palette.text.secondary,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    display: "inline-block",
  },
}));
