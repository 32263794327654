import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { orderMedicationsRoutes } from "../../routes";
import { OrderTrackingViewProps } from "../../others/types";
import { useOrderCancelMutation } from "../../gql/mutations";
import { useOpenState } from "@toolkit/core";
import { formatGraphQLError } from "@toolkit/apollo";
import { ReactNode } from "react";
import { ConfirmationDialog } from "@/shared/components";

type OrderTrackingFooterProps = OrderTrackingViewProps & {
  FooterButton?: ReactNode;
};

export const OrderTrackingFooter: FC<OrderTrackingFooterProps> = props => {
  const { prescription, order, FooterButton } = props;
  const { classes, cx } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const { failed } = useAddToast();
  const [cancelOrder, { loading: cancelOrderLoading }] = useOrderCancelMutation({
    onCompleted: () => {
      handleClose();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const { open, handleClose: onClose, handleOpen } = useOpenState();

  const disableButtons = cancelOrderLoading;

  const handleClose = () => {
    navigate(orderMedicationsRoutes.root.getRouteWithParams({ referenceId: prescription?.referenceNumber || "" }));
  };

  const handleCancel = () => {
    order?.id &&
      cancelOrder({
        variables: {
          orderCancelId: order?.id,
        },
      });
  };

  return (
    <Box className={cx(classes.footer, classes.towButtonfooter)}>
      <Box className={classes.footerStartContainer}>
        {FooterButton ? (
          FooterButton
        ) : (
          <Button disabled={disableButtons || !!1} className={cx(classes.button, classes.samllButton)}>
            {t("Continue")}
          </Button>
        )}
        <ConfirmationDialog
          variant='base'
          color='error'
          text={{
            title: t("Cancel Order"),
            body: t("Are you sure you want to cancel this order?"),
          }}
          isDoButtonDisabled={cancelOrderLoading}
          isOpen={open}
          handleClose={onClose}
          handleDo={handleCancel}
          DoTitle={t("Cancel Order")}
          button={
            <Button
              onClick={handleOpen}
              disabled={disableButtons}
              className={cx(classes.button, classes.xSmallButton)}
              sx={{ paddingInline: 0 }}
              variant='outlined'
            >
              {t("Cancel Order")}
            </Button>
          }
        />
      </Box>
      <Box className={classes.footerEndContainer}>
        <Button disabled={disableButtons} onClick={handleClose} className={cx(classes.button, classes.samllButton)} variant='outlined'>
          {t("Close")}
        </Button>
      </Box>
    </Box>
  );
};
