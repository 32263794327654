import { Chat } from "../../schema/types";
import { i18n, useTranslation } from "@toolkit/i18n";
import { FC, useMemo } from "react";
import { Box, format, Typography } from "@toolkit/ui";
import { useStyles } from "./ChatView.styles";
import { ChatViewMedia } from "./ChatViewMedia";
import { ChatPreview } from "./ChatPreview";

type ChatViewProps = {
  chat: Chat;
  isOutgoing?: boolean;
};

export const ChatView: FC<ChatViewProps> = ({ isOutgoing, chat }) => {
  const { t } = useTranslation();
  const hasText = !!chat.message;
  const hasPhoto = !!chat.attachments?.find(a => a.type.startsWith("image/"));
  const time = useMemo(() => format(chat.createdAt, "LT", i18n.language), [chat.createdAt]);
  const { classes } = useStyles({ hasPhoto, hasText, isOutgoing: !!isOutgoing, isDeleted: chat.deleted });

  return (
    <Box className={classes.root}>
      {!chat.deleted && (
        <>
          {chat.parent && <ChatPreview chat={chat.parent} />}
          {chat.attachments && chat.attachments?.length > 0 && (
            <Box className={classes.media}>
              <ChatViewMedia attachments={chat.attachments} />
            </Box>
          )}

          {chat.message && (
            <Box className={classes.textContent}>
              <Typography>{chat.message}</Typography>
            </Box>
          )}
        </>
      )}

      {chat.deleted && (
        <Box className={classes.textContent}>
          <Typography color='GrayText'>{t("Message deleted")}</Typography>
        </Box>
      )}
      <Box className={classes.footer}>
        <Typography className={classes.footerText}>
          {chat.edited && `(${t("edited")}) `}
          {time}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatView;
