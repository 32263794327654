import { Box, CircularProgress, Typography } from "@toolkit/ui";
import { FC, ReactNode } from "react";
import { useActivitiesItemStyles } from "./ActivitiesItemStyles";

import moment from "moment";
import { AggrOperation } from "@/schema/types";
import { PatientHealthParametersAggregationQuery, usePatientHealthParametersAggregationQuery } from "../../gql";
import { ExtractNodeType } from "@toolkit/apollo";

type ActivitiesItemProps = {
  icon: ReactNode;
  label: string;
  patientId: string;
  code: string;
  date: string;
  color: string;
  renderValue?: (value: ExtractNodeType<PatientHealthParametersAggregationQuery>) => ReactNode;
};

export const ActivitiesItem: FC<ActivitiesItemProps> = props => {
  const { icon, label, patientId, code, date, color, renderValue } = props;
  const { classes } = useActivitiesItemStyles({ color });

  const { data, loading } = usePatientHealthParametersAggregationQuery({
    skip: !patientId,
    fetchPolicy: "no-cache",
    variables: {
      first: 10,
      filter: {
        code,
        patientId,
        operation: AggrOperation.Sum,
        requestedDateRange: {
          gte: date,
          lte: moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD"),
        },
      },
    },
  });

  const healthParameter = data?.patientHealthParametersAggregation?.edges?.[0]?.node;

  return (
    <Box className={classes.container}>
      <Box className={classes.iconContainer}>{icon}</Box>
      <Box className={classes.contentContainer}>
        <Typography className={classes.label}>{label}</Typography>
        {loading ? (
          <CircularProgress size={18} className={classes.loader} />
        ) : (
          <Typography className={classes.value}>{renderValue?.(healthParameter!) || "N/A"}</Typography>
        )}
      </Box>
    </Box>
  );
};
