import { EmptyMessageDefault } from "@/shared/components";
import { useGetEventList } from "@sada/react/queries";
import { IEvent } from "@sada/react/schema";
import { i18n } from "@toolkit/i18n";
import { Box, CardsContainer, useMediaQuery } from "@toolkit/ui";
import { FC } from "react";
import { ReelCard } from "../../components/ReelCard/ReelCard";
import { ReelCardSkeleton } from "../../components/ReelCardSkeleton/ReelCardSkeleton";
import { useReelsContainerStyles } from "./ReelsContainerStyle";

type ReelsContainerProps = {
  countOfReels?: number;
  classes?: Partial<ReturnType<typeof useReelsContainerStyles>["classes"]>;
};

export const ReelsContainer: FC<ReelsContainerProps> = props => {
  const { countOfReels = 10, classes: _classes } = props;
  const { classes, cx, theme } = useReelsContainerStyles();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const { events, loading } = useGetEventList({
    type: "short",
    itemsPerPage: countOfReels,
  });

  const handleOnReelCardCLick = (event: IEvent) => {
    return () => {
      console.log(event?.slug);
      // implement on click
    };
  };

  return (
    <CardsContainer
      classes={{
        cardsContainerClassName: cx(classes.cardsContainerClassName, _classes?.cardsContainerClassName),
        navBtnClassName: cx(classes.navBtnClassName, _classes?.navBtnClassName),
        navBtnContainerClassName: cx(classes.navBtnContainerClassName, _classes?.navBtnContainerClassName),
        cardsContainer: cx(classes.cardsContainer, _classes?.cardsContainer),
        cardsUpperContainer: cx(classes.cardsUpperContainer, _classes?.cardsUpperContainer),
        card: cx(classes.card, _classes?.card),
        skeletonContainer: cx(classes.skeletonContainer, _classes?.skeletonContainer),
      }}
      isLoadingData={loading}
      EmptyCardComponent={<EmptyMessageDefault message={i18n.t("No reels available.", { ns: "consumer" })} />}
      countOfSkeletons={countOfReels}
      CardSkeletonComponent={
        <Box className={cx(classes.reelCardWrapper, _classes?.reelCardWrapper)}>
          <ReelCardSkeleton />
        </Box>
      }
      cardsScrollAmount={2}
      hideNavBtns={isSmall}
      cards={events.map(event => (
        <Box onClick={handleOnReelCardCLick(event)} key={event?.slug} className={cx(classes.reelCardWrapper, _classes?.reelCardWrapper)}>
          <ReelCard imgSrc={event.image_thumbs.event_small} title={event?.title} />
        </Box>
      ))}
    />
  );
};
