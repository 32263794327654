import { makeStyles } from "@toolkit/ui";

export const useMedicationsOrderTrackingCardStyles = makeStyles()(theme => ({
  headerIconWrapper: {
    "& svg": {
      width: "65px",
      height: "65px",
    },
  },
  headerRoot: {
    paddingTop: "5px",
    textAlign: "start",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2.5px",
    alignItems: "flex-start",
  },
  titleMainText: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  titleSecondaryText: {
    color: theme.palette.text.secondary,
  },
  contentContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    "& .MuiGrid-container": {
      margin: 0,
      width: "100%",
      padding: 0,
    },
    "& .MuiGrid-item": {
      paddingInline: 15,
      paddingBlock: 10,
      "&:nth-child(4n-1)": {
        backgroundColor: theme.palette.primary[50],
        borderRadius: "10px 0px 0 10px",
      },
      "&:nth-child(4n)": {
        backgroundColor: theme.palette.primary[50],
        borderRadius: "0px 10px 10px 0px",
      },
      "&:nth-child(4n-3), &:nth-child(4n-2)": {},
      "&:last-child:nth-child(odd)": {
        flexBasis: "100%",
        maxWidth: "100%",
        borderRadius: "10px",
      },
    },
  },
  footerContainer: {
    marginTop: 18,
    display: "flex",
    justifyContent: "center",
  },
  labelValueContainer: {},
}));
