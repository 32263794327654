import React from "react";
import { Box, makeStyles, Skeleton } from "@toolkit/ui";

export const DependentSkeleton: React.FC = () => {
  const { classes } = useStyle();
  return (
    <Box alignItems='center' py={1}>
      <Box className={classes.contentContainer} p={2} height={100}>
        <Skeleton variant='text' width={"100%"} height={20} />
        <Skeleton variant='text' width={"100%"} height={20} />
      </Box>
    </Box>
  );
};

const useStyle = makeStyles()(theme => ({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: theme.palette.common.white,
    overflow: "hidden",
    borderRadius: 10,
  },
}));
