import { makeStyles } from "@toolkit/ui";

export const useActivitiesProgressCircleStyles = makeStyles()({
  root: {
    position: "relative",
  },
  iconWrapper: {
    position: "absolute",
    bottom: "-18px",
    left: `calc(50% + 12.5px)`,
    transform: "translate(-50%, -50%)",
    "& svg": {
      width: 10,
      height: 10,
    },
  },
});
