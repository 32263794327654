import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendChatMessageMutationVariables = Types.Exact<{
  input: Types.ChatMessageInput;
}>;


export type SendChatMessageMutation = { __typename?: 'Mutation', sendChatMessage?: { __typename?: 'Chat', id: string, message?: string | null, createdAt: any, deleted: boolean, edited: boolean, attachments?: Array<{ __typename?: 'ChatAttachment', id: string, type: string, name: string, url: string }> | null, senderUser: { __typename?: 'User', id: string, fullName?: string | null, photo?: string | null }, parent?: { __typename?: 'Chat', message?: string | null, createdAt: any, deleted: boolean, edited: boolean, attachments?: Array<{ __typename?: 'ChatAttachment', id: string, type: string, name: string, url: string }> | null, senderUser: { __typename?: 'User', id: string, fullName?: string | null, photo?: string | null } } | null } | null };


export const SendChatMessageDocument = gql`
    mutation SendChatMessage($input: ChatMessageInput!) {
  sendChatMessage(input: $input) {
    id
    message
    createdAt
    deleted
    edited
    attachments {
      id
      type
      name
      url
    }
    senderUser {
      id
      fullName
      photo
    }
    parent {
      message
      createdAt
      deleted
      edited
      attachments {
        id
        type
        name
        url
      }
      senderUser {
        id
        fullName
        photo
      }
    }
  }
}
    `;
export type SendChatMessageMutationFn = Apollo.MutationFunction<SendChatMessageMutation, SendChatMessageMutationVariables>;

/**
 * __useSendChatMessageMutation__
 *
 * To run a mutation, you first call `useSendChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMessageMutation, { data, loading, error }] = useSendChatMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendChatMessageMutation, SendChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendChatMessageMutation, SendChatMessageMutationVariables>(SendChatMessageDocument, options);
      }
export type SendChatMessageMutationHookResult = ReturnType<typeof useSendChatMessageMutation>;
export type SendChatMessageMutationResult = Apollo.MutationResult<SendChatMessageMutation>;
export type SendChatMessageMutationOptions = Apollo.BaseMutationOptions<SendChatMessageMutation, SendChatMessageMutationVariables>;