import { useHeaderContext } from "@/shared/context";
import { ChatIcon, LocationIcon, MailIcon, WhatsappIcon } from "@/shared/icons";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Typography } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { CareTeamCompletionCard } from "../../components/CareTeamCompletionCard/CareTeamCompletionCard";
import { routes } from "../../routes";
import { CareTeamRegistrationContainerDataType } from "../../type";
import { useCareTeamRegistrationSuccessContainerStyle } from "./CareTeamRegistrationSuccessContainerStyle";
import { SingleInfoWithIconDetailCard } from "@/shared/components";

export const CareTeamRegistrationSuccessContainer: FC = () => {
  const location: Location<CareTeamRegistrationContainerDataType> = useLocation();
  const { team, teamLeader } = location.state || {};
  const { setHeaderTitle } = useHeaderContext();
  const { classes, theme } = useCommonContainerStyles();
  const navigate = useNavigate();
  const { t } = useTranslation("consumer");
  const { classes: customClasses } = useCareTeamRegistrationSuccessContainerStyle();

  useEffect(() => {
    setHeaderTitle("Confirmation");
  }, [setHeaderTitle]);

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <CareTeamCompletionCard teamName={team?.name} />
        <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium} mt={4}>
          {t("You can always reach your care team")}
        </Typography>
        {team?.provider?.name && teamLeader?.user?.defaultShippingAddress?.streetAddress1 && (
          <SingleInfoWithIconDetailCard
            className={customClasses.infoCard}
            title={team.provider.name}
            content={teamLeader?.user?.defaultShippingAddress?.streetAddress1 || ""}
            startIcon={
              <Box className={customClasses.iconContainer}>
                <LocationIcon />
              </Box>
            }
          />
        )}
        {teamLeader?.user?.email && (
          <SingleInfoWithIconDetailCard
            className={customClasses.infoCard}
            title={t("Email")}
            content={teamLeader.user.email}
            startIcon={
              <Box className={customClasses.iconContainer}>
                <MailIcon />
              </Box>
            }
          />
        )}
        {teamLeader?.user?.mobile && (
          <SingleInfoWithIconDetailCard
            className={customClasses.infoCard}
            title={t("Mobile/WhatsApp")}
            content={teamLeader.user.mobile}
            startIcon={
              <Box className={customClasses.iconContainer}>
                <WhatsappIcon />
              </Box>
            }
          />
        )}
        <SingleInfoWithIconDetailCard
          className={customClasses.infoCard}
          title={t("Chat")}
          content={
            <Button className={customClasses.chatButton} variant='text'>
              {t("Start Chat")}
            </Button>
          }
          startIcon={
            <Box className={customClasses.iconContainer}>
              <ChatIcon />
            </Box>
          }
        />
      </Box>

      <Box className={classes.footer}>
        <Button
          className={classes.button}
          onClick={() => {
            navigate(routes.root.absRoute);
          }}
        >
          {t("Done")}
        </Button>
      </Box>
    </Box>
  );
};
