import { routes as doctorRoutes } from "@/domains/doctor";
import { useSiteSettingsGetQuery } from "@/gql";
import { DoctorAvatar, DoctorCardLayout, Dot } from "@/shared/components";
import { displayStringOfArray } from "@/shared/utils";
import { ExtractNodeType } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Avatar, Box, Button, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { VirtualVisitPaymentSummaryContainerData } from "../../containers/VirtualVisitPaymentSummaryContainer/types";
import { AvailableDoctorsForVirtualCallQuery } from "../../gql";
import { routes } from "../../routes";
import { useVirtualVisitDoctorCardStyle } from "./VirtualVisitDoctorCardStyle";

type CareTeamMemberCardProps = {
  doctor?:
    | ExtractNodeType<AvailableDoctorsForVirtualCallQuery>
    | Omit<ExtractNodeType<AvailableDoctorsForVirtualCallQuery>, "averageRating">;
  classes?: Partial<ReturnType<typeof useVirtualVisitDoctorCardStyle>["classes"]>;
  hideAction?: boolean;
  hidePrice?: boolean;
};

export const VirtualVisitDoctorCard: FC<CareTeamMemberCardProps> = props => {
  const { doctor, hideAction, hidePrice, classes: _classes } = props;

  const { classes, cx } = useVirtualVisitDoctorCardStyle();
  const { t } = useTranslation("consumer");

  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });

  const currency = data?.siteSettings?.defaultCurrency || "";

  const navigate = useNavigate();
  const handleNavigateToDoctorProfile = () => {
    navigate(doctorRoutes.profile.getRouteWithParams({ doctorId: doctor?.id || "" }));
  };

  const handleCallNow = () => {
    navigate(routes.callPaymentSummary.absRoute, { state: { doctor } as VirtualVisitPaymentSummaryContainerData });
  };

  return (
    <DoctorCardLayout
      doctorAvatarNode={
        <Box className={cx(classes.doctorAvatar, _classes?.doctorAvatar)}>
          <DoctorAvatar className={cx(classes.doctorAvatar, _classes?.doctorAvatar)} imageUrl={doctor?.user?.photo || ""} />
        </Box>
      }
      infoNode={
        <>
          <Typography className={cx(classes.doctorName, _classes?.doctorName)}>{doctor?.user?.fullName}</Typography>
          {!hidePrice && (
            <Typography className={cx(classes.price, _classes?.price)}>
              {currency} {doctor?.onlineVisitPrice}
            </Typography>
          )}
          <Typography className={cx(classes.vendorName, _classes?.vendorName)}>
            {displayStringOfArray(doctor?.specializations?.map(spec => spec?.display || "") || undefined)}
          </Typography>
          <Typography className={cx(classes.vendorName, _classes?.vendorName)}>
            {pickLocalizedValue(doctor?.vendor?.name, doctor?.vendor?.nameAr)}
          </Typography>
          <Box className={cx(classes.languagesAndExperienceContainer, _classes?.languagesAndExperienceContainer)}>
            <Box className={cx(classes.languages, _classes?.languages)}>
              {displayStringOfArray(doctor?.languages?.map(lang => lang?.display || "") || undefined)}
              {doctor?.yearsOfExperience && (
                <Box display={"inline"}>
                  <Dot className={cx(classes.dot, _classes?.dot)} size={7} />
                  {t("{{years}} Years Experience", { years: doctor?.yearsOfExperience })}
                </Box>
              )}
            </Box>
          </Box>
        </>
      }
      downAvatarNode={
        <Avatar
          className={cx(classes.vendorAvatar, _classes?.vendorAvatar)}
          title={doctor?.vendor?.name}
          src={doctor?.vendor?.logo || ""}
        />
      }
      actionsNode={
        !hideAction ? (
          <>
            <Button onClick={handleCallNow} className={cx(classes.callButton, _classes?.callButton)}>
              {t("Call Now")}
            </Button>
            <Button
              className={cx(classes.profileButton, _classes?.profileButton)}
              variant='outlined'
              onClick={handleNavigateToDoctorProfile}
            >
              {t("Doctor’s Profile")}
            </Button>
          </>
        ) : null
      }
      classes={{
        card: cx(classes.card, _classes?.card),
        actionsContainer: cx(classes.actionsContainer, _classes?.actionsContainer),
        avatarsContainer: cx(classes.avatarsContainer, _classes?.avatarsContainer),
      }}
    />
  );
};
