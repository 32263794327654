import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeetingQueryVariables = Types.Exact<{
  meetingId: Types.Scalars['ID']['input'];
}>;


export type MeetingQuery = { __typename?: 'Query', meeting?: { __typename?: 'Meeting', id: string, topic: string, jwtToken?: string | null, jwtMainTrackToken?: string | null, meetingParticipantStreamId?: string | null, createdAt: any, chatGroup?: { __typename?: 'ChatGroup', id: string, name?: string | null, visitId?: string | null, createdAt: any } | null, participants: Array<{ __typename?: 'User', id: string, photo?: string | null, fullName?: string | null }> } | null };


export const MeetingDocument = gql`
    query Meeting($meetingId: ID!) {
  meeting(id: $meetingId) {
    id
    topic
    jwtToken
    jwtMainTrackToken
    meetingParticipantStreamId
    createdAt
    chatGroup {
      id
      name
      visitId
      createdAt
    }
    participants {
      id
      photo
      fullName
    }
  }
}
    `;

/**
 * __useMeetingQuery__
 *
 * To run a query within a React component, call `useMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useMeetingQuery(baseOptions: Apollo.QueryHookOptions<MeetingQuery, MeetingQueryVariables> & ({ variables: MeetingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeetingQuery, MeetingQueryVariables>(MeetingDocument, options);
      }
export function useMeetingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeetingQuery, MeetingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeetingQuery, MeetingQueryVariables>(MeetingDocument, options);
        }
export function useMeetingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeetingQuery, MeetingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeetingQuery, MeetingQueryVariables>(MeetingDocument, options);
        }
export type MeetingQueryHookResult = ReturnType<typeof useMeetingQuery>;
export type MeetingLazyQueryHookResult = ReturnType<typeof useMeetingLazyQuery>;
export type MeetingSuspenseQueryHookResult = ReturnType<typeof useMeetingSuspenseQuery>;
export type MeetingQueryResult = Apollo.QueryResult<MeetingQuery, MeetingQueryVariables>;