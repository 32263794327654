import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type ProductDetailsFragment = { __typename?: 'MarketplaceProduct', id: string, sku?: string | null, name?: string | null, nameAr?: string | null, description?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, howToUse?: string | null, howToUseAr?: string | null, price?: any | null, contentType?: Types.MarketplaceContentType | null, mainImageUrl?: string | null, subImages?: Array<string | null> | null, tags?: Array<string | null> | null, stockQuantity: number, returnable?: boolean | null, returnPolicy?: string | null, returnPolicyAr?: string | null, returnPolicyDays?: number | null, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, endDate: any, percentage?: number | null, startDate: any, type: Types.MarketplaceDiscountType } | null };

export const ProductDetailsFragmentDoc = gql`
    fragment ProductDetails on MarketplaceProduct {
  id
  sku
  name
  nameAr
  description
  descriptionAr
  shortDescription
  shortDescriptionAr
  howToUse
  howToUseAr
  price
  contentType
  activeDiscount {
    amount
    endDate
    percentage
    startDate
    type
  }
  mainImageUrl
  subImages
  tags
  stockQuantity
  returnable
  returnPolicy
  returnPolicyAr
  returnPolicyDays
}
    `;