import { makeStyles } from "@toolkit/ui";

export const useDeliveryTimeStyles = makeStyles()(theme => ({
  dateContainer: {
    display: "flex",
    overflowX: "auto",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  dateBox: {
    padding: 0,
    margin: 0,
    display: "flex",
    height: "70px",
    minWidth: "125px",
    borderRadius: 10,
    border: "1px solid",
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    flexDirection: "column",
    justifyContent: "center",
    "&& :first-child": {
      fontSize: theme.mixins.fonts.fontSize.md,
      fontWeight: theme.mixins.fonts.fontWeight.medium,
    },
    "&.selected": {
      backgroundColor: theme.palette.primary[50],
      borderColor: theme.palette.primary.main,
      border: "2px solid",
    },
  },
  timeSlotContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  timeSlot: {
    padding: 0,
    margin: 0,
    display: "flex",
    height: "60px",
    borderRadius: 10,
    border: "1px solid",
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "start",
    paddingInlineStart: 10,
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    "&.selected": {
      backgroundColor: theme.palette.primary[50],
      borderColor: theme.palette.primary.main,
      border: "1px solid",
    },
    "&.disabled": {
      opacity: 0.5,
      pointerEvents: "none",
    },
  },
}));
