import { PatientEligibleTeamFilterInput } from "@/schema/types";
import { EmptyMessageLayout } from "@/shared/components";
import { useContainerHeight } from "@/shared/hooks";
import { EmptyDoctorsIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomInfiniteScroll } from "@toolkit/ui";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { CareTeamDataType, CareTeamRegistrationContainerDataType } from "../../type";
import { getTeamLeader } from "../../utils";
import { CareTeamCard } from "../CareTeamCard/CareTeamCard";
import { CareTeamDoctorCardSkeleton } from "../CareTeamDoctorCardSkeleton/CareTeamDoctorCardSkeleton";
import { useCareTeamsListStyle } from "./CareTeamsListStyle";
import { useCareTeamsList } from "./useCareTeamsList";

type CareTeamsListProps = {
  filters?: Partial<PatientEligibleTeamFilterInput>;
  patientId: string;
  cardVariant: "myCareTeam" | "newCareTeam" | "careTeamInfo";
};

export const CareTeamsList: FC<CareTeamsListProps> = props => {
  const { filters, patientId, cardVariant } = props;
  const navigate = useNavigate();

  const { careTeams, pageInfo, isLoading, fetchMoreData, handleRefresh } = useCareTeamsList({
    filters,
    patientId,
  });

  const { classes } = useCareTeamsListStyle();
  const { t } = useTranslation("consumer");

  const { containerRef, containerHeight } = useContainerHeight();
  const handleNavigateToMyCareTeam = useCallback(
    (team: CareTeamDataType) => {
      return () => {
        const teamLeader = getTeamLeader(team?.teamMembers);
        navigate(routes.myCareTeam.getRouteWithParams({ teamId: team.id }), {
          state: { team, teamLeader } as CareTeamRegistrationContainerDataType,
        });
      };
    },
    [navigate]
  );

  return (
    <>
      {isLoading &&
        Array(3)
          .fill(1)
          .map((_, index) => (
            <Box
              key={index}
              sx={{
                marginInline: 1,
              }}
            >
              <CareTeamDoctorCardSkeleton hasActions={cardVariant === "newCareTeam"} />
            </Box>
          ))}
      {!careTeams?.length && !isLoading && (
        <Box className={classes.emptyPageContainer}>
          <EmptyMessageLayout
            icon={<EmptyDoctorsIcon />}
            message={t("No Care Teams found")}
            subMessage={
              cardVariant === "newCareTeam"
                ? t("Please try adjusting your search/filter to find what you are looking for.")
                : t("You are not connected to any care teams yet.")
            }
          />
        </Box>
      )}
      <Box ref={containerRef} className={classes.content}>
        {careTeams?.length > 0 && !isLoading && (
          <CustomInfiniteScroll
            dataLength={Number(careTeams?.length) || 0}
            onFetchMore={fetchMoreData}
            hasMore={Boolean(pageInfo?.hasNextPage)}
            loader={<CareTeamDoctorCardSkeleton hasActions={cardVariant === "newCareTeam"} />}
            height={(containerHeight || 10) - 15}
            onRefresh={handleRefresh}
          >
            {careTeams?.map(team => (
              <Box
                key={team.id}
                sx={{
                  marginInline: 1,
                }}
              >
                <CareTeamCard
                  onClick={cardVariant === "myCareTeam" ? handleNavigateToMyCareTeam(team) : undefined}
                  team={team}
                  hasActions={cardVariant === "newCareTeam"}
                />
              </Box>
            ))}
          </CustomInfiniteScroll>
        )}
      </Box>
    </>
  );
};
