import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConsumerMarketplaceOrderCancelMutationVariables = Types.Exact<{
  cancellationReason: Types.Scalars['String']['input'];
  marketplaceOrderCancelId: Types.Scalars['ID']['input'];
}>;


export type ConsumerMarketplaceOrderCancelMutation = { __typename?: 'Mutation', marketplaceOrderCancel?: { __typename?: 'MarketplaceOrder', id: string } | null };


export const ConsumerMarketplaceOrderCancelDocument = gql`
    mutation ConsumerMarketplaceOrderCancel($cancellationReason: String!, $marketplaceOrderCancelId: ID!) {
  marketplaceOrderCancel(
    cancellationReason: $cancellationReason
    id: $marketplaceOrderCancelId
  ) {
    id
  }
}
    `;
export type ConsumerMarketplaceOrderCancelMutationFn = Apollo.MutationFunction<ConsumerMarketplaceOrderCancelMutation, ConsumerMarketplaceOrderCancelMutationVariables>;

/**
 * __useConsumerMarketplaceOrderCancelMutation__
 *
 * To run a mutation, you first call `useConsumerMarketplaceOrderCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumerMarketplaceOrderCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumerMarketplaceOrderCancelMutation, { data, loading, error }] = useConsumerMarketplaceOrderCancelMutation({
 *   variables: {
 *      cancellationReason: // value for 'cancellationReason'
 *      marketplaceOrderCancelId: // value for 'marketplaceOrderCancelId'
 *   },
 * });
 */
export function useConsumerMarketplaceOrderCancelMutation(baseOptions?: Apollo.MutationHookOptions<ConsumerMarketplaceOrderCancelMutation, ConsumerMarketplaceOrderCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsumerMarketplaceOrderCancelMutation, ConsumerMarketplaceOrderCancelMutationVariables>(ConsumerMarketplaceOrderCancelDocument, options);
      }
export type ConsumerMarketplaceOrderCancelMutationHookResult = ReturnType<typeof useConsumerMarketplaceOrderCancelMutation>;
export type ConsumerMarketplaceOrderCancelMutationResult = Apollo.MutationResult<ConsumerMarketplaceOrderCancelMutation>;
export type ConsumerMarketplaceOrderCancelMutationOptions = Apollo.BaseMutationOptions<ConsumerMarketplaceOrderCancelMutation, ConsumerMarketplaceOrderCancelMutationVariables>;