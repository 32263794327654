import { makeStyles } from "@toolkit/ui";

export const useProductsCategoriesContainerStyle = makeStyles()(theme => ({
  categoriesContainer: {
    minHeight: "240px",
    width: "100%",
    display: "grid",
    alignItems: "start",
    height: "fit-content",
    gap: "5px",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateRows: "repeat(2, auto)",
  },

  seeAllButton: {
    background: `linear-gradient(212deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    width: "58px",
    height: "58px",
    "& svg": {
      width: "32px",
      height: "32px",
    },
    "& path": {
      fill: theme.palette.common.white,
    },
    boxShadow: theme.mixins.shadows.sm,
  },
  item__button: {
    width: "58px",
    height: "58px",
    "&& svg": {
      width: "30px",
      height: "30px",
    },
  },
  item__root: {
    width: "100%",
    minWidth: "30px",
    height: "min-content",
  },
  item__icon: {
    width: "30px",
    height: "30px",
  },
  item__title: {
    marginTop: "16px",
    wordBreak: "break-word",
    overflow: "break-word",
    hyphens: "manual",
  },
  skeletonItem__container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
  },
}));
