import { Box, Button, useAddToast } from "@toolkit/ui";
import { MedicationsOrderTrackingCardProps } from "../MedicationsOrderTrackingCard/MedicationsOrderTrackingCard";
import { FC } from "react";
import { useMedicationsOrderTrackingCardStyles } from "../MedicationsOrderTrackingCard/useMedicationsOrderTrackingCard.styles";
import { useCommonContainerStyles } from "@/shared/styles";
import { CAN_CANCEL_ORDER_STATUSES } from "../../others/constants";
import { useTranslation } from "@toolkit/i18n";
import { isOrderInFinalState } from "../../others/utils";
import { useNavigate } from "react-router-dom";
import { orderMedicationsRoutes } from "../../routes";
import { useOrderCancelMutation } from "../../gql/mutations";
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { ConsumerMedicationsOrdersDocument } from "../../gql/queries";
import { ConfirmationDialog } from "@/shared/components";

export const MedicationsOrderTrackingCardContentButtons: FC<MedicationsOrderTrackingCardProps> = props => {
  const { order } = props;
  const { classes, cx } = useMedicationsOrderTrackingCardStyles();
  const { classes: commonClasses } = useCommonContainerStyles();

  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const { failed } = useAddToast();
  const canCancelOrder = order?.status ? CAN_CANCEL_ORDER_STATUSES.includes(order?.status) : false;
  const hasShowMoreButton = order?.status ? !isOrderInFinalState(order?.status) : false;
  const { open, handleClose: onClose, handleOpen } = useOpenState();

  const handleShowMore = () => {
    navigate(
      orderMedicationsRoutes.prescriptionOrder.getRouteWithParams({
        orderId: order?.id || "",
        prescriptionId: order?.prescription?.id || "",
      })
    );
  };
  const [cancelOrder, { loading: cancelOrderLoading }] = useOrderCancelMutation({
    onCompleted: () => {
      onClose();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [ConsumerMedicationsOrdersDocument],
  });

  const disableButtons = cancelOrderLoading;

  const handleCancel = () => {
    order?.id &&
      cancelOrder({
        variables: {
          orderCancelId: order?.id,
        },
      });
  };

  return (
    <>
      {canCancelOrder && hasShowMoreButton && (
        <Box className={cx(classes.footerContainer, canCancelOrder && commonClasses.towButtonfooter)}>
          {hasShowMoreButton && (
            <Button onClick={handleShowMore} className={commonClasses.button}>
              {t("Show More")}
            </Button>
          )}
          {canCancelOrder && (
            <ConfirmationDialog
              variant='base'
              color='error'
              text={{
                title: t("Cancel Order"),
                body: t("Are you sure you want to cancel this order?"),
              }}
              isDoButtonDisabled={cancelOrderLoading}
              isOpen={open}
              handleClose={onClose}
              handleDo={handleCancel}
              DoTitle={t("Cancel Order")}
              button={
                <Button
                  onClick={handleOpen}
                  disabled={disableButtons}
                  className={cx(commonClasses.button)}
                  sx={{ paddingInline: 0 }}
                  variant='outlined'
                >
                  {t("Cancel Order")}
                </Button>
              }
            />
          )}
        </Box>
      )}
    </>
  );
};
