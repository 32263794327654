import { useEffect, useState } from "react";
import { ConnectionState, MediaStream, ZoomClientType } from "./types";

type ActiveSharePayload = {
  state: "Active" | "Inactive";
  userId: number;
};

export function useActiveShare({
  client,
  mediaStream,
  connectionState,
}: {
  client: ZoomClientType;
  mediaStream: MediaStream | null;
  connectionState: ConnectionState;
}) {
  const [activeShare, setActiveShare] = useState<Partial<ActiveSharePayload>>({});

  useEffect(() => {
    if (!mediaStream || connectionState !== ConnectionState.Connected) {
      return;
    }

    const onActiveShareChange = (payload: ActiveSharePayload) => {
      setActiveShare(payload);
    };
    const onShareContentChange = ({ userId }: { userId: number }) => {
      setActiveShare(p => ({ ...p, userId }));
    };

    const activeShareUserId = mediaStream.getActiveShareUserId();
    if (activeShareUserId) {
      onActiveShareChange({ state: "Active", userId: activeShareUserId });
    }

    client.on("active-share-change", onActiveShareChange);
    client.on("share-content-change", onShareContentChange);
    return () => {
      client.off("active-share-change", onActiveShareChange);
      client.off("share-content-change", onShareContentChange);
    };
  }, [client, mediaStream, connectionState]);

  return activeShare;
}
