import { Box, ValueWithLabel } from "@toolkit/ui";
import { FC } from "react";
import { MarketplaceOrderDetails } from "../../others/types";
import { InfoCardWithHeader } from "@/shared/components";
import { MarketPlacePaymentSummary } from "@/domains/marketplace/components/CartItemsPaymentSummary/MarketPlacePaymentSummary";
import { useTranslation } from "@toolkit/i18n";
import { useMarketplaceOrderGeneralInfoCardStyle } from "../MarketplaceOrderGeneralInfoCards/useMarketplaceOrderGeneralInfoCards.styles";
import { MoneyIcon } from "@/shared/icons";
import { useSiteSettingsGetQuery } from "@/gql";
import { formatCurrency } from "@/shared/utils";
import { useOrderDetailsCardStyles } from "./useOrderDetailsCardStyles.styles";

type OrderDetailsPaymentSummaryProps = {
  order: MarketplaceOrderDetails;
};

export const OrderDetailsPaymentSummary: FC<OrderDetailsPaymentSummaryProps> = props => {
  const { order } = props;
  const { classes } = useOrderDetailsCardStyles();
  const { classes: headerClasses } = useMarketplaceOrderGeneralInfoCardStyle();
  const { t } = useTranslation("consumer");
  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const currency = data?.siteSettings?.defaultCurrency;

  return (
    <InfoCardWithHeader
      mt={"10px"}
      classes={{
        header: classes.headerContainer,
      }}
      header={
        <>
          <Box className={headerClasses.iconContainer}>
            <MoneyIcon />
          </Box>
          <ValueWithLabel
            labelProps={{
              className: classes.headerMainLabel,
            }}
            hideColon
            label={t("Payment information")}
            value={
              <ValueWithLabel
                labelProps={{
                  className: classes.headerSecondaryLabel,
                }}
                valueProps={{
                  className: classes.headerMainLabel,
                }}
                label={t("Total to Pay")}
                value={formatCurrency(order?.totalNetAmountWithVAT, currency)}
                direction='row'
              />
            }
          />
        </>
      }
    >
      <MarketPlacePaymentSummary
        classes={{ root: classes.contentRoot }}
        totalDeliveryPrice={parseFloat(order?.totalDeliveryPrice)}
        totalDiscount={parseFloat(order?.totalDiscount)}
        totalPrice={parseFloat(order?.totalNetAmount)}
        totalPriceWithVAT={parseFloat(order?.totalNetAmountWithVAT)}
      />
    </InfoCardWithHeader>
  );
};
