import { ProductsContainer } from "@/domains/marketplace/containers/ProductsContainer/ProductsContainer";
import { Section } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { useMediaQuery, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { ProductInfoFragment } from "../../gql/fragments";

type RelatedProductsSectionProps = {
  products?: ProductInfoFragment[];
};

export const RelatedProductsSection: FC<RelatedProductsSectionProps> = props => {
  const { products } = props;

  const { t } = useTranslation("consumer");

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Section mt={3} mb={2} title={t("You May Also Like")}>
      <ProductsContainer products={products} variant='best-sellers' hideNavBtns={isSmall} />
    </Section>
  );
};
