import { useCallback } from "react";

import { ExecutedResponse } from "./types";
import { useZoom } from "./useZoom";
import { formatExecutedFailure, startShareScreen } from "./utils";

type MutedParam = boolean | ((muted: boolean) => boolean);

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useMuteScreen = () => {
  const { mediaStream, currentUser } = useZoom();
  const { userId, sharerOn } = currentUser || {};

  const muteScreen = useCallback(
    // eslint-disable-next-line max-statements
    async (value: MutedParam): Promise<ExecutedResponse> => {
      if (!userId) {
        return { type: "USER_NOT_CONNECTED" };
      }
      if (!mediaStream) {
        return { type: "CLIENT_NOT_READY" };
      }
      if (mediaStream.isShareLocked()) {
        return { type: "SHARE_LOCKED" };
      }

      try {
        const muted = typeof value === "function" ? value(!sharerOn) : value;
        if (muted && sharerOn) {
          const result = await mediaStream.stopShareScreen();
          if (result === "") {
            return { success: true };
          }

          return result;
        } else if (!muted && !sharerOn) {
          const result = await startShareScreen(mediaStream);
          if (result === "") {
            return { success: true };
          }

          return result;
        }
        return { success: true };
      } catch (error) {
        console.error("Meeting: Unable mute screen due to the error", error);
        return formatExecutedFailure(error);
      }
    },
    [userId, mediaStream, sharerOn]
  );

  const supportDisplayMediaAPI = "mediaDevices" in navigator && typeof navigator.mediaDevices.getDisplayMedia === "function";
  return { muteScreen: supportDisplayMediaAPI ? muteScreen : undefined, screenMuted: sharerOn !== true };
};
