import { LocationDisplay, LocationDisplayProps } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { AddressMapContainerRequiredNavigationDataType } from "../../types";
import { useAddressMapStyles } from "./AddressMapStyles";

type AddressMapProps = LocationDisplayProps;

export const AddressMap: FC<AddressMapProps> = props => {
  const { coordinates } = props;
  const navigate = useNavigate();
  const { t } = useTranslation("consumer");
  const actionButtonTitle = coordinates?.lat && coordinates?.lng ? "Modify" : "Add";
  const location = useLocation();

  const currentPath = location.pathname;

  const handleActionClick = () => {
    navigate(routes.map.getRouteWithParams({ coordinates }), {
      state: { callBackUrl: currentPath } as AddressMapContainerRequiredNavigationDataType,
      replace: true,
    });
  };

  const { classes } = useAddressMapStyles();

  return (
    <Box className={classes.root}>
      <LocationDisplay {...props} />
      <Button onClick={handleActionClick} className={classes.actionButton}>
        {t(actionButtonTitle)}
      </Button>
    </Box>
  );
};
