import { MyDocumentsContainer } from "./containers/DocumentContainer/MyDocumentsContainer";
import { UploadDocumentContainer } from "./containers/UploadDocumentContainer/UploadDocumentContainer";

export const MyDocumentsBaseRoute = "/my-documents";

export const routes = {
  myDocument: {
    route: "/",
    component: () => <MyDocumentsContainer />,
    get absRoute() {
      return `${MyDocumentsBaseRoute}${this.route}`;
    },
  },
  documentUpload: {
    route: "/upload",
    component: UploadDocumentContainer,
    get absRoute() {
      return `${MyDocumentsBaseRoute}${this.route}`;
    },
  },
};
