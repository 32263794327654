import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnbindDependentMutationVariables = Types.Exact<{
  input: Types.UnbindDependentInput;
}>;


export type UnbindDependentMutation = { __typename?: 'Mutation', unbindDependent?: { __typename?: 'UnbindDependent', accountErrors: Array<{ __typename?: 'AccountError', code: Types.AccountErrorCode, field?: string | null, message?: string | null }>, user?: { __typename?: 'User', id: string } | null } | null };


export const UnbindDependentDocument = gql`
    mutation UnbindDependent($input: UnbindDependentInput!) {
  unbindDependent(input: $input) {
    accountErrors {
      code
      field
      message
    }
    user {
      id
    }
  }
}
    `;
export type UnbindDependentMutationFn = Apollo.MutationFunction<UnbindDependentMutation, UnbindDependentMutationVariables>;

/**
 * __useUnbindDependentMutation__
 *
 * To run a mutation, you first call `useUnbindDependentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnbindDependentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unbindDependentMutation, { data, loading, error }] = useUnbindDependentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnbindDependentMutation(baseOptions?: Apollo.MutationHookOptions<UnbindDependentMutation, UnbindDependentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnbindDependentMutation, UnbindDependentMutationVariables>(UnbindDependentDocument, options);
      }
export type UnbindDependentMutationHookResult = ReturnType<typeof useUnbindDependentMutation>;
export type UnbindDependentMutationResult = Apollo.MutationResult<UnbindDependentMutation>;
export type UnbindDependentMutationOptions = Apollo.BaseMutationOptions<UnbindDependentMutation, UnbindDependentMutationVariables>;