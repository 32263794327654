import { Box, CustomInfiniteScroll, Grid, Typography } from "@toolkit/ui";
import { FC } from "react";
import { ProviderGuidedCareProgramTeamMember, TeamMemberPosition, Vendor } from "@/schema/types";
import { useTranslation } from "@/i18n/i18n.config";
import { DoctorAvatar } from "@/shared/components";
import { getMediaLink } from "@toolkit/core";
import { sortBy, last } from "lodash";
import { pickLocalizedValue } from "@toolkit/i18n";
import { CareJourneyCardSkeleton } from "../../component/CareJouneyCardSkeleton";
import { useStyles } from "./CareJourneyTeamsStyle";

export const CareJourneyTeams: FC<{ teamMembers: ProviderGuidedCareProgramTeamMember[]; provider: Vendor; isLoading: boolean }> = ({
  teamMembers,
  provider,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const careJourneyTeamMembers = sortBy(
    teamMembers?.filter(item => !item?.isBackup),
    item => item?.guidedCareProgramTeamMember?.position !== TeamMemberPosition.TeamLeader
  );
  return (
    <>
      <Box className={classes.container}>
        {isLoading &&
          !careJourneyTeamMembers?.length &&
          Array.from({ length: 3 }).map((_, index) => (
            <Box
              key={index}
              sx={{
                marginInline: 1,
              }}
            >
              <CareJourneyCardSkeleton />
            </Box>
          ))}
        <CustomInfiniteScroll height={"auto"} dataLength={Number(teamMembers?.length) || 1}>
          {
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {careJourneyTeamMembers?.map(teamMember => {
                const isTeamLeader = teamMember?.guidedCareProgramTeamMember?.position === TeamMemberPosition.TeamLeader;
                const mediaImageLink = getMediaLink(teamMember.user?.photo);
                const specialty = last(teamMember?.doctor?.specializations);
                return (
                  <Box key={teamMember?.id} className={classes.cardWrapper}>
                    <Grid className={classes.imageContainer}>
                      <DoctorAvatar imageUrl={mediaImageLink} />
                      {isTeamLeader && (
                        <div className={classes.leaderLabel}>
                          <Typography variant='caption'>{t("Team Leader")}</Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid container item xs={12} width={160}>
                      {teamMember?.user?.fullName && (
                        <Typography className={cx(classes.textAlign, classes.careTeamName)}>{teamMember?.user?.fullName}</Typography>
                      )}
                      <Typography className={cx(classes.textColor, classes.textAlign)}>
                        {pickLocalizedValue(specialty?.display, specialty?.arabicDisplay)}
                      </Typography>
                      <Typography className={cx(classes.textColor, classes.textAlign)}>
                        {pickLocalizedValue(provider?.name, provider?.nameAr)}
                      </Typography>
                    </Grid>
                  </Box>
                );
              })}
            </Box>
          }
        </CustomInfiniteScroll>
      </Box>
    </>
  );
};
