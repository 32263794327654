import { SvgIconProps, SvgIcon, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const EmptyCartIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props?.fill || theme.palette.primary.main;
  return (
    <SvgIcon {...props} id='products' width='137.035' height='133.545' viewBox='0 0 137.035 133.545'>
      <path
        id='Path_12746'
        d='M132.688,197.089c-15.5-3.5-29.485-9.039-42.367-18.878-12.759-9.808-24.473-23.828-24.935-38.186-.307-14.327,10.546-28.932,22.383-40.4a118.387,118.387,0,0,1,39.6-26.2c15.25-6.3,33.482-10.423,45.934-3.812,12.39,6.672,19,24.074,22.721,40.184,3.782,16.08,4.643,30.869,1.875,46.241s-9.193,31.207-20.938,38.432c-11.745,7.317-28.747,5.965-44.274,2.613'
        transform='translate(-63.908 -66.252)'
        fill={fill}
        opacity='0.09'
      />
      <path
        id='Path_12936'
        data-name='Path 12936'
        d='M198.5,214.1l8.793,53.5h61.676l4.151-45.473Z'
        transform='translate(-156.1 -168.643)'
        fill='#fff'
      />
      <path
        id='Path_12937'
        data-name='Path 12937'
        d='M389.536,269a3.268,3.268,0,0,1,2.952,3.536v.031l-2.214,22.014a2.113,2.113,0,0,1-2.337,1.875,2.151,2.151,0,0,1-1.906-2.091L386,272.259A3.241,3.241,0,0,1,389.259,269Z'
        transform='translate(-285.952 -206.664)'
        fill={fill}
      />
      <path
        id='Path_12938'
        data-name='Path 12938'
        d='M390.481,287.3l-2.829,30.991H371.388A22.987,22.987,0,0,1,390.481,287.3Z'
        transform='translate(-274.784 -219.338)'
        fill='#d3d9ed'
      />
      <path
        id='Path_12939'
        data-name='Path 12939'
        d='M174.677,148.59l2.613-2.613a.891.891,0,0,1,1.291,0h0a.891.891,0,0,1,0,1.291l-2.613,2.613a.891.891,0,0,1-1.291,0h0A.891.891,0,0,1,174.677,148.59Z'
        transform='translate(-139.41 -121.273)'
        fill='#525682'
      />
      <path
        id='Path_12940'
        data-name='Path 12940'
        d='M158.322,131.9h0a.906.906,0,0,1,.922.922v3.689a.906.906,0,0,1-.922.922h0a.906.906,0,0,1-.922-.922v-3.689A.946.946,0,0,1,158.322,131.9Z'
        transform='translate(-127.636 -111.716)'
        fill='#525682'
      />
      <path
        id='Path_12941'
        data-name='Path 12941'
        d='M186.122,170.1h3.689a.906.906,0,0,1,.922.922h0a.906.906,0,0,1-.922.922h-3.689a.906.906,0,0,1-.922-.922h0A.946.946,0,0,1,186.122,170.1Z'
        transform='translate(-146.889 -138.171)'
        fill='#525682'
      />
      <path
        id='Path_12942'
        data-name='Path 12942'
        d='M144.343,214.1H120.3l14.42,53.5h18.417Z'
        transform='translate(-101.943 -168.643)'
        fill='#fff'
      />
      <path
        id='Path_12943'
        data-name='Path 12943'
        d='M267.306,266.234H204.031L194.9,210.8l76.741,8.24ZM205.6,264.389h60.046l4-43.72-72.529-7.779Z'
        transform='translate(-153.607 -166.358)'
        fill='#525682'
      />
      <path
        id='Path_12944'
        data-name='Path 12944'
        d='M151.442,266.511h-20.2L116.3,211.2h26.041Zm-18.786-1.845H149.29L140.8,213.045H118.729Z'
        transform='translate(-99.173 -166.635)'
        fill='#525682'
      />
      <path
        id='Path_12945'
        data-name='Path 12945'
        d='M119.178,189.606H92.614L80.9,177.4h26.779Zm-25.8-1.845H114.9l-8.025-8.517H85.235Z'
        transform='translate(-74.657 -143.227)'
        fill='#525682'
      />
      <path
        id='Path_12946'
        data-name='Path 12946'
        d='M90.972,176.434H63.67a3.168,3.168,0,0,1,0-6.334h27.3a3.168,3.168,0,0,1,0,6.334Zm-27.3-4.489a1.314,1.314,0,0,0-1.261,1.384,1.331,1.331,0,0,0,1.261,1.261h27.3a1.314,1.314,0,0,0,1.261-1.384,1.331,1.331,0,0,0-1.261-1.261Z'
        transform='translate(-60.594 -138.171)'
        fill={fill}
      />
      <path
        id='Path_12947'
        data-name='Path 12947'
        d='M93.048,173.1h-27.3a2.246,2.246,0,0,0,0,4.489h27.3a2.246,2.246,0,0,0,0-4.489Z'
        transform='translate(-62.669 -140.249)'
        fill={fill}
      />
      <path
        id='Path_12948'
        data-name='Path 12948'
        d='M184.461,409.345H164.2V388.1h1.845v19.4h16.572V388.1h1.845Z'
        transform='translate(-132.346 -289.146)'
        fill='#525682'
      />
      <path
        id='Path_12949'
        data-name='Path 12949'
        d='M278.889,409.345H224.1V388.1h1.845v19.4h52.944Z'
        transform='translate(-173.829 -289.146)'
        fill='#525682'
      />
      <rect
        id='Rectangle_7039'
        data-name='Rectangle 7039'
        width='101.337'
        height='1.845'
        transform='translate(5.29 123.889)'
        fill='#525682'
      />
      <g id='Group_11172' data-name='Group 11172' transform='translate(25.398 112.82)'>
        <circle
          id='Ellipse_2905'
          data-name='Ellipse 2905'
          cx='5.534'
          cy='5.534'
          r='5.534'
          transform='translate(55.373 0.922)'
          fill='#525682'
        />
        <circle
          id='Ellipse_2906'
          data-name='Ellipse 2906'
          cx='1.998'
          cy='1.998'
          r='1.998'
          transform='translate(58.908 4.458)'
          fill='#fff'
        />
        <circle
          id='Ellipse_2907'
          data-name='Ellipse 2907'
          cx='5.534'
          cy='5.534'
          r='5.534'
          transform='translate(0.922 0.922)'
          fill='#525682'
        />
        <circle id='Ellipse_2908' data-name='Ellipse 2908' cx='1.998' cy='1.998' r='1.998' transform='translate(4.458 4.458)' fill='#fff' />
        <path
          id='Path_12950'
          data-name='Path 12950'
          d='M149.657,446.113a6.457,6.457,0,1,1,6.457-6.457,6.456,6.456,0,0,1-6.457,6.457Zm0-11.068a4.612,4.612,0,1,0,4.612,4.612,4.606,4.606,0,0,0-4.612-4.612Z'
          transform='translate(-143.2 -433.2)'
          fill='#525682'
        />
        <path
          id='Path_12951'
          data-name='Path 12951'
          d='M326.757,446.113a6.457,6.457,0,1,1,6.457-6.457,6.456,6.456,0,0,1-6.457,6.457Zm0-11.068a4.612,4.612,0,1,0,4.612,4.612,4.606,4.606,0,0,0-4.612-4.612Z'
          transform='translate(-265.85 -433.2)'
          fill='#525682'
        />
        <circle
          id='Ellipse_2909'
          data-name='Ellipse 2909'
          cx='5.534'
          cy='5.534'
          r='5.534'
          transform='translate(20.261 0.922)'
          fill='#525682'
        />
        <circle
          id='Ellipse_2910'
          data-name='Ellipse 2910'
          cx='5.534'
          cy='5.534'
          r='5.534'
          transform='translate(74.128 0.922)'
          fill='#525682'
        />
        <path
          id='Path_12952'
          data-name='Path 12952'
          d='M212.557,446.113a6.457,6.457,0,1,1,6.457-6.457,6.456,6.456,0,0,1-6.457,6.457Zm0-11.068a4.612,4.612,0,1,0,4.612,4.612,4.606,4.606,0,0,0-4.612-4.612Z'
          transform='translate(-186.761 -433.2)'
          fill='#525682'
        />
        <path
          id='Path_12953'
          data-name='Path 12953'
          d='M387.757,446.113a6.457,6.457,0,1,1,6.457-6.457,6.456,6.456,0,0,1-6.457,6.457Zm0-11.068a4.612,4.612,0,1,0,4.612,4.612,4.606,4.606,0,0,0-4.612-4.612Z'
          transform='translate(-308.095 -433.2)'
          fill='#525682'
        />
        <circle
          id='Ellipse_2911'
          data-name='Ellipse 2911'
          cx='1.998'
          cy='1.998'
          r='1.998'
          transform='translate(23.766 4.458)'
          fill='#fff'
        />
        <circle
          id='Ellipse_2912'
          data-name='Ellipse 2912'
          cx='1.998'
          cy='1.998'
          r='1.998'
          transform='translate(77.663 4.458)'
          fill='#fff'
        />
      </g>
      <path
        id='Path_12954'
        data-name='Path 12954'
        d='M253.4,255.954a3.75,3.75,0,0,1,3.413,3.751v25.611a2.154,2.154,0,0,1-4.274.277l-3.2-25.4a3.79,3.79,0,0,1,3.259-4.212A2.3,2.3,0,0,1,253.4,255.954Z'
        transform='translate(-191.289 -197.615)'
        fill={fill}
      />
      <path
        id='Path_12955'
        data-name='Path 12955'
        d='M324.211,263.254a3.781,3.781,0,0,1,3.413,4.089h0L325.5,290.557a2.152,2.152,0,0,1-4.3-.061l-1.107-23.244a3.8,3.8,0,0,1,3.566-3.966A1.164,1.164,0,0,1,324.211,263.254Z'
        transform='translate(-240.305 -202.671)'
        fill={fill}
      />
      <path
        id='Path_12956'
        data-name='Path 12956'
        d='M252.014,145.757v-1.845a6.886,6.886,0,0,0,1.6-.092l.461,1.783A7.671,7.671,0,0,1,252.014,145.757Zm-3.075,0H247.1v-1.845h1.845Zm8.148-2.244-1.507-1.045a3.568,3.568,0,0,0,.553-1.322l1.814.307a5.545,5.545,0,0,1-.861,2.06Zm-1.414-4.7a3.537,3.537,0,0,0-.984-1.045l1.045-1.537a5.847,5.847,0,0,1,1.537,1.6Zm-3.382-1.63h-1.845v-1.845h1.845Zm-4.919,0a10.756,10.756,0,0,1-2-.092l.338-1.814a10.246,10.246,0,0,0,1.691.061v1.845Zm-5.165-1.783a5.49,5.49,0,0,1-1.2-1.814l1.691-.707a3.746,3.746,0,0,0,.83,1.261Zm.307-4.981-1.814-.4a6.064,6.064,0,0,1,.861-2l1.537,1.045a3.553,3.553,0,0,0-.584,1.353Zm2.521-2.89-.676-1.722a6.288,6.288,0,0,1,2.121-.369v1.845a4.754,4.754,0,0,0-1.445.246Zm19.339-.246v-1.845a10,10,0,0,0,1.66-.061l.369,1.814a7.553,7.553,0,0,1-2.029.092Zm-3.075,0h-1.845v-1.845H261.3Zm-4.919,0h-1.845v-1.845h1.845Zm-4.919,0h-1.845v-1.845h1.845Zm18.017-2.06L268,124.112a3.344,3.344,0,0,0,.615-1.291l1.814.4A5.455,5.455,0,0,1,269.478,125.219Zm-.8-5.288c0-.031-.154-.031,1.845-.031Z'
        transform='translate(-185.325 -103.406)'
        fill='#a9b4d3'
      />
      <path
        id='Path_12957'
        data-name='Path 12957'
        d='M334.7,106.735a4.33,4.33,0,0,1,4.335-4.335h3.351a4.33,4.33,0,0,1-4.335,4.335H334.7Z'
        transform='translate(-250.424 -91.286)'
        fill='#fff'
      />
      <path
        id='Path_12958'
        data-name='Path 12958'
        d='M317.386,106.735a4.33,4.33,0,0,0-4.335-4.335H309.7a4.33,4.33,0,0,0,4.335,4.335h3.351Z'
        transform='translate(-233.111 -91.286)'
        fill='#fff'
      />
      <path
        id='Path_12959'
        data-name='Path 12959'
        d='M335.874,105.58H331.6v-.922a5.255,5.255,0,0,1,5.257-5.257h4.274v.922A5.216,5.216,0,0,1,335.874,105.58Zm-2.275-1.845H335.9a3.459,3.459,0,0,0,3.321-2.49h-2.306A3.4,3.4,0,0,0,333.6,103.735Z'
        transform='translate(-248.278 -89.208)'
        fill={fill}
      />
      <path
        id='Path_12960'
        data-name='Path 12960'
        d='M316.231,105.58h-4.274a5.255,5.255,0,0,1-5.258-5.257V99.4h4.274a5.255,5.255,0,0,1,5.257,5.257Zm-7.563-4.335a3.422,3.422,0,0,0,3.29,2.49h2.306a3.422,3.422,0,0,0-3.29-2.49Z'
        transform='translate(-231.033 -89.208)'
        fill={fill}
      />
      <path
        id='Path_12961'
        data-name='Path 12961'
        d='M406.443,104.417a4.361,4.361,0,0,1,0-6.149h0l2.367-2.367a4.361,4.361,0,0,1,0,6.149h0Z'
        transform='translate(-299.232 -86.785)'
        fill='#fff'
      />
      <path
        id='Path_12962'
        data-name='Path 12962'
        d='M390.116,120.743a4.361,4.361,0,0,0-6.149,0h0l-2.367,2.367a4.361,4.361,0,0,0,6.149,0h0Z'
        transform='translate(-282.905 -103.111)'
        fill='#fff'
      />
      <path
        id='Path_12963'
        data-name='Path 12963'
        d='M404.366,102.8l-.646-.646a5.252,5.252,0,0,1,0-7.44h0l3.013-3.013.646.646a5.252,5.252,0,0,1,0,7.44h0Zm2.275-8.394-1.63,1.63a3.4,3.4,0,0,0-.553,4.089l1.63-1.63a3.4,3.4,0,0,0,.553-4.089Z'
        transform='translate(-297.154 -83.876)'
        fill={fill}
      />
      <path
        id='Path_12964'
        data-name='Path 12964'
        d='M378.146,121.679l-.646-.646,3.013-3.013a5.252,5.252,0,0,1,7.44,0h0l.646.646-3.013,3.013a5.252,5.252,0,0,1-7.44,0Zm2.029-.738a3.444,3.444,0,0,0,4.089-.553l1.629-1.63a3.444,3.444,0,0,0-4.089.553Z'
        transform='translate(-280.065 -101.034)'
        fill={fill}
      />
      <path
        id='Path_12965'
        data-name='Path 12965'
        d='M375.367,155.993l-.031-1.845a3.929,3.929,0,0,0,1.445-.307l.738,1.691A5.316,5.316,0,0,1,375.367,155.993Zm-28.839-.922h-1.845v-1.845h1.845Zm25.4-.123a6.093,6.093,0,0,1-1.507-1.568l1.537-.984a4.228,4.228,0,0,0,1.015,1.076Zm8.271-1.783-1.6-.922a3.734,3.734,0,0,0,.492-1.384l1.814.277a5.719,5.719,0,0,1-.707,2.029Zm-33.666-3.013h-1.845a7.679,7.679,0,0,1,.184-2.06l1.783.461a7.012,7.012,0,0,0-.123,1.6Zm24.812-.215h-1.845a5.128,5.128,0,0,0-.154-1.537l1.753-.553A6.18,6.18,0,0,1,371.339,149.937Zm9.623-2.06h-1.845v-1.845h1.845Zm-32.99-1.384-1.168-1.445a5.849,5.849,0,0,1,1.937-1.015l.553,1.752A4.958,4.958,0,0,0,347.972,146.493Zm19.954-.092a3.779,3.779,0,0,0-1.322-.646l.461-1.783a5.322,5.322,0,0,1,1.968.953Zm-4.181-.769H361.9v-1.845h1.845Zm-4.919,0h-1.845v-1.845h1.845Zm-4.919,0h-1.845v-1.845h1.845Zm27.056-2.675h-1.845v-1.845h1.845Zm0-4.919h-1.845v-1.845h1.845Zm-2.029-4.612a4.068,4.068,0,0,0-.707-1.291l1.414-1.168a5.785,5.785,0,0,1,1.045,1.906Zm-2.767-2.583a5.749,5.749,0,0,0-1.63-.123v-1.845a7.766,7.766,0,0,1,2.06.154Zm-4.673-.123h-1.845v-1.845h1.845Z'
        transform='translate(-257.333 -109.614)'
        fill='#a9b4d3'
      />
      <path
        id='Path_12966'
        data-name='Path 12966'
        d='M127.8,369.6a2.8,2.8,0,0,1,0-5.6,2.8,2.8,0,0,1,2,4.766A2.813,2.813,0,0,1,127.8,369.6Zm0-3.751a.953.953,0,1,0,.953.953h0A.958.958,0,0,0,127.8,365.845Z'
        transform='translate(-105.198 -272.456)'
        fill='#525682'
      />
      <path
        id='Path_12967'
        data-name='Path 12967'
        d='M93.743,412.286a3.843,3.843,0,1,1,3.843-3.874h0a3.851,3.851,0,0,1-3.843,3.874Zm0-5.811a2,2,0,1,0,2,2h0A2.035,2.035,0,0,0,93.743,406.475Z'
        transform='translate(-80.89 -300.573)'
        fill='#525682'
      />
      <circle
        id='Ellipse_2913'
        data-name='Ellipse 2913'
        cx='18.048'
        cy='18.048'
        r='18.048'
        transform='translate(100.017 72.759)'
        fill='#f5f5f5'
      />
      <path
        id='Path_12968'
        data-name='Path 12968'
        d='M401.87,337.84a18.97,18.97,0,1,1,18.97-18.97h0A19,19,0,0,1,401.87,337.84Zm0-36.1A17.125,17.125,0,1,0,419,318.87h0A17.11,17.11,0,0,0,401.87,301.745Z'
        transform='translate(-283.805 -228.064)'
        fill='#525682'
      />
      <rect
        id='Rectangle_7040'
        data-name='Rectangle 7040'
        width='20.292'
        height='6.887'
        transform='translate(108.397 95.454) rotate(-45)'
        fill={fill}
      />
      <rect
        id='Rectangle_7041'
        data-name='Rectangle 7041'
        width='6.887'
        height='20.292'
        transform='translate(108.397 85.977) rotate(-45)'
        fill={fill}
      />
    </SvgIcon>
  );
};
