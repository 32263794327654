import { ChatAttachment, ChatAttachmentInput } from "../../schema/types";
import { useTranslation } from "@toolkit/i18n";
import { IconButton, Typography, makeStyles, DocumentIcon, MuiCloudDownloadIcon } from "@toolkit/ui";
import { FC, useCallback, useMemo } from "react";
import { Box } from "@toolkit/ui";
import { useFileDownloader } from "@toolkit/core";

const useStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    userSelect: "none",
    width: "100%",
    gap: 4,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginHorizontal: 8,
    overflow: "hidden",
    "& .MuiTypography-root": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
}));

type ChatViewMediaDocumentProps = {
  media: ChatAttachment | ChatAttachmentInput;
  icon?: JSX.Element;
};

export const ChatViewMediaDocument: FC<ChatViewMediaDocumentProps> = ({ media, icon }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { url, type } = media;
  const fileName = url.split("/").pop();
  const documentName = useMemo(() => {
    if (type) {
      if (/^image\//.test(type)) {
        return t("Photo");
      } else if (/^audio\//.test(type)) {
        return t("Audio");
      } else if (/^video\//.test(type)) {
        return t("Video");
      }
    }

    return t("Document");
  }, [type, t]);

  const { download } = useFileDownloader();
  const handleDownload = useCallback(() => download(url), [download, url]);

  return (
    <Box className={classes.root}>
      {icon ?? <DocumentIcon sx={{ fontSize: 42 }} />}
      <Box className={classes.content}>
        <Typography variant='subtitle1'>{documentName}</Typography>
        <Typography variant='caption' color='textSecondary'>
          {fileName}
        </Typography>
      </Box>

      <Box display='flex' flexGrow='1'></Box>
      <IconButton onClick={handleDownload} size='large'>
        <MuiCloudDownloadIcon fontSize='medium' />
      </IconButton>
    </Box>
  );
};
