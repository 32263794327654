import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import React from "react";

export const ExcellentModeIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='icons8-disappointed'
      width='32.488'
      height='32.488'
      viewBox='0 0 32.488 32.488'
      {...props}
    >
      <defs>
        <linearGradient id='linear-gradient' x1='-15.049' y1='0.999' x2='-14.347' y2='0.297' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#fede00' />
          <stop offset='0.519' stopColor='#fecc00' />
          <stop offset='1' stopColor='#ffb700' />
        </linearGradient>
        <linearGradient id='linear-gradient-2' x1='0.5' y1='1.551' x2='0.5' y2='0.252' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#f05964' />
          <stop offset='1' stopColor='#d4414c' />
        </linearGradient>
      </defs>
      <path
        id='Path_28366'
        data-name='Path 28366'
        d='M20.244,36.488A16.244,16.244,0,1,0,4,20.244,16.245,16.245,0,0,0,20.244,36.488Z'
        transform='translate(-4 -4)'
        fill='url(#linear-gradient)'
      />
      <circle id='Ellipse_3515' data-name='Ellipse 3515' cx='3.5' cy='3.5' r='3.5' transform='translate(6 11)' fill='#fff' />
      <circle id='Ellipse_3516' data-name='Ellipse 3516' cx='3.5' cy='3.5' r='3.5' transform='translate(19 11)' fill='#fff' />
      <circle id='Ellipse_3517' data-name='Ellipse 3517' cx='1.5' cy='1.5' r='1.5' transform='translate(8 13)' fill='#343434' />
      <circle id='Ellipse_3518' data-name='Ellipse 3518' cx='1.5' cy='1.5' r='1.5' transform='translate(21 13)' fill='#343434' />
      <path
        id='Path_28367'
        data-name='Path 28367'
        d='M22.5,37.31h0a6.5,6.5,0,0,1-6.5-6.5h0A.812.812,0,0,1,16.812,30H28.183a.812.812,0,0,1,.812.812h0A6.5,6.5,0,0,1,22.5,37.31Z'
        transform='translate(-6.254 -8.883)'
        fill='#343434'
      />
      <path
        id='Path_28368'
        data-name='Path 28368'
        d='M27.37,31.624H18.376A.376.376,0,0,1,18,31.248V30h9.746v1.248A.376.376,0,0,1,27.37,31.624Z'
        transform='translate(-6.629 -8.883)'
        fill='#fff'
      />
      <path
        id='Path_28369'
        data-name='Path 28369'
        d='M19.208,36.945a6.462,6.462,0,0,0,7.784,0,4.866,4.866,0,0,0-7.784,0Z'
        transform='translate(-6.856 -9.822)'
        fill='url(#linear-gradient-2)'
      />
    </SvgIcon>
  );
};
