import { GenericFilterForm } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { FormNumberField } from "@toolkit/ui";
import {
  healthPackagesListFiltersFormDefaultValues,
  HealthPackagesListFiltersFormSchema,
  IHealthPackagesListFiltersFormValues,
} from "../HealthPackagesListFiltersFormSchema";
import { HealthPackagesListFiltersFormProps } from "../types";

export const AgeFilterForm: React.FC<HealthPackagesListFiltersFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IHealthPackagesListFiltersFormValues>
      schema={HealthPackagesListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='age'
      dialogTitle={t("Age")}
      formLabel={t("Age")}
      render={name => <FormNumberField name={name} label={t("Age")} />}
      defaultValues={healthPackagesListFiltersFormDefaultValues}
    />
  );
};
