import { FC } from "react";
import { Box, Button, CallIcon, CircularProgress, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useCallErrorStyles } from "./CallError.styles";

type CallErrorProps = {
  onClick?: () => void;
  isLoading?: boolean;
  hasError?: boolean;
  errorMessage?: string;
};

export const CallError: FC<CallErrorProps> = ({ isLoading, hasError, errorMessage, onClick }) => {
  const { t } = useTranslation("provider");
  const { classes, theme } = useCallErrorStyles();

  return (
    <Box className={classes.root}>
      {isLoading ? (
        <CircularProgress color='primary' size={50} />
      ) : (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={2}>
          <Box className={classes.icon}>
            <CallIcon color='inherit' sx={{ width: 40, height: 40 }} />
          </Box>
          <Typography fontSize={theme.mixins.fonts.fontSize.xxl}>{hasError ? t("Meeting Info Error") : t("Meeting")}</Typography>
          {!!errorMessage && <Typography fontSize={theme.mixins.fonts.fontSize.md}>{errorMessage}</Typography>}
          {onClick && <Button onClick={onClick}>{t("Retry")}</Button>}
        </Box>
      )}
    </Box>
  );
};
