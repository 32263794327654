import { makeStyles } from "@toolkit/ui";

export const useAppBarFooterStyle = makeStyles()(theme => ({
  root: {
    height: "70px",
    fontSize: "24px",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    maxWidth: theme.breakpoints.values.sm,
    boxShadow: theme.mixins.shadows.xs,
    position: "relative",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "78px",
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    "&&:before": {
      content: "''",
      borderRadius: "50%",
      width: "75px",
      height: "75px",
      position: "absolute",
      top: "-20px",
      left: "calc(50% - 37.5px)",
      boxShadow: theme.mixins.shadows.xs,
      backgroundColor: theme.palette.background.paper,
    },
  },
  brandButtonContainer: {
    width: "90px",
    height: "70px",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    left: "calc(50% - 45px)",
  },
  brandIconButton: {
    position: "absolute",
    left: "calc(50% - 37.5px)",
    top: "-20px",
    width: "75px",
    height: "75px",
    color: theme.palette.primary[700],
    backgroundColor: theme.palette.background.paper,
    borderRadius: "50%",
    "& svg": {
      width: "50px",
      height: "50px",
      marginTop: "0px",
      marginInlineEnd: theme.direction === "ltr" ? "5px" : "-5px",
    },
  },
  iconButton: {
    position: "relative",
    top: "9px",
    maxWidth: "60px",
    width: "100%",
    height: "60px",
    padding: "0px",
    "& svg": {
      height: "24px",
      width: "24px",
      fill: theme.palette.text.primary,
      position: "relative",
      top: "-7px",
    },
    color: theme.palette.primary[700],
  },
  rowContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  text: {
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    fontSize: theme.mixins.fonts.fontSize.xs,
    padding: "0px",
    margin: "0px",
    position: "relative",
    top: "-15px",
  },
}));
