import { makeStyles } from "@toolkit/ui";

export const useDigitalTwinBmrTdeeCalculatorResultStyle = makeStyles()(theme => {
  return {
    root: {
      padding: 8,
    },
    title: {
      fontSize: theme.mixins.fonts.fontSize.lg,
      fontWeight: theme.mixins.fonts.fontWeight.medium,
      alignSelf: "flex-start",
    },
    boldTitle: {
      fontWeight: theme.mixins.fonts.fontWeight.medium,
      alignSelf: "flex-start",
    },
    sectionTitle: {
      marginTop: 8,
      fontWeight: theme.mixins.fonts.fontWeight.medium,
      fontSize: theme.mixins.fonts.fontSize.md,
      alignSelf: "center",
      textAlign: "center",
    },
  };
});
