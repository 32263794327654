import { useFileDownloader } from "@toolkit/core";
import { ChatAttachment, ChatAttachmentInput } from "../../schema/types";
import { useCallback, useState } from "react";

export const useChatViewMediaPhoto = (media: ChatAttachment | ChatAttachmentInput) => {
  const { url } = media;
  const { download } = useFileDownloader();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const onDownload = useCallback(() => download(media.url), [download, media.url]);
  const onLoad = useCallback(() => setLoading(false), []);
  const onError = useCallback(() => {
    setError(true);
    setLoading(false);
  }, []);

  return {
    loading,
    error,
    onDownload,
    onLoad,
    onError,
    src: url,
    width: undefined,
    height: undefined,
  };
};
