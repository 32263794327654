import { makeStyles } from "@toolkit/ui";

export const useMarketplaceItemReviewsStyle = makeStyles()(theme => ({
  root: {
    borderTop: "1px solid",
    borderColor: theme.palette.divider,
    display: "flex",
    paddingBlock: "20px",
    gap: "24px",
  },
  startContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    gap: "7px",
  },
  endContainer: {
    flex: 2.5,
    display: "flex",
  },
  ratingIcon: {
    padding: "2.5px",
    "& svg": {
      width: "17px",
      height: "17px",
    },
  },
  userName: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    color: theme.palette.text.primary,
    display: "inline-block",
  },
  comment: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    color: theme.palette.text.primary,
    whiteSpace: "pre-wrap",
    lineHeight: 2,
  },
  showMoreText: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.text.primary,
    display: "inline-block",
    cursor: "pointer",
  },
}));
