import { HorizontalScroller } from "@/shared/components";
import { pickLocalizedValue } from "@toolkit/i18n";
import { HealthServicesIcon, useMediaQuery } from "@toolkit/ui";
import { truncate } from "lodash";
import { FC } from "react";
import { ProductsCategoriesContainerSkeleton } from "../../containers/ProductsCategoriesContainer/ProductsCategoriesContainerSkeleton";
import { MarketplaceCategoryItemButton } from "../MarketplaceCategoryItemButton/MarketplaceCategoryItemButton";
import { useStyles } from "./CategoriesFiltersStyles";
import { useCategoriesFilters } from "./useCategoriesFilters";

type CategoriesFiltersProps = {
  onClick?: (categoryId?: string) => void;
  selectedCategories?: string[];
  categoryType: "products" | "healthPackages";
};

export const CategoriesFilters: FC<CategoriesFiltersProps> = ({ selectedCategories, onClick, categoryType }) => {
  const isItemSelected = (id?: string) =>
    id === undefined && (!selectedCategories || selectedCategories.length === 0) ? true : selectedCategories?.includes(id || "");

  const { classes, theme } = useStyles();
  const { categories, hasMore, fetchMoreData } = useCategoriesFilters(categoryType);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <HorizontalScroller
      classes={{ root: classes.root }}
      data={categories}
      hideNavBtns={isSmallScreen}
      scrollAmount={6}
      hasNextPage={hasMore}
      loadNextPage={fetchMoreData}
      itemWidth={80}
      itemRenderer={(category, index, isScrolling) =>
        category && !isScrolling ? (
          <MarketplaceCategoryItemButton
            key={index}
            onClick={() => onClick?.(category?.id)}
            isSelected={isItemSelected(category?.id)}
            classes={{
              root: classes.item__root,
              title: classes.item__title,
              button: classes.item__button,
            }}
            title={truncate(pickLocalizedValue(category.name, category?.nameAr) || "", { length: 20 })}
            imgSrc={category.image}
            DefaultSvg={categoryType === "healthPackages" ? HealthServicesIcon : undefined}
          />
        ) : (
          <ProductsCategoriesContainerSkeleton countOfCategories={0} />
        )
      }
    />
  );
};
