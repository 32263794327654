import { useHeaderContext } from "@/shared/context";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { IGoogleMapAddress } from "@toolkit/maps";
import { Box, Button } from "@toolkit/ui";
import queryString from "query-string";
import { FC, useEffect, useMemo, useRef } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { AddressMapWithSearch, AddressMapWithSearchRef } from "../../components/AddressMapWithSearch/AddressMapWithSearch";
import { AddressMapContainerRequiredNavigationDataType, AddressMapRedirectDataType } from "../../types";

export const AddressMapContainer: FC = () => {
  const { classes } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const mapRef = useRef<AddressMapWithSearchRef>(null);
  const location: Location<AddressMapContainerRequiredNavigationDataType> = useLocation();
  const { callBackUrl } = location.state || {};
  const navigate = useNavigate();

  const handleLocationChange = (address: IGoogleMapAddress) => {
    if (callBackUrl) {
      navigate(callBackUrl, { state: { address } as AddressMapRedirectDataType, replace: true });
    }
  };

  const params = queryString.parse(location.search) as { lng?: string; lat?: string };
  const address = useMemo(() => {
    if (params.lng && params.lat) {
      return {
        coordinates: {
          lat: parseFloat(params.lat),
          lng: parseFloat(params.lng),
        },
      };
    }
    return undefined;
  }, [params.lat, params.lng]);

  const handleSubmit = () => {
    mapRef.current?.submit();
  };

  const { setHeaderTitle } = useHeaderContext();
  useEffect(() => {
    setHeaderTitle(t("Select Location"));
    return () => {
      setHeaderTitle("");
    };
  }, [setHeaderTitle, t]);

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <AddressMapWithSearch address={address} onLocationChange={handleLocationChange} ref={mapRef} />
      </Box>
      <Box className={classes.footer}>
        <Button onClick={handleSubmit} className={classes.button}>
          {t("Confirm")}
        </Button>
      </Box>
    </Box>
  );
};
