import { useHeaderContext } from "@/shared/context";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import queryString from "query-string";
import { FC, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { AddressMapDisplay } from "../../components/AddressMapDisplay/AddressMapDisplay";
import { googleMapsUrl } from "../../utils";

export const AddressMapDisplayContainer: FC = () => {
  const { classes } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const location = useLocation();

  const params = queryString.parse(location.search) as { lng?: string; lat?: string };
  const address = useMemo(() => {
    if (params.lng && params.lat) {
      return {
        coordinates: {
          lat: parseFloat(params.lat),
          lng: parseFloat(params.lng),
        },
      };
    }
    return undefined;
  }, [params.lat, params.lng]);

  const { setHeaderTitle } = useHeaderContext();
  useEffect(() => {
    setHeaderTitle(t("Location on Map"));
    return () => {
      setHeaderTitle("");
    };
  }, [setHeaderTitle, t]);

  const handleViewOnGoogleMaps = () => {
    if (!address?.coordinates) return;
    const { lat, lng } = address.coordinates;
    const url = googleMapsUrl(lat, lng, "directions");

    if (url) window.open(url, "_blank");
  };

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <AddressMapDisplay coordinates={address?.coordinates} />
      </Box>
      <Box className={classes.footer}>
        <Button className={classes.button} onClick={handleViewOnGoogleMaps}>
          {t("Get Directions")}
        </Button>
      </Box>
    </Box>
  );
};
