import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReasonsQueryVariables = Types.Exact<{
  isCancellation: Types.Scalars['Boolean']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  searchParameters?: Types.InputMaybe<Array<Types.InputMaybe<Types.SearchParameter>> | Types.InputMaybe<Types.SearchParameter>>;
  showOnlyVisibleProperties?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ReasonsQuery = { __typename?: 'Query', cancellationReasons?: { __typename?: 'CodeSystemConceptConnection', edges?: Array<{ __typename?: 'CodeSystemConceptEdge', node?: { __typename: 'CodeSystemConcept', id?: string | null, code?: string | null, display?: string | null } | null } | null> | null } | null, rejectionReasons?: { __typename?: 'CodeSystemConceptConnection', edges?: Array<{ __typename?: 'CodeSystemConceptEdge', node?: { __typename: 'CodeSystemConcept', id?: string | null, code?: string | null, display?: string | null } | null } | null> | null } | null };


export const ReasonsDocument = gql`
    query Reasons($isCancellation: Boolean!, $after: String, $before: String, $first: Int, $last: Int, $searchParameters: [SearchParameter], $showOnlyVisibleProperties: Boolean) {
  cancellationReasons: getCodeSystemConceptsByCode(
    after: $after
    before: $before
    first: $first
    last: $last
    searchParameters: $searchParameters
    showOnlyVisibleProperties: $showOnlyVisibleProperties
    codeSystemCode: CANCELLATION_REASON
  ) @include(if: $isCancellation) {
    edges {
      node {
        id
        code
        display
        __typename
      }
    }
  }
  rejectionReasons: getCodeSystemConceptsByCode(
    after: $after
    before: $before
    first: $first
    last: $last
    searchParameters: $searchParameters
    showOnlyVisibleProperties: $showOnlyVisibleProperties
    codeSystemCode: REJECTION_REASON
  ) @skip(if: $isCancellation) {
    edges {
      node {
        id
        code
        display
        __typename
      }
    }
  }
}
    `;

/**
 * __useReasonsQuery__
 *
 * To run a query within a React component, call `useReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReasonsQuery({
 *   variables: {
 *      isCancellation: // value for 'isCancellation'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      searchParameters: // value for 'searchParameters'
 *      showOnlyVisibleProperties: // value for 'showOnlyVisibleProperties'
 *   },
 * });
 */
export function useReasonsQuery(baseOptions: Apollo.QueryHookOptions<ReasonsQuery, ReasonsQueryVariables> & ({ variables: ReasonsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReasonsQuery, ReasonsQueryVariables>(ReasonsDocument, options);
      }
export function useReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReasonsQuery, ReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReasonsQuery, ReasonsQueryVariables>(ReasonsDocument, options);
        }
export function useReasonsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReasonsQuery, ReasonsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReasonsQuery, ReasonsQueryVariables>(ReasonsDocument, options);
        }
export type ReasonsQueryHookResult = ReturnType<typeof useReasonsQuery>;
export type ReasonsLazyQueryHookResult = ReturnType<typeof useReasonsLazyQuery>;
export type ReasonsSuspenseQueryHookResult = ReturnType<typeof useReasonsSuspenseQuery>;
export type ReasonsQueryResult = Apollo.QueryResult<ReasonsQuery, ReasonsQueryVariables>;