import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DigitalTwinPatientInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DigitalTwinPatientInfoQuery = { __typename?: 'Query', profile?: { __typename?: 'User', id: string, gender?: Types.UserGender | null, dateOfBirth?: any | null } | null };


export const DigitalTwinPatientInfoDocument = gql`
    query DigitalTwinPatientInfo {
  profile: me {
    id
    gender
    dateOfBirth
    gender
  }
}
    `;

/**
 * __useDigitalTwinPatientInfoQuery__
 *
 * To run a query within a React component, call `useDigitalTwinPatientInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalTwinPatientInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalTwinPatientInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useDigitalTwinPatientInfoQuery(baseOptions?: Apollo.QueryHookOptions<DigitalTwinPatientInfoQuery, DigitalTwinPatientInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DigitalTwinPatientInfoQuery, DigitalTwinPatientInfoQueryVariables>(DigitalTwinPatientInfoDocument, options);
      }
export function useDigitalTwinPatientInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DigitalTwinPatientInfoQuery, DigitalTwinPatientInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DigitalTwinPatientInfoQuery, DigitalTwinPatientInfoQueryVariables>(DigitalTwinPatientInfoDocument, options);
        }
export function useDigitalTwinPatientInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DigitalTwinPatientInfoQuery, DigitalTwinPatientInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DigitalTwinPatientInfoQuery, DigitalTwinPatientInfoQueryVariables>(DigitalTwinPatientInfoDocument, options);
        }
export type DigitalTwinPatientInfoQueryHookResult = ReturnType<typeof useDigitalTwinPatientInfoQuery>;
export type DigitalTwinPatientInfoLazyQueryHookResult = ReturnType<typeof useDigitalTwinPatientInfoLazyQuery>;
export type DigitalTwinPatientInfoSuspenseQueryHookResult = ReturnType<typeof useDigitalTwinPatientInfoSuspenseQuery>;
export type DigitalTwinPatientInfoQueryResult = Apollo.QueryResult<DigitalTwinPatientInfoQuery, DigitalTwinPatientInfoQueryVariables>;