import { ConfirmationDialog, CustomRadio, ExpandableCard } from "@/shared/components";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, Button, EditIcon, RemoveIcon, TruncateTypography, Typography } from "@toolkit/ui";
import { FC } from "react";
import { AddressFragmentFragment } from "../../gql/fragments";
import { useAddressExpandableCard } from "./useAddressExpandableCard";
import { useAddressExpandableCardStyle } from "./useAddressExpandableCardStyle";

type AddressExpandableCardProps = {
  address?: AddressFragmentFragment;
  isDefault?: boolean;
  selectionProps?: {
    selected?: boolean;
    onSelect: (id: AddressFragmentFragment["id"]) => void;
  };
  hideDelete?: boolean;
};

export const AddressExpandableCard: FC<AddressExpandableCardProps> = props => {
  const { address, isDefault, selectionProps, hideDelete } = props;
  const { t } = useTranslation("consumer");
  const { classes, cx } = useAddressExpandableCardStyle();

  const { handleDelete, isDeleteSubmitting, open, handleClose, handleDeleteClick, handleEdit } = useAddressExpandableCard({ address });

  const handleSelect = () => {
    address?.id && selectionProps?.onSelect(address?.id);
  };
  return (
    <ExpandableCard
      classes={{
        container: classes.root,
        headerContainer: classes.headerContainer,
        header: classes.header,
      }}
      header={
        <>
          <Box className={classes.headerStart} display='flex' alignItems='center' onClick={handleSelect}>
            {selectionProps && <CustomRadio checked={selectionProps.selected ?? isDefault} />}
            <Box className={classes.addressContainer}>
              <TruncateTypography
                className={classes.addressText}
                maxWidth='100%'
                maxLines={2}
                text={pickLocalizedValue(address?.name, address?.nameAr) || "-"}
              />
              {isDefault && <Typography className={classes.defaultText}>{t("Default Address")}</Typography>}
            </Box>
          </Box>
          <Box className={classes.headerEnd}>
            {!hideDelete && (
              <ConfirmationDialog
                variant='base'
                color='error'
                DoTitle={t("Delete")}
                text={{
                  title: t("Delete Address"),
                  body: t("Are you sure you want to delete this address?"),
                }}
                isDoButtonDisabled={isDeleteSubmitting}
                handleDo={handleDelete}
                handleClose={handleClose}
                isOpen={open}
                button={
                  <Button onClick={handleDeleteClick} className={classes.actionButton} variant='text' startIcon={<RemoveIcon />}>
                    {t("Remove")}
                  </Button>
                }
              />
            )}

            <Button onClick={handleEdit} className={classes.actionButton} variant='text' startIcon={<EditIcon />}>
              {t("Edit")}
            </Button>
          </Box>
        </>
      }
      defaultCollapsed={!isDefault}
    >
      <Box className={classes.contentContainer}>
        <Typography className={cx(classes.containerLabel)}>{t("Address label")}</Typography>
        <Typography className={classes.containerValue}>{pickLocalizedValue(address?.name, address?.nameAr) || "-"}</Typography>
        <Typography className={cx(classes.containerLabel)}>{t("Address")}</Typography>
        <Typography className={classes.containerValue}>{address?.streetAddress1 || "-"}</Typography>
        <Typography className={cx(classes.containerLabel)}>{t("Mobile Number")}</Typography>
        <Typography className={classes.containerValue}>{address?.phone || "-"}</Typography>
      </Box>
    </ExpandableCard>
  );
};
