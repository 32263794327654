import { makeStyles } from "@toolkit/ui";

export const useOrderCardStyles = makeStyles()(theme => ({
  root: {
    padding: 20,
  },
  emptyMessageLayout: {},
  iconContainer: {
    "& svg": {
      width: 130,
      height: 130,
    },
  },
  messageContainer: {
    marginTop: 20,
    fontWeight: theme.mixins.fonts.fontWeight.normal,
  },
  bold: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.lg,
    display: "block",
  },
}));
