import { MarketplaceReviewSummary } from "@/schema/types";
import { RatingLinearProgress } from "@/shared/components";
import { RatingStarIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useRatingOverviewStyle } from "./RatingOverviewStyle";

type RatingCountsDetailsProps = {
  ratingCounts?: MarketplaceReviewSummary["ratingCounts"];
  total?: number;
};

export const RatingCountsDetails: FC<RatingCountsDetailsProps> = props => {
  const { ratingCounts, total = 1 } = props;
  const { classes, theme } = useRatingOverviewStyle();
  const { t } = useTranslation("consumer");

  const sortedRatingCounts = ratingCounts ? [...ratingCounts].sort((a, b) => b.rating - a.rating) : [];

  return (
    <Box className={classes.ratingCountsContainer}>
      {sortedRatingCounts.map(({ rating, count }) => (
        <Box key={rating} className={classes.ratingCountItem}>
          <Typography className={classes.ratingCountLabel}>
            {rating} <RatingStarIcon fill={theme.palette.primary.main} />
          </Typography>
          <RatingLinearProgress className={classes.ratingCountProgress} value={(count / total) * 100} variant='determinate' />
        </Box>
      ))}
      {sortedRatingCounts.length === 0 && <Typography className={classes.noRatingsText}>{t("No ratings available.")}</Typography>}
    </Box>
  );
};
