import { makeStyles } from "@toolkit/ui";

export const useCommonSectionsStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    height: "100%",
    maxWidth: theme.breakpoints.values.xl,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  titleContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: theme?.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  titleSeeAll: {
    fontSize: theme?.mixins.fonts.fontSize.sm,
    color: theme.palette.secondary.main,
    padding: 0,
    margin: 0,
    minWidth: "fit-content",
  },
  footerContainer: {
    width: "100%",
    display: "flex",
  },
}));
