import React, { useEffect, useState } from "react";
import { Box, BoxProps } from "../../base/mui";
import DOMPurify from "dompurify";

interface InlineSVGProps extends BoxProps {
  src?: string;
  svgString?: string;
  fallbackSVG?: string; // New prop for custom fallback SVG
  width?: string | number;
  height?: string | number;
}

const isValidSVG = (content: string): boolean => {
  const svgRegex = /<svg[^>]*>[\s\S]*<\/svg>/i;
  return svgRegex.test(content);
};

const sanitizeSVG = (svg: string): string => {
  return DOMPurify.sanitize(svg);
};

const saveSVGToDB = async (db: IDBDatabase, src: string, svgContent: string) => {
  return new Promise<void>((resolve, reject) => {
    const transaction = db.transaction("svgs", "readwrite");
    const store = transaction.objectStore("svgs");
    const request = store.put(svgContent, src);

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = () => {
      reject("Failed to store SVG in DB.");
    };
  });
};

const openDatabase = async () => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open("SVGCache", 1);

    request.onupgradeneeded = event => {
      const db = (event.target as IDBRequest).result;
      if (!db.objectStoreNames.contains("svgs")) {
        db.createObjectStore("svgs");
      }
    };

    request.onsuccess = event => {
      resolve((event.target as IDBRequest).result);
    };

    request.onerror = () => {
      reject("Failed to open IndexedDB.");
    };
  });
};

const getSVGFromDB = async (db: IDBDatabase, src: string): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction("svgs", "readonly");
    const store = transaction.objectStore("svgs");
    const request = store.get(src);

    request.onsuccess = event => {
      resolve((event.target as IDBRequest).result);
    };

    request.onerror = () => {
      reject("Failed to retrieve SVG from DB.");
    };
  });
};

const fetchSVGContent = async (src: string): Promise<string | null> => {
  try {
    const db = await openDatabase();
    const cachedSVG = await getSVGFromDB(db, src);
    if (cachedSVG) {
      return sanitizeSVG(cachedSVG);
    }

    const response = await fetch(src);
    const text = await response.text();

    if (isValidSVG(text)) {
      await saveSVGToDB(db, src, text);
      return sanitizeSVG(text);
    } else {
      console.error("Fetched content is not a valid SVG.");
      return null;
    }
  } catch (error) {
    console.error("Error handling SVG fetch or IndexedDB:", error);
    return null;
  }
};

export const InlineSVG: React.FC<InlineSVGProps> = ({
  src,
  svgString,
  fallbackSVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><rect width="24" height="24" fill="#ccc" /></svg>`, // Default fallback SVG
  width = "24px",
  height = "24px",
  ...props
}) => {
  const [svgContent, setSvgContent] = useState<string>(fallbackSVG);

  useEffect(() => {
    const loadSVG = async () => {
      if (src) {
        const fetchedSVG = await fetchSVGContent(src);
        if (fetchedSVG) {
          setSvgContent(fetchedSVG);
        } else {
          console.error("Fetched content is not a valid SVG. Using fallback.");
          setSvgContent(fallbackSVG);
        }
      } else if (svgString) {
        const sanitizedSVG = sanitizeSVG(svgString);
        if (isValidSVG(sanitizedSVG)) {
          setSvgContent(sanitizedSVG);
        } else {
          console.error("Provided svgString is not a valid SVG. Using fallback.");
          setSvgContent(fallbackSVG);
        }
      } else {
        console.error("No valid `src` or `svgString` provided. Using fallback.");
        setSvgContent(fallbackSVG);
      }
    };

    loadSVG();
  }, [src, svgString, fallbackSVG]);

  return <Box component='span' dangerouslySetInnerHTML={{ __html: svgContent }} width={width} height={height} {...props} />;
};
