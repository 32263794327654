import { MarketplaceProductFilterInput, MarketplaceProductSortingInput } from "@/schema/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useMarketplaceProductsQuery } from "../../gql";

export const useProductsList = (filters?: MarketplaceProductFilterInput, sortingInput?: MarketplaceProductSortingInput) => {
  const {
    data,
    loading: isLoading,
    hasMore,
    handleFetchMore: fetchMoreData,
    refetch,
  } = useRelayStyleConnection({
    useQuery: useMarketplaceProductsQuery,
    variables: {
      filter: filters,
      sortBy: sortingInput,
    },
  });

  const handleRefresh = () => {
    refetch();
  };

  return {
    products: data,
    isLoading,
    hasMore,
    fetchMoreData,
    handleRefresh,
  };
};
