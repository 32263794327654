import { makeStyles } from "@toolkit/ui";

export const useMedicationsOrdersListStyles = makeStyles()(() => ({
  content: {
    flexGrow: 1,
    maxHeight: "100%",
  },
  emptyPageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "calc(100% - 20px)",
    height: "100%",
    padding: "10px",
    marginInline: "10px",
    paddingBottom: "100px",
  },
}));
