import { FC } from "react";
import { useWellnessCardStyles } from "../../styles/useWellnessCard.styles";
import { CustomCard } from "@/shared/components";
import { Box, Typography } from "@toolkit/ui";
import { Trans, useTranslation } from "@toolkit/i18n";
import { FoodCaloriesCalculatorIcon } from "@/shared/icons";
import { TdeeAndBmrCalculationRoutes } from "@/domains/digital-twin-tdee-bmr-calculator/routes";
import { useNavigate } from "react-router-dom";
import { useGetDigitalTwinHealthParameterByCode } from "@/domains/health-parameters/hooks/useGetDigitalTwinHealthParameterByCode";
import { HealthParameterCodes } from "@/domains/health-parameters/constants/healthParameterCodes";
import { DigitalTwinBmrTdeeCalculatorResult } from "@/domains/digital-twin-tdee-bmr-calculator/components/DigitalTwinBmrTdeeCalculatorResult/DigitalTwinBmrTdeeCalculatorResult";

export const CaloriesTrackingCard: FC = () => {
  const { classes, cx } = useWellnessCardStyles();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();

  const { value: bee, loading: isLoadingBee } = useGetDigitalTwinHealthParameterByCode(HealthParameterCodes.bee);
  const { value: tdee, loading: isLoadingTdee } = useGetDigitalTwinHealthParameterByCode(HealthParameterCodes.tdee);
  const tdeeValueRound = Math.round(tdee || 0);
  const beeValueRound = Math.round(bee || 0);

  const isLoading = isLoadingBee || isLoadingTdee;

  const handleNavigateToTdeeCalculator = () => {
    navigate(TdeeAndBmrCalculationRoutes.root.absRoute);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <Box className={classes.titleWrapper}>
            <Typography className={classes.title}>
              <span className={classes.bold}>{t("Tracking Calories")}</span>
            </Typography>
          </Box>
          <Box className={classes.loadingSkeleton}></Box>
        </>
      );
    }

    return (
      <>
        <Box className={classes.titleWrapper}>
          <Trans
            className={classes.title}
            i18nKey='Start <b>Tracking Calories</b>'
            t={t}
            components={{
              b: <Typography className={classes.bold} />,
            }}
          />
        </Box>
        <Typography className={classes.description}>
          {t("Use our calculator to find your calories for weight loss, weight gain or maintenance.")}
        </Typography>
      </>
    );
  };

  if (bee) {
    return (
      <Box className={classes.clickableRoot} onClick={handleNavigateToTdeeCalculator}>
        <DigitalTwinBmrTdeeCalculatorResult hideChart maxTdee={tdeeValueRound} tdee={tdee} bee={beeValueRound} />
      </Box>
    );
  }

  return (
    <CustomCard onClick={handleNavigateToTdeeCalculator} className={cx(classes.root, classes.clickableRoot)}>
      <Box className={classes.startIcon}>
        <FoodCaloriesCalculatorIcon />
      </Box>
      <Box className={classes.textWrapper}>{renderContent()}</Box>
    </CustomCard>
  );
};
