import { sleepCalculationRoutes } from "@/domains/digital-twin-sleep-calculator/routes";
import { MainPageLayout } from "@/shared/components";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const SleepCalculatorRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageLayout containerBreakpoint='sm' />}>
        <Route path={sleepCalculationRoutes.root.route} Component={sleepCalculationRoutes.root.component} />
        <Route path={sleepCalculationRoutes.form.route} Component={sleepCalculationRoutes.form.component} />
      </Route>
    </Routes>
  );
};
