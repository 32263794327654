import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import { FC } from "react";

export const ConfirmedOrderIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-oreder' width='136.514' height='130.78' viewBox='0 0 136.514 130.78' {...props}>
      <defs>
        <linearGradient id='linear-gradient' x1='0.5' y1='4.174' x2='0.5' y2='5.719' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#a9afc4' />
          <stop offset='1' stopColor='#d4d8e5' />
        </linearGradient>
      </defs>
      <g id='order_has_been_confirmed_' data-name='order has been confirmed  ' transform='translate(-46.5 -69.252)'>
        <path
          id='Path_12746_00000181086977908612989720000002170403974832897214_'
          d='M128.394,197.38c-15.175-3.432-28.874-8.852-41.49-18.487-12.5-9.6-23.967-23.334-24.418-37.4-.3-14.031,10.327-28.333,21.919-39.563a115.937,115.937,0,0,1,38.78-25.653c14.934-6.172,32.789-10.207,44.983-3.734,12.134,6.534,18.607,23.575,22.25,39.352,3.7,15.747,4.546,30.229,1.837,45.284s-9,30.561-20.5,37.636c-11.5,7.166-28.152,5.841-43.357,2.559'
          transform='translate(-11.168 0)'
          fill='#2b3078'
          opacity='0.09'
        />
        <g id='Group_11263' data-name='Group 11263' transform='translate(156.488 69.869)'>
          <path
            id='Path_13322'
            data-name='Path 13322'
            d='M429.071,88.03l2.559-2.559a.873.873,0,0,1,1.265,0h0a.873.873,0,0,1,0,1.265l-2.559,2.559a.873.873,0,0,1-1.265,0h0A.873.873,0,0,1,429.071,88.03Z'
            transform='translate(-423.681 -81.015)'
            fill='#525682'
          />
          <path
            id='Path_13323'
            data-name='Path 13323'
            d='M412.7,71.3h0a.887.887,0,0,1,.9.9v3.613a.887.887,0,0,1-.9.9h0a.887.887,0,0,1-.9-.9V72.2A.948.948,0,0,1,412.7,71.3Z'
            transform='translate(-411.8 -71.3)'
            fill='#525682'
          />
          <path
            id='Path_13324'
            data-name='Path 13324'
            d='M440.5,109.6h3.613a.887.887,0,0,1,.9.9h0a.887.887,0,0,1-.9.9H440.5a.887.887,0,0,1-.9-.9h0A.906.906,0,0,1,440.5,109.6Z'
            transform='translate(-431.23 -98.068)'
            fill='#525682'
          />
        </g>
        <rect
          id='Rectangle_7120'
          data-name='Rectangle 7120'
          width='71.599'
          height='1.867'
          transform='translate(81.999 189.612)'
          fill='#525682'
        />
        <g id='Group_11265' data-name='Group 11265' transform='translate(46.5 73.119)'>
          <g id='Cart' transform='translate(0 0)'>
            <path
              id='Path_13325'
              data-name='Path 13325'
              d='M51.234,252.367a3.555,3.555,0,1,1,.391,0Zm-1.475-4.908a1.864,1.864,0,1,0,2.921,2.318,1.886,1.886,0,0,0-1.686-3.041A2.369,2.369,0,0,0,49.758,247.459Z'
              transform='translate(-47.199 -195.881)'
              fill='#2b3078'
            />
            <path
              id='Path_13326'
              data-name='Path 13326'
              d='M105.99,282.081a3.016,3.016,0,1,1,.3-.03A.93.93,0,0,1,105.99,282.081Zm-.783-3.493a1.006,1.006,0,0,0,1.566,1.265,1.072,1.072,0,0,0,.211-.723,1.034,1.034,0,0,0-1.114-.9.874.874,0,0,0-.662.361Z'
              transform='translate(-86.058 -217.827)'
              fill='#2b3078'
            />
            <circle
              id='Ellipse_2943'
              data-name='Ellipse 2943'
              cx='6.805'
              cy='6.805'
              r='6.805'
              transform='translate(99.119 104.992)'
              fill='#525682'
            />
            <path
              id='Path_13327'
              data-name='Path 13327'
              d='M145.5,197l13.248,55.37,69.07-5.57a4.064,4.064,0,0,0,3.673-3.463l6.022-41.731a4.049,4.049,0,0,0-3.432-4.577,3.273,3.273,0,0,0-.542-.03Z'
              transform='translate(-115.692 -162.403)'
              fill='#fff'
            />
            <rect
              id='Rectangle_7121'
              data-name='Rectangle 7121'
              width='39.894'
              height='14.994'
              transform='translate(41.31 19.633)'
              fill='url(#linear-gradient)'
            />
            <rect
              id='Rectangle_7122'
              data-name='Rectangle 7122'
              width='39.894'
              height='14.994'
              transform='translate(41.31 19.633)'
              fill='#fff'
            />
            <path
              id='Path_13328'
              data-name='Path 13328'
              d='M233.3,147.3v7.828l2.439-.873,2.409.873,2.409-.783,2.439.783V147.3Z'
              transform='translate(-177.056 -127.667)'
              fill='#2b3078'
            />
            <path
              id='Path_13329'
              data-name='Path 13329'
              d='M220.251,231.726v29.9a2.359,2.359,0,0,1-4.7.271l-3.523-29.718a4.106,4.106,0,1,1,8.159-.933,1.326,1.326,0,0,1,.06.482Z'
              transform='translate(-162.171 -183.791)'
              fill='#2b3078'
            />
            <path
              id='Path_13330'
              data-name='Path 13330'
              d='M305,232.014l-1.656,27.881a2.354,2.354,0,0,1-4.7.03l-1.867-27.851a4.106,4.106,0,0,1,8.19-.6,3.265,3.265,0,0,0,.03.542Z'
              transform='translate(-221.415 -183.838)'
              fill='#2b3078'
            />
            <path
              id='Path_13331'
              data-name='Path 13331'
              d='M389.59,232.446l-3.161,25.834a2.341,2.341,0,0,1-2.138,2.047l-.361.03a2.354,2.354,0,0,1-2.529-2.138V231.964a4.095,4.095,0,0,1,8.19.06,1.964,1.964,0,0,1,0,.422Z'
              transform='translate(-280.565 -183.999)'
              fill='#2b3078'
            />
            <path
              id='Path_13332'
              data-name='Path 13332'
              d='M295.023,147.3v14.964H285.9l1.536-14.964Z'
              transform='translate(-213.819 -127.667)'
              fill='#a9b4d3'
            />
            <path
              id='Path_13333'
              data-name='Path 13333'
              d='M222.431,160.961H180.7V144.1h41.731Zm-39.864-1.867h38V145.967h-38Z'
              transform='translate(-140.294 -125.431)'
              fill='#525682'
            />
            <path
              id='Path_13336'
              data-name='Path 13336'
              d='M155.3,251.2l-13.7-57.3h89.183a5,5,0,0,1,5,4.968,5.852,5.852,0,0,1-.06.723L229.7,241.322a4.911,4.911,0,0,1-4.546,4.245Zm-11.351-55.431,12.766,53.443L225,243.7a3.106,3.106,0,0,0,2.83-2.65l5.992-41.731a3.124,3.124,0,0,0-2.65-3.523,1.988,1.988,0,0,0-.422-.03Z'
              transform='translate(-112.966 -160.236)'
              fill='#525682'
            />
            <path
              id='Path_13337'
              data-name='Path 13337'
              d='M121.691,222.625a9.314,9.314,0,0,1-9.093-7.166L94.533,140.7a4.542,4.542,0,0,0-4.426-3.463H79.9a5.835,5.835,0,0,0,.06-4.336H90.077a8.886,8.886,0,0,1,8.641,6.8l18.065,74.761a5.022,5.022,0,0,0,4.878,3.854h62.687a2.181,2.181,0,0,1,0,4.336Z'
              transform='translate(-69.844 -117.603)'
              fill='#dee1ec'
            />
            <path
              id='Path_13338'
              data-name='Path 13338'
              d='M181.063,221.322H118.406a10.272,10.272,0,0,1-10-7.889L90.345,138.672a3.553,3.553,0,0,0-3.523-2.74H75.2l.542-1.325a4.914,4.914,0,0,0,.06-3.643L75.32,129.7H86.792a9.8,9.8,0,0,1,9.545,7.5L114.4,211.958a4.031,4.031,0,0,0,3.974,3.1h62.687a3.109,3.109,0,0,1,2.83,3.372,3.179,3.179,0,0,1-2.83,2.89ZM77.88,134.036h8.942a5.474,5.474,0,0,1,5.329,4.185l18.065,74.761a8.407,8.407,0,0,0,8.19,6.443h62.687a1.248,1.248,0,0,0,0-2.469H118.406a5.928,5.928,0,0,1-5.781-4.546L94.56,137.649a7.928,7.928,0,0,0-7.738-6.082H77.91A6.8,6.8,0,0,1,77.88,134.036Z'
              transform='translate(-66.559 -115.366)'
              fill='#525682'
            />
            <circle
              id='Ellipse_2944'
              data-name='Ellipse 2944'
              cx='6.805'
              cy='6.805'
              r='6.805'
              transform='translate(51.878 104.992)'
              fill='#525682'
            />
            <circle
              id='Ellipse_2945'
              data-name='Ellipse 2945'
              cx='5.781'
              cy='5.781'
              r='5.781'
              transform='translate(0 11.564)'
              fill='#2b3078'
            />
            <circle
              id='Ellipse_2946'
              data-name='Ellipse 2946'
              cx='2.68'
              cy='2.68'
              r='2.68'
              transform='translate(56.003 109.117)'
              fill='#f5f5f5'
            />
            <circle
              id='Ellipse_2947'
              data-name='Ellipse 2947'
              cx='2.68'
              cy='2.68'
              r='2.68'
              transform='translate(103.244 109.117)'
              fill='#f5f5f5'
            />
            <g id='Group_11264' data-name='Group 11264' transform='translate(115.709 25.925)'>
              <rect id='Rectangle_7123' data-name='Rectangle 7123' width='1.867' height='1.867' fill='#a9b4d3' />
              <path
                id='Path_13339'
                data-name='Path 13339'
                d='M473.469,189.667a11.311,11.311,0,0,0-.813-1.867l-1.656.9a10.17,10.17,0,0,1,.663,1.566Z'
                transform='translate(-458.896 -181.899)'
                fill='#a9b4d3'
              />
              <path
                id='Path_13340'
                data-name='Path 13340'
                d='M447,170.267a10.086,10.086,0,0,1,1.656.331l.542-1.807a11.592,11.592,0,0,0-1.987-.391Z'
                transform='translate(-442.122 -168.34)'
                fill='#a9b4d3'
              />
              <rect
                id='Rectangle_7124'
                data-name='Rectangle 7124'
                width='1.867'
                height='1.867'
                transform='translate(13.248 26.074)'
                fill='#a9b4d3'
              />
              <rect
                id='Rectangle_7125'
                data-name='Rectangle 7125'
                width='1.867'
                height='1.867'
                transform='translate(13.248 36.04)'
                fill='#a9b4d3'
              />
              <rect
                id='Rectangle_7126'
                data-name='Rectangle 7126'
                width='1.867'
                height='1.867'
                transform='translate(13.248 21.076)'
                fill='#a9b4d3'
              />
              <path
                id='Path_13341'
                data-name='Path 13341'
                d='M474.8,205.03V206.9h1.867V205Z'
                transform='translate(-461.552 -193.92)'
                fill='#a9b4d3'
              />
              <rect
                id='Rectangle_7127'
                data-name='Rectangle 7127'
                width='1.867'
                height='1.867'
                transform='translate(13.248 16.108)'
                fill='#a9b4d3'
              />
              <rect
                id='Rectangle_7128'
                data-name='Rectangle 7128'
                width='1.867'
                height='1.867'
                transform='translate(13.248 41.008)'
                fill='#a9b4d3'
              />
              <rect
                id='Rectangle_7129'
                data-name='Rectangle 7129'
                width='1.867'
                height='1.867'
                transform='translate(13.248 31.042)'
                fill='#a9b4d3'
              />
              <path
                id='Path_13342'
                data-name='Path 13342'
                d='M462.054,174.5,461,176.066a8.84,8.84,0,0,1,1.325,1.054l1.295-1.355A13.053,13.053,0,0,0,462.054,174.5Z'
                transform='translate(-451.907 -172.603)'
                fill='#a9b4d3'
              />
              <rect
                id='Rectangle_7130'
                data-name='Rectangle 7130'
                width='1.867'
                height='1.867'
                transform='translate(13.248 50.975)'
                fill='#a9b4d3'
              />
              <rect
                id='Rectangle_7131'
                data-name='Rectangle 7131'
                width='1.867'
                height='1.867'
                transform='translate(13.248 46.007)'
                fill='#a9b4d3'
              />
            </g>
          </g>
        </g>
        <g id='Group_11266' data-name='Group 11266' transform='translate(146.04 133.188)'>
          <circle
            id='Ellipse_2948'
            data-name='Ellipse 2948'
            cx='16.831'
            cy='16.831'
            r='16.831'
            transform='translate(1.656 1.415)'
            fill='#46a200'
          />
          <path
            id='Path_13343'
            data-name='Path 13343'
            d='M395.587,281.6a18.487,18.487,0,1,0,18.487,18.487A18.491,18.491,0,0,0,395.587,281.6Zm16.68,18.547a16.68,16.68,0,1,1-16.68-16.68A16.7,16.7,0,0,1,412.267,300.147Z'
            transform='translate(-377.1 -281.6)'
            fill='#fff'
          />
          <path
            id='Path_13344'
            data-name='Path 13344'
            d='M426.705,320.873,423,317.2l-9.033,9-4.366-4.366-3.7,3.673,4.4,4.4h0l3.673,3.673h0l3.7-3.673h0Z'
            transform='translate(-397.229 -306.481)'
            fill='#fff'
          />
        </g>
      </g>
    </SvgIcon>
  );
};
