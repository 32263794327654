import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientHealthParametersAggregationQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter: Types.PatientHealthParameterAggrFilterInput;
}>;


export type PatientHealthParametersAggregationQuery = { __typename?: 'Query', patientHealthParametersAggregation?: { __typename?: 'PatientHealthParameterAggrCountableConnection', edges: Array<{ __typename?: 'PatientHealthParameterAggrCountableEdge', node: { __typename?: 'PatientHealthParameterAggr', code?: string | null, date?: any | null, value?: any | null } }>, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } | null };


export const PatientHealthParametersAggregationDocument = gql`
    query PatientHealthParametersAggregation($first: Int, $filter: PatientHealthParameterAggrFilterInput!) {
  patientHealthParametersAggregation(first: $first, filter: $filter) {
    edges {
      node {
        code
        date
        value
      }
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __usePatientHealthParametersAggregationQuery__
 *
 * To run a query within a React component, call `usePatientHealthParametersAggregationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientHealthParametersAggregationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientHealthParametersAggregationQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePatientHealthParametersAggregationQuery(baseOptions: Apollo.QueryHookOptions<PatientHealthParametersAggregationQuery, PatientHealthParametersAggregationQueryVariables> & ({ variables: PatientHealthParametersAggregationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientHealthParametersAggregationQuery, PatientHealthParametersAggregationQueryVariables>(PatientHealthParametersAggregationDocument, options);
      }
export function usePatientHealthParametersAggregationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientHealthParametersAggregationQuery, PatientHealthParametersAggregationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientHealthParametersAggregationQuery, PatientHealthParametersAggregationQueryVariables>(PatientHealthParametersAggregationDocument, options);
        }
export function usePatientHealthParametersAggregationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientHealthParametersAggregationQuery, PatientHealthParametersAggregationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientHealthParametersAggregationQuery, PatientHealthParametersAggregationQueryVariables>(PatientHealthParametersAggregationDocument, options);
        }
export type PatientHealthParametersAggregationQueryHookResult = ReturnType<typeof usePatientHealthParametersAggregationQuery>;
export type PatientHealthParametersAggregationLazyQueryHookResult = ReturnType<typeof usePatientHealthParametersAggregationLazyQuery>;
export type PatientHealthParametersAggregationSuspenseQueryHookResult = ReturnType<typeof usePatientHealthParametersAggregationSuspenseQuery>;
export type PatientHealthParametersAggregationQueryResult = Apollo.QueryResult<PatientHealthParametersAggregationQuery, PatientHealthParametersAggregationQueryVariables>;