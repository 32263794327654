import { IconButton, makeStyles, MuiDeleteIcon, MuiMicIcon, Typography, MuiSendIcon } from "@toolkit/ui";
import { FC, PropsWithChildren, useCallback, useEffect } from "react";
import { Box } from "@toolkit/ui";
import { ChatMessageDraft, useChatPlayer } from "../ChatProvider";
import { useAudioRecorder } from "./useAudioRecorder";

const useStyles = makeStyles()(theme => ({
  root: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: theme.palette.stale[100],
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 10,
  },
}));

type ChatInputRecordAudioProps = PropsWithChildren<{
  onSubmit: (value: { file: File; type: string }) => void;
  draft?: ChatMessageDraft;
  id?: string | null;
}>;

export const ChatInputRecordAudio: FC<ChatInputRecordAudioProps> = ({ id, onSubmit, draft, children }) => {
  const {
    recordEnabled,
    recordDurationText,
    isRecording,
    cancelRecording: handleCancel,
    startRecording: handleStart,
    stopRecording,
  } = useAudioRecorder();
  const { classes } = useStyles();

  useEffect(() => {
    handleCancel();
  }, [handleCancel, id]);

  const handleStop = useCallback(async () => {
    const record = await stopRecording();
    if (record) {
      const { type } = record;
      const ext = type.split("/").pop();
      const file = new File([record], `audio-record.${ext}`, { type });
      onSubmit({ file, type });
    }
  }, [onSubmit, stopRecording]);

  const { pause } = useChatPlayer();
  useEffect(() => {
    isRecording && pause();
  }, [pause, isRecording]);

  const enabled = (!draft || (!draft.message && !draft.attachments)) && recordEnabled;

  if (!enabled) {
    return children;
  }

  if (isRecording) {
    return (
      <Box className={classes.root}>
        <IconButton size={"large"} onClick={handleCancel}>
          <MuiDeleteIcon />
        </IconButton>
        <Typography>{recordDurationText}</Typography>
        <IconButton size={"large"} onClick={handleStop}>
          <MuiSendIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <IconButton size={"large"} onClick={handleStart}>
      <MuiMicIcon />
    </IconButton>
  );
};
