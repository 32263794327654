import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderItemsDetailsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.MarketplaceOrderFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetOrderItemsDetailsQuery = { __typename?: 'Query', marketplaceOrders?: { __typename?: 'MarketplaceOrderConnection', edges: Array<{ __typename?: 'MarketplaceOrderEdge', node: { __typename?: 'MarketplaceOrder', id: string, paymentStatus?: Types.PaymentStatus | null, cartTokenId: string, type: Types.MarketplaceOrderType, totalNetAmount: any, totalDiscount?: any | null, totalDeliveryPrice: any, totalNetAmountWithVAT: any, orderLines: Array<{ __typename?: 'MarketplaceOrderLine', quantity: number, unitPriceDiscount?: any | null, unitPriceNetAmount: any, product?: { __typename?: 'MarketplaceProduct', id: string, name?: string | null, nameAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, mainImageUrl?: string | null } | null, healthPackage?: { __typename?: 'MarketplaceHealthPackage', id: string, name?: string | null, nameAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, mainImageUrl?: string | null } | null }> } }> } | null };


export const GetOrderItemsDetailsDocument = gql`
    query getOrderItemsDetails($filter: MarketplaceOrderFilterInput, $first: Int) {
  marketplaceOrders(filter: $filter, first: $first) {
    edges {
      node {
        id
        paymentStatus
        cartTokenId
        type
        orderLines {
          quantity
          product {
            id
            name
            nameAr
            shortDescription
            shortDescriptionAr
            mainImageUrl
          }
          healthPackage {
            id
            name
            nameAr
            shortDescription
            shortDescriptionAr
            mainImageUrl
          }
          unitPriceDiscount
          unitPriceNetAmount
        }
        totalNetAmount
        totalDiscount
        totalDeliveryPrice
        totalNetAmountWithVAT
      }
    }
  }
}
    `;

/**
 * __useGetOrderItemsDetailsQuery__
 *
 * To run a query within a React component, call `useGetOrderItemsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderItemsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderItemsDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetOrderItemsDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderItemsDetailsQuery, GetOrderItemsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderItemsDetailsQuery, GetOrderItemsDetailsQueryVariables>(GetOrderItemsDetailsDocument, options);
      }
export function useGetOrderItemsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderItemsDetailsQuery, GetOrderItemsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderItemsDetailsQuery, GetOrderItemsDetailsQueryVariables>(GetOrderItemsDetailsDocument, options);
        }
export function useGetOrderItemsDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrderItemsDetailsQuery, GetOrderItemsDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrderItemsDetailsQuery, GetOrderItemsDetailsQueryVariables>(GetOrderItemsDetailsDocument, options);
        }
export type GetOrderItemsDetailsQueryHookResult = ReturnType<typeof useGetOrderItemsDetailsQuery>;
export type GetOrderItemsDetailsLazyQueryHookResult = ReturnType<typeof useGetOrderItemsDetailsLazyQuery>;
export type GetOrderItemsDetailsSuspenseQueryHookResult = ReturnType<typeof useGetOrderItemsDetailsSuspenseQuery>;
export type GetOrderItemsDetailsQueryResult = Apollo.QueryResult<GetOrderItemsDetailsQuery, GetOrderItemsDetailsQueryVariables>;