import { useCallback, useContext, useEffect, useRef } from "react";
import { ChatAudioPlayer, ChatContext } from "./ChatProvider";

export const useChatPlayer = () => {
  const currentRef = useRef<ChatAudioPlayer>();
  const { audioRef } = useContext(ChatContext);

  const pause = useCallback(() => audioRef?.current?.pause(), [audioRef]);
  const update = useCallback(
    (ref?: ChatAudioPlayer) => {
      if (audioRef && audioRef.current !== ref) {
        audioRef.current?.pause();
        audioRef.current = ref;
        currentRef.current = ref;
      }
    },
    [audioRef]
  );

  useEffect(() => {
    return () => {
      if (currentRef.current && audioRef?.current === currentRef.current) {
        audioRef.current = undefined;
      }
    };
  }, [audioRef]);
  return { pause, update };
};
