/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import { CallInstance } from "./types";
import { Badge, Box, Button, CustomDialog, useMediaQuery } from "@toolkit/ui";
import { Fragment, useMemo } from "react";
import {
  MuiMicIcon,
  MuiMicOffIcon,
  MuiVideocamIcon,
  MuiVideocamOffIcon,
  MuiCallEndIcon,
  MuiChatIcon,
  MuiFullscreenIcon,
  MuiFullscreenExitIcon,
  MuiPeopleIcon,
  MuiScreenShareIcon,
  MuiMoreHorizIcon,
  MuiPhotoCameraIcon,
} from "@toolkit/ui";
import { useCallMenuHookStyles } from "./MeetingMenu.styles";
import DoctorConsoleIcon from "./DoctorConsoleIcon";
import { useCallMenuHook } from "./useCallMenuHook";

type MeetingMenuProps = ReturnType<typeof useCallMenuHook> & {
  call: CallInstance;
};

export const MeetingMenu = ({
  call,
  isDoctorConsolEnabled,
  isDoctorConsoleOpen,
  isChatOpen,
  isParticipantsOpen,
  isMobileMenuOpen,
  isFullscreen,
  isFullscreenSupported,
  isDrawerOpen,
  isTakePhotoAllowed,

  onToggleDoctorConsole,
  onToggleParticipants,
  onToggletMobileMenu,
  onToggleChat,
  onToggleFullscreen,
  onToggleTakePhoto,
  onToggleVideo,
  onToggleAudio,
  onToggleShare,
  onDropCall,
}: MeetingMenuProps) => {
  const { t } = useTranslation();
  const { unreadMessagesCount, state } = call;
  const { videoMuted, screenMuted, audioMuted } = state;

  const { classes, theme } = useCallMenuHookStyles({ isDrawerOpen, isDoctorConsoleOpen });
  const isSm = useMediaQuery(theme.breakpoints.down(700));
  const isMd = useMediaQuery(theme.breakpoints.down(1000));
  const useBottomSheet = isSm || (isMd && isDoctorConsoleOpen && isDrawerOpen);
  const selectedColor = theme.palette.info.main;

  const menu = useMemo(
    () => ({
      doctorConsole: isDoctorConsolEnabled && (
        <Button variant='text' onClick={onToggleDoctorConsole} className={classes.menuItem}>
          <DoctorConsoleIcon color={isDoctorConsoleOpen ? selectedColor : ""} />
          {t("DR Console")}
        </Button>
      ),

      fullscreen: isFullscreenSupported && (
        <Button variant='text' onClick={onToggleFullscreen} className={classes.menuItem}>
          {!isFullscreen ? <MuiFullscreenIcon /> : <MuiFullscreenExitIcon />}
          {t("Fullscreen")}
        </Button>
      ),

      audio: (
        <Button variant='text' onClick={onToggleAudio} className={classes.menuItem}>
          {!audioMuted ? <MuiMicIcon /> : <MuiMicOffIcon />}
          {!audioMuted ? t("Mute") : t("Unmute")}
        </Button>
      ),

      video: (
        <Button variant='text' onClick={onToggleVideo} className={classes.menuItem}>
          {!videoMuted ? <MuiVideocamIcon /> : <MuiVideocamOffIcon />}
          {!videoMuted ? t("Stop video") : t("Start video")}
        </Button>
      ),

      screen: (
        <Button variant='text' onClick={onToggleShare} className={classes.menuItem}>
          {!screenMuted ? <MuiScreenShareIcon sx={{ color: "#5eda29" }} /> : <MuiScreenShareIcon />}
          {t("Share screen")}
        </Button>
      ),

      chat: (
        <Button variant='text' onClick={onToggleChat} className={classes.menuItem}>
          <Badge badgeContent={unreadMessagesCount} color='error'>
            <MuiChatIcon htmlColor={isChatOpen ? selectedColor : "inherit"} />
          </Badge>
          {t("Chat")}
        </Button>
      ),

      takephoto: isTakePhotoAllowed && (
        <Button variant='text' onClick={onToggleTakePhoto} className={classes.menuItem}>
          <MuiPhotoCameraIcon />
          {t("Take photo")}
        </Button>
      ),

      participants: (
        <Button variant='text' onClick={onToggleParticipants} className={classes.menuItem}>
          <Badge badgeContent={call.participants.length}>
            <MuiPeopleIcon htmlColor={isParticipantsOpen ? selectedColor : "inherit"} />
          </Badge>
          {t("Participants")}
        </Button>
      ),

      dropcall: (
        <Button variant='text' onClick={onDropCall} className={classes.menuItem}>
          <MuiCallEndIcon color='error' />
          {t("End")}
        </Button>
      ),
    }),
    [
      isDoctorConsolEnabled,
      onToggleDoctorConsole,
      classes.menuItem,
      isDoctorConsoleOpen,
      selectedColor,
      t,
      isFullscreenSupported,
      onToggleFullscreen,
      isFullscreen,
      onToggleAudio,
      audioMuted,
      onToggleVideo,
      videoMuted,
      onToggleShare,
      screenMuted,
      onToggleChat,
      isChatOpen,
      isTakePhotoAllowed,
      onToggleTakePhoto,
      onToggleParticipants,
      call.participants.length,
      isParticipantsOpen,
      onDropCall,
    ]
  );

  if (useBottomSheet) {
    const { dropcall: dropcallBtn, chat: chatBtn, video: videoBtn, audio: audioBtn, ...buttons } = menu;

    return (
      <>
        <Box className={classes.root}>
          {audioBtn}
          {videoBtn}
          {chatBtn}
          {dropcallBtn}
          <Button variant='text' onClick={onToggletMobileMenu} className={classes.menuItem}>
            <MuiMoreHorizIcon />
            {t("More")}
          </Button>
        </Box>
        <CustomDialog open={isMobileMenuOpen} handleClose={onToggletMobileMenu} type='bottomSheet'>
          <Box className={classes.mobileMenu}>
            {Object.keys(buttons).map(key => (
              <Fragment key={key}>{buttons[key as keyof typeof buttons]}</Fragment>
            ))}
            <Button variant='text' onClick={onToggletMobileMenu} className={classes.menuItem}>
              {t("Cancel")}
            </Button>
          </Box>
        </CustomDialog>
      </>
    );
  }

  const { doctorConsole: doctorConsoleBtn, takephoto: takephotoBtn, fullscreen: fullscreenBtn, ...buttons } = menu;
  return (
    <Box className={classes.root}>
      <Box className={classes.sideMenu}>
        {doctorConsoleBtn}
        {takephotoBtn}
        {fullscreenBtn}
      </Box>
      {Object.keys(buttons).map(key => (
        <Fragment key={key}>{buttons[key as keyof typeof buttons]}</Fragment>
      ))}
    </Box>
  );
};
