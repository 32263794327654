import { ChatInput, ChatList } from "@health/chat";
import { CallInstance } from "./types";
import { FC } from "react";

type MeetingChatViewProps = {
  call: CallInstance;
};

export const MeetingChatView: FC<MeetingChatViewProps> = ({ call }) => {
  const chatId = call?.callInfo?.chatGroup?.id;

  return (
    <>
      <ChatList id={chatId} />
      <ChatInput id={chatId} />
    </>
  );
};
