import { CustomCard, Section } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { useTodayFeelingSectionStyles } from "./TodayFeelingSectionStyle";
import { CustomSlider } from "@/shared/components/Form/Slider/CustomSlider";
import { Box, Typography } from "@toolkit/ui";
import { feelingModes } from "../../others/utils";
import { useTodayFeelingSection } from "./useTodayFeelingSection";

type TodayFeelingSectionProps = {
  patientId: string;
};

export const TodayFeelingSection: FC<TodayFeelingSectionProps> = props => {
  const { patientId } = props;

  const { t } = useTranslation("consumer");
  const { classes } = useTodayFeelingSectionStyles();

  const { moodIndex, handleMoodChange } = useTodayFeelingSection({ patientId });

  return (
    <Section mt={2} mb={2} title={t("How do you feel today?")}>
      <CustomCard className={classes.root}>
        <Box className={classes.iconsContainer}>
          {feelingModes?.map((mode, index) => (
            <Box className={classes.iconWrapper} key={index}>
              <mode.icon />
            </Box>
          ))}
        </Box>
        <CustomSlider
          classes={{
            root: classes.SliderRoot,
            rail: classes.sliderRail,
          }}
          step={1}
          min={1}
          max={5}
          value={[moodIndex]}
          onChange={(_, value) => handleMoodChange(value)}
        />
        <Box className={classes.modeTextContainer}>
          {feelingModes?.map((mode, index) => (
            <Typography className={classes.modeText} key={index}>
              {mode.label}
            </Typography>
          ))}
        </Box>
      </CustomCard>
    </Section>
  );
};
