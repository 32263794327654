import { makeStyles } from "@toolkit/ui";

export const useAddressExpandableCardStyle = makeStyles()(theme => ({
  root: {
    marginTop: 10,
  },
  headerContainer: {},
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerStart: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  addressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    paddingInlineEnd: 8,
  },
  defaultText: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    fontWeight: theme.mixins.fonts.fontWeight.light,
    textAlign: "start",
  },
  addressText: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "start",
  },
  headerEnd: {
    display: "flex",
    gap: 8,
  },
  actionButton: {
    padding: 0,
    margin: 0,
    "& svg": {
      width: 17,
      height: 17,
    },
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.light,
  },
  contentContainer: {
    display: "grid",
    gridTemplateColumns: "0.65fr 1fr",
    gap: 20,
    alignItems: "start",
  },
  containerLabel: {
    textAlign: "start",
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    verticalAlign: "center",
  },
  containerValue: {
    textAlign: "start",
    fontWeight: theme.mixins.fonts.fontWeight.light,
  },
}));
