import { makeStyles } from "@toolkit/ui";

type StylesProps = {
  hasPhoto: boolean;
  hasText: boolean;
  isOutgoing: boolean;
  isDeleted: boolean;
};

export const useStyles = makeStyles<StylesProps>()((theme, { hasPhoto, hasText, isOutgoing, isDeleted }) => ({
  root: {
    position: "relative",
    color: theme.palette.gray[1900],
    backgroundColor: isOutgoing && !isDeleted ? theme.palette.yellow[200] : theme.palette.gray[50],
    borderRadius: 10,
    overflow: "hidden",
    flex: 1,
    padding: hasPhoto ? undefined : "8px 12px",

    [`& a`]: {
      wordBreak: "break-all",
      color: theme.palette.primary.main,
    },
  },

  media: {
    maxHeight: 300,
    gap: 6,
  },

  footer: {
    paddingHorizontal: 4,
    paddingVertical: 2,
    ...(hasPhoto
      ? {
          position: "absolute",
          bottom: hasText ? 45 : 10,
          right: 20,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          borderRadius: 10,
        }
      : {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
          marginTop: -8,
        }),
  },

  footerText: {
    color: hasPhoto ? theme.palette.common.white : "#76797b",
    padding: "2px 4px",
    display: "flex",
    fontStyle: "italic",
    userSelect: "none",
    fontSize: "0.7rem",
  },

  textContent: {
    padding: 4,
    flexGrow: 1,
    flexShrink: 1,
    textAlign: "start",
  },
  replyContent: { padding: 4 },
}));
