import { useMeetingSettingsQuery } from "../../gql/queries";
import { useCallback, useMemo } from "react";

export const useMeetingSettings = () => {
  const { loading, data, refetch, error } = useMeetingSettingsQuery({ fetchPolicy: "no-cache" });
  const handleRetry = useCallback(async () => {
    try {
      await refetch();
    } catch {
      // ignore
    }
  }, [refetch]);

  return useMemo(
    () => ({
      loading,
      error,
      handleRetry,
      type: data?.siteSettings.conferenceProviderType,
      url: data?.siteSettings.conferenceProviderTypeWebSocketUrl,
    }),
    [data?.siteSettings.conferenceProviderType, data?.siteSettings.conferenceProviderTypeWebSocketUrl, error, handleRetry, loading]
  );
};
