import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const MedicalFormIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={props.width || "17.085"}
      height={props.height || "23.064"}
      viewBox='0 0 17.085 23.064'
      {...props}
    >
      <path
        id='icons8-pass-fail'
        d='M13.542,0A2.576,2.576,0,0,0,10.98,2.563H7.563A2.576,2.576,0,0,0,5,5.125V20.5a2.576,2.576,0,0,0,2.563,2.563H19.522A2.576,2.576,0,0,0,22.085,20.5V5.125a2.576,2.576,0,0,0-2.563-2.563H16.105A2.576,2.576,0,0,0,13.542,0Zm0,1.708a.854.854,0,1,1-.854.854A.842.842,0,0,1,13.542,1.708ZM7.563,4.271H9.271A1.708,1.708,0,0,0,10.98,5.98h5.125a1.708,1.708,0,0,0,1.708-1.708h1.708a.841.841,0,0,1,.854.854V20.5a.841.841,0,0,1-.854.854H7.563a.841.841,0,0,1-.854-.854V5.125A.841.841,0,0,1,7.563,4.271ZM17.8,9.388a.854.854,0,0,0-.587.259l-.6.6h-.5a.854.854,0,1,0,0,1.709h.854a.854.854,0,0,0,.6-.25l.854-.854A.854.854,0,0,0,17.8,9.388Zm-8.526.863a.854.854,0,1,0,0,1.709h3.417a.854.854,0,1,0,0-1.709ZM16.1,14.514a.854.854,0,0,0-.6,1.467l.25.25-.25.25a.854.854,0,1,0,1.208,1.208l.25-.25.25.25a.854.854,0,1,0,1.208-1.208l-.25-.25.25-.25a.854.854,0,1,0-1.208-1.208l-.25.25-.25-.25A.854.854,0,0,0,16.1,14.514Zm-6.826.863a.854.854,0,1,0,0,1.709h3.417a.854.854,0,1,0,0-1.709Z'
        transform='translate(-5)'
        fill={fill}
      />
    </SvgIcon>
  );
};
