import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGuidedCareJourneyQueryVariables = Types.Exact<{
  guidedCareJourneyId: Types.Scalars['ID']['input'];
}>;


export type GetGuidedCareJourneyQuery = { __typename?: 'Query', guidedCareJourney?: { __typename?: 'GuidedCareJourney', id: string, startDate?: any | null, endDate?: any | null, guidedCareHealthProgram?: { __typename?: 'GuidedCareHealthProgram', name?: string | null, insuranceNetworks?: Array<{ __typename?: 'HealthProgramNetwork', name?: string | null } | null> | null } | null, providerGuidedCareHealthProgramTeam?: { __typename?: 'ProviderGuidedCareHealthProgramTeam', name?: string | null, teamMembers?: Array<{ __typename?: 'ProviderGuidedCareProgramTeamMember', id: string, isBackup?: boolean | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, fullName?: string | null, photo?: string | null, gender?: Types.UserGender | null, vendor?: { __typename?: 'Vendor', name: string, logo?: string | null } | null } | null, doctor?: { __typename?: 'Doctor', id: string, bio?: string | null, yearsOfExperience?: number | null, isLanguagesPublic: boolean, isYearsOfExperiencePublic: boolean, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', display?: string | null, arabicDisplay?: string | null } | null> | null, languages?: Array<{ __typename?: 'Language', display: string, displayAr?: string | null } | null> | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null, guidedCareProgramTeamMember?: { __typename?: 'GuidedCareProgramTeamMember', position?: Types.TeamMemberPosition | null } | null } | null> | null, provider: { __typename?: 'Vendor', name: string, nameAr?: string | null } } | null } | null };


export const GetGuidedCareJourneyDocument = gql`
    query getGuidedCareJourney($guidedCareJourneyId: ID!) {
  guidedCareJourney(id: $guidedCareJourneyId) {
    id
    startDate
    endDate
    guidedCareHealthProgram {
      name
      insuranceNetworks {
        name
      }
    }
    providerGuidedCareHealthProgramTeam {
      name
      teamMembers {
        id
        isBackup
        user {
          id
          firstName
          lastName
          fullName
          photo
          gender
          vendor {
            name
            logo
          }
        }
        doctor {
          id
          bio
          yearsOfExperience
          isLanguagesPublic
          isYearsOfExperiencePublic
          appointmentTypes
          specializations {
            display
            arabicDisplay
          }
          languages {
            display
            displayAr
          }
          user {
            id
            firstName
            lastName
          }
        }
        guidedCareProgramTeamMember {
          position
        }
      }
      provider {
        name
        nameAr
      }
    }
  }
}
    `;

/**
 * __useGetGuidedCareJourneyQuery__
 *
 * To run a query within a React component, call `useGetGuidedCareJourneyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuidedCareJourneyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuidedCareJourneyQuery({
 *   variables: {
 *      guidedCareJourneyId: // value for 'guidedCareJourneyId'
 *   },
 * });
 */
export function useGetGuidedCareJourneyQuery(baseOptions: Apollo.QueryHookOptions<GetGuidedCareJourneyQuery, GetGuidedCareJourneyQueryVariables> & ({ variables: GetGuidedCareJourneyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGuidedCareJourneyQuery, GetGuidedCareJourneyQueryVariables>(GetGuidedCareJourneyDocument, options);
      }
export function useGetGuidedCareJourneyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGuidedCareJourneyQuery, GetGuidedCareJourneyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGuidedCareJourneyQuery, GetGuidedCareJourneyQueryVariables>(GetGuidedCareJourneyDocument, options);
        }
export function useGetGuidedCareJourneySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGuidedCareJourneyQuery, GetGuidedCareJourneyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGuidedCareJourneyQuery, GetGuidedCareJourneyQueryVariables>(GetGuidedCareJourneyDocument, options);
        }
export type GetGuidedCareJourneyQueryHookResult = ReturnType<typeof useGetGuidedCareJourneyQuery>;
export type GetGuidedCareJourneyLazyQueryHookResult = ReturnType<typeof useGetGuidedCareJourneyLazyQuery>;
export type GetGuidedCareJourneySuspenseQueryHookResult = ReturnType<typeof useGetGuidedCareJourneySuspenseQuery>;
export type GetGuidedCareJourneyQueryResult = Apollo.QueryResult<GetGuidedCareJourneyQuery, GetGuidedCareJourneyQueryVariables>;