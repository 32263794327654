import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 2,
    alignItems: "flex-start",
    position: "relative",
  },

  marker: {
    width: 5,
    height: "90%",
    backgroundColor: theme.palette.gray.light,
    position: "absolute",
    left: 42,
    top: 0,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    zIndex: 1,
    top: "20px",
    left: "3px",
  },
  dateBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: "2px",
  },
  itemTypeIcon: {
    borderRadius: "50%",
    width: 35,
    height: 35,
    backgroundColor: theme.palette.common.white,
    border: `1.5px solid ${theme.palette.primary.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contain: {
    paddingRight: 2,
    marginBottom: 10,
    flexGrow: 1,
    marginLeft: 25,
  },
  card: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    padding: 10,
    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
  },
  arrow: {
    position: "absolute",
    left: 75,
    zIndex: 1,
    width: 0,
    height: 0,
    borderTop: "7px solid transparent",
    borderBottom: "7px solid transparent",
    borderRight: `7px solid ${theme.palette.common.white}`,
    marginTop: 35,
  },
  contentView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "5px",
  },
  dueDate: {
    fontSize: 12,
  },
  box: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  divider: { width: "100%", marginBottom: "10px" },
}));
