import { useRelayStyleConnection } from "@toolkit/apollo";
import { useConsumerMedicationsOrdersQuery } from "../../gql/queries";
import { OrderDirection, OrderSortField } from "@/schema/types";
import { useAuth } from "react-oidc-context";

export const useMedicationsOrdersList = () => {
  const { isAuthenticated } = useAuth();
  const {
    data,
    loading: isLoading,
    hasMore,
    handleFetchMore: fetchMoreData,
    refetch,
  } = useRelayStyleConnection({
    useQuery: useConsumerMedicationsOrdersQuery,
    accessor: "me.orders",
    variables: {
      first: 10,
      sortBy: {
        field: OrderSortField.ModifiedDate,
        direction: OrderDirection.Desc,
      },
    },
    skip: !isAuthenticated,
  });
  const handleRefresh = () => {
    refetch();
  };
  return {
    orders: data,
    isLoading,
    hasMore,
    fetchMoreData,
    handleRefresh,
  };
};
