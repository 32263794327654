import { pickLocalizedValue } from "@toolkit/i18n";
import { makeStyles } from "@toolkit/ui";

export const useAddressMapWithSearchStyles = makeStyles()(theme => ({
  root: {
    height: "calc(100dvh - 230px)",
    borderRadius: "10px",
    boxShadow: theme.mixins.shadows.main,
  },
  error: {
    color: theme.palette.error.main,
    marginBlock: 20,
  },
  searchField: {
    "& .MuiInputBase-root": {
      borderRadius: "25px",
      height: "50px",
      border: "none",
      "&.Mui-error": {
        borderWidth: "1px",
        "& svg": {
          fill: "inherit",
        },
      },
      boxShadow: theme.mixins.shadows.main,
    },
    "& .MuiInputBase-input": {
      textIndent: "12.5px",
      width: "100%",
      padding: "5px",
      paddingInlineEnd: "120px",
      borderRadius: "28px",
    },
  },
  locationButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: pickLocalizedValue("0 25px 25px 0", "25px 0 0 25px"),
    [pickLocalizedValue("right", "left")]: 0,
    [pickLocalizedValue("left", "right")]: "unset",
    "&:hover": {
      backgroundColor: theme.palette.secondary[900],
      color: theme.palette.common.white,
    },
  },
}));
