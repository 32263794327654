import { ExpandMoreIcon } from "@/shared/icons";
import { Box, Button, CloseIcon, IconButton, makeStyles, useTheme } from "@toolkit/ui";
import React from "react";

type FilterButtonProps = {
  label: string;
  onFilterClick?: () => void;
  onReset?: () => void;
  hasValue?: boolean;
};

const useStyles = makeStyles()(theme => ({
  button: {
    borderRadius: "20px",
    height: "40px",
    padding: "8px 16px",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "100px",
    marginInline: "3px",
    paddingInlineEnd: "7px",
    width: "max-content",
    "& svg": {
      width: "10px",
      height: "10px",
    },
    "&.MuiButton-outlinedPrimary:hover": {
      backgroundColor: theme.palette.primary[50],
      color: theme.palette.text.primary,
    },
  },
  endIconButton: {
    height: "40px",
    borderRadius: "0 20px 20px 0",
    paddingInlineEnd: "15px",
    ":hover": {
      backgroundColor: theme.palette.primary[1200],
    },
  },
  endIconBox: {
    height: "40px",
    borderRadius: "0 50% 50% 0",
    paddingInlineEnd: "20px",
    width: "20px",
    paddingTop: "2px",
  },
  closeIcon: {
    color: "inherit",
  },
}));

export const FilterButton: React.FC<FilterButtonProps> = ({ label, onFilterClick, onReset, hasValue }) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const handleResetClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onReset?.();
  };
  return (
    <Button
      variant={!hasValue ? "outlined" : "contained"}
      className={classes.button}
      endIcon={
        hasValue ? (
          <IconButton onClick={handleResetClick} className={classes.endIconButton} size='small'>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        ) : (
          <Box className={classes.endIconBox}>
            <ExpandMoreIcon direction='down' fill={theme.palette.primary.main} />
          </Box>
        )
      }
      onClick={onFilterClick}
    >
      {label}
    </Button>
  );
};
