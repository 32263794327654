import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientHealthDocumentDeleteMutationVariables = Types.Exact<{
  patientHealthDocumentDeleteId: Types.Scalars['ID']['input'];
}>;


export type PatientHealthDocumentDeleteMutation = { __typename?: 'Mutation', PatientHealthDocumentDelete?: { __typename?: 'PatientHealthDocument', id: string } | null };


export const PatientHealthDocumentDeleteDocument = gql`
    mutation PatientHealthDocumentDelete($patientHealthDocumentDeleteId: ID!) {
  PatientHealthDocumentDelete(id: $patientHealthDocumentDeleteId) {
    id
  }
}
    `;
export type PatientHealthDocumentDeleteMutationFn = Apollo.MutationFunction<PatientHealthDocumentDeleteMutation, PatientHealthDocumentDeleteMutationVariables>;

/**
 * __usePatientHealthDocumentDeleteMutation__
 *
 * To run a mutation, you first call `usePatientHealthDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientHealthDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientHealthDocumentDeleteMutation, { data, loading, error }] = usePatientHealthDocumentDeleteMutation({
 *   variables: {
 *      patientHealthDocumentDeleteId: // value for 'patientHealthDocumentDeleteId'
 *   },
 * });
 */
export function usePatientHealthDocumentDeleteMutation(baseOptions?: Apollo.MutationHookOptions<PatientHealthDocumentDeleteMutation, PatientHealthDocumentDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientHealthDocumentDeleteMutation, PatientHealthDocumentDeleteMutationVariables>(PatientHealthDocumentDeleteDocument, options);
      }
export type PatientHealthDocumentDeleteMutationHookResult = ReturnType<typeof usePatientHealthDocumentDeleteMutation>;
export type PatientHealthDocumentDeleteMutationResult = Apollo.MutationResult<PatientHealthDocumentDeleteMutation>;
export type PatientHealthDocumentDeleteMutationOptions = Apollo.BaseMutationOptions<PatientHealthDocumentDeleteMutation, PatientHealthDocumentDeleteMutationVariables>;