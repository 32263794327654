import { PaymentCallBackPagesBaseRoute, paymentCallBackPagesRoutes } from "pages/PaymentCallback/route";

export const getPaymentResultRootUrl = () => {
  return window.location.origin + PaymentCallBackPagesBaseRoute;
};
export const getRedirectPageFullUrl = (absolutePath: string) => {
  return window.location.origin + absolutePath;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logIoEvent = (...data: any) => console.log(...data);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logPaymentEvent = (...data: any) => console.log(...data);

export const PAYMENT_SUCCESS_URL = getRedirectPageFullUrl(paymentCallBackPagesRoutes.success.absRoute);
export const PAYMENT_ERROR_URL = getRedirectPageFullUrl(paymentCallBackPagesRoutes.error.absRoute);
export const PAYMENT_REFUNDED_URL = getRedirectPageFullUrl(paymentCallBackPagesRoutes.refunded.absRoute);
export const PAYMENT_PENDING_URL = getRedirectPageFullUrl(paymentCallBackPagesRoutes.pending.absRoute);
