import { useNavigate } from "react-router-dom";
import { GetDependentsListDocument, useGetDependentsListQuery } from "../../gql/queries";
import { routes } from "../../routes";
import { useOpenState } from "@toolkit/core";
import { UnbindDependentMutation, useUnbindDependentMutation } from "../../gql/mutations";
import { useAddToast } from "@toolkit/ui";
import { useTranslation } from "@/i18n/i18n.config";
import { formatMessageErrors } from "@toolkit/apollo";
import { useAuth } from "@/shared/context";
import { useCallback, useState } from "react";
import { useFeatureFlag } from "@health/domains";

export const useDependentsContainerHook = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSwitching, setSwitching] = useState(false);
  const { isFeatureEnabled } = useFeatureFlag();
  const { handleSwitchUser } = useAuth();
  const { failed, succeeded } = useAddToast();
  const { handleToggle, open, handleClose } = useOpenState();
  const { data, loading } = useGetDependentsListQuery({
    fetchPolicy: "network-only",
  });
  const dependents = data?.me?.dependents || [];
  const [unbindDependentMutation, { loading: isLoadingDeactivate }] = useUnbindDependentMutation({
    onCompleted: (mutationData: UnbindDependentMutation) => {
      const errors = mutationData?.unbindDependent?.accountErrors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Successfully unlinked dependent to parent"));
        handleToggle();
      }
    },
    refetchQueries: [
      {
        query: GetDependentsListDocument,
      },
    ],
  });
  const onNavigateToCreateDependent = () => {
    navigate(routes.createDependent.absRoute);
  };
  const handleDeactivateDependent = (id: string) => {
    unbindDependentMutation({
      variables: {
        input: {
          dependentUserId: id,
        },
      },
    });
  };
  const handleSwitchUserDependent = useCallback(
    async id => {
      setSwitching(true);
      try {
        await handleSwitchUser(id);
      } catch (error) {
        console.error(error);
        setSwitching(false);
      }
    },
    [handleSwitchUser]
  );

  return {
    dependents,
    loading,
    onNavigateToCreateDependent,
    handleClose,
    open,
    handleToggle,
    handleDeactivateDependent,
    isLoadingDeactivate,
    handleSwitchUserDependent,
    isSwitching,
    isFeatureEnabled,
  };
};
