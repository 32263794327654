import { FC } from "react";
import { MarketplaceProductDetails } from "@/domains/marketplace-products/types";
import { MarketPlaceRatingOverview } from "@/domains/marketplace/components/RatingOverview/MarketPlaceRatingOverview";
import { AddReviewAndComment } from "@/domains/marketplace/components/AddReviewAndComent/AddReviewAndComent";
import { MarketplaceItemReviews } from "@/domains/marketplace/components/MarketplaceItemReviews/MarketplaceItemReviews";

type ProductTabBodyProps = {
  product: MarketplaceProductDetails;
};
export const ReviewsBody: FC<ProductTabBodyProps> = props => {
  const { product } = props;

  return (
    <>
      {product?.reviewSummary && <MarketPlaceRatingOverview ratingSummary={product?.reviewSummary} />}
      {product?.id && <AddReviewAndComment id={product?.id} marketplaceItemType='product' />}
      {product?.id && <MarketplaceItemReviews id={product?.id} marketplaceItemType='product' />}
    </>
  );
};
