import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderCancelMutationVariables = Types.Exact<{
  orderCancelId: Types.Scalars['ID']['input'];
}>;


export type OrderCancelMutation = { __typename?: 'Mutation', orderCancel?: { __typename?: 'Order', id: string } | null };


export const OrderCancelDocument = gql`
    mutation OrderCancel($orderCancelId: ID!) {
  orderCancel(id: $orderCancelId) {
    id
  }
}
    `;
export type OrderCancelMutationFn = Apollo.MutationFunction<OrderCancelMutation, OrderCancelMutationVariables>;

/**
 * __useOrderCancelMutation__
 *
 * To run a mutation, you first call `useOrderCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCancelMutation, { data, loading, error }] = useOrderCancelMutation({
 *   variables: {
 *      orderCancelId: // value for 'orderCancelId'
 *   },
 * });
 */
export function useOrderCancelMutation(baseOptions?: Apollo.MutationHookOptions<OrderCancelMutation, OrderCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderCancelMutation, OrderCancelMutationVariables>(OrderCancelDocument, options);
      }
export type OrderCancelMutationHookResult = ReturnType<typeof useOrderCancelMutation>;
export type OrderCancelMutationResult = Apollo.MutationResult<OrderCancelMutation>;
export type OrderCancelMutationOptions = Apollo.BaseMutationOptions<OrderCancelMutation, OrderCancelMutationVariables>;