import { InfoCardWithHeader } from "@/shared/components";
import { Box, Typography, ValueWithLabel } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { MedicationBlobIcon } from "@/shared/icons";
import { ActiveOrder, ActiveOrderChild, PrescriptionDetailsSummary } from "../../others/types";
import { useCommonLabelValueStyles } from "../../styles/useCommonLabelValue.styles";
import { OrderMedicationsCardHeader } from "../OrderMedicationsCardHeader/OrderMedicationsCardHeader";

type DispensedMedicationsCardProps = {
  prescription?: PrescriptionDetailsSummary;
  order?: ActiveOrder;
};

export const DispensedMedicationsCard: React.FC<DispensedMedicationsCardProps> = ({ order }) => {
  const { classes } = useCommonLabelValueStyles();
  const { t } = useTranslation("consumer");

  const commonProps = {
    labelProps: { className: classes.label },
    valueProps: { className: classes.value },
    className: classes.labelValueContainer,
  };

  const getMedicationFields = (orderLine?: ActiveOrderChild["orderLines"][number], orderChild?: ActiveOrderChild) => [
    {
      labelKey: t("Instructions"),
      value: orderLine ? pickLocalizedValue(orderLine?.medication?.instructions, orderLine?.medication?.arabicInstructions) : "-",
    },
    { labelKey: t("Duration"), value: orderLine ? orderLine?.medication?.duration : "-" },
    { labelKey: t("Prescribed Quantity"), value: orderLine ? orderLine?.medication?.quantity : "-" },
    { labelKey: t("Dispensed Quantity"), value: orderLine ? orderLine?.medication?.dispenseCount : "-" },
    {
      labelKey: t("Dispensed Pharmacy"),
      value: orderChild?.branch ? pickLocalizedValue(orderChild?.branch?.name, orderChild?.branch?.nameAr) : "-",
    },
  ];

  const orderMedicationChildren = order?.children;

  return orderMedicationChildren?.map((child, index) => (
    <InfoCardWithHeader
      key={"child-" + index}
      mt='15px'
      classes={{ contentContainer: classes.contentContainer }}
      header={<OrderMedicationsCardHeader title={t("Dispensed Medications")} icon={<MedicationBlobIcon showBackground />} />}
    >
      {child?.orderLines?.map((orderLine, index) => (
        <Box key={orderLine?.id + index} className={classes.sectionContainer}>
          <Typography className={classes.sectionHeader}>{orderLine?.tradeDrug?.display}</Typography>
          {getMedicationFields(orderLine, child).map(({ labelKey, value }) => (
            <ValueWithLabel key={labelKey} label={t(labelKey)} value={value || "-"} {...commonProps} />
          ))}
        </Box>
      ))}
    </InfoCardWithHeader>
  ));
};
