import { makeStyles } from "@toolkit/ui";

export const useHealthPackageDetailsTabsStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    height: "100%",
    flex: 1,
    paddingInline: "10px",
  },
  headerRoot: {
    height: "45px",
    width: "100%",
    borderBottomColor: theme.palette.divider,
    backgroundColor: "inherit",
  },
  tabsContainerClassName: {
    justifyContent: "start",
    gap: "30px",
    paddingInlineStart: "0px",
  },
  tabBodyContainer: {
    width: "100%",
    height: "100%",
    flex: 1,
    padding: "6px",
    paddingBlock: "20px",
    textAlign: "start",
    minHeight: "360px",
  },
}));
