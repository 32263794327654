import { makeStyles } from "@toolkit/ui";

export const useHealthPackageContainerStyle = makeStyles()(() => ({
  footer: {
    paddingInline: "33px",
    gap: "10px",
  },
  buyButton: {
    height: "48px",
    flex: 1,
    borderRadius: "24px",
  },
}));
