import { PriceDisplay } from "@/domains/marketplace/components/PriceDisplay/PriceDisplay";
import { WishlistAddButton } from "@/domains/marketplace/components/WishlistAddButton/WishlistAddButton";
import { calculateDiscountedPrice } from "@/domains/marketplace/utils";
import { Counter, CustomCard } from "@/shared/components";
import { useMarketplaceCartContext } from "@/shared/context";
import { useCounter } from "@/shared/hooks";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, Button, Skeleton, Typography, useAddToast } from "@toolkit/ui";
import { FC, useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { MarketplaceProductDetails } from "../../types";
import { useProductDetailsCardStyle } from "./ProductDetailsCardStyle";

type ProductDetailsCardProps = {
  product: MarketplaceProductDetails;
  isLoading: boolean;
};

export const ProductDetailsCard: FC<ProductDetailsCardProps> = ({ product, isLoading }) => {
  const { classes } = useProductDetailsCardStyle();
  const { t } = useTranslation("consumer");
  const { itemsLoadingTrackerMap, updateItemQuantity, cart, cartItems, addItemToCart } = useMarketplaceCartContext();
  const { succeeded } = useAddToast();
  const isProductOutStock = product?.stockQuantity === 0;
  const { isAuthenticated } = useAuth();

  const productCartItem = useMemo(() => cart?.items?.find(item => item?.product?.id === product?.id), [cart]);

  const counterProps = useCounter({
    initial: { max: productCartItem?.product?.stockQuantity || product?.stockQuantity || 0, min: 1, count: productCartItem?.quantity || 1 },
  });

  const handleAddToCart = () => {
    if (productCartItem?.id) {
      updateItemQuantity?.(productCartItem?.id, counterProps.count)?.then(response => {
        response?.data?.marketplaceCartUpdateItemQuantity?.id &&
          succeeded(t("Product count updated to {{count}}", { count: counterProps.count }));
      });
    } else {
      addItemToCart?.({
        productId: product?.id,
        quantity: counterProps.count,
      }).then(response => {
        response?.data?.marketplaceCartAddItem?.id &&
          succeeded(counterProps.count >= 1 ? t("{{count}} items added to cart", { count: counterProps.count }) : t("Item added to cart"));
      });
    }
  };

  if (isLoading || !product) {
    return (
      <CustomCard className={classes.root}>
        <Skeleton variant='text' className={classes.mainText} width='60%' />
        <Skeleton variant='text' className={classes.mainText} width='40%' />
        <Skeleton variant='rectangular' className={classes.priceContainer} height='20px' width='50%' />
        <Skeleton variant='text' className={classes.shortDescription} width='90%' />
        <Skeleton variant='rectangular' className={classes.quantitySection} height='40px' />
        <Skeleton variant='rectangular' className={classes.actionSection} height='60px' />
      </CustomCard>
    );
  }

  const { basePrice, discountedPrice, hasActiveDiscount } = calculateDiscountedPrice(product?.price, product?.activeDiscount);

  return (
    <CustomCard className={classes.root}>
      <Typography className={classes.mainText}>{pickLocalizedValue(product?.name, product?.nameAr)}</Typography>
      <Typography className={classes.mainText}>
        {t("SKU")}: {product?.sku}
      </Typography>
      <Box className={classes.priceContainer}>
        <PriceDisplay amount={discountedPrice} />
        {hasActiveDiscount && <PriceDisplay amount={basePrice} type='base-price' />}
      </Box>
      <Typography className={classes.shortDescription}>
        {pickLocalizedValue(product?.shortDescription, product?.shortDescriptionAr)}
      </Typography>
      <Box className={classes.quantitySection}>
        <Typography>{t("Quantity")}</Typography>
        <Counter
          {...counterProps}
          disabled={itemsLoadingTrackerMap?.product?.[product?.id || ""] || isProductOutStock || !isAuthenticated}
        />
      </Box>
      <Box className={classes.actionSection}>
        <WishlistAddButton id={product?.id} marketplaceItemType='product' />
        <Button
          disabled={
            itemsLoadingTrackerMap?.product?.[product?.id || ""] ||
            isProductOutStock ||
            !isAuthenticated ||
            productCartItem?.quantity === counterProps.count
          }
          onClick={handleAddToCart}
          className={classes.buyButton}
        >
          {cartItems?.product[product?.id || ""] ? t("Update Count's") : t("Add to Cart")}
        </Button>
      </Box>
    </CustomCard>
  );
};
