import { useCallback, useEffect, useReducer } from "react";

import { ZoomClientType } from "./types";

const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
};

// eslint-disable-next-line default-param-last
const mediaReducer = (s = mediaShape, action: any) => {
  switch (action.type) {
    case "audio-encode": {
      return { ...s, audio: { ...s.audio, encode: action.payload } };
    }
    case "audio-decode": {
      return { ...s, audio: { ...s.audio, decode: action.payload } };
    }
    case "video-encode": {
      return { ...s, video: { ...s.video, encode: action.payload } };
    }
    case "video-decode": {
      return { ...s, video: { ...s.video, decode: action.payload } };
    }
    case "share-encode": {
      return { ...s, share: { ...s.share, encode: action.payload } };
    }
    case "share-decode": {
      return { ...s, share: { ...s.share, decode: action.payload } };
    }
    case "reset-media": {
      return mediaShape;
    }
    default:
      return s;
  }
};

export const useMediaState = (client: ZoomClientType) => {
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);

  const resetMediaState = useCallback(() => {
    dispatch({ type: "reset-media" });
  }, [dispatch]);

  useEffect(() => {
    const onMediaSDKChange = (payload: { type: string; action: string; result: string }) => {
      const { action, type, result } = payload;
      dispatch({ type: `${type}-${action}`, payload: result === "success" });
    };

    client.on("media-sdk-change", onMediaSDKChange);
    return () => {
      client.off("media-sdk-change", onMediaSDKChange);
    };
  }, [client]);

  return {
    mediaState,
    resetMediaState,
  };
};
