import { CareJourneyDetails } from "./containers/CareJourneyDetails/CareJourneyDetails";
import { MyPreviousJourneysContainer } from "./containers/PreviousJourney/MyPreviousJourney";

export const MyPreviousJourneysRoute = "/previous-journeys";

export const routes = {
  root: {
    route: "/",
    component: () => <MyPreviousJourneysContainer />,
    get absRoute() {
      return `${MyPreviousJourneysRoute}${this.route}`;
    },
  },
  journeyDetails: {
    route: "journey-details",
    component: () => <CareJourneyDetails />,
    get absRoute() {
      return `${MyPreviousJourneysRoute}/${this.route}`;
    },
    getRouteWithParams({ journeyId }: { journeyId: string }) {
      return `${this.absRoute}?journeyId=${journeyId}`;
    },
  },
};
