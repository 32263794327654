import { MarketplaceDiscount, MarketplaceDiscountType } from "@/schema/types";

export interface PriceCalculationResult {
  basePrice: string | undefined;
  discountedPrice?: string | undefined;
  hasActiveDiscount?: boolean;
}

export const calculateDiscountedPrice = (
  basePrice?: number,
  discount?: Pick<MarketplaceDiscount, "amount" | "type" | "percentage"> | null,
  itemsCount = 1
): PriceCalculationResult => {
  if (basePrice === undefined) {
    return { basePrice: undefined, discountedPrice: undefined };
  }

  if (!discount) {
    return { basePrice: (basePrice * itemsCount).toFixed(2), discountedPrice: (basePrice * itemsCount).toFixed(2) };
  }

  const { amount, percentage, type } = discount;

  let _discountedPrice = "";

  switch (type) {
    case MarketplaceDiscountType.Fixed:
      _discountedPrice = Math.max(basePrice - (amount ?? 0), 0)?.toFixed(2);
      break;
    case MarketplaceDiscountType.Percentage:
      _discountedPrice = Math.max(basePrice - basePrice * ((percentage ?? 0) / 100), 0).toFixed(2);
      break;
    default:
      _discountedPrice = basePrice + "";
  }
  const hasActiveDiscount = discount?.amount || discount?.percentage || discount?.type;

  return {
    basePrice: (basePrice * itemsCount).toFixed(2),
    discountedPrice: (parseFloat(_discountedPrice) * itemsCount).toFixed(2),
    hasActiveDiscount,
  };
};

export interface OrderCalculationResult {
  basePrice: string | undefined;
  discountedPrice?: string | undefined;
  hasActiveDiscount?: boolean;
}

export interface OrderCalculationResult {
  basePrice: string | undefined;
  discountedPrice?: string | undefined;
  hasActiveDiscount?: boolean;
}

export const calculateOrderPrice = (unitPriceNetAmount?: number, unitPriceDiscount?: number, amount = 1): OrderCalculationResult => {
  if (unitPriceNetAmount === undefined) {
    return { basePrice: undefined, discountedPrice: undefined, hasActiveDiscount: false };
  }

  const effectiveNetAmount = unitPriceNetAmount * amount;
  const effectiveDiscount = (unitPriceDiscount ?? 0) * amount;

  const basePrice = (effectiveNetAmount + effectiveDiscount).toFixed(2);
  const discountedPrice = effectiveNetAmount.toFixed(2);
  const hasActiveDiscount = effectiveDiscount > 0;

  return {
    basePrice,
    discountedPrice,
    hasActiveDiscount,
  };
};
