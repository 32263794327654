import { makeStyles } from "@toolkit/ui";

export const useOrderMedicationsCardHeaderStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: 13,
  },
  iconWrapper: {
    "& svg": {
      width: "45px",
      height: "45px",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
