import { Typography } from "@toolkit/ui";
import { FC } from "react";
import { OrderTrackingViewProps } from "../../others/types";
import { pickLocalizedValue, Trans, useTranslation } from "@toolkit/i18n";
import { CustomCard, EmptyMessageLayout } from "@/shared/components";
import { ConfirmedOrderIcon } from "@/shared/icons";
import { useOrderCardStyles } from "./useOrderCard.styles";

export const ConfirmedOrderCard: FC<OrderTrackingViewProps> = ({ order }) => {
  const { classes } = useOrderCardStyles();
  const { t } = useTranslation("consumer");

  return (
    <CustomCard className={classes.root}>
      <EmptyMessageLayout
        classes={{
          root: classes.emptyMessageLayout,
          iconContainer: classes.iconContainer,
          messageContainer: classes.messageContainer,
        }}
        icon={<ConfirmedOrderIcon />}
        message={
          <Trans
            t={t}
            i18nKey='Your order has been confirmed <bold>By {{pharmacyName}}</bold>'
            components={{
              bold: <Typography className={classes.bold} />,
            }}
            values={{
              pharmacyName:
                pickLocalizedValue(
                  order?.branch?.name || order?.children?.[0]?.branch?.name,
                  order?.branch?.nameAr || order?.children?.[0]?.branch?.nameAr
                ) || "-",
            }}
          />
        }
      />
    </CustomCard>
  );
};
