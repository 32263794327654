import { useGetGuidedCareJourneyItemListQuery } from "../../gql";
import { guidedCareActivityTypeNonMedicalMessagesOptions } from "../CareJourneyDetails/utils";

export const useCareJourneyActivitiesListHook = (guidedCareJourneyId?: string) => {
  const {
    data: journeyListData,
    loading,
    fetchMore,
    refetch: handleRefetch,
  } = useGetGuidedCareJourneyItemListQuery({
    variables: {
      first: 10,
      filter: {
        guidedCareJourneyId: guidedCareJourneyId!,
        types: guidedCareActivityTypeNonMedicalMessagesOptions,
      },
    },
    skip: !guidedCareJourneyId,
  });
  const onFetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo.endCursor || "",
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult?.guidedCareJourneyItems?.edges?.length) return prevResult;
          return {
            ...fetchMoreResult,
            guidedCareJourneyItems: {
              ...fetchMoreResult.guidedCareJourneyItems,
              edges: [...(prevResult?.guidedCareJourneyItems?.edges || []), ...(fetchMoreResult.guidedCareJourneyItems?.edges || [])],
            },
          };
        },
      });
    }
  };

  const guidedCareJourneyList = journeyListData?.guidedCareJourneyItems?.edges?.map(edge => edge?.node);
  const pageInfo = journeyListData?.guidedCareJourneyItems?.pageInfo;
  return {
    guidedCareJourneyList,
    loading,
    onFetchMoreData,
    handleRefetch,
    pageInfo,
  };
};
