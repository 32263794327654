import { ConferenceProviderType } from "@/schema/types";
import { FC, PropsWithChildren } from "react";
import { ZoomPublisherView, AntMediaPublisherView, CallInstance } from "./types";

type CallPublisherView = PropsWithChildren<{
  call: CallInstance;
}>;

export const CallPublisherView: FC<CallPublisherView> = props => {
  const { call } = props;

  switch (call.callType) {
    case ConferenceProviderType.Zoom:
      return <ZoomPublisherView {...props} />;
    case ConferenceProviderType.AntMedia:
      return <AntMediaPublisherView {...props} />;
    default:
      return null;
  }
};
