import { Box, CustomInfiniteScroll, makeStyles } from "@toolkit/ui";
import { FC } from "react";
import { useTranslation } from "@/i18n/i18n.config";
import { EmptyMessageLayout } from "@/shared/components";
import { GuidedCareJourneyItem } from "@/schema/types";
import { DefaultEmptyIcon } from "@/shared/icons";
import { CareJourneyActivitiesCardSkeleton } from "../CareJourneyDetails/CareJourneyActivitiesCardSkeleton";
import { CareJourneyActivitiesItemCard } from "./CareJourneyActivitiesItemCard";
import { useCareJourneyActivitiesListHook } from "./useCareJourneyActivitiesListHook";

export const CareJourneyActivitiesList: FC<{ guidedCareJourneyId?: string }> = ({ guidedCareJourneyId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { guidedCareJourneyList, handleRefetch, loading, onFetchMoreData, pageInfo } =
    useCareJourneyActivitiesListHook(guidedCareJourneyId);
  return (
    <>
      {loading &&
        !guidedCareJourneyList?.length &&
        Array(3)
          .fill(1)
          .map((_, index) => (
            <Box
              key={index}
              sx={{
                marginInline: 1,
              }}
            >
              <CareJourneyActivitiesCardSkeleton />
            </Box>
          ))}

      <Box className={classes.content}>
        <CustomInfiniteScroll
          dataLength={Number(guidedCareJourneyList?.length) || 1}
          onFetchMore={onFetchMoreData}
          hasMore={pageInfo?.hasNextPage}
          height={"fit-content"}
          onRefresh={handleRefetch}
        >
          {
            <>
              {!loading && !guidedCareJourneyList?.length && (
                <Box className={classes.emptyPageContainer}>
                  <EmptyMessageLayout icon={<DefaultEmptyIcon />} message={t("No Care Journeys Found")} />
                </Box>
              )}

              {guidedCareJourneyList?.map(guidedCareJourneyItem => {
                return (
                  <CareJourneyActivitiesItemCard
                    key={guidedCareJourneyItem.id}
                    guidedCareJourneyItem={guidedCareJourneyItem as GuidedCareJourneyItem}
                  />
                );
              })}
            </>
          }
        </CustomInfiniteScroll>
      </Box>
    </>
  );
};
const useStyles = makeStyles()(() => ({
  content: {
    margin: "10px",
    borderRadius: "10px",
  },
  emptyPageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flex: 1,
    justifySelf: "center",
    alignSelf: "center",
    padding: "10px",
    paddingBottom: "10px",
  },
}));
