import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyMarketplaceCartQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyMarketplaceCartQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, marketplaceCart?: { __typename?: 'MarketplaceCart', id: string, totalPrice: any, totalDiscount: any, totalPriceWithVAT: any, totalVat: any, totalDeliveryPrice: any, items: Array<{ __typename?: 'MarketplaceCartItem', quantity: number, id: string, healthPackage?: { __typename?: 'MarketplaceHealthPackage', description?: string | null, descriptionAr?: string | null, id: string, mainImageUrl?: string | null, name?: string | null, nameAr?: string | null, price?: any | null, targetToAge?: number | null, shortDescriptionAr?: string | null, shortDescription?: string | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, logo?: string | null }, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, endDate: any, percentage?: number | null, startDate: any, type: Types.MarketplaceDiscountType } | null } | null, product?: { __typename?: 'MarketplaceProduct', id: string, mainImageUrl?: string | null, name?: string | null, nameAr?: string | null, price?: any | null, sku?: string | null, stockQuantity: number, shortDescription?: string | null, shortDescriptionAr?: string | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, logo?: string | null }, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, endDate: any, percentage?: number | null, startDate: any, type: Types.MarketplaceDiscountType } | null } | null }> } | null } | null };


export const MyMarketplaceCartDocument = gql`
    query MyMarketplaceCart {
  me {
    id
    marketplaceCart {
      id
      items {
        quantity
        id
        healthPackage {
          description
          descriptionAr
          id
          mainImageUrl
          name
          nameAr
          price
          targetToAge
          shortDescriptionAr
          shortDescription
          vendor {
            id
            name
            nameAr
            logo
          }
          activeDiscount {
            amount
            endDate
            percentage
            startDate
            type
          }
        }
        product {
          id
          mainImageUrl
          name
          nameAr
          price
          sku
          stockQuantity
          shortDescription
          shortDescriptionAr
          vendor {
            id
            name
            nameAr
            logo
          }
          activeDiscount {
            amount
            endDate
            percentage
            startDate
            type
          }
        }
      }
      totalPrice
      totalDiscount
      totalPriceWithVAT
      totalVat
      totalDeliveryPrice
    }
  }
}
    `;

/**
 * __useMyMarketplaceCartQuery__
 *
 * To run a query within a React component, call `useMyMarketplaceCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMarketplaceCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMarketplaceCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyMarketplaceCartQuery(baseOptions?: Apollo.QueryHookOptions<MyMarketplaceCartQuery, MyMarketplaceCartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyMarketplaceCartQuery, MyMarketplaceCartQueryVariables>(MyMarketplaceCartDocument, options);
      }
export function useMyMarketplaceCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyMarketplaceCartQuery, MyMarketplaceCartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyMarketplaceCartQuery, MyMarketplaceCartQueryVariables>(MyMarketplaceCartDocument, options);
        }
export function useMyMarketplaceCartSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyMarketplaceCartQuery, MyMarketplaceCartQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyMarketplaceCartQuery, MyMarketplaceCartQueryVariables>(MyMarketplaceCartDocument, options);
        }
export type MyMarketplaceCartQueryHookResult = ReturnType<typeof useMyMarketplaceCartQuery>;
export type MyMarketplaceCartLazyQueryHookResult = ReturnType<typeof useMyMarketplaceCartLazyQuery>;
export type MyMarketplaceCartSuspenseQueryHookResult = ReturnType<typeof useMyMarketplaceCartSuspenseQuery>;
export type MyMarketplaceCartQueryResult = Apollo.QueryResult<MyMarketplaceCartQuery, MyMarketplaceCartQueryVariables>;