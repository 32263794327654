import React from "react";
import { Box, Grid, makeStyles, Skeleton } from "@toolkit/ui";

export const CareJourneyCardSkeleton: React.FC = () => {
  const { classes } = useStyle();
  return (
    <Box className={classes.cardWrapper} role='button'>
      <Grid container alignItems='center' className={classes.cardContent}>
        <div className={classes.imageContainer}>
          <Skeleton variant='circular' width={100} height={100} />
          <div className={classes.leaderLabel}>
            <Skeleton variant='text' width={80} height={20} />
          </div>
        </div>
        <Grid item xs>
          <Skeleton variant='text' width='60%' height={20} />
          <Skeleton variant='text' width='80%' height={20} />
          <Skeleton variant='text' width='50%' height={20} />
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyle = makeStyles()(theme => ({
  cardWrapper: {
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    padding: 16,
    margin: 10,
    display: "flex",
    alignItems: "center",
    boxShadow: theme.shadows[3],
    cursor: "pointer",
    transition: "all 0.3s ease",
    "&:hover": {
      boxShadow: theme.shadows[6],
    },
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  imageContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
  },
  leaderLabel: {
    position: "absolute",
    bottom: -12,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 20,
    padding: "4px 12px",
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
  },
}));
