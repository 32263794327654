import { useCallback, useMemo } from "react";
import { useChatGroupsQuery } from "../../gql/queries";
import { ChatGroup, ChatGroupSortingField, CommunicationSortDirection } from "../../schema/types";

export const useChatGroupsHooks = () => {
  const { data, fetchMore, loading, refetch, error } = useChatGroupsQuery({
    variables: {
      first: 10,
      sortBy: {
        field: ChatGroupSortingField.Created,
        direction: CommunicationSortDirection.Desc,
      },
    },
  });
  const chatGroups = useMemo(() => data?.chatGroups?.edges.map(e => e.node) as ChatGroup[], [data]);
  const keyExtractor = useCallback((item: ChatGroup) => item.id, []);
  const pageInfo = data?.chatGroups?.pageInfo;
  const hasMore = pageInfo?.hasNextPage;
  const hasError = !!error;
  const isEmpty = !chatGroups || chatGroups.length === 0;

  const handleRetry = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const handleFetchMore = useCallback(() => {
    if (pageInfo?.hasNextPage && !loading) {
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            chatGroups: {
              ...fetchMoreResult.chatGroups,
              edges: [...(prev?.chatGroups?.edges || []), ...(fetchMoreResult?.chatGroups?.edges || [])],
            },
          });
        },
      });
    }
  }, [fetchMore, pageInfo?.endCursor, pageInfo?.hasNextPage, loading]);

  return {
    data: chatGroups,
    hasMore,
    hasError,
    isEmpty,
    loading,
    keyExtractor,
    handleFetchMore,
    handleRetry,
  };
};
