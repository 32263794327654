import { useTranslation } from "@toolkit/i18n";
import { FC, useMemo } from "react";
import { CalculationFormRadio, CalculationFormRadioProps } from "./CalculationFormRadio";

export const CalculationYesNoRadio: FC<Omit<CalculationFormRadioProps<string>, "options"> & { inputLabel: string }> = props => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return [
      {
        label: t("Yes"),
        value: "yes",
      },
      {
        label: t("No"),
        value: "no",
      },
    ];
  }, []);
  return <CalculationFormRadio<string> options={options} {...props} />;
};
