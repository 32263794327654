import { orderMedicationsRoutes } from "@/domains/order-medication";
import { getUrlPrescriptionOrderInfo } from "@/domains/order-medication/others/utils";
import queryString from "query-string";
import { FC, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const PrescriptionOrderController: FC = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const params = queryString.parse(location.search);
  const urlInfo = getUrlPrescriptionOrderInfo(params);

  useEffect(() => {
    if (isLoading) return;
    if (!urlInfo || (!isAuthenticated && urlInfo?.sourceType === "application")) {
      navigate(orderMedicationsRoutes.root.absRoute, { replace: true });
    }
    if (urlInfo?.sourceType === "external" && !isAuthenticated) {
      navigate(orderMedicationsRoutes.root.getExternalPickupSmsRouteRouteWithParams(urlInfo?.params), { replace: true });
    }
  }, [isAuthenticated, navigate, urlInfo, isLoading]);

  return <Outlet context={urlInfo} />;
};
