import { MarketplaceProductDetails } from "@/domains/marketplace-products/types";
import { MarketplaceContentType } from "@/schema/types";
import { EmptyMessageDefault, HtmlRenderer } from "@/shared/components";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { marked } from "marked";
import { FC } from "react";

type ProductTabBodyProps = {
  product: MarketplaceProductDetails;
};
export const DescriptionBody: FC<ProductTabBodyProps> = props => {
  const { product } = props;
  const { t } = useTranslation("consumer");
  let text = pickLocalizedValue(product?.description, product?.descriptionAr) || "";
  text = product?.contentType !== MarketplaceContentType.Html ? marked(text, { async: false }) : text;
  return text ? <HtmlRenderer htmlContent={text} /> : <EmptyMessageDefault message={t("No description provided")} />;
};
