import { ProductCardSkeleton } from "@/domains/marketplace-health-packages";
import { MarketplaceProductFilterInput, MarketplaceProductSortingInput } from "@/schema/types";
import { EmptyMessageLayout } from "@/shared/components";
import { useContainerHeight } from "@/shared/hooks";
import { EmptyMarketplaceIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomInfiniteScroll } from "@toolkit/ui";
import { isEmpty } from "lodash";
import { FC } from "react";
import { ProductCard } from "../ProductCard/ProductCard";
import { useProductsList } from "./useProductsList";
import { useStyles } from "./useProductsListStyle";

type ProductsListProps = {
  filters?: MarketplaceProductFilterInput;
  sortingInput?: MarketplaceProductSortingInput;
};

export const ProductsList: FC<ProductsListProps> = ({ filters, sortingInput }) => {
  const { products, isLoading, fetchMoreData, hasMore, handleRefresh } = useProductsList(filters, sortingInput);
  const { classes } = useStyles();
  const { containerRef, containerHeight } = useContainerHeight();
  const { t } = useTranslation("consumer");

  const message = isEmpty(filters) ? t("No products available") : t("Sorry, no result found");

  const subMessage = isEmpty(filters) ? "" : t("Try adjusting your search or filters");

  return (
    <>
      {isLoading && !products.length && (
        <Box className={classes.productGrid}>
          {Array.from({ length: 10 }).map((_, index) => (
            <Box key={`loader-${index}`} className={classes.cardWrapper}>
              <ProductCardSkeleton />
            </Box>
          ))}
        </Box>
      )}

      <Box ref={containerRef} className={classes.content}>
        <CustomInfiniteScroll
          dataLength={products?.length || 1}
          onFetchMore={fetchMoreData}
          hasMore={hasMore}
          loader={
            <Box mt={"10px"} className={classes.productGrid}>
              {Array.from({ length: 2 }).map((_, index) => (
                <Box key={`loader-${index}`} className={classes.cardWrapper}>
                  <ProductCardSkeleton />
                </Box>
              ))}
            </Box>
          }
          height={(containerHeight || 15) - 10}
          onRefresh={handleRefresh}
        >
          {!isLoading && !products.length && (
            <Box className={classes.emptyPageContainer}>
              <EmptyMessageLayout icon={<EmptyMarketplaceIcon />} message={message} subMessage={subMessage} />
            </Box>
          )}

          {products?.length > 0 && (
            <Box className={classes.productGrid}>
              {products.map(product => (
                <Box key={`product-${product?.id}`} className={classes.cardWrapper}>
                  <ProductCard product={product} />
                </Box>
              ))}
            </Box>
          )}
        </CustomInfiniteScroll>
      </Box>
    </>
  );
};
