import { CustomCard, SectionTabs } from "@/shared/components";
import { Box, Skeleton } from "@toolkit/ui";
import { FC, useState } from "react";
import { MarketplaceHealthPackageDetails } from "../../types";
import { useHealthPackageDetailsTabsStyle } from "./HealthPackageDetailsTabsStyle";
import { tabs } from "./tabs";

type HealthPackageDetailsTabsProps = {
  visibleTabsKeys?: (typeof tabs)[number]["key"][];
  healthPackage: MarketplaceHealthPackageDetails;
  isLoading: boolean;
};

export const HealthPackageDetailsTabs: FC<HealthPackageDetailsTabsProps> = props => {
  const { visibleTabsKeys = [], healthPackage, isLoading } = props;
  const { classes } = useHealthPackageDetailsTabsStyle();

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const visibleTabs = tabs.filter(tab => visibleTabsKeys.includes(tab.key));
  const tabsLabel = visibleTabs.map(tab => tab.label);
  const CurrentTab = visibleTabs[selectedTabIndex]?.component;

  const onTabClick = (_, id: number) => {
    setSelectedTabIndex(id);
  };

  if (isLoading || !healthPackage) {
    return (
      <CustomCard className={classes.root}>
        <Skeleton variant='text' className={classes.headerRoot} width='80%' />
        <Box className={classes.tabBodyContainer}>
          <Skeleton variant='rectangular' width='100%' height='150px' />
        </Box>
      </CustomCard>
    );
  }

  return (
    <CustomCard className={classes.root}>
      <SectionTabs
        tabs={tabsLabel}
        activeTabIndex={selectedTabIndex}
        onTabClick={onTabClick}
        classes={{
          root: classes.headerRoot,
          tabsContainerClassName: classes.tabsContainerClassName,
        }}
      />
      <Box className={classes.tabBodyContainer}>{CurrentTab && <CurrentTab healthPackage={healthPackage!} />}</Box>
    </CustomCard>
  );
};
