import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(() => ({
  content: {
    flexGrow: 1,
    maxHeight: "100%",
  },
  emptyPageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "calc(100% - 20px)",
    height: "100%",
    padding: "10px",
    marginInline: "10px",
    paddingBottom: "100px",
  },
  scheduleButton: {
    height: "45px",
    paddingInline: "20px",
    borderRadius: "20px",
  },
  productGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, calc(50% - 5px))",
    gap: "10px",
    width: "100%",
    paddingInline: "10px",
  },
  cardWrapper: {
    height: "290px",
    width: "100%",
  },
}));
