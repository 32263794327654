import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const ClockIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.common.white;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='14.635' height='14.635' viewBox='0 0 14.635 14.635' {...props}>
      <path
        id='icons8-clock'
        d='M4.395,1.2a.53.53,0,0,0-.287.082A8.511,8.511,0,0,0,1.281,4.1a.53.53,0,0,0,.905.553A7.452,7.452,0,0,1,4.661,2.182.53.53,0,0,0,4.395,1.2Zm4.124.957a6.363,6.363,0,1,0,6.363,6.363A6.363,6.363,0,0,0,8.518,2.152Zm0,1.06h.006a.527.527,0,0,1,.527.527V9.045H4.8a.526.526,0,0,1-.527-.527V8.512A.527.527,0,0,1,4.8,7.985H7.988V3.74A.526.526,0,0,1,8.515,3.213Zm6.81,8.9a.53.53,0,0,0-.474.26,7.452,7.452,0,0,1-2.475,2.475.53.53,0,1,0,.553.905,8.511,8.511,0,0,0,2.827-2.827.53.53,0,0,0-.431-.813Z'
        transform='translate(-1.203 -1.195)'
        fill={fill}
      />
      <svg xmlns='http://www.w3.org/2000/svg' width='14.635' height='14.635' viewBox='0 0 14.635 14.635'>
        <path
          id='icons8-clock'
          d='M4.395,1.2a.53.53,0,0,0-.287.082A8.511,8.511,0,0,0,1.281,4.1a.53.53,0,0,0,.905.553A7.452,7.452,0,0,1,4.661,2.182.53.53,0,0,0,4.395,1.2Zm4.124.957a6.363,6.363,0,1,0,6.363,6.363A6.363,6.363,0,0,0,8.518,2.152Zm0,1.06h.006a.527.527,0,0,1,.527.527V9.045H4.8a.526.526,0,0,1-.527-.527V8.512A.527.527,0,0,1,4.8,7.985H7.988V3.74A.526.526,0,0,1,8.515,3.213Zm6.81,8.9a.53.53,0,0,0-.474.26,7.452,7.452,0,0,1-2.475,2.475.53.53,0,1,0,.553.905,8.511,8.511,0,0,0,2.827-2.827.53.53,0,0,0-.431-.813Z'
          transform='translate(-1.203 -1.195)'
          fill={fill}
        />
      </svg>
    </SvgIcon>
  );
};
