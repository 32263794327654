import { AddressesList } from "@/domains/address/components/AddressesList/AddressesList";
import { AddressFragmentFragment } from "@/domains/address/gql/fragments";
import { useAccountSettingsInformationQuery } from "@/pages/AccountSettings/gql/queries";
import { useHeaderContext } from "@/shared/context";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, Typography } from "@toolkit/ui";
import { find } from "lodash";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, Location } from "react-router-dom";
import { orderMedicationsRoutes } from "../../routes";
import { AddressSelectionContainerDataProps, DeliveryTimeSelectionContainerDataProps } from "../../others/types";
import { useDispensePrescription } from "../../hooks";
import { DeliveryTypes } from "@/schema/types";
import { useSiteSettingsGetQuery } from "@/gql";

export const AddressSelectionContainer: FC = () => {
  const { classes, cx } = useCommonContainerStyles();
  const location: Location<AddressSelectionContainerDataProps> = useLocation();
  const navData = location?.state || {};
  const [selectedAddress, setSelectedAddress] = useState<AddressFragmentFragment | null>(null);

  const { data: settingsData, loading: isSettingsLoading } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });

  const { t } = useTranslation("consumer");

  const navigate = useNavigate();

  const { setHeaderTitle } = useHeaderContext();

  const { data, loading } = useAccountSettingsInformationQuery({
    fetchPolicy: "cache-and-network",
  });
  const settings = settingsData?.siteSettings;
  const addresses = data?.me?.addresses;

  const getItemSelectionStatus = (id?: string) => {
    if (selectedAddress) {
      return selectedAddress?.id === id;
    }
    return undefined;
  };

  const handleOnAddressSelect = (address: AddressFragmentFragment) => {
    setSelectedAddress(address);
  };
  const { loading: isSubmitting, handleRequestDispensePrescription } = useDispensePrescription({
    addressId: selectedAddress?.id || "",
    isDeliveryRequested: navData.selectedDeliveryType === DeliveryTypes.Delivery,
    prescriptionId: navData?.prescriptionID,
  });

  const handleContinue = () => {
    if (selectedAddress) {
      if (navData.selectedDeliveryType === DeliveryTypes.Delivery && settings?.scheduleDeliveryEnabled)
        navigate(orderMedicationsRoutes.deliveryTimeSelection.absRoute, {
          state: { ...navData, selectedAddress } as DeliveryTimeSelectionContainerDataProps,
        });
      else {
        handleRequestDispensePrescription();
      }
    }
  };

  useEffect(() => {
    setHeaderTitle(navData.selectedDeliveryType === DeliveryTypes.Delivery ? t("Deliver to Address") : t("Select your Area"));
    return () => {
      setHeaderTitle("");
    };
  }, [navData.selectedDeliveryType]);

  useEffect(() => {
    if (addresses && !selectedAddress) {
      find(addresses, address => {
        if (address?.isDefaultShippingAddress) {
          setSelectedAddress(address);
          return true;
        }
      });
    }
  }, [addresses]);

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography className={classes.pageTitle}>{t("Deliver to")}</Typography>
        {!loading ? (
          <AddressesList
            hideDelete
            isCardSelectable
            onSelect={handleOnAddressSelect}
            getItemSelectionStatus={getItemSelectionStatus}
            addresses={addresses}
          />
        ) : (
          <CircularProgress />
        )}
      </Box>
      <Box className={cx(classes.footer)}>
        <Button disabled={!selectedAddress || isSubmitting || isSettingsLoading} onClick={handleContinue} className={cx(classes.button)}>
          {t("Continue")}
        </Button>
      </Box>
    </Box>
  );
};
