import { CircularIconButton } from "@/shared/components";
import { SupplementIcon } from "@/shared/icons";
import { Box, InlineSVG, TruncateTypography } from "@toolkit/ui";
import { FC } from "react";
import { useStyles } from "./MarketplaceCategoryButtonStyle";

interface MarketplaceCategoryItemButtonProps {
  title?: string | null;
  imgSrc?: string | null;
  onClick?: () => void;
  classes?: Partial<ReturnType<typeof useStyles>["classes"]>;
  isSelected?: boolean;
  DefaultSvg?: React.ElementType;
}

export const MarketplaceCategoryItemButton: FC<MarketplaceCategoryItemButtonProps> = props => {
  const { title, imgSrc, DefaultSvg, isSelected, onClick, classes: _classes } = props;
  const { classes, cx } = useStyles();
  const isSvg = imgSrc?.endsWith(".svg");
  return (
    <Box className={cx(classes?.root, _classes?.root)}>
      <CircularIconButton onClick={onClick} className={cx(classes?.button, _classes?.button, isSelected && classes.button__selected)}>
        {imgSrc &&
          (isSvg ? (
            <InlineSVG className={cx(classes.icon, _classes?.icon)} src={imgSrc!} />
          ) : (
            <img
              onError={e => (e.currentTarget.style.display = "none")}
              src={imgSrc!}
              className={cx(classes.icon, _classes?.icon)}
              alt=''
            />
          ))}
        {!imgSrc &&
          ((DefaultSvg && <DefaultSvg className={cx(classes.icon, _classes?.icon)} />) || (
            <SupplementIcon className={cx(classes.icon, _classes?.icon)} />
          ))}
      </CircularIconButton>
      <TruncateTypography maxWidth='100%' maxLines={2} className={cx(classes?.title, _classes?.title)} text={title || ""} />
    </Box>
  );
};
