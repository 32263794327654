import { PatientHealthDocument } from "@/schema/types";
import { EmptyMessageLayout } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomInfiniteScroll, EmptyDataIcon, IconButton, MuiDeleteIcon, Typography } from "@toolkit/ui";
import moment from "moment";
import React from "react";
import { useStyles } from "../DocumentContainer/MyDocumentsContainerStyles";
import { useDocumentsHook } from "../DocumentContainer/useDocumentsHook";
import { DocumentListSkeleton } from "./DocumentsListSkeleton";

export const DocumentsList: React.FC = () => {
  const { t } = useTranslation("consumer");
  const { classes } = useStyles();
  const { healthDocuments, isLoading, containerHeight, containerRef, handleDeleteHealthDocument, isDeleteLoading, onGoToUploadDocument } =
    useDocumentsHook();

  if (isLoading) {
    return (
      <Box className={classes.loader}>
        {Array.from({ length: 5 }).map((_, index) => (
          <DocumentListSkeleton key={index} />
        ))}
      </Box>
    );
  }

  return (
    <>
      <Box ref={containerRef} className={classes.box} flex={1}>
        <CustomInfiniteScroll
          height={(containerHeight || 10) - 15}
          dataLength={healthDocuments?.length}
          loader={<CircularProgress className={classes.loader} />}
        >
          {!healthDocuments?.length && (
            <Box className={classes.emptyPageContainer}>
              <EmptyMessageLayout icon={<EmptyDataIcon />} message={t("No transactions found")} />
            </Box>
          )}

          {healthDocuments?.map(document => {
            const { id, description, resultDate, type, name } = document as PatientHealthDocument;
            return (
              <Box key={id} className={classes.rootBox}>
                <Typography className={classes.title}>{name}</Typography>
                {description && <Typography className={classes.text}>{description}</Typography>}
                {!!resultDate && <Typography className={classes.text}>{moment(resultDate).format("DD/MM/YYYY")}</Typography>}
                <Box className={classes.typeBox}>
                  <Box className={classes.dot} />
                  <Typography className={classes.type}>{type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}</Typography>
                </Box>

                <Box className={classes.deleteButton}>
                  <IconButton aria-label='delete' disabled={isDeleteLoading} onClick={handleDeleteHealthDocument(id)}>
                    <MuiDeleteIcon color={"secondary"} />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
        </CustomInfiniteScroll>
      </Box>
      <Box className={classes.footer}>
        <Button disabled={isLoading} onClick={onGoToUploadDocument} className={classes.button}>
          {t("Upload Document")}
        </Button>
      </Box>
    </>
  );
};
