import { SvgIconProps, SvgIcon } from "@toolkit/ui";
import { FC } from "react";

export const EmptyMedicationsOrderIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='Group_12177'
      data-name='Group 12177'
      width='126.332'
      height='124.763'
      viewBox='0 0 126.332 124.763'
      {...props}
    >
      <defs>
        <linearGradient id='linear-gradient' y1='-3.199' x2='1.001' y2='-3.199' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#cacfdd' />
          <stop offset='1' stopColor='#eef0f1' />
        </linearGradient>
      </defs>
      <g id='pharmacyV2' transform='translate(0 0)'>
        <g id='Group_11644' data-name='Group 11644' transform='translate(0 0)'>
          <g id='Group_11643' data-name='Group 11643' transform='translate(0 0)'>
            <g id='Group_11642' data-name='Group 11642'>
              <path
                id='Path_12746_00000121959265908568702120000003119399686751359141_'
                d='M95.461,197.585c-14.477-3.274-27.546-8.445-39.581-17.636-11.92-9.163-22.864-22.261-23.295-35.675-.287-13.385,9.852-27.029,20.911-37.743a110.6,110.6,0,0,1,37-24.472c14.247-5.888,31.28-9.737,42.913-3.562,11.576,6.233,17.751,22.491,21.227,37.542,3.533,15.022,4.337,28.838,1.752,43.2s-8.588,29.154-19.561,35.9c-10.972,6.836-26.857,5.572-41.362,2.441'
                transform='translate(-32.579 -75.352)'
                fill='#283275'
                opacity='0.09'
              />
            </g>
          </g>
        </g>
        <g id='Group_11645' data-name='Group 11645' transform='translate(16.292 4.408)'>
          <g id='shop_open' transform='translate(0 0)'>
            <path
              id='Path_14927'
              data-name='Path 14927'
              d='M247.941,116.383l-21.169-13.73-20.681,13.73-.89-1.35L226.743,100.7l22.088,14.333Z'
              transform='translate(-171.909 -97.828)'
              fill='#535783'
            />
            <circle
              id='Ellipse_3106'
              data-name='Ellipse 3106'
              cx='3.073'
              cy='3.073'
              r='3.073'
              transform='translate(51.789 0.776)'
              fill='#283275'
            />
            <path
              id='Path_14928'
              data-name='Path 14928'
              d='M270.678,98.455a3.878,3.878,0,1,1,3.878-3.878A3.88,3.88,0,0,1,270.678,98.455Zm0-6.147a2.269,2.269,0,1,0,2.269,2.269,2.274,2.274,0,0,0-2.269-2.269Z'
              transform='translate(-215.816 -90.7)'
              fill='#283275'
            />
            <rect
              id='Rectangle_7665'
              data-name='Rectangle 7665'
              width='95.075'
              height='61.641'
              transform='translate(7.468 42.453)'
              fill='#fff'
            />
            <rect
              id='Rectangle_7666'
              data-name='Rectangle 7666'
              width='95.075'
              height='4.653'
              transform='translate(7.468 51.157)'
              fill='#d3d9ed'
            />
            <path
              id='Path_14929'
              data-name='Path 14929'
              d='M209.184,298.949H112.5V235.7h96.684Zm-95.046-1.608h93.438V237.309H114.137Z'
              transform='translate(-105.836 -194.051)'
              fill='#535783'
            />
            <rect
              id='Rectangle_7667'
              data-name='Rectangle 7667'
              width='19.475'
              height='35.646'
              transform='translate(21.686 68.448)'
              fill='#f5f5f5'
            />
            <rect
              id='Rectangle_7668'
              data-name='Rectangle 7668'
              width='19.475'
              height='35.646'
              transform='translate(21.686 68.448)'
              fill='#d3d9ed'
            />
            <rect
              id='Rectangle_7669'
              data-name='Rectangle 7669'
              width='19.475'
              height='35.646'
              transform='translate(41.161 68.448)'
              fill='#f5f5f5'
            />
            <rect
              id='Rectangle_7670'
              data-name='Rectangle 7670'
              width='19.475'
              height='35.646'
              transform='translate(41.161 68.448)'
              fill='#d3d9ed'
            />
            <path
              id='Path_14930'
              data-name='Path 14930'
              d='M183.112,363.454H162V326.2h21.112Zm-19.5-1.609h17.866V327.809H163.609Z'
              transform='translate(-141.118 -258.556)'
              fill='#535783'
            />
            <path
              id='Path_14931'
              data-name='Path 14931'
              d='M250.912,363.454H229.8V326.2h21.112Zm-19.5-1.609h17.866V327.809H231.409Z'
              transform='translate(-189.443 -258.556)'
              fill='#535783'
            />
            <rect
              id='Rectangle_7671'
              data-name='Rectangle 7671'
              width='6.75'
              height='1.637'
              transform='translate(37.829 87.607)'
              fill='#535783'
            />
            <rect
              id='Rectangle_7672'
              data-name='Rectangle 7672'
              width='15.137'
              height='15.137'
              transform='translate(75.141 68.448)'
              fill='#d3d9ed'
            />
            <path
              id='Path_14932'
              data-name='Path 14932'
              d='M364.775,342.975H348V326.2h16.774Zm-15.137-1.637h13.529V327.809H349.637Z'
              transform='translate(-273.692 -258.556)'
              fill='#535783'
            />
            <path
              id='Path_14933'
              data-name='Path 14933'
              d='M361.225,384.243H340.4V378.9h20.825Zm-19.187-1.637h17.55v-2.068h-17.55Z'
              transform='translate(-268.275 -296.119)'
              fill='#535783'
            />
            <path
              id='Path_14934'
              data-name='Path 14934'
              d='M205.013,246.012H102.7V235.7H204.985v10.312Zm-100.676-1.637h99.039v-7.066H104.337Z'
              transform='translate(-98.851 -194.051)'
              fill='#535783'
            />
            <rect
              id='Rectangle_7673'
              data-name='Rectangle 7673'
              width='100.676'
              height='8.675'
              transform='translate(4.682 42.453)'
              fill='#535783'
            />
            <path
              id='Path_14935'
              data-name='Path 14935'
              d='M154.405,153h67.788a3.714,3.714,0,0,1,3.705,3.705v26.081a3.714,3.714,0,0,1-3.705,3.705H154.405a3.714,3.714,0,0,1-3.705-3.705V156.705A3.7,3.7,0,0,1,154.405,153Z'
              transform='translate(-133.064 -135.105)'
              fill='#283275'
            />
            <rect
              id='Rectangle_7674'
              data-name='Rectangle 7674'
              width='66.868'
              height='25.162'
              transform='translate(21.801 22.06)'
              fill='#fff'
            />
            <path
              id='Path_14936'
              data-name='Path 14936'
              d='M230.877,191.4H162.4V164.6h68.477Zm-66.84-1.609h65.231V166.237H164.037Z'
              transform='translate(-141.403 -143.373)'
              fill='#283275'
            />
            <path
              id='Path_14937'
              data-name='Path 14937'
              d='M220.126,185.258H152.338a4.567,4.567,0,0,1-4.538-4.538V154.638a4.567,4.567,0,0,1,4.538-4.538h67.788a4.548,4.548,0,0,1,4.538,4.538v26.081A4.567,4.567,0,0,1,220.126,185.258Zm-67.788-33.52a2.893,2.893,0,0,0-2.9,2.9v26.081a2.893,2.893,0,0,0,2.9,2.9h67.788a2.893,2.893,0,0,0,2.9-2.9V154.638a2.893,2.893,0,0,0-2.9-2.9Z'
              transform='translate(-130.997 -133.038)'
              fill='#283275'
            />
            <path
              id='Path_14938'
              data-name='Path 14938'
              d='M90.1,450.3H198.536a.8.8,0,0,1,.8.8h0a.8.8,0,0,1-.8.8H90.1a.8.8,0,0,1-.8-.8h0A.785.785,0,0,1,90.1,450.3Z'
              transform='translate(-89.3 -347.01)'
              fill='#535783'
            />
            <path
              id='Path_14939'
              data-name='Path 14939'
              d='M195.851,331.8H158.022l-3.734,4.079V337.2a4.487,4.487,0,0,0,8.962.46v-.431a4.487,4.487,0,0,0,8.962,0,4.475,4.475,0,0,0,8.933,0,4.487,4.487,0,0,0,8.962.46v-.431a4.487,4.487,0,1,0,8.962.459v-1.838Z'
              transform='translate(-135.617 -262.547)'
              fill='url(#linear-gradient)'
            />
            <path
              id='Path_14940'
              data-name='Path 14940'
              d='M188.6,319.2l-3.1,4.337V324.6a4.487,4.487,0,0,0,8.962,0v-1.063l.919-4.337Z'
              transform='translate(-157.868 -253.567)'
              fill='#f5f5f5'
            />
            <path
              id='Path_14941'
              data-name='Path 14941'
              d='M158.022,319.2l-3.734,4.079V324.6a4.487,4.487,0,1,0,8.962.46v-1.494l3.1-4.337Z'
              transform='translate(-135.617 -253.567)'
              fill='#283275'
            />
            <path
              id='Path_14942'
              data-name='Path 14942'
              d='M251.479,319.2H244.7l.919,4.337V324.6a4.487,4.487,0,1,0,8.962.46v-1.494Z'
              transform='translate(-200.064 -253.567)'
              fill='#f5f5f5'
            />
            <path
              id='Path_14943'
              data-name='Path 14943'
              d='M277.189,319.2H268.4l3.1,4.337V324.6a4.487,4.487,0,1,0,8.962.46v-1.752Z'
              transform='translate(-216.956 -253.567)'
              fill='#283275'
            />
            <path
              id='Path_14944'
              data-name='Path 14944'
              d='M224.814,319.2h-7.095l-.919,4.337V324.6a4.475,4.475,0,0,0,8.933,0v-1.063Z'
              transform='translate(-180.177 -253.567)'
              fill='#283275'
            />
            <path
              id='Path_14945'
              data-name='Path 14945'
              d='M187.985,327.889A5.289,5.289,0,0,1,182.7,322.6v-1.321l3.5-4.883h8.215l-1.12,5.256v.977A5.308,5.308,0,0,1,187.985,327.889Zm-3.677-6.089v.8a3.667,3.667,0,0,0,7.324,0v-1.149l.747-3.418h-5.343Z'
              transform='translate(-155.872 -251.571)'
              fill='#535783'
            />
            <path
              id='Path_14946'
              data-name='Path 14946'
              d='M156.785,327.889A5.289,5.289,0,0,1,151.5,322.6v-1.637l4.194-4.567h10.283l-3.878,5.4v.8A5.333,5.333,0,0,1,156.785,327.889Zm-3.677-6.29V322.6a3.667,3.667,0,1,0,7.325.373v-1.723l2.327-3.246h-6.377Z'
              transform='translate(-133.634 -251.571)'
              fill='#535783'
            />
            <path
              id='Path_14947'
              data-name='Path 14947'
              d='M247.605,327.961a5.29,5.29,0,0,1-5.285-5.285v-.948L241.2,316.5h8.215l3.5,4.883V322.7A5.308,5.308,0,0,1,247.605,327.961Zm-4.395-9.881.747,3.418v1.149a3.667,3.667,0,0,0,7.324.373v-1.206l-2.7-3.792h-5.371Z'
              transform='translate(-197.569 -251.642)'
              fill='#535783'
            />
            <path
              id='Path_14948'
              data-name='Path 14948'
              d='M271.963,327.889a5.289,5.289,0,0,1-5.285-5.285v-.8l-3.878-5.4h10.771l3.677,4.6V322.6A5.271,5.271,0,0,1,271.963,327.889Zm-6-9.881,2.327,3.246v1.321a3.667,3.667,0,0,0,7.325.373v-1.436L272.8,317.98Z'
              transform='translate(-212.965 -251.571)'
              fill='#535783'
            />
            <path
              id='Path_14949'
              data-name='Path 14949'
              d='M219.185,327.889A5.289,5.289,0,0,1,213.9,322.6v-1.149l1.091-5.055h8.416l1.092,5.055V322.6A5.352,5.352,0,0,1,219.185,327.889Zm-3.677-6.233v.977a3.67,3.67,0,0,0,7.325,0v-.977l-.776-3.619h-5.773Z'
              transform='translate(-178.11 -251.571)'
              fill='#535783'
            />
          </g>
        </g>
        <g id='Group_11646' data-name='Group 11646' transform='translate(54.925 30.317)'>
          <circle
            id='Ellipse_3107'
            data-name='Ellipse 3107'
            cx='7.669'
            cy='7.669'
            r='7.669'
            transform='translate(0.776 0.747)'
            fill='#d3d9ed'
          />
          <rect
            id='Rectangle_7675'
            data-name='Rectangle 7675'
            width='1.551'
            height='15.367'
            transform='translate(2.46 3.543) rotate(-45)'
            fill='#535783'
          />
          <path
            id='Path_14950'
            data-name='Path 14950'
            d='M232.245,197.789a8.434,8.434,0,1,1,5.975-2.47A8.44,8.44,0,0,1,232.245,197.789Zm-4.854-13.328a6.906,6.906,0,1,0,9.766,0,6.9,6.9,0,0,0-9.766,0Z'
            transform='translate(-223.8 -180.9)'
            fill='#535783'
          />
          <path
            id='Path_14951'
            data-name='Path 14951'
            d='M276.4,214.619h-6.29a4.51,4.51,0,0,1,0-9.019h6.29Z'
            transform='translate(-253.594 -198.505)'
            fill='#f5f5f5'
          />
          <path
            id='Path_14952'
            data-name='Path 14952'
            d='M309.476,214.619H303.3V205.6h6.147a4.51,4.51,0,0,1,.029,9.019Z'
            transform='translate(-280.465 -198.505)'
            fill='#283275'
          />
          <path
            id='Path_14953'
            data-name='Path 14953'
            d='M275.351,213.47h-7.066a5.285,5.285,0,1,1,0-10.57h7.066Zm-7.066-9.019a3.734,3.734,0,1,0,0,7.468H273.8v-7.468Z'
            transform='translate(-251.74 -196.581)'
            fill='#535783'
          />
          <path
            id='Path_14954'
            data-name='Path 14954'
            d='M307.551,213.47H300.6V202.9h6.951a5.285,5.285,0,1,1,0,10.57Zm-5.4-1.551h5.4a3.734,3.734,0,1,0,0-7.468h-5.4Z'
            transform='translate(-278.54 -196.581)'
            fill='#283275'
          />
        </g>
        <g id='Group_11647' data-name='Group 11647' transform='translate(54.925 30.317)'>
          <circle
            id='Ellipse_3108'
            data-name='Ellipse 3108'
            cx='7.669'
            cy='7.669'
            r='7.669'
            transform='translate(0.776 0.747)'
            fill='#d3d9ed'
          />
          <rect
            id='Rectangle_7676'
            data-name='Rectangle 7676'
            width='1.551'
            height='15.367'
            transform='translate(2.46 3.543) rotate(-45)'
            fill='#535783'
          />
          <path
            id='Path_14955'
            data-name='Path 14955'
            d='M232.245,197.789a8.434,8.434,0,1,1,5.975-2.47A8.44,8.44,0,0,1,232.245,197.789Zm-4.854-13.328a6.906,6.906,0,1,0,9.766,0,6.9,6.9,0,0,0-9.766,0Z'
            transform='translate(-223.8 -180.9)'
            fill='#535783'
          />
          <path
            id='Path_14956'
            data-name='Path 14956'
            d='M276.4,214.619h-6.29a4.51,4.51,0,0,1,0-9.019h6.29Z'
            transform='translate(-253.594 -198.505)'
            fill='#f5f5f5'
          />
          <path
            id='Path_14957'
            data-name='Path 14957'
            d='M309.476,214.619H303.3V205.6h6.147a4.51,4.51,0,0,1,.029,9.019Z'
            transform='translate(-280.465 -198.505)'
            fill='#283275'
          />
          <path
            id='Path_14958'
            data-name='Path 14958'
            d='M275.351,213.47h-7.066a5.285,5.285,0,1,1,0-10.57h7.066Zm-7.066-9.019a3.734,3.734,0,1,0,0,7.468H273.8v-7.468Z'
            transform='translate(-251.74 -196.581)'
            fill='#535783'
          />
          <path
            id='Path_14959'
            data-name='Path 14959'
            d='M307.551,213.47H300.6V202.9h6.951a5.285,5.285,0,1,1,0,10.57Zm-5.4-1.551h5.4a3.734,3.734,0,1,0,0-7.468h-5.4Z'
            transform='translate(-278.54 -196.581)'
            fill='#283275'
          />
        </g>
      </g>
    </SvgIcon>
  );
};
