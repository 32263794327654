import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { ProductInfoFragmentDoc } from '../../fragments/__generated__/ProductInfo';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/queries/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductBestSellersQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  vendorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type MarketplaceProductBestSellersQuery = { __typename?: 'Query', marketplaceProductBestSellers?: { __typename?: 'MarketplaceProductConnection', edges: Array<{ __typename?: 'MarketplaceProductEdge', node: { __typename?: 'MarketplaceProduct', id: string, name?: string | null, nameAr?: string | null, price?: any | null, description?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, mainImageUrl?: string | null, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, endDate: any, percentage?: number | null, startDate: any, type: Types.MarketplaceDiscountType } | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null }, category?: { __typename?: 'MarketplaceProductCategory', id: string, name?: string | null, nameAr?: string | null } | null } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const MarketplaceProductBestSellersDocument = gql`
    query MarketplaceProductBestSellers($after: String, $before: String, $first: Int, $last: Int, $vendorId: ID) {
  marketplaceProductBestSellers(
    after: $after
    before: $before
    first: $first
    last: $last
    vendorId: $vendorId
  ) {
    edges {
      node {
        ...ProductInfo
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${ProductInfoFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useMarketplaceProductBestSellersQuery__
 *
 * To run a query within a React component, call `useMarketplaceProductBestSellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductBestSellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceProductBestSellersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useMarketplaceProductBestSellersQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceProductBestSellersQuery, MarketplaceProductBestSellersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceProductBestSellersQuery, MarketplaceProductBestSellersQueryVariables>(MarketplaceProductBestSellersDocument, options);
      }
export function useMarketplaceProductBestSellersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceProductBestSellersQuery, MarketplaceProductBestSellersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceProductBestSellersQuery, MarketplaceProductBestSellersQueryVariables>(MarketplaceProductBestSellersDocument, options);
        }
export function useMarketplaceProductBestSellersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplaceProductBestSellersQuery, MarketplaceProductBestSellersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceProductBestSellersQuery, MarketplaceProductBestSellersQueryVariables>(MarketplaceProductBestSellersDocument, options);
        }
export type MarketplaceProductBestSellersQueryHookResult = ReturnType<typeof useMarketplaceProductBestSellersQuery>;
export type MarketplaceProductBestSellersLazyQueryHookResult = ReturnType<typeof useMarketplaceProductBestSellersLazyQuery>;
export type MarketplaceProductBestSellersSuspenseQueryHookResult = ReturnType<typeof useMarketplaceProductBestSellersSuspenseQuery>;
export type MarketplaceProductBestSellersQueryResult = Apollo.QueryResult<MarketplaceProductBestSellersQuery, MarketplaceProductBestSellersQueryVariables>;