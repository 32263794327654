import { useTranslation } from "@toolkit/i18n";
import { Box, Card, CircularProgress, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useDigitalTwinBMRAndTDEECalculatorStyle } from "./useDigitalTwinBMRAndTDEECalculatorStyle";
import { useGetDigitalTwinHealthParameterByCode } from "@/domains/health-parameters/hooks/useGetDigitalTwinHealthParameterByCode";
import { TdeeAndBmrCalculationRoutes } from "../../routes";
import { HealthParameterCodes } from "@/domains/health-parameters/constants/healthParameterCodes";
import { DigitalTwinBmrTdeeCalculatorResult } from "../../components/DigitalTwinBmrTdeeCalculatorResult/DigitalTwinBmrTdeeCalculatorResult";
import { ExpandMoreIcon } from "@/shared/icons";
import { useTdeeAndBmrCalculationParams } from "./useTdeeAndBmrCalculationParams";

export const DigitalTwinBMRAndTDEECalculatorContainer: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { bee, tdee, caloriesPerDayToAdjust, goalWeight, newCalorieIntake, newTdee, weightLossDays } = useTdeeAndBmrCalculationParams();
  const { classes } = useDigitalTwinBMRAndTDEECalculatorStyle();
  const { value: savedBee, loading: isLoadingBee } = useGetDigitalTwinHealthParameterByCode(HealthParameterCodes.bee);
  const { value: savedTdee, loading: isLoadingTdee } = useGetDigitalTwinHealthParameterByCode(HealthParameterCodes.tdee);

  const loading = isLoadingBee || isLoadingTdee;
  const handleOpenFormPressed = () => {
    navigate(TdeeAndBmrCalculationRoutes.form.absRoute);
  };
  return (
    <Box className={classes.root}>
      {loading ? (
        <CircularProgress size={40} />
      ) : (
        <>
          <Card className={classes.headerCard} onClick={handleOpenFormPressed}>
            <Box className={classes.headerCardInner}>
              <Typography className={classes.title}>{t("Set your goal for calorie counting")}</Typography>
              <Typography className={classes.subTitle}>
                {t("Fill in the details so we can calculate your calories and weight loss plan")}
              </Typography>
            </Box>
            <ExpandMoreIcon direction='right' height={15} width={20} />
          </Card>

          <DigitalTwinBmrTdeeCalculatorResult
            bee={bee || savedBee}
            tdee={tdee || savedTdee}
            maxTdee={tdee || savedTdee}
            weightLossPlanData={
              (weightLossDays !== undefined && {
                numberOfDays: weightLossDays!,
                goalWeight: goalWeight!,
                newTdeeValue: newTdee!,
                targetCalories: newCalorieIntake!,
                reduceCalorieIntake: caloriesPerDayToAdjust!,
              }) ||
              undefined
            }
          />
        </>
      )}
    </Box>
  );
};
