import { useTranslation } from "@toolkit/i18n";
import { FC, MouseEvent } from "react";
import { ConfirmationBaseDialog } from "../ConfirmationBaseDialog/ConfirmationBaseDialog";
import { DeleteConfirmationDialogProps } from "../types";

export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = props => {
  const { isOpen, onClose, onDelete, isDeleteButtonDisabled = false, button, text, classes, ...rest } = props;
  const { t } = useTranslation();
  const onConfirmationClick = (e: MouseEvent) => {
    e.preventDefault();
    onDelete();
  };

  return (
    <ConfirmationBaseDialog
      button={button}
      isDoButtonDisabled={isDeleteButtonDisabled}
      isOpen={isOpen}
      handleClose={onClose}
      handleDo={onConfirmationClick}
      DoTitle={t("Delete")}
      color='error'
      text={{
        title: text?.title ?? t("Delete"),
        body: text?.body ?? t("Are you sure you want to delete this item?"),
      }}
      classes={classes}
      {...rest}
    />
  );
};
