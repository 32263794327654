import { useCallback, useEffect, useState } from "react";
import useFullscreen from "./useFullscreen";
import { CallInstance } from "./types";
import { useOpenState } from "@toolkit/core";
import { useCallHook } from "./useCallHook";

export const useCallMenuHook = ({
  call,
  invitationLink,
  isDoctorConsolEnabled,
}: {
  call: CallInstance;
  invitationLink?: string;
  isDoctorConsolEnabled: boolean;
}) => {
  const { videoMuted, audioMuted, screenMuted } = call.state;
  const callActions = useCallHook({ call, invitationLink, isDoctorConsolEnabled });
  const [view, setView] = useState<"grid" | "speaker">("grid");
  const { open: isDoctorConsoleOpen, handleToggle: onToggleDoctorConsole } = useOpenState();
  const { open: isTakePhotoOpen, handleToggle: onToggleTakePhoto } = useOpenState();
  const { open: isGuestInviteOpen, handleToggle: onToggleGuestInvite } = useOpenState(false);
  const { isFullscreenSupported, isFullscreen, onToggleFullscreen } = useFullscreen();
  const { open: isMobileMenuOpen, handleToggle: onToggletMobileMenu, handleClose: handleCloseMobileMenu } = useOpenState(false);
  const { open: isParticipantsOpen, handleToggle: onToggleParticipants, handleClose: handleCloseParticipants } = useOpenState(false);
  const { open: isChatOpen, handleToggle: onToggleChat, handleClose: handleCloseChat } = useOpenState(false);
  const onToggleView = useCallback(() => setView(l => (l === "grid" ? "speaker" : "grid")), []);
  const onCloseDrawer = useCallback(() => {
    handleCloseChat();
    handleCloseParticipants();
  }, [handleCloseChat, handleCloseParticipants]);

  useEffect(() => {
    handleCloseMobileMenu();
  }, [view, isDoctorConsoleOpen, isParticipantsOpen, isChatOpen, isFullscreen, videoMuted, audioMuted, screenMuted, handleCloseMobileMenu]);

  return {
    view,
    isDoctorConsoleOpen: isDoctorConsolEnabled ? isDoctorConsoleOpen : false,
    isDoctorConsolEnabled,
    isParticipantsOpen,
    isMobileMenuOpen,
    isTakePhotoOpen,
    isGuestInviteOpen,
    isChatOpen,
    isFullscreenSupported,
    isFullscreen,
    isDrawerOpen: isParticipantsOpen || isChatOpen,

    onToggleFullscreen,
    onToggleView,
    onToggleDoctorConsole,
    onToggleParticipants,
    onToggletMobileMenu,
    onToggleTakePhoto,
    onToggleGuestInvite,
    onToggleChat,
    onCloseDrawer,
    ...callActions,
  };
};
