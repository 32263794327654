import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCityOptionQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.CityFilterInput>;
}>;


export type GetCityOptionQuery = { __typename?: 'Query', cities?: { __typename?: 'CityCountableConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'CityCountableEdge', node: { __typename?: 'City', id: string, name: string, nameAr?: string | null } }> } | null };


export const GetCityOptionDocument = gql`
    query getCityOption($filter: CityFilterInput) {
  cities(filter: $filter, first: 1) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        name
        nameAr
      }
    }
  }
}
    `;

/**
 * __useGetCityOptionQuery__
 *
 * To run a query within a React component, call `useGetCityOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCityOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCityOptionQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCityOptionQuery(baseOptions?: Apollo.QueryHookOptions<GetCityOptionQuery, GetCityOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCityOptionQuery, GetCityOptionQueryVariables>(GetCityOptionDocument, options);
      }
export function useGetCityOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCityOptionQuery, GetCityOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCityOptionQuery, GetCityOptionQueryVariables>(GetCityOptionDocument, options);
        }
export function useGetCityOptionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCityOptionQuery, GetCityOptionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCityOptionQuery, GetCityOptionQueryVariables>(GetCityOptionDocument, options);
        }
export type GetCityOptionQueryHookResult = ReturnType<typeof useGetCityOptionQuery>;
export type GetCityOptionLazyQueryHookResult = ReturnType<typeof useGetCityOptionLazyQuery>;
export type GetCityOptionSuspenseQueryHookResult = ReturnType<typeof useGetCityOptionSuspenseQuery>;
export type GetCityOptionQueryResult = Apollo.QueryResult<GetCityOptionQuery, GetCityOptionQueryVariables>;