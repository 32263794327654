import { Box } from "@toolkit/ui";
import { useOrderMedicationsCardHeaderStyles } from "./useOrderMedicationsCardHeader.styles";
import { isString } from "lodash";

type OrderMedicationsCardHeaderProps = {
  title: string | React.ReactNode;
  icon: React.ReactNode;
  classes?: Partial<ReturnType<typeof useOrderMedicationsCardHeaderStyles>["classes"]>;
};

export const OrderMedicationsCardHeader: React.FC<OrderMedicationsCardHeaderProps> = props => {
  const { title, icon, classes: _classes } = props;
  const { classes, cx } = useOrderMedicationsCardHeaderStyles();
  return (
    <Box className={cx(classes.root, _classes?.root)}>
      <Box className={cx(classes.iconWrapper, _classes?.iconWrapper)}>{icon}</Box>
      {isString(title) ? <Box className={cx(classes.title, _classes?.title)}>{title}</Box> : title}
    </Box>
  );
};
