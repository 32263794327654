import { CallInfo, useSelectCall } from "./CallContext";
import { useMeetingQuery } from "../../gql/queries";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConferenceConfiguration, Visit, VisitStatus } from "@/schema/types";
import { CallState } from "./types";

export const useCallInfo = (visit: Visit | undefined, configuration: ConferenceConfiguration | undefined) => {
  const callId = visit?.callId;
  const { data, loading, error, refetch } = useMeetingQuery({
    skip: !callId,
    variables: callId ? { meetingId: callId } : undefined,
  });

  const callInfo = useMemo(() => {
    const meeting = data?.meeting;
    if (!meeting) {
      return undefined;
    }

    return { meeting, configuration } as CallInfo;
  }, [configuration, data]);

  const call = useSelectCall(callInfo);
  const handleRetry = useCallback(async () => {
    try {
      await refetch();
    } catch {
      // ignore
    }
  }, [refetch]);

  const { dropCall, updateCallStatus } = call || {};
  const callState = call?.state.callState;
  const [joined, setJoined] = useState(callState === CallState.InCall);
  const visitStatus = visit?.status;

  useEffect(() => {
    switch (callState) {
      case CallState.InCall: {
        setJoined(true);
        break;
      }
      case CallState.Started:
      case CallState.NoCall: {
        setJoined(false);
        break;
      }
    }
  }, [callState]);

  useEffect(() => {
    if (callState === CallState.InCall && visitStatus !== VisitStatus.CallInProgress) {
      dropCall?.();
    }
    if (callState === CallState.Dropped && visitStatus !== VisitStatus.CallInProgress) {
      updateCallStatus?.(CallState.Ended);
    }
  }, [callState, visitStatus, dropCall, updateCallStatus]);

  return { call, joined, loading, error, handleRetry };
};
