import { makeStyles } from "@toolkit/ui";

export const useMarketplaceOrderGeneralInfoCardStyle = makeStyles()(theme => ({
  iconContainer: {
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    boxShadow: theme.mixins.shadows.md,
    marginInlineEnd: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "18px",
      height: "18px",
    },
  },
  infoCard: {
    height: "70px",
    paddingInline: 15,
    paddingBlock: 10,
  },
  iconContentContainer: {
    rowGap: 0,
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
}));
