import { ExcellentModeIcon, FairModeIcon, GoodModeIcon, PoorModeIcon, WorstModeIcon } from "@/shared/icons";
import { i18n } from "@toolkit/i18n";

export const feelingModes = [
  {
    label: i18n.t("Worst", { ns: "consumer" }),
    icon: WorstModeIcon,
  },
  {
    label: i18n.t("Poor", { ns: "consumer" }),
    icon: PoorModeIcon,
  },
  {
    label: i18n.t("Fair", { ns: "consumer" }),
    icon: FairModeIcon,
  },
  {
    label: i18n.t("Good", { ns: "consumer" }),
    icon: GoodModeIcon,
  },
  {
    label: i18n.t("Excellent", { ns: "consumer" }),
    icon: ExcellentModeIcon,
  },
];
