import {
  BaseConfirmationDialog,
  ConfirmationDialogProps,
  DeleteConfirmationDialogProps,
  InfoConfirmationDialogProps,
  WarningConfirmationDialogProps,
} from "./types";
import { ConfirmationBaseDialog } from "./ConfirmationBaseDialog/ConfirmationBaseDialog";
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog/DeleteConfirmationDialog";
import { InfoConfirmationDialog } from "./InfoConfirmationDialog/InfoConfirmationDialog";
import { WarningConfirmationDialog } from "./WarningConfirmationDialog/WarningConfirmationDialog";

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = props => {
  const { variant, ...rest } = props;

  if (variant === "warning") {
    return <WarningConfirmationDialog {...(rest as WarningConfirmationDialogProps)} />;
  } else if (variant === "delete") {
    return <DeleteConfirmationDialog {...(rest as DeleteConfirmationDialogProps)} />;
  } else if (variant === "info") {
    return <InfoConfirmationDialog {...(rest as InfoConfirmationDialogProps)} />;
  }
  return <ConfirmationBaseDialog {...(rest as BaseConfirmationDialog)} />;
};
