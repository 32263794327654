import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DigitalTwinGetWeightLossPlanCalculationsQueryVariables = Types.Exact<{
  input: Types.GoalWeightInput;
}>;


export type DigitalTwinGetWeightLossPlanCalculationsQuery = { __typename?: 'Query', getWeightLossPlanCalculations?: { __typename?: 'GoalWeightResponse', caloriesPerDayToAdjust?: number | null, isWeightLoss?: boolean | null, newCalorieIntake?: number | null, newTDEE?: number | null } | null };


export const DigitalTwinGetWeightLossPlanCalculationsDocument = gql`
    query DigitalTwinGetWeightLossPlanCalculations($input: GoalWeightInput!) {
  getWeightLossPlanCalculations: goalWeight(input: $input) {
    caloriesPerDayToAdjust
    isWeightLoss
    newCalorieIntake
    newTDEE
  }
}
    `;

/**
 * __useDigitalTwinGetWeightLossPlanCalculationsQuery__
 *
 * To run a query within a React component, call `useDigitalTwinGetWeightLossPlanCalculationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalTwinGetWeightLossPlanCalculationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalTwinGetWeightLossPlanCalculationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDigitalTwinGetWeightLossPlanCalculationsQuery(baseOptions: Apollo.QueryHookOptions<DigitalTwinGetWeightLossPlanCalculationsQuery, DigitalTwinGetWeightLossPlanCalculationsQueryVariables> & ({ variables: DigitalTwinGetWeightLossPlanCalculationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DigitalTwinGetWeightLossPlanCalculationsQuery, DigitalTwinGetWeightLossPlanCalculationsQueryVariables>(DigitalTwinGetWeightLossPlanCalculationsDocument, options);
      }
export function useDigitalTwinGetWeightLossPlanCalculationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DigitalTwinGetWeightLossPlanCalculationsQuery, DigitalTwinGetWeightLossPlanCalculationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DigitalTwinGetWeightLossPlanCalculationsQuery, DigitalTwinGetWeightLossPlanCalculationsQueryVariables>(DigitalTwinGetWeightLossPlanCalculationsDocument, options);
        }
export function useDigitalTwinGetWeightLossPlanCalculationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DigitalTwinGetWeightLossPlanCalculationsQuery, DigitalTwinGetWeightLossPlanCalculationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DigitalTwinGetWeightLossPlanCalculationsQuery, DigitalTwinGetWeightLossPlanCalculationsQueryVariables>(DigitalTwinGetWeightLossPlanCalculationsDocument, options);
        }
export type DigitalTwinGetWeightLossPlanCalculationsQueryHookResult = ReturnType<typeof useDigitalTwinGetWeightLossPlanCalculationsQuery>;
export type DigitalTwinGetWeightLossPlanCalculationsLazyQueryHookResult = ReturnType<typeof useDigitalTwinGetWeightLossPlanCalculationsLazyQuery>;
export type DigitalTwinGetWeightLossPlanCalculationsSuspenseQueryHookResult = ReturnType<typeof useDigitalTwinGetWeightLossPlanCalculationsSuspenseQuery>;
export type DigitalTwinGetWeightLossPlanCalculationsQueryResult = Apollo.QueryResult<DigitalTwinGetWeightLossPlanCalculationsQuery, DigitalTwinGetWeightLossPlanCalculationsQueryVariables>;