/* eslint-disable max-statements */
/* eslint-disable sonarjs/cognitive-complexity */
import { MutableRefObject, useCallback, useEffect, useState } from "react";

import { Adaptor, ExecutedResponse, MediaStreamTrackType } from "./types";
import { formatExecutedFailure, getUserMedia, isCustomMediaTrackType } from "./utils";

type MutedParam = boolean | ((muted: boolean) => boolean);

export const useMuteVideo = ({ adaptorRef, localStream }: { adaptorRef: MutableRefObject<Adaptor | null>; localStream: MediaStream }) => {
  const [videoMuted, setVideoMuted] = useState(true);
  const videoTrack = localStream
    .getVideoTracks()
    .find(t => t.readyState !== "ended" && !isCustomMediaTrackType(t, MediaStreamTrackType.screen));
  const [flipCameraEnabled, setFlipCameraEnabled] = useState(false);
  const [facingMode, setFacingMode] = useState("");

  const addVideoTrack = useCallback(
    (track: MediaStreamTrack) => {
      // ensure to keep video track at 0 index
      const videoTracks = localStream.getVideoTracks();
      videoTracks.map(t => localStream.removeTrack(t));
      [track, ...videoTracks].forEach(t => localStream.addTrack(t));
      if (!localStream.getVideoTracks().find(t => isCustomMediaTrackType(t, MediaStreamTrackType.screen))) {
        adaptorRef.current?.replaceTrack(track);
      }
    },
    [adaptorRef, localStream]
  );

  const muteVideo = useCallback(
    async (value: MutedParam): Promise<ExecutedResponse> => {
      try {
        if (!localStream) {
          return { success: false };
        }

        const muted = typeof value === "function" ? value(videoMuted === undefined ? true : !!videoMuted) : value;
        let [track] = localStream.getVideoTracks();
        if (!track || isCustomMediaTrackType(track, MediaStreamTrackType.canvas) || track.readyState === "ended") {
          const result = await getUserMedia({ video: true });
          if (!result.success) {
            return result;
          }

          track && localStream.removeTrack(track);
          [track] = result.stream.getVideoTracks();
          addVideoTrack(track);
        }

        setVideoMuted(muted);
        track.enabled = !muted;
        if (muted) {
          track.stop();
        }
        return { success: true };
      } catch (error) {
        console.error("Meeting: Unable mute video due to the error", error);
        return formatExecutedFailure(error);
      }
    },
    [addVideoTrack, localStream, videoMuted]
  );

  const flipCamera = useCallback(async (): Promise<ExecutedResponse> => {
    try {
      const mode = facingMode === "environment" ? "user" : "environment";
      const result = await getUserMedia({ video: { facingMode: { exact: mode } } });
      if (!result.success) {
        return result;
      }
      setFacingMode(mode);

      const [track] = result.stream.getVideoTracks();
      addVideoTrack(track);
      return { success: true };
    } catch (error) {
      console.error("Meeting: Unable to switch camera due to the error", error);
      return formatExecutedFailure(error);
    }
  }, [addVideoTrack, facingMode]);

  useEffect(() => {
    if (!videoTrack) {
      return;
    }

    const onEnded = () => {
      localStream.removeTrack(videoTrack);
      setVideoMuted(true);
      setFacingMode("");
    };

    videoTrack.addEventListener("ended", onEnded);
    return () => {
      videoTrack.removeEventListener("ended", onEnded);
    };
  }, [adaptorRef, localStream, videoTrack]);

  useEffect(() => {
    if (videoMuted !== false || !navigator.mediaDevices?.enumerateDevices) {
      return;
    }

    setFlipCameraEnabled(false);
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const videoInputs = devices.filter(
        device => device.kind === "videoinput" && typeof InputDeviceInfo !== "undefined" && device instanceof InputDeviceInfo
      ) as InputDeviceInfo[];
      const facingModes = videoInputs.flatMap(videoInput => videoInput.getCapabilities().facingMode);
      setFlipCameraEnabled(facingModes.length > 0);
    });
  }, [videoMuted]);

  return {
    muteVideo,
    videoMuted: videoMuted !== false,
    flipCamera: videoMuted === false && flipCameraEnabled ? flipCamera : undefined,
  };
};
