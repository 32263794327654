import { ProductsContainer } from "@/domains/marketplace/containers/ProductsContainer/ProductsContainer";
import { routes } from "@/domains/marketplace/routes";
import { Section } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { useMediaQuery } from "@toolkit/ui";
import React from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { useCommonSectionsStyle } from "../../Styles/CommonSectionsStyle";
import { SeeAllButton } from "../SeeAllButton/SeeAllButton";
import { useStyle } from "./ProductsSectionStyle";

export const ProductsSection: React.FC = () => {
  const { classes, cx } = useStyle();
  const { classes: commonClasses, theme } = useCommonSectionsStyle();
  const { t } = useTranslation("consumer");
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleSeeAll = () => {
    navigate(routes.products.absRoute);
  };
  return (
    <Section
      title={t(!isAuthenticated ? "Featured items" : "Products for you")}
      onSeeAll={handleSeeAll}
      classes={{
        root: cx(commonClasses.root, classes.root, "section__root", "products"),
        container: cx(commonClasses.container, classes.container, "section__container"),
        titleContainer: commonClasses.titleContainer,
        title: cx(commonClasses.title, "title"),
        titleSeeAll: cx(commonClasses.titleSeeAll, "title__btn"),
      }}
      footer={
        <SeeAllButton
          classes={{
            seeAllButton__container: cx(commonClasses.seeAllButton__container__Lg),
          }}
          onClick={handleSeeAll}
        />
      }
    >
      <ProductsContainer smallCardSize={false} classes={classes} hideNavBtns={isSmall} />
    </Section>
  );
};
