import { CustomCard } from "@/shared/components";
import { FC } from "react";
import { useActivitiesCardStyles } from "./ActivitiesCardStyles";
import { Box, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { ActivitiesItems } from "../ActivitiesItems/ActivitiesItems";
import { useAuth } from "react-oidc-context";
import { getPatientId } from "@/domains/patient";
import { get } from "lodash";
import { ActivitiesGoals } from "../ActivitiesGoals/ActivitiesGoals";

export const ActivitiesCard: FC = () => {
  const { classes } = useActivitiesCardStyles();
  const { t } = useTranslation("consumer");
  const { user } = useAuth();
  const patientId = getPatientId(get(user?.profile, "patient_id") as string)!;
  return (
    <CustomCard className={classes.root}>
      <Box className={classes.startContainer}>
        <Typography className={classes.title}>{t("Activities")}</Typography>
        <ActivitiesItems patientId={patientId} />
      </Box>
      <Box className={classes.endContainer}>
        <ActivitiesGoals patientId={patientId} />
      </Box>
    </CustomCard>
  );
};
