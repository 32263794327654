import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useLocation, Location } from "react-router-dom";
import { OrderAndDeliverPrescriptionContainerDataType } from "../../others/types";
import { useHeaderContext } from "@/shared/context";
import { ERxReferenceNumber } from "../../components/ERxReferenceNumber/ERxReferenceNumber";
import { PrescribedMedications } from "../../components/PrescribedMedications/PrescribedMedications";
import { DeliveryTypes } from "../../components/DeliveryTypes/DeliveryTypes";

export const OrderAndDeliverPrescriptionContainer: FC = () => {
  const { classes } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const { setHeaderTitle } = useHeaderContext();
  const location: Location<OrderAndDeliverPrescriptionContainerDataType> = useLocation();
  const { memberID, prescriptionReference, medications, allowedDeliveryTypes, prescriptionID } = location?.state || {};
  const theme = useTheme();

  useEffect(() => {
    setHeaderTitle(t("Order & Deliver Your Prescription"));
    return () => {
      setHeaderTitle("");
    };
  }, [setHeaderTitle, t]);
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <PrescribedMedications medications={medications} />
        <ERxReferenceNumber referenceNumber={prescriptionReference} />
        <Typography fontSize={theme?.mixins.fonts.fontSize.md} mt={"28px"} className={classes.title}>
          {t("How do you want to get your prescription?")}
        </Typography>
        <DeliveryTypes
          prescriptionReference={prescriptionReference}
          memberID={memberID}
          allowedDeliveryTypes={allowedDeliveryTypes}
          prescriptionID={prescriptionID}
        />
      </Box>
    </Box>
  );
};
