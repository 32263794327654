import { makeStyles } from "@toolkit/ui";

export const useReelsContainerStyles = makeStyles()(() => ({
  reelCardWrapper: {
    width: "155px",
    height: "255px",
  },
  cardsContainerClassName: {
    height: "100%",
    overflowY: "hidden",
  },
  cardsUpperContainer: {
    gap: "0px",
  },
  cardsContainer: {},
  card: {
    marginInlineEnd: 5,
    height: "fit-content",
    "&&:first-child": {
      marginInlineStart: "5px",
    },
    "&:last-child": {
      marginInlineEnd: "5px",
    },
  },
  navBtnClassName: {},
  navBtnContainerClassName: {},
  actionsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  skeletonContainer: {
    gap: "5px",
  },
}));
