import { Box, Button, Typography } from "@toolkit/ui";
import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useEPrescriptionReferenceSearchStyles } from "./useEPrescriptionReferenceSearch.styles";
import { MedicationIcon } from "@/shared/icons";
import { Trans, useTranslation } from "@toolkit/i18n";
import {
  EPrescriptionUpsertForm,
  EPrescriptionUpsertFormRef,
} from "../../forms/EPrescriptionReferenceSearchForm/EPrescriptionReferenceSearchForm";
import { usePatientHealthProgramMembersAutocompleteQuery } from "../../gql/queries";
import { useAuth } from "react-oidc-context";
import { IEPrescriptionReferenceSearchFormValues } from "../../forms/EPrescriptionReferenceSearchForm/EPrescriptionReferenceSearchFormSchema";
import { convertEPrescriptionFormValuesToBackEndValues } from "../../others/utils";
import { OrderMedicationContainerReferenceNumberSourceTypes, OrderMedicationUrlInfo } from "../../others/types";
import { SignInByOTPModalApi } from "@/domains/sign-in";
import { useValidatePrescription } from "../../hooks/useValidatePrescription";
import { oidcUserManagerVar } from "@/shared/configs/oidc";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

type EPrescriptionReferenceSearchProps = {
  urlInfo: OrderMedicationUrlInfo;
};

export const EPrescriptionReferenceSearch: FC<EPrescriptionReferenceSearchProps> = props => {
  const { urlInfo } = props;
  const { classes } = useEPrescriptionReferenceSearchStyles();
  const { t } = useTranslation("consumer");
  const formRef = useRef<EPrescriptionUpsertFormRef>(null);
  const navigate = useNavigate();

  const handleOnSearchClick = () => {
    formRef.current?.submit();
  };
  const { isAuthenticated, isLoading } = useAuth();
  const { loading: isSubmitting, handleValidatePrescription } = useValidatePrescription();
  const { data, loading } = usePatientHealthProgramMembersAutocompleteQuery({
    skip: !isAuthenticated,
  });
  const healthProgramMember = data?.healthProgramMembers?.edges?.map(edge => edge.node);

  const handleFormSubmit = (values: IEPrescriptionReferenceSearchFormValues) => {
    if (
      (urlInfo?.sourceType === OrderMedicationContainerReferenceNumberSourceTypes.external ||
        urlInfo?.sourceType === OrderMedicationContainerReferenceNumberSourceTypes.pickupSms) &&
      !isAuthenticated
    ) {
      SignInByOTPModalApi.open({
        mobile: urlInfo?.mobileNumber,
      });
    } else {
      handleValidatePrescription(convertEPrescriptionFormValuesToBackEndValues(values));
    }
  };

  const debouncedValidate = useMemo(
    () =>
      debounce(() => {
        handleValidatePrescription({
          memberID: urlInfo?.memberId || "",
          referenceNumber: urlInfo?.referenceId || "",
        });
      }, 300),
    []
  );

  const validateWithDeps = useCallback(() => {
    urlInfo?.sourceType === OrderMedicationContainerReferenceNumberSourceTypes.external && debouncedValidate();
    urlInfo?.sourceType === OrderMedicationContainerReferenceNumberSourceTypes.pickupSms && navigate(urlInfo?.loginByOtpRedirectUrl);
  }, [urlInfo, handleValidatePrescription, urlInfo]);

  useEffect(() => {
    oidcUserManagerVar?.onNextChange(validateWithDeps);
  }, [isAuthenticated, validateWithDeps]);

  return (
    <Box className={classes.root}>
      <Box className={classes.iconWrapper}>
        <MedicationIcon />
      </Box>
      <Typography className={classes.infoText}>
        <Trans
          t={t}
          i18nKey='Please Enter <bold>e-Prescription</bold> Reference Number'
          components={{
            bold: <Box component='span' className={classes.boldText} />,
          }}
        />
      </Typography>
      <EPrescriptionUpsertForm
        healthProgramMember={healthProgramMember}
        memberId={urlInfo?.memberId}
        ref={formRef}
        onChange={handleFormSubmit}
        ePrescriptionReference={urlInfo?.referenceId}
      />
      <Button disabled={loading || isSubmitting || isLoading} onClick={handleOnSearchClick} className={classes.searchButton}>
        {t("Search")}
      </Button>
    </Box>
  );
};
