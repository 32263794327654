import { useMarketplaceCartContext } from "@/shared/context";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, RemoveIcon, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { CartList } from "../../components/CartList/CartList";
import { routes } from "../../routes";
import { useWishlistContainerStyles } from "../WishlistContainer/WishlistContainerStyles";

export const CartListContainer: FC = () => {
  const { classes, cx } = useCommonContainerStyles();
  const { classes: customClasses } = useWishlistContainerStyles();
  const { clearCart, itemCounts } = useMarketplaceCartContext();

  const { t } = useTranslation("consumer");

  const navigate = useNavigate();

  const handleProceedToPay = () => {
    navigate(routes.addressSelection.absRoute);
  };

  const handleNavigateToShop = () => {
    navigate(routes.root.absRoute);
  };
  const handleClearCart = () => {
    clearCart?.();
  };

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Box className={customClasses.pageHeader}>
          <Typography className={cx(classes.pageTitle, customClasses.pageTitle)}>{t("Cart")}</Typography>
          {!!itemCounts?.totalCount && (
            <Button onClick={handleClearCart} className={customClasses.clearButton} variant='text' startIcon={<RemoveIcon />}>
              {t("Clear Cart")}
            </Button>
          )}
        </Box>
        <CartList />
      </Box>
      {!!itemCounts?.totalCount && (
        <Box className={cx(classes.footer, customClasses.footer)}>
          <Button onClick={handleProceedToPay} className={cx(classes.button, customClasses.viwCardButton)}>
            {t("Proceed to Pay")}
          </Button>
          <Button onClick={handleNavigateToShop} variant='outlined' className={cx(classes.button, customClasses.shopButton)}>
            {t("Continue Shopping")}
          </Button>
        </Box>
      )}
    </Box>
  );
};
