import {
  MarketplaceProductsQuery,
  useMarketplaceProductBestSellersQuery,
  useMarketplaceProductsForYouQuery,
  useMarketplaceProductsQuery,
} from "@/domains/marketplace-products";
import { ExtractNodeType } from "@toolkit/apollo";
import { useAuth } from "react-oidc-context";
import { ProductInfoFragment } from "@/domains/marketplace-products/gql/fragments";
import { isEmpty } from "lodash";

type UseProductsContainerProps = {
  countOfProducts: number;
  variant: "auth-based" | "best-sellers";
  comingProducts?: ProductInfoFragment[];
};

type UseProductsContainerReturnTypes = {
  products:
    | ExtractNodeType<MarketplaceProductsQuery>[]
    | ExtractNodeType<MarketplaceProductsQuery>[]
    | ExtractNodeType<MarketplaceProductsQuery>[];
  isLoading?: boolean;
};

export const useProductsContainer = (props: UseProductsContainerProps): UseProductsContainerReturnTypes => {
  const { countOfProducts, variant, comingProducts } = props;
  const { isAuthenticated } = useAuth();
  const { data, loading } = useMarketplaceProductsQuery({
    variables: {
      first: countOfProducts,
    },
    skip: variant !== "auth-based" || isAuthenticated || !isEmpty(comingProducts),
  });

  const { data: YourData, loading: YourDataLoading } = useMarketplaceProductsForYouQuery({
    variables: {
      first: countOfProducts,
    },
    skip: variant !== "auth-based" || !isAuthenticated || !isEmpty(comingProducts),
  });
  const { data: bestSellersData, loading: bestSellersLoading } = useMarketplaceProductBestSellersQuery({
    variables: {
      first: countOfProducts,
    },
    skip: variant !== "best-sellers" || !isEmpty(comingProducts),
  });

  const products =
    comingProducts ||
    data?.marketplaceProducts?.edges?.map(edge => edge?.node) ||
    YourData?.marketplaceProductsForYou?.edges?.map(edge => edge?.node) ||
    bestSellersData?.marketplaceProductBestSellers?.edges?.map(edge => edge?.node) ||
    [];
  const isLoading = loading || YourDataLoading || bestSellersLoading;
  return { products, isLoading };
};
