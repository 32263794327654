import { useAuth } from "@/shared/context";
import { Box } from "@toolkit/ui";
import useStyles from "./PreList.styles";
import { Profile } from "./Profile";
import { SignIn } from "./SignIn";

export const PreList: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.circleBackground}></Box>
      <Box className={classes.content}>{isAuthenticated ? <Profile /> : <SignIn />}</Box>
    </Box>
  );
};
