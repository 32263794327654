import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../../../../domains/address/gql/fragments/__generated__/address';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountSettingsInformationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AccountSettingsInformationQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, mobile?: string | null, nationalId?: string | null, emailVerified: boolean, mobileVerified: boolean, preferredLanguage?: { __typename?: 'Language', code: string, display: string, displayAr?: string | null, id: string } | null, addresses?: Array<{ __typename?: 'Address', id: string, streetAddress1: string, streetAddress2: string, phone?: string | null, name?: string | null, nameAr?: string | null, buildingNumber?: string | null, unitNumber?: string | null, isDefaultShippingAddress?: boolean | null, isDefaultBillingAddress?: boolean | null, area: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lat?: number | null, lng?: number | null } | null } | null> | null } | null };


export const AccountSettingsInformationDocument = gql`
    query accountSettingsInformation {
  me {
    id
    email
    firstName
    lastName
    mobile
    preferredLanguage {
      code
      display
      displayAr
      id
    }
    nationalId
    emailVerified
    mobileVerified
    addresses {
      ...AddressFragment
    }
  }
}
    ${AddressFragmentFragmentDoc}`;

/**
 * __useAccountSettingsInformationQuery__
 *
 * To run a query within a React component, call `useAccountSettingsInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSettingsInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSettingsInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountSettingsInformationQuery(baseOptions?: Apollo.QueryHookOptions<AccountSettingsInformationQuery, AccountSettingsInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountSettingsInformationQuery, AccountSettingsInformationQueryVariables>(AccountSettingsInformationDocument, options);
      }
export function useAccountSettingsInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountSettingsInformationQuery, AccountSettingsInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountSettingsInformationQuery, AccountSettingsInformationQueryVariables>(AccountSettingsInformationDocument, options);
        }
export function useAccountSettingsInformationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountSettingsInformationQuery, AccountSettingsInformationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountSettingsInformationQuery, AccountSettingsInformationQueryVariables>(AccountSettingsInformationDocument, options);
        }
export type AccountSettingsInformationQueryHookResult = ReturnType<typeof useAccountSettingsInformationQuery>;
export type AccountSettingsInformationLazyQueryHookResult = ReturnType<typeof useAccountSettingsInformationLazyQuery>;
export type AccountSettingsInformationSuspenseQueryHookResult = ReturnType<typeof useAccountSettingsInformationSuspenseQuery>;
export type AccountSettingsInformationQueryResult = Apollo.QueryResult<AccountSettingsInformationQuery, AccountSettingsInformationQueryVariables>;