import { routes } from "@/domains/VirtualVisit/routes";
import { MainPageLayout, PageWithBackHeaderLayout, ProtectedRoute } from "@/shared/components";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const VirtualVisitRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageLayout containerBreakpoint='sm' />}>
        <Route path={routes.root.route} Component={routes.root.component} />
      </Route>
      <Route element={<PageWithBackHeaderLayout containerBreakpoint='sm' />}>
        <Route path={routes.callPaymentSummary.route} element={<ProtectedRoute Component={routes.callPaymentSummary.component} />} />
      </Route>
    </Routes>
  );
};
