import { MarketplaceHealthPackageDetails } from "@/domains/marketplace-health-packages/types";
import { MarketplaceContentType } from "@/schema/types";
import { EmptyMessageDefault, HtmlRenderer } from "@/shared/components";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { marked } from "marked";
import { FC } from "react";

type DescriptionBodyProps = {
  healthPackage: MarketplaceHealthPackageDetails;
};
export const DescriptionBody: FC<DescriptionBodyProps> = props => {
  const { healthPackage } = props;
  const { t } = useTranslation("consumer");
  let text = pickLocalizedValue(healthPackage?.description, healthPackage?.descriptionAr) || "";
  text = healthPackage?.contentType !== MarketplaceContentType.Html ? marked(text, { async: false }) : text;
  return text ? <HtmlRenderer htmlContent={text} /> : <EmptyMessageDefault message={t("No description provided")} />;
};
