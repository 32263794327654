import { LocationInput } from "@/schema/types";
import { LocationPicker } from "@toolkit/maps";
import { Box, MapMarkerIcon } from "@toolkit/ui";
import { toNumber } from "lodash";
import { FC } from "react";
import { useLocationDisplayStyles } from "./LocationDisplayStyles";

export type LocationDisplayProps = {
  id: string;
  coordinates?: { lat?: string | number | null; lng?: string | number | null } | LocationInput | null;
};

export const LocationDisplay: FC<LocationDisplayProps> = props => {
  const { coordinates, id } = props;
  const { classes } = useLocationDisplayStyles();
  return (
    <Box className={classes.root}>
      <LocationPicker
        containerClassName={classes.mapRoot}
        defaultZoom={17}
        classes={{
          searchContainer: classes.searchContainer,
        }}
        location={{
          lat: toNumber(coordinates?.lat),
          lng: toNumber(coordinates?.lng),
        }}
        googleMapProps={{
          options: {
            disableDefaultUI: true,
            zoomControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            keyboardShortcuts: false,
          },
        }}
        locations={[
          {
            id: id,
            icon: <MapMarkerIcon />,
            coordinates: {
              lat: toNumber(coordinates?.lat),
              lng: toNumber(coordinates?.lng),
            },
          },
        ]}
      />
    </Box>
  );
};
