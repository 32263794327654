import { useSiteSettingsGetQuery } from "@/gql";
import { FormSlider, GenericFilterForm, ValueLabelComponent } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box } from "@toolkit/ui";
import { isEmpty, xor } from "lodash";
import {
  IProductsListFiltersFormValues,
  productsListFiltersFormDefaultValues,
  ProductsListFiltersFormSchema,
} from "../ProductsListFiltersFormSchema";
import { ProductsListFiltersFormProps } from "../types";

export const PriceFilterForm: React.FC<ProductsListFiltersFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const defaultCurrency = data?.siteSettings?.defaultCurrency || "";
  return (
    <GenericFilterForm<IProductsListFiltersFormValues>
      schema={ProductsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='price'
      dialogTitle={t("Price")}
      formLabel={t("Price")}
      render={name => (
        <Box width='100%' px={1}>
          <FormSlider
            valueLabelFormat={value => ValueLabelComponent({ value, unit: t(defaultCurrency) })}
            valueLabelDisplay='on'
            min={Math.min(...(productsListFiltersFormDefaultValues.price ?? []))}
            max={Math.max(...(productsListFiltersFormDefaultValues.price ?? []))}
            name={name}
          />
        </Box>
      )}
      height={"fit-content"}
      paddingX={4}
      paddingY={2}
      defaultValues={productsListFiltersFormDefaultValues}
      isEmpty={value => {
        const _value = value as IProductsListFiltersFormValues["price"];
        return isEmpty(xor(_value, productsListFiltersFormDefaultValues.price));
      }}
    />
  );
};
