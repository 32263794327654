/* eslint-disable react/forbid-dom-props */
import { ExpandMoreIcon } from "@/shared/icons";
import { i18n } from "@toolkit/i18n";
import { Box, IconButton } from "@toolkit/ui";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { useHorizontalScrollerStyle } from "./HorizontalScrollerStyle";

interface HorizontalScrollerProps<D> {
  data: D[];
  hasNextPage: boolean;
  loadNextPage: () => void;
  hideNavBtns?: boolean;
  scrollAmount?: number;
  itemWidth: number;
  itemRenderer: (item: D | null, index: number, isScrolling: boolean) => React.ReactNode;
  classes?: Partial<ReturnType<typeof useHorizontalScrollerStyle>["classes"]>;
}
// to do: enhance the component logic

export const HorizontalScroller = <D,>({
  data,
  hasNextPage,
  loadNextPage,
  hideNavBtns = false,
  scrollAmount = 7,
  itemWidth,
  itemRenderer,
  classes: _classes = {},
}: HorizontalScrollerProps<D>): React.ReactElement => {
  const { classes, cx, theme } = useHorizontalScrollerStyle();
  const listRef = useRef<List>(null);

  const [scrollOffset, setScrollOffset] = useState(0);

  const itemCount = useMemo(() => (hasNextPage ? data.length + 1 : data.length), [data.length, hasNextPage]);
  const isItemLoaded = (index: number) => !hasNextPage || index < data.length;

  const canScrollNext = scrollOffset < (itemCount - scrollAmount) * itemWidth;
  const canScrollPrev = scrollOffset > 0;

  const scrollNext = () => {
    if (listRef.current) {
      const newOffset = Math.min(scrollOffset + scrollAmount * itemWidth, (itemCount - 2) * itemWidth);
      listRef.current.scrollTo(newOffset);
      setScrollOffset(newOffset);
    }
  };

  const scrollPrev = () => {
    if (listRef.current) {
      const newOffset = Math.max(0, scrollOffset - scrollAmount * itemWidth);
      listRef.current.scrollTo(newOffset);
      setScrollOffset(newOffset);
    }
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo(scrollOffset);
    }
  }, [scrollOffset]);

  return (
    <Box className={cx(classes.root, _classes.root)}>
      {!hideNavBtns && (
        <Box className={cx(classes.navButtonWrapper, _classes.navButtonWrapper)}>
          <IconButton
            className={cx(classes.navButton, classes.startNavButton, _classes.navButton)}
            onClick={scrollPrev}
            disabled={!canScrollPrev}
            aria-label='Scroll Left'
          >
            <ExpandMoreIcon fill={theme.palette.primary.main} direction='left' />
          </IconButton>
        </Box>
      )}

      <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadNextPage}>
        {({ onItemsRendered, ref }) => (
          <List
            direction={i18n.dir()}
            className={classes.contentContainer}
            ref={node => {
              ref(node);
              listRef.current = node;
            }}
            height={"100%"}
            width={(scrollAmount + 1) * itemWidth}
            itemCount={itemCount}
            itemSize={itemWidth}
            layout='horizontal'
            onScroll={({ scrollOffset }) => setScrollOffset(scrollOffset)}
            onItemsRendered={onItemsRendered}
          >
            {({ index, style, isScrolling }) => (
              <div style={style}>{itemRenderer(isItemLoaded(index) ? data[index] : null, index, isScrolling)}</div>
            )}
          </List>
        )}
      </InfiniteLoader>

      {!hideNavBtns && (
        <Box className={cx(classes.navButtonWrapper, _classes.navButtonWrapper)}>
          <IconButton
            className={cx(classes.navButton, classes.endNavButton, _classes.navButton)}
            onClick={scrollNext}
            disabled={!canScrollNext}
            aria-label='Scroll Right'
          >
            <ExpandMoreIcon fill={theme.palette.primary.main} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
