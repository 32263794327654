import { useCommonStyles } from "@/domains/appointment/containers/CommonContainer.styles";
import { CartSummaryBar } from "@/domains/marketplace/components/CartSummaryBar/CartSummaryBar";
import { CategoriesFilters } from "@/domains/marketplace/components/CategoriesFilters/CategoriesFilters";
import { MarketplaceProductFilterInput, MarketplaceProductSortingInput } from "@/schema/types";
import { useMarketplaceCartContext } from "@/shared/context";
import { Box } from "@toolkit/ui";
import { produce } from "immer";
import { xor } from "lodash";
import queryString from "query-string";
import { FC, useLayoutEffect, useMemo, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Location, useLocation } from "react-router-dom";
import { ProductsList } from "../../components/ProductsList/ProductsList";
import { ProductsListFiltersForm } from "../../forms/ProductsListFilters/ProductsListFiltersForm";
import { IProductsListFiltersFormValues } from "../../forms/ProductsListFilters/ProductsListFiltersFormSchema";
import { ConvertProductsFilterFormValuesToBackEndValues } from "../../forms/ProductsListFilters/utils";

export const ProductsListContainer: FC = () => {
  const { classes } = useCommonStyles();
  const { itemCounts } = useMarketplaceCartContext();
  const { isAuthenticated } = useAuth();
  const location: Location = useLocation();

  const params = queryString.parse(location.search) as { categoryId?: string };

  const [listInput, setListInput] = useState<{
    filters?: MarketplaceProductFilterInput;
    sortingFilters?: MarketplaceProductSortingInput;
  }>({});

  const handleProductsFiltersFormSubmit = (values: IProductsListFiltersFormValues) => {
    const backendValues = ConvertProductsFilterFormValuesToBackEndValues(values);
    setListInput(
      produce(backendValues, draft => {
        draft.filters = draft.filters || { categories: [] };
        draft.filters.categories = listInput?.filters?.categories;
      })
    );
  };

  const handleOnCategorySelect = (id?: string) => {
    setListInput(
      produce(listInput, draft => {
        draft.filters = draft.filters || { categories: [] };
        draft.filters.categories = !id ? [] : xor(draft.filters.categories, [id]);
      })
    );
  };

  const selectedCategories = useMemo(() => {
    return (listInput?.filters?.categories || []).filter((category): category is string => !!category);
  }, [listInput?.filters?.categories]);

  useLayoutEffect(() => {
    if (params.categoryId) {
      handleOnCategorySelect(params.categoryId);
    }
  }, []);

  return (
    <Box className={classes.pageContainer}>
      <CategoriesFilters categoryType='products' selectedCategories={selectedCategories} onClick={handleOnCategorySelect} />
      <ProductsListFiltersForm onSubmit={handleProductsFiltersFormSubmit} />
      <Box className={classes.pageContent} display='contents'>
        <ProductsList sortingInput={listInput?.sortingFilters} filters={listInput?.filters} />
      </Box>
      {isAuthenticated && (itemCounts?.totalCount || 0) > 0 && (
        <Box className={classes.footer} bgcolor={"red"}>
          <Box width='calc(100% - 33px)'>
            <CartSummaryBar />
          </Box>
        </Box>
      )}
    </Box>
  );
};
