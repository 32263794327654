import { RecipeArticlesContainer } from "@/domains/articles";
import { Section } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { useReelsSectionStyles } from "../ReelsSection/ReelsSectionStyle";

export const RecipesSection: FC = () => {
  const { t } = useTranslation("consumer");
  const { classes } = useReelsSectionStyles();
  return (
    <Section
      classes={{
        container: classes.container,
      }}
      mt={1.8}
      mb={1.8}
      title={t("Recipes")}
    >
      <RecipeArticlesContainer />
    </Section>
  );
};
