import { makeStyles } from "@toolkit/ui";

export const useDigitalTwinSleepCalculatorResultStyle = makeStyles()(theme => {
  return {
    root: {
      padding: 16,
      display: "flex",
      flexDirection: "column",
      rowGap: 8,
    },
    title: {
      fontSize: theme.mixins.fonts.fontSize.md,
      alignSelf: "flex-start",
    },
    boldTitle: {
      fontSize: theme.mixins.fonts.fontSize.md,
      alignSelf: "flex-start",
    },
  };
});
