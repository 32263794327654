import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const MoneyIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20.172' height='17.133' viewBox='0 0 20.172 17.133' {...props}>
      <g id='icons8-money' transform='translate(0 0)'>
        <path
          id='Path_12995'
          data-name='Path 12995'
          d='M22.028,34.985a1.86,1.86,0,0,1-.753-.161A56.111,56.111,0,0,0,7.661,30.581a.8.8,0,1,1,.27-1.569A57.639,57.639,0,0,1,21.92,33.368a.324.324,0,0,0,.051.018,14.057,14.057,0,0,1,.411-1.4.8.8,0,0,1,1.511.5,11.426,11.426,0,0,0-.4,1.391q0,.019-.008.037a1.369,1.369,0,0,1-.744.908A1.629,1.629,0,0,1,22.028,34.985Z'
          transform='translate(-5.938 -17.852)'
          fill='#24287f'
        />
        <path
          id='Path_12996'
          data-name='Path 12996'
          d='M23.314,25.948H6.858A1.86,1.86,0,0,1,5,24.09V19.9a.8.8,0,0,1,1.593,0V24.09a.269.269,0,0,0,.265.265H23.314a.269.269,0,0,0,.265-.265V22.5a.8.8,0,1,1,1.593,0V24.09A1.86,1.86,0,0,1,23.314,25.948Z'
          transform='translate(-5 -13.208)'
          fill='#24287f'
        />
        <path
          id='Path_12997'
          data-name='Path 12997'
          d='M24.376,15.75a.8.8,0,0,1-.8-.8v-5.1a.269.269,0,0,0-.265-.265H6.858a.269.269,0,0,0-.265.265v2.23a.8.8,0,0,1-1.593,0V9.858A1.86,1.86,0,0,1,6.858,8H23.314a1.86,1.86,0,0,1,1.858,1.858v5.1A.8.8,0,0,1,24.376,15.75Z'
          transform='translate(-5 -8)'
          fill='#24287f'
        />
        <circle id='Ellipse_2945' data-name='Ellipse 2945' cx='1' cy='1' r='1' transform='translate(15.211 5.407)' fill={fill} />
        <ellipse
          id='Ellipse_2946'
          data-name='Ellipse 2946'
          cx='1.5'
          cy='1'
          rx='1.5'
          ry='1'
          transform='translate(2.211 5.407)'
          fill={fill}
        />
        <path
          id='Ellipse_2947'
          data-name='Ellipse 2947'
          d='M1.685-1.5A2.96,2.96,0,0,1,3.991-.353a4.053,4.053,0,0,1,.88,2.569,4.053,4.053,0,0,1-.88,2.569A2.96,2.96,0,0,1,1.685,5.932,2.96,2.96,0,0,1-.62,4.785,4.053,4.053,0,0,1-1.5,2.216,4.053,4.053,0,0,1-.62-.353,2.96,2.96,0,0,1,1.685-1.5Zm0,5.839a1.4,1.4,0,0,0,1.073-.563,2.459,2.459,0,0,0,.52-1.56,2.459,2.459,0,0,0-.52-1.56A1.4,1.4,0,0,0,1.685.093,1.4,1.4,0,0,0,.612.656a2.459,2.459,0,0,0-.52,1.56,2.459,2.459,0,0,0,.52,1.56A1.4,1.4,0,0,0,1.685,4.339Z'
          transform='translate(8.401 4.154)'
          fill='#24287f'
        />
      </g>
    </SvgIcon>
  );
};
