import { makeStyles } from "@toolkit/ui";

export const useEPrescriptionReferenceSearchStyles = makeStyles()(theme => ({
  root: {},
  iconWrapper: {
    "& svg": {
      width: "170px",
      height: "170px",
    },
    marginTop: "70px",
    marginBottom: "37px",
  },
  searchButton: {
    width: "100%",
    margin: 0,
    height: "55px",
    borderRadius: 28,
    marginTop: 15,
  },
  searchIcon: {
    "& svg": {
      width: "16px",
      height: "16px",
    },
    paddingLeft: 10,
  },
  infoText: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    marginBottom: 5,
  },
  boldText: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
