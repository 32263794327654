import { EmptyMessageLayout } from "@/shared/components";
import { useMarketplaceCartContext } from "@/shared/context";
import { EmptyCartIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Typography } from "@toolkit/ui";
import { get, groupBy, isEmpty, sortBy } from "lodash";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { CartHealthPackageCard } from "../CartHealthPackageCard/CartHealthPackageCard";
import { CartProductCard } from "../CartProductCart/CartProductCard";
import { CartSummaryBar } from "../CartSummaryBar/CartSummaryBar";
import { MarketplaceSelectedCardSkeleton } from "../MarketplaceSelectedCardSkeleton/MarketplaceSelectedCardSkeleton";
import { useCartListStyles } from "./CartListStyles";

export const CartList: FC = () => {
  const { classes } = useCartListStyles();
  const { t } = useTranslation("consumer");
  const { isCartLoading, cart, itemCounts } = useMarketplaceCartContext();

  const navigate = useNavigate();

  const handleNavigateToShop = () => {
    navigate(routes.root.absRoute);
  };

  const cartItems = cart?.items;
  const sortedGroupedItems = useMemo(() => {
    const groupedCartListItems = groupBy(cartItems, item => (item?.healthPackage?.id ? "healthPackage" : "product"));

    return Object.keys(groupedCartListItems).reduce((result, key) => {
      result[key] = sortBy(groupedCartListItems[key], item => {
        const name = get(item, "product.name", "") || get(item, "healthPackage.name", "");
        const nameAr = get(item, "product.nameAr", "") || get(item, "healthPackage.nameAr", "");
        return name || nameAr || "";
      });
      return result;
    }, {});
  }, [cartItems]);

  if (isCartLoading && !cartItems?.length)
    return (
      <Box className={classes.root}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Box mt={"10px"} key={index}>
            <MarketplaceSelectedCardSkeleton />
          </Box>
        ))}
      </Box>
    );

  if (isEmpty(cartItems))
    return (
      <Box mt={10} className={classes.emptyPageContainer}>
        <EmptyMessageLayout
          message={t("Your cart is empty. Start shopping now!")}
          subMessage={t("Find the best deals and unique items just for you.")}
          icon={<EmptyCartIcon />}
          action={
            <Button className={classes.startShopingButton} onClick={handleNavigateToShop}>
              {t("Start Shopping")}
            </Button>
          }
        />
      </Box>
    );

  return (
    <Box className={classes.root}>
      {["product", "healthPackage"].map(key => {
        const items = sortedGroupedItems[key];

        return (
          !isEmpty(items) && (
            <Box className={classes.sectionContainer} key={key}>
              <Box mt={key === "healthPackage" ? "30px" : "10px"} className={classes.sectionHeader}>
                <Typography className={classes.sectionTitle}>{key === "healthPackage" ? t("Health Packages") : t("Products")}</Typography>
                <Box className={classes.sectionCountContainer}>{items.length}</Box>
              </Box>
              {items.map(item =>
                key === "healthPackage" ? (
                  <CartHealthPackageCard cartItemId={item?.id} key={item.id} healthPackage={item?.healthPackage} />
                ) : (
                  <CartProductCard key={item.id} product={item?.product} cartItemId={item?.id} itemQuantity={item?.quantity} />
                )
              )}
            </Box>
          )
        );
      })}
      {!!itemCounts?.totalCount && (
        <Box className={classes.subTotalContainer}>
          <CartSummaryBar title={t("Sub Total")} />
        </Box>
      )}
    </Box>
  );
};
