import { GuidedCareJourneyItem } from "@/schema/types";
import { UserProfileIcon } from "@/shared/icons";
import { Box, Divider, format, formatDate, Typography } from "@toolkit/ui";
import React, { FC } from "react";
import { guidedCareActivityTypeOptionsMap, guidedCareJourneyItemStatusOptionsMap } from "@health/enum-options";
import { useStyles } from "./CareJourneyActivitiesItemCardStyle";
import { getGuidedCareJourneyItemStatusIcon, getGuidedCareJourneyItemTypeIcon } from "../CareJourneyDetails/utils";

export const CareJourneyActivitiesItemCard: FC<{ guidedCareJourneyItem: GuidedCareJourneyItem }> = ({ guidedCareJourneyItem }) => {
  const guidedCareJourneyItemTypeIcon = getGuidedCareJourneyItemTypeIcon(guidedCareJourneyItem?.guidedCareJourneyItemType!);
  const statusIcon = getGuidedCareJourneyItemStatusIcon(guidedCareJourneyItem?.guidedCareJourneyItemStatus!);
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.marker} />
      <Box className={classes.row}>
        <Box className={classes.dateBox}>
          <Typography className={classes.dueDate} fontWeight={"bold"}>
            {format(guidedCareJourneyItem.dueDate, "DD")}
          </Typography>
          <Typography className={classes.dueDate}>{format(guidedCareJourneyItem.dueDate, "MMM")}</Typography>
        </Box>
        <Box className={classes.itemTypeIcon}>{guidedCareJourneyItemTypeIcon}</Box>
      </Box>

      <Box className={classes.contain}>
        <Box className={classes.arrow} />
        <Box className={classes.card}>
          <Box className={classes.box}>
            <Typography fontWeight='bold' textAlign='left'>
              {guidedCareActivityTypeOptionsMap[guidedCareJourneyItem?.guidedCareJourneyItemType!]?.label}
            </Typography>
            <Typography variant='body2'>{formatDate(guidedCareJourneyItem?.dueDate)}</Typography>
          </Box>
          <Divider className={classes.divider} />
          {guidedCareJourneyItem.teamMember?.user?.fullName && (
            <Box className={classes.contentView}>
              <UserProfileIcon />
              <Typography variant='body1' ml={"5px"} noWrap>
                {guidedCareJourneyItem.teamMember.user.fullName}
              </Typography>
            </Box>
          )}
          {guidedCareJourneyItem.guidedCareJourneyItemServiceDetails?.map(service => (
            <Typography key={service?.id} textAlign='left' variant='body2'>
              {service?.serviceName}
            </Typography>
          ))}
          {guidedCareJourneyItem.medicalForm && (
            <Box>
              <Typography textAlign='left' fontWeight='medium' noWrap>
                {guidedCareJourneyItem.medicalForm.name}
              </Typography>
              <Typography textAlign='left'>{guidedCareJourneyItem?.medicalForm.description}</Typography>
            </Box>
          )}
          <Box className={classes.contentView}>
            {statusIcon && <Box>{statusIcon}</Box>}
            <Typography mt={"-5px"} ml='5px'>
              {guidedCareJourneyItemStatusOptionsMap[guidedCareJourneyItem?.guidedCareJourneyItemStatus!].label}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
