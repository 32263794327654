import { useCommonContainerStyles } from "@/shared/styles";
import { Box } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { OrderTrackingViewProps } from "../../others/types";
import { useHeaderContext } from "@/shared/context";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { SelectedPharmacyDetails } from "../SelectedPharmacyDetails/SelectedPharmacyDetails";
import { PrescriptionInformationCard } from "../PrescriptionInformationCard/PrescriptionInformationCard";
import { DispensedMedicationsCard } from "../DispensedMedicationsCard/DispensedMedicationsCard";
import { OrderDeliveredCard } from "../OrderDeliveredCard/OrderDeliveredCard";

export const OrderDeliveredPage: FC<OrderTrackingViewProps> = props => {
  const { order, prescription } = props;
  const { classes } = useCommonContainerStyles();
  const { setHeaderTitle } = useHeaderContext();
  const { t } = useTranslation("consumer");

  const selectedPharmacy = pickLocalizedValue(order?.children?.[0]?.branch, order?.children?.[0]?.branch);
  selectedPharmacy;
  useEffect(() => {
    setHeaderTitle(t("Deliver the order"));
    () => setHeaderTitle("");
  }, [order]);

  return (
    <>
      <Box className={classes.pageContent}>
        <OrderDeliveredCard />
        <SelectedPharmacyDetails order={order} selectedPharmacy={selectedPharmacy} />
        <PrescriptionInformationCard order={order} prescription={prescription} />
        <DispensedMedicationsCard order={order} prescription={prescription} />
      </Box>
    </>
  );
};
