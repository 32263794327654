import { PayNowDialog } from "@/domains/payment/pay-now-dialog/pay-now-dialog";
import { useHeaderContext } from "@/shared/context";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { FC, useEffect } from "react";
import { orderMedicationsRoutes } from "../../routes";
import { Box, Button } from "@toolkit/ui";
import { AmountSourceType } from "@/schema/types";
import { PaymentSummaryContainerDataProps } from "../../others/types";
import { useLocation, Location } from "react-router-dom";
import { OrderReviewCard } from "../../components/OrderReviewCard/OrderReviewCard";
import { PrescriptionInformationCard } from "../../components/PrescriptionInformationCard/PrescriptionInformationCard";
import { DispensedMedicationsCard } from "../../components/DispensedMedicationsCard/DispensedMedicationsCard";

export const PaymentSummaryContainer: FC = () => {
  const { classes, cx } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const { setHeaderTitle } = useHeaderContext();
  const location: Location<PaymentSummaryContainerDataProps> = useLocation();
  const { order, prescription } = location?.state || {};

  const handlePay = () => {
    PayNowDialog.open();
    localStorage.setItem(
      "payment-call-back-source-page",
      orderMedicationsRoutes.prescriptionOrder.getRouteWithParams({ orderId: order?.id || "", prescriptionId: prescription?.id || "" })
    );
  };

  useEffect(() => {
    setHeaderTitle(t("Order Review"));
    return () => {
      setHeaderTitle("");
    };
  }, []);

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <PayNowDialog amountSource={AmountSourceType.PrescriptionOrder} amountSourceId={order?.id || ""} />
        <OrderReviewCard address={order?.address} />
        <PrescriptionInformationCard prescription={prescription} order={order} />
        <DispensedMedicationsCard prescription={prescription} order={order} />
      </Box>
      <Box className={cx(classes.footer)}>
        <Button onClick={handlePay} className={cx(classes.button)}>
          {t("Pay Now")}
        </Button>
      </Box>
    </Box>
  );
};
