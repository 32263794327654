import { FC } from "react";
import { InfoCardWithHeader } from "@/shared/components";
import { Box, Skeleton } from "@toolkit/ui";
import { useMedicationsOrderTrackingCardStyles } from "./useMedicationsOrderTrackingCard.styles";

export const MedicationsOrderTrackingCardSkeleton: FC = () => {
  const { theme, classes } = useMedicationsOrderTrackingCardStyles();

  const HeaderSkeleton = () => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Skeleton variant='circular' width={65} height={65} />
      <Box>
        <Skeleton width={80} height={24} />
        <Skeleton width={150} height={20} sx={{ mt: 0.5 }} />
      </Box>
    </Box>
  );

  const ContentSkeleton = () => (
    <Box className={classes.contentContainer}>
      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
        <Box sx={{ p: 2 }}>
          <Skeleton width={60} height={16} sx={{ mb: 1 }} />
          <Skeleton width={120} height={20} />
        </Box>
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Box>
            <Skeleton width={60} height={16} sx={{ mb: 1 }} />
            <Skeleton width={80} height={24} sx={{ borderRadius: "16px" }} />
          </Box>
        </Box>

        <Box sx={{ p: 2, backgroundColor: theme.palette.primary[50], borderRadius: "10px 0 0 10px" }}>
          <Skeleton width={80} height={16} sx={{ mb: 1 }} />
          <Skeleton width={100} height={20} />
        </Box>
        <Box sx={{ p: 2, backgroundColor: theme.palette.primary[50], borderRadius: "0 10px 10px 0" }}>
          <Skeleton width={80} height={16} sx={{ mb: 1 }} />
          <Skeleton width={90} height={20} />
        </Box>

        <Box sx={{ p: 2 }}>
          <Skeleton width={90} height={16} sx={{ mb: 1 }} />
          <Skeleton width={110} height={20} />
        </Box>
        <Box sx={{ p: 2 }}>
          <Skeleton width={70} height={16} sx={{ mb: 1 }} />
          <Skeleton width={100} height={20} />
        </Box>

        <Box sx={{ p: 2, backgroundColor: theme.palette.primary[50], borderRadius: "10px 0 0 10px" }}>
          <Skeleton width={60} height={16} sx={{ mb: 1 }} />
          <Skeleton width={80} height={20} />
        </Box>
        <Box sx={{ p: 2, backgroundColor: theme.palette.primary[50], borderRadius: "0 10px 10px 0" }}>
          <Skeleton width={40} height={16} sx={{ mb: 1 }} />
          <Skeleton width={70} height={20} />
        </Box>
      </Box>
    </Box>
  );

  return (
    <InfoCardWithHeader
      mt='10px'
      mx='10px'
      classes={{
        contentContainer: classes.contentContainer,
      }}
      header={<HeaderSkeleton />}
    >
      <ContentSkeleton />
    </InfoCardWithHeader>
  );
};
