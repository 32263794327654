import { getPatientId } from "@/domains/patient";
import { useHeaderContext } from "@/shared/context";
import { formatGraphQLError } from "@toolkit/apollo";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FileUploadProgress, useAddToast } from "@toolkit/ui";
import { get } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { PatientHealthDocumentCreateMutation, usePatientHealthDocumentCreateMutation } from "../../gql/mutations";
import { PatientMedicalProfileDocumentsDocument } from "../../gql/queries";
import { routes } from "../../routes";
import { IPatientHealthDocumentFormValues, PatientHealthDocumentFormSchema } from "./UploadDocumentContainerFormSchema";

export const useUploadDocumentHook = () => {
  const { t } = useTranslation("consumer");
  const { failed, succeeded } = useAddToast();
  const navigate = useNavigate();
  const { setHeaderTitle } = useHeaderContext();
  const { user } = useAuth();
  const [fileLinks, setFileLinks] = useState<string[]>([]);

  const form = useCustomForm<IPatientHealthDocumentFormValues>({
    schema: PatientHealthDocumentFormSchema,
    mode: "onChange",
  });
  const { handleSubmit, setValue } = form;
  const patientId = getPatientId(get(user?.profile, "patient_id") as string)!;

  const [patientHealthDocumentCreateMutation, { loading }] = usePatientHealthDocumentCreateMutation({
    onCompleted: (data: PatientHealthDocumentCreateMutation) => {
      if (!data?.PatientHealthDocumentCreate?.id) {
        failed(t("Failed to upload document"));
      }
      succeeded(t("Document uploaded successfully"));
      return navigate(routes.myDocument.absRoute);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [{ query: PatientMedicalProfileDocumentsDocument, variables: { patientId: patientId } }],
  });

  const onEachFileUploadComplete = (fileProgress: FileUploadProgress) => {
    const fileUrl = fileProgress?.fileLink;
    setFileLinks(prevLinks => [...prevLinks, fileUrl]);
    setValue("fileLinks", [...fileLinks, fileUrl]);
  };

  const onSubmit = (values: IPatientHealthDocumentFormValues) => {
    patientHealthDocumentCreateMutation({
      variables: { input: { ...values, type: values.type.value, resultDate: moment(values.resultDate).format("YYYY-MM-DD"), fileLinks } },
    });
  };
  useEffect(() => {
    setHeaderTitle(t("Upload New Document"));
  }, [setHeaderTitle, t]);
  return {
    form,
    handleSubmit,
    onSubmit,
    onEachFileUploadComplete,
    loading,
    accessToken: user?.access_token,
    fileLinks,
  };
};
