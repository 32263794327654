import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddSnapshotToMeetingMutationVariables = Types.Exact<{
  input: Types.AddSnapshotToMeetingInput;
}>;


export type AddSnapshotToMeetingMutation = { __typename?: 'Mutation', addSnapshotToMeeting?: boolean | null };


export const AddSnapshotToMeetingDocument = gql`
    mutation AddSnapshotToMeeting($input: AddSnapshotToMeetingInput!) {
  addSnapshotToMeeting(input: $input)
}
    `;
export type AddSnapshotToMeetingMutationFn = Apollo.MutationFunction<AddSnapshotToMeetingMutation, AddSnapshotToMeetingMutationVariables>;

/**
 * __useAddSnapshotToMeetingMutation__
 *
 * To run a mutation, you first call `useAddSnapshotToMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSnapshotToMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSnapshotToMeetingMutation, { data, loading, error }] = useAddSnapshotToMeetingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSnapshotToMeetingMutation(baseOptions?: Apollo.MutationHookOptions<AddSnapshotToMeetingMutation, AddSnapshotToMeetingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSnapshotToMeetingMutation, AddSnapshotToMeetingMutationVariables>(AddSnapshotToMeetingDocument, options);
      }
export type AddSnapshotToMeetingMutationHookResult = ReturnType<typeof useAddSnapshotToMeetingMutation>;
export type AddSnapshotToMeetingMutationResult = Apollo.MutationResult<AddSnapshotToMeetingMutation>;
export type AddSnapshotToMeetingMutationOptions = Apollo.BaseMutationOptions<AddSnapshotToMeetingMutation, AddSnapshotToMeetingMutationVariables>;