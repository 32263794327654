import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DayDeliveryTimeSlotGetQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.DeliveryTimeSlotFilterInput>;
  sortBy?: Types.InputMaybe<Types.DeliveryTimeSlotSortingInput>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DayDeliveryTimeSlotGetQuery = { __typename?: 'Query', deliveryTimeSlots?: { __typename?: 'DeliveryTimeSlotCountableConnection', edges: Array<{ __typename?: 'DeliveryTimeSlotCountableEdge', node: { __typename?: 'DeliveryTimeSlot', deliveryType: Types.DeliveryTypes, endTime: string, startTime: string, isActive: boolean, id: string } }> } | null };


export const DayDeliveryTimeSlotGetDocument = gql`
    query DayDeliveryTimeSlotGet($first: Int, $filter: DeliveryTimeSlotFilterInput, $sortBy: DeliveryTimeSlotSortingInput, $after: String) {
  deliveryTimeSlots(
    after: $after
    first: $first
    filter: $filter
    sortBy: $sortBy
  ) {
    edges {
      node {
        deliveryType
        endTime
        startTime
        isActive
        id
      }
    }
  }
}
    `;

/**
 * __useDayDeliveryTimeSlotGetQuery__
 *
 * To run a query within a React component, call `useDayDeliveryTimeSlotGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDayDeliveryTimeSlotGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDayDeliveryTimeSlotGetQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDayDeliveryTimeSlotGetQuery(baseOptions?: Apollo.QueryHookOptions<DayDeliveryTimeSlotGetQuery, DayDeliveryTimeSlotGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DayDeliveryTimeSlotGetQuery, DayDeliveryTimeSlotGetQueryVariables>(DayDeliveryTimeSlotGetDocument, options);
      }
export function useDayDeliveryTimeSlotGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DayDeliveryTimeSlotGetQuery, DayDeliveryTimeSlotGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DayDeliveryTimeSlotGetQuery, DayDeliveryTimeSlotGetQueryVariables>(DayDeliveryTimeSlotGetDocument, options);
        }
export function useDayDeliveryTimeSlotGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DayDeliveryTimeSlotGetQuery, DayDeliveryTimeSlotGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DayDeliveryTimeSlotGetQuery, DayDeliveryTimeSlotGetQueryVariables>(DayDeliveryTimeSlotGetDocument, options);
        }
export type DayDeliveryTimeSlotGetQueryHookResult = ReturnType<typeof useDayDeliveryTimeSlotGetQuery>;
export type DayDeliveryTimeSlotGetLazyQueryHookResult = ReturnType<typeof useDayDeliveryTimeSlotGetLazyQuery>;
export type DayDeliveryTimeSlotGetSuspenseQueryHookResult = ReturnType<typeof useDayDeliveryTimeSlotGetSuspenseQuery>;
export type DayDeliveryTimeSlotGetQueryResult = Apollo.QueryResult<DayDeliveryTimeSlotGetQuery, DayDeliveryTimeSlotGetQueryVariables>;