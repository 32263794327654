import { makeStyles } from "@toolkit/ui";

export const useProductsContainerStyles = makeStyles()(() => ({
  productCardWrapper: {
    paddingBlock: "5px",
    "&&:first-child": {
      marginInlineStart: "5px",
    },
    "&:last-child": {
      marginInlineEnd: "5px",
    },
    width: "200px",
    height: "290px",
    minWidth: "155px",
  },
  cardsContainerClassName: {
    height: "100%",
    overflowY: "hidden",
    backgroundColor: "inherit",
  },
  cardsUpperContainer: {
    gap: "0px",
  },
  cardsContainer: {},
  card: {
    marginInlineEnd: 0,
    height: "fit-content",
  },
  navBtnClassName: {},
  navBtnContainerClassName: {},
  actionsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  skeletonContainer: {
    gap: "5px",
  },
}));
