import { makeStyles } from "@toolkit/ui";

export const useCheckoutContainerStyles = makeStyles()(() => ({
  footer: {
    height: "fit-content",
    flexDirection: "column",
    padding: 15,
    gap: 10,
  },
  button: {
    width: "70%",
  },
}));
