import { CallInstance } from "./types";
import { Box, Button, CustomIcon, Divider, IconButton, List, MuiDrawer, Typography, useMediaQuery } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useCallMenuHook } from "./useCallMenuHook";
import { useMeetingSidebarStyles } from "./MeetingSidebar.styles";
import { ParticipantListItem } from "./ParticipantListItem";
import { MeetingChatView } from "./MeetingChatView";

type MeetingSidebarProps = ReturnType<typeof useCallMenuHook> & {
  call: CallInstance;
};

export const MeetingSidebar = ({
  call,
  isChatOpen,
  isParticipantsOpen,
  isDrawerOpen,
  hasInvitationLink,
  isInvitationEnabled,
  onCloseDrawer,
  onToggleChat,
  onToggleParticipants,
  onToggleGuestInvite,
  handleShareInvitation,
}: MeetingSidebarProps) => {
  const { t } = useTranslation();
  const { classes, theme } = useMeetingSidebarStyles();
  const media = useMediaQuery(theme.breakpoints.down("md"));
  const drawerWidth = isDrawerOpen ? 320 : "unset";

  return (
    <MuiDrawer
      variant={media ? "temporary" : "permanent"}
      keepMounted
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      open={isDrawerOpen}
      anchor='right'
      onClose={onCloseDrawer}
      className={classes.root}
    >
      {isParticipantsOpen && (
        <Box className={classes.item}>
          <Box className={classes.itemHeader}>
            <Typography textAlign='center' color={theme.palette.common.white} fontSize={theme.mixins.fonts.fontSize.lg}>
              {t("Participants")}
            </Typography>
            <IconButton color='inherit' onClick={onToggleParticipants}>
              <CustomIcon color={theme.palette.common.white} icon='xAlert' />
            </IconButton>
          </Box>
          <Box sx={{ overflow: "auto" }}>
            {isInvitationEnabled && (
              <Box sx={{ p: 1, display: "flex", justifyContent: "space-between" }}>
                <Button onClick={onToggleGuestInvite}>{t("Guest Invite")}</Button>
                {hasInvitationLink && (
                  <Button variant='contained' onClick={handleShareInvitation}>
                    {t("Share Invite")}
                  </Button>
                )}
              </Box>
            )}
            <List>
              {call.participants.map(p => (
                <ParticipantListItem key={p.id} participant={p} />
              ))}
            </List>
          </Box>
        </Box>
      )}
      {isChatOpen && (
        <>
          {isParticipantsOpen && <Divider />}
          <Box className={classes.item}>
            <Box className={classes.itemHeader}>
              <Typography textAlign='center' color={theme.palette.common.white} fontSize={theme.mixins.fonts.fontSize.lg}>
                {t("Chat")}
              </Typography>

              <IconButton color='inherit' onClick={onToggleChat}>
                <CustomIcon color={theme.palette.common.white} icon='xAlert' />
              </IconButton>
            </Box>
            <MeetingChatView call={call} />
          </Box>
        </>
      )}
    </MuiDrawer>
  );
};
