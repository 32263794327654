import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountAddressDeleteMutationVariables = Types.Exact<{
  accountAddressDeleteId: Types.Scalars['ID']['input'];
}>;


export type AccountAddressDeleteMutation = { __typename?: 'Mutation', accountAddressDelete?: { __typename?: 'AccountAddressDelete', address?: { __typename?: 'Address', id: string } | null } | null };


export const AccountAddressDeleteDocument = gql`
    mutation AccountAddressDelete($accountAddressDeleteId: ID!) {
  accountAddressDelete(id: $accountAddressDeleteId) {
    address {
      id
    }
  }
}
    `;
export type AccountAddressDeleteMutationFn = Apollo.MutationFunction<AccountAddressDeleteMutation, AccountAddressDeleteMutationVariables>;

/**
 * __useAccountAddressDeleteMutation__
 *
 * To run a mutation, you first call `useAccountAddressDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountAddressDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountAddressDeleteMutation, { data, loading, error }] = useAccountAddressDeleteMutation({
 *   variables: {
 *      accountAddressDeleteId: // value for 'accountAddressDeleteId'
 *   },
 * });
 */
export function useAccountAddressDeleteMutation(baseOptions?: Apollo.MutationHookOptions<AccountAddressDeleteMutation, AccountAddressDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountAddressDeleteMutation, AccountAddressDeleteMutationVariables>(AccountAddressDeleteDocument, options);
      }
export type AccountAddressDeleteMutationHookResult = ReturnType<typeof useAccountAddressDeleteMutation>;
export type AccountAddressDeleteMutationResult = Apollo.MutationResult<AccountAddressDeleteMutation>;
export type AccountAddressDeleteMutationOptions = Apollo.BaseMutationOptions<AccountAddressDeleteMutation, AccountAddressDeleteMutationVariables>;