import { FC } from "react";

import { CallInstance } from "./useCall";
import { AntMediaView, AntMediaViewProps } from "./AntMediaView";

type AntMediaPublisherViewProps = {
  call: CallInstance;
} & Omit<AntMediaViewProps, "participant">;

export const AntMediaPublisherView: FC<AntMediaPublisherViewProps> = ({ call, ...props }) => {
  const { videoMuted } = call.state;
  const participant = call.currentParticipant;
  const renderSelfView = !videoMuted;
  if (!renderSelfView || !participant) {
    return null;
  }

  return <AntMediaView participant={participant} {...props} />;
};
