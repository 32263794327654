import { FC } from "react";

import { useTranslation } from "@toolkit/i18n";
import { Card, Divider, Typography } from "@toolkit/ui";
import { BadgedResult } from "./BadgedResult";
import { TdeeAndBmrChart } from "./TdeeAndBmrChart";
import { getNutritionValuesColors } from "./constant";
import { useDigitalTwinBmrTdeeCalculatorResultStyle } from "./useDigitalTwinBmrTdeeCalculatorResultStyle";

type DigitalTwinBmrTdeeCalculatorResultProps = {
  maxTdee: number;
  tdee?: number;
  bee?: number;
  weightLossPlanData?: {
    numberOfDays: number;
    goalWeight: number;
    targetCalories: number;
    newTdeeValue: number;
    reduceCalorieIntake: number;
  };
  hideChart?: boolean;
};

export const DigitalTwinBmrTdeeCalculatorResult: FC<DigitalTwinBmrTdeeCalculatorResultProps> = props => {
  const { t } = useTranslation();
  const { classes, theme } = useDigitalTwinBmrTdeeCalculatorResultStyle();
  const { maxTdee, tdee, bee, weightLossPlanData, hideChart } = props;
  const maxTdeeRound = Math.round(maxTdee || 0);
  const tdeeValueRound = Math.round(tdee || 0);
  const beeValueRound = Math.round(bee || 0);
  const hasWeightLossPlanData = !!weightLossPlanData;
  const targetCaloriesRound = hasWeightLossPlanData ? Math.round(weightLossPlanData?.targetCalories || 0) : 0;
  const newTdeeRounded = hasWeightLossPlanData ? Math.round(weightLossPlanData?.newTdeeValue || 0) : 0;
  const reduceCalorieIntakeRounded = hasWeightLossPlanData ? Math.round(weightLossPlanData?.reduceCalorieIntake || 0) : 0;

  const nutritionValuesColors = getNutritionValuesColors(theme);

  return (
    <>
      {!hideChart && (
        <TdeeAndBmrChart
          maxTdee={maxTdeeRound}
          targetWeight={weightLossPlanData?.goalWeight ? weightLossPlanData?.goalWeight + t("kg") : t("No Data")}
          tdee={tdeeValueRound}
          bee={beeValueRound}
          targetCalories={targetCaloriesRound}
          label={t("Target Weight")}
        />
      )}

      <Card className={classes.root}>
        <Typography className={classes.sectionTitle}> {t("Calorie needs")} </Typography>
        <BadgedResult
          badgeValue={tdeeValueRound + ""}
          badgeColor={nutritionValuesColors.tdee}
          description={t("Calories your body needs to maintain your current weight based on your new physical activity level.")}
        />
        <BadgedResult
          badgeValue={beeValueRound + ""}
          badgeColor={nutritionValuesColors.bee}
          description={t("Calories your body needs to maintain your current weight without any physical activity.")}
        />

        {hasWeightLossPlanData && (
          <>
            <Divider sx={{ h: 0.2 }} />
            <Typography className={classes.sectionTitle}> {t("Weight loss adjusted calorie")} </Typography>
            <BadgedResult
              badgeValue={newTdeeRounded + ""}
              badgeColor={nutritionValuesColors.newTdee}
              description={t("Calories your body needs to maintain your current weight based on your new physical activity level.")}
            />
            <BadgedResult
              badgeValue={targetCaloriesRound + ""}
              badgeColor={nutritionValuesColors.targetCalories}
              description={t("Calories required to reach the target weight {{targetWeight}} in {{days}} days", {
                targetWeight: weightLossPlanData?.goalWeight,
                days: weightLossPlanData?.numberOfDays,
              })}
            />
            <BadgedResult
              badgeValue={reduceCalorieIntakeRounded + ""}
              badgeColor={nutritionValuesColors.reduceCalorieIntake}
              description={t("Daily calories you need to reduce.")}
            />
          </>
        )}
      </Card>
    </>
  );
};
