import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { CallContext, CallInfo, CallProviderInfo, UserInfo } from "./CallContext";
import { AntMediaProvider, useAntMediaCall, useZoomCall, ZoomProvider, useSanarCall } from "./types";
import { ConferenceProviderType } from "@/schema/types";
import { useMeetingSettings } from "./useMeetingSettings";

type ProviderProps = CallProviderProps & {
  settings: CallProviderInfo["settings"];
};

const Provider: FC<ProviderProps> = ({ children, settings, user: userInfo, accessToken }) => {
  const [callInfo, setCallInfo] = useState<CallInfo>();
  const user = useMemo(() => ({ userId: userInfo?.user_id, userName: userInfo?.name }), [userInfo?.user_id, userInfo?.name]);
  const select = useCallback((v: CallInfo) => setCallInfo(v), []);
  const defaultProvider = settings?.type;
  const provider = useMemo(() => {
    if (!callInfo?.configuration?.providerType || callInfo?.configuration?.providerType === ConferenceProviderType.Default) {
      return defaultProvider;
    }

    return callInfo?.configuration?.providerType;
  }, [callInfo?.configuration?.providerType, defaultProvider]);

  const zoomCall = useZoomCall({ callInfo: provider === ConferenceProviderType.Zoom ? callInfo : undefined, user });
  const antMediaCall = useAntMediaCall({ callInfo: provider === ConferenceProviderType.AntMedia ? callInfo : undefined, user });
  const sanarCall = useSanarCall({ callInfo: provider === ConferenceProviderType.Sanar ? callInfo : undefined, user });
  const call = useMemo(() => {
    switch (provider) {
      case ConferenceProviderType.Zoom:
        return zoomCall;
      case ConferenceProviderType.AntMedia:
        return antMediaCall;
      case ConferenceProviderType.Sanar:
        return sanarCall;
      default:
        return null;
    }
  }, [antMediaCall, provider, zoomCall, sanarCall]);

  const value = useMemo(
    () => ({ callInfo, userInfo, call, select, accessToken, settings } as CallProviderInfo),
    [call, callInfo, select, accessToken, userInfo, settings]
  );

  return <CallContext.Provider value={value}>{children}</CallContext.Provider>;
};

type CallProviderProps = PropsWithChildren<{
  user?: UserInfo;
  accessToken?: string;
}>;

export const CallProvider: FC<CallProviderProps> = props => {
  const settings = useMeetingSettings();
  const config = useMemo(
    () => ({
      enableLog: process.env.NODE_ENV !== "production",
      url: settings.url || "",
    }),
    [settings.url]
  );

  return (
    <AntMediaProvider config={config}>
      <ZoomProvider config={config}>
        <Provider settings={settings} {...props} />
      </ZoomProvider>
    </AntMediaProvider>
  );
};
