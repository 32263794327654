import { PageTabs } from "@/shared/components";
import { makeStyles } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { MyAccountsSettingsTabsEnum } from "../../types";
import { accountSettingsTabs } from "./tabs";

const useStyles = makeStyles()(() => ({
  root: {
    marginBlockStart: "10px",
    marginBlockEnd: "10px",
  },
}));

export const AccountSettingsTabs: FC<{ currentTab: MyAccountsSettingsTabsEnum }> = ({ currentTab }) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const handleOnTabClick = (event: React.BaseSyntheticEvent, index: number) => {
    event.preventDefault();
    const selectedTab = accountSettingsTabs[index]?.routeParameterValue;
    navigate(routes.root.getRouteWithParams({ tab: selectedTab }));
  };

  const tabsLabels = accountSettingsTabs.map(tab => tab.label);

  const getSelectedTabIndex = () => {
    const index = accountSettingsTabs.findIndex(_tab => _tab.routeParameterValue === currentTab);
    return index === -1 ? 0 : index;
  };

  return (
    <PageTabs
      classes={{
        root: classes.root,
      }}
      activeTabIndex={getSelectedTabIndex()}
      onTabClick={handleOnTabClick}
      tabs={tabsLabels}
    />
  );
};
