import { PageTabs } from "@/shared/components";
import { Box, makeStyles } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { myOrdersTabs } from "./tabs";
import { routes } from "@/domains/marketplace/routes";

const useStyles = makeStyles()(() => ({
  container: {
    marginBlockStart: "10px",
    marginBlockEnd: "10px",
    paddingInline: "10px",
  },
}));

export const MyOrdersTabs: FC<{ tabIndex: number }> = ({ tabIndex }) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const handleOnTabClick = (event: React.BaseSyntheticEvent, index: number) => {
    event.preventDefault();
    const selectedTab = myOrdersTabs[index]?.routeParameterValue;
    navigate(routes.myOrders.getRouteWithParams({ tab: selectedTab }));
  };

  const tabsLabels = myOrdersTabs.map(tab => tab.label);

  return (
    <Box className={classes.container}>
      <PageTabs activeTabIndex={tabIndex} onTabClick={handleOnTabClick} tabs={tabsLabels} />
    </Box>
  );
};
