import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDependentsListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDependentsListQuery = { __typename?: 'Query', me?: { __typename?: 'User', dependents?: Array<{ __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, dateOfBirth?: any | null, fullName?: string | null, gender?: Types.UserGender | null, photo?: string | null, relationType?: string | null } | null> | null } | null };


export const GetDependentsListDocument = gql`
    query getDependentsList {
  me {
    dependents {
      id
      firstName
      lastName
      dateOfBirth
      fullName
      gender
      photo
      relationType
    }
  }
}
    `;

/**
 * __useGetDependentsListQuery__
 *
 * To run a query within a React component, call `useGetDependentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDependentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDependentsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDependentsListQuery(baseOptions?: Apollo.QueryHookOptions<GetDependentsListQuery, GetDependentsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDependentsListQuery, GetDependentsListQueryVariables>(GetDependentsListDocument, options);
      }
export function useGetDependentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDependentsListQuery, GetDependentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDependentsListQuery, GetDependentsListQueryVariables>(GetDependentsListDocument, options);
        }
export function useGetDependentsListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDependentsListQuery, GetDependentsListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDependentsListQuery, GetDependentsListQueryVariables>(GetDependentsListDocument, options);
        }
export type GetDependentsListQueryHookResult = ReturnType<typeof useGetDependentsListQuery>;
export type GetDependentsListLazyQueryHookResult = ReturnType<typeof useGetDependentsListLazyQuery>;
export type GetDependentsListSuspenseQueryHookResult = ReturnType<typeof useGetDependentsListSuspenseQuery>;
export type GetDependentsListQueryResult = Apollo.QueryResult<GetDependentsListQuery, GetDependentsListQueryVariables>;