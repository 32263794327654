import { orderMedicationsRoutes } from "@/domains/order-medication";
import { FC, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, Outlet } from "react-router-dom";

export const MedicationsOrdersController: FC = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(orderMedicationsRoutes.root.absRoute, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return null;
  }

  return <Outlet />;
};
