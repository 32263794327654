import { makeStyles } from "@toolkit/ui";

export const useProductDetailsCardStyle = makeStyles()(theme => ({
  root: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    padding: "10px",
    flexDirection: "column",
    textAlign: "start",
    color: theme.palette.common.black,
  },
  mainText: {
    color: theme.palette.common.black,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  priceContainer: {
    marginBlock: "5px",
  },
  shortDescription: {
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    whiteSpace: "pre-line",
  },
  quantitySection: {
    marginTop: "20px",
    borderTop: "1px solid",
    borderColor: theme.palette.divider,
    minHeight: "40px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
  },
  actionSection: {
    borderTop: "1px solid",
    borderColor: theme.palette.divider,
    minHeight: "60px",
    width: "100%",
    paddingTop: "10px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "10px",
    paddingInline: "5px",
  },
  buyButton: {
    height: "48px",
    flex: 1,
    borderRadius: "24px",
  },
}));
