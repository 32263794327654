import { FormRadio, GenericFilterForm } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import {
  healthPackagesListFiltersFormDefaultValues,
  HealthPackagesListFiltersFormSchema,
  IHealthPackagesListFiltersFormValues,
} from "../HealthPackagesListFiltersFormSchema";
import { HealthPackagesListFiltersFormProps } from "../types";
import { sortingOptions } from "../utils";

export const SortingFilterForm: React.FC<HealthPackagesListFiltersFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IHealthPackagesListFiltersFormValues>
      schema={HealthPackagesListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='sortingField'
      dialogTitle={t("Sorting")}
      formLabel={t("Sorting")}
      render={name => (
        <FormRadio
          radioGroupProps={{
            sx: {
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              gap: 1,
            },
          }}
          name={name}
          options={sortingOptions}
        />
      )}
      defaultValues={healthPackagesListFiltersFormDefaultValues}
    />
  );
};
