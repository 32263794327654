import { AccountSettingsInformationDocument } from "@/pages/AccountSettings/gql/queries";
import { AddressTypeEnum } from "@/schema/types";
import { useHeaderContext } from "@/shared/context";
import { useCommonContainerStyles } from "@/shared/styles";
import { useApolloClient } from "@apollo/client";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, useAddToast } from "@toolkit/ui";
import { isEmpty } from "lodash";
import { FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AddressUpsertForm, AddressUpsertFormRef, IGoogleMapAddressUpsertFormValues } from "../../forms/AddressFormUpsert";
import { AddressFragmentFragmentDoc } from "../../gql/fragments";
import { useAccountAddressCreateMutation, useAccountSetDefaultAddressMutation } from "../../gql/mutations";
import useMapAddress from "../../hooks/useMapAddress";
import { convertAddressFormValuesToBackEndValues } from "../../utils";

export const AddressCreateContainer: FC = () => {
  const { classes } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const formRef = useRef<AddressUpsertFormRef>(null);
  const navigate = useNavigate();
  const { failed } = useAddToast();
  const client = useApolloClient();

  const { mapAddress } = useMapAddress();

  const [setAddressDefault, { loading: isSetDefaultSubmitting }] = useAccountSetDefaultAddressMutation({
    onCompleted: () => {
      navigate(-1);
    },
    onError: () => {
      failed(t("Failed to create address"));
    },
    refetchQueries: [AddressFragmentFragmentDoc, AccountSettingsInformationDocument],
  });
  const [createAddress, { loading: isSubmitting }] = useAccountAddressCreateMutation({
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleAddreaseCreate = (values: IGoogleMapAddressUpsertFormValues) => {
    createAddress({ variables: { input: convertAddressFormValuesToBackEndValues(values) } })
      .then(response => {
        const addressId = response.data?.accountAddressCreate?.address?.id;
        addressId &&
          values?.isDefault &&
          setAddressDefault({ variables: { accountSetDefaultAddressId: addressId, type: AddressTypeEnum.Shipping } });
        !isEmpty(response?.data?.accountAddressCreate?.accountErrors) &&
          failed(t(formatMessageErrors(response?.data?.accountAddressCreate?.accountErrors)));

        if (addressId && !values?.isDefault) {
          client.refetchQueries({ include: [AddressFragmentFragmentDoc, AccountSettingsInformationDocument] });
          navigate(-1);
        }
      })
      .catch(() => {
        failed(t("Failed to create address"));
      });
  };
  const handleAddNewAddress = () => {
    formRef.current?.submit();
  };

  const { setHeaderTitle } = useHeaderContext();
  useEffect(() => {
    setHeaderTitle(t("Create Address"));
    return () => {
      setHeaderTitle("");
    };
  }, [setHeaderTitle, t]);
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <AddressUpsertForm
          mapAddress={mapAddress}
          isSubmitting={isSubmitting || isSetDefaultSubmitting}
          onChange={handleAddreaseCreate}
          ref={formRef}
        />
      </Box>
      <Box className={classes.footer}>
        <Button className={classes.button} disabled={isSubmitting || isSetDefaultSubmitting} onClick={handleAddNewAddress}>
          {t("Add New Address")}
        </Button>
      </Box>
    </Box>
  );
};
