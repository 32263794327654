import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceCartAddItemMutationVariables = Types.Exact<{
  input: Types.MarketplaceCartInput;
}>;


export type MarketplaceCartAddItemMutation = { __typename?: 'Mutation', marketplaceCartAddItem?: { __typename?: 'MarketplaceCart', id: string } | null };


export const MarketplaceCartAddItemDocument = gql`
    mutation MarketplaceCartAddItem($input: MarketplaceCartInput!) {
  marketplaceCartAddItem(input: $input) {
    id
  }
}
    `;
export type MarketplaceCartAddItemMutationFn = Apollo.MutationFunction<MarketplaceCartAddItemMutation, MarketplaceCartAddItemMutationVariables>;

/**
 * __useMarketplaceCartAddItemMutation__
 *
 * To run a mutation, you first call `useMarketplaceCartAddItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceCartAddItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceCartAddItemMutation, { data, loading, error }] = useMarketplaceCartAddItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceCartAddItemMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceCartAddItemMutation, MarketplaceCartAddItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceCartAddItemMutation, MarketplaceCartAddItemMutationVariables>(MarketplaceCartAddItemDocument, options);
      }
export type MarketplaceCartAddItemMutationHookResult = ReturnType<typeof useMarketplaceCartAddItemMutation>;
export type MarketplaceCartAddItemMutationResult = Apollo.MutationResult<MarketplaceCartAddItemMutation>;
export type MarketplaceCartAddItemMutationOptions = Apollo.BaseMutationOptions<MarketplaceCartAddItemMutation, MarketplaceCartAddItemMutationVariables>;