import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { MarketplaceWishlistHealthPackageDetailsFragmentFragmentDoc } from '../../fragments/__generated__/MarketplaceWishlistHealthPackageDetailsFragment';
import { MarketplaceWishlistProductDetailsFragmentFragmentDoc } from '../../fragments/__generated__/MarketplaceWishlistProductDetailsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyMarketplaceWishlistQueryVariables = Types.Exact<{
  showDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type MyMarketplaceWishlistQuery = { __typename?: 'Query', me?: { __typename?: 'User', marketplaceWishlist?: { __typename?: 'MarketplaceWishlist', id: string, items: Array<{ __typename?: 'MarketplaceWishlistItems', id: string, healthPackage?: { __typename?: 'MarketplaceHealthPackage', id: string, name?: string | null, nameAr?: string | null, mainImageUrl?: string | null, price?: any | null, description?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, category?: { __typename?: 'MarketplaceHealthPackageCategory', name?: string | null, nameAr?: string | null, id: string } | null, vendor: { __typename?: 'Vendor', name: string, nameAr?: string | null }, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, percentage?: number | null, type: Types.MarketplaceDiscountType } | null } | null, product?: { __typename?: 'MarketplaceProduct', id: string, price?: any | null, name?: string | null, nameAr?: string | null, mainImageUrl?: string | null, description?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, vendor: { __typename?: 'Vendor', name: string, nameAr?: string | null }, category?: { __typename?: 'MarketplaceProductCategory', id: string, name?: string | null, nameAr?: string | null } | null, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, percentage?: number | null, type: Types.MarketplaceDiscountType } | null } | null }> } | null } | null };


export const MyMarketplaceWishlistDocument = gql`
    query MyMarketplaceWishlist($showDetails: Boolean = false) {
  me {
    marketplaceWishlist {
      id
      items {
        id
        healthPackage {
          id
          ...MarketplaceWishlistHealthPackageDetailsFragment @include(if: $showDetails)
        }
        product {
          id
          ...MarketplaceWishlistProductDetailsFragment @include(if: $showDetails)
        }
      }
    }
  }
}
    ${MarketplaceWishlistHealthPackageDetailsFragmentFragmentDoc}
${MarketplaceWishlistProductDetailsFragmentFragmentDoc}`;

/**
 * __useMyMarketplaceWishlistQuery__
 *
 * To run a query within a React component, call `useMyMarketplaceWishlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMarketplaceWishlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMarketplaceWishlistQuery({
 *   variables: {
 *      showDetails: // value for 'showDetails'
 *   },
 * });
 */
export function useMyMarketplaceWishlistQuery(baseOptions?: Apollo.QueryHookOptions<MyMarketplaceWishlistQuery, MyMarketplaceWishlistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyMarketplaceWishlistQuery, MyMarketplaceWishlistQueryVariables>(MyMarketplaceWishlistDocument, options);
      }
export function useMyMarketplaceWishlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyMarketplaceWishlistQuery, MyMarketplaceWishlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyMarketplaceWishlistQuery, MyMarketplaceWishlistQueryVariables>(MyMarketplaceWishlistDocument, options);
        }
export function useMyMarketplaceWishlistSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyMarketplaceWishlistQuery, MyMarketplaceWishlistQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyMarketplaceWishlistQuery, MyMarketplaceWishlistQueryVariables>(MyMarketplaceWishlistDocument, options);
        }
export type MyMarketplaceWishlistQueryHookResult = ReturnType<typeof useMyMarketplaceWishlistQuery>;
export type MyMarketplaceWishlistLazyQueryHookResult = ReturnType<typeof useMyMarketplaceWishlistLazyQuery>;
export type MyMarketplaceWishlistSuspenseQueryHookResult = ReturnType<typeof useMyMarketplaceWishlistSuspenseQuery>;
export type MyMarketplaceWishlistQueryResult = Apollo.QueryResult<MyMarketplaceWishlistQuery, MyMarketplaceWishlistQueryVariables>;