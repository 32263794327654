import { MarketPlacePaymentSummaryProps } from "../components/CartItemsPaymentSummary/MarketPlacePaymentSummary";
import { marketplaceOrderDetailsType } from "../types";

export const EMPTY_PAYMENT_SUMMARY: Readonly<MarketPlacePaymentSummaryProps> = {
  totalPrice: 0,
  totalPriceWithVAT: 0,
  totalDiscount: 0,
  totalDeliveryPrice: 0,
  totalVat: 0,
} as const;

const parseToFloat = (value: string | undefined): number => parseFloat(value || "0");

export const calculatePaymentSummary = (items: Array<marketplaceOrderDetailsType> | undefined): MarketPlacePaymentSummaryProps => {
  if (!items || !items.length) {
    return EMPTY_PAYMENT_SUMMARY;
  }

  const totals = items.reduce(
    (acc, { totalNetAmount, totalNetAmountWithVAT, totalDiscount, totalDeliveryPrice }) => {
      acc.totalPrice += parseToFloat(totalNetAmount);
      acc.totalPriceWithVAT += parseToFloat(totalNetAmountWithVAT);
      acc.totalDiscount += parseToFloat(totalDiscount);
      acc.totalDeliveryPrice += parseToFloat(totalDeliveryPrice);
      return acc;
    },
    { totalPrice: 0, totalPriceWithVAT: 0, totalDiscount: 0, totalDeliveryPrice: 0, totalVat: 0 }
  );

  totals.totalVat = Math.max(0, totals.totalPriceWithVAT - totals.totalPrice);

  return totals;
};
