import { AppointmentBookingProvider } from "@/domains/appointment/context/AppointmentBookingContext/AppointmentBookingContext";
import { MainPageLayout, PageWithBackHeaderLayout } from "@/shared/components";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "../../domains/appointment/routes";

export const AppointmentsRoutes: FC = () => {
  return (
    <>
      <AppointmentBookingProvider>
        <Routes>
          <Route element={<MainPageLayout containerBreakpoint='sm' />}>
            <Route path={routes.root.route} Component={routes.root.component} />
            <Route path={routes.schedule.route} Component={routes.schedule.component} />
            <Route path={routes.confirmForm.route} Component={routes.confirmForm.component} />
            <Route path={routes.confirmOTP.route} Component={routes.confirmOTP.component} />
            <Route path={routes.confirm.route} Component={routes.confirm.component} />
          </Route>
        </Routes>
      </AppointmentBookingProvider>
      <Routes>
        <Route element={<MainPageLayout containerBreakpoint='sm' />}>
          <Route path={routes.createSuccess.route} Component={routes.createSuccess.component} />
          <Route path={routes.myAppointments.route} Component={routes.myAppointments.component} />
        </Route>
      </Routes>
      <Routes>
        <Route element={<PageWithBackHeaderLayout containerBreakpoint='sm' />}>
          <Route path={routes.reschedule.route} Component={routes.reschedule.component} />
          <Route path={routes.confirmDoctorTime.route} Component={routes.confirmDoctorTime.component} />
          <Route path={routes.paymentSummary.route} Component={routes.paymentSummary.component} />
        </Route>
      </Routes>
    </>
  );
};
