import { AmountSourceType } from "@/schema/types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logIoEvent, logPaymentEvent } from "../constants";
import { CustomWebViewProps } from "../custom-web-view/custom-web-view.component";
import { usePreparePayment } from "../hooks/use-prepare-payment.hook";

type PaymentWebView = {
  amountSourceId: string;
  amountSource: AmountSourceType;
  addressId?: string;
  onSuccess?: () => void;
  onError?: () => void;
};

const usePaymentWebView = ({ addressId, amountSourceId, amountSource, onSuccess, onError }: PaymentWebView) => {
  const { paymentInfo, failedPayment, isPaymentProcessLoading, getCheckoutId, fetchCreateCheckoutId, getPreparedShopperResultUrl } =
    usePreparePayment();

  const { integrity, checkoutPaymentFormUrl, shopperResultUrl } = paymentInfo.current;
  const navigate = useNavigate();

  // const { failed } = useAddToast();

  // const handlePaymentError = (url?: string) => {
  //   logPaymentEvent({
  //     resultUrl: url || getPreparedShopperResultUrl(),
  //     checkoutUrl: checkoutPaymentFormUrl!,
  //     amountSourceId: amountSourceId!,
  //     amountSourceType: amountSource!,
  //     success: false,
  //   });
  //   failed("Payment Failed", "Please another method, or try again later!");
  //   onError?.();
  // };

  const handleOnLoadStart: CustomWebViewProps["handleOnLoadStart"] = e => {
    console.log("handleOnLoadStart", e, onSuccess, onError);
    // const { nativeEvent } = e;
    // const { url } = nativeEvent;

    // if (url.startsWith(ERROR_URL) || url.startsWith(decodeURIComponent(ERROR_URL))) {
    //   handlePaymentError(url);
    //   logPaymentEvent({
    //     resultUrl: url,
    //     checkoutUrl: checkoutPaymentFormUrl!,
    //     amountSourceId: amountSourceId!,
    //     amountSourceType: amountSource!,
    //     success: false,
    //   });
    // } else if (url.startsWith(SUCCESS_URL)) {
    //   onSuccess?.();
    //   logPaymentEvent({
    //     resultUrl: url,
    //     checkoutUrl: checkoutPaymentFormUrl!,
    //     amountSourceId: amountSourceId!,
    //     amountSourceType: amountSource!,
    //     success: true,
    //   });
    // }
  };

  const handleOnRefresh = () => {
    fetchCreateCheckoutId({
      variables: {
        input: {
          amountSourceId: amountSourceId!,
          amountSourceType: amountSource!,
          addressId: addressId,
        },
      },
    });
  };

  const handleGoToHome = () => {
    navigate("/");
  };

  logIoEvent("payment from webview", {
    resultUrl: getPreparedShopperResultUrl(),
    checkoutId: getCheckoutId(),
    shopperResultUrl,
    checkoutPaymentFormUrl,
  });

  useEffect(() => {
    if (!amountSourceId || !amountSource) {
      return;
    }

    if (!checkoutPaymentFormUrl) {
      fetchCreateCheckoutId({
        variables: {
          input: {
            amountSourceId: amountSourceId!,
            amountSourceType: amountSource!,
            addressId: addressId,
          },
        },
      });
    }
  }, [checkoutPaymentFormUrl, amountSourceId, amountSource, fetchCreateCheckoutId]);

  useEffect(() => {
    if (!amountSource || !amountSourceId || !checkoutPaymentFormUrl) {
      return;
    }

    logPaymentEvent({
      resultUrl: getPreparedShopperResultUrl(),
      integrity,
      checkoutUrl: checkoutPaymentFormUrl,
      amountSourceId: amountSourceId!,
      amountSourceType: amountSource!,
    });
  }, [checkoutPaymentFormUrl, integrity, amountSourceId, amountSource]);

  return {
    integrity,
    failedPayment,
    isPaymentProcessLoading,
    checkoutPaymentFormUrl,
    handleGoToHome,
    handleOnRefresh,
    handleOnLoadStart,
    getPreparedShopperResultUrl,
  };
};

export default usePaymentWebView;
