import { useCallback } from "react";
import { DeliveryTypes, ValidatePrescriptionInput } from "@/schema/types";
import { orderMedicationsRoutes } from "../routes";
import { formatGraphQLError } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { OrderAndDeliverPrescriptionContainerDataType } from "../others/types";
import { useValidatePrescriptionMutation } from "../gql/mutations";
import { isEmpty } from "lodash";
import { isOrderInFinalState } from "../others/utils";

export const useValidatePrescription = () => {
  const navigate = useNavigate();
  const { failed } = useAddToast();

  const [validatePrescription, { loading }] = useValidatePrescriptionMutation({
    onCompleted: (data, options) => {
      if (data.validatePrescription?.prescription?.id) {
        const response = data.validatePrescription;
        const input = options?.variables?.input as ValidatePrescriptionInput;
        const activeOrder = response?.prescription?.activeOrders?.[0];
        if (
          isEmpty(response?.prescription?.activeOrders) ||
          !response?.prescription?.activeOrders?.[0]?.id ||
          (response?.prescription?.activeOrders?.[0]?.status && isOrderInFinalState(response?.prescription?.activeOrders?.[0]?.status))
        ) {
          navigate(orderMedicationsRoutes.orderAndDeliver.absRoute, {
            state: {
              prescriptionReference: input?.referenceNumber,
              memberID: input?.memberID,
              prescriptionID: response.prescription.id,
              allowedDeliveryTypes: (response?.allowedDeliveryTypes as unknown as DeliveryTypes[]) || [],
              medications: response.prescription.medications,
            } as OrderAndDeliverPrescriptionContainerDataType,
          });
        } else {
          navigate(
            orderMedicationsRoutes.prescriptionOrder.getRouteWithParams({
              orderId: activeOrder?.id || "",
              prescriptionId: response?.prescription?.id,
            })
          );
        }
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleValidatePrescription = useCallback(
    (input: ValidatePrescriptionInput) => {
      validatePrescription({
        variables: {
          input,
        },
      });
    },
    [validatePrescription]
  );

  return { handleValidatePrescription, loading };
};
