import { CustomCard, EmptyMessageLayout } from "@/shared/components";
import { CheckoutIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { makeStyles } from "@toolkit/ui";
import { FC } from "react";

const useOrderCardStyles = makeStyles()(() => ({
  root: {
    padding: 20,
  },
  emptyMessageLayout: {},
  iconContainer: {
    "& svg": {
      width: 140,
      height: 130,
    },
  },
  messageContainer: {
    marginTop: 20,
  },
}));

export const OrderStatusSummaryCard: FC = () => {
  const { t } = useTranslation("consumer");
  const { classes } = useOrderCardStyles();
  return (
    <CustomCard className={classes.root}>
      <EmptyMessageLayout
        classes={{
          root: classes.emptyMessageLayout,
          iconContainer: classes.iconContainer,
          messageContainer: classes.messageContainer,
        }}
        icon={<CheckoutIcon />}
        message={t("Thank you! Your order has been placed.")}
      />
    </CustomCard>
  );
};
