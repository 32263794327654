import { FC, useCallback, useState } from "react";
import { Box } from "@toolkit/ui";

import { ChatAttachment, ChatAttachmentInput } from "../../schema/types";
import { CircularProgress, MuiBrokenImageIcon } from "@toolkit/ui";
import { useChatViewMediaPhoto } from "./ChatViewMediaPhoto.hooks";
import { useStyles } from "./ChatViewMediaPhoto.styles";
import { ChatViewMediaPhotoZoom } from "./ChatViewMediaPhotoZoom";
import { ChatViewMediaDocument } from "./ChatViewMediaDocument";

type ChatViewMediaPhotoProps = {
  media: ChatAttachment | ChatAttachmentInput;
  isZoomDisabled?: boolean;
};

export const ChatViewMediaPhoto: FC<ChatViewMediaPhotoProps> = ({ media, isZoomDisabled }) => {
  const { src, width, height, loading, error, onLoad, onError, onDownload } = useChatViewMediaPhoto(media);
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const isZoomable = !loading && !error && !isZoomDisabled;
  const handleToggleOpen = useCallback(() => setOpen(o => !o), []);
  const onClick = isZoomable ? handleToggleOpen : undefined;

  if (error) {
    return (
      <Box className={classes.root} px={2} pt={2} pb={3}>
        <ChatViewMediaDocument media={media} icon={<MuiBrokenImageIcon sx={{ fontSize: 42 }} color='disabled' />} />
      </Box>
    );
  }

  return (
    <>
      {open && isZoomable && <ChatViewMediaPhotoZoom media={media} onDownload={onDownload} onClose={handleToggleOpen} />}
      <Box className={classes.root} sx={{ cursor: isZoomable ? "pointer" : "default" }} onClick={onClick}>
        {loading && (
          <Box className={classes.loader}>
            <CircularProgress color='primary' />
          </Box>
        )}
        {error && (
          <Box sx={{ width, height }}>
            <MuiBrokenImageIcon className={classes.placeholder} fontSize='large' color='disabled' />
          </Box>
        )}
        <img
          // eslint-disable-next-line react/forbid-dom-props
          style={{ visibility: loading || error ? "hidden" : "visible" }}
          onLoad={onLoad}
          onError={onError}
          draggable='false'
          src={src}
          width={width}
          height={height}
          alt=''
        />
      </Box>
    </>
  );
};
