import { makeStyles } from "@toolkit/ui";

export const useAddReviewAndCommentStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  ratingIcon: {
    padding: "5px",
  },
  commentContainer: {
    height: "50px",
    width: "100%",
    display: "flex",
    gap: "10px",
    marginTop: "15px",
  },
  commentTextField: {
    width: "100%",
    minHeight: "50px",
    flex: 7,
    "& .MuiInputBase-root, & .MuiInputBase-input:focus": {
      backgroundColor: theme.palette.stale[50],
      border: "none",
      color: theme.palette.text.primary,
    },
  },
  addButton: {
    padding: 0,
    margin: 0,
    width: "100%",
    height: "50px",
    flex: 1,
  },
}));
