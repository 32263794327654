import { CustomCard } from "@/shared/components";
import { DoctorRegisterIcon } from "@/shared/icons";
import { Trans, useTranslation } from "@toolkit/i18n";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

type CreatedAppointmentCardProps = {
  doctorName: string;
};

export const CreatedAppointmentCard: FC<CreatedAppointmentCardProps> = ({ doctorName }) => {
  const { t } = useTranslation("consumer");
  const theme = useTheme();
  return (
    <CustomCard sx={{ display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", padding: "10px" }}>
      <Box
        sx={{
          "&& svg": {
            width: "132px",
            height: "132px",
            marginTop: "10px",
          },
        }}
      >
        <DoctorRegisterIcon />
      </Box>
      <Typography
        sx={{
          fontSize: theme.mixins.fonts.fontSize.md,
        }}
      >
        <Trans
          t={t}
          i18nKey={"Your appointment with {{doctorName}}<br/> has been scheduled successfully"}
          values={{ doctorName }}
          components={{
            br: <br />,
          }}
        />
      </Typography>
      <Typography
        sx={{
          marginBottom: "5px",
          marginTop: "5px",
          color: theme.palette.stale.main,
          fontSize: theme.mixins.fonts.fontSize.sm,
        }}
      >
        <Trans
          t={t}
          i18nKey={"The details have been sent to your registered<br/> email address and mobile number"}
          values={{ doctorName }}
          components={{
            br: <br />,
          }}
        />
      </Typography>
    </CustomCard>
  );
};
