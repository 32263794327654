import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";
import { Adaptor, CallbackType, CustomMediaStreamTrack, MediaStreamTrackType, StreamConfig } from "./types";

class CustomWebRTCAdaptor extends WebRTCAdaptor {
  private iceRestart = false;
  initPeerConnection(streamId: string, dataChannelMode: string) {
    const pc = super.initPeerConnection(streamId, dataChannelMode) as RTCPeerConnection;
    pc.onnegotiationneeded = async () => {
      if (!this.iceRestart) {
        return;
      }
      this.iceRestart = false;
      const offer = await pc.createOffer({ iceRestart: this.iceRestart });
      this.gotDescription(offer, streamId);
    };
    return pc;
  }
  replaceTrack(streamId: string, track: MediaStreamTrack) {
    const pc = this.remotePeerConnection[streamId] as RTCPeerConnection;
    if (!pc) {
      return;
    }

    this.iceRestart = true;
    const sender = pc.getSenders().find(s => s.track?.kind === track.kind);
    if (sender) {
      sender.replaceTrack(track);
    } else {
      pc.addTrack(track);
    }
  }

  unpublish(streamId: string, roomId: string) {
    this.closeStream();
    const isConnected = this.webSocketAdaptor?.connected;
    if (isConnected) {
      this.stop(streamId);
      this.stop(roomId);
      this.closeWebSocket();
    }
  }
}

export function createAntMediaAdaptor({
  callback,
  debug,
  stream: localStream,
  ...config
}: StreamConfig & {
  stream: MediaStream;
  debug: boolean;
  callback: (config: StreamConfig) => (type: CallbackType, data: any, instance: CustomWebRTCAdaptor) => void;
}) {
  const { url, roomId, streamId, publishToken, playToken, streamName, metaData, iceServers } = config;
  let webRTCAdaptor: CustomWebRTCAdaptor;
  // eslint-disable-next-line prefer-const
  webRTCAdaptor = new CustomWebRTCAdaptor({
    localStream,
    websocket_url: url,
    debug,
    mediaConstraints: { audio: false, video: false },
    sdp_constraints: { offerToReceiveAudio: true, offerToReceiveVideo: true },
    peerconnection_config: { iceServers: iceServers && iceServers.length > 0 ? iceServers : [{ urls: "stun:stun1.l.google.com:19302" }] },
    reconnectIfRequiredFlag: false,
    callback: (type: CallbackType, data: any) => callback(config)(type, data, webRTCAdaptor),
    callbackError: (err: string, data: any) => {
      if (err.indexOf("WebSocketNotConnected") !== -1 || err.indexOf("WebSocketError") !== -1) {
        // WebSocket Connection is disconnected.
        callback(config)("web_socket_error", data, webRTCAdaptor);
      } else {
        callback(config)(err as CallbackType, data, webRTCAdaptor);
      }
    },
  });

  webRTCAdaptor.mediaManager.gotStream(localStream);
  webRTCAdaptor.mediaManager.disableAudioLevelForLocalStream();
  if (!localStream.getVideoTracks().length) {
    const videoTrack: CustomMediaStreamTrack = webRTCAdaptor.mediaManager.getBlackVideoTrack();
    videoTrack._type = MediaStreamTrackType.canvas;
    videoTrack && localStream.addTrack(videoTrack);
  }

  return {
    instance: webRTCAdaptor,
    getBroadcastObject: (pid: string) => webRTCAdaptor.getBroadcastObject(pid),
    stop: (id: string) => webRTCAdaptor.stop(id),
    publish: () => webRTCAdaptor.publish(streamId, publishToken, undefined, undefined, streamName, roomId, JSON.stringify(metaData)),
    unpublish: () => webRTCAdaptor.unpublish(streamId, roomId),
    play: () => webRTCAdaptor.play(roomId, playToken),
    updateUserStatus: (metadata: StreamConfig["metaData"]) => {
      if (!webRTCAdaptor.webSocketAdaptor) {
        return;
      }

      webRTCAdaptor.updateStreamMetaData(streamId, JSON.stringify(metadata));
      if (webRTCAdaptor.dataChannelEnabled) {
        webRTCAdaptor.sendData(roomId, JSON.stringify({ streamId, eventType: "UPDATE_STREAM_METADATA", metaData: metadata }));
      }
    },
    replaceTrack: (track: MediaStreamTrack) => webRTCAdaptor.replaceTrack(streamId, track),
  } as Adaptor;
}
