import { makeStyles } from "@toolkit/ui";

export const useProductSelectedCardStyle = makeStyles()(theme => ({
  card: {
    padding: 0,
  },
  itemAvatarContainer: {
    flex: 1.5,
  },
  infoContainer: {
    flex: 2,
    padding: "15px 15px 15px 0",
    width: "100px",
  },
  itemName: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  itemDiscretion: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  image: {
    minHeight: "70px",
    height: "80%",
    width: "80%",
    objectFit: "cover",
  },
  imageRoot: {
    marginTop: "5px",
    width: "70%",
    height: "70%",
    maxHeight: "140px",
    maxWidth: "140px",
  },
  actionsContainer: {},
  textColumn: {},
}));
