import { useCommonContainerStyles } from "@/shared/styles";
import { Box } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { OrderTrackingViewProps } from "../../others/types";
import { useHeaderContext } from "@/shared/context";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { OrderTrackingFooter } from "../OrderTrackingFooter/OrderTrackingFooter";
import { WaitingErxHubApprovalCard } from "../WaitingErxHubApprovalCard/WaitingErxHubApprovalCard";

export const WaitingErxHubApprovalPage: FC<OrderTrackingViewProps> = props => {
  const { order, prescription } = props;
  const { classes } = useCommonContainerStyles();
  const { setHeaderTitle } = useHeaderContext();
  const { t } = useTranslation("consumer");

  const selectedPharmacy = pickLocalizedValue(order?.children?.[0]?.branch, order?.children?.[0]?.branch);
  selectedPharmacy;
  useEffect(() => {
    setHeaderTitle(t("Payment Share"));
    () => setHeaderTitle("");
  }, [order]);

  return (
    <>
      <Box className={classes.pageContent}>
        <WaitingErxHubApprovalCard />
      </Box>
      <OrderTrackingFooter order={order} prescription={prescription} />
    </>
  );
};
