import React, { FC } from "react";

import moment from "moment";
import { SleepCalculatorClockWidget } from "./SleepCalculatorClockWidget";
import { useDigitalTwinSleepCalculatorResultStyle } from "./useDigitalTwinSleepCalculatorResultStyle";
import { useTranslation } from "@toolkit/i18n";
import { Card, ValueWithLabel } from "@toolkit/ui";

export type DigitalTwinSleepCalculatorResultProps = {
  isBasedOnSleepTime?: boolean;
  sleepHour: string;
  wakeUpHour: string;
  sleepCycleCounts: number;
};

export const DigitalTwinSleepCalculatorResult: FC<DigitalTwinSleepCalculatorResultProps> = props => {
  const { sleepHour, wakeUpHour, sleepCycleCounts, isBasedOnSleepTime } = props;
  const { t } = useTranslation("consumer");
  const { classes } = useDigitalTwinSleepCalculatorResultStyle();

  const description = isBasedOnSleepTime ? t("Sleep Time") : t("Wake Up Time");
  return (
    <>
      <SleepCalculatorClockWidget
        isBasedOnSleepTime={isBasedOnSleepTime}
        sleepTime={sleepHour}
        selectedTime={wakeUpHour}
        sleepCycles={sleepCycleCounts}
        label={description}
      />

      {wakeUpHour && sleepHour && (
        <Card className={classes.root}>
          <ValueWithLabel label={t("Recommended Sleep Time")} value={moment(sleepHour, "hh:mm A").format("h:mm A")} direction='row' />
          <ValueWithLabel label={t("Recommended Wake Up Time")} value={moment(wakeUpHour, "hh:mm A").format("h:mm A")} direction='row' />
        </Card>
      )}
    </>
  );
};
