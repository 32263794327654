import { CustomCard } from "@/shared/components";
import { Box } from "@toolkit/ui";
import { FC } from "react";
import { useMarketplaceCardLayoutStyles } from "./MarketplaceCardLayoutStyles";

export interface MarketplaceCardProps {
  actionsNode?: React.ReactNode;
  infoNode: React.ReactNode;
  itemAvatarNode: React.ReactNode;
  classes?: Partial<ReturnType<typeof useMarketplaceCardLayoutStyles>["classes"]>;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

export const MarketPlaceCardLayout: FC<MarketplaceCardProps> = props => {
  const { actionsNode, infoNode, itemAvatarNode, onClick, classes: _classes = {} } = props;

  const { classes, cx } = useMarketplaceCardLayoutStyles();

  return (
    <CustomCard onClick={onClick} className={cx(classes.card, _classes?.card)}>
      <Box className={cx(classes.container, _classes.container)}>
        <Box className={cx(classes.itemAvatarContainer, _classes.itemAvatarContainer)}>{itemAvatarNode}</Box>
        <Box className={cx(classes.infoContainer, _classes.infoContainer)}>
          <Box className={cx(classes.textColumn, _classes.textColumn)}>{infoNode}</Box>
          <Box className={cx(classes.actionsContainer, _classes.actionsContainer)}>{actionsNode}</Box>
        </Box>
      </Box>
    </CustomCard>
  );
};
