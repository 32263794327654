import React from "react";
import { Box, Skeleton } from "@toolkit/ui";
import { useProductsCategoriesContainerStyle } from "./ProductsCategoriesContainerStyle";

export const ProductsCategoriesContainerSkeleton: React.FC<{
  countOfCategories?: number;
  classes?: Partial<ReturnType<typeof useProductsCategoriesContainerStyle>["classes"]>;
}> = ({ countOfCategories = 9, classes: _classes = {} }) => {
  const { classes, cx } = useProductsCategoriesContainerStyle();

  return (
    <Box className={cx(classes.categoriesContainer, _classes.categoriesContainer)}>
      {Array.from({ length: countOfCategories + 1 }).map((_, index) => (
        <Box key={index} className={cx(classes.skeletonItem__container, _classes.skeletonItem__container)}>
          <Skeleton variant='circular' className={cx(classes.item__button, _classes.item__button)} />
          <Skeleton variant='text' width={"90%"} height={20} className={cx(classes.item__title, _classes.item__title)} />
        </Box>
      ))}
    </Box>
  );
};
