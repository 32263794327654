import { useCommonContainerStyles } from "@/shared/styles";
import { Box } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { OrderTrackingViewProps } from "../../others/types";
import { useHeaderContext } from "@/shared/context";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { OrderTrackingFooter } from "../OrderTrackingFooter/OrderTrackingFooter";
import { ConfirmedOrderCard } from "../ConfirmedOrderCard/ConfirmedOrderCard";
import { SelectedPharmacyDetails } from "../SelectedPharmacyDetails/SelectedPharmacyDetails";

export const OrderConfirmedPage: FC<OrderTrackingViewProps> = props => {
  const { order, prescription } = props;
  const { classes } = useCommonContainerStyles();
  const { setHeaderTitle } = useHeaderContext();
  const { t } = useTranslation("consumer");

  const selectedPharmacy = pickLocalizedValue(order?.children?.[0]?.branch, order?.children?.[0]?.branch);

  useEffect(() => {
    setHeaderTitle(t("Pharmacy Selecting"));
    () => setHeaderTitle("");
  }, [order]);

  return (
    <>
      <Box className={classes.pageContent}>
        <ConfirmedOrderCard order={order} />
        <SelectedPharmacyDetails order={order} selectedPharmacy={selectedPharmacy} />
      </Box>
      <OrderTrackingFooter order={order} prescription={prescription} />
    </>
  );
};
