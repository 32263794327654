import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { TdeeAndBmrCalculationBaseRouteParams } from "../../routes";

export const useTdeeAndBmrCalculationParams = (): TdeeAndBmrCalculationBaseRouteParams => {
  const location = useLocation();
  const result = queryString.parse(location.search) as Record<string, string>;

  return {
    bee: result?.bee ? Number(result.bee) : NaN,
    tdee: result?.tdee ? Number(result.tdee) : NaN,
    caloriesPerDayToAdjust: result?.caloriesPerDayToAdjust ? Number(result.caloriesPerDayToAdjust) : undefined,
    goalWeight: result?.goalWeight ? Number(result.goalWeight) : undefined,
    newCalorieIntake: result?.newCalorieIntake ? Number(result.newCalorieIntake) : undefined,
    newTdee: result?.newTdee ? Number(result.newTdee) : undefined,
    weightLossDays: result?.weightLossDays ? Number(result.weightLossDays) : undefined,
  };
};
