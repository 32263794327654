import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/queries/__generated__/pageInfoFragment';
import { ProductInfoFragmentDoc } from '../../fragments/__generated__/ProductInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.MarketplaceProductFilterInput>;
  sortBy?: Types.InputMaybe<Types.MarketplaceProductSortingInput>;
}>;


export type MarketplaceProductsQuery = { __typename?: 'Query', marketplaceProducts?: { __typename?: 'MarketplaceProductConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges: Array<{ __typename?: 'MarketplaceProductEdge', node: { __typename?: 'MarketplaceProduct', id: string, name?: string | null, nameAr?: string | null, price?: any | null, description?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, mainImageUrl?: string | null, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, endDate: any, percentage?: number | null, startDate: any, type: Types.MarketplaceDiscountType } | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null }, category?: { __typename?: 'MarketplaceProductCategory', id: string, name?: string | null, nameAr?: string | null } | null } }> } | null };


export const MarketplaceProductsDocument = gql`
    query MarketplaceProducts($first: Int, $after: String, $last: Int, $before: String, $filter: MarketplaceProductFilterInput, $sortBy: MarketplaceProductSortingInput) {
  marketplaceProducts(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        ...ProductInfo
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${ProductInfoFragmentDoc}`;

/**
 * __useMarketplaceProductsQuery__
 *
 * To run a query within a React component, call `useMarketplaceProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceProductsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMarketplaceProductsQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceProductsQuery, MarketplaceProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceProductsQuery, MarketplaceProductsQueryVariables>(MarketplaceProductsDocument, options);
      }
export function useMarketplaceProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceProductsQuery, MarketplaceProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceProductsQuery, MarketplaceProductsQueryVariables>(MarketplaceProductsDocument, options);
        }
export function useMarketplaceProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplaceProductsQuery, MarketplaceProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceProductsQuery, MarketplaceProductsQueryVariables>(MarketplaceProductsDocument, options);
        }
export type MarketplaceProductsQueryHookResult = ReturnType<typeof useMarketplaceProductsQuery>;
export type MarketplaceProductsLazyQueryHookResult = ReturnType<typeof useMarketplaceProductsLazyQuery>;
export type MarketplaceProductsSuspenseQueryHookResult = ReturnType<typeof useMarketplaceProductsSuspenseQuery>;
export type MarketplaceProductsQueryResult = Apollo.QueryResult<MarketplaceProductsQuery, MarketplaceProductsQueryVariables>;