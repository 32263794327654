import { useMemo } from "react";
import moment from "moment-timezone";
import { DeliveryTimeSlotSortField, DeliveryTypes, OrderDirection } from "@/schema/types";
import { useDayDeliveryTimeSlotGetQuery } from "../../gql/queries";

export const useTimeSlots = (deliveryType: DeliveryTypes | undefined, selectedDate?: moment.Moment) => {
  const queryVariables = useMemo(
    () => ({
      first: 100,
      filter: {
        deliveryType,
        isActive: true,
      },
      sortBy: {
        field: DeliveryTimeSlotSortField.StartTime,
        direction: OrderDirection.Asc,
      },
    }),
    [deliveryType]
  );

  const { data, loading } = useDayDeliveryTimeSlotGetQuery({
    variables: queryVariables,
    skip: !selectedDate,
  });

  const timeSlots = useMemo(() => data?.deliveryTimeSlots?.edges?.map(edge => edge?.node) || [], [data?.deliveryTimeSlots?.edges]);

  return { timeSlots, loading };
};
