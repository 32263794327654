import { AccountSettingsBaseRoute } from "@/pages/AccountSettings/routes";
import { AddressCreateContainer } from "./containers/AddressCreate/AddressCreateContainer";
import { AddressMapContainer } from "./containers/AddressMap/AddressMapContainer";
import { AddressUpdateContainer } from "./containers/AddressUpdate/AddressUpdateContainer";
import { MapCoordinates } from "./types";
import { AddressMapDisplayContainer } from "./containers/AddressMapDisplayContainer/AddressMapDisplayContainer";

export const routes = {
  new: {
    route: "/address/new",
    component: AddressCreateContainer,
    get absRoute() {
      return AccountSettingsBaseRoute + this.route;
    },
  },
  edit: {
    route: "/address/:addressId/edit",
    component: AddressUpdateContainer,
    get absRoute() {
      return AccountSettingsBaseRoute + this.route;
    },
    getAbsRouteWithParams(addressId: string) {
      return AccountSettingsBaseRoute + `/address/${addressId}/edit`;
    },
  },
  map: {
    route: "/address/map",
    component: AddressMapContainer,
    get absRoute() {
      return AccountSettingsBaseRoute + this.route;
    },
    getRouteWithParams({ coordinates }: { coordinates?: MapCoordinates }) {
      return coordinates ? `${this.absRoute}?lat=${coordinates.lat}&lng=${coordinates.lng}` : this.absRoute;
    },
  },
  mapDisplay: {
    route: "/address/map-display",
    component: AddressMapDisplayContainer,
    get absRoute() {
      return AccountSettingsBaseRoute + this.route;
    },
    getRouteWithParams({ coordinates }: { coordinates?: MapCoordinates }) {
      return coordinates ? `${this.absRoute}?lat=${coordinates.lat}&lng=${coordinates.lng}` : this.absRoute;
    },
  },
};
