import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const RadIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20.501' height='27.458' viewBox='0 0 20.501 27.458' {...props}>
      <g id='icons8-skeleton' transform='translate(-8.06 -2.65)'>
        <path
          id='Line_1822'
          data-name='Line 1822'
          d='M-.535,18.994A.965.965,0,0,1-1.5,18.03V-.535A.965.965,0,0,1-.535-1.5a.965.965,0,0,1,.965.965V18.03A.965.965,0,0,1-.535,18.994Z'
          transform='translate(18.846 4.15)'
          fill={fill}
        />
        <path
          id='Line_1823'
          data-name='Line 1823'
          d='M6.429.429H-.535A.965.965,0,0,1-1.5-.535.965.965,0,0,1-.535-1.5H6.429a.965.965,0,0,1,.965.965A.965.965,0,0,1,6.429.429Z'
          transform='translate(11.881 6.471)'
          fill={fill}
        />
        <path
          id='Line_1824'
          data-name='Line 1824'
          d='M6.037.429H-.535A.965.965,0,0,1-1.5-.535.965.965,0,0,1-.535-1.5H6.037A.965.965,0,0,1,7-.535.965.965,0,0,1,6.037.429Z'
          transform='translate(19.238 6.471)'
          fill={fill}
        />
        <path
          id='Line_1825'
          data-name='Line 1825'
          d='M6.429.429H-.535A.965.965,0,0,1-1.5-.535.965.965,0,0,1-.535-1.5H6.429a.965.965,0,0,1,.965.965A.965.965,0,0,1,6.429.429Z'
          transform='translate(11.881 16.915)'
          fill={fill}
        />
        <path
          id='Line_1826'
          data-name='Line 1826'
          d='M7.586.429H-.535A.965.965,0,0,1-1.5-.535.965.965,0,0,1-.535-1.5H7.586a.965.965,0,0,1,.965.965A.965.965,0,0,1,7.586.429Z'
          transform='translate(10.724 13.436)'
          fill={fill}
        />
        <path
          id='Line_1827'
          data-name='Line 1827'
          d='M6.037.429H-.535A.965.965,0,0,1-1.5-.535.965.965,0,0,1-.535-1.5H6.037A.965.965,0,0,1,7-.535.965.965,0,0,1,6.037.429Z'
          transform='translate(19.238 16.915)'
          fill={fill}
        />
        <path
          id='Line_1828'
          data-name='Line 1828'
          d='M7.194.429H-.535A.965.965,0,0,1-1.5-.535.965.965,0,0,1-.535-1.5h7.73a.965.965,0,0,1,.965.965A.965.965,0,0,1,7.194.429Z'
          transform='translate(19.238 13.436)'
          fill={fill}
        />
        <path
          id='Line_1829'
          data-name='Line 1829'
          d='M8.75.429H-.535A.965.965,0,0,1-1.5-.535.965.965,0,0,1-.535-1.5H8.75a.965.965,0,0,1,.965.965A.965.965,0,0,1,8.75.429Z'
          transform='translate(9.56 9.95)'
          fill={fill}
        />
        <path
          id='Line_1830'
          data-name='Line 1830'
          d='M8.358.429H-.535A.965.965,0,0,1-1.5-.535.965.965,0,0,1-.535-1.5H8.358a.965.965,0,0,1,.965.965A.965.965,0,0,1,8.358.429Z'
          transform='translate(19.238 9.95)'
          fill={fill}
        />
        <path
          id='Path_15015'
          data-name='Path 15015'
          d='M12.983,35.926a.964.964,0,0,1-.85-.507,3.865,3.865,0,0,1,3.4-5.7,5.219,5.219,0,0,1,2.068.585,5.108,5.108,0,0,0,2,.572.965.965,0,1,1,0,1.929,6.792,6.792,0,0,1-2.772-.735,3.946,3.946,0,0,0-1.293-.423,1.936,1.936,0,0,0-1.7,2.854.965.965,0,0,1-.848,1.423Z'
          transform='translate(-1.289 -9.663)'
          fill={fill}
        />
        <path
          id='Path_15016'
          data-name='Path 15016'
          d='M21.348,39.771a4.885,4.885,0,0,1-4.082-2.077,3.72,3.72,0,0,0-.291-.333.965.965,0,0,1,1.115-1.574,3.556,3.556,0,0,1,.669.686,3.134,3.134,0,0,0,5.18,0,1.955,1.955,0,0,1,1.473-.952,1.936,1.936,0,1,0,0-3.871.965.965,0,0,1,0-1.929,3.865,3.865,0,0,1,.231,7.723c-.064.071-.145.171-.211.251A4.887,4.887,0,0,1,21.348,39.771Z'
          transform='translate(-3.038 -9.663)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
