import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const StepIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.common.white;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='8.261' height='19' viewBox='0 0 8.261 19' {...props}>
      <path
        id='icons8-step'
        d='M17.022,3c-.684,0-1.239.74-1.239,1.652S16.337,6.3,17.022,6.3s1.239-.74,1.239-1.652S17.706,3,17.022,3Zm-2.68.56c-.456,0-.826.494-.826,1.1s.37,1.1.826,1.1.826-.493.826-1.1S14.8,3.56,14.342,3.56Zm-1.925.858c-.394,0-.713.425-.713.95s.319.95.713.95.711-.426.712-.95-.318-.949-.712-.95ZM11.11,5.957c-.279,0-.5.3-.505.673a.8.8,0,0,0,.148.477.451.451,0,0,0,.357.2c.279,0,.5-.3.5-.674S11.388,5.958,11.11,5.957ZM15.024,7.13A5.144,5.144,0,0,0,10,12.087c0,2.478,1.651,3.792,1.651,7.022a2.891,2.891,0,1,0,5.718-.6c-.008-.04-.018-.08-.027-.119a2.892,2.892,0,0,0-.1-.332c-.432-1.238-1.455-2.189-1.455-3.5,0-1.8,2.478-1.746,2.478-4.13A3.178,3.178,0,0,0,15.024,7.13Zm-4.611.55c-.228,0-.413.246-.413.55a.656.656,0,0,0,.121.39.345.345,0,0,0,.585,0,.656.656,0,0,0,.121-.39c0-.3-.185-.55-.413-.55Z'
        transform='translate(-10 -3)'
        fill={fill}
      />
    </SvgIcon>
  );
};
