import { MarketplaceItemAvatar } from "@/domains/marketplace/components/MarketplaceItemAvatar/MarketplaceItemAvatar";
import { CustomCard, CustomSwiper } from "@/shared/components";
import { Box, Skeleton } from "@toolkit/ui";
import { FC } from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { useProductImagesSliderStyle } from "./ProductImagesSliderStyle";

type ProductImagesSliderProps = {
  images: string[];
  isLoading: boolean;
};

export const ProductImagesSlider: FC<ProductImagesSliderProps> = ({ images, isLoading }) => {
  const { classes } = useProductImagesSliderStyle();

  if (isLoading) {
    return (
      <CustomCard className={classes.root}>
        <Box className={classes.skeleton}>
          <Skeleton variant='rectangular' className={classes.skeletonImage} />
          <Skeleton variant='rectangular' className={classes.skeletonPagination} />
        </Box>
      </CustomCard>
    );
  }

  return (
    <CustomCard className={classes.root}>
      <CustomSwiper
        className={classes.pagination}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 2000,
        }}
        slides={images.map((image, index) => (
          <MarketplaceItemAvatar
            alt={`Product Image ${index + 1}`}
            key={index}
            className={classes.image}
            imageUrl={image}
            marketplaceItemType='product'
          />
        ))}
        spaceBetween={10}
        slidesPerView={1}
        loop
        pagination={{
          clickable: true,
        }}
      />
    </CustomCard>
  );
};
