import { orderMedicationsRoutes } from "@/domains/order-medication";
import { MainPageWithAppBarLayout } from "@/shared/components";
import { MainPageLayout, PageWithAppBarAndBackHeaderLayout, PageWithBackHeaderLayout } from "@/shared/components/Layouts/PageLayout";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { MedicationsOrdersController } from "./RoutesControllers/MedicationsOrdersController";
import { PrescriptionOrderController } from "./RoutesControllers/PrescriptionOrderController";
export const OrderMedicationRoutes: FC = () => {
  return (
    <>
      <Routes>
        <Route element={<MainPageWithAppBarLayout containerBreakpoint='sm' />}>
          <Route path={orderMedicationsRoutes.root.route} Component={orderMedicationsRoutes.root.component} />
        </Route>
        <Route element={<MainPageLayout containerBreakpoint='sm' />}>
          <Route element={<MedicationsOrdersController />}>
            <Route path={orderMedicationsRoutes.medicationsOrders.route} Component={orderMedicationsRoutes.medicationsOrders.component} />
          </Route>
        </Route>
        <Route element={<PageWithAppBarAndBackHeaderLayout containerBreakpoint='sm' />}>
          <Route path={orderMedicationsRoutes.orderAndDeliver.route} Component={orderMedicationsRoutes.orderAndDeliver.component} />
        </Route>
        <Route element={<PageWithBackHeaderLayout containerBreakpoint='sm' />}>
          <Route path={orderMedicationsRoutes.addressSelection.route} Component={orderMedicationsRoutes.addressSelection.component} />
          <Route
            path={orderMedicationsRoutes.deliveryTimeSelection.route}
            Component={orderMedicationsRoutes.deliveryTimeSelection.component}
          />
          <Route element={<PrescriptionOrderController />}>
            <Route path={orderMedicationsRoutes.prescriptionOrder.route} Component={orderMedicationsRoutes.prescriptionOrder.component} />
          </Route>
          <Route path={orderMedicationsRoutes.paymentSummary.route} Component={orderMedicationsRoutes.paymentSummary.component} />
          <Route path={orderMedicationsRoutes.termsAndConditions.route} Component={orderMedicationsRoutes.termsAndConditions.component} />
        </Route>
      </Routes>
    </>
  );
};
