import { Box, Typography, useTheme } from "@toolkit/ui";
import React from "react";

type CircularProgressWithLabelProps = {
  value: number;
  maxValue?: number;
  size?: number;
  strokeWidth?: number;
  title?: string;
};

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  value,
  maxValue = 100,
  size = 80,
  strokeWidth = 8,
  title,
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const progress = Math.min((value / maxValue) * 100, 100);
  const progressStrokeDashoffset = circumference - (progress / 100) * circumference;
  const theme = useTheme();
  return (
    <Box position='relative' width={size} height={size}>
      <svg width={size} height={size}>
        <circle cx={size / 2} cy={size / 2} r={radius} stroke={theme.palette.grey[300]} strokeWidth={strokeWidth} fill='none' />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={theme.palette.primary.main}
          strokeWidth={strokeWidth}
          fill='none'
          strokeDasharray={circumference}
          strokeDashoffset={progressStrokeDashoffset}
          strokeLinecap='round'
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>

      <Box
        position='absolute'
        top='50%'
        left='50%'
        textAlign='center'
        sx={{
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant='h6' fontWeight='bold'>
          {value}
        </Typography>
      </Box>
      {title && <Typography>{title}</Typography>}
    </Box>
  );
};

export default CircularProgressWithLabel;
