import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const OkIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='31.498' height='31.498' viewBox='0 0 31.498 31.498' {...props}>
      <g id='icons8-ok_2_' data-name='icons8-ok (2)' transform='translate(-3.8 -3.8)'>
        <path
          id='Path_12990'
          data-name='Path 12990'
          d='M19.549,37.788A15.757,15.757,0,0,1,3.8,22.039a16.658,16.658,0,0,1,1.257-6.212,1.169,1.169,0,0,1,2.165.886,14.429,14.429,0,0,0-1.082,5.327,13.267,13.267,0,0,0,1.056,5.209,13.52,13.52,0,0,0,7.145,7.145,13.267,13.267,0,0,0,5.209,1.056,13.589,13.589,0,0,0,8.186-2.807,1.169,1.169,0,1,1,1.4,1.871A15.91,15.91,0,0,1,19.549,37.788Z'
          transform='translate(0 -2.49)'
          fill={fill}
        />
        <path
          id='Path_12991'
          data-name='Path 12991'
          d='M34.691,25.865A1.17,1.17,0,0,1,33.6,24.278a13.411,13.411,0,0,0-.2-9.939,13.52,13.52,0,0,0-7.145-7.145,13.267,13.267,0,0,0-5.209-1.056,13.616,13.616,0,0,0-8.54,3.091,1.169,1.169,0,1,1-1.475-1.815A15.962,15.962,0,0,1,21.047,3.8,15.757,15.757,0,0,1,36.8,19.549a15.563,15.563,0,0,1-1.012,5.562A1.17,1.17,0,0,1,34.691,25.865Z'
          transform='translate(-1.498)'
          fill={fill}
        />
        <path
          id='Path_12992'
          data-name='Path 12992'
          d='M20.068,27.025a1.166,1.166,0,0,1-.827-.343l-3.9-3.9A1.169,1.169,0,0,1,17,21.131L20.068,24.2l7.359-7.359A1.169,1.169,0,0,1,29.081,18.5l-8.186,8.186A1.166,1.166,0,0,1,20.068,27.025Z'
          transform='translate(-2.468 -2.798)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
