/* eslint-disable max-statements */

import { isLTR } from "@toolkit/i18n";
import { Box, useTheme } from "@toolkit/ui";
import { FC } from "react";

export type CircularProgressBarProps = {
  size?: number;
  strokeWidth?: number;
  progress?: number;
  progressColor?: string;
  // eslint-disable-next-line react/boolean-prop-naming
  showValue?: boolean;
  maxValue?: number;
  textSize?: number;
};

export const CircularProgressBar: FC<CircularProgressBarProps> = props => {
  const { maxValue = 100, size = 100, strokeWidth = 10, progress = 0, textSize = 10, showValue = false } = props;
  const theme = useTheme();
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const arcLength = circumference;
  const strokeDashoffset = progress > maxValue ? 0 : arcLength - (progress / maxValue) * arcLength;
  const svgFlipTransform = !isLTR() ? `scale(-1, 1) translate(-${size}, 0)` : undefined;
  const progressColor = props.progressColor || theme.palette.primary.main;
  const angle = progress > maxValue ? 270 : (progress / maxValue) * 270;
  const radians = ((angle + 90) * Math.PI) / 180;
  const textX = size / 2 + radius * Math.cos(radians);
  const textY = size / 2 + radius * Math.sin(radians);

  let value;

  if (progress > maxValue) {
    value = maxValue;
  } else if (Number.isInteger(progress)) {
    value = progress;
  } else {
    value = progress.toFixed();
  }

  return (
    <Box sx={{ alignItems: "center", justifyContent: "center" }}>
      <svg height={size} width={size} viewBox={`0 0 ${size} ${size}`} transform={svgFlipTransform}>
        <circle
          fill='none'
          r={radius}
          cx={size / 2}
          cy={size / 2}
          origin={`${size / 2}, ${size / 2}`}
          stroke={theme.palette.grey[100]}
          strokeDashoffset={0}
          strokeLinecap='round'
          strokeWidth={strokeWidth}
          strokeDasharray={`${arcLength}, ${circumference}`}
        />

        <circle
          r={radius}
          fill='none'
          cx={size / 2}
          cy={size / 2}
          origin={`${size / 2}, ${size / 2}`}
          stroke={progressColor}
          strokeWidth={strokeWidth}
          strokeLinecap='round'
          strokeDasharray={`${arcLength}, ${circumference}`}
          strokeDashoffset={strokeDashoffset}
        />

        {showValue && !!progress && (
          <text
            dx='2.5em'
            x={textX}
            y={textY}
            textAnchor='middle'
            fontSize={textSize}
            transform={`rotate(${angle + 200}, ${textX}, ${textY})`}
          >
            {`${value}%`}
          </text>
        )}
      </svg>
    </Box>
  );
};
