import { makeStyles } from "@toolkit/ui";

export const useDigitalTwinWeightLossCalculatorFormStyle = makeStyles()(theme => {
  return {
    root: {
      padding: 16,
      display: "flex",
      flexDirection: "column",
      rowGap: 16,
      textAlign: "start",
    },
    headerCard: {
      padding: 16,
      display: "flex",
      gap: 16,
      justifyContent: "space-around",
      alignItems: "center",
    },
    headerCardInner: {
      display: "flex",
      flexDirection: "column",
      rowGap: 8,
    },
    card: {
      padding: 16,
      paddingVertical: 16,
    },
    title: {
      fontSize: theme.mixins.fonts.fontSize.lg,
      alignSelf: "flex-start",
    },
    subTitle: {
      fontSize: theme.mixins.fonts.fontSize.md,
      color: theme.palette.text.secondary,
      alignSelf: "flex-start",
    },
    inputLabel: {
      fontSize: theme.mixins.fonts.fontSize.lg,
      paddingHorizontal: 0,
      marginBottom: 8,
      alignSelf: "flex-start",
    },
    radioGroup: { gap: 4, mx: 2 },
  };
});
