import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const OnSiteIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon width={"14.984"} height={"16.182"} viewBox='0 0 14.984 16.182' {...props}>
      <path
        id='icons8-permanent-job'
        d='M7.4,3a.6.6,0,1,0,0,1.2H14.59a.6.6,0,1,0,0-1.2ZM5.6,5.4a.6.6,0,1,0,0,1.2V17.384H9.2v-3h3.6v2.229a4.957,4.957,0,0,1,1.279-.638l.122-.046a3.222,3.222,0,0,1-.376-.617,1.978,1.978,0,0,1-.516-1.372,1.913,1.913,0,0,1,.021-.288,2.787,2.787,0,0,1-.179-.967,3.019,3.019,0,0,1,.846-2.139V9.593h1.2v.2a3.656,3.656,0,0,1,1.2-.2v-3a.6.6,0,1,0,0-1.2ZM6.8,7.2H8v1.2H6.8Zm2.4,0h1.2v1.2H9.2Zm2.4,0h1.2v1.2h-1.2Zm2.4,0h1.2v1.2h-1.2ZM6.8,9.593H8v1.2H6.8Zm2.4,0h1.2v1.2H9.2Zm2.4,0h1.2v1.2h-1.2Zm4.795,1.2a1.874,1.874,0,0,0-2.045,1.9,1.733,1.733,0,0,0,.288.915s-.131.064-.131.339a.655.655,0,0,0,.344.663c.048.423.7,1.043.7,1.043v.706c-.353,1.06-2.757.453-2.757,2.827h7.192c0-2.374-2.4-1.767-2.757-2.827v-.706s.657-.62.7-1.043a.861.861,0,0,0,.344-.663c0-.295-.131-.339-.131-.339a2.205,2.205,0,0,0,.235-.876c0-.858-.43-1.518-1.153-1.518A1.115,1.115,0,0,0,16.388,10.79ZM6.8,11.99H8v1.2H6.8Zm2.4,0h1.2v1.2H9.2Zm2.4,0h1.2v1.2h-1.2ZM6.8,14.388H8v1.2H6.8Z'
        transform='translate(-5 -3)'
        fill={fill}
      />
    </SvgIcon>
  );
};
