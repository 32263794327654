import { SvgIcon } from "@toolkit/ui";
import { FC } from "react";

export const EmptyDependentsIcon: FC = () => {
  return (
    <SvgIcon
      id='Group_15502'
      data-name='Group 15502'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='127.662'
      height='129.853'
      viewBox='0 0 127.662 129.853'
    >
      <defs>
        <linearGradient id='linear-gradient' y1='-1.237' x2='0.999' y2='-1.237' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#cacfdd' />
          <stop offset='1' stopColor='#eef0f1' />
        </linearGradient>
      </defs>
      <g id='No_dependents_yet' data-name='No dependents yet' transform='translate(0 0)'>
        <g id='Group_11190' data-name='Group 11190' transform='translate(0 0)'>
          <path
            id='Path_12746_00000008846741855670481910000015707548724329931168_'
            d='M112.718,183.936c-13.914-3.147-26.476-8.117-38.044-16.951-11.457-8.807-21.976-21.4-22.39-34.289-.276-12.865,9.469-25.979,20.1-36.277A106.305,106.305,0,0,1,107.942,72.9c13.693-5.66,30.065-9.359,41.246-3.423,11.126,5.991,17.062,21.617,20.4,36.083,3.4,14.439,4.169,27.718,1.684,41.522s-8.255,28.022-18.8,34.51c-10.546,6.571-25.813,5.356-39.755,2.347'
            transform='translate(-52.279 -66.452)'
            fill='#ebecf7'
          />
        </g>
        <g id='Group_11195' data-name='Group 11195' transform='translate(8.481 6.501)'>
          <g id='Group_11191' data-name='Group 11191' transform='translate(32.025 107.311)'>
            <path
              id='Path_13070'
              data-name='Path 13070'
              d='M236.685,507.918h-1.518V506.4h1.518Zm-4.031,0h-1.518V506.4h1.518Zm-4,0h-1.518V506.4h1.518Zm-4.031,0H223.1V506.4h1.518Zm-4,0H219.1V506.4h1.518Zm-4.031,0h-1.518V506.4h1.518Zm-4.031,0h-1.518V506.4h1.518Zm-4,0h-1.518V506.4h1.518Zm-4.031,0H203V506.4h1.518Zm-4,0H199V506.4h1.518Z'
              transform='translate(-199 -498.753)'
              fill='#a9b4d3'
            />
            <path
              id='Path_13071'
              data-name='Path 13071'
              d='M200.518,484.111H199v-1.518h1.518Zm0-4.031H199V478.7h1.518Z'
              transform='translate(-199 -478.7)'
              fill='#a9b4d3'
            />
          </g>
          <g id='Group_11192' data-name='Group 11192'>
            <g id='Pharmacist'>
              <path
                id='Path_13072'
                data-name='Path 13072'
                d='M182.9,314.084v26.448H86V314.084a19.98,19.98,0,0,1,9.276-16.841c1.988-1.27,1.38-.883,25.592-11.043h27.194l22.169,9.3A20.057,20.057,0,0,1,182.9,314.084Z'
                transform='translate(-85.172 -232.034)'
                fill='#d3d9ed'
              />
              <path
                id='Path_13073'
                data-name='Path 13073'
                d='M314.643,287.857c-3.727-1.574-3.976-1.656-3.948-1.656Z'
                transform='translate(-247.832 -232.034)'
                fill='url(#linear-gradient)'
              />
              <path
                id='Path_13074'
                data-name='Path 13074'
                d='M212.2,286.168a11.841,11.841,0,0,0,11.844,11.844h3.479a11.841,11.841,0,0,0,11.844-11.844h0C239.394,286.14,240.029,286.168,212.2,286.168Z'
                transform='translate(-176.531 -232.001)'
                fill='#dee1ec'
              />
              <path
                id='Path_13075'
                data-name='Path 13075'
                d='M84.656,338.46H83V312.012a20.93,20.93,0,0,1,13.2-19.353L118.5,283.3h13.555v1.656H118.835l-22,9.249A19.246,19.246,0,0,0,84.656,312.04V338.46Z'
                transform='translate(-83 -229.934)'
                fill='#5e6175'
              />
              <rect
                id='Rectangle_7055'
                data-name='Rectangle 7055'
                width='1.656'
                height='22.114'
                transform='translate(83.017 86.385)'
                fill='#525682'
              />
              <rect
                id='Rectangle_7056'
                data-name='Rectangle 7056'
                width='1.656'
                height='22.114'
                transform='translate(13.942 86.385)'
                fill='#525682'
              />
              <path
                id='Path_13076'
                data-name='Path 13076'
                d='M169.851,286.2v54.36h-4.307l-6.433-18.746H153.7l8.779-32.522Z'
                transform='translate(-134.181 -232.034)'
                fill='#fff'
              />
              <path
                id='Path_13077'
                data-name='Path 13077'
                d='M310.7,286.2v54.36h4.307l6.433-18.746h5.411l-8.779-32.522Z'
                transform='translate(-247.837 -232.034)'
                fill='#fff'
              />
              <path
                id='Path_13078'
                data-name='Path 13078'
                d='M161.92,337.551l-6.212-18.166H149.8l9.166-33.958,8.89-3.727v55.6H166.2V284.185l-5.853,2.457-8.393,31.086H156.9l6.6,19.3Z'
                transform='translate(-131.358 -228.776)'
                fill='#525682'
              />
              <path
                id='Path_13079'
                data-name='Path 13079'
                d='M313.636,337.551l-1.574-.525,6.6-19.3H323.6l-8.393-31.086-5.853-2.457V337.3H307.7V281.7l8.89,3.727,9.166,33.958h-5.908Z'
                transform='translate(-245.665 -228.776)'
                fill='#525682'
              />
              <path
                id='Path_13080'
                data-name='Path 13080'
                d='M212.128,286.2a11.841,11.841,0,0,0,11.844,11.844h3.479A11.841,11.841,0,0,0,239.294,286.2h0v54.332H212.1V286.2Z'
                transform='translate(-176.458 -232.034)'
                fill='#2b3078'
              />
              <path
                id='Path_13081'
                data-name='Path 13081'
                d='M238.123,340.56h-1.656V292.467a12.655,12.655,0,0,1-11.015,6.4h-3.479a12.679,12.679,0,0,1-11.016-6.4v48.065H209.3V286.2h1.656a11.028,11.028,0,0,0,11.016,11.016h3.479A11.028,11.028,0,0,0,236.466,286.2h1.656Z'
                transform='translate(-174.431 -232.034)'
                fill='#525682'
              />
              <path
                id='Path_13082'
                data-name='Path 13082'
                d='M405.6,319.122l-1.6.387-.387-1.6,1.6-.387Zm-1.021-4.279-1.6.387-.387-1.6,1.6-.387Zm-1.049-4.307-1.6.386c-.11-.5-.221-.939-.414-1.574l1.6-.469c.166.663.3,1.1.414,1.657Zm-2.871-3.7c-.193-.5-.414-.994-.635-1.463l1.491-.718c.248.5.469,1.021.69,1.574Zm-1.9-3.782c-.276-.442-.58-.911-.883-1.325l1.353-.966a16,16,0,0,1,.939,1.436Zm-2.568-3.4c-.359-.386-.745-.773-1.132-1.132l1.132-1.215c.414.387.828.8,1.215,1.215Zm-3.175-2.871a15.955,15.955,0,0,0-1.325-.911l.883-1.408c.469.3.966.635,1.408.966Zm-3.617-2.209a13.939,13.939,0,0,0-1.463-.663l.635-1.546c.525.221,1.049.442,1.574.69Zm-3.976-1.518a14.927,14.927,0,0,0-1.518-.331l.221-1.629a13.111,13.111,0,0,1,1.739.387Z'
                transform='translate(-300.828 -235.581)'
                fill='#a9b4d3'
              />
              <path
                id='Path_13083'
                data-name='Path 13083'
                d='M232.193,287.857a25.019,25.019,0,0,1-35.366.138l-.028-.028,4.252-1.767h27.194Z'
                transform='translate(-165.382 -232.034)'
                fill='#d3d9ed'
              />
              <path
                id='Path_13084'
                data-name='Path 13084'
                d='M181.632,338.533h-1.656V312.085a19.212,19.212,0,0,0-12.147-17.807L145.8,285.029H118.935l-22,9.249a19.246,19.246,0,0,0-12.175,17.835V338.56H83.1V312.112a20.777,20.777,0,0,1,13.224-19.353l22.307-9.359h27.525l22.307,9.359a20.792,20.792,0,0,1,13.2,19.353v26.421Z'
                transform='translate(-83.072 -230.007)'
                fill='#525682'
              />
              <path
                id='Path_13085'
                data-name='Path 13085'
                d='M206.406,167.42A20.713,20.713,0,0,1,185.7,146.714V136.306a20.706,20.706,0,1,1,41.412,0v10.381a20.7,20.7,0,0,1-20.706,20.733Z'
                transform='translate(-157.347 -108.532)'
                fill='#fff'
              />
              <path
                id='Path_13086'
                data-name='Path 13086'
                d='M173.972,190.8h-.883a4.411,4.411,0,0,0-3.589,4.334v2.9a4.434,4.434,0,0,0,1.3,3.12l1.408,1.408a4.218,4.218,0,0,0,1.822,1.077C173.945,202.616,173.972,192.539,173.972,190.8Z'
                transform='translate(-145.619 -162.971)'
                fill='#fff'
              />
              <path
                id='Path_13087'
                data-name='Path 13087'
                d='M172.667,202.625A6.806,6.806,0,0,1,169.354,201l-1.408-1.408a5.416,5.416,0,0,1-1.546-3.7v-2.926a5.233,5.233,0,0,1,4.279-5.135,9.356,9.356,0,0,1,1.877-.028C172.557,202.266,172.5,200.251,172.667,202.625ZM170.9,189.456a3.633,3.633,0,0,0-2.816,3.506v2.9a3.577,3.577,0,0,0,1.049,2.512,20.37,20.37,0,0,0,1.767,1.712Z'
                transform='translate(-143.375 -160.8)'
                fill='#525682'
              />
              <path
                id='Path_13088'
                data-name='Path 13088'
                d='M335.628,190.8h.883a4.411,4.411,0,0,1,3.589,4.334v2.9a4.433,4.433,0,0,1-1.3,3.12l-1.408,1.408a4.289,4.289,0,0,1-1.795,1.077C335.655,202.616,335.628,192.539,335.628,190.8Z'
                transform='translate(-265.863 -162.971)'
                fill='#f5f5f5'
              />
              <path
                id='Path_13089'
                data-name='Path 13089'
                d='M332.3,202.625c.166-2.374.11-.055.11-14.825H334.2a5.241,5.241,0,0,1,4.334,5.163v2.9a5.226,5.226,0,0,1-1.546,3.7C334.757,201.825,334.95,201.825,332.3,202.625Zm1.767-2.512c.248-.193.248-.221,1.767-1.712a3.577,3.577,0,0,0,1.049-2.512v-2.926a3.6,3.6,0,0,0-2.816-3.506Z'
                transform='translate(-263.474 -160.8)'
                fill='#525682'
              />
              <path
                id='Path_13090'
                data-name='Path 13090'
                d='M204.234,166.076A21.562,21.562,0,0,1,182.7,144.542V134.134a21.534,21.534,0,0,1,43.068,0v10.381A21.568,21.568,0,0,1,204.234,166.076Zm0-51.82a19.936,19.936,0,0,0-19.878,19.878v10.408a19.878,19.878,0,1,0,39.755,0V134.162a19.889,19.889,0,0,0-19.878-19.905Z'
                transform='translate(-155.175 -106.361)'
                fill='#525682'
              />
              <path
                id='Path_13091'
                data-name='Path 13091'
                d='M188.639,117.273,193,121.635a6.712,6.712,0,0,0,4.721,1.96h25.123v-11.4A13.691,13.691,0,0,0,209.152,98.5h-17.2a6.48,6.48,0,0,0-6.488,6.488h-1.077a6.48,6.48,0,0,0-6.488,6.488v12.479h4.141a6.669,6.669,0,0,0,6.6-6.681Z'
                transform='translate(-151.7 -96.153)'
                fill='#2b3078'
              />
              <path
                id='Path_13092'
                data-name='Path 13092'
                d='M179.669,122.583H174.7V109.3a7.329,7.329,0,0,1,7.316-7.316h.3a7.322,7.322,0,0,1,7.288-6.488h17.2a14.522,14.522,0,0,1,14.522,14.522v12.23H195.378a7.478,7.478,0,0,1-5.3-2.181L186.93,116.9A7.5,7.5,0,0,1,179.669,122.583Zm-3.313-1.656h3.313a5.826,5.826,0,0,0,5.825-5.825v-1.988l5.77,5.77a5.787,5.787,0,0,0,4.114,1.712h24.295V110.022a12.86,12.86,0,0,0-12.865-12.865h-17.2a5.667,5.667,0,0,0-5.66,5.66v.828h-1.9a5.656,5.656,0,0,0-5.66,5.687Z'
                transform='translate(-149.384 -93.982)'
                fill='#2b3078'
              />
              <path
                id='Path_13093'
                data-name='Path 13093'
                d='M149.175,96.35a3.162,3.162,0,1,1,1.712-.5A3.1,3.1,0,0,1,149.175,96.35Zm0-4.693a1.514,1.514,0,0,0-1.518,1.518,1.5,1.5,0,0,0,1.518,1.518,1.535,1.535,0,0,0,1.491-1.187,1.5,1.5,0,0,0-1.16-1.795c-.11-.028-.221-.028-.331-.055Z'
                transform='translate(-128.607 -90)'
                fill='#525682'
              />
              <path
                id='Path_13094'
                data-name='Path 13094'
                d='M99.557,156.714a2.457,2.457,0,1,1,0-4.914,2.454,2.454,0,0,1,1.3,4.528A2.383,2.383,0,0,1,99.557,156.714Zm0-3.258a.8.8,0,1,0,0,1.6.781.781,0,0,0,.166-1.546.2.2,0,0,0-.166-.055Z'
                transform='translate(-93.207 -134.738)'
                fill='#525682'
              />
            </g>
          </g>
          <g id='Group_11193' data-name='Group 11193' transform='translate(29.982 72.47)'>
            <path
              id='Path_13095'
              data-name='Path 13095'
              d='M408.5,432.849v-8.807a9.438,9.438,0,0,1,9.442-9.442h9.469a9.438,9.438,0,0,1,9.442,9.442v8.807Z'
              transform='translate(-348.619 -397.456)'
              fill='#d3d9ed'
            />
            <path
              id='Path_13096'
              data-name='Path 13096'
              d='M487.45,458.4v-8.5a1.123,1.123,0,0,0-1.1.883l-1.546,7.592Z'
              transform='translate(-403.854 -423.01)'
              fill='#fff'
            />
            <path
              id='Path_13097'
              data-name='Path 13097'
              d='M440.861,372.006a7.27,7.27,0,0,1-7.261-7.261v-1.684a7.261,7.261,0,0,1,14.522,0v1.684A7.27,7.27,0,0,1,440.861,372.006Z'
              transform='translate(-366.789 -354.889)'
              fill='#fff'
            />
            <path
              id='Path_13098'
              data-name='Path 13098'
              d='M438.4,370.583a8.2,8.2,0,0,1-8.2-8.2V360.7a8.2,8.2,0,0,1,16.4,0v1.684A8.2,8.2,0,0,1,438.4,370.583Zm0-16.178A6.292,6.292,0,0,0,432.1,360.7v1.684a6.295,6.295,0,1,0,12.589,0V360.7A6.292,6.292,0,0,0,438.4,354.4Z'
              transform='translate(-364.328 -352.5)'
              fill='#525682'
            />
            <path
              id='Path_13099'
              data-name='Path 13099'
              d='M423.8,458.4v-8.5a1.152,1.152,0,0,1,1.1.883l1.546,7.592H423.8Z'
              transform='translate(-359.695 -423.01)'
              fill='#fff'
            />
            <path
              id='Path_13100'
              data-name='Path 13100'
              d='M435.258,431.181H405v-9.773A10.416,10.416,0,0,1,415.408,411h9.469a10.416,10.416,0,0,1,10.408,10.408v9.773Zm-28.353-1.9h26.448v-7.868a8.507,8.507,0,0,0-8.5-8.5h-9.469a8.525,8.525,0,0,0-8.5,8.5v7.868Z'
              transform='translate(-346.085 -394.849)'
              fill='#525682'
            />
            <path
              id='Path_13101'
              data-name='Path 13101'
              d='M195.1,432.849v-8.807a9.438,9.438,0,0,1,9.442-9.442h9.469a9.438,9.438,0,0,1,9.442,9.442v8.807Z'
              transform='translate(-194.134 -397.456)'
              fill='#d3d9ed'
            />
            <path
              id='Path_13102'
              data-name='Path 13102'
              d='M274.05,458.4v-8.5a1.123,1.123,0,0,0-1.1.883l-1.546,7.592Z'
              transform='translate(-249.369 -423.01)'
              fill='#fff'
            />
            <path
              id='Path_13103'
              data-name='Path 13103'
              d='M218.95,458.476l-1.546-7.592a1.1,1.1,0,0,0-1.1-.883v8.5Z'
              transform='translate(-209.481 -423.082)'
              fill='#fff'
            />
            <path
              id='Path_13104'
              data-name='Path 13104'
              d='M227.461,372.006a7.27,7.27,0,0,1-7.261-7.261v-1.684a7.261,7.261,0,0,1,14.522,0v1.684A7.27,7.27,0,0,1,227.461,372.006Z'
              transform='translate(-212.304 -354.889)'
              fill='#fff'
            />
            <path
              id='Path_13105'
              data-name='Path 13105'
              d='M225,370.583a8.2,8.2,0,0,1-8.2-8.2V360.7a8.2,8.2,0,0,1,16.4,0v1.684A8.2,8.2,0,0,1,225,370.583Zm0-16.178A6.292,6.292,0,0,0,218.7,360.7v1.684a6.295,6.295,0,1,0,12.589,0V360.7A6.292,6.292,0,0,0,225,354.4Z'
              transform='translate(-209.843 -352.5)'
              fill='#525682'
            />
            <path
              id='Path_13106'
              data-name='Path 13106'
              d='M221.858,431.181H191.6v-9.773A10.416,10.416,0,0,1,202.008,411h9.469a10.416,10.416,0,0,1,10.408,10.408v9.773Zm-28.353-1.9h26.448v-7.868a8.507,8.507,0,0,0-8.5-8.5h-9.469a8.525,8.525,0,0,0-8.5,8.5v7.868Z'
              transform='translate(-191.6 -394.849)'
              fill='#525682'
            />
            <path
              id='Path_13107'
              data-name='Path 13107'
              d='M304.7,432.849v-8.807a9.438,9.438,0,0,1,9.442-9.442h9.442a9.438,9.438,0,0,1,9.442,9.442h0v8.807Z'
              transform='translate(-273.476 -397.456)'
              fill='#d3d9ed'
            />
            <path
              id='Path_13108'
              data-name='Path 13108'
              d='M336.961,372.006a7.27,7.27,0,0,1-7.261-7.261v-1.684a7.261,7.261,0,0,1,14.522,0v1.684A7.252,7.252,0,0,1,336.961,372.006Z'
              transform='translate(-291.574 -354.889)'
              fill='#fff'
            />
            <path
              id='Path_13109'
              data-name='Path 13109'
              d='M334.5,370.583a8.2,8.2,0,0,1-8.2-8.2V360.7a8.2,8.2,0,0,1,16.4,0h0v1.684A8.2,8.2,0,0,1,334.5,370.583Zm0-16.178A6.292,6.292,0,0,0,328.2,360.7v1.684a6.295,6.295,0,1,0,12.589,0h0V360.7A6.292,6.292,0,0,0,334.5,354.4Z'
              transform='translate(-289.112 -352.5)'
              fill='#525682'
            />
            <path
              id='Path_13110'
              data-name='Path 13110'
              d='M320,458.4v-8.5a1.123,1.123,0,0,1,1.1.883l1.546,7.592Z'
              transform='translate(-284.552 -423.01)'
              fill='#fff'
            />
            <path
              id='Path_13111'
              data-name='Path 13111'
              d='M384.85,458.4v-8.5a1.123,1.123,0,0,0-1.1.883l-1.546,7.592Z'
              transform='translate(-329.58 -423.01)'
              fill='#fff'
            />
            <path
              id='Path_13112'
              data-name='Path 13112'
              d='M331.486,431.181H301.2v-9.773A10.416,10.416,0,0,1,311.608,411h9.442a10.416,10.416,0,0,1,10.408,10.408Zm-28.353-1.9h26.448v-7.868a8.507,8.507,0,0,0-8.5-8.5h-9.469a8.507,8.507,0,0,0-8.5,8.5Z'
              transform='translate(-270.942 -394.849)'
              fill='#525682'
            />
          </g>
          <path
            id='Path_13113'
            data-name='Path 13113'
            d='M354.5,395.995v18.663a4.507,4.507,0,0,1-4.528,4.5H325.32A21.076,21.076,0,0,1,346.025,394.2,20.856,20.856,0,0,1,354.5,395.995Z'
            transform='translate(-258.15 -310.217)'
            fill='#d3d9ed'
          />
          <circle
            id='Ellipse_2917'
            data-name='Ellipse 2917'
            cx='17.255'
            cy='17.255'
            r='17.255'
            transform='translate(70.648 87.82)'
            fill='#fff'
          />
          <path
            id='Path_13114'
            data-name='Path 13114'
            d='M354.128,442.256a18.028,18.028,0,1,1,18.028-18.028h0A18.051,18.051,0,0,1,354.128,442.256Zm0-34.482a16.509,16.509,0,1,0,16.509,16.509,16.519,16.519,0,0,0-16.509-16.509Z'
            transform='translate(-266.225 -318.904)'
            fill='#535783'
          />
          <g id='Group_11194' data-name='Group 11194' transform='translate(79.162 96.605)'>
            <rect
              id='Rectangle_7057'
              data-name='Rectangle 7057'
              width='18.414'
              height='6.239'
              transform='translate(0.015 13.021) rotate(-45)'
              fill='#283275'
            />
            <rect
              id='Rectangle_7058'
              data-name='Rectangle 7058'
              width='6.239'
              height='18.414'
              transform='translate(0 4.437) rotate(-45)'
              fill='#283275'
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
