import { makeStyles } from "@toolkit/ui";

export const useCounterStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary[50],
    padding: "11px",
    height: "35px",
    borderRadius: "17.5px",
    width: "max-content",
  },
  button: {
    padding: "0px",
    margin: "0px",
    borderRadius: "50%",
    width: "16px",
    height: "16px",
    "& svg": {
      width: "16px",
      height: "16px",
    },
    cursor: "pointer",
  },
  disabledButton: {},
  startButtonContainer: {
    width: "max-content",
  },
  endButtonContainer: {
    width: "max-content",
  },
  value: {
    paddingInline: "8px",
    fontSize: theme.mixins.fonts.fontSize.lg,
  },
  incrementButton: {},
  decrementButton: {},
  deleteButton: {
    "& svg": {
      transform: "rotate(45deg)",
      fill: theme.palette.secondary.main,
    },
    "& path": {
      fill: theme.palette.secondary.main,
    },
  },
}));
