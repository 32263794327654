import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    overflowX: "auto",
    scrollbarWidth: "none",
    marginBlock: "10px",
  },
  button: {
    borderRadius: "20px",
    height: "40px",
    padding: "8px 16px",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    marginInline: "3px",
    width: "min-content",
    "&.MuiButton-outlinedPrimary:hover": {
      backgroundColor: theme.palette.primary[50],
      color: theme.palette.text.primary,
    },
  },
}));
