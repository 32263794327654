import { useLocation } from "react-router-dom";
import { DigitalTwinWeightLossCalculatorForm } from "../../components/DigitalTwinWeightLossCalculatorForm/DigitalTwinWeightLossCalculatorForm";
import { useDigitalTwinWeighLossCalculatorFormStyle } from "./useDigitalTwinWeighLossCalculatorFormStyle";
import { Box } from "@toolkit/ui";
import queryString from "query-string";
import { ActivityLevel } from "@/schema/types";

export const DigitalTwinWeighLossCalculatorFormContainer = () => {
  const { classes } = useDigitalTwinWeighLossCalculatorFormStyle();

  const location = useLocation();
  const result = queryString.parse(location.search) as Record<string, string>;
  const inputData = {
    height: result?.height ? Number(result.height) : 0,
    weight: result?.weight ? Number(result.weight) : 0,
    activityLevel: result?.activityLevel as ActivityLevel,
  };

  return (
    <>
      <Box className={classes.root}>
        <DigitalTwinWeightLossCalculatorForm bee={Number(result.bee)} tdee={Number(result.tdee)} inputData={inputData!} />
      </Box>
    </>
  );
};
