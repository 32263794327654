import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerPrescriptionDispenseMutationVariables = Types.Exact<{
  input: Types.CustomerPrescriptionDispenseInput;
}>;


export type CustomerPrescriptionDispenseMutation = { __typename?: 'Mutation', customerPrescriptionDispense?: { __typename?: 'Order', id: string, type: Types.OrderType } | null };


export const CustomerPrescriptionDispenseDocument = gql`
    mutation CustomerPrescriptionDispense($input: CustomerPrescriptionDispenseInput!) {
  customerPrescriptionDispense(input: $input) {
    id
    type
  }
}
    `;
export type CustomerPrescriptionDispenseMutationFn = Apollo.MutationFunction<CustomerPrescriptionDispenseMutation, CustomerPrescriptionDispenseMutationVariables>;

/**
 * __useCustomerPrescriptionDispenseMutation__
 *
 * To run a mutation, you first call `useCustomerPrescriptionDispenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerPrescriptionDispenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerPrescriptionDispenseMutation, { data, loading, error }] = useCustomerPrescriptionDispenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerPrescriptionDispenseMutation(baseOptions?: Apollo.MutationHookOptions<CustomerPrescriptionDispenseMutation, CustomerPrescriptionDispenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerPrescriptionDispenseMutation, CustomerPrescriptionDispenseMutationVariables>(CustomerPrescriptionDispenseDocument, options);
      }
export type CustomerPrescriptionDispenseMutationHookResult = ReturnType<typeof useCustomerPrescriptionDispenseMutation>;
export type CustomerPrescriptionDispenseMutationResult = Apollo.MutationResult<CustomerPrescriptionDispenseMutation>;
export type CustomerPrescriptionDispenseMutationOptions = Apollo.BaseMutationOptions<CustomerPrescriptionDispenseMutation, CustomerPrescriptionDispenseMutationVariables>;