import { IconButton, MuiAttachFileIcon } from "@toolkit/ui";
import { FC, useCallback, useRef } from "react";

type ChatInputAttachFileProps = {
  onSubmit: (value: { file: File; type: string }) => void;
};

export const ChatInputAttachFile: FC<ChatInputAttachFileProps> = ({ onSubmit }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onClick = useCallback(() => inputRef.current?.click(), []);
  const handleUploadFile = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target?.files?.[0];
      event.target.value = "";
      if (file) {
        onSubmit({ file, type: file.type });
      }
    },
    [onSubmit]
  );

  return (
    <IconButton onClick={onClick}>
      <MuiAttachFileIcon />
      <input ref={inputRef} type='file' accept='*' hidden onChange={handleUploadFile} />
    </IconButton>
  );
};
