import { useTranslation } from "@toolkit/i18n";
import { Box, Button, FormAutocomplete, FormDatePickerField, FormTextField, ImageUploadController, Typography } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useCreateDependentHook } from "./useCreateDependentHook";
import { useStyles } from "./CreateDependentContainerStyles";
import { userGenderOptions } from "@health/enum-options";
import moment from "moment";

export const CreateDependentContainer: FC = () => {
  const { t } = useTranslation();
  const { form, handleSubmit, loading, onDependentCreate, control, errors, accessToken } = useCreateDependentHook();
  const { classes } = useStyles();
  return (
    <FormProvider {...form}>
      <Box display='flex' flexDirection={"column"} alignItems='start' justifyContent='center' width='100%' gap={2} padding={"10px"}>
        <Box className={classes.imageUpload}>
          <ImageUploadController
            name={"insuranceCardLogo"}
            control={control}
            validationRation={true}
            error={!!errors?.photo?.message}
            helperText={errors?.photo?.message}
            token={accessToken!}
          />
        </Box>
        <Typography className={classes.title}>{t("Personal Information")}</Typography>
        <Box display='flex' flexDirection={"row"} alignItems='center' justifyContent='center' width={"100%"} gap={1}>
          <FormTextField name='firstName' label={t("First Name")} />
          <FormTextField name='lastName' label={t("Last Name")} />
        </Box>

        <FormDatePickerField
          name={"dateOfBirth"}
          label={t("Date of Birth")}
          placeholder={t("Date of Birth")}
          datePickerProps={{ maxDate: moment() }}
        />
        <FormTextField name={"relationType"} label={t("Relation Type")} placeholder={t("Description")} />
        <FormAutocomplete options={userGenderOptions} name={"gender"} label={t("Gender")} />
      </Box>
      <Box className={classes.footer}>
        <Button disabled={loading} onClick={handleSubmit(onDependentCreate)} className={classes.button}>
          {t("Add New")}
        </Button>
      </Box>
    </FormProvider>
  );
};
