import { DigitalTwinWeightLossPlanCalculator } from "./containers/DigitalTwinWeightLossPlanCalculator/DigitalTwinWeightLossPlanCalculatorContainer";
import { DigitalTwinWeighLossCalculatorFormContainer } from "./containers/DigitalTwinWeighLossCalculatorForm/DigitalTwinWeighLossCalculatorFormContainer";
import { ActivityLevel } from "@/schema/types";

export const WeightLossPlanCalculationBaseRoute = "/weight-loss-plan-calculator";
export interface WeightLossPlanCalculationBaseRouteParams extends Record<string, any> {
  bee: number;
  tdee: number;
  newTdee?: number;
  goalWeight?: number;
  newCalorieIntake?: number;
  caloriesPerDayToAdjust?: number;
  weightLossDays?: number;
}
export interface WeightLossPlanCalculationFormRouteParams extends Record<string, any> {
  bee: number;
  tdee: number;
  height: number;
  weight: number;
  activityLevel: ActivityLevel;
}
export const weightLossPlanCalculationRoutes = {
  root: {
    route: "/",
    component: DigitalTwinWeightLossPlanCalculator,
    get absRoute() {
      return WeightLossPlanCalculationBaseRoute;
    },
    getRouteWithParams(result: WeightLossPlanCalculationBaseRouteParams) {
      return `${this.absRoute}?` + new URLSearchParams(result as any).toString();
    },
  },
  form: {
    route: "/form",
    component: DigitalTwinWeighLossCalculatorFormContainer,
    get absRoute() {
      return `${WeightLossPlanCalculationBaseRoute}${this.route}`;
    },
    getRouteWithParams(result: WeightLossPlanCalculationFormRouteParams) {
      return `${this.absRoute}?` + new URLSearchParams(result as any).toString();
    },
  },
};
