import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const FireIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.common.white;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='12.86' height='16.717' viewBox='0 0 12.86 16.717' {...props}>
      <path
        id='icons8-fire'
        d='M10.144,2a.643.643,0,0,0-.643.643C9.5,5.895,5,8.43,5,12.931c0,3.419,3.706,5.63,5.011,5.773a.643.643,0,0,0,.539-1.128h0a4.834,4.834,0,0,1-1.692-3.108,3.161,3.161,0,0,1,1.929-2.822c-1.1,3.112,2.5,3.488,3.23,6.569h0a.641.641,0,0,0,1,.381l.034-.026a6.842,6.842,0,0,0,2.8-5.637,16.355,16.355,0,0,0-1.329-5.374V7.551A.642.642,0,0,0,15.3,7.67s0,0,0,0A3.835,3.835,0,0,1,14,9.716c0-3.029-2.187-6.159-3.341-7.453A.647.647,0,0,0,10.144,2Z'
        transform='translate(-5 -2)'
        fill={fill}
      />
    </SvgIcon>
  );
};
