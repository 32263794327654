import { RefObject, useEffect, useRef } from "react";

import { CallParticipant, ExecutedFailure, VideoQuality } from "./types";
import { useCanvasDimension } from "./useCanvasDimension";
import { useZoom } from "./useZoom";
import { makeStyles } from "@toolkit/ui";
import { useUser } from "./useUser";

export type ZoomViewVideoProps = {
  participant: CallParticipant;
};

const useStyles = makeStyles()({
  root: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
});

export const ZoomViewVideo = ({ participant }: ZoomViewVideoProps) => {
  const { classes } = useStyles();
  const { mediaStream, mediaState } = useZoom();
  const videoRef = useRef<HTMLCanvasElement | HTMLVideoElement>(null);
  const { width, height } = useCanvasDimension(mediaStream, videoRef);
  const { isCurrentUser } = participant;
  const { userId, bVideoOn } = useUser(participant);
  const isVideoDecodeReady = mediaState?.video.decode;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (!isVideoDecodeReady || !userId || !mediaStream || !videoRef.current || !bVideoOn || !width || !height) {
      return;
    }

    let isRendered = false;
    let timeoutId: ReturnType<typeof setTimeout> | null = null;
    const renderVideo = async (canvasElement: HTMLCanvasElement | HTMLVideoElement) => {
      try {
        await mediaStream.renderVideo(canvasElement, userId, width, height, 0, 0, VideoQuality.Video_720P);
        isRendered = true;
        timeoutId = setTimeout(async () => {
          if (!isRendered) {
            return;
          }

          try {
            await mediaStream.adjustRenderedVideoPosition(canvasElement, userId, width, height, 0, 0);
          } catch (error) {
            console.error("Meeting: Unable to adjust rendered video position due to error", error);
          }
        }, 0);
      } catch (error) {
        console.error("Meeting: Unable to render video due to error", error);
      }
    };

    const stopRenderVideo = async (videoElement: HTMLCanvasElement | HTMLVideoElement) => {
      try {
        if (!isRendered) {
          return;
        }

        await mediaStream.stopRenderVideo(videoElement, userId);
      } catch (error: unknown) {
        if ((error as ExecutedFailure).type === "IMPROPER_MEETING_STATE") {
          return;
        }

        console.error("Meeting: Unable to stop render video due to error", error);
      }
    };

    const videoElement = videoRef.current;
    renderVideo(videoElement);

    return () => {
      timeoutId && clearTimeout(timeoutId);
      stopRenderVideo(videoElement);
    };
  }, [mediaStream, bVideoOn, userId, width, height, isVideoDecodeReady]);

  if (isCurrentUser && mediaStream?.isRenderSelfViewWithVideoElement()) {
    // eslint-disable-next-line jsx-a11y/media-has-caption
    return <video className={classes.root} ref={videoRef as RefObject<HTMLVideoElement>} />;
  }

  return <canvas className={classes.root} ref={videoRef as RefObject<HTMLCanvasElement>} />;
};
