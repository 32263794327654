import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles<{ increaseFontSizeBy?: number; color?: string }>()((theme, { increaseFontSizeBy, color }) => ({
  priceContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  price: {
    fontSize: theme.mixins.fonts.fontSize.sm + (increaseFontSizeBy ?? 0),
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: color || theme.palette.secondary.main,
  },
  currency: {
    position: "relative",
    top: "-3px",
    paddingInlineEnd: "3px",
    display: "inline",
    fontSize: theme.mixins.fonts.fontSize.xxs + (increaseFontSizeBy ?? 0),
    verticalAlign: "upper",
    color: color || theme.palette.secondary.main,
  },
  discount: {
    color: color || theme.palette.stale.main,
    fontWeight: theme.mixins.fonts.fontWeight.normal,
    textDecoration: "line-through",
  },
}));
