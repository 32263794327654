/* eslint-disable sonarjs/cognitive-complexity */
import { FC } from "react";
import { MedicationsOrderTrackingCardProps } from "../MedicationsOrderTrackingCard/MedicationsOrderTrackingCard";
import { Box, ValueWithLabel, Grid, Typography, TruncateTypography } from "@toolkit/ui";
import { useMedicationsOrderTrackingCardStyles } from "../MedicationsOrderTrackingCard/useMedicationsOrderTrackingCard.styles";
import { useCommonLabelValueStyles } from "../../styles/useCommonLabelValue.styles";
import { getOrderStatusDetailsMapper } from "../../others/utils";
import { useTranslation } from "@toolkit/i18n";
import { Dot } from "@/shared/components";
import moment from "moment";
import { deliveryMethodTypeMap } from "@health/enum-options";
import { OrderType } from "@/schema/types";
import { SeeLocationOnTheMap } from "@/domains/address/components/SeeLocationOnTheMap/SeeLocationOnTheMap";
import { formatCurrency } from "@/shared/utils";
import { useSiteSettingsGetQuery } from "@/gql";
import { MedicationsOrderTrackingCardContentButtons } from "../MedicationsOrderTrackingCardContentButtons/MedicationsOrderTrackingCardContentButtons";

export const MedicationsOrderTrackingCardContent: FC<MedicationsOrderTrackingCardProps> = ({ order }) => {
  const { classes, theme } = useMedicationsOrderTrackingCardStyles();
  const { classes: labelValueClasses } = useCommonLabelValueStyles();
  const { t } = useTranslation("consumer");
  const selectedPharmacy = order?.children?.[0]?.branch;
  const vat = (order?.totalPaymentAmount || 0) - ((order?.totalPatientShareAmount || 0) + (order?.deliveryPrice || 0));
  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const currency = data?.siteSettings?.defaultCurrency || "";

  const commonProps = {
    labelProps: { className: labelValueClasses.label },
    valueProps: { className: labelValueClasses.value },
    className: classes.labelValueContainer,
  };

  const statusDetails = order?.status ? getOrderStatusDetailsMapper()[order.status] : null;
  const isPickup = order?.type === OrderType.Pickup;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ValueWithLabel label={t("Order ID")} value={order?.id || "-"} {...commonProps} />
        </Grid>
        <Grid item xs={6}>
          <ValueWithLabel
            label='Status'
            value={
              statusDetails ? (
                <Box display='flex' alignItems='center' gap={1}>
                  <Dot color={statusDetails.color} />
                  <Typography
                    fontWeight={theme.mixins.fonts.fontWeight.medium}
                    color={statusDetails.color ? theme.palette?.[statusDetails.color].main : undefined}
                  >
                    {statusDetails.label}
                  </Typography>
                </Box>
              ) : (
                "-"
              )
            }
            {...commonProps}
          />
        </Grid>
        <Grid item xs={6}>
          <ValueWithLabel label={t("eRx Reference Number")} value={order?.prescription?.referenceNumber || "-"} {...commonProps} />
        </Grid>
        {order?.deliveryDate && (
          <Grid item xs={6}>
            <ValueWithLabel
              label={t("Delivery Time")}
              value={order?.deliveryDate ? moment(order?.deliveryDate).format("DD MMM YYYY") : "-"}
              {...commonProps}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <ValueWithLabel
            label={t("Delivery Way")}
            value={order?.type ? deliveryMethodTypeMap[order?.type]?.label : "-"}
            {...commonProps}
          />
        </Grid>
        <Grid item xs={6}>
          <ValueWithLabel
            label={isPickup ? t("Pharmacy Address") : t("Selected Address")}
            value={
              isPickup ? (
                <Box>
                  <TruncateTypography text={selectedPharmacy?.address?.streetAddress1 || "-"} maxLines={3} maxWidth={"100%"} />
                  {selectedPharmacy?.address?.coordinates ? (
                    <SeeLocationOnTheMap coordinates={selectedPharmacy.address.coordinates} />
                  ) : (
                    "-"
                  )}
                </Box>
              ) : (
                order?.address?.name || "-"
              )
            }
            {...commonProps}
          />
        </Grid>
        <Grid item xs={6}>
          <ValueWithLabel label={t("Sharing")} value={formatCurrency(order?.totalPatientShareAmount || 0, currency)} {...commonProps} />
        </Grid>
        {!!order?.deliveryPrice && (
          <Grid item xs={6}>
            <ValueWithLabel label={t("Delivery Cost")} value={formatCurrency(order?.deliveryPrice || 0, currency)} {...commonProps} />
          </Grid>
        )}
        <Grid item xs={6}>
          <ValueWithLabel label={t("VAT")} value={formatCurrency(vat, currency)} {...commonProps} />
        </Grid>
        {!!order?.pickUpOtp && (
          <Grid item xs={6}>
            <ValueWithLabel label={t("OTP")} value={order?.pickUpOtp} {...commonProps} />
          </Grid>
        )}
      </Grid>
      <MedicationsOrderTrackingCardContentButtons order={order} />
    </>
  );
};
