import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrescriptionGetQueryVariables = Types.Exact<{
  referenceNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  prescriptionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type PrescriptionGetQuery = { __typename?: 'Query', prescription?: { __typename?: 'Prescription', id: string, referenceNumber: string, dispenseStatus: Types.PrescriptionDispenseStatus, modified?: any | null, patient: { __typename?: 'Patient', id: string, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, mobile?: string | null, nationalId?: string | null } | null }, visit?: { __typename?: 'Visit', id: string, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null } | null, medications: Array<{ __typename?: 'Medication', id: number, code: string, display?: string | null, instructions?: string | null, duration?: string | null, quantity?: number | null }> } | null };


export const PrescriptionGetDocument = gql`
    query PrescriptionGet($referenceNumber: String, $prescriptionId: ID) {
  prescription(referenceNumber: $referenceNumber, id: $prescriptionId) {
    id
    referenceNumber
    dispenseStatus
    modified
    patient {
      id
      user {
        id
        firstName
        lastName
        mobile
        nationalId
      }
    }
    visit {
      id
      doctor {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
    medications {
      id
      code
      display
      instructions
      duration
      quantity
    }
  }
}
    `;

/**
 * __usePrescriptionGetQuery__
 *
 * To run a query within a React component, call `usePrescriptionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionGetQuery({
 *   variables: {
 *      referenceNumber: // value for 'referenceNumber'
 *      prescriptionId: // value for 'prescriptionId'
 *   },
 * });
 */
export function usePrescriptionGetQuery(baseOptions?: Apollo.QueryHookOptions<PrescriptionGetQuery, PrescriptionGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrescriptionGetQuery, PrescriptionGetQueryVariables>(PrescriptionGetDocument, options);
      }
export function usePrescriptionGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionGetQuery, PrescriptionGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrescriptionGetQuery, PrescriptionGetQueryVariables>(PrescriptionGetDocument, options);
        }
export function usePrescriptionGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PrescriptionGetQuery, PrescriptionGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PrescriptionGetQuery, PrescriptionGetQueryVariables>(PrescriptionGetDocument, options);
        }
export type PrescriptionGetQueryHookResult = ReturnType<typeof usePrescriptionGetQuery>;
export type PrescriptionGetLazyQueryHookResult = ReturnType<typeof usePrescriptionGetLazyQuery>;
export type PrescriptionGetSuspenseQueryHookResult = ReturnType<typeof usePrescriptionGetSuspenseQuery>;
export type PrescriptionGetQueryResult = Apollo.QueryResult<PrescriptionGetQuery, PrescriptionGetQueryVariables>;