import { makeStyles } from "@toolkit/ui";

export const useSelectedPharmacyDetailsStyles = makeStyles()(() => ({
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 20,
  },
  button: {
    width: "215px",
    height: "50px",
    borderRadius: "24px",
    paddingInline: "10px",
  },
}));
