import { CircularIconButton } from "@/shared/components";
import { ArrowIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import React from "react";
import { useSeeAllButtonStyles } from "./SeeAllButtonStyle";

type SeeAllButtonProps = {
  onClick: (Event?: React.MouseEvent<HTMLButtonElement>) => void;
  classes?: Partial<ReturnType<typeof useSeeAllButtonStyles>["classes"]>;
};

export const SeeAllButton: React.FC<SeeAllButtonProps> = props => {
  const { onClick, classes: _classes } = props;
  const { classes, theme, cx } = useSeeAllButtonStyles();
  const { t } = useTranslation("consumer");
  return (
    <Box className={cx(classes.actionsContainer, _classes?.actionsContainer)}>
      <Box className={cx(classes.seeAllButton__container, _classes?.seeAllButton__container)}>
        <Typography className={"see-all__title"}>{t("See All")}</Typography>
        <CircularIconButton onClick={onClick} height={"46px"} className={cx(classes.seeAllButton, _classes?.seeAllButton)}>
          <ArrowIcon fill={theme.palette.common.white} />
        </CircularIconButton>
      </Box>
    </Box>
  );
};
