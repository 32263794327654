import { i18n } from "@toolkit/i18n";
import { DescriptionBody } from "./TabsBodies/DescriptionBody";
import { ReviewsBody } from "./TabsBodies/ReviewsBody";

export const tabs = [
  {
    label: i18n.t("Description", { ns: "consumer" }),
    key: "description",
    component: DescriptionBody,
  },
  {
    label: i18n.t("Reviews", { ns: "consumer" }),
    key: "reviews",
    component: ReviewsBody,
  },
] as const;

export type TabKey = (typeof tabs)[number]["key"];
