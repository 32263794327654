import { HealthPackagesContainer } from "@/domains/marketplace/containers/HealthPackagesContainer/HealthPackagesContainer";
import { routes } from "@/domains/marketplace/routes";
import { Section } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCommonSectionsStyle } from "../../Styles/CommonSectionsStyle";
import { useStyle } from "./HealthPackagesSectionStyle";

export const HealthPackagesSection: React.FC = () => {
  const { classes, cx } = useStyle();
  const { classes: commonClasses } = useCommonSectionsStyle();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();

  const handleSeeAll = () => {
    navigate(routes.healthPackages.absRoute);
  };

  return (
    <Section
      title={t("Featured Health Packages")}
      onSeeAll={handleSeeAll}
      classes={{
        root: cx(commonClasses.root, classes.root, "section__root", "health-packages"),
        container: cx(commonClasses.container, classes.container, "section__container"),
        titleContainer: commonClasses.titleContainer,
        title: cx(commonClasses.title, "title"),
        titleSeeAll: cx(commonClasses.titleSeeAll, "title__btn"),
      }}
    >
      <HealthPackagesContainer classes={classes} />
    </Section>
  );
};
