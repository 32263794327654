import { Counter } from "@/shared/components";
import { useMarketplaceWishlistContext } from "@/shared/context";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import { FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { CartProductItem } from "../../types";
import { calculateDiscountedPrice } from "../../utils";
import { ProductSelectedCard } from "../ProductSelectedCard/ProductSelectedCard";
import { useCartProductCardHook } from "./useCartProductCardHook";
import { useCartProductCardStyle } from "./useCartProductCardStyle";

type CartProductCardProps = {
  product?: CartProductItem;
  cartItemId: string;
  itemQuantity: number;
};

export const CartProductCard: FC<CartProductCardProps> = ({ product, cartItemId, itemQuantity }) => {
  const { classes } = useCartProductCardStyle();
  const navigate = useNavigate();
  const { t } = useTranslation("consumer");

  const { wishlistItems } = useMarketplaceWishlistContext();

  const { counter, cartItemsLoadingTracker, wishListLoadingTracker, handleSaveForLater } = useCartProductCardHook(
    product,
    cartItemId,
    itemQuantity
  );

  const handleOnClick = () => {
    navigateToProductDetails();
  };

  const navigateToProductDetails = () => {
    navigate(routes.product.getRouteWithParams({ productId: product?.id || "" }));
  };

  const handleStopPropagation = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <ProductSelectedCard
      priceInfo={calculateDiscountedPrice(product?.price, product?.activeDiscount)}
      classes={{
        itemAvatarContainer: classes.itemAvatarContainer,
        infoContainer: classes.infoContainer,
      }}
      handleOnClick={handleOnClick}
      product={product}
      actionsNode={
        <Box className={classes.root} onClick={handleStopPropagation}>
          <Counter disabled={cartItemsLoadingTracker?.product?.[product?.id || ""]} {...counter} deleteButtonVisibleAtMin />
          {!wishlistItems.product?.[product?.id || ""] && (
            <Button
              disabled={wishListLoadingTracker.product?.[product?.id || ""] || cartItemsLoadingTracker?.product?.[product?.id || ""]}
              onClick={handleSaveForLater}
              variant='outlined'
              className={classes.removeButton}
            >
              {t("Save for later")}
            </Button>
          )}
        </Box>
      }
    />
  );
};
