import { MarketplaceReviewSummary } from "@/schema/types";
import { CustomRating } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { toNumber } from "lodash";
import { FC } from "react";
import { RatingCountsDetails } from "./RatingCountsDetails";
import { useRatingOverviewStyle } from "./RatingOverviewStyle";

type MarketPlaceRatingOverviewProps = {
  ratingSummary?: MarketplaceReviewSummary;
};

export const MarketPlaceRatingOverview: FC<MarketPlaceRatingOverviewProps> = props => {
  const { ratingSummary } = props;
  const { classes } = useRatingOverviewStyle();
  const { t } = useTranslation("consumer");
  return (
    <Box className={classes?.root}>
      <Typography className={classes.title}>{t("Rating Overview")}</Typography>
      <Box className={classes.ratingAvgContainer}>
        <Typography className={classes.averageRatingValue}>{(toNumber(ratingSummary?.avgRating) || 0).toFixed(2)}</Typography>
        <Typography className={classes.averageRatingLabel}>/5</Typography>
      </Box>
      <CustomRating value={ratingSummary?.avgRating} readOnly precision={0.1} />
      <Typography className={classes.total}>{t("{{total}} Ratings", { total: ratingSummary?.total })}</Typography>
      <RatingCountsDetails ratingCounts={ratingSummary?.ratingCounts} total={ratingSummary?.total} />
    </Box>
  );
};
