import { makeStyles } from "@toolkit/ui";

export const useAddressMapStyles = makeStyles()(() => ({
  root: {
    position: "relative",
    width: "100%",
    height: "max-content",
  },
  actionButton: {
    position: "absolute",
    bottom: "15px",
    right: "15px",
    zIndex: 5,
    margin: "0",
    padding: "0",
    height: "48px",
    borderRadius: "24px",
    paddingInline: "20px",
  },
}));
