import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountAddressCreateMutationVariables = Types.Exact<{
  input: Types.AddressInput;
}>;


export type AccountAddressCreateMutation = { __typename?: 'Mutation', accountAddressCreate?: { __typename?: 'AccountAddressCreate', address?: { __typename?: 'Address', id: string, name?: string | null } | null, accountErrors: Array<{ __typename?: 'AccountError', message?: string | null }> } | null };


export const AccountAddressCreateDocument = gql`
    mutation AccountAddressCreate($input: AddressInput!) {
  accountAddressCreate(input: $input) {
    address {
      id
      name
    }
    accountErrors {
      message
    }
  }
}
    `;
export type AccountAddressCreateMutationFn = Apollo.MutationFunction<AccountAddressCreateMutation, AccountAddressCreateMutationVariables>;

/**
 * __useAccountAddressCreateMutation__
 *
 * To run a mutation, you first call `useAccountAddressCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountAddressCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountAddressCreateMutation, { data, loading, error }] = useAccountAddressCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountAddressCreateMutation(baseOptions?: Apollo.MutationHookOptions<AccountAddressCreateMutation, AccountAddressCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountAddressCreateMutation, AccountAddressCreateMutationVariables>(AccountAddressCreateDocument, options);
      }
export type AccountAddressCreateMutationHookResult = ReturnType<typeof useAccountAddressCreateMutation>;
export type AccountAddressCreateMutationResult = Apollo.MutationResult<AccountAddressCreateMutation>;
export type AccountAddressCreateMutationOptions = Apollo.BaseMutationOptions<AccountAddressCreateMutation, AccountAddressCreateMutationVariables>;