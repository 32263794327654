import { WellnessMainContainer } from "./containers/WellnessMainContainer/WellnessMainContainer";

export const wellnessBaseRoute = "/wellness";

export const wellnessRoutes = {
  root: {
    route: "",
    component: WellnessMainContainer,
    get absRoute() {
      return `${wellnessBaseRoute}${this.route}`;
    },
  },
};
