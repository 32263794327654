import { makeStyles } from "@toolkit/ui";

export const useDigitalTwinSleepCalculatorContainerStyle = makeStyles()(theme => {
  return {
    root: {
      paddingHorizontal: 12,
    },
    pageContent: {
      rowGap: 16,
      display: "flex",
      flexDirection: "column",
    },
    headerCard: {
      paddingHorizontal: 12,
      marginTop: 45,
    },
    title: {
      fontSize: theme.mixins.fonts.fontSize.lg,
      alignSelf: "flex-start",
    },
    subTitle: {
      fontSize: theme.mixins.fonts.fontSize.md,
      color: theme.palette.text.secondary,
      alignSelf: "flex-start",
    },
  };
});
