import { ExpandMoreIcon } from "@/shared/icons";
import { Box } from "@toolkit/ui";
import { ReactNode } from "react";
import { Tab, Tabs } from "react-tabs-scrollable";
import { usePageTabsStyle } from "./PageTabsStyle";

type PageTabsProps = {
  tabs: string[] | ReactNode[];
  activeTabIndex: number;
  onTabClick: (e: React.BaseSyntheticEvent, id: number) => void;
  classes?: Partial<ReturnType<typeof usePageTabsStyle>["classes"]>;
};

export const PageTabs: React.FC<PageTabsProps> = props => {
  const { tabs, activeTabIndex, onTabClick, classes: _classes } = props;
  const { classes, cx, theme } = usePageTabsStyle({ tabsCount: tabs.length || 1 });
  const isRtl = theme.direction === "rtl";

  return (
    <Box className={cx(classes.root, _classes?.root)}>
      <Tabs
        tabsUpperContainerClassName={cx(classes.tabsUpperContainer, _classes?.tabsUpperContainer)}
        tabsContainerClassName={cx(classes.tabsContainer, _classes?.tabsContainer)}
        tabsScrollAmount={2}
        hideNavBtns={false}
        hideNavBtnsOnMobile={false}
        navBtnContainerClassName={cx(classes.navBtnContainer, _classes?.navBtnContainer)}
        isRTL={isRtl}
        activeTab={activeTabIndex}
        onTabClick={onTabClick}
        rightBtnIcon={<ExpandMoreIcon direction='right' />}
        leftBtnIcon={<ExpandMoreIcon direction='left' />}
      >
        {tabs.map((tab, index) => (
          <Tab
            className={cx(classes.tab, _classes?.tab, activeTabIndex === index && cx(classes.activeTab, _classes?.activeTab))}
            key={index + tab.toString()}
          >
            {tab}
          </Tab>
        ))}
      </Tabs>
    </Box>
  );
};
