import { oidcUserManager } from "@/shared/configs";
import React, { FC, PropsWithChildren } from "react";
import { useAuth as odicUseAuth } from "react-oidc-context";
import { AuthContext } from "./context";
import { getEnvVariable } from "@/env";
import { AuthResult } from "@/domains/sign-in/types";
import { User, UserManager } from "oidc-client-ts";
import { oidcUserManagerVar } from "@/shared/configs/oidc";

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, user } = odicUseAuth();

  const setAppToken = (token?: string) => {
    token;
    // do nothing for now
    // odicUserManger is already handling the token
    // keeping this function for future use
  };
  const getAppToken = () => {
    return user?.access_token ?? null;
  };

  const askToLogin = () => {
    oidcUserManager.signinRedirect();
  };

  const handleLogout = async () => {
    await oidcUserManager.clearStaleState();
    await oidcUserManager.signoutRedirect({
      id_token_hint: (await oidcUserManager.getUser())?.id_token,
      state: (await oidcUserManager.getUser())?.state,
      post_logout_redirect_uri: window.location.origin,
    });
    await oidcUserManager.removeUser();
    await oidcUserManager.revokeTokens();
    // localStorage.removeItem(AppAuth.TOKEN);
    // setIsAuthenticated(false);
  };

  const loadUser = async (authResult: AuthResult): Promise<User> => {
    try {
      const user = await oidcUserManager.getUser();
      await oidcUserManager.storeUser(
        new User({
          id_token: authResult.id_token,
          session_state: authResult.session_state,
          access_token: authResult.access_token,
          refresh_token: authResult.refresh_token,
          token_type: authResult.token_type,
          scope: authResult.scope,
          profile: JSON.parse(atob(authResult.id_token.split(".")[1])),
          expires_at: Math.floor(Date.now() / 1000) + authResult.expires_in,
        })
      );

      const userManager = new UserManager({ ...oidcUserManagerVar().settings });
      oidcUserManagerVar(userManager);

      return user!;
    } catch (error) {
      console.error("Error loading user:", error);
      throw error;
    }
  };
  const handleSwitchUser = async (id: string) => {
    try {
      const response = await fetch(`${getEnvVariable("KEYCLOAK_REALM_LINK")}/api/impersonate?userId=${id}`, {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.access_token}`,
          "Cache-Control": "no-cache",
        },
      });

      const data = await response.json();
      if (data) {
        await loadUser(data);
        location.reload();
      }
    } catch (e: any) {
      console.error("Failed to switch user", e);
    }
  };
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        askToLogin,
        setAppToken,
        getAppToken,
        doLogout: handleLogout,
        handleSwitchUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
