import { useCallback } from "react";
import { EVENT_TOPIC, TOPIC_LISTENERS, useSubscribeToNotificationTopic } from "../../NotificationsServiceProvider";

export const useGetNotificationCountAfterNewNotification = (
  handler: TOPIC_LISTENERS[EVENT_TOPIC.NOTIFICATION_COUNT_AFTER_NEW_NOTIFICATION][number]
) => {
  const onNotificationCountChange: TOPIC_LISTENERS[EVENT_TOPIC.NOTIFICATION_COUNT_AFTER_NEW_NOTIFICATION][number] = useCallback(data => {
    handler(data);
  }, []);
  useSubscribeToNotificationTopic(EVENT_TOPIC.NOTIFICATION_COUNT_AFTER_NEW_NOTIFICATION, onNotificationCountChange);
};
