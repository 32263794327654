import { makeStyles } from "@toolkit/ui";

export const useLocationDisplayStyles = makeStyles()(() => ({
  root: {
    height: "120px",
    width: "100%",
    marginBottom: "15px",
  },
  searchContainer: {
    marginBottom: 0,
  },
  mapRoot: {
    height: "100%",
    width: "100%",
    minHeight: "120px",
  },
}));
