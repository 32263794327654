import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientHealthDocumentCreateMutationVariables = Types.Exact<{
  input: Types.PatientHealthDocumentInput;
}>;


export type PatientHealthDocumentCreateMutation = { __typename?: 'Mutation', PatientHealthDocumentCreate?: { __typename?: 'PatientHealthDocument', id: string } | null };


export const PatientHealthDocumentCreateDocument = gql`
    mutation PatientHealthDocumentCreate($input: PatientHealthDocumentInput!) {
  PatientHealthDocumentCreate(input: $input) {
    id
  }
}
    `;
export type PatientHealthDocumentCreateMutationFn = Apollo.MutationFunction<PatientHealthDocumentCreateMutation, PatientHealthDocumentCreateMutationVariables>;

/**
 * __usePatientHealthDocumentCreateMutation__
 *
 * To run a mutation, you first call `usePatientHealthDocumentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientHealthDocumentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientHealthDocumentCreateMutation, { data, loading, error }] = usePatientHealthDocumentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientHealthDocumentCreateMutation(baseOptions?: Apollo.MutationHookOptions<PatientHealthDocumentCreateMutation, PatientHealthDocumentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientHealthDocumentCreateMutation, PatientHealthDocumentCreateMutationVariables>(PatientHealthDocumentCreateDocument, options);
      }
export type PatientHealthDocumentCreateMutationHookResult = ReturnType<typeof usePatientHealthDocumentCreateMutation>;
export type PatientHealthDocumentCreateMutationResult = Apollo.MutationResult<PatientHealthDocumentCreateMutation>;
export type PatientHealthDocumentCreateMutationOptions = Apollo.BaseMutationOptions<PatientHealthDocumentCreateMutation, PatientHealthDocumentCreateMutationVariables>;