import { makeStyles } from "@toolkit/ui";

export const useMarketplaceCardLayoutStyles = makeStyles()(() => ({
  card: {
    display: "flex",
    padding: "15px",
    justifyContent: "center",
    alignItems: "center",
    marginInline: "0px",
  },
  container: {
    display: "flex",
    width: "100%",
    height: "fit-content",
  },
  itemAvatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    paddingInlineEnd: "10px",
    minWidth: "120px",
  },
  infoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    flexWrap: "wrap",
    overflowWrap: "break-word",
  },
  textColumn: {
    display: "flex",
    alignContent: "start",
    flexDirection: "column",
    textAlign: "start",
    paddingInlineStart: 2,
    width: "calc(100%)",
    paddingBottom: "10px",
  },
  actionsContainer: {
    width: "max-content",
    display: "flex",
    alignSelf: "center",
    flexWrap: "wrap",
    gap: "20px",
  },
}));
