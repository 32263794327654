import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const BySystemIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='17.038' height='16.186' viewBox='0 0 17.038 16.186' {...props}>
      <g id='icons8-imac' transform='translate(-4 -5)'>
        <path
          id='Path_13029'
          data-name='Path 13029'
          d='M20.4,12.369a.639.639,0,0,1-.639-.639V6.917a.618.618,0,0,0-.639-.639H5.917a.618.618,0,0,0-.639.639v.6A.639.639,0,1,1,4,7.513v-.6A1.9,1.9,0,0,1,5.917,5h13.2a1.9,1.9,0,0,1,1.917,1.917V11.73A.639.639,0,0,1,20.4,12.369Z'
          fill={fill}
        />
        <path
          id='Path_13030'
          data-name='Path 13030'
          d='M19.121,23.806H5.917A1.9,1.9,0,0,1,4,21.889v-5.75a.639.639,0,0,1,1.278,0v5.75a.618.618,0,0,0,.639.639h13.2a.618.618,0,0,0,.639-.639v-1.15a.639.639,0,1,1,1.278,0v1.15A1.9,1.9,0,0,1,19.121,23.806Z'
          transform='translate(0 -6.028)'
          fill={fill}
        />
        <path
          id='Line_1696'
          data-name='Line 1696'
          d='M7.232-.222H-.861A.639.639,0,0,1-1.5-.861.639.639,0,0,1-.861-1.5H7.232a.639.639,0,0,1,.639.639A.639.639,0,0,1,7.232-.222Z'
          transform='translate(9.334 21.408)'
          fill={fill}
        />
        <path
          id='Line_1697'
          data-name='Line 1697'
          d='M-.393,3.185a.639.639,0,0,1-.632-.552L-1.494-.774a.639.639,0,0,1,.546-.72.639.639,0,0,1,.72.546L.24,2.459a.639.639,0,0,1-.546.72A.645.645,0,0,1-.393,3.185Z'
          transform='translate(15.638 18.001)'
          fill={fill}
        />
        <path
          id='Line_1698'
          data-name='Line 1698'
          d='M-.86,3.185a.644.644,0,0,1-.088-.006.639.639,0,0,1-.546-.72l.469-3.408a.639.639,0,0,1,.72-.546.639.639,0,0,1,.546.72L-.228,2.633A.639.639,0,0,1-.86,3.185Z'
          transform='translate(10.654 18.001)'
          fill={fill}
        />
        <path
          id='Line_1699'
          data-name='Line 1699'
          d='M12.9-.222H-.861A.639.639,0,0,1-1.5-.861.639.639,0,0,1-.861-1.5H12.9a.639.639,0,0,1,.639.639A.639.639,0,0,1,12.9-.222Z'
          transform='translate(7.502 15.445)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
