import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatsQueryVariables = Types.Exact<{
  filter: Types.ChatFilterInput;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.ChatSortingInput>;
}>;


export type ChatsQuery = { __typename?: 'Query', chats?: { __typename?: 'ChatConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges: Array<{ __typename?: 'ChatEdge', cursor?: string | null, node: { __typename?: 'Chat', id: string, message?: string | null, createdAt: any, deleted: boolean, edited: boolean, attachments?: Array<{ __typename?: 'ChatAttachment', id: string, type: string, name: string, url: string }> | null, senderUser: { __typename?: 'User', id: string, photo?: string | null, fullName?: string | null }, parent?: { __typename?: 'Chat', message?: string | null, createdAt: any, deleted: boolean, edited: boolean, attachments?: Array<{ __typename?: 'ChatAttachment', id: string, type: string, name: string, url: string }> | null, senderUser: { __typename?: 'User', id: string, fullName?: string | null, photo?: string | null } } | null } }> } | null };


export const ChatsDocument = gql`
    query Chats($filter: ChatFilterInput!, $before: String, $after: String, $first: Int, $last: Int, $sortBy: ChatSortingInput) {
  chats(
    filter: $filter
    before: $before
    after: $after
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      cursor
      node {
        id
        message
        createdAt
        deleted
        edited
        attachments {
          id
          type
          name
          url
        }
        senderUser {
          id
          photo
          fullName
        }
        parent {
          message
          createdAt
          deleted
          edited
          attachments {
            id
            type
            name
            url
          }
          senderUser {
            id
            fullName
            photo
          }
        }
      }
    }
  }
}
    `;

/**
 * __useChatsQuery__
 *
 * To run a query within a React component, call `useChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useChatsQuery(baseOptions: Apollo.QueryHookOptions<ChatsQuery, ChatsQueryVariables> & ({ variables: ChatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatsQuery, ChatsQueryVariables>(ChatsDocument, options);
      }
export function useChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatsQuery, ChatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatsQuery, ChatsQueryVariables>(ChatsDocument, options);
        }
export function useChatsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ChatsQuery, ChatsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChatsQuery, ChatsQueryVariables>(ChatsDocument, options);
        }
export type ChatsQueryHookResult = ReturnType<typeof useChatsQuery>;
export type ChatsLazyQueryHookResult = ReturnType<typeof useChatsLazyQuery>;
export type ChatsSuspenseQueryHookResult = ReturnType<typeof useChatsSuspenseQuery>;
export type ChatsQueryResult = Apollo.QueryResult<ChatsQuery, ChatsQueryVariables>;