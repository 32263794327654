import { useCommonContainerStyles } from "@/shared/styles";
import { Box } from "@toolkit/ui";
import { FC } from "react";
import { CaloriesTrackingCard } from "../../components/CaloriesTrackingCard/CaloriesTrackingCard";
import { SleepTrackingCard } from "../../components/SleepTrackingCard/SleepTrackingCard";
import { TodayFeelingSection } from "../../components/TodayFeelingSection/TodayFeelingSection";
import { getPatientId } from "@/domains/patient";
import { get } from "lodash";
import { useAuth } from "react-oidc-context";
import { ReelsSection } from "../../components/ReelsSection/ReelsSection";
import { RecipesSection } from "../../components/RecipesSection/RecipesSection";
import { ActivitiesCard } from "@/domains/activites/components";

export const WellnessMainContainer: FC = () => {
  const { classes } = useCommonContainerStyles();
  const { user } = useAuth();

  const patientId = getPatientId(get(user?.profile, "patient_id") as string)!;

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <ActivitiesCard />
        <TodayFeelingSection patientId={patientId} />
        <SleepTrackingCard />
        <ReelsSection />
        <RecipesSection />
        <CaloriesTrackingCard />
      </Box>
    </Box>
  );
};
