import { makeStyles } from "@toolkit/ui";

export const useSleepCalculatorClockWidgetStyles = makeStyles()(theme => {
  return {
    root: {
      direction: "ltr",
      padding: 8,
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    title: {
      fontSize: theme.mixins.fonts.fontSize.md,
      alignSelf: "flex-start",
    },
    iconInClock: {
      padding: 4,
      borderWidth: 2,
      borderStyle: "solid",
      borderRadius: 25,
      backgroundColor: "white",
    },
  };
});
