import { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";

export const UserProfileIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props?.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27'>
      <g id='Group_11086' data-name='Group 11086' transform='translate(-107 -350)'>
        <g id='Ellipse_2784' data-name='Ellipse 2784' transform='translate(107 350)' fill={fill} stroke='#fff' strokeWidth='1'>
          <circle cx='13.5' cy='13.5' r='13.5' stroke='none' />
          <circle cx='13.5' cy='13.5' r='13' fill='none' />
        </g>
        <path
          id='icons8-user_6_'
          data-name='icons8-user (6)'
          d='M9.539,3A3.021,3.021,0,0,0,6.518,6.021v.5a3.021,3.021,0,0,0,6.043,0v-.5A3.021,3.021,0,0,0,9.539,3Zm0,8.057c-2.017,0-4.606,1.091-5.35,2.06a.915.915,0,0,0,.733,1.465h9.236a.915.915,0,0,0,.733-1.465C14.145,12.149,11.555,11.057,9.538,11.057Z'
          transform='translate(110.826 354.113)'
          fill='#fff'
        />
      </g>
    </SvgIcon>
  );
};
