import { useTranslation } from "@/i18n/i18n.config";
import { useAuth } from "@/shared/context";
import { ArrowIcon } from "@/shared/icons";
import { Box, Button, Divider, Typography } from "@toolkit/ui";
import React from "react";
import { sideMenuDrawerOpenStateVar } from "../sideMenuDrawerOpenStateVar";
import useStyles from "./PostList.styles";

export const PostList: React.FC = () => {
  const { doLogout, isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const handleLogout = () => {
    sideMenuDrawerOpenStateVar(false);
    doLogout();
  };

  return (
    <Box className={cx(classes.container, !isAuthenticated && classes.containerWithoutLogout)}>
      {isAuthenticated && (
        <Button variant='outlined' className={classes.button} onClick={handleLogout} endIcon={<ArrowIcon />}>
          {t("Log out")}
        </Button>
      )}
      <Divider className={classes.divider} />
      <Typography className={classes.versionText}>iO health v.1.20</Typography>
    </Box>
  );
};
