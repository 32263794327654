import { reduce } from "lodash";
import { marketplaceCartDataType, marketplaceItemsHashMap } from "./types";
import { produce } from "immer";

export const calculateCartItemCounts = (cart: marketplaceCartDataType) => {
  const { productsCount, packagesCount } = reduce(
    cart?.items,
    (acc, item) => {
      if (item?.healthPackage?.id) {
        acc.packagesCount += item?.quantity || 0;
      } else {
        acc.productsCount += item?.quantity || 0;
      }
      return acc;
    },
    { productsCount: 0, packagesCount: 0 }
  );

  const totalCount = productsCount + packagesCount;

  return { totalCount, productsCount, packagesCount };
};

export const generateCartItemsHashMap = (cart: marketplaceCartDataType) => {
  return cart?.items?.reduce(
    (hashMap: Record<"product" | "healthPackage", Record<string, boolean>>, item) => {
      if (item?.product?.id && !hashMap[item.product.id!]) {
        hashMap["product"][item.product.id!] = true;
      }
      if (item?.healthPackage?.id && !hashMap[item.healthPackage.id!]) {
        hashMap["healthPackage"][item.healthPackage.id!] = true;
      }
      return hashMap;
    },
    { product: {}, healthPackage: {} } as marketplaceItemsHashMap
  );
};

export const itemsLoadingTrackerMapReducer = (
  state: marketplaceItemsHashMap,
  action: { type: string; payload: { id: string; category: "product" | "healthPackage"; value?: boolean } }
) => {
  return produce(state, draft => {
    const { id, category, value } = action.payload;
    switch (action.type) {
      case "SET_LOADING":
        draft[category][id] = value ?? true;
        break;
      case "SET_LOADED":
        draft[category][id] = false;
        break;
      default:
        break;
    }
  });
};
