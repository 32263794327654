import { AddressFragmentFragment } from "@/domains/address/gql/fragments";
import { Maybe } from "@/schema/types";
import { CustomCard } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { AddIconInCircle, makeStyles, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { AddressExpandableCard } from "../AddressExpandableCard/AddressExpandableCard";

export type AddressesListProps = {
  addresses?: Maybe<AddressFragmentFragment>[] | null;
  getItemSelectionStatus?: (id?: string) => boolean | undefined;
  onSelect?: (address: AddressFragmentFragment) => void;
  isCardSelectable?: boolean;
  hideDelete?: boolean;
};

const useAddressesListStyle = makeStyles()(theme => ({
  addCardRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    gap: 8,
    cursor: "pointer",
    "& svg": {
      width: 20,
      height: 20,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary[100],
    },
  },
}));
export const AddressesList: FC<AddressesListProps> = props => {
  const { addresses, getItemSelectionStatus, isCardSelectable, hideDelete, onSelect } = props;
  const { classes } = useAddressesListStyle();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const handleAddNewAddress = () => {
    navigate(routes.new.absRoute);
  };
  return (
    <>
      {addresses?.map(address =>
        address ? (
          <AddressExpandableCard
            hideDelete={hideDelete}
            selectionProps={
              isCardSelectable
                ? {
                    selected: getItemSelectionStatus?.(address?.id),
                    onSelect: () => onSelect?.(address),
                  }
                : undefined
            }
            key={address?.id}
            address={address}
            isDefault={!!address.isDefaultShippingAddress}
          />
        ) : undefined
      )}
      <CustomCard className={classes.addCardRoot} onClick={handleAddNewAddress}>
        <AddIconInCircle />
        <Typography>{t("Add New Address")}</Typography>
      </CustomCard>
    </>
  );
};
