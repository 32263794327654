import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Typography } from "@toolkit/ui";
import { FC } from "react";
import { CareTeamMemberCard } from "../../components/CareTeamMemberCard/CareTeamMemberCard";
import { CareTeamMembersList } from "../../components/CareTeamMembersList/CareTeamMembersList";
import { useStyle } from "./CareTeamRegistrationConfirmContainerStyle";
import { useCareTeamRegistrationConfirm } from "./useCareTeamRegistrationConfirm";
import { ConfirmationDialog } from "@/shared/components";

export const CareTeamRegistrationConfirmContainer: FC = () => {
  const { classes: commonClasses } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const { classes, cx } = useStyle();
  const { open, handleOpen, handleClose, handleConfirmClick, handleCancelClick, loading, team, teamLeader, leaderTeamsMembers } =
    useCareTeamRegistrationConfirm(t);

  return (
    <Box className={commonClasses.pageContainer}>
      <Box className={commonClasses.pageContent}>
        <Typography className={commonClasses.pageTitle}>{team?.name}</Typography>

        <Typography className={classes.title}>{t("Your care team leader")}</Typography>
        <CareTeamMemberCard member={teamLeader!} isTeamLeader />

        <Typography className={classes.title} mt={4}>
          {t("Your care team")}
        </Typography>
        <CareTeamMembersList teamMembers={leaderTeamsMembers!} />

        {open && (
          <ConfirmationDialog
            variant='warning'
            isOpen={open}
            onClose={handleClose}
            isConfirmButtonDisabled={loading}
            onConfirm={handleConfirmClick}
            text={{
              title: t("Confirmation"),
              body: t("Please be aware that this team will have access to your medical data for effective treatment"),
            }}
          />
        )}
      </Box>

      <Box className={cx(commonClasses.footer, classes.footer)}>
        <Button onClick={handleOpen} className={classes.button}>
          {t("Confirm")}
        </Button>
        <Button variant='outlined' onClick={handleCancelClick} className={classes.button}>
          {t("Cancel")}
        </Button>
      </Box>
    </Box>
  );
};
