import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC, useId } from "react";

export interface BlobImageIconProps extends SvgIconProps {
  src?: string | null;
  fill?: string;
  fillBackground?: string;
  showBackground?: boolean;
  isCircular?: boolean;
  elementId?: string;
}

export const BlobImageIcon: FC<BlobImageIconProps> = props => {
  const { src, fill, fillBackground, showBackground = true, isCircular = false, elementId, ...rest } = props;
  const theme = useTheme();
  const uniqueId = useId();
  const patternId = `image-pattern-${elementId || uniqueId}`;
  const clipPathId = `clip-path-${elementId || uniqueId}`;

  const defaultFill = fill || theme.palette.primary.main;
  const defaultFillBackground = fillBackground || theme.palette.primary.main;

  const viewBox = isCircular ? "0 0 61 61" : "0 0 62 62.774";
  const width = isCircular ? 61 : 62;
  const height = isCircular ? 61 : 62.774;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...rest}>
      <defs>
        {src && (
          <>
            <pattern id={patternId} patternUnits='userSpaceOnUse' width={width} height={height}>
              <circle cx={width / 2 + 1} cy={height / 2 + 2.5} r={Math.min(width, height) * 0.4} fill='white' />
              <circle cx={width / 2 + 1} cy={height / 2 + 2.5} r={Math.min(width, height) * 0.4} fill='none' strokeWidth='1' />
              <clipPath id={clipPathId}>
                <circle cx={width / 2 + 1} cy={height / 2 + 2.5} r={Math.min(width, height) * 0.38} />
              </clipPath>
              <image
                href={src}
                x={width * 0.1}
                y={height * 0.1}
                width={width * 0.8}
                height={height * 0.8}
                preserveAspectRatio='xMidYMid slice'
                clipPath={`url(#${clipPathId})`}
              />
            </pattern>
          </>
        )}
      </defs>
      <g id='blob-icon' transform='translate(-11 -271)'>
        <g transform='translate(11 271)'>
          {showBackground &&
            (isCircular ? (
              <>
                <circle cx={width / 2} cy={height / 2} r={width / 2} fill={defaultFillBackground} />
                <circle cx={width / 2} cy={height / 2} r={width / 2 - 1} fill={fill} />
              </>
            ) : (
              <>
                <path
                  d='M29.753,61.344A46.81,46.81,0,0,1,11.024,53C5.381,48.659.2,42.465,0,36.121c-.14-6.33,4.661-12.787,9.888-17.859a52.377,52.377,0,0,1,17.5-11.578c6.747-2.79,14.8-4.605,20.3-1.679,5.472,2.95,8.4,10.642,10.04,17.769a48.974,48.974,0,0,1,.832,20.435C57.349,50,54.508,57.008,49.312,60.2c-5.173,3.226-12.7,2.634-19.559,1.147'
                  transform='translate(0 -1.9) scale(1.03)'
                  fill={defaultFillBackground}
                />
                <path
                  d='M29.753,61.344A46.81,46.81,0,0,1,11.024,53C5.381,48.659.2,42.465,0,36.121c-.14-6.33,4.661-12.787,9.888-17.859a52.377,52.377,0,0,1,17.5-11.578c6.747-2.79,14.8-4.605,20.3-1.679,5.472,2.95,8.4,10.642,10.04,17.769a48.974,48.974,0,0,1,.832,20.435C57.349,50,54.508,57.008,49.312,60.2c-5.173,3.226-12.7,2.634-19.559,1.147'
                  transform='translate(1 -0.9) scale(1)'
                  fill={fill}
                />
              </>
            ))}
          {isCircular ? (
            <circle
              transform='translate(-4 -6) scale(1.1)'
              cx={width / 2}
              cy={height / 2}
              r={width / 2}
              fill={src ? `url(#${patternId})` : defaultFill}
            />
          ) : (
            <path
              d='M29.753,61.344A46.81,46.81,0,0,1,11.024,53C5.381,48.659.2,42.465,0,36.121c-.14-6.33,4.661-12.787,9.888-17.859a52.377,52.377,0,0,1,17.5-11.578c6.747-2.79,14.8-4.605,20.3-1.679,5.472,2.95,8.4,10.642,10.04,17.769a48.974,48.974,0,0,1,.832,20.435C57.349,50,54.508,57.008,49.312,60.2c-5.173,3.226-12.7,2.634-19.559,1.147'
              transform='translate(0.898 -0.818)'
              fill={src ? `url(#${patternId})` : defaultFill}
            />
          )}
        </g>
      </g>
    </SvgIcon>
  );
};
