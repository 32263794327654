import React from "react";
import DOMPurify from "dompurify";
import { Box } from "@toolkit/ui";

interface HtmlRendererProps {
  htmlContent: string;
  isSanitized?: boolean;
}

const HtmlRenderer: React.FC<HtmlRendererProps> = ({ htmlContent, isSanitized = true }) => {
  const sanitizedHtmlContent = isSanitized ? htmlContent : DOMPurify.sanitize(htmlContent);

  return <Box dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }} />;
};

export default HtmlRenderer;
