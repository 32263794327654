import { makeStyles } from "@toolkit/ui";

export const useCallStyles = makeStyles()(theme => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    minHeight: "inherit",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    minWidth: 320,
    overflowX: "hidden",
    colorScheme: "dark",
  },

  container: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    padding: "0.5rem",
    maxWidth: "100%",
    boxSizing: "border-box",
  },

  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },

  headerTitle: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textAlign: "center",
    flex: 1,
    fontSize: theme.mixins.fonts.fontSize.md,
  },

  body: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    overflow: "hidden",
  },

  icon: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    width: 70,
    height: 70,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
