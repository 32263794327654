import { useDigitalTwinPatientHealthParameterListQuery } from "../gql";
import { HealthParameterCodes } from "../constants/healthParameterCodes";
import { HealthParameter, PatientHealthParameterSortField, H_OrderDirection } from "@/schema/types";

export const useGetDigitalTwinHealthParameterByCode = (
  code: HealthParameterCodes,
  onCodeFetched?: (value: number, param: Partial<HealthParameter>) => void
) => {
  const { data, loading } = useDigitalTwinPatientHealthParameterListQuery({
    variables: {
      first: 1,
      filter: {
        codes: [code],
      },
      sortBy: {
        field: PatientHealthParameterSortField.ReadingDate,
        direction: H_OrderDirection.Desc,
      },
    },
    skip: !code,
    onCompleted: onCodeFetched
      ? data => {
          const hp = data?.patientHealthParameters?.edges?.[0]?.node;
          if (hp) {
            onCodeFetched(hp?.valueNumber!, hp as Partial<HealthParameter>);
          }
        }
      : undefined,
  });
  return {
    value: data?.patientHealthParameters?.edges?.[0]?.node?.valueNumber,
    healthParameter: data?.patientHealthParameters?.edges?.[0]?.node,
    loading,
  };
};
