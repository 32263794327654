import { useAddToast } from "@toolkit/ui";
import { AddressFragmentFragment } from "../../gql/fragments";
import { useAccountAddressDeleteMutation } from "../../gql/mutations";
import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

type UseAddressExpandableCardProps = {
  address?: AddressFragmentFragment;
};

export const useAddressExpandableCard = (props: UseAddressExpandableCardProps) => {
  const { address } = props;
  const { t } = useTranslation("consumer");
  const { failed } = useAddToast();
  const { open, handleClose, handleOpen } = useOpenState();
  const navigate = useNavigate();

  const [deleteAddress, { loading: isDeleteSubmitting }] = useAccountAddressDeleteMutation({
    onCompleted: () => {
      handleClose();
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
    update: cache => {
      const normalizedId = cache.identify({ id: address?.id, __typename: "Address" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const handleDelete = () => {
    address?.id &&
      deleteAddress({
        variables: {
          accountAddressDeleteId: address?.id,
        },
      });
  };

  const handleDeleteClick = () => {
    handleOpen();
  };

  const handleEdit = () => {
    address?.id && navigate(routes.edit.getAbsRouteWithParams(address?.id));
  };

  return {
    address: props.address,
    isDeleteSubmitting,
    handleDelete,
    open,
    handleClose,
    handleDeleteClick,
    handleEdit,
  };
};
