import { useTranslation } from "@/i18n/i18n.config";
import { Appointment, AppointmentType, CalculatedPaymentStatusForSubscription } from "@/schema/types";
import { EVENT_TOPIC, useOnAppointmentStatusUpdate } from "@health/sse";
import { Trans } from "@toolkit/i18n";
import { Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useAppointmentQuery } from "../../gql";
import { getNotificationTextStyle } from "../../utils";
import { AppointmentDateTime } from "../AppointmentNotification/AppointmentNotificationStatusViews";
import { getAppointmentDateTime } from "../AppointmentNotification/utils";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { NotificationDefaultCard } from "../NotificationDefaultCard/NotificationDefaultCard";
import { paymentNotificationStatusMapper } from "./utils";

type AppointmentRefundNotificationProps = {
  id: string;
  creationDate: string;
  isSeen: boolean;
  status: CalculatedPaymentStatusForSubscription;
  refundAmount?: string;
};

const AppointmentRefundNotification: FC<AppointmentRefundNotificationProps> = ({ id, creationDate, isSeen, status, refundAmount }) => {
  const { t } = useTranslation("consumer");
  const theme = useTheme();

  const { color } = getNotificationTextStyle(isSeen, theme);

  const {
    data: appointmentInfo,
    loading,
    error,
    refetch,
  } = useAppointmentQuery({
    variables: { appointmentId: id },
    skip: !id,
  });

  useOnAppointmentStatusUpdate(({ data }) => {
    if (data.graphqlType === "Appointment" && data?.id === id) {
      refetch();
    }
  });

  const appointment = appointmentInfo?.appointment as Appointment;
  const appointmentDate = getAppointmentDateTime(appointment);
  const doctor = `${appointment?.doctor?.user?.firstName || "-"} ${appointment?.doctor?.user?.lastName || ""}`;
  const appointmentType = Object.keys(AppointmentType).find(
    key => AppointmentType[key as keyof typeof AppointmentType] === appointment?.type
  );

  if (!appointment?.id || error) {
    return (
      <NotificationDefaultCard type={EVENT_TOPIC.HEALTH_PROGRAM_APPOINTMENT_STATUS_CHANGED} creationDate={creationDate} isSeen={isSeen} />
    );
  }

  const Icon = paymentNotificationStatusMapper(theme)[status!]?.icon;

  return (
    <NotificationCard
      title={paymentNotificationStatusMapper(theme)[status!]?.display || status?.replace("_", " ")}
      date={creationDate}
      icon={<Icon />}
      iconColor={paymentNotificationStatusMapper(theme)[status!]?.color}
      isLoading={loading}
      isSeen={isSeen}
    >
      <Typography color={color}>
        <Trans
          t={t}
          i18nKey='Your <b>{{appointmentType}}</b> Appointment with <b>{{doctor}}</b>'
          values={{ appointmentType, doctor }}
          components={{
            b: <Typography color={color} display='inline' fontWeight={theme.mixins.fonts.fontWeight.semiBold} />,
          }}
        />
      </Typography>
      {refundAmount && (
        <Typography color={color} display='inline'>
          <Typography color={color} display='inline'>
            {status === CalculatedPaymentStatusForSubscription.PaymentSucceeded ? t("Paid Amount") : t("Refund Amount")}
          </Typography>
          : {refundAmount}
        </Typography>
      )}

      <AppointmentDateTime
        appointmentDate={appointmentDate}
        requestedTimeSlots={appointment?.requestedTimeSlots as string[]}
        isSeen={isSeen}
      />
    </NotificationCard>
  );
};

export default AppointmentRefundNotification;
