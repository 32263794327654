import { HealthProgramMember } from "@/schema/types";
import { useCommonFormStyles } from "@/shared/styles";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, createAutocompleteOption, FormAutocomplete, FormTextField } from "@toolkit/ui";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { FormProvider } from "react-hook-form";
import {
  ePrescriptionReferenceSearchFormDefaultValues,
  EPrescriptionReferenceSearchFormSchema,
  IEPrescriptionReferenceSearchFormValues,
} from "./EPrescriptionReferenceSearchFormSchema";
import { convertEPrescriptionToFormValues } from "../../others/utils";
import { SearchIcon } from "@/shared/icons";
import { useEPrescriptionReferenceSearchStyles } from "../../components/EPrescriptionReferenceSearch/useEPrescriptionReferenceSearch.styles";

type EPrescriptionUpsertFormProps = {
  healthProgramMember?: Array<Pick<HealthProgramMember, "id" | "insuranceId">>;
  onChange?: (values: IEPrescriptionReferenceSearchFormValues) => void;
  ePrescriptionReference?: string;
  memberId?: string;
};

export type EPrescriptionUpsertFormRef = {
  submit: () => void;
};

const EPrescriptionUpsertFormForwardRef: ForwardRefRenderFunction<EPrescriptionUpsertFormRef, EPrescriptionUpsertFormProps> = (
  props,
  ref
) => {
  const { onChange, healthProgramMember, ePrescriptionReference, memberId } = props;
  const { t } = useTranslation("consumer");

  const { classes } = useCommonFormStyles();
  const { classes: customClasses } = useEPrescriptionReferenceSearchStyles();
  const form = useCustomForm<IEPrescriptionReferenceSearchFormValues>({
    schema: EPrescriptionReferenceSearchFormSchema,
    defaultValues: ePrescriptionReferenceSearchFormDefaultValues,
    mode: "onChange",
  });

  const { setValues, watch } = form;

  const handleSubmit = (values: IEPrescriptionReferenceSearchFormValues) => {
    const _values = values;
    onChange?.(_values);
  };

  useImperativeHandle(ref, () => ({
    submit: () => form.handleSubmit(handleSubmit)(),
  }));

  useEffect(() => {
    const ePrescription = convertEPrescriptionToFormValues(ePrescriptionReference, healthProgramMember, memberId);
    setValues(ePrescription);
  }, [memberId, healthProgramMember, ePrescriptionReference, setValues]);

  const hideAutoComplete = watch("variant") === "text";
  const healthProgramMemberOptions = healthProgramMember?.map(member => createAutocompleteOption(member, "id", "insuranceId"));

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Box className={classes.formContainer}>
          <FormTextField
            className={classes.textField}
            name='ePrescriptionReference'
            label={t("e-Prescription")}
            InputLabelProps={{ shrink: true }}
            placeholder={t("Ex: DH435983409583")}
            InputProps={{
              startAdornment: (
                <Box component='span' className={customClasses.searchIcon}>
                  <SearchIcon />
                </Box>
              ),
            }}
          />
          {!hideAutoComplete && (
            <FormAutocomplete
              label={t("Member Id")}
              className={classes.textField}
              options={healthProgramMemberOptions}
              name={"healthProgramMemberAutocomplete"}
            />
          )}
          {hideAutoComplete && (
            <FormTextField
              className={classes.textField}
              name={"healthProgramMemberText"}
              label={t("Member Id")}
              placeholder={t("Member Id")}
              InputLabelProps={{ shrink: true }}
            />
          )}
        </Box>
      </form>
    </FormProvider>
  );
};

export const EPrescriptionUpsertForm = forwardRef(EPrescriptionUpsertFormForwardRef);
