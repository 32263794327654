import { useCommonInfiniteContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { FC, useMemo } from "react";
import { MyOrdersTabs } from "../../components/MyOrdersTabs/MyOrdersTabs";
import { myOrdersTabs } from "../../components/MyOrdersTabs/tabs";
import queryString from "query-string";
import { MyOrdersTabsEnum } from "../../others/types";
import { MarketplaceOrdersList } from "../../components/MyOrdersList/MyOrdersList";

export const MyOrdersContainer: FC = () => {
  const { classes } = useCommonInfiniteContainerStyles();
  const { t } = useTranslation("consumer");

  const { tab } = queryString.parse(location.search) as { tab?: MyOrdersTabsEnum };
  const getSelectedTabIndex = () => {
    const index = myOrdersTabs.findIndex(tabItem => tabItem.routeParameterValue === tab);
    return index === -1 ? 0 : index;
  };
  const tabIndex = useMemo(() => getSelectedTabIndex(), [tab]);
  return (
    <Box className={classes.container}>
      <Typography variant='h6' className={classes.title}>
        {t("My Orders")}
      </Typography>
      <MyOrdersTabs tabIndex={tabIndex} />
      <MarketplaceOrdersList tabIndex={tabIndex} />
    </Box>
  );
};
