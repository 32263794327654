import { makeStyles } from "@toolkit/ui";

export const useOrderDetailsCardStyles = makeStyles()(theme => ({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
  },
  headerCentredContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  contentRoot: {
    boxShadow: "none",
    padding: 0,
    background: "inherit",
    paddingInline: 10,
  },
  headerMainLabel: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  headerSecondaryLabel: {
    fontWeight: theme.mixins.fonts.fontWeight.normal,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
}));
