import { MarketplaceHealthPackageSortingField } from "@/schema/types";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";
import { z } from "zod";

export const HealthPackagesListFiltersFormSchema = z.object({
  vendor: zodSchema.vendor.nullish(),
  brands: z.array(zodSchema.marketplaceBrand.nullish()).optional(),
  price: z.array(z.number()).length(2).optional(),
  sortingField: z.nativeEnum(MarketplaceHealthPackageSortingField).optional(),
  gender: zodEnumSchema.marketplaceGenderType.nullable().optional(),
  name: z.string().optional(),
  age: z.number().int().min(1).max(100).optional().nullish(),
});

export type IHealthPackagesListFiltersFormValues = z.infer<typeof HealthPackagesListFiltersFormSchema>;

export const healthPackagesListFiltersFormDefaultValues: Partial<IHealthPackagesListFiltersFormValues> = {
  vendor: undefined,
  brands: undefined,
  price: [0, 1200],
  sortingField: undefined,
  gender: undefined,
  name: undefined,
  age: undefined,
};
