import { FC, useEffect } from "react";
import { Trans, useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, Typography } from "@toolkit/ui";
import { SignInOTPVerification } from "../../components/SignInOTPVerification/SignInOTPVerification";
import { useSignInByOTPModalStyles } from "./useSignInByOTPModalStyles";
import { useSignInByOTPModalLogic } from "./useSignInByOTPModalLogic.hook";
import { SignInByOTPModalOpenDataType } from "../../types";
import { noop } from "lodash";
import { useOtpVerification } from "./useOtpVerification.hook";

type IOpen = (data: SignInByOTPModalOpenDataType) => void;
type IClose = () => void;

export const SignInByOTPModalApi: { open: IOpen; close: IClose } = {
  open: noop,
  close: noop,
};

export const SignInByOTPModal: FC = () => {
  const { t } = useTranslation("consumer");
  const { classes } = useSignInByOTPModalStyles();
  const { isOpen, signInData, sessionToken, isOtpSending, open, close, handleSendOtp, setSessionToken } = useSignInByOTPModalLogic();

  const { setOtp, isVerifying, handleVerifyOtp } = useOtpVerification(sessionToken, signInData?.mobile);

  useEffect(() => {
    SignInByOTPModalApi.open = open;
    SignInByOTPModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type='bottomSheet'
      open={isOpen}
      title={t("Sign In By OTP")}
      handleClose={noop}
      classes={{ title: classes.title }}
      hasDragBar={false}
      footer={
        <Box className={classes.actionsContainer}>
          <Button
            onClick={sessionToken ? handleVerifyOtp : handleSendOtp}
            className={classes.button}
            disabled={isOtpSending || isVerifying}
          >
            {!sessionToken ? t("Send OTP") : t("Verify")}
          </Button>
          <Button className={classes.button} variant='outlined' onClick={close} disabled={isOtpSending || isVerifying}>
            {t("Close")}
          </Button>
        </Box>
      }
    >
      <Box className={classes.containerBody}>
        {!sessionToken ? (
          <Typography>
            <Trans
              i18nKey='To sign in, we’ll send an OTP to your registered number <secondary>{{mobile}}</secondary>. Click below to proceed.'
              values={{ mobile: signInData?.mobile ?? "" }}
              components={{ secondary: <Typography className={classes.secondary} /> }}
            />
          </Typography>
        ) : (
          <SignInOTPVerification handleOtpChange={setOtp} mobile={signInData?.mobile ?? ""} handleSessionToken={setSessionToken} />
        )}
      </Box>
    </CustomDialog>
  );
};
