import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "562px",
  },
  container: {
    [theme.breakpoints.up("sm")]: {
      gap: "30px",
    },
  },
  reelCardWrapper: {
    [theme.breakpoints.up("sm")]: {
      width: "230px",
      height: "330px",
    },
  },
  card: {
    [theme.breakpoints.up("sm")]: {
      marginInlineEnd: 16,
    },
    height: "fit-content",
  },
  skeletonContainer: {
    [theme.breakpoints.up("sm")]: {
      gap: "16px",
    },
  },
}));
