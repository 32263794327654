import { CreateDependentContainer } from "./containers/CreateDependentContainer/CreateDependentContainer";
import { DependentsContainer } from "./containers/DependentsContainer/DependentsContainer";

export const DependentsBaseRoute = "/dependents";

export const routes = {
  dependents: {
    route: "/",
    component: () => <DependentsContainer />,
    get absRoute() {
      return `${DependentsBaseRoute}${this.route}`;
    },
  },
  createDependent: {
    route: "/create",
    component: CreateDependentContainer,
    get absRoute() {
      return `${DependentsBaseRoute}${this.route}`;
    },
  },
};
