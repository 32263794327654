import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceWishlistAddItemMutationVariables = Types.Exact<{
  input: Types.MarketplaceWishlistInput;
}>;


export type MarketplaceWishlistAddItemMutation = { __typename?: 'Mutation', marketplaceWishlistAddItem?: { __typename?: 'MarketplaceWishlist', id: string } | null };


export const MarketplaceWishlistAddItemDocument = gql`
    mutation MarketplaceWishlistAddItem($input: MarketplaceWishlistInput!) {
  marketplaceWishlistAddItem(input: $input) {
    id
  }
}
    `;
export type MarketplaceWishlistAddItemMutationFn = Apollo.MutationFunction<MarketplaceWishlistAddItemMutation, MarketplaceWishlistAddItemMutationVariables>;

/**
 * __useMarketplaceWishlistAddItemMutation__
 *
 * To run a mutation, you first call `useMarketplaceWishlistAddItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceWishlistAddItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceWishlistAddItemMutation, { data, loading, error }] = useMarketplaceWishlistAddItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceWishlistAddItemMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceWishlistAddItemMutation, MarketplaceWishlistAddItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceWishlistAddItemMutation, MarketplaceWishlistAddItemMutationVariables>(MarketplaceWishlistAddItemDocument, options);
      }
export type MarketplaceWishlistAddItemMutationHookResult = ReturnType<typeof useMarketplaceWishlistAddItemMutation>;
export type MarketplaceWishlistAddItemMutationResult = Apollo.MutationResult<MarketplaceWishlistAddItemMutation>;
export type MarketplaceWishlistAddItemMutationOptions = Apollo.BaseMutationOptions<MarketplaceWishlistAddItemMutation, MarketplaceWishlistAddItemMutationVariables>;