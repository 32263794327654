import { memo } from "react";
import { useMeetingDuration } from "./useMeetingDuration";
import { CallInstance } from "./types";

type MeetingDuration = {
  call: CallInstance;
};

export const MeetingDuration = memo(({ call }: MeetingDuration) => {
  const startedAt = call.callInfo?.createdAt;
  const { duration } = useMeetingDuration({ startedAt });
  if (!startedAt) {
    return null;
  }

  return <>{duration}</>;
});
