import defaultProductImage from "@/assets/emptyProduct.png";
import { useHeaderContext } from "@/shared/context";
import { pickLocalizedValue } from "@toolkit/i18n";
import { compact, concat } from "lodash";
import queryString from "query-string";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useMarketplaceProductQuery } from "../../gql";
type QueryParams = {
  productId?: string;
};

export const useProductContainerHook = () => {
  const queryParams: QueryParams = queryString.parse(location.search);
  const productId = queryParams.productId || "";
  const { setHeaderTitle } = useHeaderContext();
  const { isAuthenticated } = useAuth();

  const { data, loading } = useMarketplaceProductQuery({
    variables: {
      id: productId,
      skipReviewSummary: !isAuthenticated,
    },
  });

  const productDetails = data?.marketplaceProduct;

  const _productImages = compact(concat([productDetails?.mainImageUrl], productDetails?.subImages));
  const productImages = _productImages?.length ? _productImages : [defaultProductImage];
  const relatedProducts = productDetails?.relatedProducts?.edges?.map(edge => edge?.node);

  useEffect(() => {
    setHeaderTitle(pickLocalizedValue(productDetails?.name, productDetails?.nameAr) || "");
    return () => {
      setHeaderTitle("");
    };
  }, [setHeaderTitle, productDetails]);

  const haveHowToUseTab = pickLocalizedValue(productDetails?.howToUse, productDetails?.howToUseAr);
  return {
    isLoading: loading,
    productDetails,
    productImages,
    haveHowToUseTab,
    relatedProducts,
  };
};
