import { SleepIcon, WakeupIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { Card, useTheme } from "@toolkit/ui";
import moment from "moment";
import { FC } from "react";
import { getCalculateCoordinates } from "../../others/sleepCalculatorUtils";
import { useSleepCalculatorClockWidgetStyles } from "./useSleepCalculatorClockWidgetStyle";

type SleepCalculatorClockWidgetProps = {
  radius?: number;
  center?: number;
  strokeWidth?: number;
  isBasedOnSleepTime?: boolean;
  sleepTime?: string;
  selectedTime?: string;
  label?: string;
  sleepCycles?: number;
};

export const SleepCalculatorClockWidget: FC<SleepCalculatorClockWidgetProps> = props => {
  const { selectedTime, label, sleepCycles, isBasedOnSleepTime, sleepTime } = props;
  const { radius = 120, center = 160, strokeWidth = 20 } = props;
  const { t } = useTranslation("consumer");
  const theme = useTheme();
  const { classes: styles } = useSleepCalculatorClockWidgetStyles();
  const sleepTimeMoment = moment(sleepTime, "HH:mm");
  const selectedTimeFormat = selectedTime ? moment(selectedTime, "HH:mm A").format("hh:mm A") : undefined;
  const startCalculationTime = !isBasedOnSleepTime ? sleepTimeMoment.format("hh:mm A") : selectedTimeFormat;
  const endCalculationTime = !isBasedOnSleepTime ? selectedTimeFormat : sleepTimeMoment.format("hh:mm A");

  const calculateCoordinates = getCalculateCoordinates(radius, center, startCalculationTime, endCalculationTime);
  const { wakeCoords, sleepCoords } = calculateCoordinates ?? {};

  const arc = isBasedOnSleepTime ? "0 0 1" : "1 0 0";

  return (
    <Card className={styles.root}>
      {
        <svg width={center * 2} height={center * 2}>
          <circle cx={center} cy={center} r={radius} stroke={theme.palette.primary.main} strokeWidth={strokeWidth} fill='none' />

          <path
            d={`M ${wakeCoords?.x} ${wakeCoords?.y} A ${radius} ${radius} ${arc} ${sleepCoords?.x} ${sleepCoords?.y}`}
            fill='none'
            stroke={theme.palette.gray[200]}
            strokeWidth={strokeWidth}
          />

          {wakeCoords?.x && wakeCoords?.y && (
            <foreignObject x={wakeCoords.x - 22.5} y={wakeCoords.y - 11.5} width='45' height='45'>
              {isBasedOnSleepTime ? <WakeupIcon /> : <SleepIcon />}
            </foreignObject>
          )}

          {sleepCoords?.x && sleepCoords?.y && (
            <foreignObject x={sleepCoords.x - 22.5} y={sleepCoords.y - 11.5} width='40' height='40'>
              {isBasedOnSleepTime ? <SleepIcon /> : <WakeupIcon />}
            </foreignObject>
          )}
          <line x1={center} y1={center - radius + 25} x2={center} y2={center - radius + 15} stroke='black' strokeWidth='2' />

          <text x={center} y={center - radius + 40} textAnchor='middle' fill='black'>
            12
          </text>

          <line x1={center + radius - 15} y1={center} x2={center + radius - 25} y2={center} stroke='black' strokeWidth='2' />
          <text x={center + radius - 35} y={center + 5} textAnchor='middle' fill='black'>
            3
          </text>

          <text x={center} y={center + radius - 30} textAnchor='middle' fill='black'>
            6
          </text>
          <line x1={center} y1={center + radius - 25} x2={center} y2={center + radius - 15} stroke='black' strokeWidth='2' />

          <line x1={center - radius + 25} y1={center} x2={center - radius + 15} y2={center} stroke='black' strokeWidth='2' />
          <text x={center - radius + 35} y={center + 5} textAnchor='middle' fill='black'>
            9
          </text>

          <text x={center} y={center - 10} textAnchor='middle' fontSize='25' fill='black' fontWeight='bold'>
            {selectedTimeFormat || "00:00"}
          </text>
          <text x={center} y={center + 15} textAnchor='middle' fontSize='16' fill='black'>
            {label}
          </text>
          <text x={center} y={center + 40} textAnchor='middle' fontSize='16' fill={theme.palette.primary.main}>
            {`${sleepCycles} ${t("Sleep Cycles")}`}
          </text>
        </svg>
      }
    </Card>
  );
};
