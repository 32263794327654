import { makeStyles } from "@toolkit/ui";

export const useWishlistHealthPackageCardStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    gap: "10px",
    width: "100%",
  },
  addButton: {
    margin: "0px",
    height: "48px",
    paddingInline: "17px",
    borderRadius: "24px",
  },
  addedDisplay: {
    margin: "0px",
    height: "48px",
    paddingInline: "30px",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.success[1200],
    color: theme.palette.common.white,
  },
  removeButton: {
    margin: "0px",
    height: "48px",
    paddingInline: "17px",
    borderRadius: "24px",
    "&:hover": {
      " & path": {
        fill: theme.palette.common.white,
      },
    },
  },
  itemAvatarContainer: {
    minWidth: "120px",
    flex: 1.5,
  },
  infoContainer: {
    maxWidth: "100%",
    flex: 4,
  },
}));
