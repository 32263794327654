import { useTranslation } from "@toolkit/i18n";
import { CallInstance, CallState } from "./types";
import { useCallStyles } from "./Call.styles";
import { Box, Button, CircularProgress, CustomIcon, IconButton, Rating, RatingIcon, Typography, useAddToast } from "@toolkit/ui";
import { SyntheticEvent, useCallback, useState } from "react";
import useCountDown from "./useCountDown";
import { useRatingScreenStyles } from "./RatingScreen.styles";
import { useUpdateMeetingRateMutation } from "../../gql/mutations";
import { useVisitRejoinCall } from "./useVisitRejoinCall";
import { Visit } from "@/schema/types";

type RatingScreenProps = {
  call: CallInstance;
  visit?: Visit;
  onClose?: () => void;
  isEndVisitOnLeave?: boolean;
};

export const RatingScreen = ({ call, visit, onClose, isEndVisitOnLeave }: RatingScreenProps) => {
  const { t } = useTranslation();
  const { succeeded } = useAddToast();
  const { classes: callClasses } = useCallStyles();
  const { classes: ratingClasses, theme } = useRatingScreenStyles();
  const { isCallRejoinable, handleRejoinCall, loading, handleVisitCallEnd } = useVisitRejoinCall(call, visit);

  const { callId, state, resetCallState: handleReset } = call;
  const { callState } = state;
  const [rate, setRate] = useState<number | null>(0);

  const handleClose = useCallback(async () => {
    if (isEndVisitOnLeave) {
      await handleVisitCallEnd();
    }
    handleReset?.();
    onClose?.();
  }, [isEndVisitOnLeave, handleReset, handleVisitCallEnd, onClose]);

  const { countDown } = useCountDown(30, handleClose);

  const [updateMeetingRate] = useUpdateMeetingRateMutation();

  const onChange = useCallback(
    async (event: SyntheticEvent, value: number | null) => {
      setRate(value);
      if (!callId || value === null) {
        return;
      }

      try {
        await updateMeetingRate({ variables: { meetingId: callId, meetingRate: value } });
      } catch (error) {
        console.error(error);
      } finally {
        succeeded(t("Thank you for your feedback!"));
        handleClose();
      }
    },
    [callId, handleClose, succeeded, t, updateMeetingRate]
  );

  return (
    <Box className={callClasses.root}>
      <Box className={callClasses.container}>
        <Box className={callClasses.header}>
          <Box className={ratingClasses.header}>
            <Box className={ratingClasses.counter}>{countDown}</Box>
          </Box>
          <IconButton size='small' onClick={handleClose}>
            <CustomIcon color={theme.palette.common.white} icon='xAlert' />
          </IconButton>
        </Box>

        <Box className={callClasses.body} width={"320px !important"}>
          <Box className={ratingClasses.root}>
            <Box mb={3}>
              <Typography fontSize={theme.mixins.fonts.fontSize.xl}>
                {callState === CallState.Ended ? t("The call is ended") : t("You left the call")}
              </Typography>
            </Box>
            <Box mb={3} gap={1}>
              <Button disabled={!isCallRejoinable} size='small' onClick={handleRejoinCall}>
                {loading ? <CircularProgress size={24} /> : t("Rejoin")}
              </Button>
              <Button variant='outlined' size='small' onClick={handleClose}>
                {t("Return to home screen")}
              </Button>
            </Box>
            <Box className={ratingClasses.content}>
              <Typography sx={{ pb: 2, textAlign: "initial" }}>{t("How was the call quality ?")}</Typography>

              <Rating
                value={rate}
                icon={<RatingIcon fill={theme.palette.common.white} />}
                emptyIcon={<RatingIcon fill={theme.palette.gray[800]} />}
                onChange={onChange}
                className={ratingClasses.input}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
