import { ComingSoonCard } from "@/domains/appointment/components/ComingSoonCard/ComingSoonCard";
import { Section } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography, useMediaQuery } from "@toolkit/ui";
import React from "react";
import { useCommonSectionsStyle } from "../../Styles/CommonSectionsStyle";
import { useStyle } from "./UserUpcomingAppointmentAndProgressSectionStyle";

export const UserUpcomingAppointmentAndProgressSection: React.FC = () => {
  const { classes, cx, theme } = useStyle();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("consumer");
  const { classes: commonClasses } = useCommonSectionsStyle();
  return (
    <Section
      hideHeader
      classes={{
        root: cx(commonClasses.root, classes.root, "section__root", "user-events-and-reads"),
        container: cx(commonClasses.container, classes.container),
      }}
    >
      <Box className={classes.cardsContainer}>
        <Box className={classes.nextAppointmentCardWrapper}>
          <Typography className={classes.title}>{t(isSmall ? "Upcoming" : "What’s Next For Me")}</Typography>
          <ComingSoonCard className={classes.card} />
        </Box>
      </Box>
    </Section>
  );
};
