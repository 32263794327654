import { ActivitiesItem } from "../ActivitiesItem/ActivitiesItem";
import moment from "moment";
import { FC, useMemo } from "react";
import { ActivityItemCodesEnum } from "../../types";
import { useGetHealthParametersQuery } from "../../gql";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useTheme } from "@toolkit/ui";
import { ClockIcon, FireIcon, StepIcon } from "@/shared/icons";
import { formatActivityValue } from "../../others/utils";

type ActivitiesItemsProps = {
  patientId: string;
  date?: string;
};

export const ActivitiesItems: FC<ActivitiesItemsProps> = ({ patientId, date: selectedDate }) => {
  const { t } = useTranslation("consumer");
  const date = useMemo(() => selectedDate || moment().format("YYYY-MM-DD"), [selectedDate]);

  const theme = useTheme();

  const { data, loading } = useGetHealthParametersQuery({
    variables: {
      first: 10,
      filter: {
        code: [ActivityItemCodesEnum.Daily_Step_Count, ActivityItemCodesEnum.Daily_Calories_Burned, ActivityItemCodesEnum.Active_Time],
      },
    },
  });

  const activeTime = data?.getHealthParameters?.edges?.find(edge => edge?.node?.code === ActivityItemCodesEnum.Active_Time)?.node;

  return (
    !loading && (
      <>
        <ActivitiesItem
          patientId={patientId}
          icon={<StepIcon />}
          label={t("Steps")}
          code={ActivityItemCodesEnum.Daily_Step_Count}
          color={theme.palette.primary.main}
          date={date}
          renderValue={value => formatActivityValue(ActivityItemCodesEnum.Daily_Step_Count, value?.value)}
        />
        <ActivitiesItem
          patientId={patientId}
          icon={<ClockIcon />}
          label={pickLocalizedValue(activeTime?.display, activeTime?.arabicDisplay) || t("Active Time")}
          color={theme.palette.success.main}
          code={ActivityItemCodesEnum.Active_Time}
          date={date}
          renderValue={value => formatActivityValue(ActivityItemCodesEnum.Active_Time, value?.value)}
        />
        <ActivitiesItem
          patientId={patientId}
          icon={<FireIcon />}
          label={t("Activity Calories")}
          color={theme.palette.warning.main}
          code={ActivityItemCodesEnum.Daily_Calories_Burned}
          date={date}
          renderValue={value => formatActivityValue(ActivityItemCodesEnum.Daily_Calories_Burned, value?.value)}
        />
      </>
    )
  );
};
