import { useTranslation } from "@/i18n/i18n.config";
import { Box, CustomInfiniteScroll, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useStyles } from "./MyPreviousJourneysContainerStyle";
import { useGuidedCareJourneyListQuery } from "../../gql";
import { EmptyMessageLayout } from "@/shared/components";
import { DefaultEmptyIcon } from "@/shared/icons";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useContainerHeight } from "@/shared/hooks";
import { CareJourneyCard } from "../CareJourneyCard/CareJourneyCard";
import { GuidedCareJourney, GuidedCareJourneyStatus } from "@/schema/types";
import { CareJourneyCardSkeleton } from "../../component/CareJouneyCardSkeleton";

export const MyPreviousJourneysContainer: FC = () => {
  const { t } = useTranslation("consumer");
  const { classes } = useStyles();
  const { containerRef, containerHeight } = useContainerHeight();

  const {
    data: guidedCareJourneys,
    loading,
    hasMore,
    handleFetchMore: onFetchMoreData,
    refetch: handleRefetch,
  } = useRelayStyleConnection({
    useQuery: useGuidedCareJourneyListQuery,
    variables: {
      first: 10,
      filter: {
        statuses: [GuidedCareJourneyStatus.Completed, GuidedCareJourneyStatus.Cancelled],
      },
    },
  });

  return (
    <Box className={classes.container}>
      <Typography variant='h6' className={classes.title}>
        {t("Care Journeys History")}
      </Typography>
      {loading &&
        !guidedCareJourneys.length &&
        Array(3)
          .fill(1)
          .map((_, index) => (
            <Box
              key={index}
              sx={{
                marginInline: 1,
              }}
            >
              <CareJourneyCardSkeleton />
            </Box>
          ))}
      <Box ref={containerRef} className={classes.content}>
        <CustomInfiniteScroll
          dataLength={Number(guidedCareJourneys?.length) || 1}
          onFetchMore={onFetchMoreData}
          hasMore={hasMore}
          loader={<CareJourneyCardSkeleton />}
          height={(containerHeight || 10) - 15}
          onRefresh={handleRefetch}
        >
          {
            <>
              {!loading && !guidedCareJourneys.length && (
                <Box className={classes.emptyPageContainer}>
                  <EmptyMessageLayout icon={<DefaultEmptyIcon />} message={t("No Care Journeys Found")} />
                </Box>
              )}

              {guidedCareJourneys?.map(careJourney => {
                return <CareJourneyCard key={careJourney.id} careJourney={careJourney as GuidedCareJourney} />;
              })}
            </>
          }
        </CustomInfiniteScroll>
      </Box>
    </Box>
  );
};
