import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React, { FC } from "react";

export const LabIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='29.75' height='26.218' viewBox='0 0 29.75 26.218' {...props}>
      <g id='icons8-drugs' transform='translate(-2.9 -4.85)'>
        <path
          id='Path_15015'
          data-name='Path 15015'
          d='M10.753,33.955a4.773,4.773,0,0,1-2.972-1.036,4.821,4.821,0,0,1-.8-6.764l6.181-7.833A1.032,1.032,0,0,1,14.787,19.6L8.605,27.435a2.751,2.751,0,0,0,4.319,3.408l.215-.271a1.032,1.032,0,1,1,1.616,1.285l-.213.268a4.786,4.786,0,0,1-3.215,1.8A4.9,4.9,0,0,1,10.753,33.955Z'
          transform='translate(0 -4.454)'
          fill={fill}
        />
        <path
          id='Path_15016'
          data-name='Path 15016'
          d='M19.912,25.625a1.033,1.033,0,0,1-.81-1.672L29.751,10.467l-4.32-3.414-4.488,5.681a1.032,1.032,0,0,1-1.62-1.28L24.45,4.962a1.032,1.032,0,0,1,1.45-.17l5.94,4.694a1.032,1.032,0,0,1,.17,1.45l-11.288,14.3A1.031,1.031,0,0,1,19.912,25.625Z'
          transform='translate(-4.03 -0.29)'
          fill={fill}
        />
        <path
          id='Line_1822'
          data-name='Line 1822'
          d='M7.1,6.539a1.028,1.028,0,0,1-.639-.222L-1.107.343a1.032,1.032,0,0,1-.17-1.45,1.032,1.032,0,0,1,1.45-.17L7.737,4.7A1.033,1.033,0,0,1,7.1,6.539Z'
          transform='translate(20.886 5.14)'
          fill={fill}
        />
        <path
          id='Line_1823'
          data-name='Line 1823'
          d='M9.031.565h-9.5A1.032,1.032,0,0,1-1.5-.468,1.032,1.032,0,0,1-.468-1.5h9.5A1.032,1.032,0,0,1,10.064-.468,1.032,1.032,0,0,1,9.031.565Z'
          transform='translate(16.57 12.271)'
          fill={fill}
        />
        <path
          id='Path_15017'
          data-name='Path 15017'
          d='M38.1,24.532a3.1,3.1,0,0,1-3.1-3.1c0-.5.167-1.423,1.609-3.734a1.754,1.754,0,0,1,2.978,0c1.425,2.3,1.608,3.2,1.608,3.732a1.032,1.032,0,1,1-2.065,0A6.361,6.361,0,0,0,38.1,19.227a7.1,7.1,0,0,0-1.032,2.209A1.033,1.033,0,0,0,38.1,22.468a1.032,1.032,0,1,1,0,2.065Z'
          transform='translate(-9.054 -4.125)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
