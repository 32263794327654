import { makeStyles } from "@toolkit/ui";

export const useMakeWaitingForPharmacySelectionCardStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  skeletonRectangle: {
    borderRadius: 3,
  },
}));
