import { PropsWithChildren } from "react";
import { InitConfig } from "./types";
import { useAntMediaContext, AntMediaContext } from "./AntMediaContext";
import { AntMediaAudio } from "./AntMediaAudio";

type AntMediaProviderProps = PropsWithChildren<{ config: InitConfig }>;

export const AntMediaProvider = ({ children, config }: AntMediaProviderProps) => {
  const value = useAntMediaContext(config);
  const { remoteStreams, speakerMuted } = value;
  return (
    <AntMediaContext.Provider value={value}>
      {Object.keys(remoteStreams).map(id => (
        <AntMediaAudio key={id} id={id} isMuted={speakerMuted} stream={remoteStreams[id]} />
      ))}
      {children}
    </AntMediaContext.Provider>
  );
};
