import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidatePrescriptionMutationVariables = Types.Exact<{
  input: Types.ValidatePrescriptionInput;
}>;


export type ValidatePrescriptionMutation = { __typename?: 'Mutation', validatePrescription?: { __typename?: 'ValidatePrescription', allowedDeliveryTypes: Array<Types.DeliveryType>, prescription: { __typename?: 'Prescription', id: string, dispenseStatus: Types.PrescriptionDispenseStatus, medications: Array<{ __typename?: 'Medication', id: number, display?: string | null, instructions?: string | null, arabicInstructions?: string | null }>, activeOrders?: Array<{ __typename?: 'Order', id: string, status: Types.OrderStatus } | null> | null } } | null };


export const ValidatePrescriptionDocument = gql`
    mutation ValidatePrescription($input: ValidatePrescriptionInput!) {
  validatePrescription(input: $input) {
    prescription {
      id
      dispenseStatus
      medications {
        id
        display
        instructions
        arabicInstructions
      }
      activeOrders {
        id
        status
      }
    }
    allowedDeliveryTypes
  }
}
    `;
export type ValidatePrescriptionMutationFn = Apollo.MutationFunction<ValidatePrescriptionMutation, ValidatePrescriptionMutationVariables>;

/**
 * __useValidatePrescriptionMutation__
 *
 * To run a mutation, you first call `useValidatePrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidatePrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validatePrescriptionMutation, { data, loading, error }] = useValidatePrescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidatePrescriptionMutation(baseOptions?: Apollo.MutationHookOptions<ValidatePrescriptionMutation, ValidatePrescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidatePrescriptionMutation, ValidatePrescriptionMutationVariables>(ValidatePrescriptionDocument, options);
      }
export type ValidatePrescriptionMutationHookResult = ReturnType<typeof useValidatePrescriptionMutation>;
export type ValidatePrescriptionMutationResult = Apollo.MutationResult<ValidatePrescriptionMutation>;
export type ValidatePrescriptionMutationOptions = Apollo.BaseMutationOptions<ValidatePrescriptionMutation, ValidatePrescriptionMutationVariables>;