import { makeStyles } from "@toolkit/ui";

export const useRatingOverviewStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    color: theme.palette.common.black,
  },
  ratingAvgContainer: {
    paddingBlock: "10px",
    display: "flex",
    alignItems: "center",
  },
  averageRatingValue: {
    fontSize: theme.mixins.fonts.fontSize.xxxl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  averageRatingLabel: {
    marginTop: "10px",
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  total: {
    color: theme.palette.text.primary,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    marginTop: "10px",
  },
  ratingCountsContainer: {
    width: "100%",
    marginTop: theme.spacing(2),
    paddingInlineEnd: "10px",
  },
  ratingCountItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  ratingCountLabel: {
    display: "flex",
    alignItems: "center",
    width: "40px",
    fontSize: theme.mixins.fonts.fontSize.xs,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.text.primary,
    gap: 5,
    "& svg": {
      width: "17px",
      height: "17px",
    },
  },
  ratingCountProgress: {
    flex: 1,
  },
  noRatingsText: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
}));
