import { FC, useEffect, useMemo, useRef } from "react";
import { CallParticipant } from "./types";
import { makeStyles } from "@toolkit/ui";
import { useAntMedia } from "./useAntMedia";

export type AntMediaViewProps = {
  participant: CallParticipant;
};

const useStyles = makeStyles()({
  root: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
});

export const AntMediaView: FC<AntMediaViewProps> = ({ participant }) => {
  const { classes } = useStyles();
  const videoRef = useRef<HTMLVideoElement>(null);
  const { remoteStreams, videoTracks, localStream } = useAntMedia();
  const stream = useMemo(() => {
    if (participant.isCurrentUser) {
      return localStream;
    }

    if (!remoteStreams || !participant.id) {
      return undefined;
    }

    const streamId = videoTracks?.[participant.id] ?? participant.id;
    return remoteStreams[streamId];
  }, [localStream, participant.id, participant.isCurrentUser, remoteStreams, videoTracks]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.srcObject = stream ?? null;
  }, [stream]);

  return <video ref={videoRef} autoPlay className={classes.root} muted />;
};
