import { CardProps, Typography, Box } from "@toolkit/ui";
import { ReactNode } from "react";
import { useDetailCardStyles } from "./DetailCard.styles";
import { CustomCard } from "../Card";
import { omit } from "lodash";

interface DetailCardProps extends Omit<CardProps, "title" | "variant" | "content" | "classes"> {
  title: string;
  variant?: "singleInfo" | "multipleInfo" | "singleInfoWithIcon";
  actions?: ReactNode;
  classes?: Partial<ReturnType<typeof useDetailCardStyles>["classes"]>;
}

export interface MultipleInfoDetailCardProps extends DetailCardProps {
  variant: "multipleInfo";
  items: Array<{ label: string; value: string }>;
  actions?: ReactNode;
  startIcon?: ReactNode;
}

export interface SingleInfoDetailCardProps extends DetailCardProps {
  variant?: "singleInfo" | "singleInfoWithIcon";
  content: string | ReactNode;
  actions?: ReactNode;
  startIcon?: ReactNode;
}

export const DetailCard: React.FC<MultipleInfoDetailCardProps | SingleInfoDetailCardProps> = props => {
  const { title, variant = "singleInfo", className, startIcon, actions, classes: _classes, ...cardProps } = props;
  const { classes, cx } = useDetailCardStyles();

  return (
    <CustomCard
      {...omit(cardProps, "content")}
      className={cx(classes.card, variant === "singleInfoWithIcon" && classes.cardForSingleInfoWithIcon, className)}
    >
      {actions && <Box className={cx(classes.actionsContainer, _classes?.actionsContainer)}>{actions}</Box>}
      {startIcon}
      <Box
        className={cx(
          variant !== "singleInfoWithIcon" ? classes.contentContainer : classes.iconContentContainer,
          _classes?.contentContainer
        )}
      >
        <Typography className={cx(classes.title, _classes?.title)}>{title}</Typography>
        {["singleInfo", "singleInfoWithIcon"].includes(variant) ? (
          <Typography className={cx(classes.content, _classes?.content)}>{(props as SingleInfoDetailCardProps).content}</Typography>
        ) : (
          (props as MultipleInfoDetailCardProps).items.map(item => (
            <Typography key={item.label + item.value} className={cx(classes.content, _classes?.content)}>
              <Typography component={"span"} className={cx(classes.labelSpan, _classes?.labelSpan)}>
                {item.label}:{" "}
              </Typography>
              {item.value}
            </Typography>
          ))
        )}
      </Box>
    </CustomCard>
  );
};
