import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestOtpForLoginMutationVariables = Types.Exact<{
  phoneNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type RequestOtpForLoginMutation = { __typename?: 'Mutation', requestOtp?: { __typename?: 'RequestOTP', sessionToken?: string | null, accountErrors: Array<{ __typename?: 'AccountError', message?: string | null, field?: string | null, code: Types.AccountErrorCode }> } | null };


export const RequestOtpForLoginDocument = gql`
    mutation requestOtpForLogin($phoneNumber: String) {
  requestOtp(phoneNumber: $phoneNumber, operation: LOGIN_BY_OTP) {
    accountErrors {
      message
      field
      code
    }
    sessionToken
  }
}
    `;
export type RequestOtpForLoginMutationFn = Apollo.MutationFunction<RequestOtpForLoginMutation, RequestOtpForLoginMutationVariables>;

/**
 * __useRequestOtpForLoginMutation__
 *
 * To run a mutation, you first call `useRequestOtpForLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOtpForLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOtpForLoginMutation, { data, loading, error }] = useRequestOtpForLoginMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useRequestOtpForLoginMutation(baseOptions?: Apollo.MutationHookOptions<RequestOtpForLoginMutation, RequestOtpForLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestOtpForLoginMutation, RequestOtpForLoginMutationVariables>(RequestOtpForLoginDocument, options);
      }
export type RequestOtpForLoginMutationHookResult = ReturnType<typeof useRequestOtpForLoginMutation>;
export type RequestOtpForLoginMutationResult = Apollo.MutationResult<RequestOtpForLoginMutation>;
export type RequestOtpForLoginMutationOptions = Apollo.BaseMutationOptions<RequestOtpForLoginMutation, RequestOtpForLoginMutationVariables>;