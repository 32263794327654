import {
  HealthPackageCard,
  HealthPackageCardSkeleton,
  useMarketplaceHealthPackagesForYouQuery,
  useMarketplaceHealthPackagesQuery,
} from "@/domains/marketplace-health-packages";
import { EmptyMessageDefault } from "@/shared/components";
import { i18n } from "@toolkit/i18n";
import { Box, CardsContainer, CardsContainerProps, useMediaQuery } from "@toolkit/ui";
import React from "react";
import { useAuth } from "react-oidc-context";
import { useHealthPackagesContainerStyles } from "./HealthPackagesContainerStyle";

type HealthPackagesContainerProps = Partial<Omit<CardsContainerProps, "classes">> & {
  classes?: Partial<ReturnType<typeof useHealthPackagesContainerStyles>["classes"]>;
  countOfPackages?: number;
};

export const HealthPackagesContainer: React.FC<HealthPackagesContainerProps> = props => {
  const { classes: _classes, countOfPackages = 9, ...rest } = props;
  const { classes, cx, theme } = useHealthPackagesContainerStyles();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAuthenticated } = useAuth();
  const { data: packagesData, loading: _loading } = useMarketplaceHealthPackagesForYouQuery({
    variables: {
      first: countOfPackages,
    },
    skip: !isAuthenticated,
  });
  const { data, loading } = useMarketplaceHealthPackagesQuery({
    variables: {
      first: countOfPackages,
    },
    skip: isAuthenticated,
  });
  const isLoading = loading || _loading;
  const healthPackages =
    data?.marketplaceHealthPackages?.edges?.map(edge => edge?.node) ||
    packagesData?.marketplaceHealthPackagesForYou?.edges?.map(edge => edge?.node) ||
    [];

  return (
    <CardsContainer
      classes={{
        cardsContainerClassName: cx(classes.cardsContainerClassName, _classes?.cardsContainerClassName),
        navBtnClassName: cx(classes.navBtnClassName, _classes?.navBtnClassName),
        navBtnContainerClassName: cx(classes.navBtnContainerClassName, _classes?.navBtnContainerClassName),
        cardsContainer: cx(classes.cardsContainer, _classes?.cardsContainer),
        cardsUpperContainer: cx(classes.cardsUpperContainer, _classes?.cardsUpperContainer),
        card: cx(classes.card, _classes?.card),
        skeletonContainer: cx(classes.skeletonContainer, _classes?.skeletonContainer),
      }}
      isLoadingData={isLoading}
      countOfSkeletons={countOfPackages}
      CardSkeletonComponent={
        <Box className={cx(classes.healthPackagesCardWrapper, _classes?.healthPackagesCardWrapper)}>
          <HealthPackageCardSkeleton />
        </Box>
      }
      cardsScrollAmount={2}
      hideNavBtns={isSmall}
      EmptyCardComponent={<EmptyMessageDefault message={i18n.t("No services available.", { ns: "consumer" })} />}
      cards={healthPackages?.map(healthPackage => (
        <Box key={healthPackage?.id} className={cx(classes.healthPackagesCardWrapper, _classes?.healthPackagesCardWrapper)}>
          <HealthPackageCard healthPackage={healthPackage} />
        </Box>
      ))}
      {...rest}
    />
  );
};
