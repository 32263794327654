import { createContext } from "react";

type AppAuthContext = {
  isAuthenticated: boolean;
  setAppToken: (token: string) => void;
  getAppToken: () => string | null;
  askToLogin: () => void;
  doLogout: () => void;
  handleSwitchUser: (userId: string) => Promise<any>;
};

export const AuthContext = createContext<AppAuthContext>({
  isAuthenticated: false,
  setAppToken: token => {
    console.log(token);
  },
  handleSwitchUser: () => Promise.resolve(undefined),
  getAppToken: () => null,
  askToLogin: () => {},
  doLogout: () => {},
});
