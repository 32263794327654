import { useMeProfileInfoQuery } from "@/domains/patient/gql";
import { CustomUserAvatar } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, Typography } from "@toolkit/ui";
import { Link } from "react-router-dom";
import useStyles from "./Profile.styles";
import { routes } from "@/domains/Dependents";
import { useCallback, useState } from "react";
import { useAuth } from "@/shared/context";

export const Profile: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation("consumer");
  const [isSwitching, setSwitching] = useState(false);
  const { handleSwitchUser } = useAuth();
  const { data, loading } = useMeProfileInfoQuery({
    fetchPolicy: "cache-first",
  });

  const me = data?.me;

  const handleSwitchToParent = useCallback(async () => {
    if (!me?.parentUser?.id) return;
    try {
      setSwitching(true);
      await handleSwitchUser(me?.parentUser?.id!);
      setSwitching(false);
    } catch (error) {
      console.error(error);
      setSwitching(false);
    }
  }, [handleSwitchUser, me?.parentUser?.id]);

  return (
    <Box className={classes.container}>
      {loading ? (
        <CircularProgress className={classes.circularProgress} />
      ) : (
        <>
          <CustomUserAvatar key={"image-sidebar-image"} id='image-sidebar-image' src={me?.photo} width={80} height={80} />
          <Box>
            <Button className={classes.button} endIcon={<Box className={classes.buttonIcon}></Box>}>
              {me?.fullName}
            </Button>
            {me?.dependents?.length && (
              <Link className={classes.dependentLink} to={routes.dependents.absRoute}>
                {t("{{dependentCount}} dependents", { dependentCount: me?.dependents?.length })}
              </Link>
            )}
            {me?.parentUser?.id && !isSwitching ? (
              <Typography className={classes.exitButton} onClick={handleSwitchToParent}>
                {t("Exit Child Account")}
              </Typography>
            ) : (
              <CircularProgress size='small' color='error' />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
