import { OTPInput } from "@/shared/components";
import { Trans, useTranslation } from "@toolkit/i18n";
import { Box, Typography, useTheme, VerificationInputRef } from "@toolkit/ui";
import { FC, useRef } from "react";
import { useSignInOTPVerificationStyles } from "./SignInOTPVerification.styles"; // Assuming styles are separated into a hook
import { useOTPVerification } from "./useOTPVerification";

type SignInOTPVerificationProps = {
  mobile: string;
  handleSessionToken: (sessionToken: string) => void;
  handleOtpChange: (otp: string) => void;
};

export const SignInOTPVerification: FC<SignInOTPVerificationProps> = props => {
  const { mobile, handleSessionToken, handleOtpChange } = props;
  const theme = useTheme();
  const { t } = useTranslation("consumer");

  const { resetRemainingTime, isDisabled, handleChange, handleResend } = useOTPVerification({
    initialResetTime: 30,
    mobile,
    handleSessionToken,
    handleOtpChange,
  });
  const ref = useRef<VerificationInputRef>(null);
  const { classes: otpClasses } = useSignInOTPVerificationStyles({ isDisabled });

  return (
    <>
      <Typography className={otpClasses.verificationTitle}>{t("Verification")}</Typography>
      <Typography className={otpClasses.verificationDescription}>
        <Trans
          i18nKey='Please enter the 4-digit verification code that was sent to <bold>{{ContactInformation}}</bold>'
          components={{
            bold: <Typography fontWeight={theme.mixins.fonts.fontWeight.bold} display='inline' />,
          }}
          values={{ ContactInformation: mobile }}
        />
      </Typography>
      <Box className={otpClasses.otpContainer}>
        <OTPInput ref={ref} onChange={handleChange} />
      </Box>
      <Box className={otpClasses.resendContainer}>
        <Typography fontSize={theme.mixins.fonts.fontSize.lg} sx={{ display: "inline" }}>
          {t("Didn't receive an OTP? ")}
        </Typography>
        <Typography variant='button' onClick={handleResend} className={otpClasses.resendButton} component='span'>
          {t("Resend {{resetRemainingTime}}", { resetRemainingTime })}
        </Typography>
      </Box>
    </>
  );
};
