import React, { PropsWithChildren } from "react";
import { MarketplaceCartProvider } from "./marketplaceCartContext/MarketplaceCartContext";
import { WishlistProvider } from "./marketplaceWishlistContext/MarketplaceWishlistContext";

export const MarketplaceProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <MarketplaceCartProvider>
      <WishlistProvider>{children}</WishlistProvider>
    </MarketplaceCartProvider>
  );
};
