/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-statements */
import { useCallback, useState } from "react";
import { ConnectionState, ExecutedResponse } from "./types";
import { useZoom } from "./useZoom";
import { formatExecutedFailure, getUserMedia, startVideoStream } from "./utils";

type MutedParam = boolean | ((muted: boolean) => boolean);

export const useMuteVideo = () => {
  const { currentUser, mediaStream, connectionState } = useZoom();
  const [localStream, setLocalStream] = useState<MediaStream>();
  const { userId, bVideoOn } = currentUser || { bVideoOn: localStream && !localStream.getVideoTracks()?.[0]?.muted };

  const muteVideo = useCallback(
    async (value: MutedParam, options: any = {}): Promise<ExecutedResponse> => {
      try {
        const muted = typeof value === "function" ? value(!bVideoOn) : value;
        if (connectionState !== ConnectionState.Connected) {
          if (muted) {
            localStream?.getTracks().forEach(t => t.stop());
            setLocalStream(undefined);
            return { success: true };
          } else {
            const result = await getUserMedia({ video: true });
            setLocalStream(result.success ? result.stream : undefined);
            return result;
          }
        }

        if (!userId) {
          return { type: "USER_NOT_CONNECTED" };
        }
        if (!mediaStream) {
          return { type: "CLIENT_NOT_READY" };
        }

        const enabled = mediaStream.isCapturingVideo();
        if (enabled) {
          if (muted) {
            await mediaStream.stopVideo();
          } else {
            const cameraId = options?.constraints?.video?.deviceId;
            if (cameraId) {
              await mediaStream.switchCamera(cameraId);
            }
          }
        } else if (!muted) {
          const startVideoResult = await startVideoStream(mediaStream);
          if (!startVideoResult.success) {
            console.error("Meeting: Unable start video due to the error", startVideoResult);
            return startVideoResult;
          }
        }

        return { success: true };
      } catch (error) {
        console.error("Meeting: Unable mute video due to the error", error);
        return formatExecutedFailure(error);
      }
    },
    [bVideoOn, connectionState, userId, mediaStream, localStream]
  );

  return { muteVideo, videoMuted: bVideoOn !== true, videoStream: localStream };
};
