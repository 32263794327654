import { FC } from "react";
import { OrderTrackingViewProps } from "../../others/types";
import { WaitingPharmacyPage } from "./WaitingPharmacySelection";
import { OrderStatus, PrescriptionDispenseStatus } from "@/schema/types";
import { OrderConfirmedPage } from "./OrderConfirmedPage";
import { WaitingErxHubApprovalPage } from "./WaitingErxHubApproval";
import { OrderPaymentPage } from "./OrderPaymentPage";
import { OrderOnTheWayPage } from "./OrderOnTheWayPage";
import { OrderDeliveredPage } from "./OrderDeliveredPage";
import { OrderMedicationPage } from "./OrderMedicationPage";

export const OrderTrackingViewsRenderer: FC<OrderTrackingViewProps> = props => {
  const { order, prescription } = props;
  const getOrderView = () => {
    switch (order?.status) {
      case OrderStatus.NewRequest:
      case OrderStatus.WaitingProviderSelection:
        return <WaitingPharmacyPage {...props} />;
      case OrderStatus.Allocated:
        return <OrderConfirmedPage {...props} />;
      case OrderStatus.WaitingErxHubApproval:
        return <WaitingErxHubApprovalPage {...props} />;
      case OrderStatus.PaymentPending:
      case OrderStatus.PaymentFailed:
      case OrderStatus.ErxHubFullyApproved:
      case OrderStatus.ErxHubPartiallyApproved:
        return <OrderPaymentPage {...props} />;
      case OrderStatus.WaitingErxHubClaimApproval:
      case OrderStatus.ErxHubClaimApproved:
      case OrderStatus.PaymentSucceeded:
      case OrderStatus.Dispensed:
      case OrderStatus.ReadyForCustomerPickup:
      case OrderStatus.PaymentOnArrive:
      case OrderStatus.OutForDelivery:
        return <OrderOnTheWayPage {...props} />;
      case OrderStatus.Delivered:
        return <OrderDeliveredPage {...props} />;
      case OrderStatus.CanceledByConsumer:
        return <OrderMedicationPage {...props} />;
      default:
        return prescription?.dispenseStatus === PrescriptionDispenseStatus.Delivered ? (
          <OrderDeliveredPage {...props} />
        ) : (
          <OrderMedicationPage {...props} />
        );
    }
  };
  return getOrderView();
};
