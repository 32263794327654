import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    minHeight: "380px",
    height: "max-content",
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      height: "fit-content",
      "& .section__container": {
        gap: "10px",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "& .title": {
        marginBottom: "20px",
      },
    },
  },
  container: {
    paddingInline: "10px",
    alignContent: "start",
    width: "100%",
    height: "100%",
  },
  categoriesContainer: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
      gap: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      "& > :nth-last-child(-n+2)": {
        display: "none",
      },
    },
    [theme.breakpoints.down("xl")]: {
      gap: "10px",
    },
  },
  seeAllButton: {
    width: "120px",
    height: "120px",
  },
  item__button: {
    [theme.breakpoints.up("sm")]: {
      width: "120px",
      height: "120px",
      "&& svg": {
        width: "45px",
        height: "45px",
      },
    },
  },
  item__root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "120px",
      maxWidth: "120px",
    },
  },
  item__icon: {
    [theme.breakpoints.up("sm")]: {
      width: "45px",
      height: "45px",
    },
  },
}));
