import { Box, CircularProgress, CircularProgressProps, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

export interface ProgressCircleProps extends Omit<CircularProgressProps, "value" | "color"> {
  value: number;
  color?: string;
  showText?: boolean;
  maxValue?: number;
  size?: number;
}

export const ProgressCircle: FC<ProgressCircleProps> = ({ value, color, showText = true, maxValue = 75, size: _size, ...rest }) => {
  const theme = useTheme();
  const size = _size || 200;
  const radius = size / 2 - 10;

  const totalArc = (maxValue / 100) * 360;
  const degrees = (value / 100) * totalArc - 90 + 20;
  const angle = (degrees * Math.PI) / 180;

  const x = radius * Math.cos(angle);
  const y = radius * Math.sin(angle);
  const textRotationAngle = ((angle + Math.PI / 2) * 180) / Math.PI - 180;

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        rotate: "180deg",
      }}
    >
      <CircularProgress
        variant='determinate'
        value={maxValue}
        size={size}
        thickness={3}
        sx={{ color: theme.palette.primary[50], strokeLinecap: "round", ...rest?.sx }}
        {...rest}
      />

      <Box top={0} position='absolute' display='flex' alignItems='center' justifyContent='center'>
        <CircularProgress
          size={size}
          thickness={3}
          variant='determinate'
          value={(value / 100) * maxValue}
          sx={{
            color: color || theme.palette.primary.main,
            strokeLinecap: "round",
          }}
          {...rest}
        />
      </Box>

      {showText && (
        <Box sx={{ position: "absolute", width: "100%", height: "100%", pointerEvents: "none" }}>
          <Typography
            variant='body2'
            sx={{
              position: "absolute",
              fontWeight: theme.mixins.fonts.fontWeight.medium,
              left: `calc(50% + ${x}px)`,
              top: `calc(50% + ${y}px)`,
              transform: `translate(-50%, -50%) rotate(${textRotationAngle - 180}deg)`,
              color: theme.palette.text.primary,
              fontSize: theme.mixins.fonts.fontSize.xxs,
            }}
          >
            {value.toFixed(0)}%
          </Typography>
        </Box>
      )}
    </Box>
  );
};
