import { z } from "zod";
import { healthProgramMemberSchema } from "../../others/utils";
import { i18n } from "@toolkit/i18n";

export const EPrescriptionReferenceSearchFormSchema = z
  .object({
    ePrescriptionReference: z.string().min(1),
    healthProgramMemberAutocomplete: healthProgramMemberSchema.optional(),
    healthProgramMemberText: z.string().optional(),
    variant: z.enum(["autocomplete", "text"]).optional(),
  })
  .superRefine((data, ctx) => {
    if (data.variant === "text" && !data.healthProgramMemberText) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "consumer" }),
        path: ["healthProgramMemberText"],
      });
    }
    if (data.variant === "autocomplete" && !data?.healthProgramMemberAutocomplete?.value?.id!) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "consumer" }),
        path: ["healthProgramMemberAutocomplete"],
      });
    }
  });

export type IEPrescriptionReferenceSearchFormValues = z.infer<typeof EPrescriptionReferenceSearchFormSchema>;
export const ePrescriptionReferenceSearchFormDefaultValues: Partial<IEPrescriptionReferenceSearchFormValues> = {
  ePrescriptionReference: "",
  healthProgramMemberAutocomplete: undefined,
  healthProgramMemberText: "",
  variant: "autocomplete",
};
