import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type AddressFragmentFragment = { __typename?: 'Address', id: string, streetAddress1: string, streetAddress2: string, phone?: string | null, name?: string | null, nameAr?: string | null, buildingNumber?: string | null, unitNumber?: string | null, isDefaultShippingAddress?: boolean | null, isDefaultBillingAddress?: boolean | null, area: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lat?: number | null, lng?: number | null } | null };

export const AddressFragmentFragmentDoc = gql`
    fragment AddressFragment on Address {
  id
  streetAddress1
  streetAddress2
  phone
  name
  nameAr
  buildingNumber
  unitNumber
  isDefaultShippingAddress
  isDefaultBillingAddress
  area
  city {
    id
    name
    nameAr
  }
  coordinates {
    lat
    lng
  }
}
    `;