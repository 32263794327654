import { makeStyles } from "@toolkit/ui";

export const usePatientShareAmountCardStyles = makeStyles()(theme => ({
  root: {
    padding: 20,
  },
  emptyMessageLayout: {},
  iconContainer: {
    "& svg": {
      width: 130,
      height: 130,
    },
  },
  messageContainer: {
    marginTop: 20,
  },
  subMessageContainer: {
    fontWeight: theme.mixins.fonts.fontWeight.normal,
    color: theme.palette.text.primary,
  },
  secondary: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.lg,
    color: theme.palette.secondary.main,
    display: "block",
  },
}));
