import { GenericFilterForm } from "@/shared/components";
import { VendorsAutocomplete } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import {
  healthPackagesListFiltersFormDefaultValues,
  HealthPackagesListFiltersFormSchema,
  IHealthPackagesListFiltersFormValues,
} from "../HealthPackagesListFiltersFormSchema";
import { HealthPackagesListFiltersFormProps } from "../types";

export const VendorFilterForm: React.FC<HealthPackagesListFiltersFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IHealthPackagesListFiltersFormValues>
      schema={HealthPackagesListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='vendor'
      dialogTitle={t("Provider")}
      formLabel={t("Provider")}
      render={name => <VendorsAutocomplete name={name} />}
      defaultValues={healthPackagesListFiltersFormDefaultValues}
    />
  );
};
