import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { MarketplaceOrderDetails } from "../../others/types";
import { InfoCardWithHeader } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { useMarketplaceOrderGeneralInfoCardStyle } from "../MarketplaceOrderGeneralInfoCards/useMarketplaceOrderGeneralInfoCards.styles";
import { useOrderDetailsCardStyles } from "../OrderDetailsPaymentSummary/useOrderDetailsCardStyles.styles";
import { calculateOrderPrice } from "@/domains/marketplace/utils";
import { HealthPackageIcon } from "@/shared/icons";
import { MarketplaceOrderType } from "@/schema/types";
import { OrderHealthPackagesCard } from "@/domains/marketplace/components/OrderHealthPackagesCard/OrderHealthPackagesCard";

type OrderDetailsHealthPackagesCardProps = {
  order: MarketplaceOrderDetails;
};

export const OrderDetailsHealthPackagesCard: FC<OrderDetailsHealthPackagesCardProps> = props => {
  const { order } = props;
  const { classes } = useOrderDetailsCardStyles();
  const { classes: headerClasses, cx } = useMarketplaceOrderGeneralInfoCardStyle();
  const { t } = useTranslation("consumer");
  return order.type === MarketplaceOrderType.HealthPackage ? (
    <InfoCardWithHeader
      mt={"10px"}
      classes={{
        header: cx(classes.headerContainer, classes.headerCentredContainer),
        contentContainer: classes.contentRoot,
      }}
      header={
        <>
          <Box className={headerClasses.iconContainer}>
            <HealthPackageIcon />
          </Box>
          <Typography className={classes.headerMainLabel}>{t("Health Packages")}</Typography>
        </>
      }
    >
      <OrderHealthPackagesCard
        isTitleHidden
        classes={{
          root: classes.contentRoot,
        }}
        healthPackages={order?.orderLines?.map(item => ({
          ...item.healthPackage,
          id: item?.healthPackage?.id || "",
          quantity: item.quantity,
          priceForQuantity: calculateOrderPrice(item?.unitPriceNetAmount, item?.unitPriceDiscount, item.quantity),
          priceInfo: calculateOrderPrice(item?.unitPriceNetAmount, item?.unitPriceDiscount),
        }))}
      />
    </InfoCardWithHeader>
  ) : undefined;
};
