import { useHeaderContext } from "@/shared/context";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, makeStyles, Typography } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles()(theme => ({
  section: {
    textAlign: "start",
    marginBottom: theme.spacing(3),
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    lineHeight: 1.5,
    paddingInline: 10,
  },
  bold: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));

export const MedicationsTermsContainer: FC = () => {
  const { classes, cx } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const { setHeaderTitle } = useHeaderContext();
  const navigate = useNavigate();
  const { classes: textClasses } = useStyles();

  const handleDone = () => {
    navigate(-1);
  };

  useEffect(() => {
    setHeaderTitle(t("Terms and Conditions"));
    return () => {
      setHeaderTitle("");
    };
  }, []);

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography className={textClasses.section}>
          {t(
            "The purpose of this form is to obtain your informed consent to participate in a virtual call/ consultation with ioHealth medical professionals."
          )}
        </Typography>
        <Typography className={textClasses.section}>
          {t(
            "In preparation for the call, the medical professional will be able to view the consumer’s medical record declared history, and other medical tests shared by the consumer, all of which might be discussed with the medical during real-time audio and video calls. The medical professional may ask the consumer questions during the call, as an alternative to the in-person medical examination. For the consumers’ safety and quality assurance regulatory purposes, this call may be recorded and safely stored. The management, storage, and processing of any medical information for the consumer are all regulator compliant to protect consumer privacy."
          )}
        </Typography>
        <Typography className={textClasses.section}>
          {t(
            "Sehacity virtual visits are similar in nature to those in-person medical visits. Technical difficulties may arise due to the remote nature of the call. To address this, sehacity’s protocol will make sure that the consumer receives the care they need including the possibility of making direct mobile calls on the provided phone number when such issues arise."
          )}
        </Typography>
        <Typography className={cx(textClasses.section, textClasses.bold)}>
          {t(
            "I have read the information above and hereby I declare that I give my consent to this sehacity call with full knowledge and capacity and I was not coerced, harassed, or compelled in any way to sign this informed consent."
          )}
        </Typography>
        <Typography className={cx(textClasses.section, textClasses.bold)}>
          {t(" The consumer is above the minimum age for consent")}
        </Typography>
      </Box>

      <Box className={classes.footer}>
        <Button onClick={handleDone} className={classes.button}>
          {t("Done")}
        </Button>
      </Box>
    </Box>
  );
};
