import { useMarketplaceHealthPackageCategoriesQuery } from "@/domains/marketplace-health-packages";
import { useMarketplaceProductCategoriesQuery } from "@/domains/marketplace-products";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useMemo } from "react";

export const useCategoriesFilters = (type: "products" | "healthPackages") => {
  const { t } = useTranslation("consumer");

  const queryMap = {
    products: useMarketplaceProductCategoriesQuery,
    healthPackages: useMarketplaceHealthPackageCategoriesQuery,
  };

  const {
    data,
    loading: isLoading,
    hasMore,
    handleFetchMore: fetchMoreData,
  } = useRelayStyleConnection({
    useQuery: queryMap[type],
    variables: { first: 10 },
  });

  const all = {
    id: undefined,
    name: t("All"),
    nameAr: undefined,
    image: undefined,
  };

  const categories = useMemo(() => (data ? [all, ...data] : [all]), [data]);

  return {
    categories,
    isLoading,
    hasMore,
    fetchMoreData,
  };
};
