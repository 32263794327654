import { AmountSourceType } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import { FC } from "react";
import { CustomWebView } from "../custom-web-view/custom-web-view.component";
import ListEmptyComponent from "./ListEmptyComponent";
import usePaymentWebViewStyle from "./payment-web-view.component.style";
import usePaymentWebView from "./use-payment-web-view.component.hook";

export type PaymentFormWebViewProps = {
  addressId?: string;
  amountSourceId: string;
  amountSource: AmountSourceType;
  onSuccess?: () => void;
  onError?: () => void;
};

const PaymentFormWebView: FC<PaymentFormWebViewProps> = props => {
  const { addressId, amountSourceId, amountSource, onSuccess = () => {}, onError = () => {} } = props;

  const {
    failedPayment,
    isPaymentProcessLoading,
    checkoutPaymentFormUrl,
    integrity,
    handleGoToHome,
    // handleOnRefresh,
    handleOnLoadStart,
    getPreparedShopperResultUrl,
  } = usePaymentWebView({ addressId, amountSourceId, amountSource, onSuccess, onError });

  const { t } = useTranslation();
  const { classes } = usePaymentWebViewStyle();

  return failedPayment ? (
    <Box>
      <ListEmptyComponent
        title='Failed to create payment'
        description='Please, try again later.'
        className={classes.listEmpty}
        actionButton={
          <Button variant='outlined' onClick={handleGoToHome}>
            {t("Back Home")}
          </Button>
        }
      />
    </Box>
  ) : (
    <Box className={classes.root}>
      {!isPaymentProcessLoading && (
        <CustomWebView
          key={amountSourceId + amountSource + (addressId ?? "")}
          resultUrl={getPreparedShopperResultUrl()!}
          integrity={integrity || ""}
          checkoutPaymentFormUrl={checkoutPaymentFormUrl!}
          handleOnLoadStart={handleOnLoadStart}
        />
      )}
    </Box>
  );
};

export default PaymentFormWebView;
