import { useHeaderContext } from "@/shared/context";
import { Box, CircularProgress } from "@toolkit/ui";
import queryString from "query-string";
import { FC, useEffect } from "react";
import { useMarketplaceOrdersDetailsQuery } from "../../gql/queries";
import { useTranslation } from "@toolkit/i18n";
import { useCommonContainerStyles } from "@/shared/styles";
import { OrderDetails } from "../../components/OrderDetails/OrderDetails";

export const OrdersDetailsContainer: FC = () => {
  const { setHeaderTitle } = useHeaderContext();
  const queryParams = queryString.parse(location.search);
  const ordersIds = typeof queryParams?.orders === "string" ? queryParams.orders.split(",") : undefined;
  const { t } = useTranslation("consumer");
  const { classes } = useCommonContainerStyles();

  const { data, loading } = useMarketplaceOrdersDetailsQuery({
    variables: {
      filter: {
        ids: ordersIds,
      },
    },
  });
  const orders = data?.marketplaceOrders?.edges?.map(order => order?.node);
  useEffect(() => {
    const headerText = (ordersIds?.length ?? 0) > 1 ? t("Orders Summary") : t("Order Summary");
    setHeaderTitle(t(headerText));
    return () => setHeaderTitle("");
  }, [orders]);
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        {loading && <CircularProgress />}
        {orders && orders?.map(order => <OrderDetails key={order?.id} order={order} />)}
      </Box>
    </Box>
  );
};
