import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountAddressUpdateMutationVariables = Types.Exact<{
  accountAddressUpdateId: Types.Scalars['ID']['input'];
  input: Types.AddressInput;
}>;


export type AccountAddressUpdateMutation = { __typename?: 'Mutation', accountAddressUpdate?: { __typename?: 'AccountAddressUpdate', address?: { __typename?: 'Address', id: string, name?: string | null } | null, accountErrors: Array<{ __typename?: 'AccountError', message?: string | null }> } | null };


export const AccountAddressUpdateDocument = gql`
    mutation AccountAddressUpdate($accountAddressUpdateId: ID!, $input: AddressInput!) {
  accountAddressUpdate(id: $accountAddressUpdateId, input: $input) {
    address {
      id
      name
    }
    accountErrors {
      message
    }
  }
}
    `;
export type AccountAddressUpdateMutationFn = Apollo.MutationFunction<AccountAddressUpdateMutation, AccountAddressUpdateMutationVariables>;

/**
 * __useAccountAddressUpdateMutation__
 *
 * To run a mutation, you first call `useAccountAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountAddressUpdateMutation, { data, loading, error }] = useAccountAddressUpdateMutation({
 *   variables: {
 *      accountAddressUpdateId: // value for 'accountAddressUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountAddressUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AccountAddressUpdateMutation, AccountAddressUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountAddressUpdateMutation, AccountAddressUpdateMutationVariables>(AccountAddressUpdateDocument, options);
      }
export type AccountAddressUpdateMutationHookResult = ReturnType<typeof useAccountAddressUpdateMutation>;
export type AccountAddressUpdateMutationResult = Apollo.MutationResult<AccountAddressUpdateMutation>;
export type AccountAddressUpdateMutationOptions = Apollo.BaseMutationOptions<AccountAddressUpdateMutation, AccountAddressUpdateMutationVariables>;