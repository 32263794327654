import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CloseXIcon, CustomDialog, Grid, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useStyles } from "./DependentsConstainerStyles";
import { useDependentsContainerHook } from "./useDependentsContainerHook";
import { DoctorAvatar, EmptyMessageLayout } from "@/shared/components";
import { EmptyDependentsIcon, SwitchIcon } from "@/shared/icons";
import { isEmpty } from "lodash";
import { DependentSkeleton } from "./DependentsSkeleton";
import { FeatureEnum } from "@health/domains";

export const DependentsContainer: FC = () => {
  const { t } = useTranslation();
  const { classes, theme, cx } = useStyles();
  const {
    dependents,
    loading,
    onNavigateToCreateDependent,
    handleToggle,
    open,
    handleClose,
    handleDeactivateDependent,
    isLoadingDeactivate,
    handleSwitchUserDependent,
    isSwitching,
    isFeatureEnabled,
  } = useDependentsContainerHook();

  if (loading && isEmpty(dependents)) {
    return (
      <Box className={classes.root}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Box mt={"10px"} key={index}>
            <DependentSkeleton />
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography variant='h6' className={classes.title}>
            {t("Dependents")}
          </Typography>
          {isFeatureEnabled([FeatureEnum.FamilySync]) && (
            <Box className={classes.syncButtonContainer}>
              <SwitchIcon />
              <Button variant='text' disabled={true} className={classes.syncButton}>
                {t("Sync family members")}
              </Button>
            </Box>
          )}
        </Box>

        {dependents?.map(dependent => (
          <Box key={dependent?.id} role='button' className={classes.content}>
            <Grid height='100%' className={"card__container"} container alignItems='center'>
              <DoctorAvatar imageUrl={dependent?.photo || ""} />
              <Grid>
                <Typography className={cx(classes.text, classes.name)}>{dependent?.fullName}</Typography>
                <Typography className={classes.text}>{dependent?.relationType}</Typography>
                <Button
                  className={classes.actionButton}
                  disabled={isSwitching}
                  startIcon={<SwitchIcon fill={theme.palette.common.white} />}
                  onClick={() => handleSwitchUserDependent(dependent?.id!)}
                >
                  {t("Switch Profile")}
                </Button>
                <Button
                  variant='outlined'
                  disabled={isLoadingDeactivate}
                  startIcon={<CloseXIcon fill={theme.palette.primary.main} />}
                  className={classes.actionButton}
                  onClick={handleToggle}
                >
                  {t("Deactivate")}
                </Button>
              </Grid>
            </Grid>
            <CustomDialog
              isOpen={open}
              type='warning'
              text={{
                body: t("Are you sure you want to unlink this dependent from this account?"),
                title: t("Deactivate Dependent"),
              }}
              onClose={handleClose}
              onConfirm={() => handleDeactivateDependent(dependent?.id!)}
            />
          </Box>
        ))}
        {isEmpty(dependents) && (
          <Box mt={15} className={classes.root}>
            <EmptyMessageLayout message={t("No Dependents found")} icon={<EmptyDependentsIcon />} />
          </Box>
        )}
      </Box>
      {isFeatureEnabled([FeatureEnum.DependentCreate]) && (
        <Box className={classes.footer}>
          <Button className={classes.button} onClick={onNavigateToCreateDependent}>
            {t("Add New Dependent")}
          </Button>
        </Box>
      )}
    </>
  );
};
