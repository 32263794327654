import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type MarketplaceCartFragmentFragment = { __typename?: 'MarketplaceCart', id: string, totalPrice: any, totalDiscount: any, totalPriceWithVAT: any, totalVat: any, totalDeliveryPrice: any, items: Array<{ __typename?: 'MarketplaceCartItem', quantity: number, id: string, healthPackage?: { __typename?: 'MarketplaceHealthPackage', description?: string | null, descriptionAr?: string | null, id: string, mainImageUrl?: string | null, name?: string | null, nameAr?: string | null, price?: any | null, targetToAge?: number | null, shortDescriptionAr?: string | null, shortDescription?: string | null } | null, product?: { __typename?: 'MarketplaceProduct', id: string, mainImageUrl?: string | null, name?: string | null, nameAr?: string | null, price?: any | null, sku?: string | null, stockQuantity: number, shortDescription?: string | null, shortDescriptionAr?: string | null } | null }> };

export const MarketplaceCartFragmentFragmentDoc = gql`
    fragment MarketplaceCartFragment on MarketplaceCart {
  id
  items {
    quantity
    id
    healthPackage {
      description
      descriptionAr
      id
      mainImageUrl
      name
      nameAr
      price
      targetToAge
      shortDescriptionAr
      shortDescription
    }
    product {
      id
      mainImageUrl
      name
      nameAr
      price
      sku
      stockQuantity
      shortDescription
      shortDescriptionAr
    }
  }
  totalPrice
  totalDiscount
  totalPriceWithVAT
  totalVat
  totalDeliveryPrice
}
    `;