import { ConsumerMarketplaceOrdersQuery, MarketplaceOrdersDetailsQuery } from "../gql/queries";

export enum MyOrdersTabsEnum {
  orderPlaced = "order-placed",
  onTheWay = "on-the-way",
  delivered = "delivered",
  cancelled = "cancelled",
}

export type MarketplaceOrderInfo = NonNullable<
  NonNullable<NonNullable<ConsumerMarketplaceOrdersQuery["me"]>["marketplaceOrders"]>["edges"]
>[0]["node"];

export type MarketplaceOrderDetails = NonNullable<NonNullable<MarketplaceOrdersDetailsQuery["marketplaceOrders"]>["edges"]>[0]["node"];
