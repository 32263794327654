import { useCallback, useState } from "react";

type MutedParam = boolean | ((muted: boolean) => boolean);

export function useMuteSpeaker() {
  const [speakerMuted, setSpeakerMuted] = useState(false);
  const muteSpeaker = useCallback((value: MutedParam) => setSpeakerMuted(value), []);

  return { muteSpeaker, speakerMuted };
}
