import { i18n } from "@toolkit/i18n";
import { MyAccountsSettingsTabsEnum } from "../../types";
import PageUnderConstruction from "@/pages/PageUnderConstruction";
import { AddressesBody } from "./tabsBodies/AddressessBody/AddressesBody";

export const accountSettingsTabs = [
  {
    label: i18n.t("Account info", { ns: "consumer" }),
    routeParameterValue: MyAccountsSettingsTabsEnum.accountInfo,
    component: PageUnderConstruction,
  },
  {
    label: i18n.t("Payment", { ns: "consumer" }),
    routeParameterValue: MyAccountsSettingsTabsEnum.payment,
    component: PageUnderConstruction,
  },
  {
    label: i18n.t("Addresses", { ns: "consumer" }),
    routeParameterValue: MyAccountsSettingsTabsEnum.addresses,
    component: AddressesBody,
  },
] as const;
