import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import queryString from "query-string";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { EPrescriptionReferenceSearch } from "../../components/EPrescriptionReferenceSearch/EPrescriptionReferenceSearch";
import {
  ApplicationOrderMedicationUrlParamsType,
  ExternalOrderMedicationUrlParamsType,
  OrderMedicationContainerReferenceNumberSourceTypes,
  PrescriptionDetailsExternalUrlParams,
} from "../../others/types";
import { orderMedicationsRoutes } from "../../routes";

export const OrderMedicationContainer: FC = () => {
  const { classes } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const location = useLocation();

  const params = queryString.parse(location.search);

  const isExternalReference = !!(params.erx && params.nid && params.mobile && params.mid);
  const isPickupSmsExternalPrescription = !!(params.erx && params.nid && params.mobile && params.mid && params.oid && params.pid);

  const getUrlInfo = () => {
    if (isExternalReference && !isPickupSmsExternalPrescription) {
      const externalParams = params as ExternalOrderMedicationUrlParamsType;
      return {
        referenceId: externalParams.erx,
        sourceType: OrderMedicationContainerReferenceNumberSourceTypes.external,
        params: externalParams,
        mobileNumber: externalParams.mobile?.replace(" ", "+"),
        memberId: externalParams.mid,
        nationalId: externalParams.nid,
      } as const;
    } else if (isPickupSmsExternalPrescription) {
      const pickupSmsParams = params as PrescriptionDetailsExternalUrlParams;
      return {
        referenceId: pickupSmsParams.erx,
        sourceType: OrderMedicationContainerReferenceNumberSourceTypes.pickupSms,
        params: pickupSmsParams,
        mobileNumber: pickupSmsParams.mobile?.replace(" ", "+"),
        memberId: pickupSmsParams.mid,
        nationalId: pickupSmsParams.nid,
        loginByOtpRedirectUrl: orderMedicationsRoutes.prescriptionOrder.getExternalPickupSmsRouteRouteWithParams(pickupSmsParams),
      } as const;
    } else {
      const applicationParams = params as ApplicationOrderMedicationUrlParamsType;
      return {
        referenceId: applicationParams.referenceId,
        sourceType: OrderMedicationContainerReferenceNumberSourceTypes.application,
        params: applicationParams,
        mobileNumber: undefined,
        memberId: undefined,
        nationalId: undefined,
      } as const;
    }
  };
  const urlInfo = getUrlInfo();

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography className={classes.pageTitle}>{t("Order Medications")}</Typography>
        <EPrescriptionReferenceSearch urlInfo={urlInfo} />
      </Box>
    </Box>
  );
};
