import { routes } from "@/domains/Dependents";
import { MainPageLayout, PageWithBackHeaderLayout } from "@/shared/components";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const DependentsRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageLayout containerBreakpoint='sm' />}>
        <Route path={routes.dependents.route} Component={routes.dependents.component} />
      </Route>
      <Route element={<PageWithBackHeaderLayout containerBreakpoint='sm' />}>
        <Route path={routes.createDependent.route} element={<routes.createDependent.component />} />
      </Route>
    </Routes>
  );
};
