import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    margin: "10px",
    borderRadius: "10px",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  label: {
    color: theme.palette.gray.main,
    marginBottom: "4px",
  },
  careTeamText: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "left",
    fontSize: theme.mixins.fonts.fontSize.md,
    marginLeft: "10px",
  },
}));
