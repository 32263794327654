import { FC } from "react";
import { MarketplaceOrderInfo } from "../../others/types";
import { ConfirmationDialog, CustomCard } from "@/shared/components";
import { useMarketplaceOrderCardStyles } from "./useMarketplaceOrderCard.styles";
import { Box, Button, CircularProgress, CustomIcon, IconButton, TextField, TruncateTypography, Typography } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { BlobImageIcon, ExpandMoreIcon } from "@/shared/icons";
import emptyProvider from "@/assets/emptyProvider.png";
import moment from "moment";
import { useSiteSettingsGetQuery } from "@/gql";
import { formatCurrency } from "@/shared/utils";
import { useMarketplaceOrderCard } from "./useMarketplaceOrderCard.hook";
import { myOrdersTabs } from "../MyOrdersTabs/tabs";
import { useNavigate } from "react-router-dom";
import { routes } from "@/domains/marketplace/routes";

type MarketplaceOrderCardProps = {
  order: MarketplaceOrderInfo;
};

export const MarketplaceOrderCard: FC<MarketplaceOrderCardProps> = ({ order }) => {
  const { classes, theme } = useMarketplaceOrderCardStyles();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const {
    handleOrderAgain,
    isOrderAgainInProgress,
    canOrderAgain,
    handleCancelOrder,
    isCancelling,
    cancellationReason,
    setCancellationReason,
    reorderDialog,
    cancelDialog,
  } = useMarketplaceOrderCard(order);

  const handleShowOrderDetails = () => {
    order?.id && navigate(routes.ordersDetails.getRouteWithParams({ orders: order?.id }));
  };

  const { data } = useSiteSettingsGetQuery();
  const currency = data?.siteSettings?.defaultCurrency;

  return (
    <CustomCard className={classes.root}>
      <Box className={classes.content} onClick={handleShowOrderDetails}>
        <Box className={classes.vendorAvatarContainer}>
          <BlobImageIcon
            fill={theme.palette.common.white}
            fillBackground={theme.palette.divider}
            isCircular
            src={order?.vendor?.logo || emptyProvider}
          />
        </Box>

        <Box className={classes.contentInfoContainer}>
          <TruncateTypography
            className={classes.mainInfoText}
            text={pickLocalizedValue(order?.vendor?.name, order?.vendor?.nameAr) || "-"}
            maxWidth='100%'
          />
          <Typography className={classes.subInfoText}>{moment(order?.createdDate)?.format("DD MMMM yyyy")}</Typography>
          <Typography className={classes.subInfoText}>
            {t("Order ID")}: {"#" + order?.id}
          </Typography>
          <Typography className={classes.subInfoText}>
            {t("Total")}: {formatCurrency(order?.totalNetAmountWithVAT, currency)}
          </Typography>
        </Box>
        <IconButton className={classes.expandButton}>
          <ExpandMoreIcon direction='right' />
        </IconButton>
      </Box>
      <Box className={classes.footer}>
        <ConfirmationDialog
          variant='info'
          isOpen={reorderDialog.open}
          onConfirm={handleOrderAgain}
          onClose={reorderDialog.handleClose}
          button={
            <Button
              onClick={reorderDialog.handleOpen}
              disabled={!canOrderAgain || isOrderAgainInProgress}
              startIcon={isOrderAgainInProgress ? <CircularProgress size={20} /> : <CustomIcon icon='icons8_restart' />}
              className={classes.reorderButton}
            >
              {t("Reorder")}
            </Button>
          }
          text={{
            title: t("Start your reorder?"),
            body: t(
              "This will add your previous items to the cart. Existing items will remain - you can review everything before checkout."
            ),
          }}
        />

        {myOrdersTabs[0]?.statusFiltersValues?.includes(order?.status) && (
          <ConfirmationDialog
            variant='base'
            isOpen={cancelDialog.open}
            handleDo={handleCancelOrder}
            handleClose={cancelDialog.handleClose}
            isDoButtonDisabled={!cancellationReason || isCancelling}
            color='error'
            DoTitle={t("Cancel Order")}
            button={
              <Button className={classes.cancelButton} color='error' variant='outlined' onClick={cancelDialog.handleOpen}>
                {isCancelling ? <CircularProgress size={20} /> : t("Cancel Order")}
              </Button>
            }
            text={{
              title: t("Cancel this order?"),
              body: t("Are you sure you want to cancel this order? Please add Your Reasons"),
            }}
          >
            <TextField
              multiline
              rows={3}
              label={t("Reason")}
              value={cancellationReason}
              onChange={e => setCancellationReason(e.target.value)}
              className={classes.reasonsTextField}
            />
          </ConfirmationDialog>
        )}
      </Box>
    </CustomCard>
  );
};
