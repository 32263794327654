import { useCallback } from "react";
import { useCustomerPrescriptionDispenseMutation } from "../gql/mutations/__generated__/CustomerPrescriptionDispense";
import { CustomerPrescriptionDispenseInput } from "@/schema/types";
import { orderMedicationsRoutes } from "../routes";
import { formatGraphQLError } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import moment from "moment";
import { BACKEND_DATE_FORMAT } from "@toolkit/core";

export const useDispensePrescription = (input: CustomerPrescriptionDispenseInput) => {
  const navigate = useNavigate();
  const { failed } = useAddToast();
  const { t } = useTranslation("consumer");

  const [dispensePrescription, { loading }] = useCustomerPrescriptionDispenseMutation({
    onCompleted: data => {
      if (data?.customerPrescriptionDispense?.id) {
        navigate(
          orderMedicationsRoutes.prescriptionOrder.getRouteWithParams({
            orderId: data.customerPrescriptionDispense.id,
            prescriptionId: input?.prescriptionId,
          })
        );
      } else {
        failed(t("Something went wrong"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });

  const handleRequestDispensePrescription = useCallback(() => {
    dispensePrescription({
      variables: {
        input: {
          addressId: input?.addressId,
          deliveryDate: input?.deliveryDate ? moment(input?.deliveryDate).format(BACKEND_DATE_FORMAT) : undefined,
          deliveryTimeSlotId: input?.deliveryTimeSlotId,
          isDeliveryRequested: input?.isDeliveryRequested,
          prescriptionId: input?.prescriptionId,
        },
      },
    });
  }, [input, dispensePrescription]);

  return { handleRequestDispensePrescription, loading };
};
