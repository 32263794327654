import { Box, Button, CheckMarkIcon, FileUploader, FormAutocomplete, FormDatePickerField, FormTextField, Typography } from "@toolkit/ui";
import React from "react";
import { FormProvider } from "react-hook-form";
import { healthDocumentTypeOptions } from "@health/enum-options";
import moment from "moment";
import { useStyles } from "./UploadDocumentContainerStyles";
import { useUploadDocumentHook } from "./UploadDocumentHook";
import { useTranslation } from "@toolkit/i18n";

export const UploadDocumentContainer: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation("consumer");
  const { form, handleSubmit, onSubmit, accessToken, fileLinks, onEachFileUploadComplete, loading } = useUploadDocumentHook();

  return (
    <FormProvider {...form}>
      <Box display='flex' flexDirection={"column"} alignItems='start' justifyContent='center' width='100%' gap={2} padding={"10px"}>
        <FormTextField name='name' label={t("Name")} />
        <FormAutocomplete name={"type"} label={t("Type")} options={healthDocumentTypeOptions} />
        <FormDatePickerField
          name={"resultDate"}
          label={t("Result Date")}
          placeholder={t("Result Date")}
          datePickerProps={{ maxDate: moment() }}
        />
        <FormTextField name={"description"} label={t("Description")} placeholder={t("Description")} multiline rows={4} />
        <Typography ml={"5px"}>{t("Upload document:")}</Typography>
        <Box sx={{ backgroundColor: "white", borderRadius: "10px", padding: "10px", marginBottom: "10px", width: "100%" }}>
          <FileUploader
            inputId='fileLinks'
            disabled={false}
            token={accessToken}
            maxFileSize={1000000}
            multiple={true}
            accept='.pdf, .csv'
            onEachFileUploadComplete={onEachFileUploadComplete}
            child={
              fileLinks?.length ? (
                <Box display='flex' flexDirection='column' alignItems='center'>
                  {fileLinks.map((link, index) => (
                    <Box key={index} display='flex' alignItems='center'>
                      <CheckMarkIcon sx={{ color: "green", marginRight: "8px" }} />
                      <Typography>{link}</Typography>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Typography>{t("Drag and drop file here or click to browse")}</Typography>
                </Box>
              )
            }
          />
        </Box>
      </Box>
      <Box className={classes.footer}>
        <Button disabled={loading} onClick={handleSubmit(onSubmit)} className={classes.button}>
          {t("Upload Document")}
        </Button>
      </Box>
    </FormProvider>
  );
};
