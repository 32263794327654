import { useMarketplaceCartContext } from "@/shared/context";
import { useTranslation } from "@toolkit/i18n";
import { alpha, Box, makeStyles, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { PriceDisplay } from "../PriceDisplay/PriceDisplay";

const useStyles = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "48px",
    borderRadius: "24px",
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    ":hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.9),
    },
  },
  start: {
    marginInlineStart: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "14px",
  },
  end: {
    marginInlineEnd: "26px",
  },
  totalItemCountDot: {
    height: "38px",
    minWidth: "38px",
    width: "fit-content",
    borderRadius: "19px",
    backgroundColor: alpha(theme.palette.common.white, 0.1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.common.white,
    padding: "0 8px",
  },
  viewText: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.common.white,
  },
}));

type CartSummaryBarProps = {
  onClick?: () => void;
  title?: string;
};

export const CartSummaryBar: FC<CartSummaryBarProps> = props => {
  const { onClick, title } = props;
  const { classes, theme } = useStyles();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();

  const onBarClicked = () => {
    navigate(routes.cart.absRoute);
  };
  const { totalPrice, itemCounts } = useMarketplaceCartContext();
  return (
    <Box onClick={onClick || onBarClicked} className={classes.root}>
      <Box className={classes.start}>
        <Box className={classes.totalItemCountDot}>{itemCounts?.totalCount}</Box>
        <Typography className={classes.viewText}>{title || t("View Cart")}</Typography>
      </Box>
      <Box className={classes.end}>
        <PriceDisplay type='discounted-price' amount={totalPrice} increaseFontSizeBy={1} color={theme.palette.common.white} />
      </Box>
    </Box>
  );
};
