import { routes } from "@/domains/marketplace/routes";
import { MainPageLayout, MainPageWithAppBarLayout, PageWithBackHeaderLayout } from "@/shared/components";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
export const HealthMarketsRoutes: FC = () => {
  return (
    <>
      <Routes>
        <Route element={<MainPageWithAppBarLayout containerBreakpoint='sm' />}>
          <Route path={routes.root.route} Component={routes.root.component} />
          <Route path={routes.myOrders.route} Component={routes.myOrders.component} />
        </Route>
        <Route element={<MainPageLayout containerBreakpoint='sm' />}>
          <Route path={routes.products.route} Component={routes.products.component} />
          <Route path={routes.healthPackages.route} Component={routes.healthPackages.component} />
          <Route path={routes.cart.route} Component={routes.cart.component} />
          <Route path={routes.wishlist.route} Component={routes.wishlist.component} />
        </Route>
        <Route element={<PageWithBackHeaderLayout containerBreakpoint='sm' />}>
          <Route path={routes.product.route} Component={routes.product.component} />
          <Route path={routes.healthPackage.route} Component={routes.healthPackage.component} />
          <Route path={routes.addressSelection.route} Component={routes.addressSelection.component} />
          <Route path={routes.placeOrder.route} Component={routes.placeOrder.component} />
          <Route path={routes.termsAndConditions.route} Component={routes.termsAndConditions.component} />
          <Route path={routes.checkout.route} Component={routes.checkout.component} />
          <Route path={routes.ordersDetails.route} Component={routes.ordersDetails.component} />
        </Route>
      </Routes>
    </>
  );
};
