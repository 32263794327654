import { VisitStatus } from "@/schema/types";
import { CallContent } from "./CallContent";
import { CallError } from "./CallError";
import { CallVisitStatus } from "./CallVisitStatus";
import { useMeetingInfo } from "./useMeetingInfo";

type CallProps = {
  visitId: string;
  invitationLink?: string;
  isEndVisitOnLeave?: boolean;
  renderDoctorConsole?: () => JSX.Element;
  onClose?: () => void;
  onGoBack?: () => void;
};

export const Call = ({ invitationLink, visitId, isEndVisitOnLeave, renderDoctorConsole, onClose, onGoBack }: CallProps) => {
  const { joined, call, visit, isLoading, hasError, handleRetry, errorDetails } = useMeetingInfo(visitId);

  if (!visit || !call || isLoading || hasError) {
    return <CallError isLoading={isLoading} hasError={hasError} errorMessage={errorDetails} onClick={handleRetry} />;
  }

  if (!joined && visit?.status !== VisitStatus.CallInProgress) {
    return <CallVisitStatus visit={visit} call={call} onClose={onClose} />;
  }

  return (
    <CallContent
      call={call}
      visit={visit}
      invitationLink={invitationLink}
      onClose={onClose}
      onGoBack={onGoBack}
      renderDoctorConsole={renderDoctorConsole}
      isEndVisitOnLeave={isEndVisitOnLeave}
    />
  );
};
