import { InfoCardWithHeader } from "@/shared/components";
import { Box, Button, useTheme, ValueWithLabel } from "@toolkit/ui";
import { OrderMedicationsCardHeader } from "../OrderMedicationsCardHeader/OrderMedicationsCardHeader";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { BlobImageIcon } from "@/shared/icons";
import { ActiveOrder, SelectedPharmacy } from "../../others/types";
import { useCommonLabelValueStyles } from "../../styles/useCommonLabelValue.styles";
import { useSelectedPharmacyDetailsStyles } from "./useSelectedPharmacyDetails.styles";
import { OrderStatus, OrderType } from "@/schema/types";
import { SeeLocationOnTheMap } from "@/domains/address/components/SeeLocationOnTheMap/SeeLocationOnTheMap";
import { safeJsonParseWithDefault } from "@/domains/appointment/utils";
import { toNumber } from "lodash";
import { useNavigate } from "react-router-dom";
import { orderMedicationsRoutes } from "../../routes";

type ERxReferenceNumberProps = {
  selectedPharmacy: SelectedPharmacy;
  order?: ActiveOrder;
};

export const SelectedPharmacyDetails: React.FC<ERxReferenceNumberProps> = props => {
  const { selectedPharmacy = props?.order?.branch, order } = props;
  const { classes } = useCommonLabelValueStyles();
  const theme = useTheme();
  const { t } = useTranslation("consumer");
  const { classes: customClasses } = useSelectedPharmacyDetailsStyles();
  const isDelivery = order?.type === OrderType.Delivery;
  const displayedAddress = isDelivery ? order?.address : selectedPharmacy?.address;
  let displayedCoordinates = selectedPharmacy?.address?.coordinates;
  const navigate = useNavigate();

  const handleTrackOrder = () => {
    navigate(orderMedicationsRoutes.medicationsOrders.absRoute);
  };

  if (isDelivery && displayedAddress?.coordinates && typeof displayedAddress.coordinates === "string") {
    const parsedCoordinates = safeJsonParseWithDefault(displayedAddress.coordinates, {
      lat: 0,
      lng: 0,
    });
    displayedCoordinates = {
      lat: parsedCoordinates.lat ? toNumber(parsedCoordinates?.lat) : undefined,
      lng: parsedCoordinates.lng ? toNumber(parsedCoordinates?.lng) : undefined,
    };
  }

  return (
    <InfoCardWithHeader
      mt={"15px"}
      header={
        <OrderMedicationsCardHeader
          title={pickLocalizedValue(selectedPharmacy?.name, selectedPharmacy?.nameAr) || "-"}
          icon={
            <BlobImageIcon
              fillBackground={theme.palette.divider}
              fill={theme.palette.background.paper}
              src={selectedPharmacy?.vendor?.logo}
            />
          }
        />
      }
    >
      <Box>
        <ValueWithLabel
          hideColon
          labelProps={{
            className: classes.label,
          }}
          valueProps={{
            className: classes.value,
          }}
          label={t("Address")}
          value={
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              {displayedAddress?.streetAddress1}
              {displayedCoordinates && <SeeLocationOnTheMap coordinates={displayedCoordinates} />}
            </Box>
          }
        />
        <ValueWithLabel
          hideColon
          labelProps={{
            className: classes.label,
          }}
          valueProps={{
            className: classes.value,
          }}
          label={t("Order Number")}
          value={order?.id}
        />
        {order?.pickUpOtp && (
          <ValueWithLabel
            hideColon
            labelProps={{
              className: classes.label,
            }}
            valueProps={{
              className: classes.value,
            }}
            label={t("OTP")}
            value={order?.pickUpOtp || "-"}
          />
        )}
        {[OrderStatus.Delivered, OrderStatus.Dispensed, OrderStatus.OutForDelivery]?.includes(order?.status!) && (
          <Box className={customClasses.buttonsContainer}>
            <Button onClick={handleTrackOrder} className={customClasses.button}>
              {t("Track Your Order")}
            </Button>
          </Box>
        )}
      </Box>
    </InfoCardWithHeader>
  );
};
