import { CustomCard } from "@/shared/components";
import { pickLocalizedValue, Trans, useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useOrderReviewStyles } from "./OrderReviewCardStyles";
import { PrescriptionAddressFragmentFragment } from "@/domains/address/gql/fragments";
import { orderMedicationsRoutes } from "../../routes";

type OrderReviewCardProps = {
  address?: PrescriptionAddressFragmentFragment;
};

export const OrderReviewCard: FC<OrderReviewCardProps> = props => {
  const { address } = props;
  const { classes, cx } = useOrderReviewStyles();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();

  const handleNavigateToTermsAndConditions = () => {
    navigate(orderMedicationsRoutes.termsAndConditions.absRoute);
  };
  return (
    <CustomCard className={classes.root}>
      <Box className={cx(classes.section, classes.divider)}>
        <Box className={classes.sectionHeader}>
          <Typography className={classes.sectionTitle}>{t("Review your order")}</Typography>
        </Box>
        <Box className={classes.sectionContent}>
          <Typography className={classes.termsText}>
            <Trans
              t={t}
              components={{ btn: <Typography className={classes.underlinedText} onClick={handleNavigateToTermsAndConditions} /> }}
              i18nKey={
                "By Clicking on the “Place Order and Pay” you agree to ioHealth <btn>privacy notes </btn> & <btn> conditions of use </btn>."
              }
            />
          </Typography>
        </Box>
      </Box>
      <Box className={classes.section}>
        <Box className={classes.sectionHeader}>
          <Typography className={classes.sectionTitle}>{t("Delivery Address")}</Typography>
        </Box>
        <Box className={cx(classes.sectionContent, classes.addressContainer)}>
          <Typography className={cx(classes.addressContainerLabel)}>{t("Name")}</Typography>
          <Typography className={classes.addressContainerValue}>{pickLocalizedValue(address?.name, address?.nameAr)}</Typography>
          <Typography className={cx(classes.addressContainerLabel)}>{t("Address")}</Typography>
          <Typography className={classes.addressContainerValue}>{address?.streetAddress1 || "-"}</Typography>
          <Typography className={cx(classes.addressContainerLabel)}>{t("Mobile Number")}</Typography>
          <Typography className={classes.addressContainerValue}>{address?.phone || "-"}</Typography>
        </Box>
      </Box>
    </CustomCard>
  );
};
