import { makeStyles } from "@toolkit/ui";

export const useWishlistContainerStyles = makeStyles()(theme => ({
  pageHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    heigh: "fit-content",
    marginBottom: "10px",
  },
  clearButton: {
    color: theme.palette.secondary.main,
    "& svg": {
      fill: theme.palette.secondary.main,
    },
    "& path": {
      fill: theme.palette.secondary.main,
    },
  },
  pageTitle: {
    margin: "0px",
  },
  footer: {
    justifyContent: "space-between",
  },
  shopButton: {
    width: "fit-content",
    paddingInline: "30px",
  },
  viwCardButton: {
    width: "fit-content",
    paddingInline: "30px",
  },
}));
