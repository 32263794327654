import { Box, BoxProps } from "@toolkit/ui";
import React, { FC } from "react";
import { useStyle } from "./EmptyMessageLayoutStyle";

type EmptyMessageLayoutProps = BoxProps & {
  icon?: React.ReactNode;
  message?: React.ReactNode;
  subMessage?: React.ReactNode;
  action?: React.ReactNode;
  classes?: Partial<ReturnType<typeof useStyle>["classes"]>;
};

export const EmptyMessageLayout: FC<EmptyMessageLayoutProps> = props => {
  const { icon, message, subMessage, action, classes: _classes, ...rest } = props;
  const { classes, cx } = useStyle();
  return (
    <Box className={cx(classes.root, _classes?.root)} {...rest}>
      <Box className={cx(classes.iconContainer, _classes?.iconContainer)}>{icon}</Box>
      <Box className={cx(classes.messageContainer, _classes?.messageContainer)}>{message}</Box>
      {subMessage && <Box className={cx(classes.subMessageContainer, _classes?.subMessageContainer)}>{subMessage}</Box>}
      {action && <Box className={cx(classes.actionContainer, _classes?.actionContainer)}>{action}</Box>}
    </Box>
  );
};
