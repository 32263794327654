import { useCommonContainerStyles } from "@/shared/styles";
import { Box, CircularProgress } from "@toolkit/ui";
import { FC, useEffect, useCallback } from "react";
import { useOutletContext } from "react-router-dom";
import { useActiveOrderGetQuery, usePrescriptionGetQuery } from "../../gql/queries";
import { useOnOrderStatusUpdate, usePrescriptionStatusUpdate } from "@health/sse";
import { useHeaderContext } from "@/shared/context";
import { OrderTrackingViewsRenderer } from "../../components/OrderTrackingViews/OrderTrackingViewsRenderer";
import { orderMedicationsRoutes } from "../../routes";
import { extractIdFromBase64 } from "@toolkit/core";
import { debounce } from "lodash";
import { PrescriptionDetailsUrlInfo } from "../../others/types";

export const PrescriptionOrderDetailsContainer: FC = () => {
  const { classes } = useCommonContainerStyles();
  const { orderId, prescriptionId } = useOutletContext<PrescriptionDetailsUrlInfo>();
  const { setHeaderTitle, setBackPath } = useHeaderContext();

  const { data, loading, refetch } = usePrescriptionGetQuery({
    variables: {
      prescriptionId: prescriptionId || "",
    },
    fetchPolicy: "no-cache",
  });
  const {
    data: orderData,
    loading: isOrderLoading,
    refetch: refetchActiveOrder,
  } = useActiveOrderGetQuery({
    variables: {
      orderId: orderId || "",
    },
    fetchPolicy: "cache-and-network",
  });

  const debouncedRefetch = useCallback(
    debounce(() => {
      console.log("refetchActiveOrder");
      refetchActiveOrder();
    }, 300),
    [refetchActiveOrder]
  );

  const currentOrder = orderData?.order ?? undefined;
  const prescription = data?.prescription ?? undefined;

  useEffect(() => {
    loading && setHeaderTitle("Order Details");
    setBackPath(
      orderMedicationsRoutes.root.getRouteWithParams({
        referenceId: prescription?.referenceNumber || "",
      })
    );
  }, [prescription]);
  useEffect(() => {
    return () => {
      setHeaderTitle("");
      setBackPath(undefined);
    };
  }, []);

  useOnOrderStatusUpdate(data => {
    if (extractIdFromBase64(data?.data?.id) === Number(orderId)) {
      debouncedRefetch();
    }
  });

  usePrescriptionStatusUpdate(() => {
    refetch();
    debouncedRefetch();
  });

  useEffect(() => {
    return () => {
      debouncedRefetch.cancel();
    };
  }, [debouncedRefetch]);

  const isLoading = loading || isOrderLoading;
  return (
    <Box className={classes.pageContainer}>
      {isLoading && (
        <Box className={classes.pageContent}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && <OrderTrackingViewsRenderer order={currentOrder} prescription={prescription} />}
    </Box>
  );
};
