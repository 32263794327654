import { useCallback, useState } from "react";

import { ExecutedResponse } from "./types";
import { useZoom } from "./useZoom";
import { formatExecutedFailure } from "./utils";

type MutedParam = boolean | ((muted: boolean) => boolean);

export function useMuteSpeaker() {
  const [speakerMuted, setSpeakerMuted] = useState(false);
  const { mediaStream } = useZoom();

  const muteSpeaker = useCallback(
    async (value: MutedParam): Promise<ExecutedResponse> => {
      if (!mediaStream) {
        return { type: "USER_NOT_CONNECTED" };
      }

      const muted = typeof value === "function" ? value(speakerMuted) : value;
      try {
        if (muted) {
          await mediaStream.muteAllUserAudioLocally();
        } else {
          await mediaStream.unmuteAllUserAudioLocally();
        }

        setSpeakerMuted(muted);
        return { success: true };
      } catch (error) {
        console.error("Meeting: Unable to toggle speaker due to the error", error);
        return formatExecutedFailure(error);
      }
    },
    [mediaStream, speakerMuted]
  );

  return { speakerMuted, muteSpeaker: !mediaStream ? undefined : muteSpeaker };
}
