import { Box, StyledQRCode } from "@toolkit/ui";
import { FC } from "react";
import { MarketplaceOrderDetails } from "../../others/types";
import { MarketplaceOrderGeneralInfoCards } from "../MarketplaceOrderGeneralInfoCards/MarketplaceOrderGeneralInfoCards";
import { OrderDetailsPaymentSummary } from "../OrderDetailsPaymentSummary/OrderDetailsPaymentSummary";
import { OrderDetailsHealthPackagesCard } from "../OrderDetailsHealthPackagesCard/OrderDetailsHealthPackagesCard";
import { OrderDetailsProductCard } from "../OrderDetailsProductCard/OrderDetailsProductCard";
import { MarketplaceOrderType } from "@/schema/types";
import { getEnvVariable } from "@/env";

type OrderDetailsProps = {
  order: MarketplaceOrderDetails;
};

export const OrderDetails: FC<OrderDetailsProps> = props => {
  const { order } = props;
  const qrUrl =
    getEnvVariable("KEYCLOAK_API_URL") +
    (order?.type === MarketplaceOrderType.Product ? "/product-orders" : "/health-package-orders") +
    "?orderId=" +
    order?.id;
  return (
    <Box mb={2}>
      <StyledQRCode url={qrUrl} logoPath={"/qr-logo.png"} />
      <MarketplaceOrderGeneralInfoCards order={order} />
      <OrderDetailsProductCard order={order} />
      <OrderDetailsHealthPackagesCard order={order} />
      <OrderDetailsPaymentSummary order={order} />
    </Box>
  );
};
