import { PayNowDialog } from "@/domains/payment/pay-now-dialog/pay-now-dialog";
import { AmountSourceType } from "@/schema/types";
import { useHeaderContext, useMarketplaceCartContext } from "@/shared/context";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import { get, groupBy } from "lodash";
import { FC, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { MarketPlacePaymentSummary } from "../../components/CartItemsPaymentSummary/MarketPlacePaymentSummary";
import { OrderHealthPackagesCard } from "../../components/OrderHealthPackagesCard/OrderHealthPackagesCard";
import { OrderProductsCard } from "../../components/OrderProductsCard/OrderProductsCard";
import { OrderReviewCard } from "../../components/OrderReviewCard/OrderReviewCard";
import { routes } from "../../routes";
import { calculateDiscountedPrice } from "../../utils";

export const PlaceOrderContainer: FC = () => {
  const { classes, cx } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const { setHeaderTitle } = useHeaderContext();
  const { shippingAddress, cart } = useMarketplaceCartContext();

  const handlePay = () => {
    PayNowDialog.open();
    localStorage.setItem("payment-call-back-source-page", routes.checkout.getRouteWithParams({ cartId: cart?.id || "" }));
  };

  const cartItems = cart?.items;

  const sortedGroupedItems = useMemo(() => {
    if (!cartItems) return { product: [], healthPackage: [] };

    const groupedItems = groupBy(cartItems, item => (item?.healthPackage?.id ? "healthPackage" : "product"));

    const sortItemsByName = items => {
      return items.sort((a, b) => {
        const nameA = get(a, "product.name", "") || get(a, "healthPackage.name", "");
        const nameB = get(b, "product.name", "") || get(b, "healthPackage.name", "");
        return nameA.localeCompare(nameB);
      });
    };

    return {
      product: sortItemsByName(groupedItems.product ?? []),
      healthPackage: sortItemsByName(groupedItems.healthPackage ?? []),
    };
  }, [cartItems]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!shippingAddress) {
      navigate(routes.addressSelection.absRoute);
    }
    setHeaderTitle(t("Place Order"));
    return () => {
      setHeaderTitle("");
    };
  }, []);

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <PayNowDialog amountSource={AmountSourceType.OrderCheckoutToken} amountSourceId={cart?.id || ""} addressId={shippingAddress?.id} />
        <OrderReviewCard />
        <OrderProductsCard
          products={sortedGroupedItems.product.map(item => ({
            ...item.product,
            id: item?.product?.id || "",
            quantity: item.quantity,
            priceInfo: calculateDiscountedPrice(item?.product?.price, item?.product?.activeDiscount),
            priceForQuantity: calculateDiscountedPrice(item?.product?.price, item?.product?.activeDiscount, item.quantity),
          }))}
        />
        <OrderHealthPackagesCard
          healthPackages={sortedGroupedItems.healthPackage.map(item => ({
            ...item.healthPackage,
            id: item?.healthPackage?.id || "",
            quantity: item.quantity,
            price: item?.healthPackage?.price,
            priceForQuantity: calculateDiscountedPrice(item?.healthPackage?.price, item?.healthPackage?.activeDiscount),
            priceInfo: calculateDiscountedPrice(item?.healthPackage?.price, item?.healthPackage?.activeDiscount),
          }))}
        />
        <MarketPlacePaymentSummary
          totalDeliveryPrice={cart?.totalDeliveryPrice}
          totalDiscount={cart?.totalDiscount}
          totalPrice={cart?.totalPrice}
          totalVat={cart?.totalVat}
          totalPriceWithVAT={cart?.totalPriceWithVAT}
        />
      </Box>
      <Box className={cx(classes.footer)}>
        <Button onClick={handlePay} className={cx(classes.button)}>
          {t("Pay Now")}
        </Button>
      </Box>
    </Box>
  );
};
