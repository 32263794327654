import { InfoCardWithHeader } from "@/shared/components";
import { Box, TruncateTypography, Typography } from "@toolkit/ui";
import { FC } from "react";
import { TrackingMedicationsOrderData } from "../../others/types";
import { OrderMedicationsCardHeader } from "../OrderMedicationsCardHeader/OrderMedicationsCardHeader";
import { BlobImageIcon } from "@/shared/icons";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useMedicationsOrderTrackingCardStyles } from "./useMedicationsOrderTrackingCard.styles";
import { MedicationsOrderTrackingCardContent } from "../MedicationsOrderTrackingCardContent/MedicationsOrderTrackingCardContent";

export type MedicationsOrderTrackingCardProps = {
  order?: TrackingMedicationsOrderData;
};
export const MedicationsOrderTrackingCard: FC<MedicationsOrderTrackingCardProps> = props => {
  const { order } = props;
  const { theme, classes } = useMedicationsOrderTrackingCardStyles();
  const { t } = useTranslation("consumer");
  const selectedPharmacy = order?.children?.[0]?.branch;
  return (
    <InfoCardWithHeader
      mt={"10px"}
      mx={"10px"}
      key={order?.id}
      classes={{
        contentContainer: classes.contentContainer,
      }}
      header={
        <OrderMedicationsCardHeader
          title={
            <Box className={classes.titleContainer}>
              <Typography className={classes.titleMainText}>{t("Pharmacy")}</Typography>
              <TruncateTypography
                className={classes.titleSecondaryText}
                text={String(pickLocalizedValue(selectedPharmacy?.name, selectedPharmacy?.nameAr || "-"))}
                maxLines={2}
                maxWidth={"100%"}
              />
            </Box>
          }
          classes={{
            iconWrapper: classes.headerIconWrapper,
            root: classes.headerRoot,
          }}
          icon={
            <BlobImageIcon
              fillBackground={theme.palette.divider}
              isCircular
              fill={theme.palette.background.paper}
              src={selectedPharmacy?.vendor?.logo}
            />
          }
        />
      }
    >
      <MedicationsOrderTrackingCardContent order={order} />
    </InfoCardWithHeader>
  );
};
