import { PaymentCallbackErrorPage } from "./PaymentCallbackErrorPage";
import { PaymentCallbackPendingPage } from "./PaymentCallbackPendingPage";
import { PaymentCallbackRefundedPage } from "./PaymentCallbackRefundedPage";
import { PaymentCallbackRootPage } from "./PaymentCallbackRootPage";
import { PaymentCallbackSuccessPage } from "./PaymentCallbackSuccessPage";

export const PaymentCallBackPagesBaseRoute = "/payment-callback";
export const paymentCallBackPagesRoutes = {
  root: {
    route: "/",
    component: PaymentCallbackRootPage,
    get absRoute() {
      return PaymentCallBackPagesBaseRoute;
    },
    getRouteWithParams({
      checkoutPaymentFormUrl,
      resultUrl,
      integrity,
    }: {
      resultUrl: string;
      checkoutPaymentFormUrl: string;
      integrity: string;
    }) {
      return (
        this.absRoute +
        `?result-url=${encodeURIComponent(resultUrl)}&checkout-payment-form-url=${encodeURIComponent(
          checkoutPaymentFormUrl
        )}&checkout-int=${btoa(integrity)}`
      );
    },
  },
  success: {
    route: "/payment-success-redirect-page",
    component: PaymentCallbackSuccessPage,
    get absRoute() {
      return `${PaymentCallBackPagesBaseRoute}${this.route}`;
    },
    getRouteWithParams({ sourcePage }: { sourcePage: "string" }) {
      return `${this.absRoute}?sourcePage=${sourcePage}`;
    },
  },
  error: {
    route: "/payment-error-redirect-page",
    component: PaymentCallbackErrorPage,
    get absRoute() {
      return `${PaymentCallBackPagesBaseRoute}${this.route}`;
    },
    getRouteWithParams({ sourcePage }: { sourcePage: "string" }) {
      return `${this.absRoute}?sourcePage=${sourcePage}`;
    },
  },
  pending: {
    route: "/payment-pending-redirect-page",
    component: PaymentCallbackPendingPage,
    get absRoute() {
      return `${PaymentCallBackPagesBaseRoute}${this.route}`;
    },
    getRouteWithParams({ sourcePage }: { sourcePage: "string" }) {
      return `${this.absRoute}?sourcePage=${sourcePage}`;
    },
  },
  refunded: {
    route: "/payment-refunded-redirect-page",
    component: PaymentCallbackRefundedPage,
    get absRoute() {
      return `${PaymentCallBackPagesBaseRoute}${this.route}`;
    },
    getRouteWithParams({ sourcePage }: { sourcePage: "string" }) {
      return `${this.absRoute}?sourcePage=${sourcePage}`;
    },
  },
};
