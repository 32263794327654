import { AddressInput } from "@/schema/types";
import { pickLocalizedValue } from "@toolkit/i18n";
import { IGoogleMapAddress } from "@toolkit/maps";
import { createAutocompleteOption } from "@toolkit/ui";
import { compact } from "lodash";
import { IGoogleMapAddressUpsertFormValues } from "./forms/AddressFormUpsert";
import { AddressFragmentFragment } from "./gql/fragments";

export const formatAddress = (address: AddressFragmentFragment): string => {
  const { streetAddress1, streetAddress2, buildingNumber, area, city } = address;

  const addressParts = compact([streetAddress1, streetAddress2, buildingNumber, area, pickLocalizedValue(city?.name, city?.nameAr)]);

  return addressParts.join(", ");
};

export const convertAddressFormValuesToBackEndValues = (values: IGoogleMapAddressUpsertFormValues): AddressInput => {
  return {
    name: values.name,
    city: values.city?.value?.id || "",
    area: values.area,
    buildingNumber: values.buildingNumber,
    streetAddress1: values.streetAddress1,
    streetAddress2: values.streetAddress2,
    unitNumber: values.unitNumber,
    phone: values.phone,
    coordinates: {
      lat: values.coordinates.lat || 0,
      lng: values.coordinates.lng || 0,
    },
  };
};

export const convertAddressToFormValues = (address?: AddressFragmentFragment): IGoogleMapAddressUpsertFormValues => {
  return {
    name: address?.name || "",
    city: address?.city ? createAutocompleteOption(address?.city, "name", "nameAr") : undefined,
    area: address?.area || "",
    buildingNumber: address?.buildingNumber || "",
    streetAddress1: address?.streetAddress1 || "",
    streetAddress2: address?.streetAddress2 || "",
    unitNumber: address?.unitNumber || null,
    phone: address?.phone ?? "",
    isDefault: address?.isDefaultShippingAddress || false,
    coordinates: {
      lat: address?.coordinates?.lat || undefined,
      lng: address?.coordinates?.lng || undefined,
    },
  };
};

export const mapFormAddressWithMapAddress = (mapAddress?: IGoogleMapAddress): Partial<IGoogleMapAddressUpsertFormValues> => {
  return {
    streetAddress1: mapAddress?.streetAddress1 || "",
    coordinates: {
      lat: mapAddress?.coordinates?.lat || undefined,
      lng: mapAddress?.coordinates?.lng || undefined,
    },
  };
};

export const googleMapsUrl = (lat?: number, lng?: number, type: "map" | "directions" = "map"): string | null => {
  if (typeof lat !== "number" || typeof lng !== "number") {
    console.error("Invalid coordinates for Google Maps");
    return null;
  }

  return type === "directions"
    ? `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`
    : `https://www.google.com/maps/@?api=1&map_action=map&center=${lat},${lng}&zoom=15`;
};
