import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React, { FC } from "react";

export const CanceledIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.common.white;
  return (
    <SvgIcon width={"27"} height='27' viewBox='0 0 27 27' {...props}>
      <g id='Group_11085' data-name='Group 11085' transform='translate(-107 -388)'>
        <g id='Ellipse_2784' data-name='Ellipse 2784' transform='translate(107 388)' fill='#b93535' stroke='#fff' strokeWidth='1'>
          <circle cx='13.5' cy='13.5' r='13.5' stroke='none' />
          <circle cx='13.5' cy='13.5' r='13' fill='none' />
        </g>
        <path
          id='icons8-close_2_'
          data-name='icons8-close (2)'
          d='M5.408,4a.467.467,0,0,0-.332.138l-.939.939a.469.469,0,0,0,0,.664L7.561,9.163,4.137,12.586a.469.469,0,0,0,0,.664l.939.939a.469.469,0,0,0,.664,0l3.423-3.423,3.423,3.423a.469.469,0,0,0,.664,0l.939-.939a.469.469,0,0,0,0-.664L10.765,9.163,14.189,5.74a.469.469,0,0,0,0-.664l-.939-.939a.469.469,0,0,0-.664,0L9.163,7.561,5.74,4.137A.468.468,0,0,0,5.408,4Z'
          transform='translate(111 392)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
