import { useMarketplaceWishlistContext } from "@/shared/context";
import { HeartIcon, IconButton, makeStyles } from "@toolkit/ui";
import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { marketplaceItemType } from "../../types";

type WishlistAddButtonProps = {
  id?: string;
  marketplaceItemType: marketplaceItemType;
};

const useStyles = makeStyles()(theme => ({
  button: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "& svg": {
      color: theme.palette.primary.main,
      width: "18px",
      height: "18px",
      scale: "1.7",
    },
    "& path": {
      stroke: theme.palette.primary.main,
      fill: theme.palette.primary.main,
      strokeWidth: "0.15px",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary[50],
      "& svg": {
        color: theme.palette.primary.main,
      },
      "& path": {
        stroke: theme.palette.primary.main,
        fill: theme.palette.primary.main,
        strokeWidth: "0.15px",
      },
    },
    "&:disabled": {
      backgroundColor: theme.palette.common.white,
      "& svg": {
        color: theme.palette.primary.main,
      },
      "& path": {
        stroke: theme.palette.primary.main,
        fill: theme.palette.primary.main,
        strokeWidth: "0.15px",
      },
    },
  },
  buttonSelected: {
    backgroundColor: theme.palette.primary.main,
    "& svg": {
      color: theme.palette.common.white,
    },
    "& path": {
      stroke: theme.palette.common.white,
      fill: theme.palette.common.white,
      strokeWidth: "0.15px",
    },
  },
}));

export const WishlistAddButton: FC<WishlistAddButtonProps> = props => {
  const { id, marketplaceItemType } = props;
  const { classes, cx } = useStyles();
  const { wishlistItems, toggleItem, itemsLoadingTrackerMap } = useMarketplaceWishlistContext();
  const { isAuthenticated } = useAuth();
  const handleButtonClick = () => {
    toggleItem({
      [marketplaceItemType === "product" ? "productId" : "healthPackageId"]: id || "",
    });
  };
  return (
    <IconButton
      disabled={itemsLoadingTrackerMap[marketplaceItemType][id || ""] || !isAuthenticated}
      onClick={handleButtonClick}
      className={cx(classes.button, wishlistItems[marketplaceItemType][id || ""] && classes.buttonSelected)}
    >
      <HeartIcon />
    </IconButton>
  );
};
