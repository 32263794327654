import { ChatAttachment, ChatAttachmentInput } from "../../schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FC, useEffect, useRef } from "react";
import { useChatPlayer } from "../ChatProvider";
import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  MuiPauseCircleIcon,
  MuiPlayCircleIcon,
  Typography,
  useWaveSurferAudio,
} from "@toolkit/ui";

const useStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    userSelect: "none",
    width: "100%",
    gap: 8,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginHorizontal: 8,
    overflow: "hidden",
    "& .MuiTypography-root": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
}));

type ChatViewMediaAudioProps = {
  media: ChatAttachment | ChatAttachmentInput;
};

export const ChatViewMediaAudio: FC<ChatViewMediaAudioProps> = ({ media }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { url } = media;
  const { update: handleOnPlay } = useChatPlayer();
  const {
    waveformRef,
    duration,
    currentTime,
    loading,
    playing,
    handlePause: pause,
    handlePlayPause,
    formatTime,
    hasError,
  } = useWaveSurferAudio({ url });
  const playerRef = useRef({ pause });
  playerRef.current.pause = pause;

  useEffect(() => {
    if (playing) {
      handleOnPlay(playerRef.current);
    }
  }, [playing, handleOnPlay]);

  return (
    <Box className={classes.root}>
      <IconButton disabled={loading} onClick={handlePlayPause} sx={{ p: 0 }}>
        {loading && <CircularProgress size={36} />}
        {!loading && (
          <>
            {playing ? (
              <MuiPauseCircleIcon sx={{ fontSize: 42 }} color={"primary"} />
            ) : (
              <MuiPlayCircleIcon sx={{ fontSize: 42 }} color={"primary"} />
            )}
          </>
        )}
      </IconButton>
      <Box className={classes.content}>
        <Typography variant='subtitle1'>{t("Voice message")}</Typography>
        {hasError ? (
          <Typography variant='caption' color='textSecondary'>
            {t("Unable to load")}
          </Typography>
        ) : (
          <Typography variant='caption' color='textSecondary'>
            {formatTime(currentTime || 0)}
            {duration ? ` / ${formatTime(duration)}` : ""}
          </Typography>
        )}
      </Box>

      <Box ref={waveformRef} sx={{ flex: 1 }} />
    </Box>
  );
};
