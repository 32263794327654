import { useSiteSettingsGetQuery } from "@/gql";
import { FormSlider, GenericFilterForm, ValueLabelComponent } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box } from "@toolkit/ui";
import { isEmpty, xor } from "lodash";
import {
  healthPackagesListFiltersFormDefaultValues,
  HealthPackagesListFiltersFormSchema,
  IHealthPackagesListFiltersFormValues,
} from "../HealthPackagesListFiltersFormSchema";
import { HealthPackagesListFiltersFormProps } from "../types";

export const PriceFilterForm: React.FC<HealthPackagesListFiltersFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const defaultCurrency = data?.siteSettings?.defaultCurrency || "";
  return (
    <GenericFilterForm<IHealthPackagesListFiltersFormValues>
      schema={HealthPackagesListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='price'
      dialogTitle={t("Price")}
      formLabel={t("Price")}
      render={name => (
        <Box width='100%' px={1}>
          <FormSlider
            valueLabelFormat={value => ValueLabelComponent({ value, unit: t(defaultCurrency) })}
            valueLabelDisplay='on'
            min={Math.min(...(healthPackagesListFiltersFormDefaultValues.price ?? []))}
            max={Math.max(...(healthPackagesListFiltersFormDefaultValues.price ?? []))}
            name={name}
          />
        </Box>
      )}
      height={"fit-content"}
      paddingX={4}
      paddingY={2}
      defaultValues={healthPackagesListFiltersFormDefaultValues}
      isEmpty={value => {
        const _value = value as IHealthPackagesListFiltersFormValues["price"];
        return isEmpty(xor(_value, healthPackagesListFiltersFormDefaultValues.price));
      }}
    />
  );
};
