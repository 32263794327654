import { FC } from "react";
import { MarketPlaceRatingOverview } from "@/domains/marketplace/components/RatingOverview/MarketPlaceRatingOverview";
import { AddReviewAndComment } from "@/domains/marketplace/components/AddReviewAndComent/AddReviewAndComent";
import { MarketplaceItemReviews } from "@/domains/marketplace/components/MarketplaceItemReviews/MarketplaceItemReviews";
import { MarketplaceHealthPackageDetails } from "@/domains/marketplace-health-packages/types";

type ReviewBodyProps = {
  healthPackage: MarketplaceHealthPackageDetails;
};
export const ReviewsBody: FC<ReviewBodyProps> = props => {
  const { healthPackage } = props;

  return (
    <>
      {healthPackage?.reviewSummary && <MarketPlaceRatingOverview ratingSummary={healthPackage?.reviewSummary} />}
      {healthPackage?.id && <AddReviewAndComment id={healthPackage?.id} marketplaceItemType='healthPackage' />}
      {healthPackage?.id && <MarketplaceItemReviews id={healthPackage?.id} marketplaceItemType='healthPackage' />}
    </>
  );
};
