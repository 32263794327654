import { FC, MutableRefObject, PropsWithChildren, createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Chat, ChatMessageInput } from "../../schema/types";
import { useOnChatHaveNewMessage } from "@health/sse";

export type ChatMessageDraft = Partial<ChatMessageInput> & { action?: "edit" | "reply"; chat?: Chat };

export type ChatAudioPlayer = { pause: () => void };
export const ChatContext = createContext<{
  audioRef?: MutableRefObject<ChatAudioPlayer | undefined>;
  senderId?: number;
  accessToken?: string;
  draft?: ChatMessageDraft;
  chats: Chat[];
  unreadMessages: Record<string, number>;
  markAsRead: (id: string) => void;
  markAllAsRead: () => void;
  updateChats: (chats: Chat[]) => void;
  updateDraft: (draft?: ChatMessageDraft) => void;
}>({
  unreadMessages: {},
  chats: [],
  updateChats: () => {},
  updateDraft: () => {},
  markAsRead: () => {},
  markAllAsRead: () => {},
});

type ChatProviderProps = PropsWithChildren<{ accessToken?: string; senderId?: number }>;

export const ChatProvider: FC<ChatProviderProps> = ({ children, accessToken, senderId }) => {
  const audioRef = useRef<ChatAudioPlayer>();
  const [chats, setChats] = useState<Chat[]>([]);
  const [draft, setDraft] = useState<ChatMessageDraft>();
  const [unreadMessages, setUnreadMessages] = useState<Record<string, number>>({});
  const markAsRead = useCallback((id: string) => setUnreadMessages(msgs => ({ ...msgs, [id]: 0 })), []);
  const markAllAsRead = useCallback(() => setUnreadMessages({}), []);
  const updateDraft = useCallback((value?: ChatMessageDraft) => setDraft(value), []);
  const updateChats = useCallback((value: Chat[]) => setChats(value), []);

  useOnChatHaveNewMessage(({ data }) => {
    if (data.status === "NEW") {
      setUnreadMessages(msgs => ({ ...msgs, [data.chatGroupId]: (msgs[data.chatGroupId] || 0) + 1 }));
    }
  });

  useEffect(() => {
    setUnreadMessages({});
    setDraft(undefined);
    setChats([]);
  }, [senderId]);

  const value = useMemo(
    () => ({ audioRef, senderId, accessToken, draft, chats, unreadMessages, markAsRead, markAllAsRead, updateDraft, updateChats }),
    [senderId, accessToken, draft, chats, unreadMessages, markAsRead, markAllAsRead, updateDraft, updateChats]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
