import { DigitalTwinSleepCalculatorResultContainer } from "./containers/DigitalTwinSleepCalculatorResultContainer/DigitalTwinSleepCalculatorResultContainer";
import { DigitalTwinSleepCalculatorFormContainer } from "./containers/DigitalTwinSleepCalculatorFormContainer/DigitalTwinSleepCalculatorFormContainer";

export const SleepCalculationBaseRoute = "/sleep-calculator";
export interface SleepCalculationBaseRouteParams extends Record<string, any> {
  sleepCycles?: number | null;
  sleepTime?: string | null;
  wakeUpTime?: string | null;
  isBasedOnSleepTime: boolean;
}
export const sleepCalculationRoutes = {
  root: {
    route: "/",
    component: DigitalTwinSleepCalculatorResultContainer,
    get absRoute() {
      return SleepCalculationBaseRoute;
    },
    getRouteWithParams(result: SleepCalculationBaseRouteParams) {
      return `${this.absRoute}?` + new URLSearchParams(result);
    },
  },
  form: {
    route: "/form",
    component: DigitalTwinSleepCalculatorFormContainer,
    get absRoute() {
      return `${SleepCalculationBaseRoute}${this.route}`;
    },
    getRouteWithParams() {
      return `${this.absRoute}`;
    },
  },
};
