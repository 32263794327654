import { useTranslation } from "@toolkit/i18n";
import { Box, Card, CardContent } from "@toolkit/ui";
import { FC } from "react";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import { CareJourneyStatistics } from "@/schema/types";
import { useStyles } from "./CareJourneyCircularProgressStyle";

export const CareJourneyCircularProgressWithLabel: FC<{ careJourneyStatistics: CareJourneyStatistics }> = ({ careJourneyStatistics }) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  return (
    <Box className={classes.box}>
      <Card className={classes.cards}>
        <CardContent className={classes.cardContent}>
          <CircularProgressWithLabel title={t("Activity")} value={careJourneyStatistics?.allCompletedActivities! || 0} />
        </CardContent>
      </Card>
      <Card className={classes.cards}>
        <CardContent className={classes.cardContent}>
          <CircularProgressWithLabel title={t("Adherence")} value={careJourneyStatistics?.adherence! || 0} />
        </CardContent>
      </Card>
      <Card className={cx(classes.gaps, classes.cards)}>
        <CardContent className={classes.cardContent}>
          <CircularProgressWithLabel title={t("Gaps")} value={careJourneyStatistics?.gaps! || 0} />
        </CardContent>
      </Card>
    </Box>
  );
};
