import { DoctorProfileForTabs } from "@/domains/doctor/type";
import { SectionTabs } from "@/shared/components";
import { Box } from "@toolkit/ui";
import { FC, useState } from "react";
import { CareTeamMemberType } from "../../type";
import { useCareTeamsDoctorTabsStyle } from "./CareTeamsDoctorTabsStyle";
import { tabs } from "./tabs";

type CareTeamsDoctorTabsProps = {
  visibleTabsKeys: (typeof tabs)[number]["key"][];
  doctorInfo: DoctorProfileForTabs;
  teamMembers?: CareTeamMemberType[];
};

export const CareTeamsDoctorTabs: FC<CareTeamsDoctorTabsProps> = props => {
  const { visibleTabsKeys, doctorInfo, teamMembers = [] } = props;
  const { classes } = useCareTeamsDoctorTabsStyle();

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const visibleTabs = tabs.filter(tab => visibleTabsKeys.includes(tab.key));
  const tabsLabel = visibleTabs.map(tab => tab.label);

  const CurrentTab = visibleTabs[selectedTabIndex]?.component;

  const onTabClick = (_, id: number) => {
    setSelectedTabIndex(id);
  };
  return (
    <Box className={classes.root}>
      <SectionTabs
        tabs={tabsLabel}
        activeTabIndex={selectedTabIndex}
        onTabClick={onTabClick}
        classes={{
          root: classes.headerRoot,
        }}
      />
      <Box className={classes.tabBodyContainer}>{<CurrentTab doctorInfo={doctorInfo} teamMembers={teamMembers} />}</Box>
    </Box>
  );
};
