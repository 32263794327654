import { Log, UserManager, WebStorageStateStore } from "oidc-client-ts";
import { getEnvVariable } from "../../env";
import { makeVar } from "@apollo/client";

export type UserInfoType = {
  aud: string;
  email_verified: boolean;
  email: string;
  exp: number;
  family_name: string;
  national_id: string;
  given_name: string;
  meeting_platform_id: string;
  name: string;
  permissions: Array<string>;
  preferred_username: string;
  sub: string;
  user_id: number;
  patient_id: number;
};
export const oidcUserManager = new UserManager({
  authority: getEnvVariable("KEYCLOAK_REALM_LINK"),
  client_id: getEnvVariable("KEYCLOAK_CLIENT_ID_CONSUMER"),
  redirect_uri: location.href,
  response_type: "code",
  scope: "openid profile email",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  // stateStore: new WebStorageStateStore({ store: window.localStorage, prefix: "stateoidc" }),
  automaticSilentRenew: false,
  loadUserInfo: true,
  accessTokenExpiringNotificationTimeInSeconds: 10,
  includeIdTokenInSilentSignout: true,
});

oidcUserManager.events.addUserLoaded(async user => {
  if (!user || !user?.access_token || user?.expired) {
    try {
      await oidcUserManager.signinSilent();
    } catch (error) {
      oidcUserManager.signinRedirect();
    }
  }
});
process.env.NODE_ENV === "development" && Log.setLogger(console);

export const oidcUserManagerVar = makeVar<UserManager>(oidcUserManager);
