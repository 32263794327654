import { useCallback, useEffect, useState } from "react";
import { useZoom } from "./useZoom";
import { ConnectionState } from "@zoom/videosdk";
import { Meeting } from "@/schema/types";
import { CallState } from "./types";

export function useCallState(meeting: Meeting | undefined) {
  const callId = meeting?.id;
  const { resetConnectionState, connectionState, isConnecting, leaveSession } = useZoom();

  const [callState, setCallState] = useState<CallState>(CallState.NoCall);
  const updateCallStatus = useCallback(
    (value: CallState) => {
      setCallState(() => {
        if (value === CallState.NoCall && callId) {
          return CallState.Started;
        }

        return value;
      });
    },
    [callId]
  );

  useEffect(() => {
    if (callId) {
      updateCallStatus(CallState.Started);
    }
  }, [callId, updateCallStatus]);

  useEffect(() => {
    if (
      callState !== CallState.InCall ||
      isConnecting ||
      !connectionState ||
      connectionState === ConnectionState.Connected ||
      connectionState === ConnectionState.Reconnecting
    ) {
      return;
    }

    const timeoutId = setTimeout(() => {
      console.log("Meeting: Drop call, reason: connection state closed.", { connectionState });
      updateCallStatus(connectionState === ConnectionState.Fail ? CallState.Timeout : CallState.Dropped);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [callState, isConnecting, connectionState, updateCallStatus]);

  useEffect(() => {
    if (connectionState !== ConnectionState.Connected || callState === CallState.InCall) {
      return;
    }

    const timeoutId = setTimeout(() => {
      console.log("Meeting: Drop call, reason: call state mismatch.");
      leaveSession?.();
    }, 5000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [callState, connectionState, leaveSession]);

  const resetCallState = useCallback(() => {
    resetConnectionState?.();
    updateCallStatus(CallState.NoCall);
  }, [resetConnectionState, updateCallStatus]);

  return { callState, updateCallStatus, resetCallState };
}
