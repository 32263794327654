import { RatingStarIcon } from "@/shared/icons";
import { Rating, RatingProps } from "@toolkit/ui";
import { FC } from "react";
import { useCustomRatingStyle } from "./CustomRatingStyle";

export const CustomRating: FC<RatingProps> = props => {
  const { classes: _classes = {}, ...rest } = props;
  const { classes, theme, cx } = useCustomRatingStyle();
  return (
    <Rating
      icon={<RatingStarIcon />}
      classes={{
        ..._classes,
        icon: cx(classes.icon, _classes.icon),
      }}
      emptyIcon={<RatingStarIcon fill={theme.palette.stale[200]} />}
      {...rest}
    />
  );
};
