import { ReelBackgroundIcon } from "@/shared/icons";
import { Box, TruncateTypography } from "@toolkit/ui";
import { FC } from "react";
import { useReelCardStyle } from "./ReelCardStyle";

type ReelCardsProps = {
  imgSrc: string;
  title: string;
};
export const ReelCard: FC<ReelCardsProps> = props => {
  const { imgSrc, title } = props;
  const { classes } = useReelCardStyle({ src: imgSrc });
  return (
    <Box className={classes.root}>
      <Box className={classes.cardBackground}>
        <ReelBackgroundIcon />
      </Box>
      <TruncateTypography alignSelf='end' className={classes.title} text={title} />
    </Box>
  );
};
