import { makeStyles } from "@toolkit/ui";

export const useChatListItemStyles = makeStyles<{ isOutgoing: boolean }>()((theme, { isOutgoing }) => ({
  root: {
    display: "flex",
    padding: "0 0.5rem",
    userSelect: "none",
    ...(isOutgoing ? { flexDirection: "row-reverse", justifyContent: "end" } : {}),
  },

  outgoing: {
    flexDirection: "row-reverse",
    justifyContent: "end",
  },

  content: {
    minWidth: 140,
    margin: "0 0.5rem",
    position: "relative",
    maxWidth: "40vh",
    img: {
      maxHeight: "40vh",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "50vh",
      },
    },
  },
  avatar: {
    minWidth: 32,
  },
}));
