import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type ProductInfoFragment = { __typename?: 'MarketplaceProduct', id: string, name?: string | null, nameAr?: string | null, price?: any | null, description?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, mainImageUrl?: string | null, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, endDate: any, percentage?: number | null, startDate: any, type: Types.MarketplaceDiscountType } | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null }, category?: { __typename?: 'MarketplaceProductCategory', id: string, name?: string | null, nameAr?: string | null } | null };

export const ProductInfoFragmentDoc = gql`
    fragment ProductInfo on MarketplaceProduct {
  id
  name
  nameAr
  price
  description
  descriptionAr
  shortDescription
  shortDescriptionAr
  activeDiscount {
    amount
    endDate
    percentage
    startDate
    type
  }
  vendor {
    id
    name
    nameAr
  }
  category {
    id
    name
    nameAr
  }
  mainImageUrl
}
    `;