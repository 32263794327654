import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleQueryVariables = Types.Exact<{
  articleId: Types.Scalars['ID']['input'];
}>;


export type ArticleQuery = { __typename?: 'Query', article?: { __typename?: 'Article', id: string, title?: string | null, content?: string | null, contentAr?: string | null, readTimeMinutes?: number | null, headerImage?: string | null, description?: string | null, isRead?: boolean | null, readCounter?: number | null, references?: Array<string | null> | null, descriptionAr?: string | null, titleAr?: string | null, contentImages?: Array<{ __typename?: 'ArticleContentImage', id: string, url: string } | null> | null, category?: Array<{ __typename?: 'ArticleCategory', id: string, display?: string | null, displayAr?: string | null } | null> | null, relatedArticles?: Array<{ __typename?: 'Article', content?: string | null, contentAr?: string | null, headerImage?: string | null, id: string, title?: string | null, titleAr?: string | null, readTimeMinutes?: number | null, active?: boolean | null, contentImages?: Array<{ __typename?: 'ArticleContentImage', id: string, url: string } | null> | null, category?: Array<{ __typename?: 'ArticleCategory', display?: string | null, displayAr?: string | null, id: string } | null> | null } | null> | null } | null };


export const ArticleDocument = gql`
    query Article($articleId: ID!) {
  article(id: $articleId) {
    id
    title
    content
    contentAr
    readTimeMinutes
    headerImage
    description
    isRead
    readCounter
    contentImages {
      id
      url
    }
    category {
      id
      display
      displayAr
    }
    references
    relatedArticles {
      content
      contentAr
      contentImages {
        id
        url
      }
      headerImage
      id
      category {
        display
        displayAr
        id
      }
      title
      titleAr
      readTimeMinutes
      active
    }
    descriptionAr
    titleAr
  }
}
    `;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useArticleQuery(baseOptions: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables> & ({ variables: ArticleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
      }
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
        }
export function useArticleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleSuspenseQueryHookResult = ReturnType<typeof useArticleSuspenseQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;