import usePaymentSummary from "@/domains/payment/hooks/use-payment-summary.hook";
import { CustomCard } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { PriceDisplay } from "../PriceDisplay/PriceDisplay";
import { useMarketPlacePaymentSummaryStyles } from "./MarketPlacePaymentSummaryStyles";

export type MarketPlacePaymentSummaryProps = {
  totalPrice?: number;
  totalPriceWithVAT?: number;
  totalVat?: number;
  totalDiscount?: number;
  totalDeliveryPrice?: number;
  classes?: Partial<ReturnType<typeof useMarketPlacePaymentSummaryStyles>["classes"]>;
};

export const MarketPlacePaymentSummary: FC<MarketPlacePaymentSummaryProps> = props => {
  const { totalPrice, totalPriceWithVAT, totalVat, totalDiscount: _totalDiscount, totalDeliveryPrice, classes: _classes } = props;
  const { classes, cx } = useMarketPlacePaymentSummaryStyles();
  const { t } = useTranslation("consumer");
  const { subtotal, total, taxes, totalDelivery, totalDiscount } = usePaymentSummary({
    priceBeforeVat: totalPrice || 0,
    priceAfterVat: totalPriceWithVAT || 0,
    totalVat: totalVat || 0,
    totalDiscount: _totalDiscount || 0,
    totalDelivery: totalDeliveryPrice || 0,
  });

  return (
    <CustomCard className={cx(classes.root, _classes?.root)}>
      <Box className={cx(classes.summaryItemContainer, _classes?.summaryItemContainer)}>
        <Typography className={cx(classes.summaryItemLabel, _classes?.summaryItemLabel)}>{t("Subtotal") + ":"}</Typography>
        <PriceDisplay increaseFontSizeBy={1} amount={(Number(subtotal) || 0).toFixed(2)} />
      </Box>
      <Box className={cx(classes.summaryItemContainer, _classes?.summaryItemContainer)}>
        <Typography className={cx(classes.summaryItemLabel, _classes?.summaryItemLabel)}>{t("Items Discount" + ":")}</Typography>
        <PriceDisplay increaseFontSizeBy={1} amount={(Number(totalDiscount) || 0).toFixed(2)} />
      </Box>
      <Box className={cx(classes.summaryItemContainer, _classes?.summaryItemContainer)}>
        <Typography className={cx(classes.summaryItemLabel, _classes?.summaryItemLabel)}>{t("Delivery Charges:")}</Typography>
        <PriceDisplay increaseFontSizeBy={1} amount={(Number(totalDelivery) || 0).toFixed(2)} />
      </Box>
      <Box className={cx(classes.summaryItemContainer, _classes?.summaryItemContainer)}>
        <Typography className={cx(classes.summaryItemLabel, _classes?.summaryItemLabel)}>{t("Tax")}</Typography>
        <PriceDisplay increaseFontSizeBy={1} amount={(Number(taxes) || 0).toFixed(2)} />
      </Box>
      <Box className={cx(classes.summaryItemContainer, _classes?.summaryItemContainer)}>
        <Typography className={cx(classes.summaryItemLabel, classes.total, _classes?.summaryItemLabel, _classes?.total)}>
          {t("Total")}
        </Typography>
        <PriceDisplay increaseFontSizeBy={1} amount={(Number(total) || 0).toFixed(2)} />
      </Box>
    </CustomCard>
  );
};
