import { makeStyles } from "@toolkit/ui";

export const useOrderReviewStyles = makeStyles()(theme => ({
  root: {
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    padding: 10,
  },
  section: {
    marginBlockEnd: 10,
    display: "flex",
    flexDirection: "column",
    gap: 5,
    padding: 5,
    paddingBlockEnd: 15,
  },
  divider: {
    borderBottom: "1px solid",
    borderColor: theme.palette.divider,
  },
  sectionHeader: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: 10,
  },
  sectionTitle: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  sectionContent: {},
  termsText: {
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    color: theme.palette.common.black,
  },
  underlinedText: {
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.secondary.main,
    display: "inline",
  },
  addressContainer: {
    paddingTop: 20,
    display: "grid",
    gridTemplateColumns: "0.65fr 1fr",
    gap: 20,
    alignItems: "start",
  },
  addressContainerLabel: {
    textAlign: "start",
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    verticalAlign: "center",
  },
  addressContainerValue: {
    textAlign: "start",
    fontWeight: theme.mixins.fonts.fontWeight.light,
  },
}));
