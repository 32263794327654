import { EmptyMessageDefault } from "@/shared/components";
import { useArticlesQuery } from "../../gql";
import { i18n } from "@toolkit/i18n";
import { Box, CardsContainer, useMediaQuery } from "@toolkit/ui";
import { FC } from "react";
import { RecipeCard } from "../../components/RecipeCard/RecipeCard";
import { RecipeCardSkeleton } from "../../components/RecipeCardSkeleton/RecipeCardSkeleton";
import { useRecipeArticlesContainerStyles } from "./useRecipeArticlesContainerStyles";
import { IArticleNodeType } from "../../types";

type RecipeArticlesContainerProps = {
  countOfArticles?: number;
  classes?: Partial<ReturnType<typeof useRecipeArticlesContainerStyles>["classes"]>;
};

export const RecipeArticlesContainer: FC<RecipeArticlesContainerProps> = props => {
  const { countOfArticles = 10, classes: _classes } = props;
  const { classes, cx, theme } = useRecipeArticlesContainerStyles();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const { data, loading } = useArticlesQuery({
    variables: {
      first: countOfArticles,
      filter: {},
    },
  });

  const articles = data?.articles?.edges?.map(edge => edge?.node) || [];

  const handleOnArticleClick = (article: IArticleNodeType) => {
    return () => {
      console.log(article?.id);
      // implement on click
    };
  };

  return (
    <CardsContainer
      classes={{
        cardsContainerClassName: cx(classes.cardsContainerClassName, _classes?.cardsContainerClassName),
        navBtnClassName: cx(classes.navBtnClassName, _classes?.navBtnClassName),
        navBtnContainerClassName: cx(classes.navBtnContainerClassName, _classes?.navBtnContainerClassName),
        cardsContainer: cx(classes.cardsContainer, _classes?.cardsContainer),
        cardsUpperContainer: cx(classes.cardsUpperContainer, _classes?.cardsUpperContainer),
        card: cx(classes.card, _classes?.card),
        skeletonContainer: cx(classes.skeletonContainer, _classes?.skeletonContainer),
      }}
      isLoadingData={loading}
      EmptyCardComponent={<EmptyMessageDefault message={i18n.t("No articles available.", { ns: "consumer" })} />}
      countOfSkeletons={countOfArticles}
      CardSkeletonComponent={
        <Box className={cx(classes.recipeCardWrapper, _classes?.recipeCardWrapper)}>
          <RecipeCardSkeleton />
        </Box>
      }
      cardsScrollAmount={2}
      hideNavBtns={isSmall}
      cards={articles.map(article => (
        <Box
          onClick={handleOnArticleClick(article!)}
          key={article?.id}
          className={cx(classes.recipeCardWrapper, _classes?.recipeCardWrapper)}
        >
          <RecipeCard article={article!} />
        </Box>
      ))}
    />
  );
};
