import { Box, TruncateTypography } from "@toolkit/ui";
import { MarketplaceHealthPackageQuery } from "../../gql";
import { useHealthPackageImageStyle } from "./HealthPackageImageStyle";
import { FC } from "react";
import { pickLocalizedValue } from "@toolkit/i18n";
import { MarketplaceItemAvatar } from "@/domains/marketplace/components/MarketplaceItemAvatar/MarketplaceItemAvatar";

type HealthPackageImageProps = {
  healthPackage: MarketplaceHealthPackageQuery["marketplaceHealthPackage"];
};

export const HealthPackageImage: FC<HealthPackageImageProps> = ({ healthPackage }) => {
  const { classes } = useHealthPackageImageStyle();
  const imageUrl = healthPackage?.mainImageUrl;

  return (
    <Box className={classes.root}>
      <MarketplaceItemAvatar
        classes={{ img: classes.image, root: classes.imageRoot }}
        imageUrl={imageUrl}
        itemName={pickLocalizedValue(healthPackage?.name, healthPackage?.nameAr) || ""}
        marketplaceItemType='healthPackage'
      />
      <TruncateTypography
        alignSelf='end'
        className={classes.title}
        text={pickLocalizedValue(healthPackage?.name, healthPackage?.nameAr) || ""}
      />
    </Box>
  );
};
