import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceWishlistRemoveItemsMutationVariables = Types.Exact<{
  wishlistItemIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type MarketplaceWishlistRemoveItemsMutation = { __typename?: 'Mutation', marketplaceWishlistRemoveItems?: { __typename?: 'MarketplaceWishlist', id: string } | null };


export const MarketplaceWishlistRemoveItemsDocument = gql`
    mutation MarketplaceWishlistRemoveItems($wishlistItemIds: [ID!]!) {
  marketplaceWishlistRemoveItems(wishlistItemIds: $wishlistItemIds) {
    id
  }
}
    `;
export type MarketplaceWishlistRemoveItemsMutationFn = Apollo.MutationFunction<MarketplaceWishlistRemoveItemsMutation, MarketplaceWishlistRemoveItemsMutationVariables>;

/**
 * __useMarketplaceWishlistRemoveItemsMutation__
 *
 * To run a mutation, you first call `useMarketplaceWishlistRemoveItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceWishlistRemoveItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceWishlistRemoveItemsMutation, { data, loading, error }] = useMarketplaceWishlistRemoveItemsMutation({
 *   variables: {
 *      wishlistItemIds: // value for 'wishlistItemIds'
 *   },
 * });
 */
export function useMarketplaceWishlistRemoveItemsMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceWishlistRemoveItemsMutation, MarketplaceWishlistRemoveItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceWishlistRemoveItemsMutation, MarketplaceWishlistRemoveItemsMutationVariables>(MarketplaceWishlistRemoveItemsDocument, options);
      }
export type MarketplaceWishlistRemoveItemsMutationHookResult = ReturnType<typeof useMarketplaceWishlistRemoveItemsMutation>;
export type MarketplaceWishlistRemoveItemsMutationResult = Apollo.MutationResult<MarketplaceWishlistRemoveItemsMutation>;
export type MarketplaceWishlistRemoveItemsMutationOptions = Apollo.BaseMutationOptions<MarketplaceWishlistRemoveItemsMutation, MarketplaceWishlistRemoveItemsMutationVariables>;