import { CalculationFormRadio } from "@/domains/digital-twin-calculators/CalculationFormInputs/CalculationFormRadio";
import { SleepInput } from "@/schema/types";
import { i18n, useTranslation } from "@toolkit/i18n";
import { Box, Button, Card, Checkbox, FormControlLabel, TimePicker, Typography, useAddToast } from "@toolkit/ui";
import moment from "moment";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDigitalTwinSleepCalculateLazyQuery } from "../../gql/queries";
import { useDigitalTwinSleepCalculatorFormStyle } from "./useDigitalTwinSleepCalculatorFormStyle";
import { sleepCalculationRoutes } from "../../routes";

interface DigitalTwinSleepCalculatorFormProps {}

const radioOptions = [
  {
    label: i18n.t("Sleep Time"),
    value: "sleep",
  },
  {
    label: i18n.t("Wake Up Time"),
    value: "wake",
  },
];

export const DigitalTwinSleepCalculatorForm: FC<DigitalTwinSleepCalculatorFormProps> = () => {
  const { failed } = useAddToast();
  const [selectedTime, setSelectedTime] = useState<string | undefined>("");
  const [shouldSaveData, setShouldSaveData] = useState<boolean>(false);

  const [selectedSleepOrWake, setSelectedSleepOrWake] = useState<"sleep" | "wake">("sleep");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fetchCalculateSleep, { loading: isSubmitting }] = useDigitalTwinSleepCalculateLazyQuery();
  const { classes, cx } = useDigitalTwinSleepCalculatorFormStyle();
  const handleSelectedTimeChanged = (value?: string) => {
    setSelectedTime(value);
  };

  const handleSleepOrWakeChanged = (value: string) => {
    setSelectedSleepOrWake(value as "sleep" | "wake");
  };

  const handleSubmit = () => {
    const isBasedOnSleepTime = selectedSleepOrWake === "sleep";
    const inputData: SleepInput = {
      time: moment(selectedTime).format("HH:mm"),
      basedOnSleepTime: isBasedOnSleepTime,
    };

    fetchCalculateSleep({
      variables: {
        input: inputData,
        saveData: shouldSaveData,
      },
    }).then(({ data }) => {
      if (data?.sleep) {
        navigate(
          sleepCalculationRoutes.root.getRouteWithParams({
            isBasedOnSleepTime: isBasedOnSleepTime,
            sleepTime: isBasedOnSleepTime ? moment(selectedTime).format("hh:mm A") : data.sleep.time,
            wakeUpTime: !isBasedOnSleepTime ? moment(selectedTime).format("hh:mm A") : data.sleep.time,
            sleepCycles: data.sleep.sleepCycles,
          })
        );
      } else {
        failed(t("Failed to calculate sleep time"));
      }
    });
  };

  const handleChangeSaveResultCheckbox = () => setShouldSaveData(!shouldSaveData);

  return (
    <Box className={cx(classes.root)}>
      <Card className={classes.card}>
        <Typography className={classes.title}>{t("Set your sleep goal!")}</Typography>
        <Typography className={classes.subTitle}>{t("Fill in the details to calculate your sleep or wake up time.")}</Typography>
      </Card>
      <Card className={cx(classes.card, classes.formInputsCard)}>
        <CalculationFormRadio
          inputLabel={t("Do you want to calculate based on?")}
          name='radioGroup'
          defaultValue='sleep'
          value={selectedSleepOrWake}
          options={radioOptions}
          onChange={handleSleepOrWakeChanged}
        />
        <Typography className={classes.inputLabel}>
          {selectedSleepOrWake === "sleep" ? t("When do you usually go to sleep?") : t("When do you usually wake up?")}
        </Typography>
        <TimePicker value={selectedTime} onChange={handleSelectedTimeChanged} />
        <FormControlLabel
          label={t("Save results to your profile?")}
          control={
            <Checkbox checked={shouldSaveData} title={t("Save results to your profile?")} onChange={handleChangeSaveResultCheckbox} />
          }
        />
      </Card>
      <Button onClick={handleSubmit} disabled={isSubmitting} sx={{ mt: 4 }}>
        {isSubmitting ? t("Submitting") : t("Show result")}
      </Button>
    </Box>
  );
};
