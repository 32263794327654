import { useAccountSettingsInformationQuery } from "@/pages/AccountSettings/gql/queries";
import { useCommonFormStyles } from "@/shared/styles";
import { uuidv4 } from "@/shared/utils";
import { CitiesAutocomplete } from "@health/autocompletes";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormPhoneInput, FormSwitch, FormTextField } from "@toolkit/ui";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { AddressMap } from "../../components/AddressMap/AddressMap";
import { AddressFragmentFragment } from "../../gql/fragments";
import { convertAddressToFormValues } from "../../utils";
import { AddressFormSeggetionShip } from "./AddressFormSeggetionShips";
import {
  AddressUpsertFormSchema,
  addressUpsertFormSchemaDefaultValues,
  IGoogleMapAddressUpsertFormValues,
} from "./AddressUpsertFormSchema";

type AddressUpsertFormProps = {
  address?: AddressFragmentFragment;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange?: (values: IGoogleMapAddressUpsertFormValues) => void;
  mapAddress?: Partial<IGoogleMapAddressUpsertFormValues>;
};

export type AddressUpsertFormRef = {
  submit: () => void;
};

const AddressUpsertFormForwardRef: ForwardRefRenderFunction<AddressUpsertFormRef, AddressUpsertFormProps> = (props, ref) => {
  const { onChange, address, mapAddress } = props;
  const { t } = useTranslation("consumer");
  const form = useCustomForm<IGoogleMapAddressUpsertFormValues>({
    schema: AddressUpsertFormSchema,
    defaultValues: addressUpsertFormSchemaDefaultValues,
    mode: "onChange",
  });

  const { setValues, watch, getValues } = form;

  const { classes } = useCommonFormStyles();
  const { data } = useAccountSettingsInformationQuery({
    fetchPolicy: "cache-first",
  });

  const onSubmit = (values: IGoogleMapAddressUpsertFormValues) => {
    const _values = values;
    onChange?.(_values);
  };

  useImperativeHandle(ref, () => ({
    submit: () => form.handleSubmit(onSubmit)(),
  }));

  useEffect(() => {
    const _address = convertAddressToFormValues(address);
    setValues(_address);
  }, [address]);

  useEffect(() => {
    const formValues = getValues();
    if (mapAddress)
      setValues({
        ...formValues,
        ...mapAddress,
      });
  }, [JSON.stringify(mapAddress)]);

  const fromCoordinates = watch("coordinates");

  const fallbackId = useMemo(() => uuidv4(), []);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box className={classes.formContainer}>
          <AddressMap id={address?.id || fallbackId} coordinates={fromCoordinates} />

          <FormTextField
            className={classes.textField}
            name='name'
            label={t("Name")}
            InputLabelProps={{ shrink: true }}
            placeholder={t("Name")}
          />
          {!form.getValues("name") && (
            <Box>
              <AddressFormSeggetionShip formKey='name' label='Home' value='Home' />
              <AddressFormSeggetionShip formKey='name' label='Work' value='Work' />
            </Box>
          )}

          <CitiesAutocomplete className={classes.textField} name='city' placeholder={t("City")} />

          <FormTextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            name='area'
            label={t("Area")}
            placeholder={t("Area")}
          />
          <FormTextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            name='streetAddress1'
            label={t("Address")}
            placeholder={t("Address")}
          />
          <FormTextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            name='streetAddress2'
            label={t("Street")}
            placeholder={t("Street")}
          />
          <FormTextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            name='buildingNumber'
            label={t("Building Number")}
            placeholder={t("Building Number")}
          />
          <FormTextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            name='unitNumber'
            label={t("Unit Number")}
            placeholder={t("Unit Number")}
          />

          <FormPhoneInput name='phone' label={t("Mobile Number")} />

          {data?.me?.mobile && (
            <Box>
              <AddressFormSeggetionShip
                formKey='phone'
                checkIfValueEmpty={value => !((value?.length || 0) > 6)}
                label={`Use: ${data?.me?.mobile}`}
                value={data?.me?.mobile}
              />
            </Box>
          )}

          <FormSwitch disabled={!!address?.isDefaultShippingAddress} name='isDefault' label={t("Default Address")} />
        </Box>
      </form>
    </FormProvider>
  );
};

export const AddressUpsertForm = forwardRef(AddressUpsertFormForwardRef);
