import { routes } from "@/domains/my-previous-journeys";
import { MainPageLayout, PageWithBackHeaderLayout } from "@/shared/components";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const MyPreviousJourneysRoute: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageLayout containerBreakpoint='sm' />}>
        <Route path={routes.root.route} element={<routes.root.component />} />
      </Route>
      <Route element={<PageWithBackHeaderLayout containerBreakpoint='sm' />}>
        <Route path={routes.journeyDetails.route} element={<routes.journeyDetails.component />} />
      </Route>
    </Routes>
  );
};
