import { useMemo } from "react";
import { useAntMedia } from "./useAntMedia";
import { CallParticipant } from "./types";
import { Meeting, User } from "@/schema/types";
import { toBase64 } from "@toolkit/core";
import { getCallParticipant, getVideoSettings } from "./utils";

export function useCallParticipants({
  meeting,
  userId,
  displayName,
}: {
  meeting?: Meeting | undefined;
  userId?: number;
  displayName?: string;
}) {
  const userIdentity = useMemo(() => (userId ? toBase64(`User:${userId}`) : undefined), [userId]);
  const { streams, videoMuted, screenMuted, audioMuted, localStream } = useAntMedia();

  const meetingParticipants: Record<string, User> = useMemo(
    () => (meeting?.participants || []).reduce((list, u) => ({ ...list, [u.id]: u }), {}),
    [meeting?.participants]
  );

  const currentParticipant = useMemo<CallParticipant>(() => {
    return getCallParticipant(
      {
        isCurrentStream: true,
        streamName: displayName,
        metaData: {
          userIdentity: userIdentity!,
          audioMuted: audioMuted!,
          videoMuted: videoMuted!,
          screenMuted: screenMuted!,
          videoSettings: getVideoSettings(localStream),
        },
      },
      meetingParticipants
    );
  }, [displayName, userIdentity, audioMuted, videoMuted, screenMuted, localStream, meetingParticipants]);

  const participants = useMemo<CallParticipant[]>(() => {
    const streamList = streams ? Object.values(streams) : [];
    if (streamList.length === 0) {
      return currentParticipant ? [currentParticipant] : [];
    }

    return streamList.map(stream => getCallParticipant(stream, meetingParticipants));
  }, [streams, currentParticipant, meetingParticipants]);
  return {
    participants,
    currentParticipant,
  };
}
