import { useCommonContainerStyles, useCommonInfiniteContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { AddIconInCircle, Box, Button, Typography } from "@toolkit/ui";
import { FC } from "react";
import { MedicationsOrdersList } from "../../components/MedicationsOrdersList/MedicationsOrdersList";
import { useNavigate } from "react-router-dom";
import { orderMedicationsRoutes } from "../../routes";

export const MedicationsOrdersContainer: FC = () => {
  const { classes } = useCommonInfiniteContainerStyles();
  const { classes: containerClasses, theme } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const handleNavigateToOrderMedication = () => {
    navigate(orderMedicationsRoutes.root.absRoute);
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{t("Order Medications")}</Typography>
      <MedicationsOrdersList />
      <Box className={containerClasses.footer}>
        <Button
          sx={{
            "& path": {
              fill: theme.palette.common.white,
            },
          }}
          startIcon={<AddIconInCircle />}
          className={containerClasses.button}
          onClick={handleNavigateToOrderMedication}
        >
          {t("Request New eRX")}
        </Button>
      </Box>
    </Box>
  );
};
