import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DigitalTwinSleepCalculateQueryVariables = Types.Exact<{
  input: Types.SleepInput;
  saveData?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type DigitalTwinSleepCalculateQuery = { __typename?: 'Query', sleep?: { __typename?: 'SleepResponse', sleepCycles?: number | null, time?: any | null } | null };


export const DigitalTwinSleepCalculateDocument = gql`
    query DigitalTwinSleepCalculate($input: SleepInput!, $saveData: Boolean) {
  sleep(input: $input, saveData: $saveData) {
    sleepCycles
    time
  }
}
    `;

/**
 * __useDigitalTwinSleepCalculateQuery__
 *
 * To run a query within a React component, call `useDigitalTwinSleepCalculateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalTwinSleepCalculateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalTwinSleepCalculateQuery({
 *   variables: {
 *      input: // value for 'input'
 *      saveData: // value for 'saveData'
 *   },
 * });
 */
export function useDigitalTwinSleepCalculateQuery(baseOptions: Apollo.QueryHookOptions<DigitalTwinSleepCalculateQuery, DigitalTwinSleepCalculateQueryVariables> & ({ variables: DigitalTwinSleepCalculateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DigitalTwinSleepCalculateQuery, DigitalTwinSleepCalculateQueryVariables>(DigitalTwinSleepCalculateDocument, options);
      }
export function useDigitalTwinSleepCalculateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DigitalTwinSleepCalculateQuery, DigitalTwinSleepCalculateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DigitalTwinSleepCalculateQuery, DigitalTwinSleepCalculateQueryVariables>(DigitalTwinSleepCalculateDocument, options);
        }
export function useDigitalTwinSleepCalculateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DigitalTwinSleepCalculateQuery, DigitalTwinSleepCalculateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DigitalTwinSleepCalculateQuery, DigitalTwinSleepCalculateQueryVariables>(DigitalTwinSleepCalculateDocument, options);
        }
export type DigitalTwinSleepCalculateQueryHookResult = ReturnType<typeof useDigitalTwinSleepCalculateQuery>;
export type DigitalTwinSleepCalculateLazyQueryHookResult = ReturnType<typeof useDigitalTwinSleepCalculateLazyQuery>;
export type DigitalTwinSleepCalculateSuspenseQueryHookResult = ReturnType<typeof useDigitalTwinSleepCalculateSuspenseQuery>;
export type DigitalTwinSleepCalculateQueryResult = Apollo.QueryResult<DigitalTwinSleepCalculateQuery, DigitalTwinSleepCalculateQueryVariables>;