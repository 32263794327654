import { Visit } from "@/schema/types";
import { JoinScreen } from "./JoinScreen";
import { MeetingScreen } from "./MeetingScreen";
import { RatingScreen } from "./RatingScreen";
import { TimeoutScreen } from "./TimeoutScreen";
import { WaitingScreen } from "./WaitingScreen";
import { CallState, CallInstance } from "./types";

type CallContentProps = {
  call: CallInstance;
  visit?: Visit;
  invitationLink?: string;
  onClose?: () => void;
  onGoBack?: () => void;
  renderDoctorConsole?: () => JSX.Element;
  isEndVisitOnLeave?: boolean;
};

export const CallContent = ({
  renderDoctorConsole,
  invitationLink,
  call,
  visit,
  onClose,
  onGoBack,
  isEndVisitOnLeave,
}: CallContentProps) => {
  const { callState } = call.state;

  if (callState === CallState.NoCall) {
    return <WaitingScreen call={call} onClose={onClose} />;
  }

  if (callState === CallState.Started) {
    return <JoinScreen call={call} onClose={onClose} />;
  }

  if (callState === CallState.Timeout) {
    return <TimeoutScreen call={call} onClose={onClose} />;
  }

  if (callState === CallState.Dropped || callState === CallState.Ended) {
    return <RatingScreen call={call} visit={visit} onClose={onClose} isEndVisitOnLeave={isEndVisitOnLeave} />;
  }

  return <MeetingScreen call={call} renderDoctorConsole={renderDoctorConsole} invitationLink={invitationLink} onGoBack={onGoBack} />;
};
