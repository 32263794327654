import { DefaultEmptyIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { EmptyMessageLayout } from "../EmptyMessageLayout/EmptyMessageLayout";
import { useEmptyMessageDefaultStyles } from "./EmptyMessageDefaultStyle";

type EmptyMessageDefaultProps = {
  classes?: Partial<ReturnType<typeof useEmptyMessageDefaultStyles>["classes"]>;
  message?: string;
};

export const EmptyMessageDefault: FC<EmptyMessageDefaultProps> = props => {
  const { message, classes: _classes = {} } = props;
  const { t } = useTranslation("consumer");
  const { classes, cx } = useEmptyMessageDefaultStyles();
  return (
    <EmptyMessageLayout
      classes={{
        root: cx(classes.root, _classes.root),
        iconContainer: cx(classes.iconContainer, _classes.iconContainer),
        messageContainer: cx(classes.messageContainer, _classes.messageContainer),
        subMessageContainer: cx(classes.subMessageContainer, _classes.subMessageContainer),
        actionContainer: cx(classes.actionContainer, _classes.actionContainer),
      }}
      icon={<DefaultEmptyIcon />}
      message={t(message || "No Data!")}
    />
  );
};
