import { ProgressCircle, ProgressCircleProps } from "@/shared/components";
import { Box } from "@toolkit/ui";
import moment from "moment/moment";
import React, { FC, ReactNode } from "react";
import { useActivitiesProgressCircleStyles } from "./ActivitiesProgressCircleStyles";
import { useAuth } from "react-oidc-context";
import { AggrOperation } from "@/schema/types";
import { usePatientHealthParametersAggregationQuery } from "../../gql";
import { activityDailyGoals, calculatePercentage } from "../../others/utils";
import { ActivityItemCodesEnum } from "../../types";

type ActivitiesProgressCircleProps = Omit<ProgressCircleProps, "value"> & {
  icon?: ReactNode;
  patientId: string;
  activityCode: ActivityItemCodesEnum;
  date: string;
  progressColor?: string;
};

export const ActivitiesProgressCircle: FC<ActivitiesProgressCircleProps> = props => {
  const { classes } = useActivitiesProgressCircleStyles();
  const { icon, patientId, activityCode, date, progressColor, ...rest } = props;
  const { isAuthenticated } = useAuth();

  const { data, loading } = usePatientHealthParametersAggregationQuery({
    skip: !patientId || !isAuthenticated,
    fetchPolicy: "no-cache",
    variables: {
      first: 10,
      filter: {
        code: activityCode,
        patientId: patientId,
        operation: AggrOperation.Sum,
        requestedDateRange: {
          gte: date,
          lte: moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD"),
        },
      },
    },
  });

  const value = data?.patientHealthParametersAggregation?.edges?.[0]?.node?.value;
  const goal = activityDailyGoals(activityCode);
  const percentage = calculatePercentage(value, goal, loading);

  return (
    <Box className={classes.root}>
      <ProgressCircle value={percentage || 0} color={progressColor || rest.color} {...rest} />
      <Box className={classes.iconWrapper}>{icon}</Box>
    </Box>
  );
};
