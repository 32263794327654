import { Chat } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useMemo } from "react";

export const useChatPreviewHooks = (chat: Chat) => {
  const { t } = useTranslation();

  const { senderUser, attachments } = chat;
  const senderName = senderUser.fullName;
  const message = useMemo(() => {
    if (chat.deleted) {
      return t("Message deleted");
    }

    if (!attachments || attachments.length === 0) {
      return chat?.message;
    }

    if (attachments.length === 1) {
      const [{ type }] = attachments;
      if (/^image\//.test(type)) {
        return t("Photo");
      } else if (/^audio\//.test(type)) {
        return t("Voice message");
      } else if (/^video\//.test(type)) {
        return t("Video");
      }
      return t("Document");
    }

    return t("Documents");
  }, [attachments, chat.deleted, chat.message, t]);

  return { senderName, message };
};
