import React from "react";
import { IArticleNodeType } from "../../types";
import { Box, TruncateTypography } from "@toolkit/ui";
import { CustomCard } from "@/shared/components";
import { useRecipeCardStyles } from "./RecipeCardStyle";
import defaultFallbackImage from "@/assets/defaultFallbackImage.png";
import { pickLocalizedValue } from "@toolkit/i18n";

type RecipeCardProps = {
  article: IArticleNodeType;
};
export const RecipeCard: React.FC<RecipeCardProps> = props => {
  const { article } = props;
  const { classes } = useRecipeCardStyles();
  return (
    <CustomCard className={classes.root}>
      <Box className={classes.imageContainer}>
        <img className={classes.image} src={article?.headerImage || defaultFallbackImage} />
      </Box>
      <Box className={classes.content}>
        <TruncateTypography maxWidth='100%' className={classes.title} text={pickLocalizedValue(article?.title, article?.titleAr) || ""} />
        <TruncateTypography
          maxWidth='100%'
          className={classes.description}
          text={pickLocalizedValue(article?.description, article?.descriptionAr) || ""}
        />
        <Box className={classes.tagsContainer}>
          {article?.tags?.slice(0, 3)?.map((tag, index) => (
            <Box key={index} className={classes.tag}>
              {tag}
            </Box>
          ))}
        </Box>
      </Box>
    </CustomCard>
  );
};
