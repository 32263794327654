import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    width: "100%",
  },
  button: {
    color: theme.palette.primary.main,
    width: "120px",
    height: "120px",
    borderWidth: 0,
    boxShadow: theme.mixins.shadows.sm,
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    "& img": {
      width: "110px",
      height: "110px",
      fill: theme.palette.primary.main,
      borderRadius: "50%",
      [theme.breakpoints.down("sm")]: {
        width: "50px",
        height: "50px",
      },
    },
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary[200],
    },
  },
  icon: {
    "& svg": {
      width: "24px",
      height: "24px",
    },
    "& svg path": {
      fill: theme.palette.primary.main,
    },
  },
  button__selected: {
    border: "3px solid",
    "&&& svg": {
      fill: theme.palette.common.white,
    },
    "&&& path": {
      fill: theme.palette.common.white,
    },
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
  },
  healthPackageRoot: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
    "& svg": {
      fill: theme.palette.primary.main,
    },
  },
}));
