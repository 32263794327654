import { Box, Button, CustomInfiniteScroll } from "@toolkit/ui";
import React, { FC } from "react";
import { useMarketplaceOrdersList } from "./useMyOrdersList.hook";
import { useContainerHeight } from "@/shared/hooks";
import { EmptyMessageLayout } from "@/shared/components";
import { EmptyOrderIcon } from "@/shared/icons";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "@toolkit/i18n";
import { useMyOrdersListStyles } from "./useMyOrdersList.styles";
import { myOrdersTabs } from "../MyOrdersTabs/tabs";
import { useNavigate } from "react-router-dom";
import { routes } from "@/domains/marketplace/routes";
import { MarketplaceOrderCard } from "../MarketplaceOrderCard/MarketplaceOrderCard";
import { MarketplaceOrderCardSkeleton } from "../MarketplaceOrderCard/MarketplaceOrderCardSkeleton";

type MarketplaceOrdersListProps = {
  tabIndex: number;
};
export const MarketplaceOrdersList: FC<MarketplaceOrdersListProps> = ({ tabIndex }) => {
  const { orders, isLoading, fetchMoreData, hasMore, handleRefresh } = useMarketplaceOrdersList({ tabIndex });
  const { containerRef, containerHeight } = useContainerHeight();
  const { classes } = useMyOrdersListStyles();
  const navigate = useNavigate();
  const { t } = useTranslation("consumer");
  const currentTab = myOrdersTabs[tabIndex];
  const handleShopNow = () => {
    navigate(routes.root.absRoute);
  };

  return (
    <React.Fragment>
      {isLoading && isEmpty(orders) && (
        <Box>
          {Array.from({ length: 10 }).map((_, index) => (
            <MarketplaceOrderCardSkeleton key={`loader-${index}`} />
          ))}
        </Box>
      )}

      <Box ref={containerRef} className={classes.content}>
        <CustomInfiniteScroll
          dataLength={orders?.length || 1}
          onFetchMore={fetchMoreData}
          hasMore={hasMore}
          loader={
            <Box mt={"10px"}>
              {Array.from({ length: 2 }).map((_, index) => (
                <MarketplaceOrderCardSkeleton key={`loader-${index}`} />
              ))}
            </Box>
          }
          height={(containerHeight || 15) - 10}
          onRefresh={handleRefresh}
        >
          {!isLoading && isEmpty(orders) && (
            <Box className={classes.emptyPageContainer}>
              <EmptyMessageLayout
                icon={<EmptyOrderIcon />}
                message={currentTab.message}
                subMessage={currentTab.subMessage}
                action={
                  <Button onClick={handleShopNow} className={classes.button}>
                    {t("Shop Now")}
                  </Button>
                }
              />
            </Box>
          )}

          {!isEmpty(orders) && (
            <Box>
              {orders?.map((order, index) => (
                <MarketplaceOrderCard key={"order" + order.id + "-" + index} order={order} />
              ))}
            </Box>
          )}
        </CustomInfiniteScroll>
      </Box>
    </React.Fragment>
  );
};
