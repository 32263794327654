import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DependentCreateMutationVariables = Types.Exact<{
  input: Types.CustomerCreateInput;
}>;


export type DependentCreateMutation = { __typename?: 'Mutation', customerCreate?: { __typename?: 'CustomerCreate', patient?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null } | null, patientErrors: Array<{ __typename?: 'PatientError', code: Types.PatientErrorCode, field?: string | null, message?: string | null }> } | null };


export const DependentCreateDocument = gql`
    mutation dependentCreate($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    patient {
      id
      firstName
      lastName
    }
    patientErrors {
      code
      field
      message
    }
  }
}
    `;
export type DependentCreateMutationFn = Apollo.MutationFunction<DependentCreateMutation, DependentCreateMutationVariables>;

/**
 * __useDependentCreateMutation__
 *
 * To run a mutation, you first call `useDependentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDependentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dependentCreateMutation, { data, loading, error }] = useDependentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDependentCreateMutation(baseOptions?: Apollo.MutationHookOptions<DependentCreateMutation, DependentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DependentCreateMutation, DependentCreateMutationVariables>(DependentCreateDocument, options);
      }
export type DependentCreateMutationHookResult = ReturnType<typeof useDependentCreateMutation>;
export type DependentCreateMutationResult = Apollo.MutationResult<DependentCreateMutation>;
export type DependentCreateMutationOptions = Apollo.BaseMutationOptions<DependentCreateMutation, DependentCreateMutationVariables>;