import { CustomCard, DotLoader } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box, Skeleton, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useMakeWaitingForPharmacySelectionCardStyles } from "./useMakeWaitingForPharmacySelectionCard.styles";

export const WaitingForPharmacySelectionCard: FC = () => {
  const { classes } = useMakeWaitingForPharmacySelectionCardStyles();
  const { t } = useTranslation("consumer");

  return (
    <CustomCard className={classes.root}>
      <Box className={classes.header}>
        <DotLoader />
        <Typography className={classes.title} variant='h6'>
          {t("Waiting For Pharmacy Selection")}
        </Typography>
      </Box>
      {[...Array(6)].map((_, index) => (
        <Box key={index} className={classes.listItem}>
          <Skeleton variant='circular' width={44} height={44} />
          <Skeleton className={classes.skeletonRectangle} variant='rectangular' width='60%' height={18} />
        </Box>
      ))}
    </CustomCard>
  );
};
