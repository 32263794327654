import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const StoreIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-store' width='18.06' height='17.662' viewBox='0 0 18.06 17.662' {...props}>
      <path
        id='Path_12992'
        data-name='Path 12992'
        d='M20.718,33.331H7.585A1.587,1.587,0,0,1,6,31.746V25.179a.679.679,0,0,1,1.359,0v6.567a.229.229,0,0,0,.226.226H20.718a.229.229,0,0,0,.226-.226V30.387a.679.679,0,1,1,1.359,0v1.359A1.587,1.587,0,0,1,20.718,33.331Z'
        transform='translate(-5.167 -15.669)'
        fill={fill}
      />
      <path
        id='Line_1593'
        data-name='Line 1593'
        d='M-.821,5.7A.679.679,0,0,1-1.5,5.021V-.821A.679.679,0,0,1-.821-1.5a.679.679,0,0,1,.679.679V5.021A.679.679,0,0,1-.821,5.7Z'
        transform='translate(17.278 6.934)'
        fill={fill}
      />
      <path
        id='Path_12993'
        data-name='Path 12993'
        d='M22.284,33.6a.679.679,0,0,1-.679-.679V26.359H14.359v6.567a.679.679,0,1,1-1.359,0V25.679A.679.679,0,0,1,13.679,25h8.6a.679.679,0,0,1,.679.679v7.246A.679.679,0,0,1,22.284,33.6Z'
        transform='translate(-8.997 -15.943)'
        fill={fill}
      />
      <path
        id='Path_12994'
        data-name='Path 12994'
        d='M18.173,12.7a3.98,3.98,0,0,1-2.416-.792,4.552,4.552,0,0,1-5.123.006,3.776,3.776,0,0,1-.828.473,4.13,4.13,0,0,1-1.6.313,4.024,4.024,0,0,1-2.65-1.006A4.2,4.2,0,0,1,4.192,9.256a1.452,1.452,0,0,1,.149-.982l.018-.034,1.4-2.432A1.564,1.564,0,0,1,7.122,5h8.106a.679.679,0,1,1,0,1.359H7.122a.215.215,0,0,0-.162.077l-.018.034L5.549,8.9a.372.372,0,0,0-.028.075q0,.019.007.038a2.835,2.835,0,0,0,.927,1.661,2.666,2.666,0,0,0,1.754.669,2.551,2.551,0,0,0,1.9-.779.679.679,0,0,1,.961-.034,3.163,3.163,0,0,0,4.236,0,.679.679,0,0,1,.944.016,2.628,2.628,0,0,0,1.92.8,2.711,2.711,0,0,0,1.788-.669,2.677,2.677,0,0,0,.89-1.643q0-.028.01-.056a.371.371,0,0,0-.028-.075l-1.379-2.4c-.036-.05-.109-.135-.194-.135H17.991a.679.679,0,1,1,0-1.359h1.268a1.6,1.6,0,0,1,1.335.755l.024.039,1.4,2.446.018.034a1.455,1.455,0,0,1,.151.972,4.054,4.054,0,0,1-4.02,3.453Z'
        transform='translate(-4.161 -5)'
        fill={fill}
      />
      <path
        id='Line_1594'
        data-name='Line 1594'
        d='M-.821,7.1A.679.679,0,0,1-1.5,6.425V-.821A.679.679,0,0,1-.821-1.5a.679.679,0,0,1,.679.679V6.425A.679.679,0,0,1-.821,7.1Z'
        transform='translate(9.805 10.557)'
        fill={fill}
      />
    </SvgIcon>
  );
};
