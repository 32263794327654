import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import React from "react";

export const WorstModeIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='icons8-disappointed'
      width='32.488'
      height='32.488'
      viewBox='0 0 32.488 32.488'
      {...props}
    >
      <defs>
        <linearGradient id='linear-gradient' x1='-4.849' y1='4.399' x2='-4.147' y2='3.697' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#fede00' />
          <stop offset='0.519' stopColor='#fecc00' />
          <stop offset='1' stopColor='#ffb700' />
        </linearGradient>
      </defs>
      <path
        id='Path_28358'
        data-name='Path 28358'
        d='M20.244,36.488A16.244,16.244,0,1,0,4,20.244,16.245,16.245,0,0,0,20.244,36.488Z'
        transform='translate(-4 -4)'
        fill='url(#linear-gradient)'
      />
      <path
        id='Path_28359'
        data-name='Path 28359'
        d='M28.793,33.846A.811.811,0,0,0,28.72,32.7a9.393,9.393,0,0,0-12.444,0,.812.812,0,1,0,1.073,1.219,7.77,7.77,0,0,1,10.3,0,.811.811,0,0,0,1.146-.073Z'
        transform='translate(-6.254 -8.948)'
        fill='#343434'
      />
      <g id='Group_18982' data-name='Group 18982' transform='translate(6.42 12.055)'>
        <path
          id='Path_28360'
          data-name='Path 28360'
          d='M17.7,18.894a.813.813,0,0,0-.516.627,2.438,2.438,0,0,1-3.951,1.49A.812.812,0,0,0,12.2,22.27a4.063,4.063,0,0,0,6.582-2.481.811.811,0,0,0-.668-.934A.8.8,0,0,0,17.7,18.894Z'
          transform='translate(-11.905 -18.843)'
          fill='#343434'
        />
      </g>
      <g id='Group_18983' data-name='Group 18983' transform='translate(18.604 12.055)'>
        <path
          id='Path_28361'
          data-name='Path 28361'
          d='M28,18.894a.813.813,0,0,1,.516.627,2.438,2.438,0,0,0,3.951,1.49A.812.812,0,0,1,33.5,22.269a4.063,4.063,0,0,1-6.582-2.481A.813.813,0,0,1,28,18.894Z'
          transform='translate(-26.905 -18.842)'
          fill='#343434'
        />
      </g>
    </SvgIcon>
  );
};
