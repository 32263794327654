import { useCommonContainerStyles } from "@/shared/styles";
import { Box } from "@toolkit/ui";
import { FC } from "react";
import { DigitalTwinSleepCalculatorForm } from "../../components/DigitalTwinSleepCalculatorForm/DigitalTwinSleepCalculatorForm";

export const DigitalTwinSleepCalculatorFormContainer: FC = () => {
  const { classes: containerCx } = useCommonContainerStyles();
  return (
    <Box className={containerCx.pageContainer}>
      <Box className={containerCx.pageContent}>
        <DigitalTwinSleepCalculatorForm />
      </Box>
    </Box>
  );
};
