import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React, { FC } from "react";

export const CheckIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.common.white;
  return (
    <SvgIcon width='27' height='27' viewBox='0 0 27 27' {...props}>
      <g id='Group_11083' data-name='Group 11083' transform='translate(-107 -537.529)'>
        <g id='path_12675' data-name='path 12675' transform='translate(107 537.529)' fill='#47a102'>
          <path
            d='M 13.5 26.5 C 10.02758026123047 26.5 6.76298999786377 25.14776039123535 4.307610034942627 22.69239044189453 C 1.852239966392517 20.23700904846191 0.5 16.97241973876953 0.5 13.5 C 0.5 10.02758026123047 1.852239966392517 6.76298999786377 4.307610034942627 4.307610034942627 C 6.76298999786377 1.852239966392517 10.02758026123047 0.5 13.5 0.5 C 16.97241973876953 0.5 20.23700904846191 1.852239966392517 22.69239044189453 4.307610034942627 C 25.14776039123535 6.76298999786377 26.5 10.02758026123047 26.5 13.5 C 26.5 16.97241973876953 25.14776039123535 20.23700904846191 22.69239044189453 22.69239044189453 C 20.23700904846191 25.14776039123535 16.97241973876953 26.5 13.5 26.5 Z'
            stroke='none'
          />
          <path
            d='M 13.5 1 C 10.16113090515137 1 7.022109985351562 2.300230026245117 4.661169052124023 4.661169052124023 C 2.300230026245117 7.022109985351562 1 10.16113090515137 1 13.5 C 1 16.83887100219727 2.300230026245117 19.97789001464844 4.661169052124023 22.33882904052734 C 7.022109985351562 24.69976997375488 10.16113090515137 26 13.5 26 C 16.83887100219727 26 19.97789001464844 24.69976997375488 22.33882904052734 22.33882904052734 C 24.69976997375488 19.97789001464844 26 16.83887100219727 26 13.5 C 26 10.16113090515137 24.69976997375488 7.022109985351562 22.33882904052734 4.661169052124023 C 19.97789001464844 2.300230026245117 16.83887100219727 1 13.5 1 M 13.5 0 C 20.95584106445312 0 27 6.044160842895508 27 13.5 C 27 20.95584106445312 20.95584106445312 27 13.5 27 C 6.044160842895508 27 0 20.95584106445312 0 13.5 C 0 6.044160842895508 6.044160842895508 0 13.5 0 Z'
            stroke='none'
            fill={fill}
          />
        </g>
        <path
          id='icons8-done_3_'
          data-name='icons8-done (3)'
          d='M16.712,5.293,7.8,14.207,4.409,10.818,3.293,11.935l4.5,4.5,10.03-10.03Z'
          transform='translate(109.707 540.236)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
