import { Box, DecrementIcon, IconButton, PlusAddIcon, Typography } from "@toolkit/ui";
import { useCounterStyles } from "./CounterStyle";

interface CounterProps {
  count: number;
  min: number;
  max: number;
  handleIncrement: () => void;
  handleDecrement: () => void;
  disabled?: boolean;
  deleteButtonVisibleAtMin?: boolean;
}

export const Counter: React.FC<CounterProps> = ({
  count,
  min,
  max,
  handleIncrement,
  handleDecrement,
  disabled = false,
  deleteButtonVisibleAtMin = false,
}) => {
  const { classes, cx } = useCounterStyles();
  const _min = deleteButtonVisibleAtMin ? min + 1 : min;

  return (
    <Box className={classes.container}>
      <Box className={classes.startButtonContainer}>
        <IconButton onClick={handleIncrement} disabled={disabled || count === max} className={cx(classes.button, classes.incrementButton)}>
          <PlusAddIcon />
        </IconButton>
      </Box>
      <Typography className={classes.value}>{count}</Typography>
      <Box className={classes.endButtonContainer}>
        {count <= _min && deleteButtonVisibleAtMin ? (
          <IconButton onClick={handleDecrement} className={cx(classes.button, classes.deleteButton)} disabled={disabled || count <= min}>
            <PlusAddIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={handleDecrement}
            disabled={disabled || count === min}
            className={cx(classes.button, classes.decrementButton)}
          >
            <DecrementIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
