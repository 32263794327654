import { VirtualScrollingContainer } from "@/shared/components";
import { Box } from "@toolkit/ui";
import { FC } from "react";
import { WeekCard } from "./WeekCard";
import { useStyles } from "./styles";
import { HorizontalCalendarProps } from "./types";
import { useHorizontalCalendar } from "./useHorizontalCalendar";

export const HorizontalCalendar: FC<HorizontalCalendarProps> = props => {
  const { weeks, handleDateChange, handleWeekChange, selectedDate } = useHorizontalCalendar(props);
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <VirtualScrollingContainer
        cards={weeks.map(weekStart => (
          <WeekCard
            key={weekStart.format("YYYY-MM-DD")}
            weekStart={weekStart}
            selectedDate={selectedDate}
            minDate={props.minDate}
            maxDate={props.maxDate}
            onDateChange={handleDateChange}
          />
        ))}
        onReachEnd={() => handleWeekChange("end")}
        onReachStart={() => handleWeekChange("start")}
        cardWidth={387}
        gap={12}
        visibleCardCount={3}
      />
    </Box>
  );
};
