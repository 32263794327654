import { ListItem, ListItemIcon, ListItemText } from "@toolkit/ui";
import React from "react";
import { Link } from "react-router-dom";
import { useMenuMenuStyles } from "./Menu.styles";
import { MenuItem } from "./MenuItems"; // Assuming you have an interface like this

interface SectionProps {
  items: MenuItem[];
  classes: Partial<ReturnType<typeof useMenuMenuStyles>["classes"]>;
}

export const MenuSection: React.FC<SectionProps> = ({ items, classes }) => {
  return (
    <>
      {items.map(item => (
        <ListItem
          disabled={item?.disabled}
          key={item.text}
          component={item.link && !item?.disabled ? Link : "div"}
          to={item.link ? item.link : undefined}
          onClick={item?.onclick}
          className={classes.listItem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <item.icon />
          </ListItemIcon>
          <ListItemText className={classes.listItemText} primary={item.text} />
        </ListItem>
      ))}
    </>
  );
};
