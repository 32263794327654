import { useState } from "react";
import { useAddToast } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { getEnvVariable } from "@/env";
import { oidcUserManager, oidcUserManagerVar } from "@/shared/configs/oidc";
import { User, UserManager } from "oidc-client-ts";
import { AuthResult } from "../../types";

export const useOtpVerification = (sessionToken?: string, mobile?: string) => {
  const { t } = useTranslation("consumer");
  const { failed } = useAddToast();
  const [otp, setOtp] = useState<string | undefined>();
  const [isVerifying, setIsVerifying] = useState(false);

  const handleVerifyOtp = async () => {
    if (!otp || !sessionToken || !mobile) {
      failed(t("Please enter the OTP code"));
      return;
    }

    setIsVerifying(true);
    try {
      const response = await fetch(getEnvVariable("KEYCLOAK_OTP_LOGIN_URL"), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ mobile, code: otp, sessionToken }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error?.error || t("OTP verification failed"));
      }

      const authResult = await response.json();
      await loadUser(authResult);
    } catch (error) {
      failed(error instanceof Error ? error.message : t("Verification failed"));
    } finally {
      setIsVerifying(false);
    }
  };

  const loadUser = async (authResult: AuthResult): Promise<User> => {
    try {
      const user = await oidcUserManager.getUser();
      await oidcUserManager.storeUser(
        new User({
          id_token: authResult.id_token,
          session_state: authResult.session_state,
          access_token: authResult.access_token,
          refresh_token: authResult.refresh_token,
          token_type: authResult.token_type,
          scope: authResult.scope,
          profile: JSON.parse(atob(authResult.id_token.split(".")[1])),
          expires_at: Math.floor(Date.now() / 1000) + authResult.expires_in,
        })
      );

      const userManager = new UserManager({ ...oidcUserManagerVar().settings });
      oidcUserManagerVar(userManager);

      return user!;
    } catch (error) {
      console.error("Error loading user:", error);
      throw error;
    }
  };

  return { setOtp, isVerifying, handleVerifyOtp };
};
