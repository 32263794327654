import React from "react";
import { Box, Card, Skeleton } from "@toolkit/ui";
import { useStyle } from "../HealthPackageCard/HealthPackageCardStyle";

type HealthPackageCardSkeletonProps = {
  classes?: Partial<ReturnType<typeof useStyle>["classes"]>;
};

export const HealthPackageCardSkeleton: React.FC<HealthPackageCardSkeletonProps> = props => {
  const { classes: _classes } = props;
  const { classes, cx } = useStyle();

  return (
    <Card className={cx(classes.root, _classes?.root)}>
      <Box className={cx(classes.cardImageContainer, _classes?.cardImageContainer)}>
        <Skeleton variant='rectangular' width='100%' height='130px' />
      </Box>
      <Box className={cx(classes.cardDetailsContainer, _classes?.cardDetailsContainer)}>
        <Skeleton variant='text' width='80%' height='30px' />
        <Skeleton variant='text' width='40%' height='16px' />
        <Skeleton sx={{ mt: 1 }} variant='text' width='100%' height='14px' />
        <Skeleton variant='text' width='60%' height='14px' />
      </Box>
      <Box className={cx(classes.cardActionContainer, _classes?.cardActionContainer)}>
        <Box>
          <Skeleton variant='text' width='40px' height='20px' />
          <Skeleton variant='text' width='60px' height='18px' />
        </Box>
        <Box className={cx(classes.cardActionButtonsContainer, _classes?.cardActionButtonsContainer)}>
          <Skeleton variant='rectangular' width='35px' height='35px' />
          <Skeleton variant='rectangular' width='35px' height='35px' />
        </Box>
      </Box>
    </Card>
  );
};
