import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "hidden",
  },
  documentTitle: {
    marginBlock: "10px 5px",
    marginInline: "10px",
    fontSize: theme.mixins.fonts.fontSize.xxl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "start",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "start",
  },
  text: { textAlign: "start" },
  box: {
    flexGrow: 1,
    height: "100%",
    overflowY: "hidden",
  },
  loader: {
    marginTop: "5px",
  },
  footer: {
    position: "sticky",
    bottom: "0px",
    left: "0px",
    height: "74px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  button: {
    width: "215px",
    height: "48px",
    borderRadius: "24px",
    paddingInline: "40px",
  },
  emptyPageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flex: 1,
    justifySelf: "center",
    alignSelf: "center",
    padding: "10px",
    paddingBottom: "100px",
  },
  rootBox: {
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 10,
    boxShadow: theme.mixins.shadows.xs,
    margin: 10,
    position: "relative",
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.main,
    marginRight: "5px",
  },
  type: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    color: theme.palette.secondary.main,
  },
  typeBox: { display: "flex", alignItems: "center" },
  deleteButton: {
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
  },
}));
