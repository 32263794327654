import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const CheckoutIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-chat_5_' width='29.414' height='29.636' viewBox='0 0 138.951 130.49' {...props}>
      <g id='rder_has_been_placed_v2' data-name='rder has been placed v2' transform='translate(-52.179 -66.252)'>
        <path
          id='Path_12746_00000181086977908612989720000002170403974832897214_'
          d='M117.948,194.1c-15.141-3.425-28.81-8.832-41.4-18.446-12.467-9.583-23.914-23.283-24.364-37.312-.3-14,10.3-28.27,21.871-39.475a115.679,115.679,0,0,1,38.694-25.6c14.9-6.159,32.716-10.184,44.883-3.725,12.107,6.519,18.566,23.523,22.2,39.265,3.7,15.712,4.536,30.162,1.833,45.183s-8.983,30.493-20.459,37.553c-11.476,7.15-28.089,5.828-43.261,2.554'
          transform='translate(0 0)'
          fill={fill}
          opacity='0.09'
        />
        <g id='Group_11215' data-name='Group 11215' transform='translate(52.906 103.368)'>
          <path
            id='Path_13151'
            data-name='Path 13151'
            d='M71.324,208.186l-2.554-2.554a.871.871,0,0,1,0-1.262h0a.871.871,0,0,1,1.262,0l2.554,2.554a.871.871,0,0,1,0,1.262h0A.871.871,0,0,1,71.324,208.186Z'
            transform='translate(-64.324 -199.804)'
            fill='#525682'
          />
          <path
            id='Path_13152'
            data-name='Path 13152'
            d='M54.6,230.5h0a.885.885,0,0,1,.9-.9h3.605a.885.885,0,0,1,.9.9h0a.885.885,0,0,1-.9.9H55.5A.946.946,0,0,1,54.6,230.5Z'
            transform='translate(-54.6 -217.643)'
            fill='#525682'
          />
          <path
            id='Path_13153'
            data-name='Path 13153'
            d='M92.9,194.306V190.7a.885.885,0,0,1,.9-.9h0a.885.885,0,0,1,.9.9v3.605a.885.885,0,0,1-.9.9h0A.9.9,0,0,1,92.9,194.306Z'
            transform='translate(-81.394 -189.8)'
            fill='#525682'
          />
        </g>
        <rect
          id='Rectangle_7094'
          data-name='Rectangle 7094'
          width='105.838'
          height='1.863'
          transform='translate(54.709 183.791)'
          fill='#525682'
        />
        <g id='Group_11216' data-name='Group 11216' transform='translate(66.906 73.717)'>
          <g id='Delivery_Truck' transform='translate(0 0)'>
            <path
              id='Path_13154'
              data-name='Path 13154'
              d='M430.1,234.135a3.4,3.4,0,1,1,6.789.3,3.308,3.308,0,0,1-1.082,2.343A3.412,3.412,0,0,1,431,236.6,3.474,3.474,0,0,1,430.1,234.135Zm3.395-1.532a1.688,1.688,0,0,0-1.682,1.622h0a1.669,1.669,0,1,0,1.742-1.592C433.55,232.6,433.52,232.6,433.49,232.6Z'
              transform='translate(-331.287 -188.892)'
              fill='#525682'
            />
            <path
              id='Path_13155'
              data-name='Path 13155'
              d='M476.194,269.018a2.631,2.631,0,0,1,5.257.21,2.682,2.682,0,0,1-.841,1.833,2.613,2.613,0,0,1-3.7-.15A2.551,2.551,0,0,1,476.194,269.018Zm2.614-.811a.918.918,0,0,0-.9.871h0a.868.868,0,0,0,.871.931.886.886,0,0,0,.931-.871.868.868,0,0,0-.871-.931Z'
              transform='translate(-363.536 -213.801)'
              fill='#525682'
            />
            <path
              id='Path_13156'
              data-name='Path 13156'
              d='M271.76,119.43h-1.712v-1.712h1.712Zm0-4.6h-1.712v-1.712h1.712Zm0-4.6h-1.712v-1.712h1.712Zm0-4.6h-1.712v-1.712h1.712Zm-1.953-4.386a14.789,14.789,0,0,0-.451-1.532l1.622-.6a11.965,11.965,0,0,1,.511,1.772Zm-1.652-3.875a9.887,9.887,0,0,0-.991-1.232l1.262-1.2a13.613,13.613,0,0,1,1.172,1.442Zm-3.064-2.914a12.7,12.7,0,0,0-1.412-.751l.691-1.562a10.5,10.5,0,0,1,1.622.871Zm-3.935-1.5a13.629,13.629,0,0,0-1.592-.15l.03-1.712a12.234,12.234,0,0,1,1.833.15Zm-4.446-.15H255V91.1h1.712Zm-4.6,0H250.4V91.1h1.712Z'
              transform='translate(-205.577 -91.1)'
              fill='#a9b4d3'
            />
            <rect
              id='Rectangle_7095'
              data-name='Rectangle 7095'
              width='62.337'
              height='5.738'
              transform='translate(29.952 95.954)'
              fill={fill}
              opacity='0.09'
            />
            <rect
              id='Rectangle_7096'
              data-name='Rectangle 7096'
              width='20.999'
              height='10.124'
              transform='translate(92.259 95.954)'
              fill='#f5f5f5'
            />
            <rect
              id='Rectangle_7097'
              data-name='Rectangle 7097'
              width='13.849'
              height='10.124'
              transform='translate(0.871 95.954)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13157'
              data-name='Path 13157'
              d='M262.05,415.051H198V407.6h64.05Zm-62.337-1.712h60.595v-4.026H199.712Z'
              transform='translate(-168.919 -312.517)'
              fill='#525682'
            />
            <path
              id='Path_13158'
              data-name='Path 13158'
              d='M428.212,419.437H405.5V407.6h22.712Zm-21-1.712H426.5v-8.382H407.213Z'
              transform='translate(-314.082 -312.517)'
              fill='#525682'
            />
            <path
              id='Path_13159'
              data-name='Path 13159'
              d='M116.792,419.437H101.2V407.6h15.592Zm-13.849-1.712h12.137v-8.382H102.942Z'
              transform='translate(-101.2 -312.517)'
              fill='#525682'
            />
            <path
              id='Path_13160'
              data-name='Path 13160'
              d='M352.1,263.8h11.987l6.82,4.056h9.373l7.15,22.922v17.094H352.1Z'
              transform='translate(-276.724 -211.917)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13161'
              data-name='Path 13161'
              d='M386.312,306.714H349.3V260.9h13.1l6.819,4.056h9.734l7.39,23.673Zm-35.3-1.742H384.6v-16.1l-6.94-22.2h-8.953l-6.82-4.056H350.982Z'
              transform='translate(-274.765 -209.889)'
              fill='#525682'
            />
            <path
              id='Path_13162'
              data-name='Path 13162'
              d='M423.054,307.541l-4.6-14.841H405.6v14.841Z'
              transform='translate(-314.152 -232.135)'
              fill='#525682'
            />
            <rect
              id='Rectangle_7098'
              data-name='Rectangle 7098'
              width='5.708'
              height='14.841'
              transform='translate(80.903 60.565)'
              fill='#525682'
            />
            <circle
              id='Ellipse_2919'
              data-name='Ellipse 2919'
              cx='7.961'
              cy='7.961'
              r='7.961'
              transform='translate(6.79 95.954)'
              fill='#525682'
            />
            <circle
              id='Ellipse_2920'
              data-name='Ellipse 2920'
              cx='2.944'
              cy='2.944'
              r='2.944'
              transform='translate(11.807 100.972)'
              fill='#f5f5f5'
            />
            <rect
              id='Rectangle_7099'
              data-name='Rectangle 7099'
              width='74.504'
              height='53.475'
              transform='translate(0.871 42.48)'
              fill='#dee1ec'
            />
            <rect
              id='Rectangle_7100'
              data-name='Rectangle 7100'
              width='74.504'
              height='53.475'
              transform='translate(0.871 42.48)'
              fill='#fff'
            />
            <path
              id='Path_13163'
              data-name='Path 13163'
              d='M149.653,265.2h0a3.154,3.154,0,0,1,3.154,3.155v28.48a3.154,3.154,0,0,1-3.154,3.154h0a3.154,3.154,0,0,1-3.154-3.154v-28.48A3.135,3.135,0,0,1,149.653,265.2Z'
              transform='translate(-132.89 -212.897)'
              fill={fill}
            />
            <path
              id='Path_13164'
              data-name='Path 13164'
              d='M220.054,265.2h0a3.154,3.154,0,0,1,3.154,3.155v28.48a3.154,3.154,0,0,1-3.154,3.154h0a3.154,3.154,0,0,1-3.154-3.154v-28.48A3.154,3.154,0,0,1,220.054,265.2Z'
              transform='translate(-182.141 -212.897)'
              fill={fill}
            />
            <path
              id='Path_13165'
              data-name='Path 13165'
              d='M290.454,265.2h0a3.154,3.154,0,0,1,3.154,3.155v28.48a3.154,3.154,0,0,1-3.154,3.154h0a3.154,3.154,0,0,1-3.154-3.154v-28.48A3.154,3.154,0,0,1,290.454,265.2Z'
              transform='translate(-231.392 -212.897)'
              fill={fill}
            />
            <circle
              id='Ellipse_2921'
              data-name='Ellipse 2921'
              cx='7.961'
              cy='7.961'
              r='7.961'
              transform='translate(21.991 95.954)'
              fill='#525682'
            />
            <circle
              id='Ellipse_2922'
              data-name='Ellipse 2922'
              cx='2.944'
              cy='2.944'
              r='2.944'
              transform='translate(27.008 100.972)'
              fill='#f5f5f5'
            />
            <circle
              id='Ellipse_2923'
              data-name='Ellipse 2923'
              cx='7.961'
              cy='7.961'
              r='7.961'
              transform='translate(84.298 95.954)'
              fill='#525682'
            />
            <circle
              id='Ellipse_2924'
              data-name='Ellipse 2924'
              cx='2.944'
              cy='2.944'
              r='2.944'
              transform='translate(89.315 100.972)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13166'
              data-name='Path 13166'
              d='M193.072,232.5a9.862,9.862,0,0,1-9.674,8.262l-5.978,8.051-5.978-8.051H151.1a9.838,9.838,0,0,1-9.7-8.262Z'
              transform='translate(-129.323 -190.021)'
              fill={fill}
              opacity='0.09'
            />
            <path
              id='Path_13167'
              data-name='Path 13167'
              d='M177.517,284.817H101.3V229.6h76.217Zm-74.5-1.742H175.8V231.342H103.012Z'
              transform='translate(-101.27 -187.992)'
              fill='#525682'
            />
            <path
              id='Path_13168'
              data-name='Path 13168'
              d='M194,114.1H161.737a5.135,5.135,0,0,0-5.137,5.137v28.84a5.135,5.135,0,0,0,5.137,5.137h22.682l3.635,4.867,3.635-4.867h2.343a5.135,5.135,0,0,0,5.137-5.137h0v-28.84A5.18,5.18,0,0,0,194,114.1Z'
              transform='translate(-139.957 -107.19)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13169'
              data-name='Path 13169'
              d='M186.025,157.525l-4.056-5.468H159.708a6.026,6.026,0,0,1-6.008-6.008v-28.84a6.026,6.026,0,0,1,6.008-6.008h32.265a6.026,6.026,0,0,1,6.008,6.008v28.84a6.026,6.026,0,0,1-6.008,6.008h-1.893Zm-26.317-44.582a4.3,4.3,0,0,0-4.3,4.3v28.84a4.3,4.3,0,0,0,4.3,4.3h23.1l3.184,4.3,3.215-4.3h2.764a4.3,4.3,0,0,0,4.3-4.3v-28.84a4.3,4.3,0,0,0-4.3-4.3Z'
              transform='translate(-137.928 -105.162)'
              fill='#525682'
            />
            <path
              id='Path_13170'
              data-name='Path 13170'
              d='M203.7,140.9v5.948l-1.262-.751-1.262.751-1.262-.751-1.262.751-1.292-.751-1.262.751V140.9h-8.2v22.351h24.034V140.9Zm-7.06,19.467h-5.978v-2.4h5.978Z'
              transform='translate(-161.854 -125.939)'
              fill={fill}
            />
          </g>
        </g>
        <g id='Group_11217' data-name='Group 11217' transform='translate(154.239 156.092)'>
          <circle
            id='Ellipse_2925'
            data-name='Ellipse 2925'
            cx='16.794'
            cy='16.794'
            r='16.794'
            transform='translate(1.652 1.412)'
            fill='#46a200'
          />
          <path
            id='Path_13171'
            data-name='Path 13171'
            d='M410.346,365.3a18.446,18.446,0,1,0,18.446,18.446A18.45,18.45,0,0,0,410.346,365.3Zm16.643,18.506a16.643,16.643,0,1,1-16.643-16.643A16.661,16.661,0,0,1,426.989,383.806Z'
            transform='translate(-391.9 -365.3)'
            fill='#fff'
          />
          <path
            id='Path_13172'
            data-name='Path 13172'
            d='M441.459,404.565l-3.7-3.665-9.013,8.983-4.356-4.356-3.7,3.665,4.386,4.386h0l3.665,3.665h0l3.7-3.665h0Z'
            transform='translate(-412.048 -390.205)'
            fill='#fff'
          />
        </g>
      </g>
    </SvgIcon>
  );
};
