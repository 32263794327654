import { OperationType, PaymentOperationLog } from "@/schema/types";
import { WalletArrowIcon } from "@/shared/icons";
import { TFunction } from "@toolkit/core";
import { Theme } from "@toolkit/ui";

export const getTransactionDetails = (status: OperationType, number: number, theme: Theme) => {
  switch (status) {
    case OperationType.Refund:
    case OperationType.AddToWallet:
      return {
        color: theme.palette.success.main,
        icon: <WalletArrowIcon fill={theme.palette.success.main} />,
        formattedNumber: `+${number}`,
        operationType: "Refund",
      };

    case OperationType.DeductFromWallet:
    case OperationType.RefundFromWallet:
      return {
        color: theme.palette.error.main,
        icon: <WalletArrowIcon fill={theme.palette.error.main} direction='right' />,
        formattedNumber: `-${number}`,
        operationType: "Payment",
      };
    default:
      return {
        color: theme.palette.success.main,
        icon: <WalletArrowIcon fill={theme.palette.success.main} />,
        formattedNumber: number,
        operationType: status,
      };
  }
};
export const getTransaction = (item: PaymentOperationLog, t: TFunction) => {
  if (item?.appointmentId) {
    return t("Appointment");
  }
  if (item?.visitId) {
    return t("Virtual Consultation");
  }
  if (item?.orderId) {
    return item?.order?.number ? t("Order") + " #" + (item?.order?.number || "") : "";
  } else {
    return "";
  }
};
export const getDescription = (item: PaymentOperationLog) => {
  if (item?.appointmentId) {
    return item?.appointment?.doctor?.user?.fullName || "";
  }
  if (item?.visitId) {
    return item?.visit?.doctor?.user?.fullName || "";
  } else {
    return "";
  }
};
