import { AccountSettingContainer } from "./containers/AccountSettingContainer/AccountSettingContainer";
import { MyAccountsSettingsTabsEnum } from "./types";

export const AccountSettingsBaseRoute = "/account-settings";
export const routes = {
  root: {
    route: "/",
    component: AccountSettingContainer,
    get absRoute() {
      return AccountSettingsBaseRoute;
    },
    getRouteWithParams({ tab }: { tab?: MyAccountsSettingsTabsEnum }) {
      return tab ? `${this.absRoute}?tab=${tab}` : this.absRoute;
    },
  },
};
