import { makeStyles } from "@toolkit/ui";

export const useActivitiesItemStyles = makeStyles<{ color: string }>()((theme, { color }) => ({
  container: {
    display: "flex",
    gap: 10,
  },
  iconContainer: {
    width: 30,
    height: 30,
    borderRadius: "50%",
    backgroundColor: color,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      fill: theme.palette.common.white,
      width: 16,
      height: 16,
    },
    "& path": {
      fill: theme.palette.common.white,
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    gap: 0,
  },
  label: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    color: theme.palette.stale.main,
    textAlign: "start",
  },
  value: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: color,
    position: "relative",
    top: -4,
  },
  loader: {},
}));
