import { makeStyles } from "@toolkit/ui";

export const useRecipeCardStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: 270,
    width: 180,
  },
  imageContainer: {
    height: 140,
    width: "100%",
    "& img": {
      objectFit: "cover",
    },
  },
  image: {
    height: "100%",
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    padding: 10,
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  description: {
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    marginTop: 5,
  },
  tagsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    marginTop: 10,
    flexWrap: "wrap",
    gap: 5,
  },
  tag: {
    width: "max-content",
    padding: "5px 10px",
    borderRadius: 15,
    backgroundColor: theme.palette.secondary.main,
    fontSize: theme.mixins.fonts.fontSize.xs,
    color: theme.palette.common.white,
  },
}));
