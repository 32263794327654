import { zodSchema } from "@health/autocompletes";
import { z } from "zod";
export const AddressUpsertFormSchema = z.object({
  name: z.string().min(3),
  city: zodSchema.city.optional(),
  area: z.string().optional(),
  buildingNumber: z.string(),
  streetAddress1: z.string().min(3),
  streetAddress2: z.string().max(50).nullable(),
  unitNumber: z.string().max(50).nullable(),
  phone: z.string().min(4).max(15),
  isDefault: z.boolean().nullable(),
  coordinates: z.object({
    lat: z.number().nullable().optional(),
    lng: z.number().nullable().optional(),
  }),
});

export type IGoogleMapAddressUpsertFormValues = z.infer<typeof AddressUpsertFormSchema>;

export const addressUpsertFormSchemaDefaultValues: Partial<IGoogleMapAddressUpsertFormValues> = {
  name: undefined,
  city: undefined,
  area: undefined,
  buildingNumber: undefined,
  streetAddress1: undefined,
  streetAddress2: undefined,
  unitNumber: undefined,
  phone: undefined,
  isDefault: false,
  coordinates: {
    lat: undefined,
    lng: undefined,
  },
};
