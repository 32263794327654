import { useMemo } from "react";

import { CallParticipant, ZoomParticipant } from "./types";
import { useZoom } from "./useZoom";

export const useUser = (participant: CallParticipant) => {
  const { users } = useZoom();

  return useMemo(() => {
    if (!users || !participant.id || !users[participant.id]) {
      return {} as ZoomParticipant;
    }

    return users[participant.id];
  }, [users, participant.id]);
};
