import { useReducer, useEffect, useCallback } from "react";
import { debounce } from "lodash";
interface State {
  count: number;
  min: number;
  max: number;
}

type Action = { type: "increment" } | { type: "decrement" } | { type: "reset"; payload: { count: number; min: number; max: number } };

const initialState: State = { count: 1, min: 0, max: Number.MAX_SAFE_INTEGER };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "increment":
      return {
        ...state,
        count: Math.min(state.count + 1, state.max),
      };
    case "decrement":
      return {
        ...state,
        count: Math.max(state.count - 1, state.min),
      };
    case "reset":
      return {
        count: action.payload.count,
        min: action.payload.min,
        max: action.payload.max,
      };
    default:
      throw new Error("Unsupported action type");
  }
}

interface UseCounterProps {
  initial?: State;
  onCountChange?: (count: number) => void;
}

export function useCounter(props: UseCounterProps) {
  const { initial = initialState, onCountChange } = props;

  const [state, dispatchCounterAction] = useReducer(reducer, initial);

  const debouncedOnCountChange = useCallback(
    debounce((count: number) => {
      if (onCountChange) {
        onCountChange(count);
      }
    }, 1000),
    [onCountChange]
  );

  useEffect(() => {
    debouncedOnCountChange(state.count);
    return () => {
      debouncedOnCountChange.cancel();
    };
  }, [state.count]);
  const handleIncrement = () => {
    if (state.count < state.max) {
      dispatchCounterAction({ type: "increment" });
    }
  };

  const handleDecrement = () => {
    if (state.count >= state.min) {
      dispatchCounterAction({ type: "decrement" });
    }
  };

  const handleReset = (payload?: { count: number; min: number; max: number }) => {
    dispatchCounterAction({ type: "reset", payload: payload || initial });
  };

  useEffect(() => {
    handleReset({ count: initial.count, min: initial.min, max: initial.max });
  }, [JSON.stringify(initial)]);

  return {
    count: state.count,
    min: state.min,
    max: state.max,
    handleIncrement,
    handleDecrement,
    handleReset,
  };
}
