import { Box, formatDate, Grid, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useStyles } from "./CareJourneyCardStyle";
import { GuidedCareJourney, TeamMemberPosition } from "@/schema/types";
import { getMediaLink } from "@toolkit/core";
import { DoctorAvatar } from "@/shared/components";
import { RightArrow } from "@/shared/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@/i18n/i18n.config";
import { routes } from "../../routes";

export const CareJourneyCard: FC<{ careJourney: GuidedCareJourney }> = ({ careJourney }) => {
  const { t, i18n } = useTranslation("consumer");
  const navigate = useNavigate();
  const { classes, theme } = useStyles();
  const lang = i18n.language;
  const careJourneyTeam = careJourney?.providerGuidedCareHealthProgramTeam;

  const careJourneyTeamLeader = careJourneyTeam?.teamMembers?.find(
    teamMember => teamMember?.guidedCareProgramTeamMember?.position === TeamMemberPosition.TeamLeader && !teamMember?.isBackup
  );

  const mediaImageLink = getMediaLink(careJourneyTeamLeader?.user?.photo);
  const onGoToCareJourneyDetails = () => {
    if (!careJourney?.id) return;
    navigate(routes.journeyDetails.getRouteWithParams({ journeyId: careJourney?.id }));
  };
  return (
    <Box className={classes.cardWrapper} role='button' onClick={onGoToCareJourneyDetails}>
      <Grid container alignItems='center' gap={2} className={"card__container"}>
        <Grid className={classes.imageContainer}>
          <DoctorAvatar imageUrl={mediaImageLink} />
          <div className={classes.leaderLabel}>
            <Typography variant='caption'>{t("Team Leader")}</Typography>
          </div>
        </Grid>
        <Grid className={classes.textWrapper}>
          {careJourneyTeam?.name && <Typography className={classes.careTeamName}>{careJourneyTeam?.name}</Typography>}
          {careJourneyTeam?.guidedCareHealthProgram?.name && <Typography>{careJourneyTeam?.guidedCareHealthProgram?.name}</Typography>}
          {careJourneyTeamLeader?.user?.fullName && <Typography>{careJourneyTeamLeader?.user?.fullName}</Typography>}
          <Typography color={theme.palette.gray.main}>
            {`${formatDate(careJourney?.startDate, lang)} - ${formatDate(careJourney?.endDate, lang)}`}
          </Typography>
        </Grid>
      </Grid>
      <RightArrow />
    </Box>
  );
};
