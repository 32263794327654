import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import { FC } from "react";

export const OrderOnTheWayIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='132.94' height='130.78' viewBox='0 0 132.94 130.78' {...props}>
      <g id='rder_has_been_placed_v2' data-name='rder has been placed v2' transform='translate(-52.179 -66.252)'>
        <path
          id='Path_12746_00000181086977908612989720000002170403974832897214_'
          d='M118.094,194.38c-15.175-3.432-28.874-8.852-41.49-18.487-12.5-9.6-23.967-23.334-24.418-37.4-.3-14.031,10.327-28.333,21.919-39.563a115.936,115.936,0,0,1,38.78-25.653c14.934-6.172,32.789-10.207,44.983-3.733C170,76.082,176.475,93.124,180.119,108.9c3.7,15.747,4.546,30.229,1.837,45.284s-9,30.561-20.5,37.636c-11.5,7.166-28.152,5.841-43.357,2.559'
          transform='translate(0 0)'
          fill='#2b3078'
          opacity='0.09'
        />
        <g id='Group_11215' data-name='Group 11215' transform='translate(52.908 103.451)'>
          <path
            id='Path_13151'
            data-name='Path 13151'
            d='M71.33,208.195l-2.559-2.559a.873.873,0,0,1,0-1.265h0a.873.873,0,0,1,1.265,0l2.559,2.559a.873.873,0,0,1,0,1.265h0A.873.873,0,0,1,71.33,208.195Z'
            transform='translate(-64.315 -199.794)'
            fill='#525682'
          />
          <path
            id='Path_13152'
            data-name='Path 13152'
            d='M54.6,230.5h0a.887.887,0,0,1,.9-.9h3.613a.887.887,0,0,1,.9.9h0a.887.887,0,0,1-.9.9H55.5A.948.948,0,0,1,54.6,230.5Z'
            transform='translate(-54.6 -217.617)'
            fill='#525682'
          />
          <path
            id='Path_13153'
            data-name='Path 13153'
            d='M92.9,194.316V190.7a.887.887,0,0,1,.9-.9h0a.887.887,0,0,1,.9.9v3.613a.887.887,0,0,1-.9.9h0A.906.906,0,0,1,92.9,194.316Z'
            transform='translate(-81.368 -189.8)'
            fill='#525682'
          />
        </g>
        <rect
          id='Rectangle_7094'
          data-name='Rectangle 7094'
          width='106.074'
          height='1.867'
          transform='translate(54.715 184.053)'
          fill='#525682'
        />
        <g id='Group_11216' data-name='Group 11216' transform='translate(66.939 73.734)'>
          <g id='Delivery_Truck' transform='translate(0 0)'>
            <path
              id='Path_13154'
              data-name='Path 13154'
              d='M430.1,234.143a3.406,3.406,0,1,1,6.8.3,3.316,3.316,0,0,1-1.084,2.349,3.419,3.419,0,0,1-4.818-.181A3.483,3.483,0,0,1,430.1,234.143Zm3.4-1.536a1.691,1.691,0,0,0-1.686,1.626h0a1.673,1.673,0,1,0,1.746-1.6C433.558,232.607,433.528,232.607,433.5,232.607Z'
              transform='translate(-331.067 -188.799)'
              fill='#525682'
            />
            <path
              id='Path_13155'
              data-name='Path 13155'
              d='M476.194,269.024a2.637,2.637,0,0,1,5.269.211,2.688,2.688,0,0,1-.843,1.837,2.619,2.619,0,0,1-3.7-.151A2.557,2.557,0,0,1,476.194,269.024Zm2.619-.813a.92.92,0,0,0-.9.873h0a.87.87,0,0,0,.873.933.888.888,0,0,0,.933-.873.87.87,0,0,0-.873-.933Z'
              transform='translate(-363.286 -213.684)'
              fill='#525682'
            />
            <path
              id='Path_13156'
              data-name='Path 13156'
              d='M271.808,119.493h-1.716v-1.716h1.716Zm0-4.607h-1.716V113.17h1.716Zm0-4.607h-1.716v-1.716h1.716Zm0-4.607h-1.716v-1.716h1.716Zm-1.957-4.4a14.81,14.81,0,0,0-.452-1.536l1.626-.6a11.993,11.993,0,0,1,.512,1.776Zm-1.656-3.884a9.909,9.909,0,0,0-.994-1.234l1.265-1.2A13.647,13.647,0,0,1,269.64,96.4Zm-3.071-2.921a12.732,12.732,0,0,0-1.415-.753l.693-1.566a10.522,10.522,0,0,1,1.626.873Zm-3.944-1.505a13.656,13.656,0,0,0-1.6-.151l.03-1.716a12.261,12.261,0,0,1,1.837.151Zm-4.456-.151h-1.716V91.1h1.716Zm-4.607,0H250.4V91.1h1.716Z'
              transform='translate(-205.477 -91.1)'
              fill='#a9b4d3'
            />
            <rect
              id='Rectangle_7095'
              data-name='Rectangle 7095'
              width='62.476'
              height='5.751'
              transform='translate(30.019 96.168)'
              fill='#2b3078'
              opacity='0.09'
            />
            <rect
              id='Rectangle_7096'
              data-name='Rectangle 7096'
              width='21.046'
              height='10.147'
              transform='translate(92.465 96.168)'
              fill='#f5f5f5'
            />
            <rect
              id='Rectangle_7097'
              data-name='Rectangle 7097'
              width='13.88'
              height='10.147'
              transform='translate(0.873 96.168)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13157'
              data-name='Path 13157'
              d='M262.192,415.067H198V407.6h64.192Zm-62.476-1.716h60.73v-4.035h-60.73Z'
              transform='translate(-168.855 -312.305)'
              fill='#525682'
            />
            <path
              id='Path_13158'
              data-name='Path 13158'
              d='M428.263,419.463H405.5V407.6h22.762Zm-21.046-1.716h19.33v-8.4h-19.33Z'
              transform='translate(-313.878 -312.305)'
              fill='#525682'
            />
            <path
              id='Path_13159'
              data-name='Path 13159'
              d='M116.827,419.463H101.2V407.6h15.627Zm-13.88-1.716H115.11v-8.4H102.946Z'
              transform='translate(-101.2 -312.305)'
              fill='#525682'
            />
            <path
              id='Path_13160'
              data-name='Path 13160'
              d='M352.1,263.8h12.013l6.835,4.065h9.394l7.166,22.973V307.97H352.1Z'
              transform='translate(-276.557 -211.802)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13161'
              data-name='Path 13161'
              d='M386.394,306.816H349.3V260.9h13.128l6.835,4.065h9.755l7.407,23.726Zm-35.378-1.746h33.662V288.932l-6.955-22.251H368.75l-6.835-4.065h-10.93Z'
              transform='translate(-274.6 -209.775)'
              fill='#525682'
            />
            <path
              id='Path_13162'
              data-name='Path 13162'
              d='M423.093,307.574,418.487,292.7H405.6v14.874Z'
              transform='translate(-313.948 -232)'
              fill='#525682'
            />
            <rect
              id='Rectangle_7098'
              data-name='Rectangle 7098'
              width='5.721'
              height='14.874'
              transform='translate(81.083 60.7)'
              fill='#525682'
            />
            <circle
              id='Ellipse_2919'
              data-name='Ellipse 2919'
              cx='7.979'
              cy='7.979'
              r='7.979'
              transform='translate(6.805 96.168)'
              fill='#525682'
            />
            <circle
              id='Ellipse_2920'
              data-name='Ellipse 2920'
              cx='2.951'
              cy='2.951'
              r='2.951'
              transform='translate(11.833 101.196)'
              fill='#f5f5f5'
            />
            <rect
              id='Rectangle_7099'
              data-name='Rectangle 7099'
              width='74.67'
              height='53.594'
              transform='translate(0.873 42.574)'
              fill='#dee1ec'
            />
            <rect
              id='Rectangle_7100'
              data-name='Rectangle 7100'
              width='74.67'
              height='53.594'
              transform='translate(0.873 42.574)'
              fill='#fff'
            />
            <path
              id='Path_13163'
              data-name='Path 13163'
              d='M149.66,265.2h0a3.161,3.161,0,0,1,3.161,3.161V296.9a3.161,3.161,0,0,1-3.161,3.161h0A3.161,3.161,0,0,1,146.5,296.9V268.362A3.142,3.142,0,0,1,149.66,265.2Z'
              transform='translate(-132.86 -212.78)'
              fill='#2b3078'
            />
            <path
              id='Path_13164'
              data-name='Path 13164'
              d='M220.062,265.2h0a3.161,3.161,0,0,1,3.161,3.161V296.9a3.161,3.161,0,0,1-3.161,3.161h0A3.161,3.161,0,0,1,216.9,296.9V268.362A3.161,3.161,0,0,1,220.062,265.2Z'
              transform='translate(-182.064 -212.78)'
              fill='#2b3078'
            />
            <path
              id='Path_13165'
              data-name='Path 13165'
              d='M290.461,265.2h0a3.161,3.161,0,0,1,3.161,3.161V296.9a3.161,3.161,0,0,1-3.161,3.161h0A3.161,3.161,0,0,1,287.3,296.9V268.362A3.161,3.161,0,0,1,290.461,265.2Z'
              transform='translate(-231.267 -212.78)'
              fill='#2b3078'
            />
            <circle
              id='Ellipse_2921'
              data-name='Ellipse 2921'
              cx='7.979'
              cy='7.979'
              r='7.979'
              transform='translate(22.04 96.168)'
              fill='#525682'
            />
            <circle
              id='Ellipse_2922'
              data-name='Ellipse 2922'
              cx='2.951'
              cy='2.951'
              r='2.951'
              transform='translate(27.068 101.196)'
              fill='#f5f5f5'
            />
            <circle
              id='Ellipse_2923'
              data-name='Ellipse 2923'
              cx='7.979'
              cy='7.979'
              r='7.979'
              transform='translate(84.486 96.168)'
              fill='#525682'
            />
            <circle
              id='Ellipse_2924'
              data-name='Ellipse 2924'
              cx='2.951'
              cy='2.951'
              r='2.951'
              transform='translate(89.514 101.196)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13166'
              data-name='Path 13166'
              d='M193.187,232.5a9.884,9.884,0,0,1-9.7,8.28l-5.992,8.069-5.992-8.069H151.125a9.86,9.86,0,0,1-9.725-8.28Z'
              transform='translate(-129.296 -189.926)'
              fill='#2b3078'
              opacity='0.09'
            />
            <path
              id='Path_13167'
              data-name='Path 13167'
              d='M177.686,284.94H101.3V229.6h76.386Zm-74.67-1.746H175.97V231.346H103.016Z'
              transform='translate(-101.27 -187.899)'
              fill='#525682'
            />
            <path
              id='Path_13168'
              data-name='Path 13168'
              d='M194.086,114.1H161.749a5.146,5.146,0,0,0-5.149,5.149v28.9a5.146,5.146,0,0,0,5.149,5.149h22.732l3.643,4.878,3.643-4.878h2.348a5.146,5.146,0,0,0,5.149-5.149h0v-28.9A5.192,5.192,0,0,0,194.086,114.1Z'
              transform='translate(-139.92 -107.175)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13169'
              data-name='Path 13169'
              d='M186.1,157.628l-4.065-5.48H159.722a6.04,6.04,0,0,1-6.022-6.022v-28.9a6.04,6.04,0,0,1,6.022-6.022h32.337a6.04,6.04,0,0,1,6.022,6.022v28.9a6.04,6.04,0,0,1-6.022,6.022h-1.9Zm-26.375-44.682a4.3,4.3,0,0,0-4.306,4.306v28.9a4.3,4.3,0,0,0,4.306,4.306h23.154l3.192,4.306,3.222-4.306h2.77a4.3,4.3,0,0,0,4.306-4.306v-28.9a4.3,4.3,0,0,0-4.306-4.306Z'
              transform='translate(-137.893 -105.148)'
              fill='#525682'
            />
            <path
              id='Path_13170'
              data-name='Path 13170'
              d='M203.737,140.9v5.962l-1.265-.753-1.265.753-1.265-.753-1.265.753-1.295-.753-1.265.753V140.9H187.9v22.4h24.087V140.9Zm-7.076,19.511H190.67V158h5.992Z'
              transform='translate(-161.796 -125.906)'
              fill='#2b3078'
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
