import { FC, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { Call } from "@health/meeting";
import { useNavigate } from "react-router-dom";

type VisitCallProps = {
  visitId: string;
};

export const VisitCall: FC<VisitCallProps> = ({ visitId }) => {
  // todo: apply refresh token logic
  const { user } = useAuth();
  const token = user?.access_token;
  const navigate = useNavigate();
  const onRequestClose = useCallback(() => navigate("/"), [navigate]);

  if (!token) {
    return <div>Unauthorized</div>;
  }

  return <Call visitId={visitId} onClose={onRequestClose} isEndVisitOnLeave={true} />;
};
