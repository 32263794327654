import { Box, CustomInfiniteScroll } from "@toolkit/ui";
import React, { FC } from "react";
import { useMedicationsOrdersList } from "./useMedicationsOrdersList.hook";
import { useContainerHeight } from "@/shared/hooks";
import { useMedicationsOrdersListStyles } from "./useMedicationsOrdersList.styles";
import { EmptyMessageLayout } from "@/shared/components";
import { EmptyMedicationsOrderIcon } from "@/shared/icons";
import isEmpty from "lodash/isEmpty";
import { MedicationsOrderTrackingCard } from "../MedicationsOrderTrackingCard/MedicationsOrderTrackingCard";
import { MedicationsOrderTrackingCardSkeleton } from "../MedicationsOrderTrackingCard/MedicationsOrderTrackingCardSkeleton";
import { useTranslation } from "@toolkit/i18n";

export const MedicationsOrdersList: FC = () => {
  const { orders, isLoading, fetchMoreData, hasMore, handleRefresh } = useMedicationsOrdersList();
  const { containerRef, containerHeight } = useContainerHeight();
  const { classes } = useMedicationsOrdersListStyles();
  const { t } = useTranslation("consumer");

  return (
    <React.Fragment>
      {isLoading && isEmpty(orders) && (
        <Box>
          {Array.from({ length: 10 }).map((_, index) => (
            <Box key={`loader-${index}`}>
              <MedicationsOrderTrackingCardSkeleton />
            </Box>
          ))}
        </Box>
      )}

      <Box ref={containerRef} className={classes.content}>
        <CustomInfiniteScroll
          dataLength={orders?.length || 1}
          onFetchMore={fetchMoreData}
          hasMore={hasMore}
          loader={
            <Box mt={"10px"}>
              {Array.from({ length: 2 }).map((_, index) => (
                <Box key={`loader-${index}`}>
                  <MedicationsOrderTrackingCardSkeleton />
                </Box>
              ))}
            </Box>
          }
          height={(containerHeight || 15) - 10}
          onRefresh={handleRefresh}
        >
          {!isLoading && isEmpty(orders) && (
            <Box className={classes.emptyPageContainer}>
              <EmptyMessageLayout
                icon={<EmptyMedicationsOrderIcon />}
                message={t("No Medication Orders Found")}
                subMessage={"You currently have no medication orders. Start by requesting a new prescription order."}
              />
            </Box>
          )}

          {!isEmpty(orders) && (
            <Box>
              {orders?.map((order, index) => (
                <MedicationsOrderTrackingCard key={"order" + order.id + "-" + index} order={order} />
              ))}
            </Box>
          )}
        </CustomInfiniteScroll>
      </Box>
    </React.Fragment>
  );
};
