import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import { FC } from "react";

export const PaymentShareIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='cash_timer'
      data-name='cash timer'
      width='149.999'
      height='131.304'
      viewBox='0 0 149.999 131.304'
      {...props}
    >
      <defs>
        <linearGradient id='linear-gradient' x1='-106.558' y1='21.933' x2='-105.929' y2='21.933' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#cacfdd' />
          <stop offset='1' stopColor='#eef0f1' />
        </linearGradient>
      </defs>
      <path
        id='Path_12746_00000181086977908612989720000002170403974832897214_'
        d='M131.458,194.893c-15.236-3.446-28.99-8.887-41.656-18.561-12.545-9.643-24.063-23.428-24.516-37.545-.3-14.087,10.369-28.446,22.007-39.722A116.4,116.4,0,0,1,126.228,73.31c14.994-6.2,32.92-10.248,45.163-3.748,12.182,6.56,18.682,23.67,22.34,39.51,3.718,15.81,4.565,30.35,1.844,45.465s-9.039,30.683-20.586,37.787c-11.548,7.195-28.265,5.865-43.53,2.569'
        transform='translate(-56.186 -66.252)'
        fill='#2b3078'
        opacity='0.09'
      />
      <g id='Group_11362' data-name='Group 11362' transform='translate(0 43.303)'>
        <g id='Group_11361' data-name='Group 11361'>
          <g id='paylater'>
            <path
              id='Path_13389'
              data-name='Path 13389'
              d='M38.2,227.7v29.171H60.449l5.744,4.051a21.253,21.253,0,0,0,20.314.393,21.091,21.091,0,0,1,9.764-2.388l17.08-.03a8.734,8.734,0,0,0,8.706-8.736h0v-3.748a10.8,10.8,0,0,0-4.383-8.676l-14.359-10.973a17.5,17.5,0,0,0-18.682-2.811L62.2,233.446Z'
              transform='translate(-37.293 -218.513)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13390'
              data-name='Path 13390'
              d='M65.041,259.645c-.121-.06.333.242-5.592-3.93H37.5V253.9H60.023l5.955,4.172a20.291,20.291,0,0,0,19.407.363,22.016,22.016,0,0,1,10.157-2.479l14.964-.03a6.41,6.41,0,0,0-.03-12.817l-22.672.03v-1.814c24.063-.06,22.763-.06,23.1-.03a8.222,8.222,0,0,1,6.046,13.3,7.718,7.718,0,0,0,3.446-6.5v-3.748a9.965,9.965,0,0,0-4.051-7.95l-14.389-10.973c-5.411-4.383-11.729-5.32-17.775-2.69l-22.7,9.613L37.2,226.514l.423-1.753,23.7,5.683,22.158-9.371c6.681-2.9,13.633-1.874,19.589,2.932l14.359,10.943a11.812,11.812,0,0,1,4.746,9.371v3.748a9.637,9.637,0,0,1-9.583,9.643h0l-17.08.03a20.606,20.606,0,0,0-9.341,2.3A22.041,22.041,0,0,1,65.041,259.645Z'
              transform='translate(-36.595 -216.447)'
              fill='#525682'
            />
            <rect
              id='Rectangle_7354'
              data-name='Rectangle 7354'
              width='16.384'
              height='34.069'
              transform='translate(12.122 11.094)'
              fill='#2b3078'
            />
            <path
              id='Path_13391'
              data-name='Path 13391'
              d='M90.468,279.113H72.3V243.2H90.5ZM74.114,277.3H88.684V245.014H74.114Z'
              transform='translate(-61.085 -233.013)'
              fill='#525682'
            />
            <path
              id='Path_13392'
              data-name='Path 13392'
              d='M38.2,271.926H49.415v-34.1L38.2,234.2Z'
              transform='translate(-37.293 -226.733)'
              fill='#525682'
            />
            <path
              id='Path_13393'
              data-name='Path 13393'
              d='M48.229,269.973H35.2V230.1l13.029,4.232Zm-11.215-1.814h9.4V235.632l-9.4-3.053Z'
              transform='translate(-35.2 -223.873)'
              fill='#525682'
            />
            <path
              id='Path_13394'
              data-name='Path 13394'
              d='M48.229,269.973H35.2V230.1l13.029,4.232Zm-11.215-1.814h9.4V235.632l-9.4-3.053Z'
              transform='translate(-35.2 -223.873)'
              fill='#525682'
            />
            <g id='Credit_Card' transform='translate(32.348)'>
              <g id='Group_11358' data-name='Group 11358' transform='translate(25.934 14.54)'>
                <path
                  id='Path_13395'
                  data-name='Path 13395'
                  d='M234.644,259.9h61.7a4.432,4.432,0,0,1,4.444,4.444v35.671a4.432,4.432,0,0,1-4.444,4.444h-61.7a4.432,4.432,0,0,1-4.444-4.444V264.344A4.432,4.432,0,0,1,234.644,259.9Z'
                  transform='translate(-229.535 -259.205)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13396'
                  data-name='Path 13396'
                  d='M300.786,264.244v35.671a4.451,4.451,0,0,1-4.444,4.444h-61.7a4.451,4.451,0,0,1-4.444-4.444V264.244a4.451,4.451,0,0,1,4.444-4.444h61.7A4.471,4.471,0,0,1,300.786,264.244Z'
                  transform='translate(-229.535 -259.135)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13397'
                  data-name='Path 13397'
                  d='M294.807,303.458h-61.7A5.122,5.122,0,0,1,228,298.349h0V262.709a5.122,5.122,0,0,1,5.109-5.109h61.7a5.122,5.122,0,0,1,5.109,5.109V298.38a5.116,5.116,0,0,1-5.109,5.079Zm-61.7-44.5a3.78,3.78,0,0,0-3.779,3.779V298.41a3.78,3.78,0,0,0,3.779,3.779h61.7a3.78,3.78,0,0,0,3.779-3.779V262.739a3.78,3.78,0,0,0-3.779-3.779Z'
                  transform='translate(-228 -257.6)'
                  fill='#525682'
                />
                <rect
                  id='Rectangle_7355'
                  data-name='Rectangle 7355'
                  width='12.908'
                  height='5.441'
                  transform='translate(9.613 26.904)'
                  fill='#2b3078'
                  opacity='0.09'
                />
                <rect
                  id='Rectangle_7356'
                  data-name='Rectangle 7356'
                  width='12.908'
                  height='5.441'
                  transform='translate(29.655 26.904)'
                  fill='#2b3078'
                  opacity='0.09'
                />
                <rect
                  id='Rectangle_7357'
                  data-name='Rectangle 7357'
                  width='12.908'
                  height='5.441'
                  transform='translate(49.667 26.904)'
                  fill='#2b3078'
                  opacity='0.09'
                />
                <rect
                  id='Rectangle_7358'
                  data-name='Rectangle 7358'
                  width='32.95'
                  height='1.33'
                  transform='translate(9.613 37.182)'
                  fill='#2b3078'
                />
                <rect
                  id='Rectangle_7359'
                  data-name='Rectangle 7359'
                  width='18.44'
                  height='2.539'
                  transform='translate(44.165 9.371)'
                  fill='#a9b4d3'
                />
                <rect
                  id='Rectangle_7360'
                  data-name='Rectangle 7360'
                  width='7.678'
                  height='2.539'
                  transform='translate(54.897 14.661)'
                  fill='#a9b4d3'
                />
              </g>
              <path
                id='Path_13398'
                data-name='Path 13398'
                d='M143.052,375.433a1.993,1.993,0,1,1,2.328,3.235,2.025,2.025,0,0,1-1.511.333,1.989,1.989,0,0,1-1.632-2.3A2.433,2.433,0,0,1,143.052,375.433Zm.786,1.088a.671.671,0,0,0,.786,1.088.653.653,0,0,0,.272-.423.638.638,0,0,0-.544-.756.692.692,0,0,0-.514.091Z'
                transform='translate(-142.208 -325.01)'
                fill='#525682'
              />
              <path
                id='Path_13399'
                data-name='Path 13399'
                d='M167.291,388.073a2.717,2.717,0,1,1,3.779.635A2.712,2.712,0,0,1,167.291,388.073Zm3.355-2.418a1.409,1.409,0,0,0-2.3,1.632,1.409,1.409,0,0,0,2.3-1.632Z'
                transform='translate(-159.343 -331.091)'
                fill='#525682'
              />
              <path
                id='Path_13400'
                data-name='Path 13400'
                d='M473.7,334.037V332.4a4.914,4.914,0,0,0,1.481-.121l.3,1.632A8.848,8.848,0,0,1,473.7,334.037Zm4.595-1.33-.937-1.33a6.38,6.38,0,0,0,1.058-.967l1.239,1.058A7.529,7.529,0,0,1,478.295,332.707Zm2.842-3.93-1.6-.453a5.6,5.6,0,0,0,.242-1.421l1.632.06A5.286,5.286,0,0,1,481.136,328.777Zm.3-4.595h-1.632V322.55h1.632Zm0-4.383h-1.632v-1.632h1.632Zm0-4.383h-1.632v-1.632h1.632Zm0-4.383h-1.632V309.4h1.632Z'
                transform='translate(-373.492 -279.201)'
                fill='#a9b4d3'
              />
              <g id='Group_11360' data-name='Group 11360' transform='translate(79.955)'>
                <path
                  id='Path_13401'
                  data-name='Path 13401'
                  d='M424.445,277.073A18.127,18.127,0,0,1,406.7,259.6l13.634.272a4.414,4.414,0,0,1,4.353,4.534h0Z'
                  transform='translate(-406.7 -244.455)'
                  fill='#525682'
                  opacity='0.1'
                />
                <circle
                  id='Ellipse_3065'
                  data-name='Ellipse 3065'
                  cx='14.51'
                  cy='14.51'
                  r='14.51'
                  transform='translate(2.872 0.665)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13402'
                  data-name='Path 13402'
                  d='M431.175,239.85a15.175,15.175,0,1,1,15.175-15.175A15.165,15.165,0,0,1,431.175,239.85Zm0-29.051a13.845,13.845,0,1,0,13.845,13.845A13.862,13.862,0,0,0,431.175,210.8Z'
                  transform='translate(-413.189 -209.5)'
                  fill='#525682'
                />
                <g id='Group_11359' data-name='Group 11359' transform='translate(13.724 6.076)'>
                  <path
                    id='Path_13403'
                    data-name='Path 13403'
                    d='M456.665,249.487a4.58,4.58,0,0,1-4.565-4.565h2.842a1.708,1.708,0,1,0,1.723-1.693h0a4.565,4.565,0,1,1,4.565-4.565h-2.842a1.693,1.693,0,1,0-3.386,0,1.716,1.716,0,0,0,1.693,1.723h0a4.55,4.55,0,1,1-.03,9.1Z'
                    transform='translate(-452.1 -232.74)'
                    fill='#2b3078'
                  />
                  <rect
                    id='Rectangle_7361'
                    data-name='Rectangle 7361'
                    width='2.842'
                    height='2.842'
                    transform='translate(3.114)'
                    fill='#2b3078'
                  />
                  <rect
                    id='Rectangle_7362'
                    data-name='Rectangle 7362'
                    width='2.842'
                    height='2.842'
                    transform='translate(3.114 15.326)'
                    fill='#2b3078'
                  />
                </g>
              </g>
              <path
                id='Path_13404'
                data-name='Path 13404'
                d='M350.8,421.723a3.032,3.032,0,0,1,3.023,3.023h0a3.032,3.032,0,0,1,3.023-3.023,3.032,3.032,0,0,1-3.023-3.023h0A3.012,3.012,0,0,1,350.8,421.723Z'
                transform='translate(-287.744 -355.46)'
                fill='#fff'
              />
            </g>
            <path
              id='Path_13405'
              data-name='Path 13405'
              d='M265.8,285.342a3.629,3.629,0,0,0,1.239-2.751v-.726a3.643,3.643,0,0,0-7.285,0v.726a3.677,3.677,0,0,0,1.239,2.751,4.592,4.592,0,0,0-4.293,4.565v4.323h13.392v-4.293A4.6,4.6,0,0,0,265.8,285.342Z'
              transform='translate(-189.742 -257.501)'
              fill='#2b3078'
            />
            <path
              id='Path_13406'
              data-name='Path 13406'
              d='M200.1,294.73l22.672-.03a7.341,7.341,0,0,1,7.346,7.285h0a7.341,7.341,0,0,1-7.285,7.346h0l-14.964.03a21.182,21.182,0,0,0-9.764,2.388'
              transform='translate(-148.856 -268.944)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13407'
              data-name='Path 13407'
              d='M197.446,310.369l-.846-1.6a22.016,22.016,0,0,1,10.157-2.479l14.964-.03a6.425,6.425,0,0,0,0-12.848l-22.672.03v-1.814l22.672-.03a8.224,8.224,0,0,1,.363,16.445h-.3l-14.964.03A20.635,20.635,0,0,0,197.446,310.369Z'
              transform='translate(-147.81 -266.778)'
              fill='#525682'
            />
            <circle
              id='Ellipse_3066'
              data-name='Ellipse 3066'
              cx='2.449'
              cy='2.449'
              r='2.449'
              transform='translate(17.835 17.533)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13408'
              data-name='Path 13408'
              d='M239.9,390.282h-1.36v-1.814h1.36Zm-4.383,0H233.7v-1.814h1.814Zm-4.837,0h-1.814v-1.814h1.814Zm-4.837,0h-1.814v-1.814h1.814Zm-4.837,0h-1.814v-1.814h1.814Zm-4.958-.181a13.112,13.112,0,0,1-1.874-.484l.574-1.723a9.22,9.22,0,0,0,1.632.423Zm-4.806-1.935a11.8,11.8,0,0,1-1.542-1.209l1.209-1.36a11.122,11.122,0,0,0,1.33,1.028Zm-3.658-3.658a13.3,13.3,0,0,1-.937-1.693l1.663-.756a11.236,11.236,0,0,0,.816,1.451Zm-1.874-4.807a10.476,10.476,0,0,1-.181-1.935l1.814-.03a10.3,10.3,0,0,0,.151,1.663Zm1.6-4.988H205.5V372.9h1.814Z'
              transform='translate(-154.019 -323.505)'
              fill='#a9b4d3'
            />
          </g>
        </g>
      </g>
      <g id='Group_11364' data-name='Group 11364' transform='translate(60.254 8.302)'>
        <rect
          id='Rectangle_7363'
          data-name='Rectangle 7363'
          width='2.237'
          height='25.272'
          transform='translate(24.895 22.65) rotate(-9.306)'
          fill='#f5f5f5'
        />
        <path
          id='Path_13409'
          data-name='Path 13409'
          d='M319.107,167.4l1.33,8.1a9.1,9.1,0,0,0-2.207.363l-1.33-8.1Z'
          transform='translate(-291.998 -145.125)'
          fill='url(#linear-gradient)'
        />
        <path
          id='Path_13410'
          data-name='Path 13410'
          d='M315.4,166.384l2.993-.484,4.232,25.756-2.993.484Zm2.328.453-1.391.242L320.3,191.2l1.391-.242Z'
          transform='translate(-290.951 -144.079)'
          fill='#525682'
        />
        <path
          id='Path_13411'
          data-name='Path 13411'
          d='M331.055,238.092a6.036,6.036,0,0,1-6.983-4.9l-1.572-9.039,4.353-.756,1.572,9.008a1.656,1.656,0,0,0,1.784,1.36h.091a1.614,1.614,0,0,0,1.3-1.874l-.091-.635a2.132,2.132,0,0,1,1.753-2.479l.121-.03h0a2.132,2.132,0,0,1,2.479,1.753l.091.514a6.084,6.084,0,0,1-4.776,7.043A.229.229,0,0,0,331.055,238.092Z'
          transform='translate(-295.905 -184.197)'
          fill='#25287f'
        />
        <path
          id='Path_13412'
          data-name='Path 13412'
          d='M330.764,264.176A7.288,7.288,0,0,1,323.6,258.1h14.3a7.3,7.3,0,0,1-5.8,5.955.182.182,0,0,0-.121.03A8.453,8.453,0,0,1,330.764,264.176Z'
          transform='translate(-296.672 -208.407)'
          fill='#525682'
          opacity='0.1'
        />
        <g id='Group_11363' data-name='Group 11363'>
          <path
            id='Path_13413'
            data-name='Path 13413'
            d='M300.078,99.514l-.181-4.262a1.5,1.5,0,0,1,.967-1.451,1.469,1.469,0,0,1,1.874.877l1.481,3.99.7,3.416-4.141.846Zm3.265-.6-1.451-3.93a.6.6,0,0,0-.544-.363.568.568,0,0,0-.544.574l.181,4.2.484,2.418,2.358-.484Z'
            transform='translate(-280.133 -93.715)'
            fill='#525682'
          />
          <path
            id='Path_13414'
            data-name='Path 13414'
            d='M301.4,96.218l.181,4.232.574,2.9,3.235-.665-.574-2.9-1.481-3.99a1,1,0,0,0-1.935.423Z'
            transform='translate(-281.179 -94.711)'
            fill='#25287f'
          />
          <path
            id='Path_13415'
            data-name='Path 13415'
            d='M289.042,130.829a4.278,4.278,0,0,0-.121-.6,8.964,8.964,0,1,0-17.442,4.141,4.274,4.274,0,0,0-.121-.6,8.964,8.964,0,0,0-17.442,4.141,4.278,4.278,0,0,0-.121-.6,8.964,8.964,0,1,0-17.442,4.141c-.03-.121-.06-.272-.091-.393a26.88,26.88,0,1,1,52.781-10.218Z'
            transform='translate(-235.392 -104.413)'
            fill='#fff'
            opacity='0.09'
          />
          <path
            id='Path_13416'
            data-name='Path 13416'
            d='M257.662,110.364h0a26.865,26.865,0,0,1,31.65,21.01c-.03-.212-.091-.423-.121-.635a8.952,8.952,0,0,0-17.412,4.172,8.966,8.966,0,0,0-17.563,3.567,8.963,8.963,0,0,0-17.563,3.537A26.849,26.849,0,0,1,257.662,110.364Z'
            transform='translate(-235.631 -104.957)'
            fill='#fff'
          />
          <path
            id='Path_13417'
            data-name='Path 13417'
            d='M318.943,127.34A23.641,23.641,0,0,0,306.7,111.016l.423-.816a24.564,24.564,0,0,1,12.727,16.959l1.6,7.92-.907.181Z'
            transform='translate(-284.881 -105.217)'
            fill='#525682'
          />
          <path
            id='Path_13418'
            data-name='Path 13418'
            d='M287.965,131.256a24.521,24.521,0,0,1,5.169-20.556l.7.574a23.654,23.654,0,0,0-4.958,19.8l1.6,7.92-.907.181Z'
            transform='translate(-271.466 -105.565)'
            fill='#525682'
          />
          <path
            id='Path_13419'
            data-name='Path 13419'
            d='M256.462,108.84a27.374,27.374,0,0,1,32.194,21.372l-.907.181a3.209,3.209,0,0,0-.121-.574,8.5,8.5,0,1,0-16.536,3.96l-.907.181a3.212,3.212,0,0,0-.121-.574,8.5,8.5,0,1,0-16.536,3.96l-.907.181a3.215,3.215,0,0,0-.121-.574,8.5,8.5,0,0,0-16.536,3.96l-.907.181A27.409,27.409,0,0,1,256.462,108.84Zm21.07,14.026a9.415,9.415,0,0,1,8.8,2.872,26.425,26.425,0,0,0-50.906,10.278,9.4,9.4,0,0,1,17.049-1.421A9.4,9.4,0,0,1,270,131.028a9.478,9.478,0,0,1,7.527-8.162Z'
            transform='translate(-234.522 -103.886)'
            fill='#525682'
          />
        </g>
      </g>
      <g id='Group_11365' data-name='Group 11365' transform='translate(54.981 11.979)'>
        <path
          id='Path_13420'
          data-name='Path 13420'
          d='M231.251,126.747l-2.842-2.267a.912.912,0,0,1-.151-1.27h0a.912.912,0,0,1,1.27-.151l2.842,2.267a.912.912,0,0,1,.151,1.27h0A.861.861,0,0,1,231.251,126.747Z'
          transform='translate(-224.746 -117.732)'
          fill='#25287f'
        />
        <path
          id='Path_13421'
          data-name='Path 13421'
          d='M217.085,149.806h0a.892.892,0,0,1,.786-1l3.6-.423a.892.892,0,0,1,1,.786h0a.892.892,0,0,1-.786,1l-3.6.423A.9.9,0,0,1,217.085,149.806Z'
          transform='translate(-217.078 -135.531)'
          fill='#25287f'
        />
        <path
          id='Path_13422'
          data-name='Path 13422'
          d='M251.008,110.48l-.423-3.6a.892.892,0,0,1,.786-1h0a.892.892,0,0,1,1,.786l.423,3.6a.892.892,0,0,1-.786,1h0A.868.868,0,0,1,251.008,110.48Z'
          transform='translate(-240.451 -105.878)'
          fill='#25287f'
        />
      </g>
      <path
        id='Path_13423'
        data-name='Path 13423'
        d='M370.8,204.623a3.032,3.032,0,0,1,3.023,3.023h0a3.032,3.032,0,0,1,3.023-3.023,3.032,3.032,0,0,1-3.023-3.023h0A3.012,3.012,0,0,1,370.8,204.623Z'
        transform='translate(-269.35 -160.685)'
        fill='#fff'
      />
      <g id='Group_11366' data-name='Group 11366' transform='translate(108.869 75.588)'>
        <path
          id='Path_13424'
          data-name='Path 13424'
          d='M415.432,316.3l-.967,26.844h-18.41A21.333,21.333,0,0,1,415.432,316.3Z'
          transform='translate(-395.342 -316.3)'
          fill='#d3d9ed'
        />
        <circle
          id='Ellipse_3067'
          data-name='Ellipse 3067'
          cx='17.745'
          cy='17.745'
          r='17.745'
          transform='translate(4.673 4.414)'
          fill='#f5f5f5'
        />
        <path
          id='Path_13425'
          data-name='Path 13425'
          d='M426.652,364.1A18.652,18.652,0,1,1,445.3,345.452h0A18.681,18.681,0,0,1,426.652,364.1Zm0-35.489a16.838,16.838,0,1,0,16.838,16.838h0A16.823,16.823,0,0,0,426.652,328.614Z'
          transform='translate(-404.173 -323.626)'
          fill='#525682'
        />
      </g>
      <path
        id='Path_13426'
        data-name='Path 13426'
        d='M458.51,363.109v-.665h.877V360.6H444.181v1.844h1.088v.665a6.521,6.521,0,0,0,3.839,5.925,6.572,6.572,0,0,0-3.839,5.925v.665h-1.088v1.36H444l.212.363c.03.03.03.06.06.06v.03h15.145v-1.844h-.907v-.665A6.522,6.522,0,0,0,454.671,369,6.5,6.5,0,0,0,458.51,363.109Zm-5.774,4.686-.181.03v2.388l.181.03a4.8,4.8,0,0,1,3.93,4.716v.665h-9.583v-.665a4.822,4.822,0,0,1,3.93-4.716l.181-.03v-2.388l-.181-.03a4.8,4.8,0,0,1-3.93-4.716v-.665h9.583v.665A4.769,4.769,0,0,1,452.736,367.795Z'
        transform='translate(-320.422 -271.62)'
        fill='#2b3078'
      />
    </SvgIcon>
  );
};
