import { ConferenceProviderType } from "@/schema/types";
import { FC, PropsWithChildren } from "react";
import { ZoomView, AntMediaView, CallInstance, CallParticipant } from "./types";

type CallView = PropsWithChildren<{
  call: CallInstance;
  participant: CallParticipant;
  sharing?: boolean;
}>;

export const CallView: FC<CallView> = props => {
  const { call } = props;
  switch (call.callType) {
    case ConferenceProviderType.Zoom:
      return <ZoomView {...props} />;
    case ConferenceProviderType.AntMedia:
      return <AntMediaView {...props} />;
    default:
      return null;
  }
};
