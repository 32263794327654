import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const TentativeIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.common.white;
  return (
    <SvgIcon width='26.088' height='26.088' viewBox='0 0 26.088 26.088' {...props}>
      <g id='Group_11077' data-name='Group 11077' transform='translate(-107.912 -991.471)'>
        <path
          id='icons8-done_3_'
          data-name='icons8-t (3)'
          d='M16.712,5.293,7.8,14.207,4.409,10.818,3.293,11.935l4.5,4.5,10.03-10.03Z'
          transform='translate(109.707 994.178)'
          fill={fill}
        />
        <g id='Ellipse_2785' data-name='Ellipse 2785' transform='translate(107.912 991.471)' fill='#d46a3d' stroke='#fff' strokeWidth='1'>
          <circle cx='13.044' cy='13.044' r='13.044' stroke='none' />
          <circle cx='13.044' cy='13.044' r='12.544' fill='none' />
        </g>
        <path
          id='icons8-question-mark_1_'
          data-name='icons8-question-mark (1)'
          d='M11.517,3C8.816,3,7.284,4.513,7.24,6.7v.11H9.625V6.7a1.611,1.611,0,0,1,1.733-1.592,1.478,1.478,0,0,1,1.672,1.4c0,.818-.343,1.241-1.478,1.919a2.725,2.725,0,0,0-1.585,2.913l.014.376h2.336V11.36c0-.845.326-1.277,1.5-1.954A3.281,3.281,0,0,0,15.7,6.432C15.7,4.4,14.034,3,11.517,3ZM11.2,13.352a1.365,1.365,0,1,0,0,2.724,1.365,1.365,0,1,0,0-2.724Z'
          transform='translate(109.879 995.58)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
