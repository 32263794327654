import { routes as addressRoutes } from "@/domains/address/routes";
import { MainPageLayout, PageWithBackHeaderLayout } from "@/shared/components";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./routes";

export const AccountSettingRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageLayout containerBreakpoint='sm' />}>
        <Route path={routes.root.route} element={<routes.root.component />} />
      </Route>
      <Route element={<PageWithBackHeaderLayout containerBreakpoint='sm' />}>
        <Route path={addressRoutes.edit.route} Component={addressRoutes.edit.component} />
        <Route path={addressRoutes.new.route} Component={addressRoutes.new.component} />
        <Route path={addressRoutes.map.route} Component={addressRoutes.map.component} />
        <Route path={addressRoutes.mapDisplay.route} Component={addressRoutes.mapDisplay.component} />
      </Route>
    </Routes>
  );
};
