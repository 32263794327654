import { IGoogleMapAddress, LocationPicker } from "@toolkit/maps";
import { Box } from "@toolkit/ui";
import { useState, forwardRef, useImperativeHandle, ForwardRefRenderFunction, useLayoutEffect } from "react";
import { useAddressMapWithSearchStyles } from "./AddressMapWithSearchStyles";
import { useTranslation } from "@toolkit/i18n";

export type AddressMapWithSearchProps = {
  onLocationChange?: (location: IGoogleMapAddress) => void;
  address?: IGoogleMapAddress;
  hideSearch?: boolean;
};

export type AddressMapWithSearchRef = {
  submit: () => void;
};

const AddressMapWithSearchForwardRef: ForwardRefRenderFunction<AddressMapWithSearchRef, AddressMapWithSearchProps> = (props, ref) => {
  const { address, onLocationChange } = props;
  const [location, setLocation] = useState<IGoogleMapAddress | undefined>(address);
  const [error, setError] = useState<string | null>(null);

  const { classes } = useAddressMapWithSearchStyles();
  const { t } = useTranslation("consumer");

  const handleLocation = (newLocation: IGoogleMapAddress) => {
    setLocation(newLocation);
    setError(null);
  };

  const handleSubmit = () => {
    if (!location) {
      setError("Please choose a location first.");
      return;
    }
    onLocationChange?.(location);
  };

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }));

  useLayoutEffect(() => {
    setLocation(address);
  }, [JSON.stringify(address)]);

  return (
    <Box>
      {error && <Box className={classes.error}>{error}</Box>}
      <LocationPicker
        hasSearch={!props.hideSearch}
        defaultZoom={17}
        classes={{
          containerMapStyle: classes.root,
        }}
        searchOptions={{
          placeholder: t("Search your location"),
          classes: {
            text: classes.searchField,
            locationButton: classes.locationButton,
          },
        }}
        onLocationChange={handleLocation}
        location={location?.coordinates}
        googleMapProps={{
          options: {
            disableDefaultUI: true,
            zoomControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            keyboardShortcuts: false,
          },
        }}
      />
    </Box>
  );
};

export const AddressMapWithSearch = forwardRef(AddressMapWithSearchForwardRef);
