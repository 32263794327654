import { makeStyles } from "@toolkit/ui";

export const useActivitiesGoalsStyles = makeStyles()(theme => ({
  root: {
    position: "relative",
    width: 180,
    height: 180,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circleContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nestedCircles: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  targetWeight: {
    position: "absolute",
    bottom: 0,
    right: 0,
    textAlign: "center",
    zIndex: 2,
  },
  targetWeightValue: {
    fontSize: theme.mixins.fonts.fontSize.xl,
    fontWeight: theme.mixins.fonts.fontWeight.bold,
  },
  targetWeightText: {
    fontSize: theme.mixins.fonts.fontSize.xxs,
  },
}));
