import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomCard, DotLoader, EmptyMessageLayout } from "@/shared/components";
import { PaymentShareIcon } from "@/shared/icons/PaymentShareIcon";
import { useWaitingErxHubApprovalCardStyles } from "./useWaitingErxHubApprovalCard.styles";

export const WaitingErxHubApprovalCard: FC = () => {
  const { classes } = useWaitingErxHubApprovalCardStyles();
  const { t } = useTranslation("consumer");

  return (
    <CustomCard className={classes.root}>
      <EmptyMessageLayout
        classes={{
          root: classes.emptyMessageLayout,
          iconContainer: classes.iconContainer,
          messageContainer: classes.messageContainer,
        }}
        icon={<PaymentShareIcon />}
        message={t("Your payment share will be specified upon insurance authorization")}
        subMessage={t("You will be notified once your payment share is specified")}
      />
      <DotLoader mt={2} />
    </CustomCard>
  );
};
