import { CalculationActivityLevelSelect } from "@/domains/digital-twin-calculators/CalculationFormInputs/CalculationActivityLevelSelect";
import { CalculationFormNumericSelect } from "@/domains/digital-twin-calculators/CalculationFormInputs/CalculationFormNumericSelect";
import { CalculationYesNoRadio } from "@/domains/digital-twin-calculators/CalculationFormInputs/CalculationYesNoRadio";
import { useDigitalTwinPatientInfoQuery } from "@/domains/digital-twin-calculators/gql";
import { ActivityLevel, Gender } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Card, Typography, useAddToast } from "@toolkit/ui";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDigitalTwinGetWeightLossPlanCalculationsLazyQuery } from "../../gql";
import { useDigitalTwinWeightLossCalculatorFormStyle } from "./useDigitalTwinWeightLossCalculatorFormStyle";
import { TdeeAndBmrCalculationRoutes } from "@/domains/digital-twin-tdee-bmr-calculator/routes";
import { ValueLabelComponent } from "@/shared/components";

type DigitalTwinWeightLossCalculatorFormProps = {
  bee: number;
  tdee: number;
  inputData?: {
    height: number;
    weight: number;
    activityLevel: ActivityLevel;
  };
};

export const DigitalTwinWeightLossCalculatorForm: FC<DigitalTwinWeightLossCalculatorFormProps> = props => {
  const { bee, tdee, inputData } = props;
  const { failed } = useAddToast();
  const { t } = useTranslation();
  const { data, loading: isLoadingProfile } = useDigitalTwinPatientInfoQuery({
    onCompleted: data => {
      if (!data?.profile?.dateOfBirth || !data?.profile?.gender) {
        failed(t("Please fill in your personal information first ( age & gender) "));
      }
    },
  });
  const navigate = useNavigate();
  const { classes, cx } = useDigitalTwinWeightLossCalculatorFormStyle();

  const [days, setDays] = useState<number>(50);
  const [goalWeight, setGoalWeight] = useState<number>(50);
  const [newActivityLevel, setNewActivityLevel] = useState<ActivityLevel>(inputData?.activityLevel!);
  const [isWillingToChangeActivityLevel, setIsWillingToChangeActivityLevel] = useState<boolean>(false);
  const [fetchGetWeightLossPlanCalculations, { loading: isSubmitting }] = useDigitalTwinGetWeightLossPlanCalculationsLazyQuery();

  const gender = data?.profile?.gender!;
  const handleNewActivityLevelSelect = (level: string) => {
    setNewActivityLevel(level as ActivityLevel);
  };

  const handleSubmit = () => {
    fetchGetWeightLossPlanCalculations({
      variables: {
        input: {
          bee: bee,
          days: days,
          goalWeight: goalWeight!,
          newActivityLevel: newActivityLevel!,
          weight: inputData?.weight!,
        },
      },
    }).then(({ data }) => {
      if (data?.getWeightLossPlanCalculations) {
        navigate(
          TdeeAndBmrCalculationRoutes.root.getRouteWithParams({
            bee,
            tdee,
            goalWeight: goalWeight!,
            weightLossDays: days,
            caloriesPerDayToAdjust: data.getWeightLossPlanCalculations?.caloriesPerDayToAdjust!,
            isWeightLoss: data.getWeightLossPlanCalculations?.isWeightLoss!,
            newCalorieIntake: data.getWeightLossPlanCalculations?.newCalorieIntake!,
            newTdee: data.getWeightLossPlanCalculations?.newTDEE!,
          })
        );
      } else {
        failed(t("Failed to calculate nutrition data"));
      }
    });
  };

  const handleChangeDays = (e, v: number | number[]) => setDays(+v);
  const handleChangeGoalWeight = (e, v: number | number[]) => setGoalWeight(+v);
  const handleChangeIsWillingToChangeActivityLevel = (v: string) => {
    setIsWillingToChangeActivityLevel(v === "yes");
  };
  function getIdealWeight() {
    if (!inputData?.height) return 55;

    if (gender?.toLowerCase() === Gender.Female?.toLowerCase()) {
      return 45.5 + 0.91 * (inputData?.height - 152.4);
    } else {
      return 50 + 0.91 * (inputData?.height - 152.4);
    }
  }
  return (
    <Box className={classes.root}>
      <Card className={cx(classes.card)}>
        <Typography className={classes.title}>{t("Set your goal for calorie counting")}</Typography>
        <Typography className={classes.subTitle}>
          {t("Fill in the details so we can calculate your calories and weight loss plan")}
        </Typography>
      </Card>
      <Card className={classes.card}>
        <Typography className={classes.subTitle}>
          {t("Your ideal weight is {{weight}} kg", { weight: isLoadingProfile ? t("being calculated") : getIdealWeight()?.toFixed(0) })}
        </Typography>
        <CalculationFormNumericSelect
          max={99}
          valueLabelDisplay='on'
          valueLabelFormat={value => ValueLabelComponent({ value, unit: t("kg") })}
          inputLabel={t("Whats your desired weight goal (kg)")}
          value={goalWeight}
          onChange={handleChangeGoalWeight}
        />
        <CalculationFormNumericSelect
          max={300}
          valueLabelDisplay='on'
          valueLabelFormat={value => ValueLabelComponent({ value, unit: t("days") })}
          inputLabel={t("How many days do you want to achieve this goal (days)")}
          title={t("Days you have to achieve this goal in")}
          value={days}
          onChange={handleChangeDays}
        />
        <CalculationYesNoRadio
          value={isWillingToChangeActivityLevel ? "yes" : "no"}
          name='isWillingToChangeActivityLevel'
          inputLabel={t("Are you ready to change your physical activity level to achieve your goal?")}
          onChange={handleChangeIsWillingToChangeActivityLevel}
        />
        {isWillingToChangeActivityLevel && (
          <CalculationActivityLevelSelect
            value={newActivityLevel}
            inputLabel={t("New Activity Level")}
            name='activityLevelSelect'
            onChange={handleNewActivityLevelSelect}
          />
        )}
      </Card>
      <Button disabled={isLoadingProfile} onClick={handleSubmit}>
        {isSubmitting ? t("Submitting") : t("Show result")}
      </Button>
    </Box>
  );
};
