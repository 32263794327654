import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomCard, EmptyMessageLayout } from "@/shared/components";
import { CheckoutIcon } from "@/shared/icons";
import { useOrderDeliveredCardStyles } from "./useOrderDeliveredCard.styles";

export const OrderDeliveredCard: FC = () => {
  const { classes } = useOrderDeliveredCardStyles();
  const { t } = useTranslation("consumer");
  return (
    <CustomCard className={classes.root}>
      <EmptyMessageLayout
        classes={{
          root: classes.emptyMessageLayout,
          iconContainer: classes.iconContainer,
          messageContainer: classes.messageContainer,
          subMessageContainer: classes.subMessageContainer,
        }}
        icon={<CheckoutIcon />}
        message={t("Your order has been delivered")}
      />
    </CustomCard>
  );
};
