import { BlobIcon } from "@/shared/icons";
import { Box, Breakpoint, CircularProgress, Theme } from "@toolkit/ui";
import { FC, ReactNode, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { useStyles } from "./PageLayoutStyle";

type PageLayoutProps = {
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  displayBlob?: boolean;
  containerBreakpoint?: Breakpoint;
  containerBackground?: keyof Theme["palette"]["background"];
  classes?: Partial<ReturnType<typeof useStyles>["classes"]>;
};

export const PageLayout: FC<PageLayoutProps> = props => {
  const { children, header, footer, displayBlob, containerBreakpoint, containerBackground, classes: _classes } = props;
  const { classes, cx } = useStyles({
    containerBreakpoint: containerBreakpoint,
    containerBackground: containerBackground,
  });

  return (
    <Box flex={1} className={cx(classes.root, _classes?.root)}>
      <Box className={cx(classes.contentWrapper, _classes?.contentWrapper)}>
        {header && <Box className={cx(classes.header, _classes?.header)}>{header}</Box>}
        {displayBlob && (
          <Box className={cx(classes.blobContainer, _classes?.blobContainer)}>
            <BlobIcon />
          </Box>
        )}
        <Suspense fallback={<CircularProgress />}>
          <Box className={cx(classes.container, _classes?.container)}>
            <Outlet />
            {children}
          </Box>
        </Suspense>
      </Box>
      {footer && <Box className={cx(classes.footer, _classes?.footer)}>{footer}</Box>}
    </Box>
  );
};
