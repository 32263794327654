import { useGetOrderItemsDetailsQuery } from "@/gql";
import { MarketplaceOrderType } from "@/schema/types";
import { useHeaderContext } from "@/shared/context";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress } from "@toolkit/ui";
import { get, groupBy } from "lodash";
import queryString from "query-string";
import { FC, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MarketPlacePaymentSummary,
  MarketPlacePaymentSummaryProps,
} from "../../components/CartItemsPaymentSummary/MarketPlacePaymentSummary";
import { OrderHealthPackagesCard } from "../../components/OrderHealthPackagesCard/OrderHealthPackagesCard";
import { OrderProductsCard } from "../../components/OrderProductsCard/OrderProductsCard";
import { OrderStatusSummaryCard } from "../../components/OrderSuccessCard/OrderStatusSummaryCard";
import { marketplaceOrderDetailsType } from "../../types";
import { calculateOrderPrice } from "../../utils";
import { calculatePaymentSummary } from "../../utils/orders.utils";
import { useCheckoutContainerStyles } from "./useCheckoutContainerStyles";
import { routes } from "../../routes";

export const CheckoutContainer: FC = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search) as queryString.ParsedQuery<string>;
  const cartId = queryParams.orderCartId as string;
  const handleNavigateToMyOrders = () => {
    navigate(routes.myOrders.absRoute);
  };
  const { data, loading } = useGetOrderItemsDetailsQuery({
    variables: {
      filter: {
        cartTokenId: cartId,
      },
    },
  });
  const { classes, cx } = useCommonContainerStyles();
  const { classes: customClasses } = useCheckoutContainerStyles();
  const { t } = useTranslation("consumer");
  const { setHeaderTitle, setBackPath } = useHeaderContext();
  const navigate = useNavigate();

  const handleNavigateToShop = () => {
    navigate("/");
  };

  useEffect(() => {
    setHeaderTitle(t("Checkout"));
    setBackPath("/");
    return () => {
      setHeaderTitle("");
    };
  }, []);

  const sortedGroupedItems = useMemo(() => {
    const cartItems = data?.marketplaceOrders?.edges?.map(edge => edge?.node);
    if (!cartItems) return { product: [], healthPackage: [] };

    const groupedItems = groupBy(cartItems, (order: marketplaceOrderDetailsType) => order.type) as {
      [MarketplaceOrderType.HealthPackage]: marketplaceOrderDetailsType[];
      [MarketplaceOrderType.Product]: marketplaceOrderDetailsType[];
    };

    const sortItemsByName = (items: marketplaceOrderDetailsType[] = []) => {
      return items.sort((a, b) => {
        const nameA = get(a, "product.name", "") || get(a, "healthPackage.name", "");
        const nameB = get(b, "product.name", "") || get(b, "healthPackage.name", "");
        return nameA.localeCompare(nameB);
      });
    };

    return {
      product: sortItemsByName(groupedItems?.[MarketplaceOrderType.Product])
        ?.map(item => item?.orderLines)
        .flat(),
      healthPackage: sortItemsByName(groupedItems?.[MarketplaceOrderType.HealthPackage])
        ?.map(item => item?.orderLines)
        .flat(),
    };
  }, [data]);
  const _items = data?.marketplaceOrders?.edges?.map(edge => edge?.node);
  const paymentSummary: MarketPlacePaymentSummaryProps = useMemo(() => {
    return calculatePaymentSummary(_items);
  }, [_items]);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <OrderStatusSummaryCard />
        <OrderProductsCard
          products={sortedGroupedItems.product.map(item => ({
            ...item?.product,
            id: item?.product?.id || "",
            quantity: item.quantity,
            priceInfo: calculateOrderPrice(item?.unitPriceNetAmount, item?.unitPriceDiscount),
            priceForQuantity: calculateOrderPrice(item?.unitPriceNetAmount, item?.unitPriceDiscount, item.quantity),
          }))}
        />
        <OrderHealthPackagesCard
          healthPackages={sortedGroupedItems.healthPackage.map(item => ({
            ...item.healthPackage,
            id: item?.healthPackage?.id || "",
            quantity: item.quantity,
            priceForQuantity: calculateOrderPrice(item?.unitPriceNetAmount, item?.unitPriceDiscount, item.quantity),
            priceInfo: calculateOrderPrice(item?.unitPriceNetAmount, item?.unitPriceDiscount),
          }))}
        />
        <MarketPlacePaymentSummary {...paymentSummary} />
      </Box>
      <Box className={cx(classes.footer, customClasses.footer)}>
        <Button onClick={handleNavigateToMyOrders} className={cx(classes.button, customClasses.button)}>
          {t("Track Your Order")}
        </Button>
        <Button variant='outlined' onClick={handleNavigateToShop} className={cx(classes.button, customClasses.button)}>
          {t("Continue Shopping")}
        </Button>
      </Box>
    </Box>
  );
};
