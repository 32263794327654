import { TdeeAndBmrCalculationRoutes } from "@/domains/digital-twin-tdee-bmr-calculator/routes";
import { MainPageLayout } from "@/shared/components";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const TdeeAndBmdCalculatorRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageLayout containerBreakpoint='sm' />}>
        <Route path={TdeeAndBmrCalculationRoutes.root.route} Component={TdeeAndBmrCalculationRoutes.root.component} />
        <Route path={TdeeAndBmrCalculationRoutes.form.route} Component={TdeeAndBmrCalculationRoutes.form.component} />
      </Route>
    </Routes>
  );
};
