import { FC } from "react";
import { VideoPlayer } from "@toolkit/ui";

import { ChatAttachment, ChatAttachmentInput } from "../../schema/types";

type ChatViewMediaVideoProps = {
  media: ChatAttachment | ChatAttachmentInput;
};

export const ChatViewMediaVideo: FC<ChatViewMediaVideoProps> = ({ media }) => {
  const { url } = media;

  return <VideoPlayer src={url} />;
};
