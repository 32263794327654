import { ProductCategoriesContainer } from "@/domains/marketplace/containers/ProductsCategoriesContainer/ProductsCategoriesContainer";
import { routes } from "@/domains/marketplace/routes";
import { Section } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCommonSectionsStyle } from "../../Styles/CommonSectionsStyle";
import { useStyle } from "./CategoriesSectionStyle";

export const CategoriesSection: React.FC = () => {
  const { classes, cx } = useStyle();
  const { classes: commonClasses } = useCommonSectionsStyle();
  const { t } = useTranslation("consumer");

  const navigate = useNavigate();

  const handleSeeAll = () => {
    navigate(routes.products.absRoute);
  };

  return (
    <Section
      title={t("Marketplace Categories")}
      onSeeAll={handleSeeAll}
      classes={{
        root: cx(commonClasses.root, classes.root, "section__root", "categories"),
        container: cx(commonClasses.container, classes.container, "section__container"),
        titleContainer: cx(commonClasses.titleContainer),
        title: cx(commonClasses.title, "title"),
        titleSeeAll: cx(commonClasses.titleSeeAll, "title__btn"),
      }}
    >
      <ProductCategoriesContainer
        hasMoreButton
        countOfCategories={9}
        classes={{
          categoriesContainer: cx(classes.categoriesContainer),
          item__root: cx(classes.item__root),
          item__button: cx(classes.item__button),
          item__icon: cx(classes.item__icon),
          seeAllButton: cx(classes.seeAllButton),
        }}
      />
    </Section>
  );
};
