import { SiteSettingsGetQueryHookResult } from "@/gql";

const site_settings_local_storage_key = "siteSettings";

export const getSiteSettingItemSync = (key: keyof NonNullable<SiteSettingsGetQueryHookResult["data"]>["siteSettings"]) => {
  const siteSettingsJson = localStorage.getItem(site_settings_local_storage_key);
  if (!siteSettingsJson) return null;
  const siteSettings = siteSettingsJson ? JSON.parse(siteSettingsJson) : {};
  return siteSettings[key];
};
export const setSiteSettings = (data: NonNullable<SiteSettingsGetQueryHookResult["data"]>["siteSettings"]) => {
  localStorage.setItem(site_settings_local_storage_key, JSON.stringify(data));
};
