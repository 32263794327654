import { getPatientId } from "@/domains/patient";
import { useCommonInfiniteContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { get } from "lodash";
import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { CareTeamsList } from "../../components/CareTeamsList/CareTeamsList";
import { CareTeamsListTabs } from "../../components/CareTeamsListTabs/CareTeamsListTabs";

export const MyCareTeamsListContainer: FC = () => {
  const { classes: commonContainerClasses } = useCommonInfiniteContainerStyles();
  const { user } = useAuth();

  const patientId = getPatientId(get(user?.profile, "patient_id") as string)!;

  const { t } = useTranslation("consumer");

  return (
    <Box className={commonContainerClasses.container}>
      <Typography variant='h6' className={commonContainerClasses.title}>
        {t("My Care Teams")}
      </Typography>
      <CareTeamsListTabs />

      <CareTeamsList filters={{ isPatientEnrolled: true }} patientId={patientId} cardVariant='myCareTeam' />
    </Box>
  );
};
