import { makeStyles } from "@toolkit/ui";

export const useWellnessCardStyles = makeStyles()(theme => ({
  clickableRoot: {
    cursor: "pointer",
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: theme.shadows[4],
    },
    marginTop: theme.spacing(1),
  },
  root: {
    width: "100%",
    height: "max-content",
    minHeight: 130,
    borderRadius: 10,
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, #712661)`,
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(2),
    gap: theme.spacing(2.5),
    composes: "$clickableRoot",
  },
  startIcon: {
    "& svg": {
      width: 65,
      height: 65,
      fill: theme.palette.common.white,
    },
  },
  titleWrapper: {
    fontSize: theme.mixins.fonts.fontSize.xl,
    textAlign: "start",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "start",
    justifyContent: "start",
    gap: 3,
    alignSelf: "start",
    justifySelf: "flex-start",
  },
  bold: {
    fontWeight: theme.mixins.fonts.fontWeight.bold,
    display: "inline",
    fontSize: theme.mixins.fonts.fontSize.xl,
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.xl,
    fontWeight: theme.mixins.fonts.fontWeight.bold,
    color: theme.palette.common.white,
  },
  description: {
    textAlign: "start",
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    color: theme.palette.common.white,
  },
  loadingText: {
    fontSize: theme.mixins.fonts.fontSize.md,
    color: theme.palette.common.white,
    opacity: 0.7,
  },
  loadingSkeleton: {
    width: "100%",
    height: 20,
    backgroundColor: theme.palette.common.white,
    opacity: 0.3,
    borderRadius: 4,
  },
}));
