import { Box, IconButton, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useDeliveryTypesStyles } from "./useDeliveryTypes.styles";
import { PickFromPharmacyBlobIcon, ScooterBlobIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { AddressSelectionContainerDataProps, OrderAndDeliverPrescriptionContainerDataType } from "../../others/types";
import { DeliveryTypes as DeliveryType } from "@/schema/types";
import { useNavigate } from "react-router-dom";
import { orderMedicationsRoutes } from "../../routes";

type DeliveryTypesProps = Omit<OrderAndDeliverPrescriptionContainerDataType, "medications">;

export const DeliveryTypes: FC<DeliveryTypesProps> = props => {
  const { allowedDeliveryTypes = [], ...rest } = props;
  const { classes, theme } = useDeliveryTypesStyles();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const onDeliveryButtonClick = () => {
    navigate(orderMedicationsRoutes.addressSelection.absRoute, {
      state: {
        ...rest,
        selectedDeliveryType: DeliveryType.Delivery,
      } as AddressSelectionContainerDataProps,
    });
  };
  const onPickupButtonClick = () => {
    navigate(orderMedicationsRoutes.addressSelection.absRoute, {
      state: {
        ...rest,
        selectedDeliveryType: DeliveryType.Pickup,
      } as AddressSelectionContainerDataProps,
    });
  };

  return (
    <Box className={classes.root}>
      {allowedDeliveryTypes?.includes(DeliveryType.Delivery) && (
        <Box className={classes.buttonWrapper}>
          <IconButton onClick={onDeliveryButtonClick} className={classes.button}>
            <ScooterBlobIcon />
          </IconButton>
          <Typography className={classes.label}>{t("Delivery to Address")}</Typography>
        </Box>
      )}
      {allowedDeliveryTypes?.includes(DeliveryType.Pickup) && (
        <Box className={classes.buttonWrapper}>
          <IconButton onClick={onPickupButtonClick} className={classes.button}>
            <PickFromPharmacyBlobIcon fill={theme.palette.common.white} />
          </IconButton>
          <Typography className={classes.label}>{t("Pickup from the Pharmacy")}</Typography>
        </Box>
      )}
    </Box>
  );
};
