import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const RightArrow: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='7.091' height='13' viewBox='0 0 7.091 13'>
      <path
        id='icons8-arrow'
        d='M6.5,0a.589.589,0,0,0-.418.173L.173,6.082a.591.591,0,0,0,.836.836L6.5,1.427l5.491,5.491a.591.591,0,1,0,.836-.836L6.918.173A.589.589,0,0,0,6.5,0Z'
        transform='translate(7.091) rotate(90)'
        fill={fill}
      />
    </SvgIcon>
  );
};
