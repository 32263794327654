import { ActivityItemCodesEnum } from "../types";

export const getActivityUnit = (code: ActivityItemCodesEnum): string => {
  switch (code) {
    case ActivityItemCodesEnum.Daily_Step_Count:
      return "steps";
    case ActivityItemCodesEnum.Daily_Calories_Burned:
      return "cal";
    case ActivityItemCodesEnum.Active_Time:
      return "";
    default:
      return "";
  }
};

export const formatActivityValue = (code: ActivityItemCodesEnum, value?: number | null): string => {
  const numericValue = value ?? 0;

  if (code === ActivityItemCodesEnum.Active_Time) {
    const _numericValue = numericValue / 1000;
    const hours = Math.floor(_numericValue / 3600);
    const minutes = Math.floor((_numericValue % 3600) / 60);
    return `${hours}h ${minutes}m`;
  }
  const unit = getActivityUnit(code);
  return unit ? `${Math.round(numericValue)} ${unit}` : `${Math.round(numericValue)}`;
};

export function calculatePercentage(value: string | undefined, goal: number | undefined, loading: boolean): number | undefined {
  if (loading || !goal || value === undefined) return undefined;

  const calculatedPercentage = (Number(value) / goal) * 100;
  return Math.min(calculatedPercentage, 100);
}

export function activityDailyGoals(code: ActivityItemCodesEnum) {
  switch (code) {
    case ActivityItemCodesEnum.Active_Time:
      return 2 * 3600 * 1000; // 2 hours
    case ActivityItemCodesEnum.Daily_Sleep_Duration:
      return 8 * 3600 * 1000; // 8 hours
    case ActivityItemCodesEnum.Daily_Step_Count:
      return 3000;
    case ActivityItemCodesEnum.Daily_Calories_Burned:
      return 2;
    case ActivityItemCodesEnum.Daily_Calories_Consumed:
      return 2;
  }
}
