import { Box, Card, makeStyles, Typography } from "@toolkit/ui";
import { FC } from "react";
import { CircularProgressBar } from "./CircularProgressBar";
import { getNutritionValuesColors } from "./constant";

type TdeeAndBmrChartProps = {
  targetWeight: string;
  tdee: number;
  targetCalories?: number;
  maxTdee: number;
  bee: number;
  label: string;
};

export const TdeeAndBmrChart: FC<TdeeAndBmrChartProps> = props => {
  const { label, targetWeight, tdee, bee, maxTdee, targetCalories } = props;
  const { classes, theme } = useTdeeAndBmrChartStyle();
  const nutritionValuesColors = getNutritionValuesColors(theme);

  return (
    <Card className={classes.root}>
      <Box className={classes.circularWrapper}>
        <CircularProgressBar
          progress={tdee}
          maxValue={maxTdee}
          size={300}
          progressColor={tdee ? nutritionValuesColors.tdee : theme.palette.gray[300]}
        />
      </Box>
      <Box className={classes.circularWrapper}>
        <CircularProgressBar
          progress={bee}
          maxValue={maxTdee}
          size={250}
          progressColor={bee ? nutritionValuesColors.bee : theme.palette.gray[300]}
        />
      </Box>
      <Box className={classes.circularWrapper}>
        <CircularProgressBar
          progress={targetCalories}
          maxValue={maxTdee}
          size={200}
          progressColor={targetCalories ? nutritionValuesColors.targetCalories : theme.palette.gray[300]}
        />
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography fontSize='25' color='black' fontWeight='bold'>
          {targetWeight}
        </Typography>
        <Typography fontSize='16' color='black'>
          {label}
        </Typography>
      </Box>
    </Card>
  );
};

export const useTdeeAndBmrChartStyle = makeStyles()(theme => {
  return {
    root: {
      direction: "ltr",
      padding: 8,
      width: "100%",
      height: 400,
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      alignContent: "center",
    },
    circularWrapper: {
      margin: 20,
      position: "absolute",
    },
    title: {
      fontSize: theme.mixins.fonts.fontSize.lg,
      color: theme.palette.text.secondary,
      alignSelf: "flex-start",
    },
    iconInClock: {
      padding: 4,
      borderWidth: 2,
      borderStyle: "solid",
      borderRadius: 25,
      backgroundColor: "white",
    },
  };
});
