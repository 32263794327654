import { ChatAttachment, ChatAttachmentInput } from "../../schema/types";
import { FC } from "react";
import { ChatViewMediaPhoto } from "../ChatView";

type ChatDraftViewMediaProps = {
  attachments: (ChatAttachment | ChatAttachmentInput)[];
};

export const ChatDraftViewMedia: FC<ChatDraftViewMediaProps> = ({ attachments }) => {
  if (attachments.length === 1) {
    const [attachment] = attachments;
    const { type } = attachment;

    if (type.startsWith("image/")) {
      return <ChatViewMediaPhoto media={attachment} />;
    }
  }

  return null;
};
