import { createContext, useContext, useEffect, useMemo } from "react";
import { CallInstance, CallState } from "./types";
import { Meeting, ConferenceConfiguration } from "@/schema/types";
import { useMeetingSettings } from "./useMeetingSettings";

export type CallInfoParams = {
  callId: string;
  userId?: number;
  userName?: string;
};

export type UserInfo = { user_id?: number; name?: string };
export type CallInfo = { meeting: Meeting; configuration?: ConferenceConfiguration };
export type CallProviderInfo = {
  call?: CallInstance;
  userInfo?: UserInfo;
  callInfo?: CallInfo;
  accessToken?: string;
  settings?: ReturnType<typeof useMeetingSettings>;
  select: (info: CallInfo) => void;
};

export const CallContext = createContext<CallProviderInfo>({
  select: () => Error("Not implemented!"),
});

export function useActiveCall() {
  const { call, callInfo } = useContext(CallContext);
  const isInCall = call?.state.callState === CallState.InCall;
  if (isInCall) {
    return { call, callInfo };
  }

  return null;
}

export function useSelectCall(info?: CallInfo): CallInstance | undefined {
  const { callInfo, select, call } = useContext(CallContext);
  useEffect(() => {
    if (info) {
      select(info);
    }
  }, [info, select]);

  if (info?.meeting?.id !== callInfo?.meeting?.id) {
    return undefined;
  }

  return call;
}

export function useGetCallSettings() {
  const { settings } = useContext(CallContext);
  const defaultSettings = useMemo(
    () => ({ type: undefined, url: undefined, error: undefined, loading: false, handleRetry: undefined }),
    []
  );

  return settings || defaultSettings;
}
