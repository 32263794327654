import { HealthPackageGeneralInfo } from "@/domains/marketplace/components/HealthPackageGeneralInfo/HealthPackageGeneralInfo";
import { WishlistAddButton } from "@/domains/marketplace/components/WishlistAddButton/WishlistAddButton";
import { routes } from "@/domains/marketplace/routes";
import { useMarketplaceCartContext } from "@/shared/context";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import { compact } from "lodash";
import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { HealthPackageDetailsTabs } from "../../components/HealthPackageDetailsTabs/HealthPackageDetailsTabs";
import { HealthPackageImage } from "../../components/HealthPackageImage/HealthPackageImage";
import { useHealthPackageContainerHook } from "./useHealthPackageContainer";
import { useHealthPackageContainerStyle } from "./useHealthPackageContainerStyle";

export const HealthPackageContainer: FC = () => {
  const { healthPackageDetails, isLoading } = useHealthPackageContainerHook();
  const { classes, cx } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const { classes: customClasses } = useHealthPackageContainerStyle();
  const { addItemToCart, itemsLoadingTrackerMap, cartItems } = useMarketplaceCartContext();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const handleBuyPackage = () => {
    if (cartItems?.healthPackage[healthPackageDetails?.id || ""]) {
      navigate(routes.cart.absRoute);
    } else {
      addItemToCart?.({
        healthPackageId: healthPackageDetails?.id,
        quantity: 1,
      }).then(response => {
        response?.data?.marketplaceCartAddItem?.id && navigate(routes.cart.absRoute);
      });
    }
  };
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <HealthPackageImage healthPackage={healthPackageDetails} />
        <HealthPackageGeneralInfo healthPackage={healthPackageDetails} />
        <HealthPackageDetailsTabs
          healthPackage={healthPackageDetails}
          isLoading={isLoading}
          visibleTabsKeys={compact(["description", isAuthenticated && "reviews"])}
        />
      </Box>
      <Box className={cx(classes.footer, customClasses.footer)}>
        <WishlistAddButton id={healthPackageDetails?.id} marketplaceItemType='healthPackage' />
        <Button
          disabled={itemsLoadingTrackerMap?.healthPackage?.[healthPackageDetails?.id || ""] || !isAuthenticated}
          onClick={handleBuyPackage}
          className={customClasses.buyButton}
        >
          {cartItems?.healthPackage[healthPackageDetails?.id || ""] ? t("Go to Cart") : t("Buy This Package")}
        </Button>
      </Box>
    </Box>
  );
};
