import { useCallback } from "react";
import { EVENT_TOPIC, TOPIC_LISTENERS, useSubscribeToNotificationTopic } from "../../NotificationsServiceProvider";

export const useGetNotificationCountAfterReadANotification = (
  handler: TOPIC_LISTENERS[EVENT_TOPIC.NOTIFICATION_COUNT_AFTER_MARK_A_NOTIFICATION_AS_READ][number]
) => {
  const onNotificationCountChange: TOPIC_LISTENERS[EVENT_TOPIC.NOTIFICATION_COUNT_AFTER_MARK_A_NOTIFICATION_AS_READ][number] = useCallback(
    data => {
      handler(data);
    },
    []
  );
  useSubscribeToNotificationTopic(EVENT_TOPIC.NOTIFICATION_COUNT_AFTER_MARK_A_NOTIFICATION_AS_READ, onNotificationCountChange);
};
