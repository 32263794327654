import { PaymentSummary } from "@/domains/payment";
import { PayNowDialog } from "@/domains/payment/pay-now-dialog/pay-now-dialog";
import { AmountSourceType } from "@/schema/types";
import { useHeaderContext } from "@/shared/context";
import { VideoChatIcon } from "@/shared/icons";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { VirtualVisitDoctorCard } from "../../components/VirtualVisitDoctorCard/VirtualVisitDoctorCard";
import { useCallDoctorNowHook } from "./useCallDoctorNowHook";
import { useStyle } from "./VirtualVisitPaymentSummaryContainerStyle";
import { SingleInfoWithIconDetailCard } from "@/shared/components";

export const VirtualVisitPaymentSummaryContainer: FC = () => {
  const { setHeaderTitle } = useHeaderContext();
  const { visit, doctor, isFullyCovered, isCreatingVisitLoading, handlePayDoctorNowToCreateCall } = useCallDoctorNowHook();

  const { classes: commonClasses } = useCommonContainerStyles();
  const { classes, cx } = useStyle();

  const navigate = useNavigate();

  const { t } = useTranslation("consumer");

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    setHeaderTitle(t("Call a doctor now summary"));
  }, []);

  return (
    <Box className={commonClasses.pageContainer}>
      <Box className={commonClasses.pageContent}>
        <PayNowDialog amountSource={AmountSourceType.Visit} amountSourceId={visit?.id || ""} />
        <SingleInfoWithIconDetailCard
          className={classes.infoCard}
          title={t("Visit type")}
          content={t("Virtual Visit")}
          startIcon={
            <Box className={classes.iconContainer}>
              <VideoChatIcon />
            </Box>
          }
        />

        <VirtualVisitDoctorCard hidePrice classes={{ card: classes.doctorCard }} hideAction doctor={doctor} />
        {!visit ? (
          <PaymentSummary doctorId={doctor?.id || ""} priceBeforeVat={doctor?.onlineVisitPrice || undefined} />
        ) : (
          <PaymentSummary
            priceBeforeVat={visit?.priceAmount || doctor?.onlineVisitPrice || undefined}
            priceAfterVat={visit?.priceAmountAfterVat || undefined}
          />
        )}
      </Box>
      <Box className={cx(commonClasses.footer, classes.footer)}>
        <Button
          disabled={isCreatingVisitLoading}
          onClick={handlePayDoctorNowToCreateCall}
          className={cx(commonClasses.button, classes.button)}
        >
          {isFullyCovered ? t("Call now") : t("Pay Now")}
        </Button>
        <Button onClick={handleCancel} variant='outlined' className={cx(commonClasses.button, classes.button)}>
          {t("Cancel")}
        </Button>
      </Box>
    </Box>
  );
};
