/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-nested-switch */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Adaptor, StreamConfig, ConnectionState, ExecutedResponse, InitConfig, StreamInfo, StreamMetaData, CallbackType } from "./types";
import { createContext } from "react";
import { useMuteSpeaker } from "./useMuteSpeaker";
import { useMuteScreen } from "./useMuteScreen";
import { useMuteVideo } from "./useMuteVideo";
import { useMuteAudio } from "./useMuteAudio";
import { createAntMediaAdaptor } from "./AntMediaAdaptor";
import { formatExecutedFailure, getVideoSettings, initMediaStream, parseStreamMetaData } from "./utils";
import { useNetwork } from "@toolkit/core";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";

type AntMediaContextType = Partial<ReturnType<typeof useAntMediaContext>>;

export const AntMediaContext = createContext<AntMediaContextType>({});

export function useAntMediaContext(config?: InitConfig) {
  const debug = !!config?.enableLog || process.env.NODE_ENV !== "production";
  const localStream = useMemo(() => new MediaStream(), []);
  const [isConnecting, setConnecting] = useState(false);
  const [connectionState, setConnectionState] = useState<ConnectionState | undefined>();
  const adaptorRef = useRef<Adaptor | null>(null);
  const [isDataChannelOpened, setOpenDataChannel] = useState(false);
  const [streams, setStreams] = useState<Record<string, StreamInfo>>({});
  const [remoteStreams, setRemoteStreams] = useState<Record<string, MediaStream>>({});
  const [videoTracks, setVideoTracks] = useState<Record<string, string>>({});
  const { muteSpeaker, speakerMuted } = useMuteSpeaker();
  const { muteAudio, audioMuted } = useMuteAudio({ adaptorRef, localStream });
  const { muteVideo, videoMuted, flipCamera } = useMuteVideo({ adaptorRef, localStream });
  const { muteScreen, screenMuted } = useMuteScreen({ adaptorRef, localStream });
  const userStatusRef = useRef<Partial<StreamMetaData>>({});
  const { online: isOnline } = useNetwork();
  const isConnectingRef = useRef(isConnecting);
  const updateConnecting = useCallback((value: boolean) => {
    isConnectingRef.current = value;
    setConnecting(value);
  }, []);

  const connectionRef = useRef({ state: connectionState, publishReconnected: true, playReconnected: true });
  const joinRef = useRef<(value: ExecutedResponse) => void>();
  const updateStatus = useCallback((value: ConnectionState | undefined) => {
    connectionRef.current.state = value;
    if (value === undefined || value === ConnectionState.Closed || value === ConnectionState.Fail) {
      connectionRef.current = { state: value, publishReconnected: true, playReconnected: true };
    }
    setConnectionState(value);
    joinRef.current?.({ success: value === ConnectionState.Connected });
  }, []);
  const updateReconnectingStatus = useCallback(
    (value: Partial<{ publishReconnected: boolean; playReconnected: boolean }>) => {
      connectionRef.current = { ...connectionRef.current, ...value };
      const { state, publishReconnected, playReconnected } = connectionRef.current;
      const reconnecting = !(publishReconnected && playReconnected);
      if (state === ConnectionState.Connected || state === ConnectionState.Reconnecting) {
        updateStatus(!reconnecting ? ConnectionState.Connected : ConnectionState.Reconnecting);
      }
    },
    [updateStatus]
  );
  const resetConnectionState = useCallback(
    (state: ConnectionState = ConnectionState.Closed) => {
      updateConnecting(false);
      setOpenDataChannel(false);
      setStreams({});
      setVideoTracks({});
      setRemoteStreams({});
      localStream.getTracks().forEach(t => t.stop());
      updateStatus(state);
      adaptorRef.current?.unpublish();
      adaptorRef.current = null;
    },
    [localStream, updateConnecting, updateStatus]
  );

  const closeSession = useCallback(async (state: ConnectionState) => resetConnectionState(state), [resetConnectionState]);
  const leaveSession = useCallback(async () => resetConnectionState(), [resetConnectionState]);

  const callback = useCallback(
    (info: StreamConfig) => (type: CallbackType, data: any, instance: WebRTCAdaptor) => {
      if (adaptorRef.current?.instance && adaptorRef.current?.instance !== instance) {
        instance?.closeWebSocket();
        return;
      }
      const { roomId } = info;
      switch (type) {
        case "pong":
        case "bitrateMeasurement": {
          return;
        }
        case "data_received": {
          try {
            if (!data?.data || data.data.includes("UPDATE_AUDIO_LEVEL")) {
              // ignore
              return;
            }

            const notificationEvent = JSON.parse(data.data);

            switch (notificationEvent.eventType) {
              case "TRACK_LIST_UPDATED": {
                adaptorRef.current?.getBroadcastObject(roomId);
                break;
              }
              case "UPDATE_STREAM_METADATA": {
                const { streamId, metaData } = notificationEvent;
                setStreams(s => {
                  if (!s[streamId]) {
                    return s;
                  }

                  return { ...s, [streamId]: { ...s[streamId], metaData } };
                });
                break;
              }
              case "AUDIO_TRACK_ASSIGNMENT": {
                return;
              }
              case "VIDEO_TRACK_ASSIGNMENT_LIST": {
                const { payload } = notificationEvent;
                setVideoTracks(
                  payload.reduce(
                    (tracks: Record<string, string>, v: { trackId: string; videoLabel: string }) => ({
                      ...tracks,
                      [v.trackId]: v.videoLabel,
                    }),
                    {}
                  )
                );
                break;
              }
            }
          } catch (error) {
            console.error(error);
          }
          break;
        }
        case "broadcastObject": {
          const broadcastObject = data.broadcast ? JSON.parse(data.broadcast) : undefined;
          if (broadcastObject) {
            if (data.streamId === roomId) {
              const participantIds = broadcastObject.subTrackStreamIds as string[];
              setStreams(s => {
                participantIds.forEach(pid => !s[pid] && adaptorRef.current?.getBroadcastObject(pid));
                Object.keys(s).forEach(id => !participantIds.includes(id) && delete s[id]);
                return { ...s };
              });
            } else {
              setStreams(list => ({
                ...list,
                [data.streamId]: {
                  streamId: data.streamId,
                  streamName: broadcastObject.name,
                  metaData: parseStreamMetaData(broadcastObject.metaData),
                  isCurrentStream: data.streamId === info.streamId,
                },
              }));
            }
          }
          break;
        }
        case "noStreamNameSpecified":
        case "invalidStreamName":
        case "license_suspended_please_renew_license":
        case "highResourceUsage":
        case "publishTimeoutError":
        case "streamIdInUse":
        case "unauthorized_access":
        case "closed": {
          const { state: currentState } = connectionRef.current;
          if (currentState !== ConnectionState.Fail) {
            closeSession(ConnectionState.Closed);
          }
          break;
        }
        case "web_socket_error": {
          const { state: currentState } = connectionRef.current;
          closeSession(currentState === ConnectionState.Connected ? ConnectionState.Fail : ConnectionState.Closed);
          break;
        }
        case "reconnection_attempt_for_publisher": {
          updateReconnectingStatus({ publishReconnected: false });
          break;
        }
        case "session_restored": {
          if (isConnectingRef.current) {
            // user still in call, force drop existing session
            adaptorRef.current?.unpublish();
          } else {
            updateReconnectingStatus({ publishReconnected: true });
          }

          break;
        }
        case "publish_started": {
          const { state: currentState } = connectionRef.current;
          if (currentState === ConnectionState.Reconnecting) {
            updateReconnectingStatus({ publishReconnected: true });
            adaptorRef.current?.getBroadcastObject(roomId);
          } else {
            updateStatus(ConnectionState.Connected);
            adaptorRef.current?.play();
          }
          break;
        }
        case "reconnection_attempt_for_player": {
          updateReconnectingStatus({ playReconnected: false });
          break;
        }
        case "play_started": {
          adaptorRef.current?.getBroadcastObject(roomId);
          updateReconnectingStatus({ playReconnected: true });
          break;
        }
        case "publish_finished": {
          break;
        }
        case "play_finished": {
          const { state: currentState } = connectionRef.current;
          if (currentState !== ConnectionState.Connected || roomId !== data.streamId) {
            break;
          }

          adaptorRef.current?.play();
          break;
        }
        case "initialized": {
          const { state: currentState } = connectionRef.current;
          if (currentState === ConnectionState.Reconnecting) {
            break;
          }

          adaptorRef.current?.publish();
          break;
        }
        case "no_active_streams_in_room": {
          if (!roomId || data.room !== roomId) {
            break;
          }

          setStreams({});
          break;
        }
        case "joinedTheRoom":
        case "roomInformation": {
          const { ATTR_ROOM_NAME: roomName, streamList } = data as {
            ATTR_ROOM_NAME: string;
            streamList: { streamId: string; metaData: string }[];
          };
          if (!roomId || roomName !== roomId) {
            break;
          }

          streamList.forEach(s => adaptorRef.current?.getBroadcastObject(s.streamId));
          setStreams(currentStreams =>
            streamList.reduce(
              (list, s) => ({
                ...list,
                [s.streamId]: { ...s, metaData: parseStreamMetaData(s.metaData, currentStreams[s.streamId]?.metaData) },
              }),
              {}
            )
          );
          break;
        }
        case "newTrackAvailable": {
          let id = data.streamId;
          if (data.streamId === roomId) {
            const trackId = data.trackId.substring("ARDAMSx".length);
            if (trackId === roomId || trackId === info.streamId) {
              break;
            }

            id = trackId;
          }
          setRemoteStreams(rm => ({ ...rm, [id]: data.stream }));
          break;
        }
        case "data_channel_opened": {
          if (data === roomId) {
            setOpenDataChannel(true);
          }
          break;
        }
        case "data_channel_closed": {
          if (data === roomId) {
            setOpenDataChannel(false);
          }
          break;
        }
      }
      // eslint-disable-next-line no-console
      debug && console.log("Meeting: callback", type, data);
    },
    [debug, closeSession, updateReconnectingStatus, updateStatus]
  );

  const joinSession = useCallback(
    async (joinConfig: StreamConfig) => {
      try {
        if (debug) {
          console.log("Meeting: Join session request", joinConfig);
        }

        if (adaptorRef.current) {
          await leaveSession();
        }

        updateStatus(undefined);
        updateConnecting(true);

        const audioResult = await initMediaStream(localStream, "audio", joinConfig.metaData.audioMuted);
        if (!audioResult.success) {
          return audioResult;
        }

        const videoResult = await initMediaStream(localStream, "video", joinConfig.metaData.videoMuted);
        if (!videoResult.success) {
          return videoResult;
        }

        const metaData = { ...joinConfig.metaData, videoSettings: getVideoSettings(localStream) };
        userStatusRef.current = metaData;

        return await new Promise<ExecutedResponse>(resolve => {
          try {
            const timeoutId = setTimeout(() => {
              console.error("Meeting: Join timeout");
              leaveSession();
            }, 10000);
            joinRef.current = response => {
              clearTimeout(timeoutId);
              resolve(response);
              joinRef.current = undefined;
            };
            adaptorRef.current = createAntMediaAdaptor({ ...joinConfig, metaData, debug, callback, stream: localStream });
          } catch (error) {
            console.error("Meeting: Join error", error);
            resolve(formatExecutedFailure(error));
          }
        });
      } catch (error) {
        console.error("Meeting: Join error", error);
        leaveSession();
      } finally {
        updateConnecting(false);
      }
    },
    [callback, debug, leaveSession, localStream, updateConnecting, updateStatus]
  );

  useEffect(() => {
    return () => {
      leaveSession();
    };
  }, [leaveSession]);

  const isConnected = connectionState === ConnectionState.Connected;

  useEffect(() => {
    userStatusRef.current = { ...userStatusRef.current, audioMuted, videoMuted, screenMuted, videoSettings: getVideoSettings(localStream) };
    isConnected && isDataChannelOpened && adaptorRef.current?.updateUserStatus(userStatusRef.current as StreamMetaData);
  }, [isConnected, isDataChannelOpened, audioMuted, screenMuted, videoMuted, localStream]);

  useEffect(() => {
    if (isOnline || !isConnected) {
      return;
    }

    const timeoutId = setTimeout(() => {
      closeSession(ConnectionState.Fail);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [closeSession, isConnected, isOnline]);

  return {
    url: config?.url,
    streams,
    localStream,
    videoTracks,
    remoteStreams,
    connectionState,
    isConnecting,
    audioMuted,
    videoMuted,
    screenMuted,
    speakerMuted,
    muteAudio,
    muteVideo,
    muteScreen,
    muteSpeaker: isConnected ? muteSpeaker : undefined,
    flipCamera,
    joinSession,
    leaveSession,
    resetConnectionState,
  };
}
