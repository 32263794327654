import { ActivityItemCodesEnum, usePatientHealthParameterListQuery, usePatientHealthParametersInsertMutation } from "@/domains/activites";
import { H_OrderDirection, PatientHealthParameterSortField } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { debounce } from "@toolkit/core";
import { useAddToast } from "@toolkit/ui";
import { toNumber } from "lodash";
import { useEffect, useMemo, useState } from "react";

type UseTodayFeelingSectionProps = {
  patientId: string;
};

export const useTodayFeelingSection = ({ patientId }: UseTodayFeelingSectionProps) => {
  const [moodIndex, setMoodIndex] = useState<number>(3);

  const { failed } = useAddToast();

  const { data: patientHealthParameterData } = usePatientHealthParameterListQuery({
    variables: {
      first: 10,
      filter: {
        patientId: [patientId],
        codes: [ActivityItemCodesEnum.Mood_Tracking],
      },
      sortBy: {
        field: PatientHealthParameterSortField.ReadingDate,
        direction: H_OrderDirection.Desc,
      },
    },
    fetchPolicy: "no-cache",
    skip: !patientId,
  });

  const moodPatientHealthParameters = patientHealthParameterData?.patientHealthParameters?.edges || [];

  const moodPatientHealthParameter = Math.min(Math.max(toNumber(moodPatientHealthParameters[0]?.node?.valueNumber) ?? 0, 0), 5);

  const [insertPatientHealthParameters, { loading }] = usePatientHealthParametersInsertMutation({
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  useEffect(() => {
    setMoodIndex(moodPatientHealthParameter);
  }, [moodPatientHealthParameter]);

  const debouncedInsert = useMemo(
    () =>
      debounce((index: number) => {
        insertPatientHealthParameters({
          variables: {
            input: {
              hpCode: ActivityItemCodesEnum.Mood_Tracking,
              valueNumber: index,
            },
          },
        });
      }, 500),
    [insertPatientHealthParameters]
  );

  useEffect(() => {
    return () => {
      debouncedInsert.cancel();
    };
  }, [debouncedInsert]);

  const handleMoodChange = (value: number[] | number) => {
    const numericValue = Array.isArray(value) ? value[0] : value;
    const newIndex = Math.min(Math.max(Math.round(numericValue), 1), 5);
    setMoodIndex(newIndex);
    debouncedInsert(newIndex);
  };

  return { moodIndex, handleMoodChange, moodPatientHealthParameter, loading };
};
