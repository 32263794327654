import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeetingSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeetingSettingsQuery = { __typename?: 'Query', siteSettings: { __typename?: 'SiteSettings', conferenceProviderType?: Types.ConferenceProviderType | null, conferenceProviderTypeWebSocketUrl?: string | null } };


export const MeetingSettingsDocument = gql`
    query MeetingSettings {
  siteSettings {
    conferenceProviderType
    conferenceProviderTypeWebSocketUrl
  }
}
    `;

/**
 * __useMeetingSettingsQuery__
 *
 * To run a query within a React component, call `useMeetingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeetingSettingsQuery(baseOptions?: Apollo.QueryHookOptions<MeetingSettingsQuery, MeetingSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeetingSettingsQuery, MeetingSettingsQueryVariables>(MeetingSettingsDocument, options);
      }
export function useMeetingSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeetingSettingsQuery, MeetingSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeetingSettingsQuery, MeetingSettingsQueryVariables>(MeetingSettingsDocument, options);
        }
export function useMeetingSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeetingSettingsQuery, MeetingSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeetingSettingsQuery, MeetingSettingsQueryVariables>(MeetingSettingsDocument, options);
        }
export type MeetingSettingsQueryHookResult = ReturnType<typeof useMeetingSettingsQuery>;
export type MeetingSettingsLazyQueryHookResult = ReturnType<typeof useMeetingSettingsLazyQuery>;
export type MeetingSettingsSuspenseQueryHookResult = ReturnType<typeof useMeetingSettingsSuspenseQuery>;
export type MeetingSettingsQueryResult = Apollo.QueryResult<MeetingSettingsQuery, MeetingSettingsQueryVariables>;