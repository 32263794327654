import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGuidedCareJourneyStatisticsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.CareJourneyStatisticsFilterInput>;
}>;


export type GetGuidedCareJourneyStatisticsQuery = { __typename?: 'Query', careJourneyStatistics?: { __typename?: 'CareJourneyStatistics', allCompletedActivities?: number | null, allActivities?: number | null, adherence?: number | null, gaps?: number | null } | null };


export const GetGuidedCareJourneyStatisticsDocument = gql`
    query getGuidedCareJourneyStatistics($filter: CareJourneyStatisticsFilterInput) {
  careJourneyStatistics(filter: $filter) {
    allCompletedActivities
    allActivities
    adherence
    gaps
  }
}
    `;

/**
 * __useGetGuidedCareJourneyStatisticsQuery__
 *
 * To run a query within a React component, call `useGetGuidedCareJourneyStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuidedCareJourneyStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuidedCareJourneyStatisticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetGuidedCareJourneyStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<GetGuidedCareJourneyStatisticsQuery, GetGuidedCareJourneyStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGuidedCareJourneyStatisticsQuery, GetGuidedCareJourneyStatisticsQueryVariables>(GetGuidedCareJourneyStatisticsDocument, options);
      }
export function useGetGuidedCareJourneyStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGuidedCareJourneyStatisticsQuery, GetGuidedCareJourneyStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGuidedCareJourneyStatisticsQuery, GetGuidedCareJourneyStatisticsQueryVariables>(GetGuidedCareJourneyStatisticsDocument, options);
        }
export function useGetGuidedCareJourneyStatisticsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGuidedCareJourneyStatisticsQuery, GetGuidedCareJourneyStatisticsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGuidedCareJourneyStatisticsQuery, GetGuidedCareJourneyStatisticsQueryVariables>(GetGuidedCareJourneyStatisticsDocument, options);
        }
export type GetGuidedCareJourneyStatisticsQueryHookResult = ReturnType<typeof useGetGuidedCareJourneyStatisticsQuery>;
export type GetGuidedCareJourneyStatisticsLazyQueryHookResult = ReturnType<typeof useGetGuidedCareJourneyStatisticsLazyQuery>;
export type GetGuidedCareJourneyStatisticsSuspenseQueryHookResult = ReturnType<typeof useGetGuidedCareJourneyStatisticsSuspenseQuery>;
export type GetGuidedCareJourneyStatisticsQueryResult = Apollo.QueryResult<GetGuidedCareJourneyStatisticsQuery, GetGuidedCareJourneyStatisticsQueryVariables>;