import { makeStyles } from "@toolkit/ui";

export const useActivitiesCardStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    paddingBlock: 12,
    paddingInline: 16,
    justifyContent: "space-between",
    marginTop: 5,
  },
  startContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    gap: 10,
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  endContainer: {
    flex: 1.5,
    width: 200,
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
}));
