import { makeStyles, useTheme } from "@toolkit/ui";

const useIconStyles = makeStyles()(() => {
  return {
    iconInClock: {
      padding: 4,
      borderWidth: 2,
      borderStyle: "solid",
      borderRadius: 25,
      backgroundColor: "white",
    },
  };
});

export const WakeupIcon = () => {
  const theme = useTheme();
  const { classes: styles } = useIconStyles();

  return (
    <svg width='24' height='24' viewBox='0 0 30 30' fontSize={40} className={styles.iconInClock}>
      <circle cx={15} cy={15} r={15} stroke={theme.palette.primary.main} strokeWidth={0} fill='white' />

      <path
        id='icons8-alarm'
        data-name='Path alarm'
        d='M6.292,2a4.437,4.437,0,0,0-3.2,7.354L9.356,3.089A4.477,4.477,0,0,0,6.292,2Zm13.415.012A4.474,4.474,0,0,0,16.645,3.1L22.91,9.366A4.449,4.449,0,0,0,22.7,3.308,4.4,4.4,0,0,0,19.707,2.013ZM13,4.539A9.3,9.3,0,0,0,6.254,20.251L4.787,21.718a.847.847,0,1,0,1.2,1.2l1.544-1.544a9.261,9.261,0,0,0,10.952,0l1.544,1.544a.847.847,0,1,0,1.2-1.2l-1.467-1.466A9.3,9.3,0,0,0,13,4.539Zm0,2.54h.01a.841.841,0,0,1,.842.842V14.7H8.767a.841.841,0,0,1-.842-.842v-.01A.841.841,0,0,1,8.767,13h3.391V7.921A.841.841,0,0,1,13,7.079Z'
        transform='translate(2 2)'
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
