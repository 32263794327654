import { routes } from "@/domains/my-documents";
import { MainPageLayout, PageWithBackHeaderLayout } from "@/shared/components";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const MyDocumentsRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageLayout containerBreakpoint='sm' />}>
        <Route path={routes.myDocument.route} Component={routes.myDocument.component} />
      </Route>
      <Route element={<PageWithBackHeaderLayout containerBreakpoint='sm' />}>
        <Route path={routes.documentUpload.route} element={<routes.documentUpload.component />} />
      </Route>
    </Routes>
  );
};
