import { routesPaths } from "@/routing/routesPaths";
import { BookAnAppointmentIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { Box, Grid, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./ComingSoonCardStyle";

type BookAnAppointmentCardProps = {
  className?: string;
};

export const BookAnAppointmentCard: FC<BookAnAppointmentCardProps> = props => {
  const { className } = props;
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation("consumer");

  const handleBookNow = () => {
    navigate(routesPaths.appointments);
  };

  return (
    <Box role='button' className={cx(className, classes.root, classes.emptyCard__root)} onClick={handleBookNow}>
      <Grid height='100%' className={"card__container"} container gap={2} alignItems='center' justifyContent='start'>
        <Grid item className={cx(classes.iconWrapper, "card__startIcon")}>
          <BookAnAppointmentIcon />
        </Grid>
        <Grid item className={classes.textWrapper}>
          <Typography className={cx(classes.typographyTitle, "card__title")}>{t("Book Now!")}</Typography>
          <Typography className={cx(classes.typographySubtitle, "card__subtitle")}>
            {t("There are no upcoming appointments scheduled")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
