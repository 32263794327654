import { CustomUserAvatar } from "@/shared/components";
import { Box, CameraIcon, CircularProgress, IconButton, ImageUpload, Typography } from "@toolkit/ui";
import { isEmpty } from "lodash";
import { useRef } from "react";
import { useAuth } from "react-oidc-context";
import { useStyles } from "./PatientAvatarWithUploadAndDependentsStyle";
import { usePatientAvatarWithUploadAndDependents } from "./usePatientAvatarWithUploadAndDependents";

export const PatientAvatarWithUploadAndDependents: React.FC<{ avatarId: string }> = ({ avatarId }) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const { user } = useAuth();
  const { classes, theme } = useStyles();
  const { isLoading, handleChangIsImageUploading, handleUpdatePatientImage, photo, avatar, dependents, id } =
    usePatientAvatarWithUploadAndDependents();

  const firstDependent = dependents?.[0];
  const secondDependent = dependents?.[1];
  const remainDependents = dependents?.slice(2, dependents.length);

  const handleNavigateToDependents = () => {
    console.log("Navigate to dependents ");
  };

  return (
    <>
      <CustomUserAvatar id={avatarId} key={id} isLoading={isLoading} src={photo! || avatar!} width='100%' height='100%'>
        <ImageUpload
          handleImageError={handleChangIsImageUploading(false)}
          handleImageProgress={handleChangIsImageUploading(true)}
          classes={{ root: classes.uploadContainer }}
          enableToastMessage
          token={user?.access_token}
          ref={ref}
          handleImageUploaded={handleUpdatePatientImage}
          image={undefined}
          label={""}
          id={"patient-image-uploader"}
        />
        {isLoading && <CircularProgress size={"35%"} className={classes.loadingProgress} />}
        <IconButton
          onClick={() => {
            ref.current?.click();
          }}
          className={classes.uploadButton}
        >
          <CameraIcon fill={theme.palette.primary.main} />
        </IconButton>
        <Box onClick={handleNavigateToDependents} className={classes.firstDependentBox}>
          {firstDependent && (
            <CustomUserAvatar
              key={firstDependent?.id}
              src={firstDependent?.photo || firstDependent?.avatar || undefined}
              width='100%'
              height='100%'
              withoutBackground
            />
          )}
        </Box>
        <Box onClick={handleNavigateToDependents} className={classes.secondDependentBox}>
          {secondDependent && (
            <CustomUserAvatar
              key={secondDependent?.id}
              src={secondDependent?.photo || secondDependent?.avatar || undefined}
              width='100%'
              height='100%'
              withoutBackground
            />
          )}
        </Box>
        {!isEmpty(remainDependents) && (
          <Box onClick={handleNavigateToDependents} className={classes.remainDependentsBox}>
            <CustomUserAvatar
              fillInnerBlob={theme.palette.primary.main}
              fillOuterBlob={theme.palette.common.white}
              src=''
              width='100%'
              height='100%'
              withoutBackground
              withoutDefaultIcon
            >
              <Typography className={classes.dependentTypography}>{remainDependents?.length}</Typography>
            </CustomUserAvatar>
          </Box>
        )}
      </CustomUserAvatar>
    </>
  );
};
