import { createAutocompleteOption } from "@toolkit/ui";
import { pick } from "lodash";
import { useMemo } from "react";
import type { Location } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { addressUpsertFormSchemaDefaultValues, IGoogleMapAddressUpsertFormValues } from "../forms/AddressFormUpsert";
import { AddressMapRedirectDataType } from "../types";
import { mapFormAddressWithMapAddress } from "../utils";
import { useGetCityOptionQuery } from "../gql/queries";

const useMapAddress = () => {
  const location: Location<AddressMapRedirectDataType | undefined> = useLocation();

  const { address: _mapAddress } = location?.state || {};
  const { data: cityInfo } = useGetCityOptionQuery({
    variables: {
      filter: {
        name_Icontains: _mapAddress?.city || "",
      },
    },
    skip: !_mapAddress?.city?.length,
  });

  const mapAddress = useMemo(() => {
    if (!_mapAddress) {
      return undefined;
    }
    const address = mapFormAddressWithMapAddress(_mapAddress);
    const city = cityInfo?.cities?.edges?.[0]?.node;

    return {
      ...pick(addressUpsertFormSchemaDefaultValues, ["area", "buildingNumber", "streetAddress2", "unitNumber"]),
      ...address,
      city: city?.id ? createAutocompleteOption(city, "name", "nameAr") : undefined,
    } as Partial<IGoogleMapAddressUpsertFormValues> | undefined;
  }, [_mapAddress, cityInfo]);

  return {
    mapAddress,
  };
};

export default useMapAddress;
