import React from "react";
import { Box, Typography } from "@toolkit/ui";
import { useStyles } from "./PriceDisplayStyle";
import { useSiteSettingsGetQuery } from "@/gql";

interface PriceDisplayProps {
  type?: "base-price" | "discounted-price";
  amount?: number | string;
  currency?: string;
  increaseFontSizeBy?: number;
  color?: string;
}

export const PriceDisplay: React.FC<PriceDisplayProps> = props => {
  const { type = "discounted-price", amount, currency: _currency, increaseFontSizeBy = 0, color } = props;
  const { classes, cx } = useStyles({ increaseFontSizeBy, color });
  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
    skip: !!_currency,
  });
  const currency = _currency ?? data?.siteSettings?.defaultCurrency;

  if (amount === undefined) return null;

  return (
    <Box className={cx(classes.priceContainer)}>
      {currency && <Typography className={cx(classes.currency, type && type === "base-price" && classes.discount)}>{currency}</Typography>}
      <Typography className={cx(classes.price, type === "base-price" && classes.discount)}>{amount}</Typography>
    </Box>
  );
};
