import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type HealthPackageInfoFragment = { __typename?: 'MarketplaceHealthPackage', id: string, name?: string | null, nameAr?: string | null, price?: any | null, description?: string | null, descriptionAr?: string | null, contentType?: Types.MarketplaceContentType | null, shortDescription?: string | null, shortDescriptionAr?: string | null, mainImageUrl?: string | null, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, endDate: any, percentage?: number | null, startDate: any, type: Types.MarketplaceDiscountType } | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null } };

export const HealthPackageInfoFragmentDoc = gql`
    fragment HealthPackageInfo on MarketplaceHealthPackage {
  id
  name
  nameAr
  price
  description
  descriptionAr
  contentType
  activeDiscount {
    amount
    endDate
    percentage
    startDate
    type
  }
  shortDescription
  shortDescriptionAr
  vendor {
    id
    name
    nameAr
  }
  mainImageUrl
}
    `;