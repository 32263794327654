import { zodEnumSchema } from "@health/enum-options";
import moment from "moment";
import { z } from "zod";

export const PatientHealthDocumentFormSchema = z.object({
  name: z.string().min(1),
  fileLinks: z.array(z.string()).min(1),
  description: z.string().min(1),
  resultDate: z.string().refine(value => {
    return value ? moment(value, "YYYY-MM-DD", true).isValid() : true;
  }),
  type: zodEnumSchema.patientHealthDocumentType,
});

export type IPatientHealthDocumentFormValues = z.infer<typeof PatientHealthDocumentFormSchema>;
export const patientHealthDocumentFormSchemaDefaultValues: Partial<IPatientHealthDocumentFormValues> = {
  name: "",
  fileLinks: [],
  description: "",
  resultDate: "",
  type: undefined,
};
