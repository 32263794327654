import { makeStyles } from "@toolkit/ui";

export const useHealthPackageImageStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "245px",
    borderRadius: "10px",
    position: "relative",
    display: "flex",
    justifyContent: "start",
    alignItems: "end",
    padding: 0,
  },
  image: {
    objectFit: "cover",
    borderRadius: "inherit",
    width: "100%",
    height: "100%",
  },
  imageRoot: {
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "inherit",
      background: `linear-gradient(to top, rgba(0, 0, 0, 0.768), rgba(1, 1, 1, 0))`,
      opacity: 0.99,
    },
  },
  title: {
    width: "100%",
    position: "absolute",
    padding: "12px",
    color: theme.palette.common.white,
    fontSize: theme.mixins.fonts.fontSize.xl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    zIndex: 2,
    maxWidth: "calc(100% - 24px)",
    textAlign: "start",
  },
}));
