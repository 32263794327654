import { ChatAttachment, ChatAttachmentInput } from "../../schema/types";
import { FC } from "react";
import { ChatViewMediaAudio } from "./ChatViewMediaAudio";
import { ChatViewMediaDocument } from "./ChatViewMediaDocument";
import { ChatViewMediaPhoto } from "./ChatViewMediaPhoto";
import { ChatViewMediaVideo } from "./ChatViewMediaVideo";

type ChatViewMediaProps = {
  attachments: (ChatAttachment | ChatAttachmentInput)[];
};

export const ChatViewMedia: FC<ChatViewMediaProps> = ({ attachments }) => {
  return (
    <>
      {attachments.map(attachment => {
        const { type, url: key } = attachment;
        if (type.startsWith("audio/")) {
          return <ChatViewMediaAudio key={key} media={attachment} />;
        }
        if (type.startsWith("video/")) {
          return <ChatViewMediaVideo key={key} media={attachment} />;
        }
        if (type.startsWith("image/")) {
          return <ChatViewMediaPhoto key={key} media={attachment} />;
        }
        return <ChatViewMediaDocument key={key} media={attachment} />;
      })}
    </>
  );
};
