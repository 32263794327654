import { makeStyles } from "@toolkit/ui";

export const useHealthPackagesContainerStyles = makeStyles()(() => ({
  healthPackagesCardWrapper: {
    paddingBlock: "5px",
    "&&:first-child": {
      marginInlineStart: "5px",
    },
    "&:last-child": {
      marginInlineEnd: "5px",
    },
    width: "318px",
    height: "285px",
  },
  cardsContainerClassName: {
    height: "100%",
    overflowY: "hidden",
    backgroundColor: "inherit",
  },
  cardsUpperContainer: {
    gap: "0px",
  },
  cardsContainer: {},
  card: {
    marginInlineEnd: 0,
    height: "fit-content",
  },
  navBtnClassName: {},
  navBtnContainerClassName: {},
  actionsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  skeletonContainer: {
    width: "max-content",
    overflow: "initial",
  },
}));
