import { EmptyMessageLayout } from "@/shared/components";
import { EmptyFilesIcon } from "@/shared/icons";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress, Typography } from "@toolkit/ui";
import { isNil } from "lodash";
import queryString from "query-string";
import { FC, useMemo } from "react";
import { AccountSettingsTabs } from "../../components/AccountSettingsTabs/AccountSettingsTabs";
import { accountSettingsTabs } from "../../components/AccountSettingsTabs/tabs";
import { useAccountSettingsInformationQuery } from "../../gql/queries";
import { MyAccountsSettingsTabsEnum } from "../../types";

export const AccountSettingContainer: FC = () => {
  const { classes } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");

  const defaultTab = accountSettingsTabs[2];
  const { tab } = queryString.parse(location.search) as { tab?: MyAccountsSettingsTabsEnum };
  const currentTab = useMemo(() => accountSettingsTabs.find(({ routeParameterValue }) => routeParameterValue === tab) || defaultTab, [tab]);
  const { data, loading } = useAccountSettingsInformationQuery({});

  const accountSettingsInformation = data?.me;

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography className={classes.pageTitle}>{t("Account Settings")}</Typography>
        <AccountSettingsTabs currentTab={currentTab?.routeParameterValue} />
        {loading && <CircularProgress />}
        {!loading && isNil(accountSettingsInformation) && (
          <EmptyMessageLayout mt={15} message={t("Something went wrong, please try later!")} icon={<EmptyFilesIcon />} />
        )}
        {!loading && !isNil(accountSettingsInformation) && currentTab?.component && (
          <currentTab.component accountSettingsInformation={accountSettingsInformation} />
        )}
      </Box>
    </Box>
  );
};
