import { AppointmentBaseRoute } from "@/domains/appointment/routes";
import { CareTeamBaseRoute } from "@/domains/care-teams/routes";
import { SleepCalculationBaseRoute } from "@/domains/digital-twin-sleep-calculator/routes";
import { WeightLossPlanCalculationBaseRoute } from "@/domains/digital-twin-weight-loss-calculator/routes";
import { TdeeAndBmrCalculationBaseRoute } from "@/domains/digital-twin-tdee-bmr-calculator/routes";
import { DoctorBaseRoute } from "@/domains/doctor";
import { HealthMarketBaseRoute } from "@/domains/marketplace/routes";
import { MyPreviousJourneysRoute } from "@/domains/my-previous-journeys";
import { MyWalletRoute } from "@/domains/my-wallet";
import { NotificationsBaseRoute } from "@/domains/notifications/routes";
import { orderMedications } from "@/domains/order-medication";
import { VirtualVisitRoute } from "@/domains/VirtualVisit/routes";
import { VisitCallBaseRoute, VisitCallGuestBaseRoute } from "@/domains/VisitCall/routes";
import { AccountSettingsBaseRoute } from "@/pages/AccountSettings/routes";
import { HomeBaseRoute } from "@/pages/Home/routes";
import { wellnessBaseRoute } from "@/domains/wellness/routes";

export const routesPaths = {
  login: "/login",
  landing: "/",
  changeLanguage: "/change-language",
  getHelp: "/get-help",
  accountSettings: AccountSettingsBaseRoute,
  myDocuments: "/my-documents",
  deleteAccount: "/delete-account",
  verifyDeleteAccount: "/verify-delete-account",
  deleteAccountCompleted: "/delete-account-completed",
  playGround: "/playground",
  soon: "/soon",
  visitCall: VisitCallBaseRoute,
  visitCallGuest: VisitCallGuestBaseRoute,
  appointments: AppointmentBaseRoute,
  notifications: NotificationsBaseRoute,
  home: HomeBaseRoute,
  doctor: DoctorBaseRoute,
  careTeam: CareTeamBaseRoute,
  wallet: MyWalletRoute,
  previousJourneys: MyPreviousJourneysRoute,
  virtualVisit: VirtualVisitRoute,
  healthMarket: HealthMarketBaseRoute,
  sleepCalculation: SleepCalculationBaseRoute,
  tdeeAndBmrCalculation: TdeeAndBmrCalculationBaseRoute,
  weightLossCalculation: WeightLossPlanCalculationBaseRoute,
  wellness: wellnessBaseRoute,
  orderMedications,
  dependents: "/dependents",
};
