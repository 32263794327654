import { useCallback, useMemo } from "react";
import { useAntMedia } from "./useAntMedia";
import { useCallState } from "./useCallState";
import { useCallParticipants } from "./useCallParticipants";
import { ConferenceProviderType } from "@/schema/types";
import { CallState, ExecutedResponse } from "./types";
import { useTranslation } from "@toolkit/i18n";
import { fromBase64 } from "@toolkit/core";
import { useChatUnreadMessages } from "@health/chat";
import { CallInfo } from "../Call/CallContext";
import { useGetTurnServer } from "./useGetTurnServer";

export type CallInstance = ReturnType<typeof useCall>;
export function useCall({ callInfo, user }: { callInfo?: CallInfo; user?: { userId?: number; userName?: string } }) {
  const { t } = useTranslation();
  const { userId, userName } = user || {};
  const { meeting, configuration } = callInfo || {};
  const { id: callId } = meeting || {};
  const {
    url: defaultUrl,
    joinSession,
    leaveSession,
    speakerMuted,
    muteSpeaker,
    muteAudio,
    muteVideo,
    muteScreen,
    flipCamera,
  } = useAntMedia();

  const { currentParticipant, participants } = useCallParticipants({
    userId: userId,
    displayName: userName,
    meeting,
  });
  const { displayName, userIdentity } = currentParticipant;

  const { callState, updateCallStatus, resetCallState } = useCallState(meeting);
  const { audioMuted, videoMuted, screenMuted } = currentParticipant.state;
  const url = useMemo(() => {
    if (configuration?.antmediaConfiguration) {
      let socketUrl = configuration.antmediaConfiguration.url || "";
      if (socketUrl.startsWith("http")) {
        socketUrl = socketUrl.replace(`http`, `ws`);
      } else if (!socketUrl.startsWith("ws")) {
        socketUrl = `wss://${socketUrl}`;
      }

      return `${socketUrl}/${configuration.antmediaConfiguration.applicationName}/websocket`;
    }

    return defaultUrl;
  }, [configuration, defaultUrl]);
  const { getIceServers } = useGetTurnServer();

  const joinCall = useCallback(async (): Promise<ExecutedResponse> => {
    if (!meeting || !userIdentity || !displayName || !joinSession || !url) {
      return;
    }

    const result = await joinSession({
      url,
      roomId: fromBase64(meeting.id).split(":")[1],
      streamId: meeting.meetingParticipantStreamId!,
      streamName: displayName,
      publishToken: meeting.jwtToken!,
      iceServers: await getIceServers(),
      playToken: meeting.jwtMainTrackToken!,
      metaData: {
        userIdentity: `${userIdentity}`,
        audioMuted,
        videoMuted,
        screenMuted,
      },
    });

    if (result?.success) {
      updateCallStatus(CallState.InCall);
    }

    return result;
  }, [audioMuted, displayName, getIceServers, joinSession, meeting, screenMuted, updateCallStatus, url, userIdentity, videoMuted]);

  const dropCall = useCallback(async () => {
    updateCallStatus(CallState.Dropped);
    return leaveSession?.();
  }, [leaveSession, updateCallStatus]);

  const { unreadCount } = useChatUnreadMessages(meeting?.chatGroup?.id);

  return useMemo(() => {
    return {
      callId,
      callType: ConferenceProviderType.AntMedia,
      callInfo: meeting,
      callInvitationToken: "",
      unreadMessagesCount: unreadCount,
      visitId: meeting?.chatGroup?.visitId,
      title: meeting?.chatGroup?.name || t("Meeting"),
      participants,
      currentParticipant,
      state: {
        ...currentParticipant.state,
        callState,
        speakerMuted: speakerMuted!,
      },
      flipCamera: flipCamera as any,
      muteAudio: muteAudio!,
      muteVideo: muteVideo!,
      muteSpeaker: muteSpeaker as any,
      muteScreen,
      joinCall,
      dropCall,
      updateCallStatus,
      resetCallState,
    };
  }, [
    callId,
    meeting,
    unreadCount,
    t,
    participants,
    currentParticipant,
    callState,
    speakerMuted,
    flipCamera,
    muteAudio,
    muteVideo,
    muteScreen,
    muteSpeaker,
    joinCall,
    dropCall,
    updateCallStatus,
    resetCallState,
  ]);
}
