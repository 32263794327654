import { DoctorAvatar, DoctorCardLayout, Dot } from "@/shared/components";
import { displayStringOfArray } from "@/shared/utils";
import { formatUserNameFull } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Avatar, Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { CareTeamMemberType } from "../../type";
import { useCareTeamCardStyle } from "../CareTeamCard/CareTeamCardStyle";

type CareTeamMemberCardProps = {
  member?: CareTeamMemberType;
  isTeamLeader?: boolean;
  classes?: Partial<ReturnType<typeof useCareTeamCardStyle>["classes"]>;
};

export const CareTeamMemberCard: FC<CareTeamMemberCardProps> = props => {
  const { member, isTeamLeader = false, classes: _classes } = props;
  const user = member?.user;

  const { classes, cx } = useCareTeamCardStyle({ hasActions: false });
  const { t } = useTranslation("consumer");

  const doctor = member?.user?.doctor;

  return (
    <DoctorCardLayout
      doctorAvatarNode={
        <Box className={cx(classes.doctorAvatar, _classes?.doctorAvatar)}>
          <DoctorAvatar className={cx(classes.doctorAvatar, _classes?.doctorAvatar)} imageUrl={user?.photo || ""} />
          {isTeamLeader && (
            <Typography className={cx(classes.teamLeaderBadge, _classes?.teamLeaderBadge)}>{t("Care Team Leader")}</Typography>
          )}
        </Box>
      }
      infoNode={
        <>
          <Typography className={cx(classes.teamName, _classes?.teamName)}>
            {user?.fullName || formatUserNameFull(user) || formatUserNameFull(doctor?.user)}
          </Typography>
          {isTeamLeader && (
            <Typography className={cx(classes.vendorName, _classes?.vendorName)}>
              {displayStringOfArray(doctor?.specializations?.map(spec => spec?.display || "") || undefined)}
            </Typography>
          )}
          {isTeamLeader && (
            <Typography className={cx(classes.vendorName, _classes?.vendorName)}>
              {pickLocalizedValue(member?.user?.vendor?.name, member?.user?.vendor?.name)}
            </Typography>
          )}
          {isTeamLeader && (
            <Box className={cx(classes.languagesAndExperienceContainer, _classes?.languagesAndExperienceContainer)}>
              <Box className={cx(classes.languages, _classes?.languages)}>
                {displayStringOfArray(doctor?.languages?.map(lang => lang?.display || "") || undefined)}
                {doctor?.yearsOfExperience && (
                  <Box display={"inline"}>
                    <Dot className={cx(classes.dot, _classes?.dot)} size={7} />
                    {t("{{years}} Years Experience", { years: doctor?.yearsOfExperience })}
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </>
      }
      downAvatarNode={
        user?.vendor?.logo && isTeamLeader ? (
          <Avatar className={cx(classes.vendorAvatar, _classes?.vendorAvatar)} title={user?.vendor?.name} src={user?.vendor?.logo} />
        ) : null
      }
      classes={{
        avatarsContainer: cx(classes.avatarsContainer, _classes?.avatarsContainer, !isTeamLeader && classes.fullHeight),
        actionsContainer: cx(classes.actionsContainer, _classes?.actionsContainer),
        infoContainer: cx(classes.infoContainer, _classes?.infoContainer),
        card: cx(classes.card, _classes?.card),
        downAvatarContainer: cx(!isTeamLeader && classes.displayNone),
      }}
    />
  );
};
