import React from "react";
import { Box, Skeleton } from "@toolkit/ui";
import { useRecipeCardStyles } from "../RecipeCard/RecipeCardStyle";

export const RecipeCardSkeleton: React.FC = () => {
  const { classes } = useRecipeCardStyles();

  return (
    <Box className={classes.root} borderRadius='10px'>
      <Skeleton variant='rectangular' className={classes.imageContainer} height={140} sx={{ borderRadius: "10px 10px 0 0" }} />

      <Box className={classes.content}>
        <Skeleton variant='text' width='80%' height={24} sx={{ fontSize: "1rem" }} />

        <Skeleton variant='text' width='100%' height={16} sx={{ mt: 1 }} />
        <Skeleton variant='text' width='90%' height={16} sx={{ mt: 0.5 }} />

        <Box className={classes.tagsContainer} sx={{ mt: 2 }}>
          <Skeleton variant='rectangular' width={50} height={24} sx={{ borderRadius: 15 }} />
          <Skeleton variant='rectangular' width={60} height={24} sx={{ borderRadius: 15 }} />
          <Skeleton variant='rectangular' width={55} height={24} sx={{ borderRadius: 15 }} />
        </Box>
      </Box>
    </Box>
  );
};
