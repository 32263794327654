import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceReviewSubmitMutationVariables = Types.Exact<{
  input: Types.MarketplaceReviewInput;
}>;


export type MarketplaceReviewSubmitMutation = { __typename?: 'Mutation', marketplaceReviewSubmit?: { __typename?: 'MarketplaceReview', comment?: string | null, id: string, rating: number } | null };


export const MarketplaceReviewSubmitDocument = gql`
    mutation MarketplaceReviewSubmit($input: MarketplaceReviewInput!) {
  marketplaceReviewSubmit(input: $input) {
    comment
    id
    rating
  }
}
    `;
export type MarketplaceReviewSubmitMutationFn = Apollo.MutationFunction<MarketplaceReviewSubmitMutation, MarketplaceReviewSubmitMutationVariables>;

/**
 * __useMarketplaceReviewSubmitMutation__
 *
 * To run a mutation, you first call `useMarketplaceReviewSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceReviewSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceReviewSubmitMutation, { data, loading, error }] = useMarketplaceReviewSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceReviewSubmitMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceReviewSubmitMutation, MarketplaceReviewSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceReviewSubmitMutation, MarketplaceReviewSubmitMutationVariables>(MarketplaceReviewSubmitDocument, options);
      }
export type MarketplaceReviewSubmitMutationHookResult = ReturnType<typeof useMarketplaceReviewSubmitMutation>;
export type MarketplaceReviewSubmitMutationResult = Apollo.MutationResult<MarketplaceReviewSubmitMutation>;
export type MarketplaceReviewSubmitMutationOptions = Apollo.BaseMutationOptions<MarketplaceReviewSubmitMutation, MarketplaceReviewSubmitMutationVariables>;