import { makeStyles } from "@toolkit/ui";

export const useCommonLabelValueStyles = makeStyles()(theme => ({
  label: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.stale.main,
    fontSize: theme.mixins.fonts.fontSize.sm,
    textAlign: "start",
  },
  value: {
    color: theme.palette.primary.main,
    textAlign: "start",
  },
  contentContainer: {
    paddingBlock: 0,
    paddingInline: 0,
  },
  labelValueContainer: {
    paddingTop: 10,
    paddingBottom: 2.5,
    paddingInline: 18,
    "&:nth-child(even)": {
      backgroundColor: theme.palette.primary[50],
    },
  },
  sectionContainer: {
    ":not(:last-child)": {
      borderBottom: "1px solid",
      borderColor: theme.palette.divider,
    },
  },
  sectionHeader: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "start",
    paddingInline: 18,
    paddingBlock: 17,
  },
}));
