import { createZodAutocompleteFromType } from "@toolkit/core";
import {
  ApplicationOrderMedicationUrlParamsType,
  ExternalOrderMedicationUrlParamsType,
  OrderMedicationContainerReferenceNumberSourceTypes,
  PatientHealthProgramMembersAutocomplete,
  PrescriptionDetailsApplicationUrlParams,
  PrescriptionDetailsExternalUrlParams,
  PrescriptionDetailsUrlInfo,
} from "./types";
import { z } from "zod";
import { IEPrescriptionReferenceSearchFormValues } from "../forms/EPrescriptionReferenceSearchForm/EPrescriptionReferenceSearchFormSchema";
import { HealthProgramMember, OrderStatus, ValidatePrescriptionInput } from "@/schema/types";
import { isEmpty } from "lodash";
import { ERX_HUB_FINAL_STATUSES, FINAL_ORDER_STATUSES } from "./constants";
import { i18n } from "@toolkit/i18n";
import queryString, { ParsedQuery } from "query-string";

export const healthProgramMemberSchema = createZodAutocompleteFromType<PatientHealthProgramMembersAutocomplete>({
  id: z.string(),
  insuranceId: z.string().nullish(),
});

export const convertEPrescriptionFormValuesToBackEndValues = (
  values: IEPrescriptionReferenceSearchFormValues
): ValidatePrescriptionInput => {
  return {
    referenceNumber: values?.ePrescriptionReference,
    memberID:
      (values?.variant === "autocomplete" ? values.healthProgramMemberAutocomplete?.value?.id : values?.healthProgramMemberText) || "",
  };
};

export const convertEPrescriptionToFormValues = (
  ePrescriptionReference?: string,
  healthProgramMember?: Array<Pick<HealthProgramMember, "id" | "insuranceId">>,
  memberId?: string
): IEPrescriptionReferenceSearchFormValues => {
  const isTextVariant = isEmpty(healthProgramMember) || memberId;
  return {
    ePrescriptionReference: ePrescriptionReference || "",
    variant: isTextVariant ? "text" : "autocomplete",
    healthProgramMemberText: isTextVariant ? memberId : undefined,
    healthProgramMemberAutocomplete: undefined,
  };
};

export const isOrderInFinalState = (status: OrderStatus): boolean => {
  return [...FINAL_ORDER_STATUSES, ...ERX_HUB_FINAL_STATUSES].includes(status);
};

export const getOrderStatusDetailsMapper = () =>
  ({
    [OrderStatus.NewRequest]: { label: i18n.t("New", { ns: "consumer" }), color: "primary" },
    [OrderStatus.CanceledByConsumer]: { label: i18n.t("Cancelled", { ns: "consumer" }), color: "error" },
    [OrderStatus.CanceledByProvider]: { label: i18n.t("Cancelled", { ns: "consumer" }), color: "error" },
    [OrderStatus.AcceptedByProvider]: { label: i18n.t("Pending Allocation", { ns: "consumer" }), color: "warning" },
    [OrderStatus.Allocated]: { label: i18n.t("Allocated", { ns: "consumer" }), color: "primary" },
    [OrderStatus.WaitingProviderSelection]: { label: i18n.t("Awaiting Provider", { ns: "consumer" }), color: "warning" },

    // Payment Statuses
    [OrderStatus.PaymentPending]: { label: i18n.t("Payment Pending", { ns: "consumer" }), color: "warning" },
    [OrderStatus.PaymentSucceeded]: { label: i18n.t("Payment Successful", { ns: "consumer" }), color: "success" },
    [OrderStatus.PaymentFailed]: { label: i18n.t("Payment Failed", { ns: "consumer" }), color: "error" },
    [OrderStatus.PaymentOnArrive]: { label: i18n.t("Pay on Arrival", { ns: "consumer" }), color: "primary" },
    [OrderStatus.PaymentTimedOut]: { label: i18n.t("Payment Timed Out", { ns: "consumer" }), color: "error" },

    // Delivery/Completion Statuses
    [OrderStatus.OutForDelivery]: { label: i18n.t("Out for Delivery", { ns: "consumer" }), color: "primary" },
    [OrderStatus.Delivered]: { label: i18n.t("Delivered", { ns: "consumer" }), color: "success" },
    [OrderStatus.ReadyForCustomerPickup]: { label: i18n.t("Ready for Pickup", { ns: "consumer" }), color: "success" },
    [OrderStatus.Dispensed]: { label: i18n.t("Dispensed", { ns: "consumer" }), color: "success" },
    [OrderStatus.Returned]: { label: i18n.t("Returned", { ns: "consumer" }), color: "warning" },

    // Approval/Authorization Statuses
    [OrderStatus.ErxHubFullyApproved]: { label: i18n.t("Approved", { ns: "consumer" }), color: "success" },
    [OrderStatus.ErxHubPartiallyApproved]: { label: i18n.t("Partially Approved", { ns: "consumer" }), color: "warning" },
    [OrderStatus.WaitingErxHubApproval]: { label: i18n.t("Pending Approval", { ns: "consumer" }), color: "warning" },
    [OrderStatus.WaitingErxHubClaimApproval]: { label: i18n.t("Pending Claim Approval", { ns: "consumer" }), color: "warning" },
    [OrderStatus.ErxHubClaimApproved]: { label: i18n.t("Claim Approved", { ns: "consumer" }), color: "success" },
    [OrderStatus.ErxHubClaimRejected]: { label: i18n.t("Claim Rejected", { ns: "consumer" }), color: "error" },

    // Errors and Failures
    [OrderStatus.Failed]: { label: i18n.t("Failed", { ns: "consumer" }), color: "error" },
    [OrderStatus.FailedToDispense]: { label: i18n.t("Failed to Dispense", { ns: "consumer" }), color: "error" },
    [OrderStatus.ErxHubRejected]: { label: i18n.t("Rejected", { ns: "consumer" }), color: "error" },
    [OrderStatus.ErxHubTimedOut]: { label: i18n.t("Order Timed Out", { ns: "consumer" }), color: "error" },
    [OrderStatus.ErxHubClaimFailed]: { label: i18n.t("Claim Failed", { ns: "consumer" }), color: "error" },
    [OrderStatus.ErxHubAuthRequestFailed]: { label: i18n.t("Auth Request Failed", { ns: "consumer" }), color: "error" },
    [OrderStatus.ErxHubAuthSubmitTimedOut]: { label: i18n.t("Auth Submit Timed Out", { ns: "consumer" }), color: "error" },
    [OrderStatus.ErxHubClaimSubmitTimedOut]: { label: i18n.t("Claim Submit Timed Out", { ns: "consumer" }), color: "error" },
  } as const);

export const getUrlPrescriptionOrderInfo = (params: queryString.ParsedQuery<string>): PrescriptionDetailsUrlInfo | null => {
  const isExternalPrescription = !!(params.erx && params.nid && params.mobile && params.mid && params.oid && params.pid);

  const isApplicationPrescription = !!(params.prescriptionId && params.orderId);

  if (isExternalPrescription) {
    const externalParams = params as unknown as PrescriptionDetailsExternalUrlParams;
    return {
      referenceId: externalParams.erx,
      sourceType: OrderMedicationContainerReferenceNumberSourceTypes.external,
      params: externalParams,
      mobileNumber: externalParams.mobile?.replace(/ /g, "+"),
      memberId: externalParams.mid,
      nationalId: externalParams.nid,
      prescriptionId: externalParams.pid,
      orderId: externalParams.oid,
    };
  }

  if (isApplicationPrescription) {
    const applicationParams = params as unknown as PrescriptionDetailsApplicationUrlParams;
    return {
      referenceId: applicationParams.prescriptionId,
      sourceType: OrderMedicationContainerReferenceNumberSourceTypes.application,
      params: applicationParams,
      prescriptionId: applicationParams.prescriptionId,
      orderId: applicationParams.orderId,
    };
  }

  return null;
};

export const getOrderMedicationUrlInfo = (params: ParsedQuery<string>) => {
  const isExternalReference = !!(params.erx && params.nid && params.mobile && params.mid);
  const isPickupSmsExternalPrescription = !!(params.erx && params.nid && params.mobile && params.mid && params.oid && params.pid);
  if (isExternalReference) {
    const externalParams = params as ExternalOrderMedicationUrlParamsType;
    return {
      referenceId: externalParams.erx,
      sourceType: OrderMedicationContainerReferenceNumberSourceTypes.external,
      params: externalParams,
      mobileNumber: externalParams.mobile?.replace(" ", "+"),
      memberId: externalParams.mid,
      nationalId: externalParams.nid,
    } as const;
  } else if (isPickupSmsExternalPrescription) {
    const pickupSmsParams = params as PrescriptionDetailsExternalUrlParams;
    return {
      referenceId: pickupSmsParams.erx,
      sourceType: OrderMedicationContainerReferenceNumberSourceTypes.pickupSms,
      params: pickupSmsParams,
      mobileNumber: pickupSmsParams.mobile?.replace(" ", "+"),
      memberId: pickupSmsParams.mid,
      nationalId: pickupSmsParams.nid,
    } as const;
  } else {
    const applicationParams = params as ApplicationOrderMedicationUrlParamsType;
    return {
      referenceId: applicationParams.referenceId,
      sourceType: OrderMedicationContainerReferenceNumberSourceTypes.application,
      params: applicationParams,
      mobileNumber: undefined,
      memberId: undefined,
      nationalId: undefined,
    } as const;
  }
};
