import React from "react";
import { Swiper, SwiperSlide, SwiperProps } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

interface CustomSwiperProps extends SwiperProps {
  slides: React.ReactNode[]; // Array of slides (React elements)
}

const CustomSwiper: React.FC<CustomSwiperProps> = ({ slides, ...props }) => {
  return (
    <Swiper {...props}>
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CustomSwiper;
