import { memo, useCallback } from "react";
import { CallParticipant } from "./types";
import {
  ListItemButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  MuiMicIcon,
  MuiMicOffIcon,
  MuiScreenShareIcon,
  MuiVideocamIcon,
  MuiVideocamOffIcon,
  ListItem,
} from "@toolkit/ui";
import { useParticipantListItemStyles } from "./ParticipantListItem.styles";
import { ChatAvatar } from "@health/chat";
import { useTranslation } from "@toolkit/i18n";

type ParticipantListItemProps = {
  participant: CallParticipant;
  onClick?: (participant: CallParticipant) => void;
  color?: string;
  hideActions?: boolean;
};

export const ParticipantListItem = memo(({ participant, color, onClick, hideActions }: ParticipantListItemProps) => {
  const { t } = useTranslation();
  const { classes } = useParticipantListItemStyles({ color });
  const handleOnClick = useCallback(() => {
    onClick?.(participant);
  }, [onClick, participant]);

  const Item = onClick ? ListItemButton : (ListItem as typeof ListItemButton);
  return (
    <Item key={participant.id} onClick={handleOnClick} className={classes.root}>
      <ListItemAvatar>
        <ChatAvatar value={participant.info} size={36} />
      </ListItemAvatar>
      <ListItemText>
        <Typography className={classes.title}>{participant.isCurrentUser ? t("You") : participant.displayName}</Typography>
      </ListItemText>
      {!hideActions && (
        <ListItemSecondaryAction className={classes.action}>
          {!participant.state.audioMuted ? <MuiMicIcon className={classes.icon} /> : <MuiMicOffIcon className={classes.icon} />}
          {!participant.state.videoMuted ? <MuiVideocamIcon className={classes.icon} /> : <MuiVideocamOffIcon className={classes.icon} />}
          {!participant.state.screenMuted && <MuiScreenShareIcon className={classes.icon} />}
        </ListItemSecondaryAction>
      )}
    </Item>
  );
});
