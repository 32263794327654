import { customWebViewStyle } from "@/domains/payment/custom-web-view/custom-web-view.component.style";
import { getSiteSettingItemSync } from "@/shared/utils/siteSettings";
import { Box } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const PaymentCallbackRootPage: FC = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  // eslint-disable-next-line max-statements
  useEffect(() => {
    const script = document.createElement("script");
    const optionsScript = document.createElement("script");
    const meta = document.createElement("meta");
    const style = document.createElement("style");
    optionsScript.innerHTML = `window.wpwlOptions = {
    style: "plain",
    locale: "en",
    showPlaceholders: true,
  }`;

    meta.httpEquiv = "Content-Security-Policy";
    const NONCE_ID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const baseURl = getSiteSettingItemSync("paymentGatewayBaseURL").replace(/com\/.*/g, "com");
    meta.content = `style-src 'self' ${baseURl} 'unsafe-inline' ; frame-src 'self' ${baseURl}; script-src 'self' ${baseURl} 'nonce-${NONCE_ID}' ; connect-src 'self' ${baseURl}; img-src 'self' ${baseURl}; `;
    script.src = params.get("checkout-payment-form-url")!;
    script.async = true;
    script.crossOrigin = "anonymous";
    script.integrity = atob(params.get("checkout-int")!);
    style.innerHTML = customWebViewStyle;
    script.setAttribute("nonce", NONCE_ID);
    optionsScript.setAttribute("nonce", NONCE_ID);
    document.head.prepend(meta);
    document.body.appendChild(optionsScript);
    document.body.appendChild(script);
    document.body.appendChild(style);

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(meta);
      document.body.removeChild(optionsScript);
    };
  }, []);

  return (
    <Box p={2} sx={{ width: "100%", height: "100%" }} className='process-payment-page'>
      <form action={params.get("result-url")!} className='paymentWidgets' data-brands='VISA MASTER AMEX'></form>
    </Box>
  );
};
