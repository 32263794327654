import { FC } from "react";
import { MarketPlaceCardLayout } from "../MarketplaceCardLayout/MarketplaceCardLayout";
import { MarketplaceItemAvatar } from "../MarketplaceItemAvatar/MarketplaceItemAvatar";
import { useHealthPackageSelectedCardStyle } from "./HealthPackageSelectedCardStyles";
import { TruncateTypography } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { PriceDisplay } from "../PriceDisplay/PriceDisplay";
import { PriceCalculationResult } from "../../utils";
import { SelectedCardHealthPackagePropsType } from "../../types";

type HealthPackageSelectedCardProps = {
  healthPackage?: SelectedCardHealthPackagePropsType;
  priceInfo: PriceCalculationResult;
  actionsNode?: React.ReactNode;
  handleOnClick?: () => void;
  classes?: Partial<ReturnType<typeof useHealthPackageSelectedCardStyle>["classes"]>;
  extraInfoNode?: React.ReactNode;
};

export const HealthPackageSelectedCard: FC<HealthPackageSelectedCardProps> = props => {
  const { healthPackage, actionsNode, priceInfo, classes: _classes, handleOnClick } = props;

  const { classes, cx } = useHealthPackageSelectedCardStyle();

  return (
    <MarketPlaceCardLayout
      onClick={handleOnClick}
      classes={{
        card: cx(classes.card, _classes?.card),
        itemAvatarContainer: cx(classes.itemAvatarContainer, _classes?.itemAvatarContainer),
        infoContainer: cx(classes.infoContainer, _classes?.infoContainer),
      }}
      infoNode={
        <>
          <TruncateTypography
            maxWidth='90%'
            className={cx(classes.itemName, _classes?.itemName)}
            text={pickLocalizedValue(healthPackage?.name, healthPackage?.nameAr) || ""}
          />
          <TruncateTypography
            text={pickLocalizedValue(healthPackage?.shortDescription, healthPackage?.shortDescriptionAr) || ""}
            width='100%'
            maxLines={3}
            className={cx(classes.itemDiscretion, _classes?.itemDiscretion)}
          />
          <PriceDisplay increaseFontSizeBy={1} amount={priceInfo?.discountedPrice} type='discounted-price' />
          {priceInfo?.hasActiveDiscount && <PriceDisplay increaseFontSizeBy={1} amount={priceInfo?.basePrice} type='base-price' />}
          {props.extraInfoNode}
        </>
      }
      actionsNode={actionsNode}
      itemAvatarNode={
        <MarketplaceItemAvatar
          classes={{
            img: cx(classes.image, _classes?.image),
            root: cx(classes.imageRoot, _classes?.imageRoot),
          }}
          imageUrl={healthPackage?.mainImageUrl}
          variant='square'
          hidden
          marketplaceItemType='healthPackage'
        />
      }
    />
  );
};
