import { FC } from "react";
import { Box, TruncateTypography, Typography } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { calculateDiscountedPrice } from "@/domains/marketplace/utils";
import { PriceDisplay } from "@/domains/marketplace/components/PriceDisplay/PriceDisplay";
import { MarketplaceHealthPackageDetails } from "@/domains/marketplace-health-packages/types";
import { useHealthPackageGeneralInfoStyle } from "./useHealthPackageGeneralInfoStyle";

type HealthPackageGeneralInfoProps = {
  healthPackage: MarketplaceHealthPackageDetails;
};

export const HealthPackageGeneralInfo: FC<HealthPackageGeneralInfoProps> = ({ healthPackage }) => {
  const { classes } = useHealthPackageGeneralInfoStyle();
  const { basePrice, discountedPrice } = calculateDiscountedPrice(healthPackage?.price, healthPackage?.activeDiscount);

  const hasDiscount = !!(healthPackage?.activeDiscount?.amount || healthPackage?.activeDiscount?.percentage);

  return (
    <>
      <Box className={classes.generalInfo}>
        <TruncateTypography
          className={classes.provider}
          text={pickLocalizedValue(healthPackage?.vendor?.name, healthPackage?.vendor?.nameAr) || ""}
        />
        <Box className={classes.healthPackageTitle}>
          <PriceDisplay increaseFontSizeBy={3} amount={discountedPrice} />
          {hasDiscount && <PriceDisplay increaseFontSizeBy={1} amount={basePrice} type='base-price' />}
        </Box>
      </Box>
      <Typography className={classes.healthPackageShortDescription}>
        {pickLocalizedValue(healthPackage?.shortDescription, healthPackage?.shortDescriptionAr) || ""}
      </Typography>
    </>
  );
};
