import { makeStyles } from "@toolkit/ui";

export const useProductImagesSliderStyle = makeStyles()(theme => ({
  root: {
    padding: 0,
    paddingBlockStart: "15px",
    height: "280px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: "250px",
    objectFit: "scale-down",
  },
  loader: {},
  pagination: {
    height: "100%",
    "--swiper-pagination-bottom": "5px",
    "& .swiper-pagination-bullet": {
      backgroundColor: "inherit",
      border: "1px solid",
      borderColor: theme?.palette.secondary.main,
      width: "10px",
      height: "10px",
      opacity: 1,
    },
    "& .swiper-pagination-bullet-active": {
      backgroundColor: theme?.palette.secondary.main,
      opacity: 1,
    },
  },
  skeleton: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  skeletonImage: {
    width: "90%",
    height: "230px",
    borderRadius: "4px",
  },
  skeletonPagination: {
    width: "80px",
    height: "10px",
    backgroundColor: theme.palette.grey[300],
    borderRadius: "4px",
  },
}));
