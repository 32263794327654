import { useHeaderContext } from "@/shared/context";
import { pickLocalizedValue } from "@toolkit/i18n";
import queryString from "query-string";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useMarketplaceHealthPackageQuery } from "../../gql";
type QueryParams = {
  healthPackageId?: string;
};

export const useHealthPackageContainerHook = () => {
  const queryParams: QueryParams = queryString.parse(location.search);
  const healthPackageId = queryParams.healthPackageId || "";
  const { setHeaderTitle } = useHeaderContext();
  const { isAuthenticated } = useAuth();

  const { data, loading } = useMarketplaceHealthPackageQuery({
    variables: {
      healthPackageId: healthPackageId,
      skipReviewSummary: !isAuthenticated,
    },
  });

  const healthPackageDetails = data?.marketplaceHealthPackage;

  useEffect(() => {
    setHeaderTitle(pickLocalizedValue(healthPackageDetails?.name, healthPackageDetails?.nameAr) || "");
    return () => {
      setHeaderTitle("");
    };
  }, [setHeaderTitle, healthPackageDetails]);

  return {
    isLoading: loading,
    healthPackageDetails,
  };
};
