import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  footer: {
    position: "sticky",
    backgroundColor: theme.palette.common.white,
    bottom: "0px",
    left: "0px",
    height: "74px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  button: {
    width: "215px",
    height: "48px",
    borderRadius: "24px",
    // width: "fit-content",
    paddingInline: "40px",
  },
}));
