import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, Typography } from "@toolkit/ui";
import { makeStyles } from "@toolkit/ui";

export const useChatListEmptyStyles = makeStyles()(() => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
}));

export type ChatListEmptyProps = {
  isLoading: boolean;
  hasError?: boolean;
  onRetry: () => void;
};

export const ChatListEmpty = ({ hasError, isLoading, onRetry }: ChatListEmptyProps) => {
  const { t } = useTranslation();
  const { classes, theme } = useChatListEmptyStyles();

  return (
    <Box className={classes.root}>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <Typography fontSize={theme.mixins.fonts.fontSize.md}>
            {hasError ? t("An error occurred, please try again.") : t("No messages yet!")}
          </Typography>
          {hasError && <Button onClick={onRetry}>{t("Retry")}</Button>}
        </>
      )}
    </Box>
  );
};
