import { AddressesList } from "@/domains/address/components/AddressesList/AddressesList";
import { AccountSettingsBodyProps } from "@/pages/AccountSettings/types";
import { FC } from "react";

export const AddressesBody: FC<AccountSettingsBodyProps> = props => {
  const { accountSettingsInformation } = props;
  return (
    <>
      <AddressesList addresses={accountSettingsInformation?.addresses} />
    </>
  );
};
