import { FC } from "react";
import { Box, CardsContainer, Typography } from "@toolkit/ui";
import moment from "moment";
import { useDeliveryTimeStyles } from "./useDeliveryTime.styles";
import { NUMBER_OF_MAX_ALLOWED_DELIVERY_DAYS } from "../../others/constants";

interface DateSelectorProps {
  selectedDate: moment.Moment;
  onDateSelect: (date: moment.Moment) => void;
}

export const DateSelector: FC<DateSelectorProps> = ({ selectedDate, onDateSelect }) => {
  const { classes, cx } = useDeliveryTimeStyles();

  const dates = Array.from({ length: NUMBER_OF_MAX_ALLOWED_DELIVERY_DAYS }, (_, i) => moment().add(i, "days"));

  return (
    <CardsContainer
      hideNavBtnsOnMobile
      cards={dates.map(date => (
        <Box
          key={date.format()}
          className={cx(classes.dateBox, {
            selected: date.format("YYYY-MM-DD") === selectedDate.format("YYYY-MM-DD"),
          })}
          onClick={() => onDateSelect(date)}
        >
          <Typography>{date.format("dddd")}</Typography>
          <Typography>{date.format("DD-M-yyyy")}</Typography>
        </Box>
      ))}
    />
  );
};
