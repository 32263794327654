/* eslint-disable no-restricted-imports */
import { useCustomForm } from "@toolkit/core";
import React from "react";
import { FormProvider } from "react-hook-form";
import {
  ProductsListFiltersFormSchema,
  productsListFiltersFormDefaultValues,
  IProductsListFiltersFormValues,
} from "./ProductsListFiltersFormSchema";

import { Box, Button } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useStyles } from "./ProductsListFiltersStyle";
import { VendorFilterForm } from "./Filters/VendorFilter";
import { BrandFilterForm } from "./Filters/BrandsFilter";
import { PriceFilterForm } from "./Filters/PriceFilter";
import { SortingFilterForm } from "./Filters/SortingFilter";

type ProductsListFiltersFormProps = {
  onSubmit?: (values: IProductsListFiltersFormValues) => void;
};

export type ProductsListFiltersFormRef = {
  submit: () => void;
};

export const ProductsListFiltersForm: React.FC<ProductsListFiltersFormProps> = props => {
  const { t } = useTranslation("consumer");
  const form = useCustomForm<IProductsListFiltersFormValues>({
    schema: ProductsListFiltersFormSchema,
    defaultValues: productsListFiltersFormDefaultValues,
    mode: "onChange",
  });
  const { setValue } = form;
  const onSubmit = <K extends keyof IProductsListFiltersFormValues>(fieldName: K, value: IProductsListFiltersFormValues[K]) => {
    setValue(
      fieldName as keyof IProductsListFiltersFormValues,
      value as IProductsListFiltersFormValues[keyof IProductsListFiltersFormValues]
    );
    props.onSubmit?.({
      ...form.getValues(),
      [fieldName]: value,
    });
  };
  const getValue = <K extends keyof IProductsListFiltersFormValues>(fieldName: K) => {
    return form.getValues()[fieldName];
  };
  const resetForm = () => {
    form.reset(productsListFiltersFormDefaultValues);
    props.onSubmit?.(productsListFiltersFormDefaultValues);
  };

  const { classes } = useStyles();
  return (
    <FormProvider {...form}>
      <form>
        <Box className={classes.container}>
          <Button className={classes.button} onClick={resetForm} variant='outlined'>
            {t("All")}
          </Button>
          <VendorFilterForm onSubmit={onSubmit} getValue={getValue} />
          <SortingFilterForm onSubmit={onSubmit} getValue={getValue} />
          <PriceFilterForm onSubmit={onSubmit} getValue={getValue} />
          <BrandFilterForm onSubmit={onSubmit} getValue={getValue} />
        </Box>
      </form>
    </FormProvider>
  );
};
