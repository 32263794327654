import { InfoCardWithHeader } from "@/shared/components";
import { ValueWithLabel } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { BlobERxIcon } from "@/shared/icons";
import { ActiveOrder, PrescriptionDetailsSummary } from "../../others/types";
import { useCommonLabelValueStyles } from "../../styles/useCommonLabelValue.styles";
import moment from "moment";
import { OrderMedicationsCardHeader } from "../OrderMedicationsCardHeader/OrderMedicationsCardHeader";

type ERxReferenceNumberProps = {
  prescription?: PrescriptionDetailsSummary;
  order?: ActiveOrder;
};

export const PrescriptionInformationCard: React.FC<ERxReferenceNumberProps> = ({ prescription, order }) => {
  const { classes } = useCommonLabelValueStyles();
  const { t } = useTranslation("consumer");
  const patientUser = prescription?.patient?.user;

  const commonProps = {
    labelProps: { className: classes.label },
    valueProps: { className: classes.value },
    className: classes.labelValueContainer,
  };

  const fields = [
    { labelKey: "Prescription Number", value: prescription?.referenceNumber },
    {
      labelKey: "Prescription Date",
      value: prescription?.modified && moment(prescription?.modified).format("DD-MM-YYYY"),
    },
    { labelKey: "City", value: order?.address?.city },
    {
      labelKey: "Name",
      value: `${patientUser?.firstName || ""} ${patientUser?.lastName || ""}`.trim() || "-",
    },
    {
      labelKey: "Doctor Name",
      value: `${prescription?.visit?.doctor?.user?.firstName || ""} ${prescription?.visit?.doctor?.user?.lastName || ""}`.trim(),
    },
    { labelKey: "National ID Number", value: patientUser?.nationalId },
    { labelKey: "Contact Number", value: patientUser?.mobile },
  ];

  return (
    <InfoCardWithHeader
      mt='15px'
      classes={{ contentContainer: classes.contentContainer }}
      header={<OrderMedicationsCardHeader title={t("Prescription information")} icon={<BlobERxIcon showBackground />} />}
    >
      {fields.map(({ labelKey, value }) => (
        <ValueWithLabel key={labelKey} label={t(labelKey)} value={value || "-"} {...commonProps} />
      ))}
    </InfoCardWithHeader>
  );
};
