import { formatCurrency } from "@/shared/utils";
import { coverageOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Box, Card, makeStyles, Skeleton, Typography } from "@toolkit/ui";
import { round } from "lodash";
import { FC, ReactNode } from "react";
import usePaymentSummary from "../hooks/use-payment-summary.hook";

export type PaymentSummaryProps = {
  doctorId?: string;
  priceBeforeVat?: number;
  priceAfterVat?: number;
  serviceFee?: number;
};

const useStyles = makeStyles()(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  title: {
    textAlign: "start",
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
    marginBottom: theme.spacing(4),
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  skeletonText: {},
  divider: {
    backgroundColor: theme.palette.primary.main,
    margin: `${theme.spacing(1)} 0`,
  },
  rowLabel: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));

export const PaymentSummary: FC<PaymentSummaryProps> = props => {
  const { doctorId, priceBeforeVat = 0, priceAfterVat = 0, serviceFee = 0 } = props;
  const { classes } = useStyles();
  const { t } = useTranslation("consumer");

  const { subtotal, coverage, currency, includeVat, total, taxes, isLoading, showOnlyTotal } = usePaymentSummary({
    priceBeforeVat,
    priceAfterVat,
    doctorId,
  });

  return (
    <Card className={classes.container}>
      {!showOnlyTotal && <Typography className={classes.title}>{t("Payment Summary")}</Typography>}

      {subtotal !== total && (
        <DetailRow isLoading={isLoading} label={t("Subtotal")} value={formatCurrency(round(subtotal, 2), currency!)} />
      )}

      {!!serviceFee && (
        <DetailRow
          isLoading={isLoading}
          label={t("Service Fee")}
          value={serviceFee ? formatCurrency(round(serviceFee, 2), currency!) : t("Free")}
        />
      )}

      {includeVat && !!taxes && <DetailRow isLoading={isLoading} label={t("Tax")} value={formatCurrency(round(taxes, 2), currency!)} />}

      <DetailRow isLoading={isLoading} label={t("Total")} value={formatCurrency(round(total, 2), currency!)} />

      {doctorId && <DetailRow isLoading={isLoading} label={t("Coverage")} value={coverageOptionsMap[coverage!]?.label} />}
    </Card>
  );
};

type DetailRowProps = {
  isLoading?: boolean;
  label: string;
  value: ReactNode;
};

export const DetailRow: FC<DetailRowProps> = ({ isLoading, label, value }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.row}>
      {isLoading ? (
        <>
          <Box className={classes.skeletonText}>
            <Skeleton width='70px' variant='text' />
          </Box>
          <Box className={classes.skeletonText}>
            <Skeleton width='70px' variant='text' />
          </Box>
        </>
      ) : (
        <>
          <Typography className={classes.rowLabel} variant='body2'>
            {label}
          </Typography>
          <Typography variant='body2'>{value}</Typography>
        </>
      )}
    </Box>
  );
};
