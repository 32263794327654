import { NotificationBellIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { getNotificationTextStyle } from "../../utils";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { marketplaceOrderStatusOptionsMap } from "@health/enum-options";

type MarketplaceOrderNotificationProps = {
  status: string;
  creationDate: string;
  isSeen: boolean;
};

export const MarketplaceOrderNotification: FC<MarketplaceOrderNotificationProps> = ({ creationDate, isSeen, status }) => {
  const { t } = useTranslation("consumer");
  const theme = useTheme();

  const { color } = getNotificationTextStyle(isSeen, theme);

  return (
    <NotificationCard title={t("Marketplace Order")} date={creationDate} icon={<NotificationBellIcon />} isSeen={isSeen}>
      <Box>
        <Typography sx={{ color }}>
          {t("Your marketplace order status have been changed to {{status}}", { status: marketplaceOrderStatusOptionsMap[status]?.label })}
        </Typography>
      </Box>
    </NotificationCard>
  );
};
