import { InfoCardWithHeader } from "@/shared/components";
import { Typography, useTheme } from "@toolkit/ui";
import { OrderMedicationsCardHeader } from "../OrderMedicationsCardHeader/OrderMedicationsCardHeader";
import { useTranslation } from "@toolkit/i18n";
import { BlobERxIcon } from "@/shared/icons";

type ERxReferenceNumberProps = {
  referenceNumber: string;
};

export const ERxReferenceNumber: React.FC<ERxReferenceNumberProps> = props => {
  const { referenceNumber } = props;
  const theme = useTheme();
  const { t } = useTranslation("consumer");
  return (
    <InfoCardWithHeader
      mt={"15px"}
      header={<OrderMedicationsCardHeader title={t("eRx Reference Number")} icon={<BlobERxIcon showBackground />} />}
    >
      <Typography fontSize={theme.mixins.fonts.fontSize.lg}>{referenceNumber}</Typography>
    </InfoCardWithHeader>
  );
};
