/* eslint-disable max-statements */
import { fetchEventSource, logSSE, logSSEError, logSSEWarning, useNetwork } from "@toolkit/core";
import React, { FC, PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { NotificationsServiceContext } from "./NotificationsServiceContext";
import { OrgSubscribeFn, TOPIC_LISTENERS } from "./types";
import _ from "lodash";

class FatalError extends Error {}

function uuidv4() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

// eslint-disable-next-line max-statements
export const NotificationsServiceProvider: FC<
  PropsWithChildren<{ eventSourceUrl: string; token: string; onTokenExpired?: () => void }>
> = ({ children, eventSourceUrl, token, onTokenExpired }) => {
  const windowID = useRef(uuidv4());
  const { online: isOnline } = useNetwork();

  const connectionStatusRef = useRef<"loading" | "connected" | "disconnected">("disconnected");
  function setConnectionStatus(v: "loading" | "connected" | "disconnected") {
    connectionStatusRef.current = v;
  }

  const topicsSubscribers = useRef<TOPIC_LISTENERS>({} as TOPIC_LISTENERS);
  const crossTabEventsChannel = useRef<BroadcastChannel | null>(
    typeof window === "undefined" ? null : new BroadcastChannel("crossTabEventsChannel")
  ).current;

  const onMessage = useCallback(ev => {
    if (ev.event === "FatalError") {
      logSSEError("subscription error:FatalError ", ev);
      throw new FatalError(ev.data);
    }
    if (ev.data) {
      logSSE("SSe Event Received ", ev?.data, { data: JSON.parse(ev?.data.replace(/^\s?data:\s?/g, "")) });

      try {
        const data = JSON.parse(ev?.data.replace(/^\s?data:\s?/g, ""));
        if (data.type === "ping-pong") return;
        const topic = unifyTopic(data.type);
        (topicsSubscribers.current?.[topic] || [])?.map(listener => listener?.(data));
      } catch (error) {
        // json parse error
      }
    }
  }, []);

  useEffect(() => {
    if (!crossTabEventsChannel) return;
    crossTabEventsChannel.onmessage = function (ev) {
      if (!ev.data.includes("SSE_EVENT_RECEIVED") || ev.data.includes("ping-pong")) {
        return;
      }
      const data = JSON.parse(ev.data);
      onMessage(data.data);
    };
  }, [crossTabEventsChannel]);

  useEffect(() => {
    logSSE("NotificationsServiceProvider", topicsSubscribers);
  }, [topicsSubscribers]);

  useEffect(() => {
    if (!eventSourceUrl || !token) return;
    window?.["sseControllerRef"]?.abort?.();
    startListening();
  }, [eventSourceUrl, token, isOnline]);

  function startListening() {
    if (!eventSourceUrl || !isOnline || !token) return;

    logSSEError("NotificationsService: EventSource starting connection ", token, eventSourceUrl);
    setConnectionStatus("loading");
    const sseConnectionRef = new AbortController();
    window["sseControllerRef"] = sseConnectionRef;
    fetchEventSource(eventSourceUrl, {
      signal: sseConnectionRef?.signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      openWhenHidden: true,
      method: "GET",
      onmessage: content => {
        onMessage(content);
        crossTabEventsChannel?.postMessage(
          JSON.stringify({
            type: "SSE_EVENT_RECEIVED",
            data: content,
            src: windowID.current,
          })
        );
      },
      onerror: onError,
      onopen: onOpen || _.noop,
      onclose() {
        setConnectionStatus("disconnected");
        logSSEWarning("Connection closed by the server " + new Date().toLocaleString());
      },
    });
  }

  function onOpen(res) {
    logSSE("Connection made ", res);
    if (res.ok && res.status === 200) {
      logSSE("Connection made ", res);
      setConnectionStatus("connected");
    } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
      if (res.status === 401 && onTokenExpired) {
        onTokenExpired();
      }
      logSSE("Client side error ", res);
      setConnectionStatus("disconnected");
      return Promise.reject(res);
    }
    return Promise.resolve(res);
  }

  function onError(err) {
    setConnectionStatus("disconnected");
    logSSEError("NotificationsService: error occurred ", err);
  }

  const subscribe: OrgSubscribeFn = (topic, listener) => {
    listener["ListenerID"] = Date.now();
    const unifiedTopic = unifyTopic(topic);
    if (!topicsSubscribers.current[unifiedTopic]) {
      topicsSubscribers.current[unifiedTopic] = [];
    }

    topicsSubscribers.current[unifiedTopic].push(listener);

    return () => {
      topicsSubscribers.current[unifiedTopic] = topicsSubscribers.current[unifiedTopic].filter(l => l !== listener);
    };
  };

  function unifyTopic(t: string) {
    return t;
  }

  return <NotificationsServiceContext.Provider value={{ subscribe }}>{children}</NotificationsServiceContext.Provider>;
};
