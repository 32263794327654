/* eslint-disable no-restricted-imports */
import { useCustomForm } from "@toolkit/core";
import React from "react";
import { FormProvider } from "react-hook-form";
import {
  HealthPackagesListFiltersFormSchema,
  healthPackagesListFiltersFormDefaultValues,
  IHealthPackagesListFiltersFormValues,
} from "./HealthPackagesListFiltersFormSchema";

import { Box, Button } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useStyles } from "./HealthPackagesListFiltersStyle";
import { VendorFilterForm } from "./Filters/VendorFilter";
import { PriceFilterForm } from "./Filters/PriceFilter";
import { SortingFilterForm } from "./Filters/SortingFilter";
import { GenderFilterForm } from "./Filters/GenderFilter";
import { NameFilterForm } from "./Filters/NameFilter";
import { AgeFilterForm } from "./Filters/AgeFilter";

type HealthPackagesListFiltersFormProps = {
  onSubmit?: (values: IHealthPackagesListFiltersFormValues) => void;
};

export type HealthPackagesListFiltersFormRef = {
  submit: () => void;
};

export const HealthPackagesListFiltersForm: React.FC<HealthPackagesListFiltersFormProps> = props => {
  const { t } = useTranslation("consumer");
  const form = useCustomForm<IHealthPackagesListFiltersFormValues>({
    schema: HealthPackagesListFiltersFormSchema,
    defaultValues: healthPackagesListFiltersFormDefaultValues,
    mode: "onChange",
  });
  const { setValue } = form;
  const onSubmit = <K extends keyof IHealthPackagesListFiltersFormValues>(fieldName: K, value: IHealthPackagesListFiltersFormValues[K]) => {
    setValue(
      fieldName as keyof IHealthPackagesListFiltersFormValues,
      value as IHealthPackagesListFiltersFormValues[keyof IHealthPackagesListFiltersFormValues]
    );
    props.onSubmit?.({
      ...form.getValues(),
      [fieldName]: value,
    });
  };
  const getValue = <K extends keyof IHealthPackagesListFiltersFormValues>(fieldName: K) => {
    return form.getValues()[fieldName];
  };
  const resetForm = () => {
    form.reset(healthPackagesListFiltersFormDefaultValues);
    props.onSubmit?.(healthPackagesListFiltersFormDefaultValues);
  };

  const { classes } = useStyles();
  return (
    <FormProvider {...form}>
      <form>
        <Box className={classes.container}>
          <Button className={classes.button} onClick={resetForm} variant='outlined'>
            {t("All")}
          </Button>
          <SortingFilterForm onSubmit={onSubmit} getValue={getValue} />
          <NameFilterForm onSubmit={onSubmit} getValue={getValue} />
          <VendorFilterForm onSubmit={onSubmit} getValue={getValue} />
          <GenderFilterForm onSubmit={onSubmit} getValue={getValue} />
          <AgeFilterForm onSubmit={onSubmit} getValue={getValue} />
          <PriceFilterForm onSubmit={onSubmit} getValue={getValue} />
        </Box>
      </form>
    </FormProvider>
  );
};
