import { IGoogleMapAddress, LocationPicker } from "@toolkit/maps";
import { Box } from "@toolkit/ui";
import { FC } from "react";
import { useAddressMapDisplayStyles } from "./AddressMapDisplay.styles";

export type AddressMapWithSearchProps = {
  coordinates?: IGoogleMapAddress["coordinates"];
};
export type AddressMapWithSearchRef = {
  submit: () => void;
};

export const AddressMapDisplay: FC<AddressMapWithSearchProps> = props => {
  const { coordinates } = props;
  const { classes } = useAddressMapDisplayStyles();

  return (
    <Box>
      <LocationPicker
        hasSearch={false}
        defaultZoom={17}
        classes={{
          containerMapStyle: classes.root,
        }}
        location={coordinates}
        googleMapProps={{
          options: {
            disableDefaultUI: false,
            fullscreenControl: true,
            streetViewControl: true,
            mapTypeControl: true,
            keyboardShortcuts: true,
          },
        }}
      />
    </Box>
  );
};
