import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const HealthPackageIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-box' width='20.172' height='20.172' viewBox='0 0 20.172 20.172' {...props}>
      <path
        id='Path_12990'
        data-name='Path 12990'
        d='M23.845,10.839H6.327A1.3,1.3,0,0,1,5,9.512V6.327A1.3,1.3,0,0,1,6.327,5h12.21a.8.8,0,0,1,0,1.593H6.593V9.247H23.58V6.593H22.093a.8.8,0,0,1,0-1.593h1.752a1.3,1.3,0,0,1,1.327,1.327V9.512A1.3,1.3,0,0,1,23.845,10.839Z'
        transform='translate(-5 -5)'
        fill={fill}
      />
      <path
        id='Path_12991'
        data-name='Path 12991'
        d='M22.66,32.069H9.389A2.363,2.363,0,0,1,7,29.68V20.5a.8.8,0,0,1,1.593,0V29.68a.77.77,0,0,0,.8.8H22.66a.77.77,0,0,0,.8-.8V27.5a.8.8,0,0,1,1.593,0V29.68A2.363,2.363,0,0,1,22.66,32.069Z'
        transform='translate(-5.938 -11.897)'
        fill={fill}
      />
      <path
        id='Line_1591'
        data-name='Line 1591'
        d='M-.7,11.453a.8.8,0,0,1-.8-.8V-.7a.8.8,0,0,1,.8-.8.8.8,0,0,1,.8.8v11.36A.8.8,0,0,1-.7,11.453Z'
        transform='translate(19.018 5.747)'
        fill={fill}
      />
      <path
        id='Line_1592'
        data-name='Line 1592'
        d='M4.074.093H-.7a.8.8,0,0,1-.8-.8.8.8,0,0,1,.8-.8H4.074a.8.8,0,0,1,.8.8A.8.8,0,0,1,4.074.093Z'
        transform='translate(8.401 9.463)'
        fill={fill}
      />
    </SvgIcon>
  );
};
