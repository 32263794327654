import { useCommonStyles } from "@/domains/appointment/containers/CommonContainer.styles";
import { Box } from "@toolkit/ui";
import { FC } from "react";
import { useProductContainerHook } from "./useProductContainerHook";
import { ProductImagesSlider } from "../../components/ProductImagesSlider/ProductImagesSlider";
import { ProductDetailsCard } from "../../components";
import { ProductDetailsTabs } from "../../components/ProductDetailsTabs/ProductDetailsTabs";
import { compact, isEmpty } from "lodash";
import { RelatedProductsSection } from "../../components/RelatedProductsSection/RelatedProductsSection";
import { useAuth } from "react-oidc-context";

export const ProductContainer: FC = () => {
  const { classes } = useCommonStyles();
  const { productDetails, productImages, isLoading, haveHowToUseTab, relatedProducts } = useProductContainerHook();
  const { isAuthenticated } = useAuth();

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <ProductImagesSlider isLoading={isLoading} images={productImages} />
        <ProductDetailsCard key={`product-${productDetails?.id}`} isLoading={isLoading} product={productDetails} />
        <ProductDetailsTabs
          isLoading={isLoading}
          product={productDetails}
          visibleTabsKeys={compact(["description", haveHowToUseTab && "howToUse", isAuthenticated && "reviews"])}
        />
        {!isEmpty(relatedProducts) && <RelatedProductsSection products={relatedProducts} />}
      </Box>
    </Box>
  );
};
