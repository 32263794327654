import { AffectedType } from "@/schema/types";
import { ReasonsBaseModal, ReasonsForm, ReasonsFormRef } from "@/shared/components";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast, useTheme } from "@toolkit/ui";
import React from "react";
import { useConsumerAppointmentCancelMutation } from "../../gql";

type AppointmentCancelationModalProps = {
  id: string;
};

export const AppointmentCancelationModal: React.FC<AppointmentCancelationModalProps> = ({ id }) => {
  const { t } = useTranslation("consumer");
  const theme = useTheme();
  const ref = React.useRef<ReasonsFormRef>(null);
  const { open, handleClose, handleOpen } = useOpenState();
  const handleConfirm = () => {
    ref.current?.submit();
  };

  const { failed, succeeded } = useAddToast();

  const [cancelAppointment, { loading: isSubmitting }] = useConsumerAppointmentCancelMutation({
    onCompleted: dataCreate => {
      const errors = dataCreate?.consumerAppointmentCancel?.appointmentErrors;
      if (!errors?.length) {
        succeeded(t("Appointment has been canceled successfully"));
        handleClose();
      } else {
        failed(formatMessageErrors(errors));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update: (cache, { data }) => {
      const normalizedId = cache.identify({ id: data?.consumerAppointmentCancel?.appointment?.id, __typename: "Appointment" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const onSubmit = (reason: string) => {
    cancelAppointment({
      variables: {
        consumerAppointmentCancelId: id,
        input: {
          cancellationReason: reason,
        },
      },
    });
  };

  return (
    <>
      <Button
        variant='outlined'
        sx={{
          borderRadius: "20px",
          height: "40px",
          padding: "8px 16px",
          textTransform: "none",
          display: "flex",
          borderColor: theme.palette.error.main,
          color: theme.palette.error.main,
        }}
        onClick={handleOpen}
      >
        {t("Cancel")}
      </Button>
      <ReasonsBaseModal
        isConfirmDisabled={isSubmitting}
        handleConfirm={handleConfirm}
        open={open}
        onClose={handleClose}
        title={t("Cancellation Reasons")}
      >
        <ReasonsForm affectedType={AffectedType.Appointment} type='cancellation' ref={ref} onSubmit={onSubmit} skip={!open} />
      </ReasonsBaseModal>
    </>
  );
};
