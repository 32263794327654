import { Box, Button, CallIcon, IconButton, MuiArrowBackIcon, Typography } from "@toolkit/ui";
import { CallInstance } from "./types";
import { useTranslation } from "@toolkit/i18n";
import { useCallStyles } from "./Call.styles";
import { useNetwork } from "@toolkit/core";
import { useCallHook } from "./useCallHook";
import { useQueryRetry } from "./useQueryRetry";

type TimeoutScreenProps = {
  call: CallInstance;
  onClose?: () => void;
};

export const TimeoutScreen = ({ call, onClose }: TimeoutScreenProps) => {
  const { t } = useTranslation();
  const { classes, theme } = useCallStyles();
  const { handleJoin, isJoining } = useCallHook({ call });
  const { online: isOnline } = useNetwork();
  const { isRetrying, attemptsRef } = useQueryRetry({ query: handleJoin, enabled: isOnline, timeout: 3000, attempts: 3 });
  const isLoading = isJoining || isRetrying;
  const attempts = attemptsRef.current;

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        {onClose && (
          <Box className={classes.header}>
            <IconButton color='inherit' onClick={onClose}>
              <MuiArrowBackIcon />
            </IconButton>
          </Box>
        )}

        <Box className={classes.body}>
          <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
            <Box className={classes.icon}>
              <CallIcon color='inherit' sx={{ width: 40, height: 40 }} />
            </Box>
            <Typography textAlign='center' fontSize={theme.mixins.fonts.fontSize.md}>
              {t("Internet Connection has been lost")}
            </Typography>

            <Typography textAlign='center' variant='body1' color={theme.palette.gray[400]}>
              {!isOnline && t("Please check your internet connection and try again.")}
              {isLoading && isOnline && t("Trying to reconnect...")}
            </Typography>

            <Box>
              <Button disabled={!call.callId || isLoading} onClick={handleJoin}>
                {!isLoading && t("Retry")}
                {isLoading && (!isRetrying || attempts === 0 ? t("Connecting ...") : t("Connecting ({{attempts}})", { attempts }))}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
