import { useGetDigitalTwinHealthParameterByCode } from "@/domains/health-parameters/hooks/useGetDigitalTwinHealthParameterByCode";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Card, CircularProgress, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  DigitalTwinSleepCalculatorResult,
  DigitalTwinSleepCalculatorResultProps,
} from "../../components/DigitalTwinSleepCalculatorResult/DigitalTwinSleepCalculatorResult";
import { sleepCalculationRoutes } from "../../routes";
import { useDigitalTwinSleepCalculatorContainerStyle } from "./useDigitalTwinSleepCalculatorContainerStyle";
import { HealthParameterCodes } from "@/domains/health-parameters/constants/healthParameterCodes";
import { ExpandMoreIcon } from "@/shared/icons";
import queryString from "query-string";

export const DigitalTwinSleepCalculatorResultContainer: FC = () => {
  const { classes: containerCx } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();

  const { classes, cx } = useDigitalTwinSleepCalculatorContainerStyle();
  const { value: savedSleepCycles, loading } = useGetDigitalTwinHealthParameterByCode(HealthParameterCodes.sleepCycles);
  const result = queryString.parse(location.search) as Record<string, string>;

  const resultsProps: DigitalTwinSleepCalculatorResultProps = {
    wakeUpHour: result?.wakeUpTime || "5:00 am",
    sleepHour: result?.sleepTime || "9:00 pm",
    sleepCycleCounts: result?.sleepCycles || savedSleepCycles || 0,
    isBasedOnSleepTime: result?.isBasedOnSleepTime === "true" || false,
  };
  const handleOpenFormPressed = () => {
    navigate(sleepCalculationRoutes.form.getRouteWithParams());
  };
  return (
    <Box className={containerCx.pageContainer}>
      <Box className={cx(containerCx.pageContent, classes.pageContent)}>
        <Card className={classes.headerCard} onClick={handleOpenFormPressed}>
          <Box alignItems='center' display={"flex"} justifyContent='space-between' p={2}>
            <Box>
              <Typography variant='medium-lg'>{t("Set your sleep goal!")}</Typography>
              <Typography variant='regular-md'>{t("Fill in the details to calculate your sleep or wake up time.")}</Typography>
            </Box>
            <ExpandMoreIcon direction='right' color={"primary"} height={15} width={20} />
          </Box>
        </Card>

        {loading ? <CircularProgress size={20} /> : <DigitalTwinSleepCalculatorResult {...resultsProps} />}
      </Box>
    </Box>
  );
};
