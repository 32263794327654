import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(() => ({
  box: { display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px" },
  cards: {
    padding: "15px",
    marginLeft: "10px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },
  gaps: { marginRight: "10px" },
  content: {
    margin: "10px",
    borderRadius: "10px",
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));
