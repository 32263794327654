import { ChangeEvent, FC, FormEvent, useCallback, useState } from "react";
import { CallError } from "../Call/CallError";
import { useCallInvitationToken } from "./useCallInvitationToken";
import { Box, Button, TextField, Typography, useAddToast } from "@toolkit/ui";
import { CallState } from "../Call/types";
import { useTranslation } from "@toolkit/i18n";
import { useGuestCallViewStyles } from "./GuestCallView.styles";
import { CallContent } from "../Call/CallContent";

type GuestCallProps = {
  token: string;
};

export const GuestCallView: FC<GuestCallProps> = ({ token }) => {
  const { t } = useTranslation();
  const { failed } = useAddToast();
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const { error, loading, retry: handleRetry, data, getCallInvitationTokenInfo } = useCallInvitationToken({ token });
  const handleChangeName = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setName(e.target.value || ""), []);
  const { classes } = useGuestCallViewStyles();
  // const call = useCall({});
  const call = {} as any;

  const handleJoin = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setSubmitted(true);
      const result = await getCallInvitationTokenInfo({ token });
      if (!result) {
        failed(t("Call is not started yet. Please try again later."));
      }
    },
    [token, getCallInvitationTokenInfo, failed, t]
  );

  const handleReset = useCallback(() => {
    setSubmitted(false);
    handleRetry();
  }, [handleRetry]);

  if (error || loading || !data) {
    return <CallError isLoading={loading} hasError={!!error} onClick={handleRetry} />;
  }

  const isCallStarted = !data && call && call.state?.callState !== CallState.NoCall;
  if (!isCallStarted || !name || !submitted) {
    return (
      <form className={classes.root} onSubmit={handleJoin}>
        <Box className={classes.content} sx={{ width: 320 }}>
          <Box>
            <Typography className={classes.title}>{t("Join Meeting")}</Typography>
            <Typography>{data.topic}</Typography>
          </Box>

          <TextField required fullWidth value={name} label={t("Enter your name")} onChange={handleChangeName} />
          <Button type='submit' fullWidth>
            {t("Join")}
          </Button>
        </Box>
      </form>
    );
  }

  return <CallContent call={call} onClose={handleReset} />;
};
