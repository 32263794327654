import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountSetDefaultAddressMutationVariables = Types.Exact<{
  accountSetDefaultAddressId: Types.Scalars['ID']['input'];
  type: Types.AddressTypeEnum;
}>;


export type AccountSetDefaultAddressMutation = { __typename?: 'Mutation', accountSetDefaultAddress?: { __typename?: 'AccountSetDefaultAddress', accountErrors: Array<{ __typename?: 'AccountError', code: Types.AccountErrorCode }> } | null };


export const AccountSetDefaultAddressDocument = gql`
    mutation AccountSetDefaultAddress($accountSetDefaultAddressId: ID!, $type: AddressTypeEnum!) {
  accountSetDefaultAddress(id: $accountSetDefaultAddressId, type: $type) {
    accountErrors {
      code
    }
  }
}
    `;
export type AccountSetDefaultAddressMutationFn = Apollo.MutationFunction<AccountSetDefaultAddressMutation, AccountSetDefaultAddressMutationVariables>;

/**
 * __useAccountSetDefaultAddressMutation__
 *
 * To run a mutation, you first call `useAccountSetDefaultAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountSetDefaultAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountSetDefaultAddressMutation, { data, loading, error }] = useAccountSetDefaultAddressMutation({
 *   variables: {
 *      accountSetDefaultAddressId: // value for 'accountSetDefaultAddressId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAccountSetDefaultAddressMutation(baseOptions?: Apollo.MutationHookOptions<AccountSetDefaultAddressMutation, AccountSetDefaultAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountSetDefaultAddressMutation, AccountSetDefaultAddressMutationVariables>(AccountSetDefaultAddressDocument, options);
      }
export type AccountSetDefaultAddressMutationHookResult = ReturnType<typeof useAccountSetDefaultAddressMutation>;
export type AccountSetDefaultAddressMutationResult = Apollo.MutationResult<AccountSetDefaultAddressMutation>;
export type AccountSetDefaultAddressMutationOptions = Apollo.BaseMutationOptions<AccountSetDefaultAddressMutation, AccountSetDefaultAddressMutationVariables>;