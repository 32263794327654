import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGuidedCareJourneyItemListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter: Types.GuidedCareJourneyItemFilter;
}>;


export type GetGuidedCareJourneyItemListQuery = { __typename?: 'Query', guidedCareJourneyItems?: { __typename?: 'GuidedCareJourneyItemCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'GuidedCareJourneyItemCountableEdge', node: { __typename?: 'GuidedCareJourneyItem', id: string, dueDate?: any | null, guidedCareJourneyItemType?: Types.GuidedCareActivityType | null, guidedCareJourneyItemStatus?: Types.GuidedCareJourneyItemStatus | null, appointment?: { __typename?: 'Appointment', id: string, type?: Types.AppointmentType | null, status?: Types.AppointmentStatus | null, confirmedStartTime?: any | null, suggestedTimeSlots?: Array<string | null> | null, requestedTimeSlots?: Array<string | null> | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null } | null, medicalForm?: { __typename?: 'MedicalForm', id: string, name?: string | null, description?: string | null, active?: boolean | null } | null, teamMember?: { __typename?: 'ProviderGuidedCareProgramTeamMember', user?: { __typename?: 'User', id: string, fullName?: string | null } | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', fullName?: string | null } | null } | null } | null, guidedCareJourneyItemServiceDetails?: Array<{ __typename?: 'ServiceDetails', standard?: string | null, serviceName?: string | null, serviceCode?: string | null } | null> | null, guidedCareJourney?: { __typename?: 'GuidedCareJourney', createdDate?: any | null, endDate?: any | null } | null } }> } | null };


export const GetGuidedCareJourneyItemListDocument = gql`
    query getGuidedCareJourneyItemList($first: Int, $last: Int, $after: String, $before: String, $filter: GuidedCareJourneyItemFilter!) {
  guidedCareJourneyItems(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        dueDate
        appointment {
          id
          type
          status
          doctor {
            id
            user {
              id
              fullName
            }
          }
          confirmedStartTime
          suggestedTimeSlots
          requestedTimeSlots
        }
        medicalForm {
          id
          name
          description
          active
        }
        guidedCareJourneyItemType
        guidedCareJourneyItemStatus
        teamMember {
          user {
            id
            fullName
          }
          doctor {
            id
            user {
              fullName
            }
          }
        }
        guidedCareJourneyItemServiceDetails {
          standard
          serviceName
          serviceCode
        }
        guidedCareJourney {
          createdDate
          endDate
        }
      }
    }
  }
}
    `;

/**
 * __useGetGuidedCareJourneyItemListQuery__
 *
 * To run a query within a React component, call `useGetGuidedCareJourneyItemListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuidedCareJourneyItemListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuidedCareJourneyItemListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetGuidedCareJourneyItemListQuery(baseOptions: Apollo.QueryHookOptions<GetGuidedCareJourneyItemListQuery, GetGuidedCareJourneyItemListQueryVariables> & ({ variables: GetGuidedCareJourneyItemListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGuidedCareJourneyItemListQuery, GetGuidedCareJourneyItemListQueryVariables>(GetGuidedCareJourneyItemListDocument, options);
      }
export function useGetGuidedCareJourneyItemListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGuidedCareJourneyItemListQuery, GetGuidedCareJourneyItemListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGuidedCareJourneyItemListQuery, GetGuidedCareJourneyItemListQueryVariables>(GetGuidedCareJourneyItemListDocument, options);
        }
export function useGetGuidedCareJourneyItemListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGuidedCareJourneyItemListQuery, GetGuidedCareJourneyItemListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGuidedCareJourneyItemListQuery, GetGuidedCareJourneyItemListQueryVariables>(GetGuidedCareJourneyItemListDocument, options);
        }
export type GetGuidedCareJourneyItemListQueryHookResult = ReturnType<typeof useGetGuidedCareJourneyItemListQuery>;
export type GetGuidedCareJourneyItemListLazyQueryHookResult = ReturnType<typeof useGetGuidedCareJourneyItemListLazyQuery>;
export type GetGuidedCareJourneyItemListSuspenseQueryHookResult = ReturnType<typeof useGetGuidedCareJourneyItemListSuspenseQuery>;
export type GetGuidedCareJourneyItemListQueryResult = Apollo.QueryResult<GetGuidedCareJourneyItemListQuery, GetGuidedCareJourneyItemListQueryVariables>;