import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceHealthPackageCategoriesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.MarketplaceCategoryFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.MarketplaceDefaultSortingInput>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type MarketplaceHealthPackageCategoriesQuery = { __typename?: 'Query', marketplaceHealthPackageCategories?: { __typename?: 'MarketplaceHealthPackageCategoryConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null, edges: Array<{ __typename?: 'MarketplaceHealthPackageCategoryEdge', node: { __typename?: 'MarketplaceHealthPackageCategory', id: string, name?: string | null, nameAr?: string | null, image?: string | null } }> } | null };


export const MarketplaceHealthPackageCategoriesDocument = gql`
    query MarketplaceHealthPackageCategories($first: Int, $filter: MarketplaceCategoryFilterInput, $after: String, $sortBy: MarketplaceDefaultSortingInput, $before: String, $last: Int) {
  marketplaceHealthPackageCategories(
    first: $first
    filter: $filter
    after: $after
    sortBy: $sortBy
    before: $before
    last: $last
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        name
        nameAr
        image
      }
    }
  }
}
    `;

/**
 * __useMarketplaceHealthPackageCategoriesQuery__
 *
 * To run a query within a React component, call `useMarketplaceHealthPackageCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceHealthPackageCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceHealthPackageCategoriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useMarketplaceHealthPackageCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceHealthPackageCategoriesQuery, MarketplaceHealthPackageCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceHealthPackageCategoriesQuery, MarketplaceHealthPackageCategoriesQueryVariables>(MarketplaceHealthPackageCategoriesDocument, options);
      }
export function useMarketplaceHealthPackageCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceHealthPackageCategoriesQuery, MarketplaceHealthPackageCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceHealthPackageCategoriesQuery, MarketplaceHealthPackageCategoriesQueryVariables>(MarketplaceHealthPackageCategoriesDocument, options);
        }
export function useMarketplaceHealthPackageCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplaceHealthPackageCategoriesQuery, MarketplaceHealthPackageCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceHealthPackageCategoriesQuery, MarketplaceHealthPackageCategoriesQueryVariables>(MarketplaceHealthPackageCategoriesDocument, options);
        }
export type MarketplaceHealthPackageCategoriesQueryHookResult = ReturnType<typeof useMarketplaceHealthPackageCategoriesQuery>;
export type MarketplaceHealthPackageCategoriesLazyQueryHookResult = ReturnType<typeof useMarketplaceHealthPackageCategoriesLazyQuery>;
export type MarketplaceHealthPackageCategoriesSuspenseQueryHookResult = ReturnType<typeof useMarketplaceHealthPackageCategoriesSuspenseQuery>;
export type MarketplaceHealthPackageCategoriesQueryResult = Apollo.QueryResult<MarketplaceHealthPackageCategoriesQuery, MarketplaceHealthPackageCategoriesQueryVariables>;