import { CallParticipant } from "./types";
import { ZoomViewScreen } from "./ZoomViewScreen";
import { ZoomViewVideo } from "./ZoomViewVideo";

export type ZoomViewProps = {
  participant: CallParticipant;
  // eslint-disable-next-line react/boolean-prop-naming
  sharing?: boolean;
};

export const ZoomView = ({ participant, sharing }: ZoomViewProps) => {
  if (sharing) {
    return <ZoomViewScreen participant={participant} />;
  }

  return <ZoomViewVideo participant={participant} />;
};
