import { makeStyles } from "@toolkit/ui";

export const useWishListStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  sectionHeader: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "5px",
  },
  sectionCountContainer: {
    minWidth: "24px",
    height: "24px",
    borderRadius: "24px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingInline: "5px",
    fontSize: theme.mixins.fonts.fontSize.xs,
    textAlign: "center",
    lineHeight: 2,
  },
  sectionTitle: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.text.primary,
  },
  startShopingButton: {
    height: "48px",
    paddingInline: "40px",
    borderRadius: "24px",
    width: "fit-content",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  emptyPageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flex: 1,
    justifySelf: "center",
    alignSelf: "center",
    padding: "10px",
    paddingBottom: "100px",
  },
  sectionContent: {},
}));
