import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const RatingStarIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='22.2' height='21.179' viewBox='0 0 22.2 21.179' {...props}>
      <path
        id='icons8-star'
        d='M13.309,2.339l2.377,7.039,7.428.086a.668.668,0,0,1,.391,1.2L17.546,15.1l2.214,7.092a.668.668,0,0,1-1.024.743l-6.06-4.3-6.06,4.3a.668.668,0,0,1-1.024-.743L7.806,15.1l-5.96-4.436a.668.668,0,0,1,.391-1.2l7.428-.086,2.377-7.039A.668.668,0,0,1,13.309,2.339Z'
        transform='translate(-1.576 -1.884)'
        fill={props.fill || theme.palette.yellow.main}
      />
    </SvgIcon>
  );
};
