import { Actor, AffectedType, Type } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { ChangeEvent, useState } from "react";
import { useReasonsQuery } from "./gql/queries";

interface ReasonsFormState {
  reason: string;
  other: string;
  isOther: boolean;
}

interface UseReasonsFormProps {
  isCancellation: boolean;
  skip?: boolean;
  affectedType?: AffectedType;
  onSubmit?: (reason: string) => void;
}

interface UseReasonsFormReturn {
  state: ReasonsFormState;
  reasons: { id?: string; display?: string; code?: string }[];
  disabled: boolean;
  error: string | null;
  handleChangeOtherReason: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeReason: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useReasonsForm = ({ isCancellation, onSubmit, affectedType, skip }: UseReasonsFormProps): UseReasonsFormReturn => {
  const [state, setState] = useState<ReasonsFormState>({ reason: "", other: "", isOther: false });
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation("consumer");

  const { data, loading: reasonsLoading } = useReasonsQuery({
    variables: {
      isCancellation: isCancellation,
      searchParameters: [
        {
          code: "Actor",
          type: Type.String,
          valueString: Actor.Patient,
        },
        {
          code: "AffectedType",
          type: Type.String,
          valueString: affectedType,
        },
      ],
    },
    skip,
    fetchPolicy: "cache-first",
  });

  const _reasons = isCancellation ? data?.cancellationReasons : data?.rejectionReasons;
  const reasons = _reasons?.edges
    ?.map(edge => edge?.node)
    ?.filter(Boolean)
    ?.map(reason => ({
      id: reason?.id ?? undefined,
      display: reason?.display ?? undefined,
      code: reason?.code ?? undefined,
    })) ?? [{ id: "1", display: "Other", code: "Other" }];

  const disabled = reasonsLoading || (state.isOther ? !state.other : !state.reason);

  const handleChangeReason = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value === "Other") {
      setState({ ...state, isOther: true, reason: event.target.value });
    } else {
      setState({ ...state, isOther: false, reason: event.target.value, other: "" });
    }
  };

  const handleChangeOtherReason = (event: ChangeEvent<HTMLInputElement>): void => {
    setState({ ...state, other: event.target.value, isOther: true });
  };
  const handleSubmit = (): void => {
    const reason = state?.isOther ? state?.other : state.reason;
    if (!reason || reasonsLoading) {
      setError(t("Please add your reason"));
    } else {
      setError("");
      onSubmit?.(reason);
    }
  };

  return {
    state,
    reasons,
    disabled,
    handleChangeOtherReason,
    handleChangeReason,
    handleSubmit,
    error,
  };
};
