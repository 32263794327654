import { makeStyles } from "@toolkit/ui";

export const useCartHealthPackageCardStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    gap: "10px",
    width: "100%",
  },

  removeButton: {
    margin: "0px",
    height: "35px",
    paddingInline: "11px",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.primary.main,
    "& svg": {
      rotate: "45deg",
      width: "16px",
      height: "16px",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "& svg": {
        fill: theme.palette.common.white,
      },
      "& path": {
        fill: theme.palette.common.white,
      },
    },
  },
  saveLaterButton: {
    margin: "0px",
    height: "35px",
    paddingInline: "17px",
    borderRadius: "24px",
    "&:hover": {
      " & path": {
        fill: theme.palette.common.white,
      },
    },
  },
  itemAvatarContainer: {
    minWidth: "120px",
    flex: 1.5,
  },
  infoContainer: {
    maxWidth: "100%",
    flex: 4,
  },
}));
