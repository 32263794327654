import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../../../address/gql/fragments/__generated__/address';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/queries/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConsumerMedicationsOrdersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.OrderFilterInput>;
  sortBy?: Types.InputMaybe<Types.OrderSortingInput>;
}>;


export type ConsumerMedicationsOrdersQuery = { __typename?: 'Query', me?: { __typename?: 'User', orders?: { __typename?: 'OrderCountableConnection', edges: Array<{ __typename?: 'OrderCountableEdge', cursor?: string | null, node: { __typename?: 'Order', id: string, status: Types.OrderStatus, deliveryDate?: any | null, pickUpOtp?: string | null, type: Types.OrderType, totalNetAmount?: number | null, totalPatientShareAmount?: number | null, totalPaymentAmount?: number | null, deliveryPrice?: number | null, address: { __typename?: 'PrescriptionAddress', name?: string | null, nameAr?: string | null }, children?: Array<{ __typename?: 'Order', pickUpOtp?: string | null, branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, address?: { __typename?: 'Address', id: string, streetAddress1: string, streetAddress2: string, phone?: string | null, name?: string | null, nameAr?: string | null, buildingNumber?: string | null, unitNumber?: string | null, isDefaultShippingAddress?: boolean | null, isDefaultBillingAddress?: boolean | null, area: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lat?: number | null, lng?: number | null } | null } | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, logo?: string | null } } | null } | null> | null, prescription: { __typename?: 'Prescription', id: string, referenceNumber: string, dispenseStatus: Types.PrescriptionDispenseStatus } } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null } | null };


export const ConsumerMedicationsOrdersDocument = gql`
    query ConsumerMedicationsOrders($first: Int, $after: String, $filter: OrderFilterInput, $sortBy: OrderSortingInput) {
  me {
    orders(first: $first, after: $after, filter: $filter, sortBy: $sortBy) {
      edges {
        cursor
        node {
          id
          status
          deliveryDate
          pickUpOtp
          type
          address {
            name
            nameAr
          }
          children {
            branch {
              id
              name
              nameAr
              address {
                ...AddressFragment
              }
              vendor {
                id
                name
                nameAr
                logo
              }
            }
            pickUpOtp
          }
          prescription {
            id
            referenceNumber
            dispenseStatus
          }
          totalNetAmount
          totalPatientShareAmount
          totalPaymentAmount
          deliveryPrice
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
}
    ${AddressFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useConsumerMedicationsOrdersQuery__
 *
 * To run a query within a React component, call `useConsumerMedicationsOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumerMedicationsOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumerMedicationsOrdersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useConsumerMedicationsOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ConsumerMedicationsOrdersQuery, ConsumerMedicationsOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsumerMedicationsOrdersQuery, ConsumerMedicationsOrdersQueryVariables>(ConsumerMedicationsOrdersDocument, options);
      }
export function useConsumerMedicationsOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumerMedicationsOrdersQuery, ConsumerMedicationsOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsumerMedicationsOrdersQuery, ConsumerMedicationsOrdersQueryVariables>(ConsumerMedicationsOrdersDocument, options);
        }
export function useConsumerMedicationsOrdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ConsumerMedicationsOrdersQuery, ConsumerMedicationsOrdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConsumerMedicationsOrdersQuery, ConsumerMedicationsOrdersQueryVariables>(ConsumerMedicationsOrdersDocument, options);
        }
export type ConsumerMedicationsOrdersQueryHookResult = ReturnType<typeof useConsumerMedicationsOrdersQuery>;
export type ConsumerMedicationsOrdersLazyQueryHookResult = ReturnType<typeof useConsumerMedicationsOrdersLazyQuery>;
export type ConsumerMedicationsOrdersSuspenseQueryHookResult = ReturnType<typeof useConsumerMedicationsOrdersSuspenseQuery>;
export type ConsumerMedicationsOrdersQueryResult = Apollo.QueryResult<ConsumerMedicationsOrdersQuery, ConsumerMedicationsOrdersQueryVariables>;