import { NotificationType } from "@/schema/types";
import { RescheduledNotificationIcon } from "@/shared/icons";
import { useGetNotificationMedicalForm } from "@health/sse";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useGuidedCareJourneyItemQuery } from "../../gql/queries";
import { getNotificationTextStyle } from "../../utils";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { NotificationDefaultCard } from "../NotificationDefaultCard/NotificationDefaultCard";

type MedicalFormProps = {
  id: string;
  creationDate: string;
  isSeen: boolean;
};

export const MedicalForm: FC<MedicalFormProps> = ({ id, creationDate, isSeen }) => {
  const { t } = useTranslation("consumer");
  const theme = useTheme();

  const { data, loading, error, refetch } = useGuidedCareJourneyItemQuery({
    variables: {
      guidedCareJourneyItemId: id,
    },
  });

  const item = data?.guidedCareJourneyItem;

  useGetNotificationMedicalForm(({ data }) => {
    if (data?.id === id) {
      refetch();
    }
  });

  const { color } = getNotificationTextStyle(isSeen, theme);

  if (!item?.id || error) {
    return (
      <NotificationDefaultCard type={NotificationType.HealthProgramAppointmentStatusChanged} creationDate={creationDate} isSeen={isSeen} />
    );
  }

  return (
    <NotificationCard
      title={t("Medical Form")}
      date={creationDate}
      icon={<RescheduledNotificationIcon />}
      isLoading={loading}
      isSeen={isSeen}
    >
      <Box>
        <Typography sx={{ color, alignSelf: "flex-start" }}>
          {pickLocalizedValue(item?.medicalForm?.name, item?.medicalForm?.nameAr || item?.medicalForm?.name)}
        </Typography>
        {item?.note && <Typography sx={{ color }}>{item?.note}</Typography>}
      </Box>
    </NotificationCard>
  );
};
