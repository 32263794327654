import { useTranslation } from "@toolkit/i18n";
import { Button, makeStyles } from "@toolkit/ui";
import { PathValue, useFormContext } from "react-hook-form";
import { IGoogleMapAddressUpsertFormValues } from "./AddressUpsertFormSchema";

type AddressFormSeggetionShipProps<K extends keyof IGoogleMapAddressUpsertFormValues> = {
  formKey: K;
  value: PathValue<IGoogleMapAddressUpsertFormValues, K>;
  label: string;
  checkIfValueEmpty?: (value: PathValue<IGoogleMapAddressUpsertFormValues, K>) => boolean;
};

const useStyles = makeStyles()(theme => ({
  ship: {
    backgroundColor: theme.palette.primary[50],
    padding: 0,
    color: theme.palette.primary.main,
    height: "36px",
    borderRadius: "18px",
    paddingInline: "20px",
    display: "inline-flex",
  },
}));
export const AddressFormSeggetionShip = <K extends keyof IGoogleMapAddressUpsertFormValues>({
  formKey: key,
  value,
  label,
  checkIfValueEmpty,
}: AddressFormSeggetionShipProps<K>) => {
  const { t } = useTranslation("consumer");
  const { classes } = useStyles();
  const { setValue, setFocus, watch } = useFormContext<IGoogleMapAddressUpsertFormValues>();
  const watchedValue = watch(key);
  const hasValue = checkIfValueEmpty ? !checkIfValueEmpty?.(watchedValue) : watchedValue;
  const onClick = () => {
    if (setValue) {
      setValue(key, value, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
      setFocus(key);
    }
  };

  return hasValue ? undefined : (
    <Button className={classes.ship} onClick={onClick}>
      {t(label)}
    </Button>
  );
};
