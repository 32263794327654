import { paymentCallBackPagesRoutes } from "@/pages/PaymentCallback/route";
import { Box } from "@toolkit/ui";
import { FC, IframeHTMLAttributes } from "react";

export type CustomWebViewProps = {
  resultUrl: string;
  integrity: string;
  checkoutPaymentFormUrl: string | null;
  handleOnLoadStart: IframeHTMLAttributes<unknown>["onLoad"];
};

export const CustomWebView: FC<CustomWebViewProps> = props => {
  const { resultUrl, checkoutPaymentFormUrl, integrity, handleOnLoadStart } = props;
  console.log("checkoutPaymentFormUrl", checkoutPaymentFormUrl, resultUrl);
  return (
    <Box sx={{ flex: 1, width: "100%", height: "100%" }}>
      {checkoutPaymentFormUrl && (
        <Box
          component={"iframe"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            border: "none",
            width: "100%",
          }}
          onLoad={handleOnLoadStart}
          src={
            location.origin +
            paymentCallBackPagesRoutes.root.getRouteWithParams({
              resultUrl,
              checkoutPaymentFormUrl,
              integrity,
            })
          }
        />
      )}
    </Box>
  );
};
