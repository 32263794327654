import React, { PropsWithChildren } from "react";
import { Box, Button, Typography, BoxProps } from "@toolkit/ui";
import { useCommonSectionsStyle } from "./SectionStyle"; // Adjust the path as needed
import { useTranslation } from "@toolkit/i18n";

interface SectionProps extends BoxProps {
  title?: string;
  onSeeAll?: () => void;
  footer?: React.ReactNode;
  classes?: Partial<ReturnType<typeof useCommonSectionsStyle>["classes"]>;
  hideHeader?: boolean;
}

export const Section: React.FC<PropsWithChildren<SectionProps>> = props => {
  const { title, onSeeAll, children, footer, hideHeader = false, classes: _classes = {}, ...rest } = props;
  const { classes, cx } = useCommonSectionsStyle();
  const { t } = useTranslation("consumer");

  return (
    <Box className={cx(classes.root, _classes.root)} {...rest}>
      <Box className={cx(classes.container, _classes.container)}>
        {/* Header */}
        {!hideHeader && (
          <Box className={cx(classes.titleContainer, _classes.titleContainer)}>
            <Typography className={cx(classes.title, _classes.title)}>{title}</Typography>
            {onSeeAll && (
              <Button onClick={onSeeAll} className={cx(classes.titleSeeAll, _classes.titleSeeAll)} variant='text'>
                {t("See All")}
              </Button>
            )}
          </Box>
        )}

        {/* Content */}
        {children}

        {/* Footer */}
        {footer && <Box className={cx(classes.footerContainer, _classes.footerContainer)}>{footer}</Box>}
      </Box>
    </Box>
  );
};
