import { makeStyles } from "@toolkit/ui";

export const useSeeAllButtonStyles = makeStyles()(theme => ({
  actionsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  seeAllButton__container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    "& .see-all__title": {
      color: theme.palette.primary.main,
    },
  },
  seeAllButton: {
    background: `linear-gradient(212deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    "& svg": {
      width: "16px",
      height: "16px",
    },
  },
}));
