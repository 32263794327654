import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import React from "react";

export const FairModeIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='icons8-disappointed'
      width='32.488'
      height='32.488'
      viewBox='0 0 32.488 32.488'
      {...props}
    >
      <defs>
        <linearGradient id='linear-gradient' x1='-1.449' y1='7.799' x2='-0.747' y2='7.097' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#fede00' />
          <stop offset='0.519' stopColor='#fecc00' />
          <stop offset='1' stopColor='#ffb700' />
        </linearGradient>
      </defs>
      <path
        id='Path_28356'
        data-name='Path 28356'
        d='M20.244,36.488A16.244,16.244,0,1,0,4,20.244,16.292,16.292,0,0,0,20.244,36.488Z'
        transform='translate(-4 -4)'
        fill='url(#linear-gradient)'
      />
      <ellipse id='Ellipse_3503' data-name='Ellipse 3503' cx='3' cy='3.5' rx='3' ry='3.5' transform='translate(6.684 11)' fill='#fff' />
      <ellipse id='Ellipse_3504' data-name='Ellipse 3504' cx='3' cy='3.5' rx='3' ry='3.5' transform='translate(19.684 11)' fill='#fff' />
      <ellipse id='Ellipse_3505' data-name='Ellipse 3505' cx='2' cy='1.5' rx='2' ry='1.5' transform='translate(7.684 13)' fill='#343434' />
      <ellipse id='Ellipse_3506' data-name='Ellipse 3506' cx='2' cy='1.5' rx='2' ry='1.5' transform='translate(20.684 13)' fill='#343434' />
      <path
        id='Path_28357'
        data-name='Path 28357'
        d='M18,34.437h0a.8.8,0,0,1,.731-.812,9.537,9.537,0,0,0,3.574-.893A6.475,6.475,0,0,1,25.229,32a5.891,5.891,0,0,1,2.355.569.777.777,0,0,1,.244,1.218h0a.751.751,0,0,1-.975.162,3.966,3.966,0,0,0-1.543-.325,5.532,5.532,0,0,0-2.274.569,13.8,13.8,0,0,1-4.142,1.056A.89.89,0,0,1,18,34.437Z'
        transform='translate(-6.629 -9.259)'
        fill='#343434'
      />
    </SvgIcon>
  );
};
