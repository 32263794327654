import { useMarketplaceWishlistContext } from "@/shared/context";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, PlusAddIcon } from "@toolkit/ui";
import { FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { CartHealthPackageItem } from "../../types";
import { calculateDiscountedPrice } from "../../utils";
import { HealthPackageSelectedCard } from "../HealthPackageSelectedCard/HealthPackageSelectedCard";
import { useCartHealthPackageCardHook } from "./useCartHealthPackageCardHook";
import { useCartHealthPackageCardStyle } from "./useCartHealthPackageCardStyle";

type CartHealthPackageCardProps = {
  healthPackage?: CartHealthPackageItem;
  cartItemId: string;
};

export const CartHealthPackageCard: FC<CartHealthPackageCardProps> = ({ healthPackage, cartItemId }) => {
  const { classes } = useCartHealthPackageCardStyle();
  const navigate = useNavigate();
  const { t } = useTranslation("consumer");

  const { heandleRemoveItemFromCart, handleSaveForLater, cartItemsLoadingTracker, wishListLoadingTracker } = useCartHealthPackageCardHook(
    healthPackage,
    cartItemId
  );
  const { wishlistItems } = useMarketplaceWishlistContext();

  const handleOnClick = () => {
    navigate(routes.healthPackage.getRouteWithParams({ packageId: healthPackage?.id || "" }));
  };

  const handleStopPropagation = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <HealthPackageSelectedCard
      priceInfo={calculateDiscountedPrice(healthPackage?.price, healthPackage?.activeDiscount)}
      classes={{
        itemAvatarContainer: classes.itemAvatarContainer,
        infoContainer: classes.infoContainer,
      }}
      handleOnClick={handleOnClick}
      healthPackage={healthPackage}
      actionsNode={
        <Box className={classes.root} onClick={handleStopPropagation}>
          <Button
            disabled={cartItemsLoadingTracker?.healthPackage?.[healthPackage?.id || ""]}
            className={classes.removeButton}
            onClick={heandleRemoveItemFromCart}
            startIcon={<PlusAddIcon />}
          >
            {t("Remove")}
          </Button>
          {!wishlistItems.healthPackage?.[healthPackage?.id || ""] && (
            <Button
              disabled={
                wishListLoadingTracker?.healthPackage?.[healthPackage?.id || ""] ||
                cartItemsLoadingTracker?.healthPackage?.[healthPackage?.id || ""]
              }
              variant='outlined'
              className={classes.saveLaterButton}
              onClick={handleSaveForLater}
            >
              {t("Save for later")}
            </Button>
          )}
        </Box>
      }
    />
  );
};
