import { makeStyles } from "@toolkit/ui";

export const useDigitalTwinWeightLossPlanCalculatorStyle = makeStyles()(theme => {
  return {
    root: {
      padding: 16,
      display: "flex",
      flexDirection: "column",
      rowGap: 16,
      textAlign: "start",
    },
    headerCard: {
      padding: 16,
      display: "flex",
      gap: 16,
      justifyContent: "space-around",
      alignItems: "center",
    },
    headerCardInner: {
      display: "flex",
      flexDirection: "column",
      rowGap: 8,
    },
    title: {
      fontSize: theme.mixins.fonts.fontSize.lg,
      alignSelf: "flex-start",
    },
    subTitle: {
      fontSize: theme.mixins.fonts.fontSize.md,
      color: theme.palette.text.secondary,
      alignSelf: "flex-start",
    },
  };
});
