import { Chat } from "../../schema/types";
import { useCallback, useContext } from "react";
import { ChatContext } from "./ChatProvider";

export const useChats = () => {
  const { chats, updateChats } = useContext(ChatContext);
  const clearChatUpdates = useCallback(() => updateChats([]), [updateChats]);
  const addNewChat = useCallback((chat: Chat) => updateChats([chat, ...chats]), [chats, updateChats]);
  const setChatDeleted = useCallback((chat: Chat) => updateChats([{ ...chat, deleted: true }, ...chats]), [chats, updateChats]);

  return { chatUpdates: chats, clearChatUpdates, addNewChat, setChatDeleted };
};
