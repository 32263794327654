import { FC, useMemo } from "react";
import { CalculationFormRadio, CalculationFormRadioProps } from "./CalculationFormRadio";
import { ActivityLevel } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";

export const CalculationActivityLevelSelect: FC<Omit<CalculationFormRadioProps, "options"> & { inputLabel: string }> = props => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return [
      {
        label: t("Sedentary: No Activity"),
        value: ActivityLevel.Sedentary,
      },
      {
        label: t("Light Activity: 1-2 days/week"),
        value: ActivityLevel.LightlyActive,
      },
      {
        label: t("Moderate Activity: 2-4 days/week"),
        value: ActivityLevel.ModeratelyActive,
      },
      {
        label: t("Heavy Activity: 4-6 days/week"),
        value: ActivityLevel.HeavyActive,
      },
      {
        label: t("Very Heavy Activity: 6-7 days/week"),
        value: ActivityLevel.VeryHeavyActive,
      },
    ];
  }, []);
  return <CalculationFormRadio options={options} {...props} />;
};
