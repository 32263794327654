import { useCallStyles } from "./Call.styles";
import { Box, IconButton, MuiArrowBackIcon, Typography, MuiFlipCameraIosIcon, MuiVolumeUpIcon, MuiVolumeOffIcon } from "@toolkit/ui";
import { CallInstance } from "./types";
import { MeetingDuration } from "./MeetingDuration";
import { MeetingMenu } from "./MeetingMenu";
import { useCallMenuHook } from "./useCallMenuHook";
import { MeetingSidebar } from "./MeetingSidebar";
import { MeetingView } from "./MeetingView";
import { ParticipantTakePhoto } from "./ParticipantTakePhoto";
import { GuestInvite } from "./GuestInvite";

type MeetingScreenProps = {
  call: CallInstance;
  invitationLink?: string;
  onGoBack?: () => void;
  renderDoctorConsole?: () => JSX.Element;
};

export const MeetingScreen = ({ call, renderDoctorConsole, invitationLink, onGoBack }: MeetingScreenProps) => {
  const menu = useCallMenuHook({ call, invitationLink, isDoctorConsolEnabled: !!renderDoctorConsole });
  const { classes } = useCallStyles();
  const { isDoctorConsoleOpen, isTakePhotoOpen, isGuestInviteOpen, onToggleSpeaker, onToggleTakePhoto, onToggleGuestInvite } = menu;
  const { state, muteSpeaker, flipCamera: handleFlipCamera } = call;
  const { speakerMuted } = state;

  return (
    <Box className={classes.root}>
      {isDoctorConsoleOpen && renderDoctorConsole && renderDoctorConsole()}
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Box>
            {onGoBack && (
              <IconButton color='inherit' onClick={onGoBack}>
                <MuiArrowBackIcon />
              </IconButton>
            )}
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography className={classes.headerTitle}>{call.title}</Typography>
            <Typography sx={{ fontSize: 12 }}>
              <MeetingDuration call={call} />
            </Typography>
          </Box>
          <Box>
            {muteSpeaker && (
              <IconButton color='inherit' onClick={onToggleSpeaker}>
                {!speakerMuted ? <MuiVolumeUpIcon /> : <MuiVolumeOffIcon />}
              </IconButton>
            )}

            {handleFlipCamera && (
              <IconButton color='inherit' onClick={handleFlipCamera}>
                <MuiFlipCameraIosIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box className={classes.body}>
          <MeetingView call={call} />
        </Box>
        <MeetingMenu call={call} {...menu} />
      </Box>

      <MeetingSidebar call={call} {...menu} />
      {isTakePhotoOpen && <ParticipantTakePhoto call={call} onClose={onToggleTakePhoto} />}
      {isGuestInviteOpen && <GuestInvite call={call} onClose={onToggleGuestInvite} />}
    </Box>
  );
};
