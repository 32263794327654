import { makeStyles } from "@toolkit/ui";

export const useDeliveryTypesStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 20,
    gap: 30,
    height: "max-content",
    marginBottom: 20,
  },
  buttonWrapper: {},
  button: {
    width: 140,
    height: 140,
    "& svg": {
      width: 140,
      height: 140,
    },
  },
  label: {
    marginTop: 15,
  },
}));
