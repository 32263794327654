import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceOrdersDetailsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.MarketplaceOrderFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.MarketplaceOrderSortingInput>;
}>;


export type MarketplaceOrdersDetailsQuery = { __typename?: 'Query', marketplaceOrders?: { __typename?: 'MarketplaceOrderConnection', edges: Array<{ __typename?: 'MarketplaceOrderEdge', node: { __typename?: 'MarketplaceOrder', type: Types.MarketplaceOrderType, id: string, createdDate: any, status: Types.MarketplaceOrderStatus, totalNetAmount: any, totalDiscount?: any | null, totalDeliveryPrice: any, totalNetAmountWithVAT: any, orderLines: Array<{ __typename?: 'MarketplaceOrderLine', quantity: number, unitPriceDiscount?: any | null, unitPriceNetAmount: any, product?: { __typename?: 'MarketplaceProduct', id: string, name?: string | null, nameAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, mainImageUrl?: string | null } | null, healthPackage?: { __typename?: 'MarketplaceHealthPackage', id: string, name?: string | null, nameAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, mainImageUrl?: string | null } | null }>, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, logo?: string | null } } }> } | null };


export const MarketplaceOrdersDetailsDocument = gql`
    query MarketplaceOrdersDetails($filter: MarketplaceOrderFilterInput, $first: Int, $after: String, $sortBy: MarketplaceOrderSortingInput) {
  marketplaceOrders(
    filter: $filter
    first: $first
    after: $after
    sortBy: $sortBy
  ) {
    edges {
      node {
        type
        id
        createdDate
        status
        type
        orderLines {
          quantity
          product {
            id
            name
            nameAr
            shortDescription
            shortDescriptionAr
            mainImageUrl
          }
          healthPackage {
            id
            name
            nameAr
            shortDescription
            shortDescriptionAr
            mainImageUrl
          }
          unitPriceDiscount
          unitPriceNetAmount
        }
        totalNetAmount
        totalDiscount
        totalDeliveryPrice
        totalNetAmountWithVAT
        totalDeliveryPrice
        vendor {
          id
          name
          nameAr
          logo
        }
      }
    }
  }
}
    `;

/**
 * __useMarketplaceOrdersDetailsQuery__
 *
 * To run a query within a React component, call `useMarketplaceOrdersDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceOrdersDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceOrdersDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMarketplaceOrdersDetailsQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceOrdersDetailsQuery, MarketplaceOrdersDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceOrdersDetailsQuery, MarketplaceOrdersDetailsQueryVariables>(MarketplaceOrdersDetailsDocument, options);
      }
export function useMarketplaceOrdersDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceOrdersDetailsQuery, MarketplaceOrdersDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceOrdersDetailsQuery, MarketplaceOrdersDetailsQueryVariables>(MarketplaceOrdersDetailsDocument, options);
        }
export function useMarketplaceOrdersDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplaceOrdersDetailsQuery, MarketplaceOrdersDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceOrdersDetailsQuery, MarketplaceOrdersDetailsQueryVariables>(MarketplaceOrdersDetailsDocument, options);
        }
export type MarketplaceOrdersDetailsQueryHookResult = ReturnType<typeof useMarketplaceOrdersDetailsQuery>;
export type MarketplaceOrdersDetailsLazyQueryHookResult = ReturnType<typeof useMarketplaceOrdersDetailsLazyQuery>;
export type MarketplaceOrdersDetailsSuspenseQueryHookResult = ReturnType<typeof useMarketplaceOrdersDetailsSuspenseQuery>;
export type MarketplaceOrdersDetailsQueryResult = Apollo.QueryResult<MarketplaceOrdersDetailsQuery, MarketplaceOrdersDetailsQueryVariables>;