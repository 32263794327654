/* eslint-disable react/jsx-handler-names */
import { useCountDown } from "@/shared/hooks";
import { i18n, useTranslation } from "@toolkit/i18n";
import { Box, Button, Typography } from "@toolkit/ui";
import React, { ReactElement, useEffect, useState } from "react";
import { InputOTP } from "./InputOTP";

interface OtpFormProps {
  codeLength?: number;
  message?: string;
  onResend?: () => void;
  onSubmit: (otp: string) => void;
  outterError?: string;
  seconds: number;
  username: string;
}

export default function OtpForm(props: OtpFormProps): ReactElement {
  const { t } = useTranslation();
  const { codeLength = 4, message = "", onResend: onResendClick, onSubmit, outterError, seconds } = props;
  const { isDone: canResend, remainingSeconds, remainingMinutes, startCountDown } = useCountDown(seconds);
  const [OTP, setOTP] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  useEffect(() => {
    startCountDown();
  }, []);

  const handelResend = async function (): Promise<void> {
    if (!canResend) return;
    startCountDown();
    onResendClick?.();
  };

  const handleSubmit = async function (e: React.FormEvent): Promise<void> {
    setIsButtonDisable(true);
    e.preventDefault();
    let isError = false;
    if (OTP.length <= 3) isError = true;
    setError(isError ? t("Invalid OTP") : "");
    setIsButtonDisable(false);
    if (isError) return;
    onSubmit(OTP);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
        <Typography fontSize={20} textAlign={"center"}>
          {t("Verification")}
        </Typography>
        <Typography fontSize={16}>
          {message} <b>{props.username}</b>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <InputOTP
            onChange={e => {
              setOTP(e);
            }}
            numInputs={codeLength}
          />
        </Box>

        <Typography>
          {t("Didn't receive an OTP?")}{" "}
          <Button variant='text' role='button' onClick={handelResend} disabled={!canResend}>
            {t("Resend")} {remainingMinutes}:{remainingSeconds}
          </Button>
        </Typography>
        <Box alignItems={"center"}>
          <Button type='submit' fullWidth className={"auth-button"} disabled={isButtonDisable}>
            {t("Verify")}
          </Button>
        </Box>
        {outterError ? <Typography color='error.main'>{outterError}</Typography> : ""}
        {error ? <Typography color='error.main'>{error}</Typography> : ""}
      </Box>
    </form>
  );
}

OtpForm.defaultProps = {
  codeLength: 4,
  message: i18n.t("Please enter the 4-digit verification code that was sent to Your"),
  onSubmit: () => {},
};
