import { PageLayout } from "./PageLayout";
import { PageHeaderWithBack } from "../PageHeader";
import { PageLayoutProps } from "./type";
import { AppBarFooter } from "../PageFooter";

export const PageWithAppBarAndBackHeaderLayout = (props: PageLayoutProps) => {
  const { containerBackground, containerBreakpoint, hideBack = false, withBlob = false, children } = props;
  return (
    <PageLayout
      containerBackground={containerBackground}
      containerBreakpoint={containerBreakpoint}
      displayBlob={withBlob}
      header={<PageHeaderWithBack hideBack={hideBack} />}
      footer={<AppBarFooter />}
    >
      {children}
    </PageLayout>
  );
};
