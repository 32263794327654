import { makeStyles } from "@toolkit/ui";

export const useEmptyMessageDefaultStyles = makeStyles()(theme => ({
  root: {},
  iconContainer: {
    "& svg": {
      width: "100px",
      height: "100px",
    },
  },
  messageContainer: {
    marginTop: "20px",
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.text.primary,
  },
  subMessageContainer: {
    height: "fit-content",
  },
  actionContainer: {
    height: "fit-content",
    display: "none",
  },
}));
