import { ExpandMoreIcon } from "@/shared/icons";
import { Box, BoxProps, Collapse, IconButton } from "@toolkit/ui";
import React, { useEffect, useState } from "react";
import { useExpandableCardStyles } from "./useExpandableCardStyles";

export interface ExpandableCardProps extends Omit<BoxProps, "classes" | "title" | "content"> {
  header: React.ReactNode;
  defaultCollapsed?: boolean;
  hideCollapse?: boolean;
  classes?: Partial<ReturnType<typeof useExpandableCardStyles>["classes"]>;
}

export const ExpandableCard: React.FC<ExpandableCardProps> = props => {
  const { header, defaultCollapsed = false, hideCollapse = false, classes: _classes, children, ...rest } = props;

  const { classes, cx } = useExpandableCardStyles();
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  useEffect(() => {
    setCollapsed(defaultCollapsed);
  }, [defaultCollapsed]);

  const handleToggle = () => {
    setCollapsed(prev => !prev);
  };

  return (
    <Box className={cx(classes.container, _classes?.container)} {...rest}>
      <Box
        className={cx(classes.headerContainer, _classes?.headerContainer, {
          [classes.headerContainerExpand]: !collapsed,
          [_classes?.headerContainerExpand ?? ""]: !collapsed,
        })}
      >
        <Box className={cx(classes.header, _classes?.header)}>{header}</Box>
        {!hideCollapse && (
          <IconButton
            className={cx(classes.toggleIcon, _classes?.toggleIcon, classes.expandIcon, _classes?.expandIcon, {
              [classes.expanded]: !collapsed,
            })}
            onClick={handleToggle}
          >
            <ExpandMoreIcon direction='down' />
          </IconButton>
        )}
      </Box>
      <Collapse in={!collapsed || hideCollapse}>
        <Box className={cx(classes.contentContainer, _classes?.contentContainer)}>{children}</Box>
      </Collapse>
    </Box>
  );
};
