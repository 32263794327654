import { FC } from "react";
import { useWellnessCardStyles } from "../../styles/useWellnessCard.styles";
import { CustomCard } from "@/shared/components";
import { Box, SleepIcon, Typography } from "@toolkit/ui";
import { Trans, useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { sleepCalculationRoutes } from "@/domains/digital-twin-sleep-calculator/routes";
import { useGetDigitalTwinHealthParameterByCode } from "@/domains/health-parameters/hooks/useGetDigitalTwinHealthParameterByCode";
import { HealthParameterCodes } from "@/domains/health-parameters/constants/healthParameterCodes";

export const SleepTrackingCard: FC = () => {
  const { classes, cx } = useWellnessCardStyles();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();

  const { value: sleepCycles, loading: isLoadingSleepCycles } = useGetDigitalTwinHealthParameterByCode(HealthParameterCodes.sleepCycles);
  const { value: sleepTime, loading: isLoadingSleepTime } = useGetDigitalTwinHealthParameterByCode(HealthParameterCodes.sleepTime);

  const isLoading = isLoadingSleepCycles || isLoadingSleepTime;

  const handleNavigateToSleepCalculator = () => {
    navigate(sleepCalculationRoutes.root.absRoute);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <Box className={classes.titleWrapper}>
            <Typography className={classes.title}>
              <span className={classes.bold}>{t("Tracking Sleep")}</span>
            </Typography>
          </Box>
          <Box className={classes.loadingSkeleton}></Box>
        </>
      );
    }

    if (sleepCycles) {
      return (
        <>
          <Box className={classes.titleWrapper}>
            <Typography className={classes.title}>
              <span className={classes.bold}>{t("Tracking Sleep")}</span>
            </Typography>
          </Box>
          <Typography className={classes.description}>
            <span className={classes.bold}>{sleepCycles}</span> {t("Sleep cycles")}
          </Typography>
          <Typography className={classes.description}>
            <span className={classes.bold}>{sleepTime}</span> {t("Sleeping hours")}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Box className={classes.titleWrapper}>
          <Trans
            className={classes.title}
            i18nKey='Start <b>Tracking Sleep</b>'
            t={t}
            components={{
              b: <Typography className={classes.bold} />,
            }}
          />
        </Box>
        <Typography className={classes.description}>{t("Use our calculator to find your recommended sleep cycle.")}</Typography>
      </>
    );
  };

  return (
    <CustomCard onClick={handleNavigateToSleepCalculator} className={cx(classes.root, classes.clickableRoot)}>
      <Box className={classes.startIcon}>
        <SleepIcon />
      </Box>
      <Box className={classes.textWrapper}>{renderContent()}</Box>
    </CustomCard>
  );
};
