import { MarketplaceHealthPackageSortingInput, MarketplaceProductFilterInput } from "@/schema/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useMarketplaceHealthPackagesQuery } from "../../gql";

export const useHealthPackagesList = (filters?: MarketplaceProductFilterInput, sortingInput?: MarketplaceHealthPackageSortingInput) => {
  const {
    data,
    loading: isLoading,
    hasMore,
    handleFetchMore: fetchMoreData,
    refetch,
  } = useRelayStyleConnection({
    useQuery: useMarketplaceHealthPackagesQuery,
    variables: {
      filter: filters,
      sortBy: sortingInput,
    },
  });

  const handleRefresh = () => {
    refetch();
  };

  return {
    healthPackages: data,
    isLoading,
    hasMore,
    fetchMoreData,
    handleRefresh,
  };
};
