import { CustomCard } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { SelectedCardProductPropsType } from "../../types";
import { PriceCalculationResult } from "../../utils";
import { HealthPackageSelectedCard } from "../HealthPackageSelectedCard/HealthPackageSelectedCard";
import { PriceDisplay } from "../PriceDisplay/PriceDisplay";
import { useOrderHealthPackagesCardStyles } from "./OrderHealthPackagesCardStyles";

type OrderItem = SelectedCardProductPropsType & {
  priceInfo: PriceCalculationResult;
  priceForQuantity: PriceCalculationResult;
  quantity: number;
};

type OrderHealthPackagesCardProps = {
  healthPackages: OrderItem[];
  classes?: Partial<ReturnType<typeof useOrderHealthPackagesCardStyles>["classes"]>;
  isTitleHidden?: boolean;
};

export const OrderHealthPackagesCard: FC<OrderHealthPackagesCardProps> = props => {
  const { healthPackages, classes: _classes, isTitleHidden } = props;
  const { classes, cx } = useOrderHealthPackagesCardStyles();
  const { t } = useTranslation("consumer");

  return healthPackages?.length ? (
    <CustomCard className={cx(classes.root, _classes?.root)}>
      {!isTitleHidden && <Typography className={cx(classes.cardHeader, _classes?.cardHeader)}>{t("Health Packages")}</Typography>}
      <Box className={cx(classes.cardContainer, _classes?.cardContainer)}>
        {healthPackages.map(healthPackage => (
          <HealthPackageSelectedCard
            key={healthPackage?.id}
            priceInfo={healthPackage?.priceInfo}
            classes={{
              card: cx(classes.itemCard, _classes?.itemCard),
              itemAvatarContainer: cx(classes.itemAvatarContainer, _classes?.itemAvatarContainer),
              infoContainer: cx(classes.infoContainer, _classes?.infoContainer),
              image: cx(classes.image, _classes?.image),
              imageRoot: cx(classes.imageRoot, _classes?.imageRoot),
            }}
            healthPackage={healthPackage}
            extraInfoNode={
              <>
                <Typography>{t("{{itemsCount}} Package", { itemsCount: healthPackage?.quantity })}</Typography>
                <Box className={cx(classes.totalPrice, _classes?.totalPrice)}>
                  <PriceDisplay increaseFontSizeBy={0} amount={healthPackage?.priceForQuantity?.discountedPrice} type='discounted-price' />
                  {healthPackage?.priceForQuantity?.hasActiveDiscount && (
                    <PriceDisplay increaseFontSizeBy={0} amount={healthPackage?.priceForQuantity?.basePrice} type='base-price' />
                  )}
                </Box>
              </>
            }
          />
        ))}
      </Box>
    </CustomCard>
  ) : null;
};
