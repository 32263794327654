import { AudioOption, ExecutedResponse, ExecutedResult, MediaStream, ZoomClientType } from "./types";

function createHiddenElement<T extends HTMLElement>(id: string, tag: string): T {
  let elm = document.getElementById(id) as T;
  if (elm) {
    return elm;
  }

  elm = document.createElement(tag) as T;
  elm.style.display = "none";
  elm.id = id;

  document.body.appendChild(elm);
  return elm;
}

export async function initializeZoomClient(client: ZoomClientType): Promise<ExecutedResponse> {
  try {
    const enforceGalleryView = !window?.crossOriginIsolated;
    await client.init("en-US", `CDN`, {
      webEndpoint: "zoom.us",
      enforceMultipleVideos: enforceGalleryView,
      enforceVirtualBackground: enforceGalleryView,
      stayAwake: true,
    });

    return { success: true };
  } catch (error) {
    return formatExecutedFailure(error);
  }
}

export function formatExecutedFailure(error: unknown): ExecutedResponse {
  if (error !== null && typeof error === "object" && "type" in error) {
    return error as ExecutedResponse;
  }

  return { type: "ERROR", reason: error };
}

async function formatExecutedResult(callback: ExecutedResult): Promise<ExecutedResponse> {
  try {
    await callback;
    return { success: true };
  } catch (error) {
    return formatExecutedFailure(error);
  }
}

export async function startAudio(stream: MediaStream, options: AudioOption): Promise<ExecutedResponse> {
  await stopAudio(stream);
  return formatExecutedResult(stream.startAudio(options));
}

export async function stopAudio(stream: MediaStream): Promise<ExecutedResponse> {
  return formatExecutedResult(stream.stopAudio());
}

export async function startVideoStream(stream: MediaStream) {
  if (stream.isRenderSelfViewWithVideoElement()) {
    const videoElement = createHiddenElement<HTMLVideoElement>("__zm-publisher-video", "video");

    // IOS Mobile support
    return formatExecutedResult(stream.startVideo({ videoElement }));
  } else {
    return formatExecutedResult(
      stream.startVideo({
        hd: stream.isSupportHDVideo(),
        ptz: stream.isBrowserSupportPTZ(),
        originalRatio: true,
      })
    );
  }
}

export async function startShareScreen(stream: MediaStream) {
  const screenElement = createHiddenElement<HTMLVideoElement | HTMLCanvasElement>(
    "__zm-publisher-screen",
    stream.isStartShareScreenWithVideoElement() ? "video" : "canvas"
  );

  return stream.startShareScreen(screenElement);
}

export async function getUserMedia(constraints?: MediaStreamConstraints): Promise<ExecutedResponse<{ stream: globalThis.MediaStream }>> {
  try {
    if (!("mediaDevices" in navigator)) {
      return { type: "MEDIA_NOT_FOUND" };
    }

    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    return { success: true, stream };
  } catch (error: any) {
    switch (error?.name) {
      case "NotFoundError":
        return { type: "MEDIA_NOT_FOUND" };
      case "PermissionDeniedError":
      case "NotAllowedError":
      case "INSUFFICIENT_PRIVILEGES": {
        return { type: "MEDIA_NOT_ALLOWED" };
      }
      case "NotReadableError": {
        return { type: "MEDIA_NOT_READABLE" };
      }
    }
    return formatExecutedFailure(error);
  }
}
