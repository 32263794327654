import { useOpenState } from "@toolkit/core";
import { useAddToast } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useState } from "react";
import { useRequestOtpForLoginMutation } from "../../graphql/mutations";
import { formatGraphQLError } from "@toolkit/apollo";
import { SignInByOTPModalOpenDataType } from "../../types";

export const useSignInByOTPModalLogic = () => {
  const { t } = useTranslation("consumer");
  const { open: isOpen, handleOpen, handleClose } = useOpenState();
  const { failed, succeeded } = useAddToast();
  const [signInData, setSignInData] = useState<SignInByOTPModalOpenDataType | null>(null);
  const [sessionToken, setSessionToken] = useState<string | undefined>();

  const [sendOtp, { loading: isOtpSending }] = useRequestOtpForLoginMutation({
    onCompleted: data => {
      const token = data?.requestOtp?.sessionToken;
      if (token) {
        setSessionToken(token);
        succeeded(t("OTP sent successfully"));
      }
    },
    onError: ({ graphQLErrors }) => failed(formatGraphQLError(graphQLErrors)),
  });

  const open = (data: SignInByOTPModalOpenDataType) => {
    handleOpen();
    setSignInData(data);
  };

  const close = () => {
    handleClose();
  };

  const handleSendOtp = () => {
    if (!signInData?.mobile) {
      failed(t("Mobile number is required"));
      return;
    }
    sendOtp({ variables: { phoneNumber: signInData.mobile } });
  };

  return {
    isOpen,
    signInData,
    sessionToken,
    isOtpSending,
    open,
    close,
    handleSendOtp,
    setSessionToken,
  };
};
