import { AddressFragmentFragment } from "@/domains/address/gql/fragments";
import {
  MarketplaceCartAddItemMutationVariables,
  MarketplaceCartUpdateItemQuantityMutation,
  useMarketplaceCartAddItemMutation,
  useMarketplaceCartClearMutation,
  useMarketplaceCartRemoveItemsMutation,
  useMarketplaceCartUpdateItemQuantityMutation,
} from "@/gql/mutations";
import { useMyMarketplaceCartQuery } from "@/gql/queries/__generated__/myMarketplaceCart";
import { MarketplaceCartInput } from "@/schema/types";
import { ApolloError, FetchResult, NetworkStatus, useApolloClient } from "@apollo/client";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { find } from "lodash";
import { FC, PropsWithChildren, createContext, useMemo, useReducer, useState } from "react";
import { useAuth } from "react-oidc-context";
import { CartContextProps, marketplaceItemsHashMap } from "./types";
import { calculateCartItemCounts, generateCartItemsHashMap, itemsLoadingTrackerMapReducer } from "./utils"; // Updated imports for utils

export const MarketplaceCartContext = createContext<CartContextProps>({} as CartContextProps);

export const MarketplaceCartProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { cache } = useApolloClient();
  const [shippingAddress, setShippingAddress] = useState<AddressFragmentFragment | undefined>();
  const { failed } = useAddToast();
  const { t } = useTranslation("consumer");

  const handleGraphQLError = (error: ApolloError) => {
    failed(t(error.message || "An error occurred while processing your request."));
  };

  const {
    data,
    networkStatus: cartNetworkStatus,
    refetch: refetchCart,
  } = useMyMarketplaceCartQuery({
    fetchPolicy: "cache-and-network",
    skip: !isAuthenticated,
  });

  const cart = data?.me?.marketplaceCart;
  const cartId = cart?.id;

  const cartItems = useMemo<marketplaceItemsHashMap | undefined>(() => generateCartItemsHashMap(cart), [cart]);
  const itemCounts = useMemo(() => calculateCartItemCounts(cart), [cart]);
  const isCartLoading = cartNetworkStatus === NetworkStatus.loading;
  const isCartRefetching = cartNetworkStatus === NetworkStatus.refetch;
  const [itemsLoadingTrackerMap, dispatch] = useReducer(itemsLoadingTrackerMapReducer, {
    product: {},
    healthPackage: {},
  });

  const [addItemToCartMutation] = useMarketplaceCartAddItemMutation({
    onError: handleGraphQLError,
    onCompleted: (data, clientOptions) => {
      const { input } = clientOptions?.variables as MarketplaceCartAddItemMutationVariables;
      refetchCart?.();
      const type = input?.productId ? "product" : "healthPackage";
      const id = input?.productId || input?.healthPackageId || "";
      dispatch({ type: "SET_LOADED", payload: { id, category: type } });
    },
    update: (cache, { data }) => {
      if (data?.marketplaceCartAddItem?.id) {
        const normalizedId = cache.identify({ id: data.marketplaceCartAddItem.id, __typename: "MarketplaceCart" });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
  });

  const [removeItemsFromCartMutation] = useMarketplaceCartRemoveItemsMutation({
    onError: handleGraphQLError,
  });

  const [clearCartMutation] = useMarketplaceCartClearMutation({
    update: cache => {
      cache.modify({
        fields: {
          marketplaceCart: () => null,
        },
      });
    },
  });

  const [updateItemQuantityMutation] = useMarketplaceCartUpdateItemQuantityMutation({
    onError: handleGraphQLError,
    onCompleted: () => refetchCart?.(),
  });

  const handleItemUpdate = (itemId: string, quantity: number) => {
    return updateItemQuantityMutation({
      variables: { cartItemId: itemId, quantity },
    });
  };

  const handleItemRemoval = (itemIds: string[]) => {
    return removeItemsFromCartMutation({
      variables: { cartItemIds: itemIds },
    });
  };

  const handleClearCartCache = (id: string) => {
    const normalizedId = cache.identify({ id, __typename: "MarketplaceCart" });
    cache.evict({ id: normalizedId });
    cache.gc();
  };

  const addItemToCart = async (items: MarketplaceCartInput) => {
    const type = items.productId ? "product" : "healthPackage";
    const id = items.productId || items.healthPackageId || "";

    dispatch({ type: "SET_LOADING", payload: { id, category: type } });

    try {
      const response = await addItemToCartMutation({
        variables: { input: items },
      });
      response?.data?.marketplaceCartAddItem?.id && refetchCart?.();
      return response;
    } finally {
      dispatch({ type: "SET_LOADED", payload: { id, category: type } });
    }
  };

  const toggleItemToCart = (items: MarketplaceCartInput) => {
    const type = items.productId ? "product" : "healthPackage";
    const id = items.productId || items.healthPackageId || "";
    if (itemsLoadingTrackerMap[type][id]) {
      return;
    }
    dispatch({ type: "SET_LOADING", payload: { id, category: type } });
    if (cartItems?.[type]?.[id]) {
      const item = find(
        cart?.items,
        item =>
          (type === "product" && item?.product?.id === items.productId) ||
          (type === "healthPackage" && item?.healthPackage?.id === items.healthPackageId)
      );
      if (item) {
        removeItemsFromCartMutation({
          variables: { cartItemIds: [item.id] },
        }).then(() => {
          dispatch({ type: "SET_LOADED", payload: { id, category: type } });
        });
      }
    } else {
      addItemToCartMutation({
        variables: { input: items },
      }).then(() => {
        refetchCart?.();
      });
    }
  };

  const removeItemsFromCart = async (itemIds: string[]) => {
    const matchingItems = cart?.items?.filter(item => itemIds.includes(item.id)) || [];

    matchingItems.forEach(item => {
      const category = item.product ? "product" : "healthPackage";
      const itemId = item.product?.id || item.healthPackage?.id || "";

      dispatch({
        type: "SET_LOADING",
        payload: { id: itemId, category },
      });
    });

    try {
      const response = await handleItemRemoval(itemIds);

      itemIds.forEach(id => {
        const matchingItem = matchingItems.find(item => item.id === id);
        const itemId = matchingItem?.product?.id || matchingItem?.healthPackage?.id || "";
        const category = matchingItem?.product ? "product" : "healthPackage";

        dispatch({
          type: "SET_LOADED",
          payload: { id: itemId, category },
        });
      });

      return response;
    } catch (error) {
      console.error("Failed to remove items from cart:", error);
      throw error;
    }
  };

  const updateItemQuantity = async (itemId: string, quantity: number): Promise<FetchResult<MarketplaceCartUpdateItemQuantityMutation>> => {
    const item = find(cart?.items, item => item.id === itemId);
    let response: Promise<FetchResult<MarketplaceCartUpdateItemQuantityMutation>>;
    if (itemId && quantity > 0) {
      response = handleItemUpdate(itemId, quantity);
    } else {
      response = handleItemRemoval([itemId]) as Promise<FetchResult<MarketplaceCartUpdateItemQuantityMutation>>;
    }
    dispatch({
      type: "SET_LOADING",
      payload: { id: item?.product?.id || item?.healthPackage?.id || "", category: item?.product ? "product" : "healthPackage" },
    });
    response.then(() => {
      dispatch({
        type: "SET_LOADED",
        payload: { id: item?.product?.id || item?.healthPackage?.id || "", category: item?.product ? "product" : "healthPackage" },
      });
    });
    return response;
  };

  const clearCart = () => {
    if (cartId) {
      clearCartMutation().then(() => handleClearCartCache(cartId));
    }
  };

  const refreshCart = () => refetchCart?.();

  const updateShippingAddress = (address?: AddressFragmentFragment) => setShippingAddress(address);

  return (
    <MarketplaceCartContext.Provider
      value={{
        itemCounts,
        totalPrice: cart?.totalPrice,
        isCartLoading,
        shippingAddress,
        isCartRefetching,
        cart,
        cartItems,
        clearCart,
        handleClearCartCache,
        updateShippingAddress,
        refreshCart,
        addItemToCart,
        removeItemsFromCart,
        updateItemQuantity,
        itemsLoadingTrackerMap,
        toggleItemToCart,
      }}
    >
      {children}
    </MarketplaceCartContext.Provider>
  );
};
