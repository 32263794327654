import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(() => ({
  root: {
    ".MuiPaper-root": {
      maxWidth: "unset",
      maxHeight: "unset",
      height: "100vh",
      width: "100vw",
      background: "transparent",
      margin: 0,
    },

    ".MuiDialogContent-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    hr: {
      display: "none",
    },
  },
  title: {
    padding: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: 1,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
  },
}));
