import { makeStyles } from "@toolkit/ui";

export const useMarketplaceItemReviewsStyle = makeStyles()(() => ({
  root: {
    marginTop: "20px",
  },
  loadMoreButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
}));
