import { AddressesList } from "@/domains/address/components/AddressesList/AddressesList";
import { AddressFragmentFragment } from "@/domains/address/gql/fragments";
import { useAccountSettingsInformationQuery } from "@/pages/AccountSettings/gql/queries";
import { useHeaderContext, useMarketplaceCartContext } from "@/shared/context";
import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, Typography } from "@toolkit/ui";
import { find } from "lodash";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

export const AddressSelectionContainer: FC = () => {
  const { classes, cx } = useCommonContainerStyles();
  const { updateShippingAddress, shippingAddress } = useMarketplaceCartContext();

  const [selectedAddress, setSelectedAddress] = useState<AddressFragmentFragment | null>(shippingAddress ?? null);

  const { t } = useTranslation("consumer");

  const navigate = useNavigate();

  const { setHeaderTitle } = useHeaderContext();

  const { data, loading } = useAccountSettingsInformationQuery({
    fetchPolicy: "cache-and-network",
  });

  const addresses = data?.me?.addresses;

  const handleNavigateToPlaceOrder = () => {
    if (selectedAddress) {
      updateShippingAddress?.(selectedAddress);
      navigate(routes.placeOrder.absRoute);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const getItemSelectionStatus = (id?: string) => {
    if (selectedAddress) {
      return selectedAddress?.id === id;
    }
    return undefined;
  };

  const handleOnAddressSelect = (address: AddressFragmentFragment) => {
    setSelectedAddress(address);
  };

  useEffect(() => {
    setHeaderTitle(t("Checkout"));
    return () => {
      setHeaderTitle("");
    };
  }, []);

  useEffect(() => {
    if (addresses && !selectedAddress) {
      find(addresses, address => {
        if (address?.isDefaultShippingAddress) {
          setSelectedAddress(address);
          return true;
        }
      });
    }
  }, [addresses]);

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography className={classes.pageTitle}>{t("Deliver to")}</Typography>
        {!loading ? (
          <AddressesList
            hideDelete
            isCardSelectable
            onSelect={handleOnAddressSelect}
            getItemSelectionStatus={getItemSelectionStatus}
            addresses={addresses}
          />
        ) : (
          <CircularProgress />
        )}
      </Box>
      <Box className={cx(classes.footer, classes.towButtonfooter)}>
        <Button onClick={handleNavigateToPlaceOrder} className={cx(classes.button, classes.samllButton)}>
          {t("Place Order")}
        </Button>
        <Button onClick={handleCancel} variant='outlined' className={cx(classes.button, classes.samllButton)}>
          {t("Cancel")}
        </Button>
      </Box>
    </Box>
  );
};
