import { useCommonContainerStyles } from "@/shared/styles";
import { Box, Button } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { OrderTrackingViewProps, PaymentSummaryContainerDataProps } from "../../others/types";
import { useHeaderContext } from "@/shared/context";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { OrderTrackingFooter } from "../OrderTrackingFooter/OrderTrackingFooter";
import { PatientShareAmountCard } from "../PatientShareAmountCard/PatientShareAmountCard";
import { useNavigate } from "react-router-dom";
import { orderMedicationsRoutes } from "../../routes";

export const OrderPaymentPage: FC<OrderTrackingViewProps> = props => {
  const { order, prescription } = props;
  const { classes, cx } = useCommonContainerStyles();
  const { setHeaderTitle } = useHeaderContext();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const handleGoToPaymentSummary = () => {
    navigate(orderMedicationsRoutes.paymentSummary.absRoute, { state: { order, prescription } as PaymentSummaryContainerDataProps });
  };

  const selectedPharmacy = pickLocalizedValue(order?.children?.[0]?.branch, order?.children?.[0]?.branch);
  selectedPharmacy;
  useEffect(() => {
    setHeaderTitle(t("Payment Share"));
    () => setHeaderTitle("");
  }, [order]);

  return (
    <>
      <Box className={classes.pageContent}>
        <PatientShareAmountCard order={order} />
      </Box>
      <OrderTrackingFooter
        FooterButton={
          <Button className={cx(classes.button, classes.samllButton)} onClick={handleGoToPaymentSummary}>
            {t("Pay Now")}
          </Button>
        }
        order={order}
        prescription={prescription}
      />
    </>
  );
};
