import { useEffect, useMemo, useState } from "react";

import { ConnectionState, ZoomClientType, ZoomParticipant } from "./types";

export function useUsers({ client, connectionState }: { client: ZoomClientType; connectionState?: ConnectionState }) {
  const [users, setUsers] = useState<Record<string, ZoomParticipant>>({});

  useEffect(() => {
    if (connectionState !== ConnectionState.Connected) {
      return;
    }

    const onUserUpdate = () => {
      const currentUserId = client.getSessionInfo().userId;
      const allUsers = client
        .getAllUser()
        .filter(u => !u.isInFailover)
        .map(user => {
          if (currentUserId === user.userId) {
            return { ...user, isCurrentUser: true };
          }
          return user;
        });

      setUsers(allUsers.reduce((list, u) => ({ ...list, [u.userId]: u }), {}));
    };

    onUserUpdate();
    client.on("user-added", onUserUpdate);
    client.on("user-removed", onUserUpdate);
    client.on("user-updated", onUserUpdate);

    return () => {
      client.off("user-added", onUserUpdate);
      client.off("user-removed", onUserUpdate);
      client.off("user-updated", onUserUpdate);
      setUsers({});
    };
  }, [client, connectionState]);

  const currentUser = useMemo(() => {
    const userId = Object.keys(users).find(u => users[u].isCurrentUser);

    return userId ? users[userId] : undefined;
  }, [users]);

  return { users, currentUser };
}
