import { useMyMarketplaceWishlistQuery } from "@/gql";
import { EmptyMessageLayout } from "@/shared/components";
import { EmptyMarketplaceIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { get, groupBy, isEmpty, sortBy } from "lodash";
import { FC, useMemo } from "react";
import { MarketplaceSelectedCardSkeleton } from "../MarketplaceSelectedCardSkeleton/MarketplaceSelectedCardSkeleton";
import { WishlistHealthPackageCard } from "../WishlistHealthPackageCard/WishlistHealthPackageCard";
import { WishlistProductCard } from "../WishlistProductCard/WishlistProductCard";
import { useWishListStyles } from "./WishlistStyles";

export const Wishlist: FC = () => {
  const { classes } = useWishListStyles();
  const { t } = useTranslation("consumer");

  const { data, loading } = useMyMarketplaceWishlistQuery({
    variables: {
      showDetails: true,
    },
    fetchPolicy: "network-only",
  });

  const wishlistItems = data?.me?.marketplaceWishlist?.items || [];

  const groupedAndSortedWishlistItems = useMemo(() => {
    if (!wishlistItems.length) return undefined;

    const groupedWishlistItems = groupBy(wishlistItems, item => (item?.healthPackage?.id ? "healthPackage" : "product"));

    return Object.keys(groupedWishlistItems).reduce((result, key) => {
      result[key] = sortBy(groupedWishlistItems[key], item => {
        const name = get(item, "product.name", "") || get(item, "healthPackage.name", "");
        const nameAr = get(item, "product.nameAr", "") || get(item, "healthPackage.nameAr", "");
        return name || nameAr || "";
      });
      return result;
    }, {});
  }, [wishlistItems]);

  if (loading && !wishlistItems.length)
    return (
      <Box className={classes.root}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Box mt={"10px"} key={index}>
            <MarketplaceSelectedCardSkeleton />
          </Box>
        ))}
      </Box>
    );

  if (isEmpty(wishlistItems))
    return (
      <Box mt={15} className={classes.root}>
        <EmptyMessageLayout
          message={t("Your wishlist is empty. Start exploring now!")}
          subMessage={t("Save items you love and revisit them anytime.")}
          icon={<EmptyMarketplaceIcon />}
        />
      </Box>
    );

  return (
    <Box className={classes.root}>
      {["product", "healthPackage"].map(key => {
        const items = groupedAndSortedWishlistItems?.[key];

        return (
          !isEmpty(items) && (
            <Box className={classes.sectionContainer} key={key}>
              <Box mt={key === "healthPackage" ? "30px" : "10px"} className={classes.sectionHeader}>
                <Typography className={classes.sectionTitle}>{key === "healthPackage" ? t("Health Packages") : t("Products")}</Typography>
                <Box className={classes.sectionCountContainer}>{items.length}</Box>
              </Box>
              {items.map(item =>
                key === "healthPackage" ? (
                  <WishlistHealthPackageCard key={item.id} healthPackage={item.healthPackage} />
                ) : (
                  <WishlistProductCard key={item.id} product={item.product} />
                )
              )}
            </Box>
          )
        );
      })}
    </Box>
  );
};
