import { GuidedCareActivityType, GuidedCareJourneyItemStatus } from "@/schema/types";
import { BySystemIcon, CanceledIcon, CheckIcon, LabIcon, MedicalFormIcon, OnSiteIcon, RadIcon, TentativeIcon } from "@/shared/icons";
import { AtHomeIcon } from "@toolkit/ui";

export const getGuidedCareJourneyItemStatusIcon = (status: GuidedCareJourneyItemStatus) => {
  switch (status) {
    case GuidedCareJourneyItemStatus.Missed:
    case GuidedCareJourneyItemStatus.Cancelled:
    case GuidedCareJourneyItemStatus.Failed:
    case GuidedCareJourneyItemStatus.Rejected:
      return <CanceledIcon width={"12px"} height={"12px"} />;
    case GuidedCareJourneyItemStatus.Completed:
    case GuidedCareJourneyItemStatus.Accepted:
    case GuidedCareJourneyItemStatus.Received:
    case GuidedCareJourneyItemStatus.InProgress:
      return <CheckIcon width={"12px"} height={"12px"} />;
    default:
      return <TentativeIcon width={"12px"} height={"12px"} />;
  }
};

export const getGuidedCareJourneyItemTypeIcon = (type: GuidedCareActivityType) => {
  switch (type) {
    case GuidedCareActivityType.HomeVisit:
      return <AtHomeIcon width={"12px"} height={"12px"} />;
    case GuidedCareActivityType.FacilityVisit:
      return <OnSiteIcon width={"12px"} height={"12px"} />;
    case GuidedCareActivityType.OnlineVisit:
      return <BySystemIcon width={"12px"} height={"12px"} />;
    case GuidedCareActivityType.MedicalForm:
      return <MedicalFormIcon width={"12px"} height={"12px"} />;
    case GuidedCareActivityType.Rad:
      return <RadIcon width={"12px"} height={"12px"} />;
    case GuidedCareActivityType.Lab:
      return <LabIcon width={"12px"} height={"12px"} />;
    default:
      return <AtHomeIcon width={"12px"} height={"12px"} />;
  }
};

export const guidedCareActivityTypeNonMedicalMessagesOptions = [
  GuidedCareActivityType.HomeVisit,
  GuidedCareActivityType.FacilityVisit,
  GuidedCareActivityType.OnlineVisit,
  GuidedCareActivityType.MedicalForm,
  GuidedCareActivityType.Lab,
  GuidedCareActivityType.Rad,
];
