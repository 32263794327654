import { HealthPackageContainer } from "../marketplace-health-packages/containers/HealthPackageContainer/HealthPackageContainer";
import { HealthPackagesListContainer } from "../marketplace-health-packages/containers/HealthPackagesListContainer/HealthPackagesListContainer";
import { MyOrdersContainer } from "../marketplace-orders";
import { OrdersDetailsContainer } from "../marketplace-orders/containers/OrdersDetailsContainer/OrdersDetailsContainer";
import { MyOrdersTabsEnum } from "../marketplace-orders/others/types";
import { ProductContainer } from "../marketplace-products/containers/ProductContainer/ProductContainer";
import { ProductsListContainer } from "../marketplace-products/containers/ProductsListContainer/ProductsListContainer";
import { AddressSelectionContainer } from "./containers/AddressSelectionContainer/AddressSelectionContainer";
import { CartListContainer } from "./containers/CartListContainer/CartListContainer";
import { CheckoutContainer } from "./containers/CheckoutContainer/CheckoutContainer";
import { HealthMarketContainer } from "./containers/MarketplaceMainContainer/HealthMarketContainer";
import { MarketplaceTermsContainer } from "./containers/MarketplaceTerms/MarketplaceTerms";
import { PlaceOrderContainer } from "./containers/PlaceOrderContainer/PlaceOrderContainer";
import { WishlistContainer } from "./containers/WishlistContainer/WishlistContainer";

export const HealthMarketBaseRoute = "/health-market";
export const routes = {
  root: {
    route: "/",
    component: HealthMarketContainer,
    get absRoute() {
      return HealthMarketBaseRoute;
    },
  },
  products: {
    route: "/products",
    component: ProductsListContainer,
    get absRoute() {
      return `${HealthMarketBaseRoute}${this.route}`;
    },
    getRouteWithParams({ categoryId }: { categoryId: string }) {
      return `${this.absRoute}?categoryId=${categoryId}`;
    },
  },
  product: {
    route: "/product",
    component: ProductContainer,
    get absRoute() {
      return `${HealthMarketBaseRoute}${this.route}`;
    },
    getRouteWithParams({ productId }: { productId: string }) {
      return `${this.absRoute}?productId=${productId}`;
    },
  },
  healthPackages: {
    route: "/health-packages",
    component: HealthPackagesListContainer,
    get absRoute() {
      return `${HealthMarketBaseRoute}${this.route}`;
    },
  },
  healthPackage: {
    route: "/health-package",
    component: HealthPackageContainer,
    get absRoute() {
      return `${HealthMarketBaseRoute}${this.route}`;
    },
    getRouteWithParams({ packageId }: { packageId: string }) {
      return `${this.absRoute}?healthPackageId=${packageId}`;
    },
  },
  cart: {
    route: "/cart",
    component: CartListContainer,
    get absRoute() {
      return `${HealthMarketBaseRoute}${this.route}`;
    },
  },
  wishlist: {
    route: "/wishlist",
    component: WishlistContainer,
    get absRoute() {
      return `${HealthMarketBaseRoute}${this.route}`;
    },
  },
  addressSelection: {
    route: "/address-selection",
    component: AddressSelectionContainer,
    get absRoute() {
      return `${HealthMarketBaseRoute}${this.route}`;
    },
  },
  placeOrder: {
    route: "/place-order",
    component: PlaceOrderContainer,
    get absRoute() {
      return `${HealthMarketBaseRoute}${this.route}`;
    },
  },
  termsAndConditions: {
    route: "/terms-and-conditions",
    component: MarketplaceTermsContainer,
    get absRoute() {
      return `${HealthMarketBaseRoute}${this.route}`;
    },
  },
  checkout: {
    route: "/checkout",
    component: CheckoutContainer,
    get absRoute() {
      return `${HealthMarketBaseRoute}${this.route}`;
    },
    getRouteWithParams({ cartId }: { cartId: string }) {
      return `${this.absRoute}?orderCartId=${cartId}`;
    },
  },
  myOrders: {
    route: "/my-orders",
    component: MyOrdersContainer,
    get absRoute() {
      return `${HealthMarketBaseRoute}${this.route}`;
    },
    getRouteWithParams({ tab }: { tab: MyOrdersTabsEnum }) {
      return `${this.absRoute}?tab=${tab}`;
    },
  },
  ordersDetails: {
    route: "/orders-details",
    get absRoute() {
      return `${HealthMarketBaseRoute}${this.route}`;
    },
    component: OrdersDetailsContainer,
    getRouteWithParams({ orders }: { orders: string | string[] }) {
      const ordersParam = Array.isArray(orders) ? orders.join(",") : orders;
      return `${this.absRoute}?orders=${encodeURIComponent(ordersParam)}`;
    },
  },
};
