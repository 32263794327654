import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMeetingRateMutationVariables = Types.Exact<{
  meetingId: Types.Scalars['ID']['input'];
  meetingRate: Types.Scalars['Int']['input'];
}>;


export type UpdateMeetingRateMutation = { __typename?: 'Mutation', updateMeetingRate?: { __typename?: 'MeetingParticipantJWT', id: string } | null };


export const UpdateMeetingRateDocument = gql`
    mutation UpdateMeetingRate($meetingId: ID!, $meetingRate: Int!) {
  updateMeetingRate(meetingId: $meetingId, meetingRate: $meetingRate) {
    id
  }
}
    `;
export type UpdateMeetingRateMutationFn = Apollo.MutationFunction<UpdateMeetingRateMutation, UpdateMeetingRateMutationVariables>;

/**
 * __useUpdateMeetingRateMutation__
 *
 * To run a mutation, you first call `useUpdateMeetingRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeetingRateMutation, { data, loading, error }] = useUpdateMeetingRateMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      meetingRate: // value for 'meetingRate'
 *   },
 * });
 */
export function useUpdateMeetingRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeetingRateMutation, UpdateMeetingRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeetingRateMutation, UpdateMeetingRateMutationVariables>(UpdateMeetingRateDocument, options);
      }
export type UpdateMeetingRateMutationHookResult = ReturnType<typeof useUpdateMeetingRateMutation>;
export type UpdateMeetingRateMutationResult = Apollo.MutationResult<UpdateMeetingRateMutation>;
export type UpdateMeetingRateMutationOptions = Apollo.BaseMutationOptions<UpdateMeetingRateMutation, UpdateMeetingRateMutationVariables>;