import { useTranslation } from "@toolkit/i18n";
import { FC, MouseEvent } from "react";
import { ConfirmationBaseDialog } from "../ConfirmationBaseDialog/ConfirmationBaseDialog";
import { InfoConfirmationDialogProps } from "../types";

export const InfoConfirmationDialog: FC<InfoConfirmationDialogProps> = props => {
  const { isOpen, onClose, onConfirm, isConfirmButtonDisabled = false, button, classes, ...rest } = props;
  const { t } = useTranslation();
  const onConfirmationClick = (e: MouseEvent) => {
    e.preventDefault();
    onConfirm();
  };

  return (
    <ConfirmationBaseDialog
      button={button}
      isDoButtonDisabled={isConfirmButtonDisabled}
      isOpen={isOpen}
      handleClose={onClose}
      handleDo={onConfirmationClick}
      DoTitle={t("confirm")}
      color='info'
      classes={classes}
      {...rest}
    />
  );
};
