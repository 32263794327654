import { FC } from "react";
import { Box, CustomDialog, ModalCloseIcon, MuiCloudDownloadIcon } from "@toolkit/ui";
import { ChatAttachment, ChatAttachmentInput } from "../../schema/types";
import { IconButton, Typography } from "@toolkit/ui";
import { useStyles } from "./ChatViewMediaPhotoZoom.styles";
import { ChatViewMediaPhoto } from "./ChatViewMediaPhoto";

type ChatViewMediaPhotoZoomProps = {
  media: ChatAttachment | ChatAttachmentInput;
  onClose: () => void;
  onDownload: () => void;
};

export const ChatViewMediaPhotoZoom: FC<ChatViewMediaPhotoZoomProps> = ({ media, onClose, onDownload }) => {
  const { classes, theme } = useStyles();

  return (
    <CustomDialog className={classes.root} type={"base"} onClose={onClose} open DialogTitleProps={{ hidden: true }}>
      <Box className={classes.title}>
        <IconButton sx={{ color: theme.palette.common.white }} onClick={onDownload}>
          <MuiCloudDownloadIcon htmlColor='inherit' fontSize='medium' />
        </IconButton>
        <Typography textAlign='center' color={theme.palette.common.white} flexGrow={1}>
          1 of 1
        </Typography>
        <IconButton sx={{ color: theme.palette.common.white }} onClick={onClose}>
          <ModalCloseIcon sx={{ color: theme.palette.common.white }} htmlColor='inherit' fontSize='small' />
        </IconButton>
      </Box>
      <Box sx={{ maxHeight: "60vh", maxWidth: "60vw" }}>
        <ChatViewMediaPhoto media={media} isZoomDisabled />
      </Box>
    </CustomDialog>
  );
};
