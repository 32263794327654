import { FC } from "react";
import { MarketPlaceCardLayout } from "../MarketplaceCardLayout/MarketplaceCardLayout";
import { Skeleton } from "@toolkit/ui";
import { useHealthPackageSelectedCardStyle } from "../HealthPackageSelectedCard/HealthPackageSelectedCardStyles";

export const MarketplaceSelectedCardSkeleton: FC = () => {
  const { classes } = useHealthPackageSelectedCardStyle();

  return (
    <MarketPlaceCardLayout
      classes={{
        card: classes.card,
        itemAvatarContainer: classes.itemAvatarContainer,
        infoContainer: classes.infoContainer,
      }}
      infoNode={
        <>
          <Skeleton variant='text' width='100%' height={24} className={classes.itemName} />
          <Skeleton variant='text' width='70%' height={18} className={classes.itemDiscretion} />
          <Skeleton variant='rectangular' height='40px' width='100%' sx={{ mt: 1, borderRadius: "5px" }} />
        </>
      }
      actionsNode={
        <>
          <Skeleton variant='rectangular' width={110} height={48} sx={{ borderRadius: "24px" }} />
          <Skeleton variant='rectangular' width={120} height={48} sx={{ borderRadius: "24px" }} />
        </>
      }
      itemAvatarNode={<Skeleton variant='rectangular' width={"100%"} height={"100%"} className={classes.imageRoot} />}
    />
  );
};
