import { makeStyles } from "@toolkit/ui";

export const useTodayFeelingSectionStyles = makeStyles()(theme => ({
  root: {
    marginBlock: theme.spacing(1.2),
    width: "100%",
    paddingInline: theme.spacing(1.7),
    paddingBlock: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  SliderRoot: {
    alignSelf: "center",
    width: "calc(100% - 40px)",
    color: theme.palette.primary[50],
    paddingX: "30px 0",
    "&& .MuiSlider-rail": {
      height: 12,
      opacity: 1,
    },
    "& .MuiSlider-thumb": {
      height: 30,
      width: 30,
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[7],
      "::after": {
        content: "''",
        position: "absolute",
        width: "26px",
        height: "26px",
        borderRadius: "50%",
        backgroundColor: theme.palette.secondary.main,
      },
    },
    "& .MuiSlider-mark": {
      display: "none",
    },
    "& .MuiSlider-track": {
      height: 12,
      border: "none",
    },
  },
  sliderRail: {
    backgroundColor: theme.palette.primary[50],
    opacity: 1,
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingInline: theme.spacing(0.5),
  },
  modeTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingInline: theme.spacing(0.5),
  },
  modeText: {
    textAlign: "center",
    maxWidth: "60px",
    flex: 1,
    ":nth-child(1)": {
      textAlign: "start",
    },
    ":nth-child(2)": {
      textAlign: "start",
      paddingInlineStart: theme.spacing(1),
    },
    ":nth-child(3)": {
      textAlign: "center",
    },
    ":nth-child(4)": {
      textAlign: "end",
      paddingInlineEnd: theme.spacing(0.5),
    },
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "32px",
      height: "32px",
    },
  },
}));
