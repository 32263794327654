import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Wishlist } from "../../components/Wishlist/Wishlist";
import { routes } from "../../routes";
import { useWishlistContainerStyles } from "./WishlistContainerStyles";

export const WishlistContainer: FC = () => {
  const { classes, cx } = useCommonContainerStyles();
  const { classes: customClasses } = useWishlistContainerStyles();

  const { t } = useTranslation("consumer");

  const navigate = useNavigate();

  const handleNavigateToCart = () => {
    navigate(routes.cart.absRoute);
  };

  const handleNavigateToShop = () => {
    navigate(routes.root.absRoute);
  };

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography className={classes.pageTitle}>{t("My Wish List")}</Typography>
        <Wishlist />
      </Box>
      <Box className={cx(classes.footer, customClasses.footer)}>
        <Button onClick={handleNavigateToShop} className={cx(classes.button, customClasses.shopButton)}>
          {t("Continue Shopping")}
        </Button>
        <Button onClick={handleNavigateToCart} variant='outlined' className={cx(classes.button, customClasses.viwCardButton)}>
          {t("View Cart")}
        </Button>
      </Box>
    </Box>
  );
};
