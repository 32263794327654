import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import React from "react";

export const PoorModeIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='icons8-disappointed'
      width='32.488'
      height='32.488'
      viewBox='0 0 32.488 32.488'
      {...props}
    >
      <defs>
        <linearGradient id='linear-gradient' x1='0.151' y1='0.151' x2='0.853' y2='0.853' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#fede00' />
          <stop offset='0.519' stopColor='#fecc00' />
          <stop offset='1' stopColor='#ffb700' />
        </linearGradient>
      </defs>
      <path
        id='Path_28362'
        data-name='Path 28362'
        d='M20.244,36.488A16.244,16.244,0,1,0,4,20.244,16.245,16.245,0,0,0,20.244,36.488Z'
        transform='translate(-4 -4)'
        fill='url(#linear-gradient)'
      />
      <ellipse id='Ellipse_3507' data-name='Ellipse 3507' cx='3' cy='3.5' rx='3' ry='3.5' transform='translate(6.646 11)' fill='#fff' />
      <ellipse id='Ellipse_3508' data-name='Ellipse 3508' cx='3' cy='3.5' rx='3' ry='3.5' transform='translate(19.646 11)' fill='#fff' />
      <ellipse id='Ellipse_3509' data-name='Ellipse 3509' cx='2' cy='1.5' rx='2' ry='1.5' transform='translate(7.646 13)' fill='#343434' />
      <ellipse id='Ellipse_3510' data-name='Ellipse 3510' cx='2' cy='1.5' rx='2' ry='1.5' transform='translate(20.646 13)' fill='#343434' />
      <path
        id='Path_28363'
        data-name='Path 28363'
        d='M28.183,34.778a.805.805,0,0,1-.536-.2,7.77,7.77,0,0,0-10.3,0,.812.812,0,1,1-1.073-1.219,9.393,9.393,0,0,1,12.444,0,.813.813,0,0,1-.537,1.422Z'
        transform='translate(-6.254 -9.071)'
        fill='#343434'
      />
    </SvgIcon>
  );
};
