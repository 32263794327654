import { ConferenceConfiguration } from "@/schema/types";
import { useGetConferenceConfigurationQuery } from "../../gql/queries";
import { useCallback } from "react";

export function useConferenceConfig(vendorId?: number | string | null) {
  const { data, refetch, loading, error } = useGetConferenceConfigurationQuery({
    skip: !vendorId,
    fetchPolicy: "network-only",
    variables: vendorId ? { vendorId: `${vendorId}` } : undefined,
  });

  const handleRetry = useCallback(async () => {
    try {
      await refetch();
    } catch {
      // ignore
    }
  }, [refetch]);

  return { loading, error, handleRetry, data: data?.getConferenceConfiguration as ConferenceConfiguration | undefined };
}
