import { useMarketplaceCartContext, useMarketplaceWishlistContext } from "@/shared/context";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, RemoveIcon } from "@toolkit/ui";
import { FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { WishlistProductItem } from "../../types";
import { calculateDiscountedPrice } from "../../utils";
import { ProductSelectedCard } from "../ProductSelectedCard/ProductSelectedCard";
import { useWishlistProductCardStyle } from "./useWishlistProductCardStyle";

type WishlistProductCardProps = {
  product?: WishlistProductItem;
};

export const WishlistProductCard: FC<WishlistProductCardProps> = props => {
  const { product } = props;
  const { classes } = useWishlistProductCardStyle();
  const navigate = useNavigate();

  const { t } = useTranslation("consumer");

  const { addItemToCart, cartItems, itemsLoadingTrackerMap: cartItemsLoadingTracker } = useMarketplaceCartContext();
  const { removeItem, itemsLoadingTrackerMap } = useMarketplaceWishlistContext();
  const priceInfo = calculateDiscountedPrice(product?.price, product?.activeDiscount);

  const handleAddToCart = () => {
    addItemToCart?.({
      productId: product?.id,
      quantity: 1,
    });
  };

  const handleRemove = () => {
    removeItem(product?.id || "", "product");
  };
  const handleOnClick = () => {
    navigate(routes.product.getRouteWithParams({ productId: product?.id || "" }));
  };

  const handleStopPropagation = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <ProductSelectedCard
      priceInfo={{
        basePrice: priceInfo.basePrice,
        discountedPrice: priceInfo.discountedPrice,
        hasActiveDiscount: priceInfo.hasActiveDiscount,
      }}
      classes={{
        itemAvatarContainer: classes.itemAvatarContainer,
        infoContainer: classes.infoContainer,
      }}
      handleOnClick={handleOnClick}
      product={product}
      actionsNode={
        <Box className={classes.root} onClick={handleStopPropagation}>
          {cartItems?.product?.[product?.id || ""] ? (
            <Box className={classes.addedDisplay}>{t("Added")}</Box>
          ) : (
            <Button
              disabled={cartItemsLoadingTracker?.product?.[product?.id || ""]}
              className={classes.addButton}
              onClick={handleAddToCart}
            >
              {t("Add to Cart")}
            </Button>
          )}
          <Button
            startIcon={<RemoveIcon />}
            variant='outlined'
            className={classes.removeButton}
            disabled={itemsLoadingTrackerMap.product?.[product?.id || ""]}
            onClick={handleRemove}
          >
            {t("Remove")}
          </Button>
        </Box>
      }
    />
  );
};
