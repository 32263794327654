import { Chat, ChatDeleteOption } from "../../schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useCallback, useEffect } from "react";
import { useAddToast } from "@toolkit/ui";
import { useChatDraft, useChats } from "../ChatProvider";
import { useDeleteChatMutation } from "../../gql/mutations";
import { useOpenState } from "@toolkit/core";

type ChatListItemMenuHookProps = {
  chat: Chat;
  isOutgoing: boolean;
  onClose: () => void;
};

export const useChatListItemMenuHook = ({ chat, onClose }: ChatListItemMenuHookProps) => {
  const { t } = useTranslation();
  const { failed, succeeded } = useAddToast();
  const { setChatDeleted } = useChats();
  const { updateDraft } = useChatDraft(null, false);
  const { open: openDeleteConfirmation, handleToggle: handleToggleConfirmDelete } = useOpenState();

  const [deleteChat] = useDeleteChatMutation();

  const handleReply = useCallback(() => {
    updateDraft({
      chat,
      action: "reply",
      repliedChatId: chat.id,
    });
    onClose();
  }, [chat, onClose, updateDraft]);

  const handleEdit = useCallback(() => {
    updateDraft({
      chat,
      action: "edit",
      message: chat.message,
    });
    onClose();
  }, [updateDraft, chat, onClose]);

  const handleCopy = useCallback(async () => {
    try {
      if (chat.message) {
        await navigator.clipboard.writeText(chat.message);
        succeeded(t("Message has been copied to clipboard"));
      }
    } catch (reason) {
      failed(t("Failed to copy Message to clipboard. Please try again later."));
    } finally {
      onClose();
    }
  }, [chat.message, failed, onClose, succeeded, t]);

  const handleDelete = useCallback(
    async (deleteOption: ChatDeleteOption) => {
      try {
        const response = await deleteChat({ variables: { deleteChatId: chat.id, deleteOption } });
        if (response.errors) {
          failed(t("Unable to delete, please retry."));
        } else {
          setChatDeleted(chat);
        }
      } catch {
        failed(t("Unable to delete, please retry."));
      } finally {
        onClose();
        handleToggleConfirmDelete();
      }
    },
    [chat, deleteChat, failed, handleToggleConfirmDelete, onClose, setChatDeleted, t]
  );

  const handleDeleteForYourself = useCallback(() => handleDelete(ChatDeleteOption.DeleteForYourself), [handleDelete]);
  const handleDeleteForEveryone = useCallback(() => handleDelete(ChatDeleteOption.DeleteForEveryone), [handleDelete]);

  useEffect(() => {
    if (openDeleteConfirmation) {
      onClose();
    }
  }, [onClose, openDeleteConfirmation]);

  return {
    handleEdit,
    handleCopy,
    handleDeleteForYourself,
    handleDeleteForEveryone,
    handleReply,
    handleToggleConfirmDelete,
    openDeleteConfirmation,
    enabled: !chat.deleted,
  };
};
