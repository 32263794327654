import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    marginTop: "10px",
  },
  item__button: {
    width: "58px",
    height: "58px",
    "&& svg": {
      width: "25px",
      height: "25px",
    },
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  item__root: {
    width: "100%",
    minWidth: "30px",
    height: "min-content",
  },
  item__title: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    marginTop: "7.5px",
    wordBreak: "break-word",
    overflow: "break-word",
    hyphens: "manual",
  },
}));
