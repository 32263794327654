import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitNewCallStartMutationVariables = Types.Exact<{
  visitId: Types.Scalars['ID']['input'];
}>;


export type VisitNewCallStartMutation = { __typename?: 'Mutation', visitNewCallStart?: { __typename?: 'VisitAction', visitErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const VisitNewCallStartDocument = gql`
    mutation VisitNewCallStart($visitId: ID!) {
  visitNewCallStart(visitId: $visitId) {
    visitErrors {
      code
      field
      message
    }
  }
}
    `;
export type VisitNewCallStartMutationFn = Apollo.MutationFunction<VisitNewCallStartMutation, VisitNewCallStartMutationVariables>;

/**
 * __useVisitNewCallStartMutation__
 *
 * To run a mutation, you first call `useVisitNewCallStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitNewCallStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitNewCallStartMutation, { data, loading, error }] = useVisitNewCallStartMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useVisitNewCallStartMutation(baseOptions?: Apollo.MutationHookOptions<VisitNewCallStartMutation, VisitNewCallStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitNewCallStartMutation, VisitNewCallStartMutationVariables>(VisitNewCallStartDocument, options);
      }
export type VisitNewCallStartMutationHookResult = ReturnType<typeof useVisitNewCallStartMutation>;
export type VisitNewCallStartMutationResult = Apollo.MutationResult<VisitNewCallStartMutation>;
export type VisitNewCallStartMutationOptions = Apollo.BaseMutationOptions<VisitNewCallStartMutation, VisitNewCallStartMutationVariables>;