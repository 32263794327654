import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0",
    boxShadow: theme.mixins.shadows.sm,
    position: "relative",
    gap: "4px",
  },
  cardImageContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "130px",
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
  },
  cardDetailsContainer: {
    width: "100%",
    textAlign: "start",
    paddingInline: "10px",
  },
  healthPackageShortDescription: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    fontWeight: theme.mixins.fonts.fontWeight.normal,
    whiteSpace: "pre-wrap",
  },
  healthPackageCategory: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    color: theme.palette.stale.main,
  },
  healthPackageName: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  healthPackageVendor: {
    width: "100%",
    textAlign: "start",
    color: theme.palette.stale.main,
  },
  cardActionContainer: {
    position: "absolute",
    left: "10px",
    bottom: "10px",
    width: "calc(100% - 20px)",
    height: "45px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    borderTop: "1px solid",
    borderColor: theme.palette.divider,
  },
  priceContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  cardActionButtonsContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
    flexDirection: "row",
    gap: "10px",
    marginInline: "7px",
  },
  actionsButton: {
    width: "35px",
    height: "35px",
    borderRadius: "7px",
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.primary.main,
    padding: "0px",
    "& svg": {
      height: "22px",
      width: "22px",
    },
    "& path": {
      fill: "none",
      stroke: theme.palette.primary.main,
    },
  },
  filledIcon: {
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  price: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.secondary.main,
  },
  currency: {
    position: "relative",
    top: "-3px",
    paddingInlineEnd: "3px",
    display: "inline",
    fontSize: theme.mixins.fonts.fontSize.xxs,
    verticalAlign: "upper",
    color: theme.palette.secondary.main,
  },
  discountPrice: {
    fontSize: theme.mixins.fonts.fontSize.md,
    color: theme.palette.gray[500],
    textDecoration: "line-through",
    display: "inline",
  },
  discountCurrency: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.gray[500],
    display: "inline",
  },
  container__clickable: {
    cursor: "pointer",
  },
}));
