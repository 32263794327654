import { LabeledInputContainer } from "@/shared/components";
import { CustomSlider } from "@/shared/components/Form/Slider/CustomSlider";
import { SliderProps } from "@toolkit/ui";
import React, { FC } from "react";

interface CalculationFormNumericSelectProps extends SliderProps {
  inputLabel: string;
}
export const CalculationFormNumericSelect: FC<CalculationFormNumericSelectProps> = props => {
  const { inputLabel, ...selectProps } = props;

  return (
    <LabeledInputContainer label={inputLabel}>
      <CustomSlider {...selectProps} />
    </LabeledInputContainer>
  );
};
