import { Typography } from "@toolkit/ui";
import { FC } from "react";
import { OrderTrackingViewProps } from "../../others/types";
import { Trans, useTranslation } from "@toolkit/i18n";
import { CustomCard, EmptyMessageLayout } from "@/shared/components";
import { PaymentShareCalculatedIcon } from "@/shared/icons/PaymentShareCalculatedIcon";
import { formatCurrency } from "@/shared/utils";
import { useSiteSettingsGetQuery } from "@/gql";
import { usePatientShareAmountCardStyles } from "./usePatientShareAmountCard.styles";

export const PatientShareAmountCard: FC<OrderTrackingViewProps> = ({ order }) => {
  const { classes } = usePatientShareAmountCardStyles();
  const { t } = useTranslation("consumer");
  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const currency = data?.siteSettings?.defaultCurrency;
  const copayAmount = formatCurrency(order?.totalPaymentAmount || 0, currency);

  return (
    <CustomCard className={classes.root}>
      <EmptyMessageLayout
        classes={{
          root: classes.emptyMessageLayout,
          iconContainer: classes.iconContainer,
          messageContainer: classes.messageContainer,
          subMessageContainer: classes.subMessageContainer,
        }}
        icon={<PaymentShareCalculatedIcon />}
        message={t("Thank you!")}
        subMessage={
          <Trans
            t={t}
            i18nKey='Your copay amount is <secondary>{{amount}}</secondary>'
            components={{
              secondary: <Typography className={classes.secondary} />,
            }}
            values={{
              amount: copayAmount,
            }}
          />
        }
      />
    </CustomCard>
  );
};
