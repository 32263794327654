import { useMarketplaceCartContext, useMarketplaceWishlistContext } from "@/shared/context";
import { useCounter } from "@/shared/hooks";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useCallback } from "react";
import { CartProductItem } from "../../types";

export const useCartProductCardHook = (product: CartProductItem | undefined, cartItemId: string, itemQuantity: number) => {
  const { failed } = useAddToast();
  const { t } = useTranslation("consumer");

  const { itemsLoadingTrackerMap: cartItemsLoadingTracker, removeItemsFromCart, updateItemQuantity } = useMarketplaceCartContext();
  const { addItem, itemsLoadingTrackerMap: wishListLoadingTracker } = useMarketplaceWishlistContext();

  const handleQuantityChange = useCallback(
    (count: number) => {
      if (itemQuantity !== count) {
        updateItemQuantity?.(cartItemId, count)?.then(response => {
          if (response?.errors?.length) {
            failed(t(response?.errors[0]?.message || "Failed to update item quantity"));
          }
        });
      }
    },
    [cartItemId, itemQuantity, updateItemQuantity, t]
  );

  const counter = useCounter({
    initial: { max: product?.stockQuantity || 0, min: 0, count: itemQuantity },
    onCountChange: handleQuantityChange,
  });

  const handleSaveForLater = useCallback(() => {
    addItemToWishlistAndRemoveFromCart();
  }, [addItem, removeItemsFromCart, cartItemId, failed, t, product?.id]);

  const addItemToWishlistAndRemoveFromCart = useCallback(() => {
    addItem(product?.id || "", "product")?.then(response => {
      if (response?.data?.marketplaceWishlistAddItem?.id) {
        removeItemsFromCart?.([cartItemId])?.then(response => {
          if (response?.errors?.length) {
            failed(t(response?.errors[0]?.message || "Failed to save product for later, you can remove it from cart and try again"));
          }
        });
      } else {
        failed(t("Failed to save product for later, you can remove it from cart and try again"));
      }
    });
  }, [addItem, cartItemId, failed, removeItemsFromCart, t, product?.id]);

  return {
    counter,
    cartItemsLoadingTracker,
    wishListLoadingTracker,
    handleSaveForLater,
    handleQuantityChange,
  };
};
