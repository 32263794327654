import { useChatUnreadMessages } from "@health/chat";
import { useCallback, useMemo } from "react";
import { CallInfo } from "../Call/CallContext";
import { useCallParticipants } from "./useCallParticipants";
import { useTranslation } from "@toolkit/i18n";
import { ConferenceProviderType } from "@/schema/types";
import { CallState } from "../Call/types";
import { useAddToast } from "@toolkit/ui";

export type CallInstance = ReturnType<typeof useCall>;
export function useCall({ callInfo, user }: { callInfo?: CallInfo; user?: { userId?: number; userName?: string } }) {
  const { t } = useTranslation();
  const { userId, userName } = user || {};
  const { meeting } = callInfo || {};
  const { id: callId } = meeting || {};
  const { currentParticipant, participants } = useCallParticipants({
    userId: userId,
    displayName: userName,
    meeting,
  });
  const { unreadCount } = useChatUnreadMessages(meeting?.chatGroup?.id);
  const { succeeded } = useAddToast();

  const joinCall = useCallback(() => {
    succeeded(t("The consultation is live. Open Sanar Virtual Consultations to join."));
  }, [succeeded, t]);

  return useMemo(() => {
    return {
      callId,
      callType: ConferenceProviderType.Sanar,
      callInfo: meeting,
      callInvitationToken: "",
      visitId: meeting?.chatGroup?.visitId,
      title: meeting?.chatGroup?.name || t("Meeting"),
      unreadMessagesCount: unreadCount,
      participants,
      currentParticipant,
      state: {
        ...currentParticipant.state,
        callState: CallState.NoCall,
        speakerMuted: false,
        speakerOn: true,
      },
      flipCamera: undefined,
      muteAudio: undefined,
      muteVideo: undefined,
      muteScreen: undefined,
      muteSpeaker: undefined,
      setSpeaker: undefined,
      joinCall,
      dropCall: undefined,
      updateCallStatus: undefined,
      resetCallState: undefined,
    };
  }, [callId, meeting, t, unreadCount, participants, currentParticipant, joinCall]);
}
