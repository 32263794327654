import { FC, useState } from "react";
import { Avatar, AvatarProps, HealthServicesIcon, OrderPharmacyIcon } from "@toolkit/ui";
import { useTranslation } from "@/i18n/i18n.config";
import { useMarketplaceItemAvatarStyle } from "./MarketplaceItemAvatarStyles";
import { marketplaceItemType } from "../../types";

interface MarketplaceItemAvatarProps extends Omit<AvatarProps, "src" | "classes"> {
  imageUrl?: string | null;
  itemName?: string;
  marketplaceItemType?: marketplaceItemType;
  classes?: Partial<ReturnType<typeof useMarketplaceItemAvatarStyle>["classes"]>;
}

export const MarketplaceItemAvatar: FC<MarketplaceItemAvatarProps> = props => {
  const { marketplaceItemType, imageUrl, itemName, className, classes: _classes, ...rest } = props;
  const { t } = useTranslation();
  const { classes, cx } = useMarketplaceItemAvatarStyle();
  const imgTitle = itemName ? t("marketplace-item-name", { name: itemName }) : t("Marketplace Item");
  const [isCorrupted, setIsCorrupted] = useState(false);

  const handleOnError = () => {
    setIsCorrupted(true);
  };

  return (
    <Avatar
      className={cx(
        classes.root,
        marketplaceItemType === "product" && classes.productRoot,
        marketplaceItemType === "healthPackage" && classes.healthPackageRoot,
        _classes?.root,
        _classes?.productRoot,
        _classes?.healthPackageRoot,
        className
      )}
      src={imageUrl || ""}
      title={imgTitle}
      onError={handleOnError}
      classes={{
        fallback: cx(classes.fallback),
        img: cx(classes.img, _classes?.img),
      }}
      {...rest}
    >
      {(isCorrupted || !imageUrl) && (marketplaceItemType === "product" ? <OrderPharmacyIcon /> : <HealthServicesIcon />)}
    </Avatar>
  );
};
