import { getPatientId } from "@/domains/patient";
import { useContainerHeight } from "@/shared/hooks";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { get } from "lodash";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { usePatientHealthDocumentDeleteMutation } from "../../gql";
import { usePatientMedicalProfileDocumentsQuery } from "../../gql/queries";
import { routes } from "../../routes";

export const useDocumentsHook = () => {
  const { t } = useTranslation("consumer");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const { user } = useAuth();

  const patientId = getPatientId(get(user?.profile, "patient_id") as string)!;
  const { data, loading: isLoading } = usePatientMedicalProfileDocumentsQuery({
    variables: {
      patientId: patientId,
    },
    skip: !patientId,
  });
  const healthDocuments = data?.patientMedicalProfile?.healthDocuments?.edges?.map(edge => edge?.node) || [];

  const { containerRef, containerHeight } = useContainerHeight();
  const [patientHealthDocumentDeleteMutation, { loading: isDeleteLoading }] = usePatientHealthDocumentDeleteMutation({
    onCompleted: () => {
      succeeded(t("Document deleted successfully"));
    },
    onError: () => {
      failed(t("Failed to delete document"));
    },
    update: (cache, { data }) => {
      const normalizedId = cache.identify({ id: data?.PatientHealthDocumentDelete?.id, __typename: "PatientHealthDocument" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });
  const onGoToUploadDocument = () => navigate(routes.documentUpload.absRoute);
  const handleDeleteHealthDocument = (id: string) => () => {
    patientHealthDocumentDeleteMutation({
      variables: {
        patientHealthDocumentDeleteId: id,
      },
    });
  };

  return { healthDocuments, isLoading, containerRef, containerHeight, isDeleteLoading, handleDeleteHealthDocument, onGoToUploadDocument };
};
