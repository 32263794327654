import { usePatientShareQuery, useSiteSettingsQuery } from "@/domains/siteSettings/gql";
import { VisitCareType } from "@/schema/types";

type PaymentSummaryProps = {
  doctorId?: string;
  priceBeforeVat: number;
  priceAfterVat?: number;
  totalVat?: number;
  totalDiscount?: number;
  totalDelivery?: number;
};

const usePaymentSummary = (props: PaymentSummaryProps) => {
  const { doctorId, totalDiscount, totalDelivery, priceBeforeVat, priceAfterVat, totalVat } = props;

  const { data, loading } = useSiteSettingsQuery();

  const currency = data?.siteSettings?.defaultCurrency;
  const vatPercentage = data?.siteSettings?.vatPercentageForMedicalServices ?? 0;

  const { data: patientShareData, loading: patientShareLoading } = usePatientShareQuery({
    variables: {
      doctorId: doctorId!,
      visitCareType: VisitCareType.Virtual,
    },
    skip: !doctorId,
  });

  const isLoading = loading || patientShareLoading;

  const patientShare = patientShareData?.patientShare?.patientShare;
  const coverage = patientShareData?.patientShare?.coverage;

  const subtotal = doctorId ? patientShare! : priceBeforeVat;

  const includeVat = priceAfterVat || vatPercentage > 0;

  let taxes = 0;

  if (includeVat) {
    taxes = (Number(subtotal) * Number(vatPercentage)) / 100;
  }

  if (totalVat) {
    taxes = Number(totalVat);
  }

  if (!totalVat && priceAfterVat) {
    taxes = Number(priceAfterVat) - Number(subtotal);
  }

  const total = priceAfterVat || Number(subtotal) + taxes;

  const showOnlyTotal = !doctorId && !includeVat && !taxes && subtotal === total;

  return {
    subtotal: subtotal,
    isLoading,
    coverage,
    currency,
    total,
    includeVat,
    taxes,
    vatPercentage,
    patientShare,
    showOnlyTotal,
    totalDiscount,
    totalDelivery,
  };
};

export type PaymentSummaryReturnType = ReturnType<typeof usePaymentSummary>;
export default usePaymentSummary;
