import { zodEnumSchema } from "@health/enum-options";
import moment from "moment";
import { z } from "zod";

export const CreateDependentFormSchema = z.object({
  firstName: z.string().min(3).max(50),
  lastName: z.string().min(3).max(50),
  photo: z.string().optional(),
  dateOfBirth: z.string().refine(value => {
    return value ? moment(value, "YYYY-MM-DD", true).isValid() : true;
  }),
  relationType: z.string(),
  gender: zodEnumSchema.userGender,
});

export type ICreateDependentFormValues = z.infer<typeof CreateDependentFormSchema>;
export const createDependentFormSchemaDefaultValues: Partial<ICreateDependentFormValues> = {
  firstName: "",
  lastName: "",
  photo: "",
  dateOfBirth: "",
  relationType: "",
};
