import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Visit } from "@/schema/types";
import { Box, Button, CircularProgress, Typography } from "@toolkit/ui";
import { CallVisitStatusTitle } from "./CallVisitStatusTitle";
import { useStyles } from "./CallVisitStatus.styles";
import { CallInstance } from "./types";
import { ChatAvatar } from "@health/chat";
import { useVisitRejoinCall } from "./useVisitRejoinCall";

type CallVisitStatusProps = {
  call: CallInstance;
  visit?: Visit;
  onClose?: () => void;
};

export const CallVisitStatus: FC<CallVisitStatusProps> = ({ call, visit, onClose }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { isCallRejoinable, handleRejoinCall, loading } = useVisitRejoinCall(call, visit);
  return (
    <Box className={classes.root}>
      <Box className={classes.content} sx={{ width: 320 }}>
        <ChatAvatar value={call.title} size={120} />

        <Box>
          <Typography className={classes.title}>
            <CallVisitStatusTitle visit={visit} />
          </Typography>
          <Typography>{call.title}</Typography>
        </Box>

        <Box>
          {isCallRejoinable && (
            <Button disabled={loading} onClick={handleRejoinCall} color='secondary' sx={{ minWidth: 100 }}>
              {loading ? <CircularProgress size={24} /> : t("Rejoin")}
            </Button>
          )}
          {onClose && (
            <Button disabled={loading} onClick={onClose} sx={{ minWidth: 100 }}>
              {t("Close")}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
