import { LinearProgress, styled } from "@toolkit/ui";
type RatingLinearProgressStyledProps = {
  customColor?: string;
};

export const RatingLinearProgress = styled(LinearProgress)<RatingLinearProgressStyledProps>(({ theme, customColor }) => {
  return {
    height: 6,
    borderRadius: 15,
    backgroundColor: theme.palette.gray.light,
    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.yellow.main || customColor,
      borderRadius: 15,
    },
    width: "100%",
  };
});
