import { useTranslation } from "@/i18n/i18n.config";
import { GuidedCareJourney } from "@/schema/types";
import { Card, CardContent, CircularProgress, formatDate, Grid, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useStyles } from "./CareJourneyDetailsStyle";

export const CareJourneyDetailsHeader: FC<{ isLoading: boolean; careJourney: GuidedCareJourney }> = ({ isLoading, careJourney }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { classes } = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent sx={{ paddingBottom: "16px !important" }}>
        {isLoading ? (
          <Grid container justifyContent='center'>
            <CircularProgress size={24} />
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={6} className={classes.grid}>
              <Typography className={classes.label}>{t("Program")}</Typography>
              <Typography>{careJourney?.guidedCareHealthProgram?.name || "-"}</Typography>
            </Grid>

            <Grid item xs={6} className={classes.grid}>
              <Typography className={classes.label}>{t("Insurance")}</Typography>
              <Typography>{careJourney?.guidedCareHealthProgram?.insuranceNetworks?.[0]?.name || "-"}</Typography>
            </Grid>

            <Grid item xs={6} className={classes.grid}>
              <Typography className={classes.label}>{t("Start Date")}</Typography>
              {careJourney?.startDate && <Typography>{formatDate(careJourney?.startDate, lang)}</Typography>}
            </Grid>

            <Grid item xs={6} className={classes.grid}>
              <Typography className={classes.label}>{t("End Date")}</Typography>
              {careJourney?.endDate && <Typography>{formatDate(careJourney?.endDate, lang)}</Typography>}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
