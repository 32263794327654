import { Box } from "@toolkit/ui";
import moment from "moment";
import { FC, useMemo } from "react";
import { ActivitiesProgressCircle } from "../ActivitiesProgressCircle/ActivitiesProgressCircle";
import { useActivitiesGoalsStyles } from "./ActivitiesGoalsStyles";
import { ActivityItemCodesEnum } from "../../types";
import { ClockIcon, FireIcon, StepIcon } from "@/shared/icons";
import { CaloriesTarget } from "../CaloriesTarget/CaloriesTarget";

export type ActivitiesGoalsProps = {
  patientId: string;
  sizeProgressStep?: number;
  sizeProgressTime?: number;
  sizeProgressTimeCalories?: number;
  date?: string;
};

export const ActivitiesGoals: FC<ActivitiesGoalsProps> = props => {
  const { classes, theme } = useActivitiesGoalsStyles();
  const { patientId, sizeProgressStep = 170, sizeProgressTime = 140, sizeProgressTimeCalories = 110, date: selectedDate } = props;

  const date = useMemo(() => selectedDate || moment().format("YYYY-MM-DD"), [selectedDate]);

  return (
    <Box className={classes.root}>
      <Box className={classes.circleContainer}>
        <ActivitiesProgressCircle
          size={sizeProgressStep}
          thickness={3}
          color={theme.palette.primary.main}
          activityCode={ActivityItemCodesEnum.Daily_Step_Count}
          patientId={patientId}
          date={date}
          icon={<StepIcon fill='primary' />}
        />

        <Box className={classes.nestedCircles} sx={{ width: sizeProgressTime, height: sizeProgressTime }}>
          <ActivitiesProgressCircle
            size={sizeProgressTime}
            thickness={3.8}
            color={theme.palette.success.main}
            activityCode={ActivityItemCodesEnum.Active_Time}
            patientId={patientId}
            date={date}
            icon={<ClockIcon fill={theme.palette.success.main} />}
          />

          <Box className={classes.nestedCircles} sx={{ width: sizeProgressTimeCalories, height: sizeProgressTimeCalories }}>
            <ActivitiesProgressCircle
              size={sizeProgressTimeCalories}
              thickness={5}
              color={theme.palette.warning.main}
              activityCode={ActivityItemCodesEnum.Daily_Calories_Burned}
              patientId={patientId}
              date={date}
              icon={<FireIcon fill={theme.palette.warning.main} />}
            />
          </Box>
        </Box>

        <Box className={classes.targetWeight}>
          <CaloriesTarget patientId={patientId} date={date} />
        </Box>
      </Box>
    </Box>
  );
};
