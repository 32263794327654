import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const MedicationIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props?.fill || theme.palette.primary.main;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='170.496' height='168.491' viewBox='0 0 170.496 168.491' {...props}>
      <g id='Medications' transform='translate(-65.279 -66.452)'>
        <g id='Group_11182' data-name='Group 11182' transform='translate(65.279 66.452)'>
          <path
            id='Path_12746_00000008846741855670481910000015707548724329931168_'
            d='M150.2,231.526c-19.551-4.422-37.2-11.4-53.454-23.818-16.1-12.374-30.878-30.063-31.46-48.178-.388-18.077,13.305-36.5,28.24-50.971a149.367,149.367,0,0,1,49.963-33.05c19.24-7.952,42.243-13.15,57.954-4.81,15.633,8.418,23.973,30.373,28.667,50.7,4.771,20.288,5.857,38.946,2.366,58.342s-11.6,39.373-26.417,48.489c-14.818,9.232-36.27,7.525-55.859,3.3'
            transform='translate(-65.279 -66.452)'
            fill='#283275'
            opacity='0.09'
          />
        </g>
        <g id='Group_11186' data-name='Group 11186' transform='translate(93.488 67.945)'>
          <g id='Sleep' transform='translate(0 0)'>
            <path
              id='Path_13009'
              data-name='Path 13009'
              d='M472.5,279.719a5.811,5.811,0,0,1,5.819,5.819h0a5.811,5.811,0,0,1,5.819-5.819h0a5.811,5.811,0,0,1-5.819-5.819A5.811,5.811,0,0,1,472.5,279.719Z'
              transform='translate(-342.744 -194.921)'
              fill='#fff'
            />
            <path
              id='Path_13010'
              data-name='Path 13010'
              d='M335.1,81.937a11.622,11.622,0,0,1,11.637,11.637,11.622,11.622,0,0,1,11.637-11.637A11.622,11.622,0,0,1,346.737,70.3,11.647,11.647,0,0,1,335.1,81.937Z'
              transform='translate(-258.643 -70.3)'
              fill='#fff'
            />
            <path
              id='Path_13011'
              data-name='Path 13011'
              d='M273.318,173.173V263.6a10.5,10.5,0,0,1-10.512,10.512H172.112A10.5,10.5,0,0,1,161.6,263.6V173.212A10.5,10.5,0,0,1,172.112,162.7h90.732A10.43,10.43,0,0,1,273.318,173.173Z'
              transform='translate(-152.445 -126.857)'
              fill='#fff'
            />
            <path
              id='Path_13012'
              data-name='Path 13012'
              d='M260.97,273.335H170.276A11.688,11.688,0,0,1,158.6,261.659V171.276A11.688,11.688,0,0,1,170.276,159.6h90.732a11.688,11.688,0,0,1,11.676,11.676v90.383A11.721,11.721,0,0,1,260.97,273.335ZM170.276,161.889a9.354,9.354,0,0,0-9.349,9.349v90.422a9.354,9.354,0,0,0,9.349,9.349h90.732a9.354,9.354,0,0,0,9.349-9.349V171.276a9.354,9.354,0,0,0-9.349-9.349Z'
              transform='translate(-150.609 -124.96)'
              fill='#525682'
            />
            <rect
              id='Rectangle_7045'
              data-name='Rectangle 7045'
              width='2.327'
              height='91.547'
              transform='translate(63.85 46.472)'
              fill='#525682'
            />
            <g id='Group_11183' data-name='Group 11183' transform='translate(19.51 55.82)'>
              <path
                id='Path_13013'
                data-name='Path 13013'
                d='M206.335,227.156h-11.4a6.48,6.48,0,0,1-.31-12.956h11.715Z'
                transform='translate(-188.296 -214.2)'
                fill='#d3d9ed'
              />
              <path
                id='Path_13014'
                data-name='Path 13014'
                d='M347.935,227.156h-11.4a6.48,6.48,0,1,1-.31-12.956h11.715Z'
                transform='translate(-274.968 -214.2)'
                fill='#d3d9ed'
              />
              <path
                id='Path_13015'
                data-name='Path 13015'
                d='M206.335,301.856h-11.4a6.48,6.48,0,1,1-.31-12.956h11.715Z'
                transform='translate(-188.296 -259.923)'
                fill='#d3d9ed'
              />
              <path
                id='Path_13016'
                data-name='Path 13016'
                d='M347.935,301.856h-11.4a6.48,6.48,0,1,1-.31-12.956h11.715Z'
                transform='translate(-274.968 -259.923)'
                fill='#d3d9ed'
              />
              <path
                id='Path_13017'
                data-name='Path 13017'
                d='M206.335,376.556h-11.4a6.48,6.48,0,0,1-.31-12.956h11.715Z'
                transform='translate(-188.296 -305.646)'
                fill='#d3d9ed'
              />
              <path
                id='Path_13018'
                data-name='Path 13018'
                d='M347.935,376.556h-11.4a6.48,6.48,0,0,1-.31-12.956h11.715Z'
                transform='translate(-274.968 -305.646)'
                fill='#d3d9ed'
              />
            </g>
            <g id='Group_11184' data-name='Group 11184' transform='translate(37.55 55.819)'>
              <path
                id='Path_13019'
                data-name='Path 13019'
                d='M246.2,227.156H234.8V214.2h11.4a6.48,6.48,0,0,1,0,12.956Z'
                transform='translate(-234.8 -214.198)'
                fill={fill}
              />
              <path
                id='Path_13020'
                data-name='Path 13020'
                d='M387.8,227.154H376.4V214.2h11.4a6.48,6.48,0,1,1,.31,12.956Z'
                transform='translate(-321.472 -214.196)'
                fill={fill}
              />
              <path
                id='Path_13021'
                data-name='Path 13021'
                d='M246.2,301.856H234.8V288.9h11.4a6.48,6.48,0,0,1,0,12.956Z'
                transform='translate(-234.8 -259.922)'
                fill={fill}
              />
              <path
                id='Path_13022'
                data-name='Path 13022'
                d='M387.8,301.854H376.4V288.9h11.4a6.48,6.48,0,1,1,.31,12.956Z'
                transform='translate(-321.472 -259.919)'
                fill={fill}
              />
              <path
                id='Path_13023'
                data-name='Path 13023'
                d='M246.2,376.495H234.8V363.5h11.4a6.5,6.5,0,0,1,0,12.995Z'
                transform='translate(-234.8 -305.583)'
                fill={fill}
              />
              <path
                id='Path_13024'
                data-name='Path 13024'
                d='M387.8,376.493H376.4V363.5h11.4a6.48,6.48,0,1,1,.31,12.956A.757.757,0,0,1,387.8,376.493Z'
                transform='translate(-321.472 -305.581)'
                fill={fill}
              />
            </g>
            <g id='Group_11185' data-name='Group 11185' transform='translate(18.306 54.618)'>
              <path
                id='Path_13025'
                data-name='Path 13025'
                d='M205.6,226.422H193.031a7.663,7.663,0,0,1-.349-15.322H205.6Zm-12.568-12.956a5.336,5.336,0,0,0-.271,10.668h10.512V213.505H193.031Z'
                transform='translate(-185.192 -211.1)'
                fill='#525682'
              />
              <path
                id='Path_13026'
                data-name='Path 13026'
                d='M244.368,226.484H231.8V211.2h12.568a7.643,7.643,0,0,1,0,15.284Zm-10.241-2.327h10.241a5.335,5.335,0,0,0,0-10.668H234.127Z'
                transform='translate(-213.721 -211.161)'
                fill='#525682'
              />
              <path
                id='Path_13027'
                data-name='Path 13027'
                d='M347.2,226.422H334.631a7.663,7.663,0,1,1-.349-15.322H347.2Zm-12.568-12.956a5.336,5.336,0,0,0-.271,10.668h10.512V213.505H334.631Z'
                transform='translate(-271.864 -211.1)'
                fill='#525682'
              />
              <path
                id='Path_13028'
                data-name='Path 13028'
                d='M385.968,226.482H373.4V211.2h12.568a7.663,7.663,0,0,1,.349,15.322,1.074,1.074,0,0,0-.349-.039Zm-10.241-2.327h10.241a5.336,5.336,0,0,0,.272-10.668H375.727Z'
                transform='translate(-300.392 -211.159)'
                fill='#525682'
              />
              <path
                id='Path_13029'
                data-name='Path 13029'
                d='M205.6,301.122H193.034a7.663,7.663,0,0,1-.349-15.322H205.6Zm-12.568-12.956a5.335,5.335,0,1,0-.272,10.668h10.512V288.166Z'
                transform='translate(-185.195 -256.823)'
                fill='#525682'
              />
              <path
                id='Path_13030'
                data-name='Path 13030'
                d='M244.368,301.184H231.8V285.9h12.568a7.643,7.643,0,0,1,0,15.284Zm-10.241-2.327h10.241a5.335,5.335,0,0,0,0-10.668H234.127Z'
                transform='translate(-213.721 -256.884)'
                fill='#525682'
              />
              <path
                id='Path_13031'
                data-name='Path 13031'
                d='M347.2,301.122H334.634a7.663,7.663,0,0,1-.349-15.322H347.2Zm-12.568-12.956a5.335,5.335,0,1,0-.272,10.668h10.512V288.166Z'
                transform='translate(-271.867 -256.823)'
                fill='#525682'
              />
              <path
                id='Path_13032'
                data-name='Path 13032'
                d='M385.968,301.182H373.4V285.9h12.568a7.663,7.663,0,0,1,.349,15.322,1.072,1.072,0,0,0-.349-.039Zm-10.241-2.327h10.241a5.335,5.335,0,0,0,.272-10.668H375.727Z'
                transform='translate(-300.392 -256.882)'
                fill='#525682'
              />
              <path
                id='Path_13033'
                data-name='Path 13033'
                d='M205.6,375.822H193.034a7.663,7.663,0,0,1-.349-15.322H205.6Zm-12.568-12.995a5.335,5.335,0,1,0-.272,10.668h10.512V362.827Z'
                transform='translate(-185.195 -302.546)'
                fill='#525682'
              />
              <path
                id='Path_13034'
                data-name='Path 13034'
                d='M244.368,375.822H231.8V360.5h12.568a7.663,7.663,0,0,1,0,15.322Zm-10.241-2.327h10.241a5.335,5.335,0,0,0,0-10.668H234.127Z'
                transform='translate(-213.721 -302.546)'
                fill='#525682'
              />
              <path
                id='Path_13035'
                data-name='Path 13035'
                d='M347.2,375.822H334.634a7.663,7.663,0,0,1-.349-15.322H347.2Zm-12.568-12.995a5.335,5.335,0,1,0-.272,10.668h10.512V362.827Z'
                transform='translate(-271.867 -302.546)'
                fill='#525682'
              />
              <path
                id='Path_13036'
                data-name='Path 13036'
                d='M385.968,375.82H373.4V360.5h12.568a7.663,7.663,0,0,1,.349,15.322Zm-10.241-2.327h10.241a5.336,5.336,0,0,0,.272-10.668H375.727Z'
                transform='translate(-300.392 -302.544)'
                fill='#525682'
              />
            </g>
            <path
              id='Path_13037'
              data-name='Path 13037'
              d='M140.327,166.64H138v-2.327h2.327Zm0-6.207H138v-2.327h2.327Zm.349-5.935-2.25-.465c.155-.815.388-1.59.621-2.405l2.211.737A13.331,13.331,0,0,0,140.677,154.5Zm2.056-5.392-2.017-1.2a23.973,23.973,0,0,1,1.4-2.056l1.823,1.435c-.427.582-.815,1.2-1.2,1.823Zm3.763-4.422-1.513-1.784a18.709,18.709,0,0,1,1.978-1.474L148.2,143.4a17.8,17.8,0,0,0-1.707,1.28Zm5-2.871-.815-2.172a16.575,16.575,0,0,1,2.366-.7l.543,2.25a14.277,14.277,0,0,0-2.095.621Zm5.664-1.086-.039-2.327h2.327v2.327Zm33.36,0H188.2V138.4h2.327Zm-6.207,0h-2.327V138.4h2.327Zm-6.207,0h-2.327V138.4h2.327Zm-6.207,0h-2.327V138.4H171.9Zm-6.207,0h-2.327V138.4H165.7Z'
              transform='translate(-138 -111.983)'
              fill='#aab5d3'
            />
            <path
              id='Path_13038'
              data-name='Path 13038'
              d='M482.5,421.152a4.376,4.376,0,1,1,.039,3.375A4.39,4.39,0,0,1,482.5,421.152Zm2.172.853a2.053,2.053,0,1,0,2.677-1.125h0a2.057,2.057,0,0,0-2.677,1.125Z'
              transform='translate(-348.673 -283.366)'
              fill='#535783'
            />
            <path
              id='Path_13039'
              data-name='Path 13039'
              d='M431.971,472.332a3.4,3.4,0,1,1,6.245-2.677,3.536,3.536,0,0,1,.039,2.6,3.353,3.353,0,0,1-4.422,1.862A3.243,3.243,0,0,1,431.971,472.332Zm3.1-2.405a1.067,1.067,0,1,0,1.086,1.047,1.146,1.146,0,0,0-.078-.388A1.017,1.017,0,0,0,435.074,469.927Z'
              transform='translate(-317.77 -313.482)'
              fill='#535783'
            />
          </g>
        </g>
        <g id='Group_11187' data-name='Group 11187' transform='translate(210.831 90.793)'>
          <path
            id='Path_13040'
            data-name='Path 13040'
            d='M461.766,142.9a1.054,1.054,0,0,0-1.513,0l-3.258,3.258a1.07,1.07,0,0,0,1.513,1.513l3.258-3.258A1.054,1.054,0,0,0,461.766,142.9Z'
            transform='translate(-450.401 -137.387)'
            fill='#535783'
          />
          <path
            id='Path_13041'
            data-name='Path 13041'
            d='M441.547,129.2a1.043,1.043,0,0,0-1.047,1.047v4.577a1.047,1.047,0,1,0,2.095,0v-4.539A1.052,1.052,0,0,0,441.547,129.2Z'
            transform='translate(-440.5 -129.2)'
            fill='#535783'
          />
          <path
            id='Path_13042'
            data-name='Path 13042'
            d='M472.825,166.2h-4.577a1.047,1.047,0,0,0,0,2.095h4.577a1.047,1.047,0,1,0,0-2.095Z'
            transform='translate(-456.843 -151.847)'
            fill='#535783'
          />
        </g>
      </g>
    </SvgIcon>
  );
};
