import { GenericFilterForm } from "@/shared/components";
import { MarketplaceBrandsAutocomplete } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import {
  IProductsListFiltersFormValues,
  productsListFiltersFormDefaultValues,
  ProductsListFiltersFormSchema,
} from "../ProductsListFiltersFormSchema";
import { ProductsListFiltersFormProps } from "../types";

export const BrandFilterForm: React.FC<ProductsListFiltersFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IProductsListFiltersFormValues>
      schema={ProductsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='brands'
      dialogTitle={t("Brands")}
      formLabel={t("Brands")}
      render={name => <MarketplaceBrandsAutocomplete name={name} multiple />}
      defaultValues={productsListFiltersFormDefaultValues}
    />
  );
};
