import { useEffect, useRef, useState } from "react";
import { CallParticipant, ExecutedFailure } from "./types";
import { useCanvasDimension } from "./useCanvasDimension";
import { useZoom } from "./useZoom";
import { makeStyles } from "@toolkit/ui";
import { useUser } from "./useUser";

export type ZoomViewScreenProps = {
  participant: CallParticipant;
};

const useStyles = makeStyles()({
  root: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
});

export const ZoomViewScreen = ({ participant }: ZoomViewScreenProps) => {
  const { classes } = useStyles();
  const { mediaStream, activeShare } = useZoom();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { width, height } = useCanvasDimension(mediaStream, canvasRef);
  const [isRendered, setIsRendered] = useState(false);
  const { userId, sharerOn } = useUser(participant);
  const screenMuted = !sharerOn || (activeShare && (activeShare.userId !== userId || activeShare.state !== "Active"));

  useEffect(() => {
    if (!userId || !mediaStream || !canvasRef.current || screenMuted) {
      return;
    }

    const canvasElement = canvasRef.current;
    const renderScreen = async () => {
      try {
        await mediaStream.startShareView(canvasElement, userId);
        setIsRendered(true);
      } catch (error: unknown) {
        if ((error as ExecutedFailure).type === "IMPROPER_MEETING_STATE") {
          return;
        }

        console.error("Meeting: Unable to start share view due to the error", error);
      }
    };

    const stopRenderScreen = async () => {
      try {
        await mediaStream.stopShareView();
      } catch (error: unknown) {
        if ((error as ExecutedFailure).type === "IMPROPER_MEETING_STATE") {
          return;
        }

        console.error("Meeting: Unable to stop share view due to the error", error);
      }
    };

    setIsRendered(false);
    renderScreen();
    return () => {
      setIsRendered(false);
      stopRenderScreen();
    };
  }, [mediaStream, screenMuted, userId]);

  useEffect(() => {
    if (!mediaStream || !isRendered) {
      return;
    }

    const timeoutId = setTimeout(() => mediaStream.updateSharingCanvasDimension(width, height), 0);
    return () => clearTimeout(timeoutId);
  }, [width, height, mediaStream, isRendered]);

  return <canvas className={classes.root} ref={canvasRef} />;
};
