import { useState } from "react";
import { useMarketplaceCartContext } from "@/shared/context";
import { MarketplaceOrderInfo } from "../../others/types";
import { useNavigate } from "react-router-dom";
import { routes } from "@/domains/marketplace/routes";
import { useConsumerMarketplaceOrderCancelMutation } from "../../gql/mutations";
import { useOpenState } from "@toolkit/core";
import { ConsumerMarketplaceOrdersDocument } from "../../gql/queries";

export const useMarketplaceOrderCard = (order: MarketplaceOrderInfo) => {
  const navigate = useNavigate();
  const [cancellationReason, setCancellationReason] = useState("");
  const { addItemToCart, itemsLoadingTrackerMap } = useMarketplaceCartContext();
  const reorderDialog = useOpenState();
  const cancelDialog = useOpenState();

  const canOrderAgain = order?.orderLines?.every(
    orderLine =>
      (orderLine?.product?.stockQuantity && orderLine?.product?.stockQuantity >= orderLine.quantity && orderLine.product?.isPublished) ||
      orderLine?.healthPackage?.id
  );

  const handleOrderAgain = async () => {
    const items = order.orderLines.map(orderLine => ({
      productId: orderLine.product?.id,
      healthPackageId: orderLine.healthPackage?.id,
      quantity: orderLine.quantity,
    }));

    const results = await Promise.allSettled(items.map(item => addItemToCart?.(item)));

    const isAtLeastOneAdded = results.some(result => result.status === "fulfilled" && result.value?.data?.marketplaceCartAddItem?.id);

    if (isAtLeastOneAdded) {
      navigate(routes.cart.absRoute);
    }
  };

  const isOrderAgainInProgress = order.orderLines.some(
    orderLine =>
      itemsLoadingTrackerMap?.product?.[orderLine.product?.id || ""] ||
      itemsLoadingTrackerMap?.healthPackage?.[orderLine.healthPackage?.id || ""]
  );

  const [cancelOrder, { loading: isCancelling }] = useConsumerMarketplaceOrderCancelMutation({
    refetchQueries: [ConsumerMarketplaceOrdersDocument],
    onCompleted: () => {
      cancelDialog.handleClose();
    },
  });

  const handleCancelOrder = async () => {
    return cancelOrder({
      variables: {
        marketplaceOrderCancelId: order.id,
        cancellationReason,
      },
    });
  };

  return {
    canOrderAgain,
    handleOrderAgain,
    isOrderAgainInProgress,
    handleCancelOrder,
    isCancelling,
    cancellationReason,
    setCancellationReason,
    reorderDialog,
    cancelDialog,
  };
};
