import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceCartClearMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type MarketplaceCartClearMutation = { __typename?: 'Mutation', marketplaceCartClear?: { __typename?: 'MarketplaceCart', id: string } | null };


export const MarketplaceCartClearDocument = gql`
    mutation MarketplaceCartClear {
  marketplaceCartClear {
    id
  }
}
    `;
export type MarketplaceCartClearMutationFn = Apollo.MutationFunction<MarketplaceCartClearMutation, MarketplaceCartClearMutationVariables>;

/**
 * __useMarketplaceCartClearMutation__
 *
 * To run a mutation, you first call `useMarketplaceCartClearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceCartClearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceCartClearMutation, { data, loading, error }] = useMarketplaceCartClearMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarketplaceCartClearMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceCartClearMutation, MarketplaceCartClearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceCartClearMutation, MarketplaceCartClearMutationVariables>(MarketplaceCartClearDocument, options);
      }
export type MarketplaceCartClearMutationHookResult = ReturnType<typeof useMarketplaceCartClearMutation>;
export type MarketplaceCartClearMutationResult = Apollo.MutationResult<MarketplaceCartClearMutation>;
export type MarketplaceCartClearMutationOptions = Apollo.BaseMutationOptions<MarketplaceCartClearMutation, MarketplaceCartClearMutationVariables>;