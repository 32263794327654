import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TurnServerConnectionInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TurnServerConnectionInfoQuery = { __typename?: 'Query', turnServerConnectionInfo?: { __typename?: 'TurnServerConnectionInfo', credential?: string | null, url?: string | null, username?: string | null } | null };


export const TurnServerConnectionInfoDocument = gql`
    query TurnServerConnectionInfo {
  turnServerConnectionInfo {
    credential
    url
    username
  }
}
    `;

/**
 * __useTurnServerConnectionInfoQuery__
 *
 * To run a query within a React component, call `useTurnServerConnectionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTurnServerConnectionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTurnServerConnectionInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useTurnServerConnectionInfoQuery(baseOptions?: Apollo.QueryHookOptions<TurnServerConnectionInfoQuery, TurnServerConnectionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TurnServerConnectionInfoQuery, TurnServerConnectionInfoQueryVariables>(TurnServerConnectionInfoDocument, options);
      }
export function useTurnServerConnectionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TurnServerConnectionInfoQuery, TurnServerConnectionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TurnServerConnectionInfoQuery, TurnServerConnectionInfoQueryVariables>(TurnServerConnectionInfoDocument, options);
        }
export function useTurnServerConnectionInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TurnServerConnectionInfoQuery, TurnServerConnectionInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TurnServerConnectionInfoQuery, TurnServerConnectionInfoQueryVariables>(TurnServerConnectionInfoDocument, options);
        }
export type TurnServerConnectionInfoQueryHookResult = ReturnType<typeof useTurnServerConnectionInfoQuery>;
export type TurnServerConnectionInfoLazyQueryHookResult = ReturnType<typeof useTurnServerConnectionInfoLazyQuery>;
export type TurnServerConnectionInfoSuspenseQueryHookResult = ReturnType<typeof useTurnServerConnectionInfoSuspenseQuery>;
export type TurnServerConnectionInfoQueryResult = Apollo.QueryResult<TurnServerConnectionInfoQuery, TurnServerConnectionInfoQueryVariables>;