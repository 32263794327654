import { makeStyles } from "@toolkit/ui";

export const useInfoCardWithHeaderStyles = makeStyles()(theme => ({
  container: {
    boxShadow: theme.mixins.shadows.main,
    borderRadius: "10px",
    paddingInline: "0",
  },
  headerContainer: {
    height: "100%",
    minHeight: "55px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    paddingInline: "15px",
    paddingBlock: "10px",
  },
  headerContainerExpand: {
    borderBottom: "1px solid",
    borderColor: theme.palette.gray[100],
    boxShadow: theme.mixins.shadows.xs,
  },
  header: {
    flex: "unset",
    justifyContent: "start",
    width: "fit-content",
    height: "100%",
  },
  contentContainer: {
    paddingBlock: theme.spacing(2),
    paddingInline: 20,
    textAlign: "start",
  },
}));
