import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DigitalTwinBeeAndTdeeCalculateQueryVariables = Types.Exact<{
  input: Types.BeeAndTdeeInput;
  saveData?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type DigitalTwinBeeAndTdeeCalculateQuery = { __typename?: 'Query', beeAndtdee?: { __typename?: 'BEEAndTDEEResponse', bee?: number | null, tdee?: number | null } | null };


export const DigitalTwinBeeAndTdeeCalculateDocument = gql`
    query DigitalTwinBeeAndTdeeCalculate($input: BEEAndTDEEInput!, $saveData: Boolean) {
  beeAndtdee(input: $input, saveData: $saveData) {
    bee
    tdee
  }
}
    `;

/**
 * __useDigitalTwinBeeAndTdeeCalculateQuery__
 *
 * To run a query within a React component, call `useDigitalTwinBeeAndTdeeCalculateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalTwinBeeAndTdeeCalculateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalTwinBeeAndTdeeCalculateQuery({
 *   variables: {
 *      input: // value for 'input'
 *      saveData: // value for 'saveData'
 *   },
 * });
 */
export function useDigitalTwinBeeAndTdeeCalculateQuery(baseOptions: Apollo.QueryHookOptions<DigitalTwinBeeAndTdeeCalculateQuery, DigitalTwinBeeAndTdeeCalculateQueryVariables> & ({ variables: DigitalTwinBeeAndTdeeCalculateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DigitalTwinBeeAndTdeeCalculateQuery, DigitalTwinBeeAndTdeeCalculateQueryVariables>(DigitalTwinBeeAndTdeeCalculateDocument, options);
      }
export function useDigitalTwinBeeAndTdeeCalculateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DigitalTwinBeeAndTdeeCalculateQuery, DigitalTwinBeeAndTdeeCalculateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DigitalTwinBeeAndTdeeCalculateQuery, DigitalTwinBeeAndTdeeCalculateQueryVariables>(DigitalTwinBeeAndTdeeCalculateDocument, options);
        }
export function useDigitalTwinBeeAndTdeeCalculateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DigitalTwinBeeAndTdeeCalculateQuery, DigitalTwinBeeAndTdeeCalculateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DigitalTwinBeeAndTdeeCalculateQuery, DigitalTwinBeeAndTdeeCalculateQueryVariables>(DigitalTwinBeeAndTdeeCalculateDocument, options);
        }
export type DigitalTwinBeeAndTdeeCalculateQueryHookResult = ReturnType<typeof useDigitalTwinBeeAndTdeeCalculateQuery>;
export type DigitalTwinBeeAndTdeeCalculateLazyQueryHookResult = ReturnType<typeof useDigitalTwinBeeAndTdeeCalculateLazyQuery>;
export type DigitalTwinBeeAndTdeeCalculateSuspenseQueryHookResult = ReturnType<typeof useDigitalTwinBeeAndTdeeCalculateSuspenseQuery>;
export type DigitalTwinBeeAndTdeeCalculateQueryResult = Apollo.QueryResult<DigitalTwinBeeAndTdeeCalculateQuery, DigitalTwinBeeAndTdeeCalculateQueryVariables>;