import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/queries/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConsumerMarketplaceOrdersQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.MarketplaceOrderFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.MarketplaceOrderSortingInput>;
}>;


export type ConsumerMarketplaceOrdersQuery = { __typename?: 'Query', me?: { __typename?: 'User', marketplaceOrders?: { __typename?: 'MarketplaceOrderConnection', edges: Array<{ __typename?: 'MarketplaceOrderEdge', node: { __typename?: 'MarketplaceOrder', id: string, createdDate: any, totalNetAmount: any, totalNetAmountWithVAT: any, totalDeliveryPrice: any, totalDiscount?: any | null, status: Types.MarketplaceOrderStatus, address?: { __typename?: 'MarketplaceAddress', id: string, name?: string | null, nameAr?: string | null } | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, logo?: string | null }, orderLines: Array<{ __typename?: 'MarketplaceOrderLine', quantity: number, unitPriceNetAmount: any, id: string, healthPackage?: { __typename?: 'MarketplaceHealthPackage', id: string, isPublished: boolean } | null, product?: { __typename?: 'MarketplaceProduct', id: string, stockQuantity: number, isPublished: boolean } | null }> } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null } | null };


export const ConsumerMarketplaceOrdersDocument = gql`
    query ConsumerMarketplaceOrders($after: String, $filter: MarketplaceOrderFilterInput, $first: Int, $sortBy: MarketplaceOrderSortingInput) {
  me {
    marketplaceOrders(
      after: $after
      filter: $filter
      first: $first
      sortBy: $sortBy
    ) {
      edges {
        node {
          id
          createdDate
          totalNetAmount
          totalNetAmountWithVAT
          totalDeliveryPrice
          totalDiscount
          status
          address {
            id
            name
            nameAr
          }
          vendor {
            id
            name
            nameAr
            logo
          }
          orderLines {
            healthPackage {
              id
              isPublished
            }
            quantity
            unitPriceNetAmount
            id
            product {
              id
              stockQuantity
              isPublished
            }
          }
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useConsumerMarketplaceOrdersQuery__
 *
 * To run a query within a React component, call `useConsumerMarketplaceOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumerMarketplaceOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumerMarketplaceOrdersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useConsumerMarketplaceOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ConsumerMarketplaceOrdersQuery, ConsumerMarketplaceOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsumerMarketplaceOrdersQuery, ConsumerMarketplaceOrdersQueryVariables>(ConsumerMarketplaceOrdersDocument, options);
      }
export function useConsumerMarketplaceOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumerMarketplaceOrdersQuery, ConsumerMarketplaceOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsumerMarketplaceOrdersQuery, ConsumerMarketplaceOrdersQueryVariables>(ConsumerMarketplaceOrdersDocument, options);
        }
export function useConsumerMarketplaceOrdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ConsumerMarketplaceOrdersQuery, ConsumerMarketplaceOrdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConsumerMarketplaceOrdersQuery, ConsumerMarketplaceOrdersQueryVariables>(ConsumerMarketplaceOrdersDocument, options);
        }
export type ConsumerMarketplaceOrdersQueryHookResult = ReturnType<typeof useConsumerMarketplaceOrdersQuery>;
export type ConsumerMarketplaceOrdersLazyQueryHookResult = ReturnType<typeof useConsumerMarketplaceOrdersLazyQuery>;
export type ConsumerMarketplaceOrdersSuspenseQueryHookResult = ReturnType<typeof useConsumerMarketplaceOrdersSuspenseQuery>;
export type ConsumerMarketplaceOrdersQueryResult = Apollo.QueryResult<ConsumerMarketplaceOrdersQuery, ConsumerMarketplaceOrdersQueryVariables>;