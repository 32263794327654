import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    overflowX: "hidden",
  },
  cardWrapper: {
    color: theme.palette.primary.main,
    borderRadius: 15,
    background: theme.palette.common.white,
    padding: 16,
    margin: 5,
    marginInlineStart: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "15px",
    paddingBottom: "45px",
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  imageContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
  },
  leaderLabel: {
    position: "absolute",
    bottom: -30,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 20,
    padding: "4px 12px",
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
    width: "120px",
    marginLeft: "10px",
  },
  textAlign: {
    textAlign: "left",
  },
  careTeamName: {
    marginBottom: "10px",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary.main,
    overflowWrap: "break-word",
    maxWidth: "100%",
  },
  textColor: {
    color: theme.palette.gray.main,
  },
}));
