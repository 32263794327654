import { GenericFilterForm } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { FormTextField } from "@toolkit/ui";
import {
  healthPackagesListFiltersFormDefaultValues,
  HealthPackagesListFiltersFormSchema,
  IHealthPackagesListFiltersFormValues,
} from "../HealthPackagesListFiltersFormSchema";
import { HealthPackagesListFiltersFormProps } from "../types";

export const NameFilterForm: React.FC<HealthPackagesListFiltersFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IHealthPackagesListFiltersFormValues>
      schema={HealthPackagesListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='name'
      dialogTitle={t("Health Package Name")}
      formLabel={t("Name")}
      render={name => <FormTextField name={name} label={t("Name")} />}
      defaultValues={healthPackagesListFiltersFormDefaultValues}
    />
  );
};
