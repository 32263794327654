import { ReelsContainer } from "@/domains/reelsAndVideos/containers/ReelsContainer/ReelsContainer";
import { Section } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import React from "react";
import { useReelsSectionStyles } from "./ReelsSectionStyle";

export const ReelsSection: React.FC = () => {
  const { t } = useTranslation("consumer");
  const { classes } = useReelsSectionStyles();

  return (
    <Section
      mt={2}
      title={t("Videos & Reels")}
      classes={{
        container: classes.container,
      }}
    >
      <ReelsContainer />
    </Section>
  );
};
