import { useRelayStyleConnection } from "@toolkit/apollo";
import { marketplaceItemType } from "../../types";
import { useMarketplaceReviewsQuery } from "@/domains/marketplace-health-packages";

export const useMarketplaceItemReviews = (id: string, marketplaceItemType: marketplaceItemType) => {
  const {
    data: reviews,
    loading: isLoading,
    hasMore,
    handleFetchMore: fetchMoreData,
  } = useRelayStyleConnection({
    useQuery: useMarketplaceReviewsQuery,
    variables: {
      first: 3,
      filter: {
        [marketplaceItemType === "product" ? "productId" : "healthPackageId"]: id,
      },
    },
  });
  return {
    reviews,
    isLoading,
    hasMore,
    fetchMoreData,
  };
};
