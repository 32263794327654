import { useState } from "react";
import { marketplaceItemType } from "../../types";
import { MarketplaceReviewSubmitMutation, useMarketplaceReviewSubmitMutation } from "@/domains/marketplace-health-packages/gql/mutations";
import { useAddToast } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";

export const useAddReviewAndComment = (id: string, marketplaceItemType: marketplaceItemType) => {
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState<number | null>(null);
  const [haveReviewed, setHaveReviewed] = useState(false);
  const { t } = useTranslation("consumer");

  const { succeeded, failed } = useAddToast();
  const [addReview, { loading: isSubmitting }] = useMarketplaceReviewSubmitMutation({
    onCompleted: (reviewData: MarketplaceReviewSubmitMutation) => {
      if (reviewData?.marketplaceReviewSubmit?.id) {
        succeeded(t("Review published successfully"));
      }
      setComment("");
      setHaveReviewed(true);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update: cache => {
      const normalizedId = cache.identify({
        id: id,
        __typename: marketplaceItemType === "product" ? "MarketplaceProduct" : "MarketplaceHealthPackage",
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
    refetchQueries: ["MarketplaceReviews"],
  });

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleRatingChange = (newRating: number | null) => {
    setRating(newRating);
  };

  const handleAddReview = () => {
    id;
    marketplaceItemType;
    addReview({
      variables: {
        input: {
          rating: rating!,
          review: comment,
          [marketplaceItemType === "product" ? "productId" : "healthPackageId"]: id,
        },
      },
    });
  };

  return {
    comment,
    rating,
    handleCommentChange,
    handleRatingChange,
    handleAddReview,
    isSubmitting,
    haveReviewed,
  };
};
