import { TimeSelectionContainerData } from "@/domains/appointment/containers/TimeSelectionContainer/type";
import { routes as appointmentsRoutes } from "@/domains/appointment/routes";
import { getDoctorInfo } from "@/domains/appointment/utils";
import { AppointmentType, AppointmentTypeEnum } from "@/schema/types";
import { ChatIcon, UserIcon, VideoChatIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { Box, Card } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { CareTeamMemberType } from "../../type";
import { CareTeamDisEnrollButton } from "../CareTeamDisEnrollButton/CareTeamDisEnrollButton";
import { CareTeamMemberCard } from "../CareTeamMemberCard/CareTeamMemberCard";
import { ServiceAction } from "../ServiceAction/ServiceAction";
import { useStyle } from "./MyCareTeamDoctorCardStyle";

type MyCareTeamDoctorCardProps = {
  member?: CareTeamMemberType;
  isTeamLeader?: boolean;
  teamId?: string;
  teamName?: string | null;
};

export const MyCareTeamDoctorCard: FC<MyCareTeamDoctorCardProps> = props => {
  const { member, isTeamLeader, teamId, teamName } = props;
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const handleOnButtonCLick = (visitType: AppointmentTypeEnum) => {
    return () => {
      const doctor = member?.user?.doctor;
      navigate(appointmentsRoutes.schedule.getRouteWithParams({ visitType, doctorId: member?.user?.doctor?.id || "" }), {
        state: {
          doctorInfo: getDoctorInfo({
            ...doctor,
            user: {
              ...doctor?.user,
              ...member?.user,
              id: member?.user?.id || "",
            },
          }),
          visitType: visitType as unknown as AppointmentType,
        } as TimeSelectionContainerData,
      });
    };
  };
  const { classes } = useStyle({});
  return (
    <Card className={classes.root}>
      {isTeamLeader && (
        <Box className={classes.disEnrollContainer}>
          <CareTeamDisEnrollButton teamName={teamName} teamId={teamId} />
        </Box>
      )}
      <CareTeamMemberCard
        classes={{
          card: classes.memberCard,
        }}
        member={member}
        isTeamLeader={isTeamLeader}
      />
      <ServiceAction icon={<ChatIcon />} label={t("Chat with my care professional")} />
      <ServiceAction
        disabled={!member?.user?.doctor?.appointmentTypes?.includes(AppointmentTypeEnum.Online)}
        icon={<VideoChatIcon />}
        label={t("Request a virtual call")}
        onExpand={handleOnButtonCLick(AppointmentTypeEnum.Online)}
      />
      <ServiceAction
        disabled={!member?.user?.doctor?.appointmentTypes?.includes(AppointmentTypeEnum.Onsite)}
        lastItem
        icon={<UserIcon />}
        label={t("Request an In-Person appointment")}
        onExpand={handleOnButtonCLick(AppointmentTypeEnum.Onsite)}
      />
    </Card>
  );
};
