import { useCallback } from "react";
import { CustomDialog, DialogContent, FormActions, useAddToast } from "@toolkit/ui";
import { CallInstance } from "./types";
import { useTranslation } from "@toolkit/i18n";
import { FormProvider, useForm } from "react-hook-form";
import { GuestInviteContactInput, GuestInviteForm } from "./GuestInviteForm";

type GuestInviteProps = {
  call: CallInstance;
  onClose: () => void;
};

type GuestInviteInput = { contacts: GuestInviteContactInput[] };

export const GuestInvite = ({ onClose }: GuestInviteProps) => {
  const { t } = useTranslation();
  const { succeeded, failed } = useAddToast();
  // @TODO
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sendConferenceCallInvitation = useCallback(async (value: { emails: string[]; phoneNumbers: string[] }) => {
    return false;
  }, []);
  const loading = false;

  const methods = useForm<GuestInviteInput>({
    mode: "onChange",
  });

  const onSubmit = useCallback(
    async (values: GuestInviteInput) => {
      if (values.contacts.length < 1) {
        return;
      }

      const result = await sendConferenceCallInvitation({
        emails: values.contacts.map(c => c.email).filter(c => !!c),
        phoneNumbers: values.contacts.map(c => c.phoneNumber).filter(c => !!c),
      });
      if (result) {
        onClose();
        succeeded(t("Invitation Sent!"));
      } else {
        failed(t("Failed to send invitation, please retry."));
      }
    },
    [failed, onClose, sendConferenceCallInvitation, succeeded, t]
  );

  return (
    <CustomDialog
      open
      onClose={onClose}
      type='base'
      DialogTitleProps={{
        onClose,
        title: t("Add Guests"),
      }}
    >
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <GuestInviteForm />

            <FormActions hasCancel isLoading={loading} hasFormButton newButtonDisabled={false} formButtonTitle={t("Send")} />
          </form>
        </FormProvider>
      </DialogContent>
    </CustomDialog>
  );
};
