import { memo, useCallback, useState } from "react";
import { CallInstance, CallParticipant } from "./types";
import { Box, Button, CircularProgress, CustomDialog, DialogContent, List, ListSubheader, Typography, useAddToast } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { ParticipantListItem } from "./ParticipantListItem";
import { useParticipantTakePhotoStyles } from "./ParticipantTakePhoto.styles";
import { useParticipantTakePhoto } from "./ParticipantTakePhoto.hooks";

type ParticipantTakePhotoProps = {
  call: CallInstance;
  onClose: () => void;
};

export const ParticipantTakePhoto = memo(({ call, onClose }: ParticipantTakePhotoProps) => {
  const { t } = useTranslation();
  const participants = call.participants.filter(p => !p.isCurrentUser && !p.state.videoMuted);

  const { classes, theme } = useParticipantTakePhotoStyles();
  const { succeeded, failed } = useAddToast();
  const [participant, setParticipant] = useState(participants.length === 1 ? participants[0] : undefined);
  const { snapshot, takeSnapshot: handleTakeSnapshot, saveSnapshot } = useParticipantTakePhoto({ call, participant });
  const [isSaving, setSaving] = useState(false);
  const handleSelect = useCallback((p: CallParticipant) => setParticipant(p), []);
  // const [description, setDescription] = useState("");
  // const handleChangeDescription = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value || ""), []);

  const handleSave = useCallback(async () => {
    try {
      setSaving(true);
      const result = await saveSnapshot();
      if (result) {
        succeeded(t("Photo is captured and stored successfully."));
        onClose();
      } else {
        failed(t("Failed to save photo, please try again."));
      }
    } finally {
      setSaving(false);
    }
  }, [failed, onClose, saveSnapshot, succeeded, t]);

  if (!participant) {
    return (
      <CustomDialog open handleClose={onClose} type='bottomSheet'>
        <List subheader={<ListSubheader>{t("Select participant:")}</ListSubheader>}>
          {participants.map(p => (
            <ParticipantListItem key={p.id} participant={p} onClick={handleSelect} color={theme.palette.common.black} hideActions />
          ))}
        </List>
      </CustomDialog>
    );
  }

  return (
    <CustomDialog
      open
      onClose={onClose}
      type='base'
      DialogTitleProps={{
        onClose,
        title: t("Take Photo"),
      }}
      DialogActionsProps={{
        children: (
          <>
            <Button onClick={handleSave} disabled={!snapshot || isSaving}>
              {isSaving ? <CircularProgress size={16} sx={{ color: theme.palette.common.white }} /> : t("Save")}
            </Button>
            <Button disabled={isSaving} onClick={handleTakeSnapshot} variant='outlined'>
              {t("Retake")}
            </Button>
          </>
        ),
      }}
    >
      <DialogContent>
        <Box>
          <Typography mb={"8px"}>{t("Image")}</Typography>
          {snapshot ? <img className={classes.image} alt='' src={snapshot} /> : <CircularProgress />}
        </Box>
        {/* <Box>
          <TextField
            fullWidth
            type='text'
            value={description}
            multiline
            onChange={handleChangeDescription}
            size='small'
            label={t("Description")}
          />
        </Box> */}
      </DialogContent>
    </CustomDialog>
  );
});
