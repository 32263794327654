import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Typography } from "@toolkit/ui";
import { FC } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { CareTeamMemberCard } from "../../components/CareTeamMemberCard/CareTeamMemberCard";
import { CareTeamsDoctorTabs } from "../../components/CareTeamsDoctorTabs/CareTeamsDoctorTabs";
import { routes } from "../../routes";
import { CareTeamRegistrationContainerDataType } from "../../type";
import { useStyle } from "./CareTeamRegistrationContainerStyle";

export const CareTeamRegistrationContainer: FC = () => {
  const { t } = useTranslation("consumer");

  const { classes: commonClasses } = useCommonContainerStyles();
  const { classes } = useStyle();

  const location: Location<CareTeamRegistrationContainerDataType> = useLocation();
  const { teamLeader, team } = location.state || {};
  const navigate = useNavigate();

  const leaderTeamsMembers = team?.teamMembers?.filter((member): member is NonNullable<typeof member> => member?.id !== teamLeader?.id);

  const handleRegisterClick = () => {
    navigate(routes.confirmRegistration.getRouteWithParams({ teamId: team?.id }), { state: { team, teamLeader } });
  };

  return (
    <Box className={commonClasses.pageContainer}>
      <Box className={commonClasses.pageContent}>
        <Typography className={commonClasses.pageTitle}>{team?.name}</Typography>

        <CareTeamMemberCard
          classes={{
            card: classes.leaderCard,
          }}
          member={teamLeader!}
          isTeamLeader
        />

        <CareTeamsDoctorTabs
          doctorInfo={{
            bio: teamLeader?.user?.doctor?.bio,
            specializations: teamLeader?.user?.doctor?.specializations,
            qualifications: undefined,
            experiences: undefined,
          }}
          teamMembers={leaderTeamsMembers!}
          visibleTabsKeys={["overview", "teamMembers"]}
        />
      </Box>

      <Box className={commonClasses.footer}>
        <Button onClick={handleRegisterClick} className={commonClasses.button} fullWidth>
          {t("Register Now")}
        </Button>
      </Box>
    </Box>
  );
};
