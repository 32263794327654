import { CustomRating } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { truncate } from "lodash";
import React, { useState } from "react";
import { MarketplaceItemReviewData } from "../../types";
import { useMarketplaceItemReviewsStyle } from "./MarketplaceItemReviewCardStyle";

type MarketplaceItemReviewCardProps = {
  review: MarketplaceItemReviewData;
};

export const MarketplaceItemReviewCard: React.FC<MarketplaceItemReviewCardProps> = ({ review }) => {
  const { classes } = useMarketplaceItemReviewsStyle();
  const { t } = useTranslation("consumer");
  const [showFullComment, setShowFullComment] = useState(false);

  const toggleShowMore = () => {
    setShowFullComment(true);
  };

  const truncatedComment = review.comment?.slice(0, 70) || "";

  return (
    <Box className={classes.root}>
      <Box className={classes.startContainer}>
        <Typography className={classes.userName}>{truncate(review?.user?.fullName || "", { length: 18 })}</Typography>
        <CustomRating
          classes={{
            icon: classes.ratingIcon,
          }}
          value={review.rating}
          readOnly
        />
      </Box>
      <Box className={classes.endContainer}>
        <Typography className={classes.comment}>
          {showFullComment ? review.comment : truncatedComment}
          {review.comment && review.comment.length > 70 && (
            <Typography className={classes.showMoreText} onClick={toggleShowMore}>
              {" "}
              {!showFullComment && t("See More...")}
            </Typography>
          )}
        </Typography>
      </Box>
    </Box>
  );
};
