import { AnonymousChatGroup, ChatGroup, User } from "../../schema/types";
import { FC, useEffect, useState } from "react";
import { Typography, Box } from "@toolkit/ui";
import { useChatAvatar } from "./ChatAvatar.hooks";
import { useChatAvatarStyles } from "./ChatAvatar.styles";

type ChatAvatarProps = {
  value: Partial<ChatGroup | AnonymousChatGroup | User> | string | undefined;
  size: number;
  fontSize?: number;
};

export const ChatAvatar: FC<ChatAvatarProps> = ({ value, size, fontSize }) => {
  const { classes } = useChatAvatarStyles();
  const avatar = useChatAvatar({ value });
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const onLoad = () => setLoaded(true);
  const onError = () => setError(true);

  useEffect(() => {
    setLoaded(false);
    setError(false);
  }, [avatar.src]);

  return (
    <Box
      className={classes.root}
      sx={{
        height: size,
        width: size,
        borderRadius: size / 2,
        backgroundColor: avatar.color,
      }}
    >
      {!loaded && (
        <Typography className={classes.text} sx={{ fontSize: fontSize ?? size * 0.3 }}>
          {avatar.shortName}
        </Typography>
      )}
      {!error && avatar.src && (
        // eslint-disable-next-line react/forbid-dom-props
        <img className={classes.image} style={{ height: size, width: size }} alt='' src={avatar.src} onLoad={onLoad} onError={onError} />
      )}
    </Box>
  );
};
