import { useMarketplaceCartContext, useMarketplaceWishlistContext } from "@/shared/context";
import { useTranslation } from "@toolkit/i18n";
import { useCallback } from "react";
import { CartHealthPackageItem } from "../../types";

export const useCartHealthPackageCardHook = (healthPackage: CartHealthPackageItem | undefined, cartItemId: string) => {
  const { t } = useTranslation("consumer");
  const { removeItemsFromCart, itemsLoadingTrackerMap: cartItemsLoadingTracker } = useMarketplaceCartContext();
  const { addItem: addItemToWishlist, itemsLoadingTrackerMap: wishListLoadingTracker, wishlistItems } = useMarketplaceWishlistContext();

  const handleCartRemoval = useCallback(async () => {
    const response = await removeItemsFromCart?.([cartItemId]);
    return !!response?.data?.marketplaceCartRemoveItems?.id;
  }, [removeItemsFromCart, cartItemId, t]);

  const heandleRemoveItemFromCart = useCallback(() => {
    handleCartRemoval();
  }, [handleCartRemoval]);

  const handleSaveForLater = useCallback(async () => {
    if (wishlistItems?.healthPackage?.[healthPackage?.id || ""]) {
      handleCartRemoval();
    } else {
      const response = await addItemToWishlist(healthPackage?.id || "", "healthPackage");
      if (response?.data?.marketplaceWishlistAddItem?.id) {
        await handleCartRemoval();
      }
    }
  }, [addItemToWishlist, handleCartRemoval, healthPackage?.id, wishlistItems?.healthPackage]);

  const isAddedToCart = cartItemsLoadingTracker?.healthPackage?.[healthPackage?.id || ""];

  return {
    handleSaveForLater,
    isAddedToCart,
    cartItemsLoadingTracker,
    wishListLoadingTracker,
    heandleRemoveItemFromCart,
  };
};
