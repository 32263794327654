import { IconButton, Box, makeStyles, MuiCloseIcon } from "@toolkit/ui";
import { FC } from "react";
import { ChatMessageDraft } from "../ChatProvider";
import { ChatDraftView } from "./ChatDraftView";

type ChatDraftProps = {
  draft?: ChatMessageDraft;
  onClose: () => void;
};

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: "0 4px",
    display: "flex",
    flexDirection: "row",
    minWidth: 280,
  },
}));

export const ChatDraft: FC<ChatDraftProps> = ({ draft, onClose }) => {
  const { classes, theme } = useStyles();
  const showDraftView = draft && (draft.action || (draft.attachments && draft.attachments.length > 0));
  if (!showDraftView) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Box flex={1}>
        <ChatDraftView draft={draft} />
      </Box>
      <Box paddingRight={2}>
        <IconButton size={"small"} onClick={onClose}>
          <MuiCloseIcon sx={{ fontSize: 20 }} htmlColor={theme.palette.stale[300]} />
        </IconButton>
      </Box>
    </Box>
  );
};
