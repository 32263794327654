import { CustomRating } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, TextField, Typography } from "@toolkit/ui";
import { FC } from "react";
import { marketplaceItemType } from "../../types";
import { useAddReviewAndCommentStyle } from "./AddReviewAndCommentStyle";
import { useAddReviewAndComment } from "./useAddReviewAndComment.hook";

type AddReviewAndCommentProps = {
  id: string;
  marketplaceItemType: marketplaceItemType;
};

export const AddReviewAndComment: FC<AddReviewAndCommentProps> = ({ id, marketplaceItemType }) => {
  const { classes } = useAddReviewAndCommentStyle();
  const { comment, rating, handleCommentChange, handleRatingChange, handleAddReview, haveReviewed } = useAddReviewAndComment(
    id,
    marketplaceItemType
  );

  const { t } = useTranslation("consumer");

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{t("Add your review and comment")}</Typography>
      <CustomRating
        value={rating}
        onChange={(_, newRating) => handleRatingChange(newRating)}
        classes={{
          icon: classes.ratingIcon,
        }}
        readOnly={haveReviewed}
      />
      <Box className={classes.commentContainer}>
        <TextField
          disabled={haveReviewed}
          value={comment}
          onChange={handleCommentChange}
          className={classes.commentTextField}
          label={t("Add your comment")}
          placeholder=''
          multiline
          rows={1}
        />
        <Button className={classes.addButton} onClick={handleAddReview} disabled={!rating}>
          {t("Add")}
        </Button>
      </Box>
    </Box>
  );
};
