import { ProductCard, ProductCardSkeleton } from "@/domains/marketplace-products";
import { ProductInfoFragment } from "@/domains/marketplace-products/gql/fragments";
import { EmptyMessageDefault } from "@/shared/components";
import { i18n } from "@toolkit/i18n";
import { Box, CardsContainer, CardsContainerProps } from "@toolkit/ui";
import React from "react";
import { useProductsContainerStyles } from "./ProductsContainerStyles";
import { useProductsContainer } from "./useProductsContainer";

type ProductsContainerProps = Partial<Omit<CardsContainerProps, "classes">> & {
  classes?: Partial<ReturnType<typeof useProductsContainerStyles>["classes"]>;
  countOfProducts?: number;
  smallCardSize?: boolean;
  products?: ProductInfoFragment[];
  variant?: "auth-based" | "best-sellers";
};

export const ProductsContainer: React.FC<ProductsContainerProps> = props => {
  const { classes: _classes, countOfProducts = 9, smallCardSize, variant = "auth-based", products: comingProducts, ...rest } = props;
  const { classes, cx } = useProductsContainerStyles();
  const { isLoading, products } = useProductsContainer({
    countOfProducts,
    variant,
    comingProducts,
  });
  return (
    <CardsContainer
      classes={{
        cardsContainerClassName: cx(classes.cardsContainerClassName, _classes?.cardsContainerClassName),
        navBtnClassName: cx(classes.navBtnClassName, _classes?.navBtnClassName),
        navBtnContainerClassName: cx(classes.navBtnContainerClassName, _classes?.navBtnContainerClassName),
        cardsContainer: cx(classes.cardsContainer, _classes?.cardsContainer),
        cardsUpperContainer: cx(classes.cardsUpperContainer, _classes?.cardsUpperContainer),
        card: cx(classes.card, _classes?.card),
        skeletonContainer: cx(classes.skeletonContainer, _classes?.skeletonContainer),
      }}
      isLoadingData={isLoading}
      countOfSkeletons={countOfProducts}
      CardSkeletonComponent={
        <Box className={cx(classes.productCardWrapper, _classes?.productCardWrapper)}>
          <ProductCardSkeleton />
        </Box>
      }
      EmptyCardComponent={<EmptyMessageDefault message={i18n.t("No services available.", { ns: "consumer" })} />}
      cardsScrollAmount={2}
      hideNavBtns={true}
      cards={products?.map(product => (
        <Box key={product?.id} className={cx(classes.productCardWrapper, _classes?.productCardWrapper)}>
          <ProductCard product={product} smallSizeOnly={smallCardSize} />
        </Box>
      ))}
      {...rest}
    />
  );
};
