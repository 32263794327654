import { GenericFilterForm } from "@/shared/components";
import { DoctorsAutocomplete } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import {
  careTeamListFiltersFormDefaultValues,
  CareTeamsListFiltersFormSchema,
  ICareTeamsListFiltersFormValues,
} from "../CareTeamsListFiltersFormSchema";
import { AppointmentFilterFormProps } from "../types";

export const DoctorsFilterForm: React.FC<AppointmentFilterFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<ICareTeamsListFiltersFormValues>
      schema={CareTeamsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='doctors'
      dialogTitle={t("Doctors")}
      formLabel={t("Doctors")}
      render={name => <DoctorsAutocomplete name={name} multiple />}
      defaultValues={careTeamListFiltersFormDefaultValues}
    />
  );
};
