import { useRelayStyleConnection } from "@toolkit/apollo";
import { useConsumerMarketplaceOrdersQuery } from "../../gql/queries";
import { MarketplaceOrderSortingField, MarketplaceSortDirection } from "@/schema/types";
import { useAuth } from "react-oidc-context";
import { myOrdersTabs } from "../MyOrdersTabs/tabs";

type UseMarketplaceOrdersListProps = {
  tabIndex: number;
};
export const useMarketplaceOrdersList = ({ tabIndex }: UseMarketplaceOrdersListProps) => {
  const { isAuthenticated } = useAuth();
  const currentTab = myOrdersTabs[tabIndex];
  const {
    data,
    loading: isLoading,
    hasMore,
    handleFetchMore: fetchMoreData,
    refetch,
  } = useRelayStyleConnection({
    useQuery: useConsumerMarketplaceOrdersQuery,
    accessor: "me.marketplaceOrders",
    variables: {
      first: 10,
      sortBy: {
        field: MarketplaceOrderSortingField.Created,
        direction: MarketplaceSortDirection.Desc,
      },
      filter: {
        statuses: currentTab?.statusFiltersValues.slice(),
      },
    },
    skip: !isAuthenticated,
  });
  const handleRefresh = () => {
    refetch();
  };
  return {
    orders: data,
    isLoading,
    hasMore,
    fetchMoreData,
    handleRefresh,
  };
};
