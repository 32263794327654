import { FC, useEffect, useRef } from "react";
import { OrderTrackingViewProps } from "../../others/types";
import { useNavigate } from "react-router-dom";
import { orderMedicationsRoutes } from "../../routes";
import { useAddToast } from "@toolkit/ui";
import { ORDER_STATUS_MESSAGES } from "../../others/constants";

export const OrderMedicationPage: FC<OrderTrackingViewProps> = props => {
  const { prescription, order } = props;
  const navigate = useNavigate();
  const { failed } = useAddToast();
  const hasShownToast = useRef(false);

  useEffect(() => {
    if (!hasShownToast.current) {
      order?.status ? failed(ORDER_STATUS_MESSAGES[order?.status]) : failed("Something Want");
      hasShownToast.current = true;

      navigate(
        orderMedicationsRoutes.root.getRouteWithParams({
          referenceId: prescription?.referenceNumber || "",
        })
      );
    }
  }, [failed, navigate, prescription?.referenceNumber]);

  return <></>;
};
