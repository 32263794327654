import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";
export const DefaultEmptyIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fillValue = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      xmlSpace='preserve'
      {...props}
    >
      <path
        d='M182.8,86.3c-9.1,3.3-15.5,12.4-15.5,22.1c0,17.2,17.4,28.4,32.9,21.1c8.8-4,13.5-11.4,13.5-21.1c0-3.1-0.6-7-1.3-8.8
            C207.6,88.4,194,82.2,182.8,86.3z M197,103c2.5,3.1,2.2,8.5-0.8,11.4c-3.1,3.2-7.5,3.2-11.1,0.1c-6.3-5.3-1.6-15.5,6.5-14.2
            C193.6,100.6,196,101.8,197,103z'
        fill={fillValue}
      />
      <path d='M417,84.2v8h-8.5H400v7.5v7.5h8.5h8.5v8.5v8.5h7.5h7.5v-8.5v-8.5h8h8v-7.5v-7.5h-8h-8v-8v-8h-7.5H417V84.2z' fill={fillValue} />
      <path d='M189.5,173v8h-8h-8v7.5v7.5h8h8v8.5v8.5h7.5h7.5v-8.5V196h8.5h8.5v-7.5V181H213h-8.5v-8v-8H197h-7.5V173z' fill='currentColor' />
      <path
        d='M314.4,109.6c-6.3,2-11.1,6.2-14,12.2c-2.3,4.8-2.6,6.3-2.1,12.1c0.9,11.8,9.5,20.4,21.1,21.1c21.9,1.4,33.6-23.1,18.7-39.1
            C332.4,109.6,322.3,107,314.4,109.6z M327.8,126.8c2.6,2.9,2.8,5.4,0.7,9.3c-2.5,4.9-9,5.7-13,1.6c-3.5-3.4-3.3-7.8,0.5-11.7
            c2.6-2.5,3.6-2.9,6.3-2.4C324.1,123.9,326.5,125.3,327.8,126.8z'
        fill={fillValue}
      />
      <path
        d='M49.5,244l36,36l-36,36l-36,36H128h114.5l26.5-26.5c14.6-14.6,26.8-26.5,27.2-26.5c0.5,0,0.8,44.5,0.8,99v99h-97h-97v-64.5
            V368h-7.5H88v72v72h168h168v-80v-80h37.2h37.3l-36-36l-36-36l36-36l36-36H432h-66.5l-23.2,23.2L319,254.5l5.2,5.2l5.2,5.3l21.1-21
            l21.1-21h44.7c24.6,0,44.7,0.3,44.7,0.8c0,0.4-10.9,11.6-24.3,25L412.5,273H312H211.5L179,240.5L146.5,208H80H13.5L49.5,244z
             M164.7,247.2c13.4,13.3,24.3,24.6,24.3,25c0,0.5-20.1,0.8-44.7,0.8H99.5l-24.2-24.2c-13.4-13.4-24.3-24.6-24.3-25
            c0-0.5,20.1-0.8,44.7-0.8h44.7L164.7,247.2z M261,312.5L236.5,337H143H49.5L74,312.5L98.5,288H192h93.5L261,312.5z M409,392.5V497
            h-48.5H312V392.5V288h48.5H409V392.5z M443.5,318l19,19h-19.3H424v-19c0-10.5,0.1-19,0.3-19C424.4,299,433.1,307.5,443.5,318z'
        fill={fillValue}
      />
      <path d='M160,464.5v7.5h8h8v-7.5V457h-8h-8V464.5z' fill='currentColor' />
      <path d='M192,464.5v7.5h8h8v-7.5V457h-8h-8V464.5z' fill='currentColor' />
      <path d='M224,464.5v7.5h8h8v-7.5V457h-8h-8V464.5z' fill='currentColor' />
    </SvgIcon>
  );
};
