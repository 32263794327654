import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useTranslation } from "@/i18n/i18n.config";
import { useStyles } from "./MyDocumentsContainerStyles";
import { DocumentsList } from "../DcoumentsList/DocumentsList";

export const MyDocumentsContainer: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant='h6' className={classes.documentTitle}>
        {t("Documents")}
      </Typography>
      <DocumentsList />
    </Box>
  );
};
