import { makeStyles } from "@toolkit/ui";

export const useAddressMapDisplayStyles = makeStyles()(theme => ({
  root: {
    height: "calc(100dvh - 180px)",
    borderRadius: "10px",
    boxShadow: theme.mixins.shadows.main,
    position: "relative",
    top: -10,
  },
}));
