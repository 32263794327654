import { DigitalTwinBmrTdeeCalculatorResult } from "@/domains/digital-twin-tdee-bmr-calculator/components/DigitalTwinBmrTdeeCalculatorResult/DigitalTwinBmrTdeeCalculatorResult";
import { TdeeAndBmrCalculationRoutes } from "@/domains/digital-twin-tdee-bmr-calculator/routes";
import { useTranslation } from "@toolkit/i18n";
import { Box, Card, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WeightLossPlanCalculationBaseRouteParams } from "../../routes";
import { useDigitalTwinWeightLossPlanCalculatorStyle } from "./useDigitalTwinWeightLossPlanCalculatorStyle";
import { ExpandMoreIcon } from "@/shared/icons";

export const DigitalTwinWeightLossPlanCalculator: FC = () => {
  const { t: trs } = useTranslation();
  const navigate = useNavigate();

  const { bee, tdee, caloriesPerDayToAdjust, goalWeight, newCalorieIntake, newTdee, weightLossDays } =
    useParams<WeightLossPlanCalculationBaseRouteParams>()!;
  const { classes } = useDigitalTwinWeightLossPlanCalculatorStyle();

  const handleOpenFormPressed = () => {
    navigate(TdeeAndBmrCalculationRoutes.form.absRoute);
  };
  return (
    <Box className={classes.root}>
      <>
        <Card className={classes.headerCard} onClick={handleOpenFormPressed}>
          <Box className={classes.headerCardInner}>
            <Typography className={classes.title}>{trs("Set your goal for calorie counting")}</Typography>
            <Typography className={classes.subTitle}>
              {trs("Fill in the details so we can calculate your calories and weight loss plan")}
            </Typography>
          </Box>
          <ExpandMoreIcon direction='right' height={15} width={20} />
        </Card>

        <DigitalTwinBmrTdeeCalculatorResult
          bee={bee}
          tdee={tdee}
          maxTdee={tdee!}
          weightLossPlanData={{
            numberOfDays: weightLossDays!,
            goalWeight: goalWeight!,
            newTdeeValue: newTdee!,
            targetCalories: caloriesPerDayToAdjust!,
            reduceCalorieIntake: newCalorieIntake!,
          }}
        />
      </>
    </Box>
  );
};
