import { makeStyles } from "@toolkit/ui";

export const useCartProductCardStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    gap: "10px",
    width: "100%",
  },
  addButton: {
    margin: "0px",
    height: "35px",
    paddingInline: "17px",
    borderRadius: "24px",
  },
  addedDisplay: {
    margin: "0px",
    height: "35px",
    paddingInline: "30px",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.success[1200],
    color: theme.palette.common.white,
  },
  removeButton: {
    margin: "0px",
    height: "35px",
    paddingInline: "17px",
    borderRadius: "24px",
    "&:hover": {
      " & path": {
        fill: theme.palette.common.white,
      },
    },
  },
  itemAvatarContainer: {},
  infoContainer: {
    maxWidth: "100%",
  },
}));
