import { DigitalTwinBMRAndTDEECalculatorContainer } from "./containers/DigitalTwinBMRAndTDEECalculator/DigitalTwinBMRAndTDEECalculatorContainer";
import { DigitalTwinBMRAndTDEECalculatorFormContainer } from "./containers/DigitalTwinBMRAndTDEECalculatorForm/DigitalTwinBMRAndTDEECalculatorFormContainer";

export const TdeeAndBmrCalculationBaseRoute = "/tdee-and-bee-calculator";
export interface TdeeAndBmrCalculationBaseRouteParams extends Record<string, any> {
  bee: number;
  tdee: number;
  newTdee?: number;
  goalWeight?: number;
  newCalorieIntake?: number;
  caloriesPerDayToAdjust?: number;
  weightLossDays?: number;
}

export const TdeeAndBmrCalculationRoutes = {
  root: {
    route: "/",
    component: DigitalTwinBMRAndTDEECalculatorContainer,
    get absRoute() {
      return `${TdeeAndBmrCalculationBaseRoute}${this.route}`;
    },
    getRouteWithParams(result: TdeeAndBmrCalculationBaseRouteParams) {
      return `${this.absRoute}?` + new URLSearchParams(result);
    },
  },
  form: {
    route: "/form",
    component: DigitalTwinBMRAndTDEECalculatorFormContainer,
    get absRoute() {
      return `${TdeeAndBmrCalculationBaseRoute}${this.route}`;
    },
    getRouteWithParams() {
      return `${this.absRoute}`;
    },
  },
};
