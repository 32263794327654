import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(() => ({
  root: {
    maxWidth: "fit-content",
    margin: "auto",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    minWidth: 0,

    ["& img"]: {
      objectFit: "contain",
      height: "100%",
      width: "100%",
      maxHeight: "inherit",
      maxWidth: "100%",
    },
  },

  placeholder: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    color: "rgb(0 0 0 / 9%)",
    width: "100%",
    height: "100%",
  },

  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    ["& .MuiCircularProgress-root"]: {
      maxWidth: "40%",
      height: "auto !important",
    },
  },
}));
