import { Box, Button } from "@toolkit/ui";
import { marketplaceItemType } from "../../types";
import { useMarketplaceItemReviewsStyle } from "./MarketplaceItemReviewsStyle";
import { useMarketplaceItemReviews } from "./useMarketplaceItemReviews";
import { useTranslation } from "@toolkit/i18n";
import { MarketplaceItemReviewCard } from "../MarketplaceItemReviewCard/MarketplaceItemReviewCard";

type MarketplaceItemReviewsProps = {
  id: string;
  marketplaceItemType: marketplaceItemType;
};

export const MarketplaceItemReviews: React.FC<MarketplaceItemReviewsProps> = props => {
  const { id, marketplaceItemType } = props;
  const { t } = useTranslation("consumer");
  const { classes } = useMarketplaceItemReviewsStyle();
  const { reviews, isLoading, hasMore, fetchMoreData } = useMarketplaceItemReviews(id, marketplaceItemType);

  return (
    <>
      {isLoading && reviews?.length === 0 && <Box className={classes.root}>{t("Loading...")}</Box>}
      {reviews && (
        <Box className={classes.root}>
          {reviews.map((review, index) => (
            <MarketplaceItemReviewCard key={index} review={review} />
          ))}
          {hasMore && (
            <Box className={classes.loadMoreButtonContainer}>
              <Button onClick={fetchMoreData}>{t("Load More")}</Button>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
