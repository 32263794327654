/* eslint-disable no-console */
/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ConnectionState, ExecutedResponse, ZoomClientType } from "./types";
import { useMediaState } from "./useMediaState";
import { formatExecutedFailure, initializeZoomClient, startAudio as startAudioStream, startVideoStream } from "./utils";

export type JoinSessionConfig = {
  topic: string;
  token: string;
  userName: string;
  sessionPassword?: string;
  sessionIdleTimeoutMins?: number;
  audioOptions?: {
    mute?: boolean;
  };
  videoOptions?: {
    localVideoOn?: boolean;
  };
};

export const useConnectionState = (client: ZoomClientType, debug?: boolean) => {
  const { mediaState, resetMediaState } = useMediaState(client);
  const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.Closed);
  const [isConnecting, setConnecting] = useState(false);
  const ref = useRef({ leaveSession: () => {}, connectionState });
  const resetConnectionState = useCallback(() => {
    if (ref.current.connectionState === ConnectionState.Connected) {
      ref.current.leaveSession?.();
    }
    ref.current.connectionState = ConnectionState.Closed;
    setConnectionState(ConnectionState.Closed);
    setConnecting(false);
  }, []);

  const mediaStream = useMemo(() => {
    if (connectionState === ConnectionState.Connected) {
      const stream = client.getMediaStream();
      (window as any)["zmClient"] = client;
      (window as any)["mediaStream"] = stream;

      return stream;
    }

    return null;
  }, [client, connectionState]);

  const startAudio = useCallback(
    async (config: JoinSessionConfig) => {
      const stream = client.getMediaStream();
      const audioResult = await startAudioStream(stream, { mute: !!config.audioOptions?.mute });
      if (!audioResult.success) {
        if (debug) {
          console.warn("Meeting: Unable to start audio, fallback to speakerOnly.", audioResult);
        }

        const speakerResult = await startAudioStream(stream, { speakerOnly: true });
        if (!speakerResult.success && debug) {
          console.error("Meeting: Failed to start speakerOnly. Reason:", speakerResult);
        }
      }
    },
    [client, debug]
  );

  const startVideo = useCallback(
    async (config: JoinSessionConfig) => {
      const stream = client.getMediaStream();
      if (config.videoOptions?.localVideoOn) {
        const videoResult = await startVideoStream(stream);
        if (!videoResult.success && debug) {
          console.error("Meeting: Unable to start video due to the Error:", videoResult);
        }
      }
    },
    [client, debug]
  );

  const leaveSession = useCallback(
    async (endSession = false): Promise<ExecutedResponse> => {
      try {
        debug && console.log("Meeting: Leave session request", { endSession });
        await client.leave(endSession);

        return { success: true };
      } catch (error) {
        if (debug) {
          console.error("Meeting: Unable to leave session due to the error", error);
        }
        return { type: "ERROR", reason: error };
      } finally {
        resetConnectionState();
      }
    },
    [client, debug, resetConnectionState]
  );

  ref.current = { leaveSession, connectionState };

  const joinSession = useCallback(
    // eslint-disable-next-line max-statements
    async (config: JoinSessionConfig): Promise<ExecutedResponse> => {
      try {
        setConnecting(true);
        debug && console.log("Meeting: Join session request", config);

        const sessionInfo = client.getSessionInfo();
        if (sessionInfo.isInMeeting) {
          if (sessionInfo.password === config.sessionPassword && sessionInfo.topic === config.token) {
            debug && console.log("Meeting: Skip Join session, user already in meeting.");
            setConnectionState(ConnectionState.Connected);
            return { success: true };
          } else {
            debug && console.log("Meeting: User already in meeting, leave session before rejoin.");
            await ref.current.leaveSession();
          }
        }

        const iniResult = await initializeZoomClient(client);
        if (!iniResult.success) {
          return iniResult;
        }

        await client.join(config.topic, config.token, config.userName, config.sessionPassword, config.sessionIdleTimeoutMins);
        setConnectionState(ConnectionState.Connected);
        startAudio(config);
        startVideo(config);

        return { success: true };
      } catch (error) {
        if (debug) {
          console.error("Meeting: Unable to join session due to the error", error);
        }
        return formatExecutedFailure(error);
      } finally {
        setConnecting(false);
      }
    },
    [debug, client, startAudio, startVideo]
  );

  useEffect(() => {
    const { isInMeeting } = client.getSessionInfo();
    if (isInMeeting) {
      setConnectionState(ConnectionState.Connected);
    }

    const onConnectionChange = (payload: { state: ConnectionState; reason?: string; subsessionName?: string }) => {
      setConnectionState(payload.state);
      if (payload.state === ConnectionState.Closed) {
        resetMediaState();
      }
    };

    client.on("connection-change", onConnectionChange);
    return () => {
      client.off("connection-change", onConnectionChange);
    };
  }, [client, resetMediaState]);

  useEffect(() => {
    return () => {
      resetConnectionState();
    };
  }, [resetConnectionState]);

  return {
    connectionState,
    isConnecting,
    joinSession,
    leaveSession,
    mediaState,
    mediaStream,
    resetConnectionState,
  };
};
