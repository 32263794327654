import { FC } from "react";

import { CallInstance } from "./useCall";
import { useZoom } from "./useZoom";
import { ZoomView, ZoomViewProps } from "./ZoomView";

type ZoomPublisherViewProps = {
  call: CallInstance;
} & Omit<ZoomViewProps, "participant">;

export const ZoomPublisherView: FC<ZoomPublisherViewProps> = ({ call, ...props }) => {
  const { videoMuted } = call.state;
  const participant = call.currentParticipant;
  const { mediaStream } = useZoom();
  const renderSelfView = !videoMuted;
  if (!renderSelfView || !participant || !mediaStream) {
    return null;
  }

  return <ZoomView participant={participant} {...props} />;
};
