import React from "react";
import { useDigitalTwinBMRAndTDEECalculatorFormContainerStyle } from "./useDigitalTwinBMRAndTDEECalculatorFormContainerStyle";
import { Box } from "@toolkit/ui";
import { DigitalTwinBMRAndTDEECalculatorForm } from "../../components/DigitalTwinBMRAndTDEECalculatorForm/DigitalTwinBMRAndTDEECalculatorForm";

export const DigitalTwinBMRAndTDEECalculatorFormContainer = () => {
  const { classes } = useDigitalTwinBMRAndTDEECalculatorFormContainerStyle();
  return (
    <Box>
      <Box className={classes.root}>
        <DigitalTwinBMRAndTDEECalculatorForm />
      </Box>
    </Box>
  );
};
