import { Radio, styled } from "@toolkit/ui";

export const CustomRadio = styled(Radio)(({ theme }) => ({
  width: 20,
  height: 20,
  color: theme.palette.text.primary,
}));

CustomRadio.defaultProps = {
  color: "default",
};
