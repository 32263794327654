import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { HealthPackageInfoFragmentDoc } from '../../fragments/__generated__/HealthPackageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceHealthPackageQueryVariables = Types.Exact<{
  healthPackageId: Types.Scalars['ID']['input'];
  skipReviewSummary: Types.Scalars['Boolean']['input'];
}>;


export type MarketplaceHealthPackageQuery = { __typename?: 'Query', marketplaceHealthPackage?: { __typename?: 'MarketplaceHealthPackage', id: string, name?: string | null, nameAr?: string | null, price?: any | null, description?: string | null, descriptionAr?: string | null, contentType?: Types.MarketplaceContentType | null, shortDescription?: string | null, shortDescriptionAr?: string | null, mainImageUrl?: string | null, reviewSummary?: { __typename?: 'MarketplaceReviewSummary', avgRating: any, total: number, ratingCounts: Array<{ __typename?: 'MarketplaceReviewRatingCount', count: number, rating: number }> } | null, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, endDate: any, percentage?: number | null, startDate: any, type: Types.MarketplaceDiscountType } | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null } } | null };


export const MarketplaceHealthPackageDocument = gql`
    query MarketplaceHealthPackage($healthPackageId: ID!, $skipReviewSummary: Boolean!) {
  marketplaceHealthPackage(id: $healthPackageId) {
    ...HealthPackageInfo
    reviewSummary @skip(if: $skipReviewSummary) {
      avgRating
      total
      ratingCounts {
        count
        rating
      }
    }
  }
}
    ${HealthPackageInfoFragmentDoc}`;

/**
 * __useMarketplaceHealthPackageQuery__
 *
 * To run a query within a React component, call `useMarketplaceHealthPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceHealthPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceHealthPackageQuery({
 *   variables: {
 *      healthPackageId: // value for 'healthPackageId'
 *      skipReviewSummary: // value for 'skipReviewSummary'
 *   },
 * });
 */
export function useMarketplaceHealthPackageQuery(baseOptions: Apollo.QueryHookOptions<MarketplaceHealthPackageQuery, MarketplaceHealthPackageQueryVariables> & ({ variables: MarketplaceHealthPackageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceHealthPackageQuery, MarketplaceHealthPackageQueryVariables>(MarketplaceHealthPackageDocument, options);
      }
export function useMarketplaceHealthPackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceHealthPackageQuery, MarketplaceHealthPackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceHealthPackageQuery, MarketplaceHealthPackageQueryVariables>(MarketplaceHealthPackageDocument, options);
        }
export function useMarketplaceHealthPackageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplaceHealthPackageQuery, MarketplaceHealthPackageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceHealthPackageQuery, MarketplaceHealthPackageQueryVariables>(MarketplaceHealthPackageDocument, options);
        }
export type MarketplaceHealthPackageQueryHookResult = ReturnType<typeof useMarketplaceHealthPackageQuery>;
export type MarketplaceHealthPackageLazyQueryHookResult = ReturnType<typeof useMarketplaceHealthPackageLazyQuery>;
export type MarketplaceHealthPackageSuspenseQueryHookResult = ReturnType<typeof useMarketplaceHealthPackageSuspenseQuery>;
export type MarketplaceHealthPackageQueryResult = Apollo.QueryResult<MarketplaceHealthPackageQuery, MarketplaceHealthPackageQueryVariables>;