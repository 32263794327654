import { HealthEngagementMessagesListContainer, NotificationListContainer } from "./containers";
import { AppointmentNotificationDetailsContainer } from "../appointment/containers";
import { orderMedicationsRoutes } from "../order-medication/routes";
import { routes as marketplaceRoutes } from "../marketplace/routes";

export const NotificationsBaseRoute = "/notifications";

export const routes = {
  root: {
    route: "/",
    component: () => <NotificationListContainer />,
    get absRoute() {
      return NotificationsBaseRoute;
    },
  },
  healthEngagementMessages: {
    route: "health-engagement-messages",
    component: () => <HealthEngagementMessagesListContainer />,
    get absRoute() {
      return `${NotificationsBaseRoute}/${this.route}`;
    },
  },
  appointmentNotification: {
    route: "appointments",
    component: () => <AppointmentNotificationDetailsContainer />,
    get absRoute() {
      return `${NotificationsBaseRoute}/${this.route}`;
    },
    getRouteWithParams({ appointmentId }: { appointmentId: string }) {
      return `${this.absRoute}?appointmentId=${appointmentId}`;
    },
  },
  surveyNotification: {
    route: "survey",
    component: () => <div>Survey</div>,
    get absRoute() {
      return `${NotificationsBaseRoute}/${this.route}`;
    },
    getRouteWithParams({ surveyId }: { surveyId: string }) {
      return `${this.absRoute}?surveyId=${surveyId}`;
    },
  },
  prescriptionNotification: {
    route: "prescription",
    component: () => <div>Prescription</div>,
    get absRoute() {
      return `${NotificationsBaseRoute}/${this.route}`;
    },
    getRouteWithParams({ prescriptionId }: { prescriptionId: string }) {
      return `${this.absRoute}?id=${prescriptionId}`;
    },
  },
  consentNotification: {
    route: "consent",
    component: () => <div>Consent Form</div>,
    get absRoute() {
      return `${NotificationsBaseRoute}/${this.route}`;
    },
    getRouteWithParams({ consentFormId }: { consentFormId: string }) {
      return `${this.absRoute}?id=${consentFormId}`;
    },
  },
  prescriptionCreateNotification: orderMedicationsRoutes.root,
  guidedCareTaskNotification: {
    route: "guided-care-task",
    component: () => <div>Guided Care Task</div>,
    get absRoute() {
      return `${NotificationsBaseRoute}/${this.route}`;
    },
    getRouteWithParams({ guidedCareTaskId }: { guidedCareTaskId: string }) {
      return `${this.absRoute}?guidedCareTaskId=${guidedCareTaskId}`;
    },
  },
  medicalFormNotification: {
    route: "medical-form",
    component: () => <div>Medical Form</div>,
    get absRoute() {
      return `${NotificationsBaseRoute}/${this.route}`;
    },
    getRouteWithParams({ id }: { id: string }) {
      return `${this.absRoute}?id=${id}`;
    },
  },
  marketplaceOrderNotification: marketplaceRoutes?.ordersDetails,
};
