import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientMedicalProfileDocumentsQueryVariables = Types.Exact<{
  patientId: Types.Scalars['ID']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type PatientMedicalProfileDocumentsQuery = { __typename?: 'Query', patientMedicalProfile?: { __typename?: 'PatientMedicalProfile', healthDocuments?: { __typename?: 'PatientHealthDocumentCountableConnection', edges: Array<{ __typename?: 'PatientHealthDocumentCountableEdge', node: { __typename?: 'PatientHealthDocument', description?: string | null, fileLinks: Array<string>, id: string, name: string, resultDate: any, type: Types.HealthDocumentType } }>, pageInfo: { __typename?: 'H_PageInfo', startCursor?: string | null, hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null } } | null } | null };


export const PatientMedicalProfileDocumentsDocument = gql`
    query PatientMedicalProfileDocuments($patientId: ID!, $after: String, $before: String, $first: Int, $last: Int) {
  patientMedicalProfile(patientId: $patientId) {
    healthDocuments(after: $after, before: $before, first: $first, last: $last) {
      edges {
        node {
          description
          fileLinks
          id
          name
          resultDate
          type
        }
      }
      pageInfo {
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
    }
  }
}
    `;

/**
 * __usePatientMedicalProfileDocumentsQuery__
 *
 * To run a query within a React component, call `usePatientMedicalProfileDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientMedicalProfileDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientMedicalProfileDocumentsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function usePatientMedicalProfileDocumentsQuery(baseOptions: Apollo.QueryHookOptions<PatientMedicalProfileDocumentsQuery, PatientMedicalProfileDocumentsQueryVariables> & ({ variables: PatientMedicalProfileDocumentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientMedicalProfileDocumentsQuery, PatientMedicalProfileDocumentsQueryVariables>(PatientMedicalProfileDocumentsDocument, options);
      }
export function usePatientMedicalProfileDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientMedicalProfileDocumentsQuery, PatientMedicalProfileDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientMedicalProfileDocumentsQuery, PatientMedicalProfileDocumentsQueryVariables>(PatientMedicalProfileDocumentsDocument, options);
        }
export function usePatientMedicalProfileDocumentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientMedicalProfileDocumentsQuery, PatientMedicalProfileDocumentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientMedicalProfileDocumentsQuery, PatientMedicalProfileDocumentsQueryVariables>(PatientMedicalProfileDocumentsDocument, options);
        }
export type PatientMedicalProfileDocumentsQueryHookResult = ReturnType<typeof usePatientMedicalProfileDocumentsQuery>;
export type PatientMedicalProfileDocumentsLazyQueryHookResult = ReturnType<typeof usePatientMedicalProfileDocumentsLazyQuery>;
export type PatientMedicalProfileDocumentsSuspenseQueryHookResult = ReturnType<typeof usePatientMedicalProfileDocumentsSuspenseQuery>;
export type PatientMedicalProfileDocumentsQueryResult = Apollo.QueryResult<PatientMedicalProfileDocumentsQuery, PatientMedicalProfileDocumentsQueryVariables>;