import {
  MarketplaceHealthPackageFilterInput,
  MarketplaceHealthPackageSortingField,
  MarketplaceHealthPackageSortingInput,
  MarketplaceSortDirection,
} from "@/schema/types";
import { i18n } from "@toolkit/i18n";
import { IHealthPackagesListFiltersFormValues } from "./HealthPackagesListFiltersFormSchema";

export const ConvertHealthPackagesFilterFormValuesToBackEndValues = (
  values: IHealthPackagesListFiltersFormValues
): { filters?: MarketplaceHealthPackageFilterInput; sortingFilters?: MarketplaceHealthPackageSortingInput } => {
  return {
    filters: {
      vendorId: values?.vendor?.value?.id,
      priceRange: values?.price ? { min: values.price[0], max: values.price[1] } : undefined,
      targetGender: values?.gender?.value,
      nameContains: values?.name,
      targetAge: values?.age,
    },
    sortingFilters: {
      field: values?.sortingField,
      direction: MarketplaceSortDirection.Asc,
    },
  };
};

export const sortingOptions = [
  {
    get name() {
      return i18n.t("Date", { ns: "consumer" });
    },
    value: MarketplaceHealthPackageSortingField.Created,
  },
  {
    get name() {
      return i18n.t("Name", { ns: "consumer" });
    },
    value: MarketplaceHealthPackageSortingField.Name,
  },
  {
    get name() {
      return i18n.t("Arabic Name", { ns: "consumer" });
    },
    value: MarketplaceHealthPackageSortingField.NameAr,
  },
  {
    get name() {
      return i18n.t("Price", { ns: "consumer" });
    },
    value: MarketplaceHealthPackageSortingField.Price,
  },
];
