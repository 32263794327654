import { LabeledInputContainer } from "@/shared/components";
import { FormControlLabel, makeStyles, Radio, RadioGroup, RadioGroupProps } from "@toolkit/ui";
import React, { FC } from "react";

export interface CalculationFormRadioProps<T = string> extends Omit<RadioGroupProps, "onChange" | "value"> {
  inputLabel: string;
  value: T;
  onChange: (value: T) => void;
  options: { label: string; value: T }[];
}
export const CalculationFormRadio = <T,>(props: CalculationFormRadioProps<T>): ReturnType<FC<CalculationFormRadioProps<T>>> => {
  const { classes, cx, theme } = useStyle();
  const { inputLabel, options, onChange, ...radioProps } = props;
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as (typeof options)[number]["value"]);
  };
  return (
    <LabeledInputContainer label={inputLabel}>
      <RadioGroup {...radioProps} onChange={handleOnChange} className={cx(classes.radioGroup, radioProps.className)}>
        {options?.map(item => (
          <FormControlLabel
            key={typeof item?.value === "string" ? item?.value : item.label}
            value={item?.value}
            control={<Radio />}
            label={item?.label || ""}
            sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}
          />
        ))}
      </RadioGroup>
    </LabeledInputContainer>
  );
};
export const useStyle = makeStyles()(theme => {
  return {
    inputLabel: {
      fontSize: theme.mixins.fonts.fontSize.lg,
      paddingHorizontal: 0,
      marginBottom: 8,
      alignSelf: "flex-start",
    },
    radioGroup: { gap: 4, mx: 2 },
  };
});
