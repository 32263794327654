import { useGetCallSettings } from "./CallContext";
import { useCallback, useMemo } from "react";
import { useVisitStatus } from "./useVisitStatus";
import { useTranslation } from "@toolkit/i18n";
import { useCallInfo } from "./useCallInfo";
import { useConferenceConfig } from "./useConferenceConfig";
import { useNetwork } from "@toolkit/core";

export const useMeetingInfo = (visitId: string | undefined) => {
  const { t } = useTranslation();
  const settingsInfo = useGetCallSettings();
  const visitInfo = useVisitStatus(visitId);
  const conferenceInfo = useConferenceConfig(visitInfo.visit?.vendorId);
  const callInfo = useCallInfo(visitInfo.visit, conferenceInfo.data);
  const { online: isOnline } = useNetwork();

  const isLoading = settingsInfo.loading || conferenceInfo.loading || visitInfo.loading || callInfo.loading;
  const hasError = !visitId || !!settingsInfo.error || !!conferenceInfo.error || !!visitInfo.error || !!callInfo.error;
  const handleRetry = useCallback(async () => {
    if (!settingsInfo.type || settingsInfo.error) {
      await settingsInfo.handleRetry?.();
    }

    if (!conferenceInfo.data || conferenceInfo.error) {
      await conferenceInfo.handleRetry();
    }

    if (!visitInfo.visit || visitInfo.error) {
      await visitInfo.handleRetry();
    }

    if (!callInfo.call || callInfo.error) {
      await callInfo.handleRetry();
    }
  }, [conferenceInfo, callInfo, settingsInfo, visitInfo]);

  const errorDetails = useMemo(() => {
    if (!visitId) {
      return t("Invalid visit ID.");
    }

    if (!isOnline) {
      return t("Please check your internet connection and try again.");
    }

    if (!settingsInfo.type || settingsInfo.error) {
      return t("Unable to Load Settings Details.");
    }

    if (!visitInfo.visit || visitInfo.error) {
      return t("Unable to Load Visit Details.");
    }

    if (!visitInfo.visit?.callId) {
      return t("Invalid call ID.");
    }

    if (conferenceInfo.error) {
      return t("Unable to Load Config Details.");
    }

    if (!callInfo.call || callInfo.error) {
      return t("Unable to Load Meeting Details.");
    }
  }, [
    callInfo.call,
    callInfo.error,
    conferenceInfo.error,
    settingsInfo.error,
    settingsInfo.type,
    t,
    visitId,
    visitInfo.error,
    visitInfo.visit,
    isOnline,
  ]);

  return { visit: visitInfo.visit, call: callInfo.call, joined: callInfo.joined, isLoading, hasError, handleRetry, errorDetails };
};
