import { makeStyles } from "@toolkit/ui";

export const useExpandableCardStyles = makeStyles()(theme => ({
  container: {
    boxShadow: theme.mixins.shadows.main,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    overflow: "hidden",
    height: "fit-content",
    paddingInline: "10px",
    paddingBlock: "0",
  },
  headerContainer: {
    height: "100%",
    minHeight: "55px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  headerContainerExpand: {
    borderBottom: "1px solid",
    borderColor: theme.palette.divider,
  },
  toggleIcon: {
    marginLeft: theme.spacing(1),
  },
  expandIcon: {
    transition: "transform 0.3s ease-in-out",

    "& svg": {
      width: "10px",
    },
  },
  expanded: {
    transform: "rotate(180deg)",
  },
  contentContainer: {
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(1),
  },
}));
