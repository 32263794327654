import { FC } from "react";
import { Box, Skeleton } from "@toolkit/ui";
import { useMarketplaceOrderCardStyles } from "./useMarketplaceOrderCard.styles";
import { CustomCard } from "@/shared/components";

export const MarketplaceOrderCardSkeleton: FC = () => {
  const { classes } = useMarketplaceOrderCardStyles();

  return (
    <CustomCard className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.vendorAvatarContainer}>
          <Skeleton variant='circular' width={50} height={50} />
        </Box>

        <Box className={classes.contentInfoContainer}>
          <Skeleton variant='text' width='60%' height={24} />
          <Skeleton variant='text' width='40%' height={20} />
          <Skeleton variant='text' width='30%' height={20} />
          <Skeleton variant='text' width='50%' height={20} />
        </Box>

        <Box>
          <Skeleton variant='circular' width={32} height={32} />
        </Box>
      </Box>

      <Box className={classes.footer}>
        <Skeleton variant='rectangular' width={120} height={45} sx={{ borderRadius: "22.5px" }} />
        <Skeleton variant='rectangular' width={140} height={45} sx={{ borderRadius: "22.5px", marginInlineStart: "10px" }} />
      </Box>
    </CustomCard>
  );
};
