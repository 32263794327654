import { makeStyles } from "@toolkit/ui";

export const useCommonFormStyles = makeStyles()(() => ({
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    gap: "15px",
  },
  textField: {
    "& .MuiInputBase-root": {
      borderRadius: "28px",
      height: "55px",
      border: "none",
      "&.Mui-error": {
        borderWidth: "1px",
        "& svg": {
          fill: "inherit",
        },
      },
    },
    "& .MuiInputBase-input": {
      textIndent: "12.5px",
      width: "100%",
      padding: "5px",
      borderRadius: "28px",
    },
    "& .MuiFilledInput-input:active": {
      backgroundColor: "inherit",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "&:hover .MuiInputBase-root": {
      borderWidth: "1px",
    },
    "&:focus-within .MuiInputBase-root": {
      borderWidth: "1px",
    },
    "& input:-webkit-autofill": {
      borderRadius: "28px",
    },
    "& .MuiFormLabel-root": {
      lineHeight: 2,
    },
  },
}));
