import { ReelsContainer } from "@/domains/reelsAndVideos/containers/ReelsContainer/ReelsContainer";
import { Section } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import React from "react";
import { useCommonSectionsStyle } from "../../Styles/CommonSectionsStyle";
import { SeeAllButton } from "../SeeAllButton/SeeAllButton";
import { useStyle } from "./ReelsSectionStyle";

export const ReelsSection: React.FC = () => {
  const { t } = useTranslation("consumer");
  const { classes, cx } = useStyle();
  const { classes: commonClasses } = useCommonSectionsStyle();

  const handleSeeAll = () => {
    // implement see all
  };
  return (
    <Section
      title={t("Videos & Reels")}
      onSeeAll={handleSeeAll}
      classes={{
        root: cx(commonClasses.root, classes.root, "section__root", "reels"),
        container: cx(commonClasses.container, classes.container, "section__container"),
        titleContainer: cx(commonClasses.titleContainer),
        title: cx(commonClasses.title, "title"),
        titleSeeAll: cx(commonClasses.titleSeeAll, "title__btn"),
      }}
      footer={
        <SeeAllButton
          classes={{
            seeAllButton__container: cx(commonClasses.seeAllButton__container__Lg),
          }}
          onClick={handleSeeAll}
        />
      }
    >
      <ReelsContainer classes={classes} />
    </Section>
  );
};
