import { NotificationWrapperIcon } from "@/shared/icons";
import { Box, Skeleton } from "@toolkit/ui"; // or your Box component's source
import { FC } from "react";
import { useNotificationDetailsStyle } from "../../styles/NotificationDetailsStyle";

export const NotificationDetailsSkeleton: FC = () => {
  const { classes, theme } = useNotificationDetailsStyle();

  return (
    <Box className={classes.contentContainer}>
      <Box className={classes.notificationsIcon}>
        <NotificationWrapperIcon fill={theme.palette.gray[300]} />
      </Box>
      <Box className={classes.content}>
        <Skeleton variant='text' width='100%' height={20} />
        <Skeleton variant='text' width='80%' height={18} />
        <Skeleton variant='text' width='90%' height={20} />
      </Box>
    </Box>
  );
};

export default NotificationDetailsSkeleton;
