import { makeStyles } from "@toolkit/ui";

export const useHealthPackageSelectedCardStyle = makeStyles()(theme => ({
  card: {
    padding: 0,
  },
  itemAvatarContainer: {
    flex: 1.5,
  },
  infoContainer: {
    flex: 3,
    padding: "15px 15px 15px 0",
  },
  itemName: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  itemDiscretion: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  image: {
    minHeight: "70px",
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  imageRoot: {
    width: "100%",
    height: "100%",
  },
}));
