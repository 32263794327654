import { useEffect, useMemo } from "react";

import { InitConfig } from "./types";
import { useActiveShare } from "./useActiveShare";
import { useConnectionState } from "./useConnectionState";
import { useUsers } from "./useUsers";
import { ZoomVideo } from "./types";

export const useZoomContext = (config?: InitConfig) => {
  const client = useMemo(() => ZoomVideo.createClient(), []);
  const debug = !!config?.enableLog || process.env.NODE_ENV !== "production";
  const { connectionState, isConnecting, joinSession, leaveSession, mediaState, mediaStream, resetConnectionState } = useConnectionState(
    client,
    debug
  );
  const { users, currentUser } = useUsers({ client, connectionState });
  const activeShare = useActiveShare({ client, connectionState, mediaStream });

  useEffect(() => {
    return () => {
      ZoomVideo.destroyClient();
    };
  }, []);

  return useMemo(
    () => ({
      debug,
      mediaState,
      mediaStream,
      activeShare,
      connectionState,
      users,
      currentUser,
      client,
      isConnecting,
      joinSession,
      leaveSession,
      resetConnectionState,
    }),
    [
      debug,
      mediaState,
      mediaStream,
      activeShare,
      connectionState,
      users,
      currentUser,
      client,
      isConnecting,
      joinSession,
      leaveSession,
      resetConnectionState,
    ]
  );
};
