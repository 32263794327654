import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { MarketplaceCartFragmentFragmentDoc } from '../../fragments/__generated__/MarketplaceCartFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceCartUpdateItemQuantityMutationVariables = Types.Exact<{
  cartItemId: Types.Scalars['ID']['input'];
  quantity: Types.Scalars['Int']['input'];
}>;


export type MarketplaceCartUpdateItemQuantityMutation = { __typename?: 'Mutation', marketplaceCartUpdateItemQuantity?: { __typename?: 'MarketplaceCart', id: string, totalPrice: any, totalDiscount: any, totalPriceWithVAT: any, totalVat: any, totalDeliveryPrice: any, items: Array<{ __typename?: 'MarketplaceCartItem', quantity: number, id: string, healthPackage?: { __typename?: 'MarketplaceHealthPackage', description?: string | null, descriptionAr?: string | null, id: string, mainImageUrl?: string | null, name?: string | null, nameAr?: string | null, price?: any | null, targetToAge?: number | null, shortDescriptionAr?: string | null, shortDescription?: string | null } | null, product?: { __typename?: 'MarketplaceProduct', id: string, mainImageUrl?: string | null, name?: string | null, nameAr?: string | null, price?: any | null, sku?: string | null, stockQuantity: number, shortDescription?: string | null, shortDescriptionAr?: string | null } | null }> } | null };


export const MarketplaceCartUpdateItemQuantityDocument = gql`
    mutation MarketplaceCartUpdateItemQuantity($cartItemId: ID!, $quantity: Int!) {
  marketplaceCartUpdateItemQuantity(cartItemId: $cartItemId, quantity: $quantity) {
    ...MarketplaceCartFragment
  }
}
    ${MarketplaceCartFragmentFragmentDoc}`;
export type MarketplaceCartUpdateItemQuantityMutationFn = Apollo.MutationFunction<MarketplaceCartUpdateItemQuantityMutation, MarketplaceCartUpdateItemQuantityMutationVariables>;

/**
 * __useMarketplaceCartUpdateItemQuantityMutation__
 *
 * To run a mutation, you first call `useMarketplaceCartUpdateItemQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceCartUpdateItemQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceCartUpdateItemQuantityMutation, { data, loading, error }] = useMarketplaceCartUpdateItemQuantityMutation({
 *   variables: {
 *      cartItemId: // value for 'cartItemId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useMarketplaceCartUpdateItemQuantityMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceCartUpdateItemQuantityMutation, MarketplaceCartUpdateItemQuantityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceCartUpdateItemQuantityMutation, MarketplaceCartUpdateItemQuantityMutationVariables>(MarketplaceCartUpdateItemQuantityDocument, options);
      }
export type MarketplaceCartUpdateItemQuantityMutationHookResult = ReturnType<typeof useMarketplaceCartUpdateItemQuantityMutation>;
export type MarketplaceCartUpdateItemQuantityMutationResult = Apollo.MutationResult<MarketplaceCartUpdateItemQuantityMutation>;
export type MarketplaceCartUpdateItemQuantityMutationOptions = Apollo.BaseMutationOptions<MarketplaceCartUpdateItemQuantityMutation, MarketplaceCartUpdateItemQuantityMutationVariables>;