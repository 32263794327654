import { memo } from "react";
import { Box } from "@toolkit/ui";
import moment from "moment-timezone";
import { TimeSlot } from "../../others/types";

interface TimeSlotItemProps {
  slot: TimeSlot;
  dateInfo: { apiDate: string } | null;
  timeZoneInfo: { userTz: string; systemTz: string };
  selectedSlot?: TimeSlot;
  onSlotSelect: (slot?: TimeSlot) => void;
  className: string;
  cx: (...args: any[]) => string;
}

export const TimeSlotItem = memo<TimeSlotItemProps>(({ slot, dateInfo, timeZoneInfo, selectedSlot, onSlotSelect, className, cx }) => {
  if (!dateInfo) return null;

  const systemStart = moment.tz(`${dateInfo.apiDate}T${slot.startTime}`, timeZoneInfo.systemTz);
  const systemEnd = moment.tz(`${dateInfo.apiDate}T${slot.endTime}`, timeZoneInfo.systemTz);

  const userStart = systemStart.clone().tz(timeZoneInfo.userTz);
  const userEnd = systemEnd.clone().tz(timeZoneInfo.userTz);

  const isSelected = selectedSlot?.id === slot.id;
  const isDisabled = !slot.isActive || moment().isAfter(systemStart);

  return (
    <Box
      className={cx(className, {
        selected: isSelected,
        disabled: isDisabled,
      })}
      onClick={() => slot.isActive && onSlotSelect(slot)}
    >
      {userStart.format("h:mm A")} - {userEnd.format("h:mm A")}
    </Box>
  );
});
