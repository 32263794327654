import { useTranslation } from "@/i18n/i18n.config";
import { useAddToast } from "@toolkit/ui";
import { useDependentCreateMutation } from "../../gql/mutations";
import { formatGraphQLError } from "@toolkit/apollo";
import { useHeaderContext } from "@/shared/context";
import { useEffect } from "react";
import { useCustomForm } from "@toolkit/core";
import { useAuth } from "react-oidc-context";
import { PersonGenderEnum } from "@/schema/types";
import { CreateDependentFormSchema, ICreateDependentFormValues } from "./CreateDependentContainerFormSchema";

export const useCreateDependentHook = () => {
  const { t } = useTranslation("consumer");
  const { failed, succeeded } = useAddToast();
  const { setHeaderTitle } = useHeaderContext();
  const { user } = useAuth();
  const form = useCustomForm<ICreateDependentFormValues>({
    schema: CreateDependentFormSchema,
    mode: "onChange",
  });
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = form;
  const [dependentCreateMutation, { loading }] = useDependentCreateMutation({
    onCompleted: data => {
      if (!data?.customerCreate?.patientErrors) {
        succeeded(t("Dependent created successfully"));
      } else {
        failed(t("Failed to create dependent"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const onDependentCreate = (input: ICreateDependentFormValues) => {
    const { gender } = input;
    dependentCreateMutation({
      variables: {
        input: {
          ...input,
          gender: gender?.value as unknown as PersonGenderEnum,
          isDependent: true,
        },
      },
    });
  };
  useEffect(() => {
    setHeaderTitle(t("Add New Dependent"));
  }, [setHeaderTitle, t]);
  const accessToken = user?.access_token;
  return {
    onDependentCreate,
    loading,
    form,
    handleSubmit,
    setValue,
    control,
    errors,
    accessToken,
  };
};
