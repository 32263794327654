import { SvgIconProps, SvgIcon, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const EmptyFilesIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props?.fill || theme.palette.primary.main;
  return (
    <SvgIcon {...props} id='medical_files3_' width='137.035' height='133.545' viewBox='0 0 130.49 122.598'>
      <g id='medical_files3_' data-name='medical files3 ' transform='translate(-65.379 -66.252)'>
        <path
          id='Path_12746'
          d='M127.17,186.363c-14.225-3.218-27.068-8.3-38.894-17.33-11.713-9-22.467-21.874-22.891-35.056-.282-13.153,9.681-26.56,20.548-37.088a108.683,108.683,0,0,1,36.354-24.048c14-5.786,30.737-9.568,42.168-3.5,11.375,6.125,17.443,22.1,20.858,36.89,3.472,14.762,4.262,28.338,1.722,42.451s-8.439,28.648-19.221,35.281c-10.782,6.718-26.39,5.476-40.644,2.4'
          transform='translate(0)'
          fill='#2b3078'
          opacity='0.09'
        />
        <g id='Group_14016' data-name='Group 14016' transform='translate(70.833 80.999)'>
          <g id='Folder_Empty' transform='translate(0 0)'>
            <path
              id='Path_20908'
              data-name='Path 20908'
              d='M237.561,231.727,231.3,273.641a8.966,8.966,0,0,1-8.891,7.649h-.452a5.3,5.3,0,0,0,5.306-5.306,4.621,4.621,0,0,0-.113-1.073l-7.705-37.4a6.373,6.373,0,0,0-6.238-5.081H168.1l-3.782-6.21a6.409,6.409,0,0,0-5.447-3.077h-2.117a6.374,6.374,0,0,1,4.742-7.677,6.508,6.508,0,0,1,1.468-.169h10.782a6.372,6.372,0,0,1,5.391,2.964L183,224.388h48.237a6.372,6.372,0,0,1,6.379,6.379C237.618,231.078,237.59,231.388,237.561,231.727Z'
              transform='translate(-136.29 -187.978)'
              fill='#afbad8'
            />
            <path
              id='Path_20909'
              data-name='Path 20909'
              d='M390.6,254.91,388.8,267.047a24.461,24.461,0,0,1-19-19.447h14.508a6.372,6.372,0,0,1,6.379,6.379Q390.644,254.4,390.6,254.91Z'
              transform='translate(-289.33 -211.161)'
              fill='#2b3078'
              opacity='0.25'
            />
            <path
              id='Path_20910'
              data-name='Path 20910'
              d='M177.206,301.215H110a6.35,6.35,0,0,1-6.238-5.081l-9.4-45.358a6.374,6.374,0,0,1,4.968-7.536,6.533,6.533,0,0,1,1.3-.141h13.52a6.346,6.346,0,0,1,5.447,3.077l3.782,6.21h45.075a6.35,6.35,0,0,1,6.238,5.081l7.734,37.4a5.29,5.29,0,0,1-4.121,6.266A7.232,7.232,0,0,1,177.206,301.215Z'
              transform='translate(-91.543 -207.932)'
              fill='#dfe6f7'
            />
            <path
              id='Path_20911'
              data-name='Path 20911'
              d='M175.066,299.909h-67.2a7.276,7.276,0,0,1-7.085-5.758l-9.371-45.358a7.22,7.22,0,0,1,5.617-8.552,6.319,6.319,0,0,1,1.468-.141h13.492a7.3,7.3,0,0,1,6.181,3.472l3.528,5.786h44.6a7.249,7.249,0,0,1,7.085,5.786l7.734,37.4a6.15,6.15,0,0,1-4.77,7.254A6.443,6.443,0,0,1,175.066,299.909ZM98.491,241.794a5.541,5.541,0,0,0-5.532,5.532,4.861,4.861,0,0,0,.113,1.1l9.343,45.358a5.53,5.53,0,0,0,5.419,4.4h67.232a4.469,4.469,0,0,0,4.46-4.46,4.672,4.672,0,0,0-.085-.9l-7.734-37.4a5.53,5.53,0,0,0-5.419-4.4H120.733l-4.008-6.6a5.578,5.578,0,0,0-4.742-2.653H98.491Z'
              transform='translate(-89.403 -205.778)'
              fill={fill}
            />
            <path
              id='Path_20912'
              data-name='Path 20912'
              d='M220.264,279.956h-.452v-1.694a4.469,4.469,0,0,0,4.46-4.46,4.671,4.671,0,0,0-.085-.9l-7.734-37.4a5.53,5.53,0,0,0-5.419-4.4H165.479l-4.008-6.6a5.578,5.578,0,0,0-4.742-2.653h-2.794l-.141-.649a7.211,7.211,0,0,1,5.363-8.693,6.562,6.562,0,0,1,1.665-.2H171.6a7.175,7.175,0,0,1,6.1,3.359l3.613,5.73H229.07a7.24,7.24,0,0,1,7.226,7.226,6.866,6.866,0,0,1-.085,1.073c0,.028.141-.931-6.266,41.914A9.782,9.782,0,0,1,220.264,279.956ZM166.438,229.4h44.6a7.275,7.275,0,0,1,7.085,5.758l7.734,37.4a6.1,6.1,0,0,1-.452,3.867,8.164,8.164,0,0,0,2.935-5.109l6.238-41.886a5.542,5.542,0,0,0-4.657-6.294,7.982,7.982,0,0,0-.819-.056H180.41l-4.121-6.52a5.44,5.44,0,0,0-4.685-2.568H160.822a5.541,5.541,0,0,0-5.532,5.532,4.784,4.784,0,0,0,.028.621h1.411a7.3,7.3,0,0,1,6.181,3.472Z'
              transform='translate(-134.149 -185.825)'
              fill={fill}
            />
            <circle
              id='Ellipse_3247'
              data-name='Ellipse 3247'
              cx='19.758'
              cy='19.758'
              r='19.758'
              transform='translate(84.704 12.221)'
              fill='#fff'
            />
            <path
              id='Path_20913'
              data-name='Path 20913'
              d='M402.3,200.009a20.6,20.6,0,1,1,20.6-20.6,20.61,20.61,0,0,1-20.6,20.6Zm0-39.487a18.911,18.911,0,1,0,18.911,18.911h0A18.894,18.894,0,0,0,402.3,160.522Z'
              transform='translate(-297.871 -147.425)'
              fill={fill}
            />
            <path
              id='Path_20914'
              data-name='Path 20914'
              d='M358.51,154.619a2.71,2.71,0,1,1,2.71-2.71h0A2.71,2.71,0,0,1,358.51,154.619Zm0-3.726a1.016,1.016,0,1,0,1.016,1.016h0a1.012,1.012,0,0,0-1.016-1.016Z'
              transform='translate(-279.282 -140.535)'
              fill={fill}
            />
            <path
              id='Path_20915'
              data-name='Path 20915'
              d='M401.026,125.951a3.726,3.726,0,1,1,3.726-3.726,3.74,3.74,0,0,1-3.726,3.726Zm0-5.758a2.032,2.032,0,1,0,2.032,2.032h0A2.024,2.024,0,0,0,401.026,120.194Z'
              transform='translate(-309.068 -118.5)'
              fill={fill}
            />
            <path
              id='Path_20916'
              data-name='Path 20916'
              d='M100.508,458.235l2.681-2.681a.818.818,0,0,1,1.185,0h0a.818.818,0,0,1,0,1.185l-2.681,2.681a.818.818,0,0,1-1.185,0h0A.871.871,0,0,1,100.508,458.235Z'
              transform='translate(-95.879 -360.238)'
              fill={fill}
            />
            <path
              id='Path_20917'
              data-name='Path 20917'
              d='M128.747,466.4h0a.832.832,0,0,1,.847.847v3.782a.832.832,0,0,1-.847.847h0a.832.832,0,0,1-.847-.847v-3.782A.849.849,0,0,1,128.747,466.4Z'
              transform='translate(-115.707 -368.205)'
              fill={fill}
            />
            <path
              id='Path_20918'
              data-name='Path 20918'
              d='M85.547,438.4h3.782a.832.832,0,0,1,.847.847h0a.832.832,0,0,1-.847.847H85.547a.832.832,0,0,1-.847-.847h0A.849.849,0,0,1,85.547,438.4Z'
              transform='translate(-84.7 -348.108)'
              fill={fill}
            />
            <rect
              id='Rectangle_9088'
              data-name='Rectangle 9088'
              width='1.694'
              height='1.694'
              transform='translate(91.732 79.341)'
              fill='#a9b4d3'
            />
            <path
              id='Path_20919'
              data-name='Path 20919'
              d='M439.41,401.293h-1.694V399.6h1.694Zm-4.516,0H433.2V399.6h1.694Z'
              transform='translate(-334.835 -320.259)'
              fill='#a9b4d3'
            />
            <path
              id='Path_20920'
              data-name='Path 20920'
              d='M466.594,343.874H464.9V342.18h1.694Zm0-4.516H464.9v-1.693h1.694Zm0-4.516H464.9v-1.694h1.694Zm0-4.516H464.9v-1.694h1.694Zm0-4.516H464.9v-1.693h1.694Zm0-4.516H464.9V319.6h1.694Z'
              transform='translate(-357.588 -262.839)'
              fill='#a9b4d3'
            />
          </g>
        </g>
        <g id='Group_14017' data-name='Group 14017' transform='translate(166.407 104.212)'>
          <rect
            id='Rectangle_9089'
            data-name='Rectangle 9089'
            width='6.464'
            height='19.052'
            transform='translate(1.696 3.6) rotate(-33.841)'
            fill='#2b3078'
          />
          <rect
            id='Rectangle_9090'
            data-name='Rectangle 9090'
            width='19.052'
            height='6.464'
            transform='translate(0 12.3) rotate(-33.841)'
            fill='#2b3078'
          />
        </g>
      </g>
    </SvgIcon>
  );
};
