import { BlobErrorIcon, BlobInfoIcon, BlobSuccessIcon, BlobWarningIcon } from "@toolkit/ui";
import { FC } from "react";

type BlobStatusIconsProps = {
  status: "info" | "warning" | "error" | "success";
};

export const BlobStatusIcons: FC<BlobStatusIconsProps> = props => {
  const { status } = props;
  switch (status) {
    case "info":
      return <BlobInfoIcon />;

    case "warning":
      return <BlobWarningIcon />;
    case "error":
      return <BlobErrorIcon />;
    case "success":
      return <BlobSuccessIcon />;
    default:
      return <BlobSuccessIcon />;
  }
};
