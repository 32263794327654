import { FC, useEffect, useState } from "react";
import { Box, Button, Typography } from "@toolkit/ui";
import { useHeaderContext } from "@/shared/context";
import { useTranslation } from "@toolkit/i18n";
import { useCommonContainerStyles } from "@/shared/styles";
import moment from "moment";
import { DeliveryTimeSelectionContainerDataProps, TimeSlot } from "../../others/types";
import { DateSelector } from "../../components/DateSelector/DateSelector";
import { TimeSlots } from "../../components/DateSelector/TimeSlots";
import { DeliveryTypes } from "@/schema/types";
import { useLocation, Location } from "react-router-dom";
import { useDispensePrescription } from "../../hooks";

export const DeliveryTimeSelectionContainer: FC = () => {
  const { classes, cx, theme } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const { setHeaderTitle } = useHeaderContext();
  const location: Location<DeliveryTimeSelectionContainerDataProps> = useLocation();
  const navData = location?.state || {};
  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));
  const [selectedSlot, setSelectedSlot] = useState<TimeSlot | undefined>(undefined);

  const { loading, handleRequestDispensePrescription } = useDispensePrescription({
    addressId: navData?.selectedAddress?.id,
    deliveryDate: selectedDate,
    deliveryTimeSlotId: selectedSlot?.id,
    isDeliveryRequested: navData?.selectedDeliveryType === DeliveryTypes.Delivery,
    prescriptionId: navData?.prescriptionID,
  });

  useEffect(() => {
    setHeaderTitle(t("Select Delivery Time"));
    return () => setHeaderTitle("");
  }, [setHeaderTitle, t]);

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography mb={1} textAlign={"start"} fontWeight={theme.mixins.fonts.fontWeight.medium} fontSize={theme.mixins.fonts.fontSize.md}>
          {t("Pick a Delivery Date")}
        </Typography>
        <DateSelector selectedDate={selectedDate} onDateSelect={setSelectedDate} />
        <Typography
          marginY={1}
          textAlign={"start"}
          fontWeight={theme.mixins.fonts.fontWeight.medium}
          fontSize={theme.mixins.fonts.fontSize.md}
        >
          {t("Time")}
        </Typography>
        <TimeSlots
          deliveryType={navData?.selectedDeliveryType}
          selectedDate={selectedDate}
          selectedSlot={selectedSlot}
          onSlotSelect={setSelectedSlot}
        />
      </Box>
      <Box className={cx(classes.footer)}>
        <Button className={cx(classes.button)} disabled={!selectedSlot?.id || loading} onClick={handleRequestDispensePrescription}>
          {t("Continue")}
        </Button>
      </Box>
    </Box>
  );
};
