import { useCommonContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { MarketHealthPackagesSection } from "../../components/MarketHealthPackagesSection/MarketHealthPackagesSection";
import { MarketProductsSection } from "../../components/MarketProductsSection/MarketProductsSection";
import { ProductBestSellersSection } from "../../components/ProductBestSellersSection/MarketProductsSection";
import { ProductCategoriesContainer } from "../ProductsCategoriesContainer/ProductsCategoriesContainer";

export const HealthMarketContainer: FC = () => {
  const { classes, theme } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <Typography className={classes.pageTitle}>{t("Health Market")}</Typography>

        <Typography pb={1} className={classes.title} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("What are you looking for?")}
        </Typography>
        <ProductCategoriesContainer hasMoreButton countOfCategories={7} />
        <MarketHealthPackagesSection />
        <MarketProductsSection />
        <ProductBestSellersSection />
      </Box>
    </Box>
  );
};
