import { useMemo } from "react";
import { getIceServersInfo } from "./utils";
import { useTurnServerConnectionInfoLazyQuery } from "../../gql/queries";

export const useGetTurnServer = () => {
  const [getTurnServerConnectionInfo, { data, loading, error }] = useTurnServerConnectionInfoLazyQuery();
  const iceServers = useMemo(() => getIceServersInfo(data?.turnServerConnectionInfo), [data?.turnServerConnectionInfo]);

  const getIceServers = async () => {
    if (iceServers) {
      return iceServers;
    }

    try {
      const result = await getTurnServerConnectionInfo();

      return getIceServersInfo(result.data?.turnServerConnectionInfo);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  return {
    data,
    loading,
    error,
    iceServers,
    getIceServers,
  };
};
