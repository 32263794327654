import { Chat } from "../../schema/types";
import { FC, useRef } from "react";
import { Box } from "@toolkit/ui";
import { ChatAvatar } from "../ChatAvatar";
import { ChatView } from "../ChatView";
import { ChatListItemMenu } from "./ChatListItemMenu";
import { useChatListItemStyles } from "./ChatListItem.styles";

export type ChatListItemProps = {
  hasAvatar: boolean;
  isOutgoing: boolean;
  chat: Chat;
};

export const ChatListItem: FC<ChatListItemProps> = ({ hasAvatar, isOutgoing, chat }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { classes } = useChatListItemStyles({ isOutgoing });
  return (
    <Box ref={contentRef} className={classes.root}>
      <Box className={classes.avatar}>{hasAvatar && <ChatAvatar size={32} fontSize={12} value={chat.senderUser} />}</Box>
      <Box className={classes.content}>
        <ChatView chat={chat} isOutgoing={isOutgoing} />
      </Box>
      <ChatListItemMenu anchorRef={contentRef} chat={chat} isOutgoing={isOutgoing} />
    </Box>
  );
};
