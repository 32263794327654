import { TurnServerConnectionInfo, User } from "@/schema/types";
import {
  CallParticipant,
  CustomMediaStreamTrack,
  ExecutedResponse,
  MediaStreamTrackType,
  StreamConfig,
  StreamInfo,
  StreamMetaData,
} from "./types";

export function formatExecutedFailure(error: unknown): ExecutedResponse {
  if (error !== null && typeof error === "object" && "type" in error) {
    return error as ExecutedResponse;
  }

  return { type: "ERROR", reason: error };
}

export async function getUserMedia(
  constraints?: MediaStreamConstraints,
  userMedia = true
): Promise<ExecutedResponse<{ stream: globalThis.MediaStream }>> {
  try {
    if (!("mediaDevices" in navigator)) {
      return { type: "MEDIA_NOT_FOUND" };
    }

    const stream = userMedia
      ? await navigator.mediaDevices.getUserMedia(constraints)
      : await navigator.mediaDevices.getDisplayMedia(constraints);
    return { success: true, stream };
  } catch (error: any) {
    switch (error?.name) {
      case "NotFoundError":
        return { type: "MEDIA_NOT_FOUND" };
      case "PermissionDeniedError":
      case "NotAllowedError":
      case "INSUFFICIENT_PRIVILEGES": {
        return { type: "MEDIA_NOT_ALLOWED" };
      }
      case "NotReadableError": {
        return { type: "MEDIA_NOT_READABLE" };
      }
    }
    return formatExecutedFailure(error);
  }
}

export async function initMediaStream(stream: MediaStream, kind: "audio" | "video", muted: boolean) {
  stream.getTracks().forEach(t => {
    if (
      t.readyState === "ended" ||
      (t.kind === kind && muted) ||
      isCustomMediaTrackType(t, MediaStreamTrackType.canvas) ||
      isCustomMediaTrackType(t, MediaStreamTrackType.screen)
    ) {
      t.stop();
      stream.removeTrack(t);
    }
  });

  if (muted) {
    return { success: true };
  }

  const activeTrack = stream.getTracks().find(t => t.kind === kind);
  if (activeTrack) {
    activeTrack.enabled = true;
    return { success: true };
  }

  const result = await getUserMedia({ [kind]: true });
  if (result.success) {
    const [track] = result.stream.getTracks();
    stream.addTrack(track);
  }

  return result;
}

export function isCustomMediaTrackType(track: CustomMediaStreamTrack, type: MediaStreamTrackType) {
  return track._type === type;
}

export function getVideoSettings(media?: MediaStream | null) {
  try {
    if (media && media.getVideoTracks().length > 0) {
      const { width, height } = media.getVideoTracks()[0].getSettings();
      if (width && height) {
        return { width, height };
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function getCallParticipant(streamInfo: Partial<StreamInfo>, participants: Record<string, User>): CallParticipant {
  const { audioMuted, videoMuted, screenMuted, userIdentity, videoSettings } = streamInfo.metaData || {};
  const userInfo = userIdentity ? participants[userIdentity] : undefined;

  return {
    id: streamInfo.streamId,
    displayName: userInfo?.fullName || streamInfo.streamName,
    info: userInfo ?? {
      __typename: "User",
      fullName: streamInfo.streamName,
    },
    userIdentity: userIdentity,
    isCurrentUser: !!streamInfo.isCurrentStream,
    state: { audioMuted: audioMuted !== false, videoMuted: videoMuted !== false, screenMuted: screenMuted !== false },
    videoSettings,
  };
}

export function parseStreamMetaData(metaData: string, defaultValue?: StreamMetaData): Partial<StreamMetaData> {
  const defaultMetaData = { audioMuted: false, videoMuted: true, screenMuted: true, userIdentity: undefined, ...(defaultValue || {}) };
  try {
    return metaData ? JSON.parse(metaData) : defaultMetaData;
  } catch (error) {
    console.error("Meeting: unable to parse stream metadata", error);
    return defaultMetaData;
  }
}

export function getIceServersInfo(info: TurnServerConnectionInfo | null | undefined): StreamConfig["iceServers"] {
  const { url, username, credential } = info || {};
  if (!url) {
    return null;
  }

  return [{ urls: url, username: username || "", credential: credential || "" }];
}
