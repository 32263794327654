import { OrderStatus } from "@/schema/types";
import { i18n } from "@toolkit/i18n";

export const NUMBER_OF_MAX_ALLOWED_DELIVERY_DAYS = 7;

export const FINAL_ORDER_STATUSES = [
  OrderStatus.CanceledByConsumer,
  OrderStatus.CanceledByProvider,
  OrderStatus.Failed,
  OrderStatus.FailedToDispense,
  OrderStatus.RejectedByProvider,
  OrderStatus.Returned,
] as Array<OrderStatus>;

export const ERX_HUB_FINAL_STATUSES = [
  OrderStatus.ErxHubAuthRequestFailed,
  OrderStatus.ErxHubAuthSubmitTimedOut,
  OrderStatus.ErxHubClaimFailed,
  OrderStatus.ErxHubClaimRejected,
  OrderStatus.ErxHubClaimSubmitTimedOut,
  OrderStatus.ErxHubRejected,
  OrderStatus.ErxHubTimedOut,
  OrderStatus.PaymentTimedOut,
] as Array<OrderStatus>;

export const ORDER_STATUS_MESSAGES = {
  [OrderStatus.CanceledByProvider]: i18n.t("Your order has been canceled by the provider", { ns: "consumer" }),
  [OrderStatus.ErxHubAuthRequestFailed]: i18n.t("We couldn't authorize your prescription. Please verify your information", {
    ns: "consumer",
  }),
  [OrderStatus.ErxHubAuthSubmitTimedOut]: i18n.t("Your prescription authorization request timed out. Please try again", { ns: "consumer" }),
  [OrderStatus.ErxHubClaimFailed]: i18n.t("We couldn't process your insurance claim. Please verify your insurance information", {
    ns: "consumer",
  }),
  [OrderStatus.ErxHubClaimRejected]: i18n.t("Your insurance claim was rejected. Please check your coverage details", { ns: "consumer" }),
  [OrderStatus.ErxHubClaimSubmitTimedOut]: i18n.t("Your insurance claim submission timed out. Please try again", { ns: "consumer" }),
  [OrderStatus.ErxHubPartiallyApproved]: i18n.t("Your prescription was partially approved. Please review the details", { ns: "consumer" }),
  [OrderStatus.ErxHubRejected]: i18n.t("Your prescription request was rejected. Please contact your healthcare provider", {
    ns: "consumer",
  }),
  [OrderStatus.ErxHubTimedOut]: i18n.t("Your prescription request timed out. Please try again", { ns: "consumer" }),
  [OrderStatus.Failed]: i18n.t("Your order failed. Please try again", { ns: "consumer" }),
  [OrderStatus.FailedToDispense]: i18n.t("We couldn't dispense your medication. Please contact customer support", { ns: "consumer" }),
  [OrderStatus.PaymentTimedOut]: i18n.t("Your payment processing timed out. Please try again", { ns: "consumer" }),
  [OrderStatus.RejectedByProvider]: i18n.t("Your order was not approved by the provider. Please contact your healthcare provider", {
    ns: "consumer",
  }),
  [OrderStatus.Returned]: i18n.t("Your order has been returned", { ns: "consumer" }),
} as Record<OrderStatus, string>;

export const ORDER_PROGRESS_MESSAGES = {
  [OrderStatus.WaitingErxHubClaimApproval]: i18n.t("Insurance approval in progress", { ns: "consumer" }),
  [OrderStatus.ErxHubClaimApproved]: i18n.t("Prescription approved, preparing your order", { ns: "consumer" }),
  [OrderStatus.PaymentSucceeded]: i18n.t("Payment confirmed, processing your order", { ns: "consumer" }),
  [OrderStatus.Dispensed]: i18n.t("Order has been prepared and packaged", { ns: "consumer" }),
  [OrderStatus.ReadyForCustomerPickup]: i18n.t("Your order is ready for pickup", { ns: "consumer" }),
  [OrderStatus.PaymentOnArrive]: i18n.t("Order ready - payment required at pickup", { ns: "consumer" }),
  [OrderStatus.OutForDelivery]: i18n.t("Your order is out for delivery", { ns: "consumer" }),
} as Record<OrderStatus, string>;

export const ORDER_IN_PROGRESS_STATUSES = [
  OrderStatus.PaymentSucceeded,
  OrderStatus.WaitingErxHubClaimApproval,
  OrderStatus.ErxHubClaimApproved,
  OrderStatus.Dispensed,
];

export const CAN_CANCEL_ORDER_STATUSES = [
  OrderStatus.NewRequest,
  OrderStatus.WaitingProviderSelection,
  OrderStatus.Allocated,
  OrderStatus.PaymentPending,
  OrderStatus.PaymentFailed,
] as Array<OrderStatus>;
