import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../fragments/__generated__/address';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAddressQueryVariables = Types.Exact<{
  addressId: Types.Scalars['ID']['input'];
}>;


export type GetAddressQuery = { __typename?: 'Query', address?: { __typename?: 'Address', id: string, streetAddress1: string, streetAddress2: string, phone?: string | null, name?: string | null, nameAr?: string | null, buildingNumber?: string | null, unitNumber?: string | null, isDefaultShippingAddress?: boolean | null, isDefaultBillingAddress?: boolean | null, area: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lat?: number | null, lng?: number | null } | null } | null };


export const GetAddressDocument = gql`
    query GetAddress($addressId: ID!) {
  address(id: $addressId) {
    ...AddressFragment
  }
}
    ${AddressFragmentFragmentDoc}`;

/**
 * __useGetAddressQuery__
 *
 * To run a query within a React component, call `useGetAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressQuery({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useGetAddressQuery(baseOptions: Apollo.QueryHookOptions<GetAddressQuery, GetAddressQueryVariables> & ({ variables: GetAddressQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddressQuery, GetAddressQueryVariables>(GetAddressDocument, options);
      }
export function useGetAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressQuery, GetAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddressQuery, GetAddressQueryVariables>(GetAddressDocument, options);
        }
export function useGetAddressSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAddressQuery, GetAddressQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAddressQuery, GetAddressQueryVariables>(GetAddressDocument, options);
        }
export type GetAddressQueryHookResult = ReturnType<typeof useGetAddressQuery>;
export type GetAddressLazyQueryHookResult = ReturnType<typeof useGetAddressLazyQuery>;
export type GetAddressSuspenseQueryHookResult = ReturnType<typeof useGetAddressSuspenseQuery>;
export type GetAddressQueryResult = Apollo.QueryResult<GetAddressQuery, GetAddressQueryVariables>;