import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/queries/__generated__/pageInfoFragment';
import { ProductInfoFragmentDoc } from '../../fragments/__generated__/ProductInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductsForYouQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  vendorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type MarketplaceProductsForYouQuery = { __typename?: 'Query', marketplaceProductsForYou?: { __typename?: 'MarketplaceProductConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges: Array<{ __typename?: 'MarketplaceProductEdge', node: { __typename?: 'MarketplaceProduct', id: string, name?: string | null, nameAr?: string | null, price?: any | null, description?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, mainImageUrl?: string | null, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, endDate: any, percentage?: number | null, startDate: any, type: Types.MarketplaceDiscountType } | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null }, category?: { __typename?: 'MarketplaceProductCategory', id: string, name?: string | null, nameAr?: string | null } | null } }> } | null };


export const MarketplaceProductsForYouDocument = gql`
    query MarketplaceProductsForYou($after: String, $before: String, $first: Int, $last: Int, $vendorId: ID) {
  marketplaceProductsForYou(
    after: $after
    before: $before
    first: $first
    last: $last
    vendorId: $vendorId
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        ...ProductInfo
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${ProductInfoFragmentDoc}`;

/**
 * __useMarketplaceProductsForYouQuery__
 *
 * To run a query within a React component, call `useMarketplaceProductsForYouQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductsForYouQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceProductsForYouQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useMarketplaceProductsForYouQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceProductsForYouQuery, MarketplaceProductsForYouQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceProductsForYouQuery, MarketplaceProductsForYouQueryVariables>(MarketplaceProductsForYouDocument, options);
      }
export function useMarketplaceProductsForYouLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceProductsForYouQuery, MarketplaceProductsForYouQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceProductsForYouQuery, MarketplaceProductsForYouQueryVariables>(MarketplaceProductsForYouDocument, options);
        }
export function useMarketplaceProductsForYouSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplaceProductsForYouQuery, MarketplaceProductsForYouQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceProductsForYouQuery, MarketplaceProductsForYouQueryVariables>(MarketplaceProductsForYouDocument, options);
        }
export type MarketplaceProductsForYouQueryHookResult = ReturnType<typeof useMarketplaceProductsForYouQuery>;
export type MarketplaceProductsForYouLazyQueryHookResult = ReturnType<typeof useMarketplaceProductsForYouLazyQuery>;
export type MarketplaceProductsForYouSuspenseQueryHookResult = ReturnType<typeof useMarketplaceProductsForYouSuspenseQuery>;
export type MarketplaceProductsForYouQueryResult = Apollo.QueryResult<MarketplaceProductsForYouQuery, MarketplaceProductsForYouQueryVariables>;