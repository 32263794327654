import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import { FC } from "react";

export const PaymentShareCalculatedIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='cashv2' width='147.669' height='130.78' viewBox='0 0 147.669 130.78' {...props}>
      <defs>
        <linearGradient id='linear-gradient' x1='-106.558' y1='21.933' x2='-105.929' y2='21.933' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#cacfdd' />
          <stop offset='1' stopColor='#eef0f1' />
        </linearGradient>
      </defs>
      <path
        id='Path_12746_00000181086977908612989720000002170403974832897214_'
        d='M131.194,194.38c-15.175-3.432-28.875-8.852-41.49-18.487-12.5-9.6-23.967-23.334-24.418-37.4-.3-14.031,10.327-28.333,21.919-39.563a115.936,115.936,0,0,1,38.78-25.653c14.934-6.172,32.789-10.207,44.983-3.734,12.134,6.534,18.607,23.575,22.251,39.352,3.7,15.747,4.546,30.229,1.837,45.284s-9,30.561-20.5,37.636c-11.5,7.166-28.152,5.841-43.357,2.559'
        transform='translate(-56.223 -66.252)'
        fill='#2b3078'
        opacity='0.09'
      />
      <g id='Group_11371' data-name='Group 11371' transform='translate(0 43.13)'>
        <g id='Group_11370' data-name='Group 11370'>
          <g id='paylater'>
            <path
              id='Path_13427'
              data-name='Path 13427'
              d='M38.2,227.682v29.055H60.36l5.721,4.035a21.168,21.168,0,0,0,20.233.391,21.006,21.006,0,0,1,9.725-2.379l17.012-.03a8.7,8.7,0,0,0,8.671-8.7h0v-3.734a10.753,10.753,0,0,0-4.366-8.641l-14.3-10.93a17.43,17.43,0,0,0-18.607-2.8L62.107,233.4Z'
              transform='translate(-37.297 -218.529)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13428'
              data-name='Path 13428'
              d='M64.93,259.485c-.12-.06.331.241-5.57-3.914H37.5v-1.807H59.932l5.931,4.155a20.21,20.21,0,0,0,19.33.361,21.928,21.928,0,0,1,10.117-2.469l14.9-.03a6.385,6.385,0,0,0-.03-12.766l-22.582.03v-1.807c23.967-.06,22.672-.06,23-.03a8.189,8.189,0,0,1,6.022,13.248,7.687,7.687,0,0,0,3.432-6.473V244.25a9.925,9.925,0,0,0-4.035-7.919L101.693,225.4c-5.39-4.366-11.682-5.3-17.7-2.68L61.377,232.3,37.2,226.486l.422-1.746,23.605,5.66,22.07-9.334c6.654-2.89,13.579-1.867,19.511,2.921l14.3,10.9a11.765,11.765,0,0,1,4.727,9.334v3.734a9.6,9.6,0,0,1-9.545,9.6h0l-17.012.03a20.524,20.524,0,0,0-9.3,2.288A21.954,21.954,0,0,1,64.93,259.485Z'
              transform='translate(-36.598 -216.459)'
              fill='#525682'
            />
            <rect
              id='Rectangle_7364'
              data-name='Rectangle 7364'
              width='16.319'
              height='33.933'
              transform='translate(12.074 11.05)'
              fill='#2b3078'
            />
            <path
              id='Path_13429'
              data-name='Path 13429'
              d='M90.4,278.969H72.3V243.2H90.426Zm-16.289-1.807H88.619V245.007H74.107Z'
              transform='translate(-61.13 -233.053)'
              fill='#525682'
            />
            <path
              id='Path_13430'
              data-name='Path 13430'
              d='M38.2,271.776H49.37V237.813L38.2,234.2Z'
              transform='translate(-37.297 -226.763)'
              fill='#525682'
            />
            <path
              id='Path_13431'
              data-name='Path 13431'
              d='M48.177,269.814H35.2V230.1l12.977,4.215Zm-11.17-1.807H46.37v-32.4l-9.364-3.041Z'
              transform='translate(-35.2 -223.898)'
              fill='#525682'
            />
            <path
              id='Path_13432'
              data-name='Path 13432'
              d='M48.177,269.814H35.2V230.1l12.977,4.215Zm-11.17-1.807H46.37v-32.4l-9.364-3.041Z'
              transform='translate(-35.2 -223.898)'
              fill='#525682'
            />
            <g id='Credit_Card' transform='translate(32.219)'>
              <g id='Group_11367' data-name='Group 11367' transform='translate(25.831 14.482)'>
                <path
                  id='Path_13433'
                  data-name='Path 13433'
                  d='M234.626,259.9h61.452a4.414,4.414,0,0,1,4.426,4.426v35.529a4.414,4.414,0,0,1-4.426,4.426H234.626a4.414,4.414,0,0,1-4.426-4.426V264.326A4.414,4.414,0,0,1,234.626,259.9Z'
                  transform='translate(-229.538 -259.207)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13434'
                  data-name='Path 13434'
                  d='M300.5,264.226v35.529a4.433,4.433,0,0,1-4.426,4.426H234.626a4.433,4.433,0,0,1-4.426-4.426V264.226a4.433,4.433,0,0,1,4.426-4.426h61.452A4.453,4.453,0,0,1,300.5,264.226Z'
                  transform='translate(-229.538 -259.138)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13435'
                  data-name='Path 13435'
                  d='M294.541,303.275H233.088A5.1,5.1,0,0,1,228,298.187h0v-35.5a5.1,5.1,0,0,1,5.088-5.088h61.452a5.1,5.1,0,0,1,5.088,5.088v35.529a5.1,5.1,0,0,1-5.088,5.058Zm-61.452-44.32a3.765,3.765,0,0,0-3.764,3.764v35.529a3.765,3.765,0,0,0,3.764,3.764h61.452a3.765,3.765,0,0,0,3.764-3.764V262.719a3.765,3.765,0,0,0-3.764-3.764Z'
                  transform='translate(-228 -257.6)'
                  fill='#525682'
                />
                <rect
                  id='Rectangle_7365'
                  data-name='Rectangle 7365'
                  width='12.857'
                  height='5.42'
                  transform='translate(9.575 26.797)'
                  fill='#2b3078'
                  opacity='0.09'
                />
                <rect
                  id='Rectangle_7366'
                  data-name='Rectangle 7366'
                  width='12.857'
                  height='5.42'
                  transform='translate(29.537 26.797)'
                  fill='#2b3078'
                  opacity='0.09'
                />
                <rect
                  id='Rectangle_7367'
                  data-name='Rectangle 7367'
                  width='12.857'
                  height='5.42'
                  transform='translate(49.469 26.797)'
                  fill='#2b3078'
                  opacity='0.09'
                />
                <rect
                  id='Rectangle_7368'
                  data-name='Rectangle 7368'
                  width='32.819'
                  height='1.325'
                  transform='translate(9.575 37.034)'
                  fill='#2b3078'
                />
                <rect
                  id='Rectangle_7369'
                  data-name='Rectangle 7369'
                  width='18.366'
                  height='2.529'
                  transform='translate(43.989 9.334)'
                  fill='#a9b4d3'
                />
                <rect
                  id='Rectangle_7370'
                  data-name='Rectangle 7370'
                  width='7.648'
                  height='2.529'
                  transform='translate(54.678 14.603)'
                  fill='#a9b4d3'
                />
              </g>
              <path
                id='Path_13436'
                data-name='Path 13436'
                d='M143.049,375.431a1.985,1.985,0,0,1,2.318,3.222,2.016,2.016,0,0,1-1.505.331,1.982,1.982,0,0,1-1.626-2.288A2.424,2.424,0,0,1,143.049,375.431Zm.783,1.084a.669.669,0,0,0,.783,1.084.65.65,0,0,0,.271-.422.636.636,0,0,0-.542-.753.69.69,0,0,0-.512.09Z'
                transform='translate(-142.208 -325.209)'
                fill='#525682'
              />
              <path
                id='Path_13437'
                data-name='Path 13437'
                d='M167.289,388.056a2.706,2.706,0,1,1,3.764.632A2.7,2.7,0,0,1,167.289,388.056Zm3.342-2.409a1.4,1.4,0,0,0-2.288,1.626,1.4,1.4,0,0,0,2.288-1.626Z'
                transform='translate(-159.372 -331.301)'
                fill='#525682'
              />
              <path
                id='Path_13438'
                data-name='Path 13438'
                d='M473.7,333.939v-1.626a4.9,4.9,0,0,0,1.475-.12l.3,1.626A8.816,8.816,0,0,1,473.7,333.939Zm4.577-1.325-.933-1.325a6.354,6.354,0,0,0,1.054-.964l1.234,1.054A7.5,7.5,0,0,1,478.277,332.614Zm2.83-3.914-1.6-.452a5.57,5.57,0,0,0,.241-1.415l1.626.06A5.265,5.265,0,0,1,481.107,328.7Zm.3-4.577h-1.626V322.5h1.626Zm0-4.366h-1.626v-1.626h1.626Zm0-4.366h-1.626v-1.626h1.626Zm0-4.366h-1.626V309.4h1.626Z'
                transform='translate(-373.891 -279.321)'
                fill='#a9b4d3'
              />
              <g id='Group_11369' data-name='Group 11369' transform='translate(79.636)'>
                <path
                  id='Path_13439'
                  data-name='Path 13439'
                  d='M424.374,277A18.054,18.054,0,0,1,406.7,259.6l13.579.271a4.4,4.4,0,0,1,4.336,4.516h0Z'
                  transform='translate(-406.7 -244.515)'
                  fill='#525682'
                  opacity='0.1'
                />
                <circle
                  id='Ellipse_3068'
                  data-name='Ellipse 3068'
                  cx='14.452'
                  cy='14.452'
                  r='14.452'
                  transform='translate(2.86 0.662)'
                  fill='#f5f5f5'
                />
                <path
                  id='Path_13440'
                  data-name='Path 13440'
                  d='M431.115,239.729a15.115,15.115,0,1,1,15.115-15.115A15.1,15.1,0,0,1,431.115,239.729Zm0-28.935a13.79,13.79,0,1,0,13.79,13.79A13.807,13.807,0,0,0,431.115,210.795Z'
                  transform='translate(-413.2 -209.5)'
                  fill='#525682'
                />
                <g id='Group_11368' data-name='Group 11368' transform='translate(13.669 6.052)'>
                  <path
                    id='Path_13441'
                    data-name='Path 13441'
                    d='M456.646,249.425a4.562,4.562,0,0,1-4.546-4.546h2.83a1.7,1.7,0,1,0,1.716-1.686h0a4.546,4.546,0,1,1,4.546-4.546h-2.83a1.686,1.686,0,1,0-3.372,0,1.709,1.709,0,0,0,1.686,1.716h0a4.532,4.532,0,1,1-.03,9.063Z'
                    transform='translate(-452.1 -232.745)'
                    fill='#2b3078'
                  />
                  <rect
                    id='Rectangle_7371'
                    data-name='Rectangle 7371'
                    width='2.83'
                    height='2.83'
                    transform='translate(3.101)'
                    fill='#2b3078'
                  />
                  <rect
                    id='Rectangle_7372'
                    data-name='Rectangle 7372'
                    width='2.83'
                    height='2.83'
                    transform='translate(3.101 15.265)'
                    fill='#2b3078'
                  />
                </g>
              </g>
              <path
                id='Path_13442'
                data-name='Path 13442'
                d='M350.8,421.711a3.02,3.02,0,0,1,3.011,3.011h0a3.02,3.02,0,0,1,3.011-3.011,3.02,3.02,0,0,1-3.011-3.011h0A3,3,0,0,1,350.8,421.711Z'
                transform='translate(-287.995 -355.712)'
                fill='#fff'
              />
            </g>
            <path
              id='Path_13443'
              data-name='Path 13443'
              d='M265.763,285.314a3.615,3.615,0,0,0,1.234-2.74v-.723a3.629,3.629,0,0,0-7.256,0v.723a3.663,3.663,0,0,0,1.234,2.74,4.574,4.574,0,0,0-4.275,4.546v4.306h13.338v-4.275A4.58,4.58,0,0,0,265.763,285.314Z'
              transform='translate(-190.009 -257.584)'
              fill='#2b3078'
            />
            <path
              id='Path_13444'
              data-name='Path 13444'
              d='M200.087,294.73l22.582-.03a7.312,7.312,0,0,1,7.316,7.256h0a7.312,7.312,0,0,1-7.256,7.316h0l-14.9.03a21.1,21.1,0,0,0-9.725,2.379'
              transform='translate(-149.052 -269.047)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13445'
              data-name='Path 13445'
              d='M197.443,310.294l-.843-1.6a21.928,21.928,0,0,1,10.117-2.469l14.9-.03a6.4,6.4,0,0,0,0-12.8l-22.582.03v-1.807l22.582-.03a8.192,8.192,0,0,1,.361,16.379h-.3l-14.9.03A20.553,20.553,0,0,0,197.443,310.294Z'
              transform='translate(-148.004 -266.877)'
              fill='#525682'
            />
            <circle
              id='Ellipse_3069'
              data-name='Ellipse 3069'
              cx='2.439'
              cy='2.439'
              r='2.439'
              transform='translate(17.764 17.463)'
              fill='#f5f5f5'
            />
            <path
              id='Path_13446'
              data-name='Path 13446'
              d='M239.764,390.213h-1.355v-1.807h1.355Zm-4.366,0h-1.807v-1.807H235.4Zm-4.817,0h-1.807v-1.807h1.807Zm-4.817,0h-1.807v-1.807h1.807Zm-4.817,0h-1.807v-1.807h1.807Zm-4.938-.181a13.065,13.065,0,0,1-1.867-.482l.572-1.716a9.186,9.186,0,0,0,1.626.421Zm-4.787-1.927a11.75,11.75,0,0,1-1.536-1.2l1.2-1.355a11.078,11.078,0,0,0,1.325,1.024Zm-3.643-3.643a13.245,13.245,0,0,1-.933-1.686l1.656-.753a11.19,11.19,0,0,0,.813,1.445Zm-1.867-4.787a10.435,10.435,0,0,1-.181-1.927l1.807-.03a10.264,10.264,0,0,0,.151,1.656Zm1.6-4.968H205.5V372.9h1.807Z'
              transform='translate(-154.224 -323.702)'
              fill='#a9b4d3'
            />
          </g>
        </g>
      </g>
      <g id='Group_11372' data-name='Group 11372' transform='translate(109.048 79.552)'>
        <circle
          id='Ellipse_3070'
          data-name='Ellipse 3070'
          cx='16.831'
          cy='16.831'
          r='16.831'
          transform='translate(0 33.228) rotate(-80.782)'
          fill='#46a200'
        />
        <path
          id='Path_13447'
          data-name='Path 13447'
          d='M418.587,334a18.487,18.487,0,1,0,18.487,18.487A18.491,18.491,0,0,0,418.587,334Zm16.68,18.547a16.68,16.68,0,1,1-16.68-16.68A16.7,16.7,0,0,1,435.267,352.547Z'
          transform='translate(-399.281 -332.936)'
          fill='#fff'
        />
        <path
          id='Path_13448'
          data-name='Path 13448'
          d='M449.705,373.273,446,369.6l-9.033,9-4.366-4.366-3.7,3.673,4.4,4.4h0l3.673,3.673h0l3.7-3.673h0Z'
          transform='translate(-419.409 -357.818)'
          fill='#fff'
        />
      </g>
      <g id='Group_11374' data-name='Group 11374' transform='translate(60.014 8.269)'>
        <rect
          id='Rectangle_7373'
          data-name='Rectangle 7373'
          width='2.228'
          height='25.171'
          transform='translate(24.795 22.56) rotate(-9.306)'
          fill='#f5f5f5'
        />
        <path
          id='Path_13449'
          data-name='Path 13449'
          d='M319.1,167.4l1.325,8.069a9.066,9.066,0,0,0-2.2.361l-1.325-8.069Z'
          transform='translate(-292.097 -145.214)'
          fill='url(#linear-gradient)'
        />
        <path
          id='Path_13450'
          data-name='Path 13450'
          d='M315.4,166.382l2.981-.482,4.215,25.653-2.981.482Zm2.318.452-1.385.241,3.944,24.027,1.385-.241Z'
          transform='translate(-291.048 -144.166)'
          fill='#525682'
        />
        <path
          id='Path_13451'
          data-name='Path 13451'
          d='M331.021,238.033a6.012,6.012,0,0,1-6.955-4.878l-1.566-9,4.336-.753,1.566,8.972a1.65,1.65,0,0,0,1.776,1.355h.09a1.608,1.608,0,0,0,1.295-1.867l-.09-.632a2.123,2.123,0,0,1,1.746-2.469l.12-.03h0a2.123,2.123,0,0,1,2.469,1.746l.09.512A6.059,6.059,0,0,1,331.141,238,.228.228,0,0,0,331.021,238.033Z'
          transform='translate(-296.011 -184.353)'
          fill='#25287f'
        />
        <path
          id='Path_13452'
          data-name='Path 13452'
          d='M330.736,264.152A7.259,7.259,0,0,1,323.6,258.1h14.242a7.275,7.275,0,0,1-5.781,5.931.181.181,0,0,0-.12.03A8.415,8.415,0,0,1,330.736,264.152Z'
          transform='translate(-296.78 -208.605)'
          fill='#525682'
          opacity='0.1'
        />
        <g id='Group_11373' data-name='Group 11373'>
          <path
            id='Path_13453'
            data-name='Path 13453'
            d='M300.077,99.491l-.181-4.245a1.492,1.492,0,0,1,.964-1.445,1.463,1.463,0,0,1,1.867.873l1.475,3.974.692,3.4-4.125.843Zm3.252-.6-1.445-3.914a.6.6,0,0,0-.542-.361.566.566,0,0,0-.542.572l.181,4.185.482,2.409,2.348-.482Z'
            transform='translate(-280.212 -93.715)'
            fill='#525682'
          />
          <path
            id='Path_13454'
            data-name='Path 13454'
            d='M301.4,96.214l.181,4.215.572,2.89,3.222-.662-.572-2.89-1.475-3.974a1,1,0,0,0-1.927.422Z'
            transform='translate(-281.26 -94.713)'
            fill='#525682'
          />
          <path
            id='Path_13455'
            data-name='Path 13455'
            d='M288.83,130.743a4.261,4.261,0,0,0-.12-.6,8.928,8.928,0,0,0-17.373,4.125,4.262,4.262,0,0,0-.12-.6,8.928,8.928,0,0,0-17.373,4.125,4.262,4.262,0,0,0-.12-.6,8.928,8.928,0,0,0-17.373,4.125c-.03-.12-.06-.271-.09-.391a26.773,26.773,0,1,1,52.57-10.177Z'
            transform='translate(-235.393 -104.432)'
            fill='#fff'
            opacity='0.09'
          />
          <path
            id='Path_13456'
            data-name='Path 13456'
            d='M257.576,110.362h0A26.758,26.758,0,0,1,289.1,131.287c-.03-.211-.09-.422-.12-.632a8.917,8.917,0,0,0-17.343,4.155,8.93,8.93,0,0,0-17.493,3.553,8.928,8.928,0,0,0-17.493,3.523A26.742,26.742,0,0,1,257.576,110.362Z'
            transform='translate(-235.633 -104.977)'
            fill='#fff'
          />
          <path
            id='Path_13457'
            data-name='Path 13457'
            d='M318.894,127.272A23.547,23.547,0,0,0,306.7,111.013l.422-.813A24.466,24.466,0,0,1,319.8,127.091l1.6,7.889-.9.181Z'
            transform='translate(-284.968 -105.236)'
            fill='#525682'
          />
          <path
            id='Path_13458'
            data-name='Path 13458'
            d='M287.963,131.174a24.424,24.424,0,0,1,5.149-20.474l.692.572a23.56,23.56,0,0,0-4.938,19.721l1.6,7.889-.9.181Z'
            transform='translate(-271.53 -105.586)'
            fill='#525682'
          />
          <path
            id='Path_13459'
            data-name='Path 13459'
            d='M256.375,108.837a27.265,27.265,0,0,1,32.066,21.287l-.9.181a3.2,3.2,0,0,0-.12-.572,8.468,8.468,0,1,0-16.47,3.944l-.9.181a3.2,3.2,0,0,0-.12-.572,8.468,8.468,0,0,0-16.47,3.944l-.9.181a3.2,3.2,0,0,0-.12-.572,8.468,8.468,0,0,0-16.47,3.944l-.9.181A27.3,27.3,0,0,1,256.375,108.837Zm20.986,13.971a9.378,9.378,0,0,1,8.762,2.86,26.319,26.319,0,0,0-50.7,10.237A9.361,9.361,0,0,1,252.4,134.49a9.364,9.364,0,0,1,17.463-3.553,9.44,9.44,0,0,1,7.5-8.129Z'
            transform='translate(-234.522 -103.904)'
            fill='#525682'
          />
        </g>
      </g>
      <g id='Group_11375' data-name='Group 11375' transform='translate(54.762 11.931)'>
        <path
          id='Path_13460'
          data-name='Path 13460'
          d='M231.239,126.731l-2.83-2.258a.908.908,0,0,1-.151-1.265h0a.908.908,0,0,1,1.265-.151l2.83,2.258a.908.908,0,0,1,.151,1.265h0A.858.858,0,0,1,231.239,126.731Z'
          transform='translate(-224.759 -117.752)'
          fill='#25287f'
        />
        <path
          id='Path_13461'
          data-name='Path 13461'
          d='M217.085,149.8h0a.888.888,0,0,1,.783-.994l3.583-.422a.888.888,0,0,1,.994.783h0a.888.888,0,0,1-.783.994l-3.583.422A.9.9,0,0,1,217.085,149.8Z'
          transform='translate(-217.078 -135.582)'
          fill='#25287f'
        />
        <path
          id='Path_13462'
          data-name='Path 13462'
          d='M251.006,110.461l-.422-3.583a.888.888,0,0,1,.783-.994h0a.888.888,0,0,1,.994.783l.422,3.583a.888.888,0,0,1-.783.994h0A.864.864,0,0,1,251.006,110.461Z'
          transform='translate(-240.492 -105.878)'
          fill='#25287f'
        />
      </g>
      <path
        id='Path_13463'
        data-name='Path 13463'
        d='M370.8,204.611a3.02,3.02,0,0,1,3.011,3.011h0a3.02,3.02,0,0,1,3.011-3.011,3.02,3.02,0,0,1-3.011-3.011h0A3,3,0,0,1,370.8,204.611Z'
        transform='translate(-269.754 -160.848)'
        fill='#fff'
      />
    </SvgIcon>
  );
};
