import { marketplaceWishlistDataType, marketplaceItemsHashMap, marketplaceItemType } from "./types";
import { produce } from "immer";

export const generateWishlistItemsHashMap = (wishlist: marketplaceWishlistDataType): marketplaceItemsHashMap => {
  return (wishlist?.items || [])?.reduce(
    (hashMap: marketplaceItemsHashMap, item) => {
      if (item?.product?.id) {
        hashMap.product[item.product.id] = true;
      }
      if (item?.healthPackage?.id) {
        hashMap.healthPackage[item.healthPackage.id] = true;
      }
      return hashMap;
    },
    { product: {}, healthPackage: {} }
  );
};

type Action = {
  type: "SET_LOADING" | "SET_LOADED";
  payload: { id: string; category: marketplaceItemType; value?: boolean };
};

export const itemsLoadingTrackerMapReducer = (state: marketplaceItemsHashMap, action: Action): marketplaceItemsHashMap =>
  produce(state, draft => {
    const { id, category, value } = action.payload;
    switch (action.type) {
      case "SET_LOADING":
        draft[category][id] = value ?? true;
        break;
      case "SET_LOADED":
        draft[category][id] = false;
        break;
    }
  });
