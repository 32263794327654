import { AccountSettingsInformationDocument } from "@/pages/AccountSettings/gql/queries";
import { AddressTypeEnum } from "@/schema/types";
import { useHeaderContext } from "@/shared/context";
import { useCommonContainerStyles } from "@/shared/styles";
import { useApolloClient } from "@apollo/client";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, useAddToast } from "@toolkit/ui";
import { isEmpty } from "lodash";
import { FC, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddressUpsertForm, AddressUpsertFormRef, IGoogleMapAddressUpsertFormValues } from "../../forms/AddressFormUpsert";
import { AddressFragmentFragmentDoc } from "../../gql/fragments";
import { useAccountAddressUpdateMutation, useAccountSetDefaultAddressMutation } from "../../gql/mutations";
import { useGetAddressQuery } from "../../gql/queries";
import useMapAddress from "../../hooks/useMapAddress";
import { convertAddressFormValuesToBackEndValues } from "../../utils";

interface IGoogleMapAddressUpdateContainerParams {
  addressId: string;
}

export const AddressUpdateContainer: FC = () => {
  const { classes } = useCommonContainerStyles();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const { failed } = useAddToast();
  const { addressId } = useParams() as unknown as IGoogleMapAddressUpdateContainerParams;
  const { setHeaderTitle } = useHeaderContext();
  const formRef = useRef<AddressUpsertFormRef>(null);
  const apolloClient = useApolloClient();

  const { data, loading } = useGetAddressQuery({ variables: { addressId } });
  const address = data?.address;

  const { mapAddress } = useMapAddress();

  const [setAddressDefault, { loading: isSettingDefault }] = useAccountSetDefaultAddressMutation({
    onCompleted: () => {
      navigate(-1);
    },
    onError: () => {
      failed(t("Failed to set address as default"));
    },
    refetchQueries: [AddressFragmentFragmentDoc, AccountSettingsInformationDocument],
  });

  const [updateAddress, { loading: isUpdating }] = useAccountAddressUpdateMutation({
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleAddressUpdate = (values: IGoogleMapAddressUpsertFormValues) => {
    updateAddress({
      variables: {
        accountAddressUpdateId: addressId,
        input: convertAddressFormValuesToBackEndValues(values),
      },
    })
      .then(response => {
        const addressId = response.data?.accountAddressUpdate?.address?.id;
        addressId &&
          values?.isDefault &&
          setAddressDefault({ variables: { accountSetDefaultAddressId: addressId, type: AddressTypeEnum.Shipping } });
        !isEmpty(response?.data?.accountAddressUpdate?.accountErrors) &&
          failed(t(formatMessageErrors(response?.data?.accountAddressUpdate?.accountErrors)));

        if (addressId && !values?.isDefault) {
          navigate(-1);
          apolloClient.refetchQueries({ include: [AddressFragmentFragmentDoc, AccountSettingsInformationDocument] });
        }
      })
      .catch(() => {
        failed(t("Failed to update address"));
      });
  };

  const handleSubmit = () => {
    formRef.current?.submit();
  };

  useEffect(() => {
    setHeaderTitle(pickLocalizedValue(address?.name, address?.nameAr) || t("Update Address"));
    return () => {
      setHeaderTitle("");
    };
  }, [setHeaderTitle, address, t]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageContent}>
        <AddressUpsertForm
          ref={formRef}
          isSubmitting={isUpdating || isSettingDefault}
          address={address ?? undefined}
          onChange={handleAddressUpdate}
          mapAddress={mapAddress}
        />
      </Box>
      <Box className={classes.footer}>
        <Button className={classes.button} onClick={handleSubmit} disabled={isUpdating || isSettingDefault}>
          {t("Update Address")}
        </Button>
      </Box>
    </Box>
  );
};
