import { safeJsonParseWithDefault } from "@/domains/appointment/utils";
import { NotificationType } from "@/schema/types";
import { Dot } from "@/shared/components";
import { Box, CircularProgress } from "@toolkit/ui";
import { FC } from "react";
import { useSeenNotificationContext } from "../../SeenNotificationContext";
import { notificationsQueryNode, StatusEnums } from "../../types";
import { AccessRequestNotification } from "../AccessRequestNotification/AccessRequestNotification";
import { AppointmentNotification } from "../AppointmentNotification/AppointmentNotification";
import { AppointmentReminder } from "../AppointmentNotification/AppointmentReminder";
import { ChronicProgramEnrolmentNotification } from "../ChronicProgramEnrolmentNotification/ChronicProgramEnrolmentNotification";
import { ERXCreate } from "../ERXCreate/ERXCreate";
import { GuidedCareTaskNotificationCard } from "../GuidedCareTaskNotificationCard/GuidedCareTaskNotificationCard";
import { MedicalForm } from "../MedicalForm/MedicalForm";
import { NotificationDefaultCard } from "../NotificationDefaultCard/NotificationDefaultCard";
import { PaymentNotification } from "../PaymentNotification/PaymentNotification";
import { PrescriptionNotification } from "../PrescriptionNotification/PrescriptionNotification";
import { SurveyNotification } from "../SurveyNotification/SurveyNotification";
import { MarketplaceOrderNotification } from "../MarketplaceOrderNotification/MarketplaceOrderNotification";

export type NotificationCardWrapperProps = {
  item: notificationsQueryNode;
};

export const NotificationCardWrapper: FC<NotificationCardWrapperProps> = ({ item }) => {
  const data = item?.data
    ? safeJsonParseWithDefault(item?.data, {
        changes: [{ value: "", field: "" }],
        id: "",
        status: "",
        reference: "",
        programName: "",
        notificationType: "",
        englishText: "",
        arabicText: "",
        patientProfileConsentFormId: "",
        userId: [],
        displayAr: "",
        displayEn: "",
        notificationMessageAr: "",
        notificationMessageEn: "",
        notificationTitleEn: "",
        notificationTitleAr: "",
        patientUserId: "",
      })
    : null;
  const creationDate = item?.creationDate;
  const isSeen = !!item?.seen;

  const { seenId, handleViewNotification } = useSeenNotificationContext();
  const isLoading = seenId === item?.id;

  const statusChange = data?.changes?.find((change: { field: string; value: string }) => change.field === "status");
  const status = statusChange?.value as StatusEnums;

  const handleViewNotificationClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handleViewNotification?.(data);
  };
  const renderNotificationContent = () => {
    if (!data) return;
    switch (item?.type) {
      case NotificationType.PaymentNotification:
        return <PaymentNotification data={item?.data} creationDate={creationDate} isSeen={isSeen} />;
      case NotificationType.MedicalForm:
        return <MedicalForm id={data?.id} creationDate={creationDate} isSeen={isSeen} />;
      case NotificationType.HealthProgramAppointmentStatusChanged:
        return <AppointmentNotification id={data?.id} status={status} creationDate={creationDate} isSeen={isSeen} />;
      case NotificationType.SurveyNotification:
        return <SurveyNotification surveyId={data?.id} creationDate={creationDate} isSeen={isSeen} />;
      case NotificationType.ErxStatusUpdate:
        return <PrescriptionNotification status={data?.status} creationDate={creationDate} isSeen={isSeen} />;
      case NotificationType.PatientProfileAccessRequest:
        return <AccessRequestNotification id={data?.patientProfileConsentFormId} creationDate={creationDate} isSeen={isSeen} />;
      case NotificationType.ChronicProgramEnrollment:
        return <ChronicProgramEnrolmentNotification programName={data?.programName} creationDate={creationDate} isSeen={isSeen} />;
      case NotificationType.ErxCreate:
        return <ERXCreate reference={data?.reference} creationDate={creationDate} isSeen={isSeen} />;
      case NotificationType.HealthProgramAppointmentReminder:
        return (
          <AppointmentReminder
            englishText={data?.englishText}
            arabicText={data?.arabicText}
            notificationType={data?.notificationType}
            changes={data?.changes}
            creationDate={creationDate}
            isSeen={isSeen}
          />
        );
      case NotificationType.GuidedCareTaskNotification:
        return <GuidedCareTaskNotificationCard data={data} creationDate={creationDate} isSeen={isSeen} />;
      case NotificationType.MarketplaceOrderStatusUpdate:
        return <MarketplaceOrderNotification status={data?.status} creationDate={creationDate} isSeen={isSeen} />;
      default:
        return <NotificationDefaultCard type={item?.type || "empty-notification-type"} creationDate={creationDate} isSeen={isSeen} />;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        paddingBlock: "20px",
        position: "relative",
      }}
      onClick={handleViewNotificationClick}
    >
      {renderNotificationContent()}

      <Box
        sx={{
          marginTop: "20px",
          marginInlineStart: "-20px",
          position: "absolute",
          right: "5px",
        }}
      >
        {!isSeen && (isLoading ? <CircularProgress size={20} /> : <Dot color='primary' />)}
      </Box>
    </Box>
  );
};
