import { useHeaderContext } from "@/shared/context";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetGuidedCareJourneyQuery, useGetGuidedCareJourneyStatisticsQuery } from "../../gql";

export const useCareJourneyDetailsHook = () => {
  const { setHeaderTitle } = useHeaderContext();
  const [searchParams] = useSearchParams();

  const journeyId = searchParams.get("journeyId");
  const { data, loading } = useGetGuidedCareJourneyQuery({
    variables: {
      guidedCareJourneyId: journeyId!,
    },
    skip: !journeyId,
  });
  const careJourney = data?.guidedCareJourney;

  useEffect(() => {
    setHeaderTitle(careJourney?.providerGuidedCareHealthProgramTeam?.name!);
    return () => {
      setHeaderTitle("");
    };
  }, [setHeaderTitle, careJourney?.providerGuidedCareHealthProgramTeam?.name]);
  const { data: getStatisticsData } = useGetGuidedCareJourneyStatisticsQuery({
    variables: {
      filter: {
        guidedCareJourneyId: journeyId!,
      },
    },
    skip: !journeyId,
  });

  const careJourneyStatistics = getStatisticsData?.careJourneyStatistics;
  return {
    loading,
    careJourney,
    careJourneyStatistics,
    journeyId,
  };
};
