import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import React from "react";

export const GoodModeIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='icons8-disappointed'
      width='32.488'
      height='32.488'
      viewBox='0 0 32.488 32.488'
      {...props}
    >
      <defs>
        <linearGradient id='linear-gradient' x1='-13.349' y1='2.699' x2='-12.647' y2='1.997' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#fede00' />
          <stop offset='0.519' stopColor='#fecc00' />
          <stop offset='1' stopColor='#ffb700' />
        </linearGradient>
      </defs>
      <path
        id='Path_28364'
        data-name='Path 28364'
        d='M20.244,36.488A16.244,16.244,0,1,0,4,20.244,16.245,16.245,0,0,0,20.244,36.488Z'
        transform='translate(-4 -4)'
        fill='url(#linear-gradient)'
      />
      <ellipse id='Ellipse_3511' data-name='Ellipse 3511' cx='3' cy='3.5' rx='3' ry='3.5' transform='translate(6.531 11)' fill='#fff' />
      <ellipse id='Ellipse_3512' data-name='Ellipse 3512' cx='3' cy='3.5' rx='3' ry='3.5' transform='translate(19.531 11)' fill='#fff' />
      <circle id='Ellipse_3513' data-name='Ellipse 3513' cx='1.5' cy='1.5' r='1.5' transform='translate(8.531 13)' fill='#343434' />
      <circle id='Ellipse_3514' data-name='Ellipse 3514' cx='1.5' cy='1.5' r='1.5' transform='translate(21.531 13)' fill='#343434' />
      <path
        id='Path_28365'
        data-name='Path 28365'
        d='M28.793,31.62a.811.811,0,0,1-.073,1.146,9.393,9.393,0,0,1-12.444,0,.812.812,0,1,1,1.073-1.219,7.77,7.77,0,0,0,10.3,0,.811.811,0,0,1,1.146.073Z'
        transform='translate(-6.254 -9.135)'
        fill='#343434'
      />
    </SvgIcon>
  );
};
