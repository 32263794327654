import { CalculationActivityLevelSelect } from "@/domains/digital-twin-calculators/CalculationFormInputs/CalculationActivityLevelSelect";
import { CalculationFormNumericSelect } from "@/domains/digital-twin-calculators/CalculationFormInputs/CalculationFormNumericSelect";
import { HealthParameterCodes } from "@/domains/health-parameters/constants/healthParameterCodes";
import { useGetDigitalTwinHealthParameterByCode } from "@/domains/health-parameters/hooks/useGetDigitalTwinHealthParameterByCode";
import { ActivityLevel, BeeAndTdeeInput } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Card, Checkbox, Divider, FormControlLabel, Typography, useAddToast } from "@toolkit/ui";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDigitalTwinBMRAndTDEECalculatorFormStyle } from "./useDigitalTwinBMRAndTDEECalculatorFormStyle";
import { useDigitalTwinPatientInfoQuery } from "@/domains/digital-twin-calculators/gql";
import { useDigitalTwinBeeAndTdeeCalculateLazyQuery } from "../../gql";
import { weightLossPlanCalculationRoutes } from "@/domains/digital-twin-weight-loss-calculator/routes";
import { ValueLabelComponent } from "@/shared/components";
import { TdeeAndBmrCalculationRoutes } from "../../routes";

export const DigitalTwinBMRAndTDEECalculatorForm: FC = () => {
  const { failed } = useAddToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useDigitalTwinBMRAndTDEECalculatorFormStyle();
  const [height, setHeight] = useState<number>(50);
  const [weight, setWeight] = useState<number>(50);
  const [activityLevel, setActivityLevel] = useState<ActivityLevel>();
  const [shouldSaveData, setShouldSaveData] = useState<boolean>(false);
  const { loading: isLoadingProfile } = useDigitalTwinPatientInfoQuery({
    onCompleted: data => {
      if (!data?.profile?.dateOfBirth || !data?.profile?.gender) {
        failed(t("Please fill in your personal information first ( age & gender) "));
      }
    },
  });
  const { loading: isLoadingBee } = useGetDigitalTwinHealthParameterByCode(HealthParameterCodes.height, value => {
    setHeight(value);
  });
  const { loading: isLoadingTdee } = useGetDigitalTwinHealthParameterByCode(HealthParameterCodes.weight, value => {
    setWeight(value);
  });

  const isLoadingSavedHealthParams = isLoadingProfile || isLoadingBee || isLoadingTdee;

  const [fetchCalculateBeeAndTdee, { loading: isSubmitting }] = useDigitalTwinBeeAndTdeeCalculateLazyQuery();

  const handleActivityLevelSelect = (level: string) => {
    setActivityLevel(level as ActivityLevel);
  };

  const handleSubmitAndContinueToWeightLossPlan = () => {
    handleSubmit().then(data => {
      navigate(
        weightLossPlanCalculationRoutes.form.getRouteWithParams({
          bee: data.beeAndtdee?.bee!,
          tdee: data.beeAndtdee?.tdee!,
          height: height!,
          weight: weight!,
          activityLevel: activityLevel!,
        })
      );
    });
  };

  const handleSubmitAndShowResult = () => {
    handleSubmit().then(data => {
      navigate(
        TdeeAndBmrCalculationRoutes.root.getRouteWithParams({
          bee: data.beeAndtdee?.bee!,
          tdee: data.beeAndtdee?.tdee!,
          height: height!,
          weight: weight!,
          activityLevel: activityLevel!,
        })
      );
    });
  };

  const handleSubmit = () => {
    const inputData: BeeAndTdeeInput = {
      height: height!,
      weight: weight!,
      activityLevel: activityLevel!,
    };

    return fetchCalculateBeeAndTdee({
      variables: {
        input: inputData,
        saveData: shouldSaveData,
      },
    }).then(({ data }) => {
      if (!data?.beeAndtdee) {
        failed(t("Failed to calculate nutrition data"));
        throw new Error("Failed to calculate nutrition data");
      }
      return data;
    });
  };

  const handleChangeWeight = (_, v: number | number[]) => setWeight(+v);
  const handleChangeHeight = (_, v: number | number[]) => setHeight(+v);
  const handleChangeSaveResultCheckbox = () => setShouldSaveData(!shouldSaveData);
  const isFormValid = height && weight && activityLevel;
  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <Typography className={classes.title}>{t("Set your goal for calorie counting")}</Typography>
        <Typography className={classes.subTitle}>
          {t("Fill in the details so we can calculate your calories and weight loss plan.")}
        </Typography>
      </Card>
      <Card className={classes.card}>
        <CalculationFormNumericSelect
          min={100}
          max={250}
          inputLabel={t("Height")}
          valueLabelDisplay='on'
          valueLabelFormat={value => ValueLabelComponent({ value, unit: t("cm") })}
          value={height}
          onChange={handleChangeHeight}
        />

        <CalculationFormNumericSelect
          max={250}
          inputLabel={t("Weight")}
          valueLabelDisplay='on'
          valueLabelFormat={value => ValueLabelComponent({ value, unit: t("kg") })}
          value={weight}
          onChange={handleChangeWeight}
        />
        <CalculationActivityLevelSelect
          value={activityLevel!}
          inputLabel={t("Activity Level")}
          name='activityLevelSelect'
          onChange={handleActivityLevelSelect}
        />

        <Divider sx={{ height: 0.2 }} />
        <FormControlLabel
          label={t("Save results to your profile?")}
          control={
            <Checkbox checked={shouldSaveData} title={t("Save results to your profile?")} onChange={handleChangeSaveResultCheckbox} />
          }
        />
      </Card>

      <Button disabled={!isFormValid || isSubmitting || isLoadingSavedHealthParams} onClick={handleSubmitAndContinueToWeightLossPlan}>
        {t("Continue and get weight loss plan")}
      </Button>

      <Button disabled={!isFormValid || isSubmitting || isLoadingSavedHealthParams} onClick={handleSubmitAndShowResult}>
        {t("Show result")}
      </Button>
    </Box>
  );
};
