import { SideMenuDrawer } from "@/shared/components";
import { client } from "@/shared/configs";
import { AuthProvider, HeaderProvider, MarketplaceProvider } from "@/shared/context";
import { ApolloProvider, useReactiveVar } from "@apollo/client";
import { ChatProvider } from "@health/chat";
import { useIsInEmbedView } from "@health/domains";
import { CallProvider } from "@health/meeting";
import { SadaProvider } from "@health/sada";
import { NotificationsServiceProvider } from "@health/sse";
import { I18nextProvider } from "@toolkit/i18n";
import { ToolkitUiProvider } from "@toolkit/ui";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { AuthProvider as OdicAuthProvider, useAuth } from "react-oidc-context";
import { BrowserRouter, useLocation } from "react-router-dom";
import "./App.css";
import { SignInByOTPModal } from "./domains/sign-in";
import { VisitCallGuestBaseRoute } from "./domains/VisitCall/routes";
import { getEnvVariable } from "./env";
import { useSiteSettingsGetQuery } from "./gql";
import i18n from "./i18n/i18n.config";
import { RootRoutes } from "./routing/RootRoutes";
import { oidcUserManagerVar } from "./shared/configs/oidc";
import { uuidv4 } from "./shared/utils";
import { setSiteSettings } from "./shared/utils/siteSettings";

function App() {
  const [oidcAuthProviderKey, setOidcAuthProviderKey] = useState("");
  useEffect(() => {
    document.body.dir = i18n.dir(localStorage.getItem("locale") || "en");
    oidcUserManagerVar.onNextChange(() => {
      setOidcAuthProviderKey(uuidv4());
    });
  }, []);
  const oidcUserManager = useReactiveVar(oidcUserManagerVar);
  return (
    <BrowserRouter>
      <OdicAuthProvider key={oidcAuthProviderKey} {...oidcUserManager.settings}>
        <AuthProvider>
          <AppRootProviders>
            <MarketplaceProvider>
              <HeaderProvider>
                <>
                  <RootRoutes />
                  <SideMenuDrawer />
                  <SignInByOTPModal />
                </>
              </HeaderProvider>
            </MarketplaceProvider>
          </AppRootProviders>
        </AuthProvider>
      </OdicAuthProvider>
    </BrowserRouter>
  );
}
const EmbedRootProviders: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const token = user?.access_token || "";
  const { isInEmbedView } = useIsInEmbedView();
  const { pathname } = useLocation();
  useSiteSettingsGetQuery({
    onCompleted: ({ siteSettings }) => {
      setSiteSettings(siteSettings);
    },
  });

  if (isInEmbedView || pathname.startsWith(VisitCallGuestBaseRoute)) {
    return children;
  }

  return (
    <ChatProvider accessToken={token} senderId={user?.profile?.user_id as number}>
      <CallProvider accessToken={token} user={user?.profile}>
        <SadaProvider token={token || ""}>{children}</SadaProvider>
      </CallProvider>
    </ChatProvider>
  );
};

const AppRootProviders: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();

  return (
    <NotificationsServiceProvider eventSourceUrl={getEnvVariable("SUBSCRIPTION_URL")} token={user?.access_token || ""}>
      <ApolloProvider client={client}>
        <EmbedRootProviders>
          <I18nextProvider i18n={i18n}>
            <ToolkitUiProvider>{children}</ToolkitUiProvider>
          </I18nextProvider>
        </EmbedRootProviders>
      </ApolloProvider>
    </NotificationsServiceProvider>
  );
};

export default App;
