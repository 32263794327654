import React from "react";
import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";

export const AtHomeIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon width={props.width || "14.176"} height={props.height || "14.176"} viewBox='0 0 14.176 14.176' {...props}>
      <path
        id='icons8-home_2_'
        data-name='icons8-home (2)'
        d='M10.088,2a.591.591,0,0,0-.413.168L3.232,7.438l-.022.017-.022.018h0a.59.59,0,0,0,.4,1.022h.591v6.5a1.181,1.181,0,0,0,1.181,1.181h9.451a1.181,1.181,0,0,0,1.181-1.181V8.5h.591a.591.591,0,0,0,.4-1.023l-.009-.007q-.019-.018-.04-.033l-.944-.772v-2.3a.591.591,0,0,0-.591-.591h-.591a.591.591,0,0,0-.591.591v.85L10.488,2.158A.591.591,0,0,0,10.088,2ZM11.86,9.679h2.363V14.4H11.86Z'
        transform='translate(-3 -2)'
        fill={fill}
      />
    </SvgIcon>
  );
};
