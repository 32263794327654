import { FC, useEffect, useMemo } from "react";
import { Box, CircularProgress, Typography } from "@toolkit/ui";
import moment from "moment-timezone";
import { useDeliveryTimeStyles } from "./useDeliveryTime.styles";
import { TimeSlot } from "../../others/types";
import { DeliveryTypes } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useTimeSlots } from "./useTimeSlots";
import { useSiteSettingsGetQuery } from "@/gql";
import { TimeSlotItem } from "../TimeSlotItem/TimeSlotItem";

interface TimeSlotsProps {
  selectedSlot: TimeSlot | undefined;
  onSlotSelect: (slot?: TimeSlot) => void;
  selectedDate?: moment.Moment;
  deliveryType?: DeliveryTypes;
}

export const TimeSlots: FC<TimeSlotsProps> = ({ selectedSlot, onSlotSelect, selectedDate, deliveryType }) => {
  const { classes, cx } = useDeliveryTimeStyles();
  const { t } = useTranslation("consumer");
  const { data: settings, loading: settingsLoading } = useSiteSettingsGetQuery();

  const timeZoneInfo = useMemo(() => {
    const userTz = moment.tz.guess();
    const systemOffset = settings?.siteSettings?.timeZone ?? 0;
    const systemTz = systemOffset !== 0 ? `Etc/GMT${systemOffset > 0 ? "-" : "+"}${Math.abs(systemOffset)}` : "UTC";

    return { userTz, systemTz };
  }, [settings?.siteSettings?.timeZone]);
  const { timeSlots, loading } = useTimeSlots(deliveryType, selectedDate);

  const dateInfo = useMemo(() => {
    if (!selectedDate) return null;

    const systemDate = selectedDate.clone().tz(timeZoneInfo.systemTz);
    const apiDate = systemDate.format("YYYY-MM-DD");

    return { systemDate, apiDate };
  }, [selectedDate, timeZoneInfo.systemTz]);

  useEffect(() => {
    onSlotSelect(undefined);
  }, [selectedDate]);

  if (!selectedDate) {
    return (
      <Box className={classes.timeSlotContainer}>
        <Typography>{t("Please select a date first")}</Typography>
      </Box>
    );
  }

  if (loading || settingsLoading) {
    return <CircularProgress />;
  }

  if (timeSlots.length === 0) {
    return (
      <Box className={classes.timeSlotContainer}>
        <Typography>{t("No available time slots for this date")}</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.timeSlotContainer}>
      {timeSlots.map(slot => (
        <TimeSlotItem
          key={slot.id}
          slot={slot}
          dateInfo={dateInfo}
          timeZoneInfo={timeZoneInfo}
          selectedSlot={selectedSlot}
          onSlotSelect={onSlotSelect}
          className={classes.timeSlot}
          cx={cx}
        />
      ))}
    </Box>
  );
};
