import { createContext, PropsWithChildren } from "react";

import { InitConfig } from "./types";
import { useZoomContext } from "./useZoomContext";

export const ZoomContext = createContext<Partial<ReturnType<typeof useZoomContext>>>({});

type ZoomProviderProps = PropsWithChildren<{ config?: InitConfig }>;

export const ZoomProvider = ({ children, config }: ZoomProviderProps) => {
  const value = useZoomContext(config);

  return <ZoomContext.Provider value={value}>{children}</ZoomContext.Provider>;
};
