import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";

export { CallState, ConnectionState, type CallInstance, type CallParticipant } from "../Call/types";
export interface Adaptor {
  instance: WebRTCAdaptor;
  getBroadcastObject: (pid: string) => void;
  publish: () => void;
  unpublish: () => void;
  play: () => void;
  stop: (streamId: string) => void;
  replaceTrack: (track: MediaStreamTrack) => void;
  updateUserStatus: (metadata: StreamMetaData) => void;
}

export type CustomMediaStreamTrack = MediaStreamTrack & {
  _type?: MediaStreamTrackType;
};

export enum MediaStreamTrackType {
  screen = "screen",
  canvas = "canvas",
}

export type InitConfig = {
  enableLog?: boolean;
  url?: string;
};

export type StreamInfo = {
  streamId: string;
  streamName: string;
  isCurrentStream: boolean;
  metaData: StreamMetaData;
};

export type StreamMetaData = {
  userIdentity: string;
  audioMuted: boolean;
  videoMuted: boolean;
  screenMuted: boolean;
  videoSettings?: { width: number; height: number };
};

export type StreamConfig = {
  url: string;
  roomId: string;
  streamId: string;
  streamName: string;
  publishToken: string;
  playToken: string;
  metaData: StreamMetaData;
  iceServers?: { urls: string; username?: string; credential?: string }[] | null;
};

export type CallbackType =
  | "initialized"
  | "pong"
  | "closed"
  | "joinedTheRoom"
  | "roomInformation"
  | "no_active_streams_in_room"
  | "publish_started"
  | "publish_finished"
  | "play_started"
  | "play_finished"
  | "broadcastObject"
  | "noStreamNameSpecified"
  | "invalidStreamName"
  | "license_suspended_please_renew_license"
  | "publishTimeoutError"
  | "streamIdInUse"
  | "unauthorized_access"
  | "newTrackAvailable"
  | "session_restored"
  | "reconnection_attempt_for_publisher"
  | "reconnection_attempt_for_player"
  | "highResourceUsage"
  | "bitrateMeasurement"
  | "data_channel_opened"
  | "data_channel_closed"
  | "data_received"
  | "already_playing"
  | "already_publishing"
  | "encoderNotOpened"
  | "encoderBlocked"
  | "no_codec_enabled_in_the_server"
  | "stream_not_active_or_expired"
  | "viewerLimitReached"
  | "no_room_specified"
  | "web_socket_error";

type FailureReason = {
  type:
    | "USER_NOT_CONNECTED"
    | "CLIENT_NOT_READY"
    | "SHARE_LOCKED"
    | "ERROR"
    | "CAN_NOT_DETECT_CAMERA"
    | "CAN_NOT_FIND_CAMERA"
    | "VIDEO_USER_FORBIDDEN_CAPTURE"
    | "VIDEO_CAMERA_IS_TAKEN"
    | "MEDIA_NOT_FOUND"
    | "MEDIA_NOT_ALLOWED"
    | "MEDIA_NOT_READABLE";
  reason?: unknown;
};

export type ExecutedResponse<T = any> = ({ success: true } & T) | (FailureReason & { success?: false });
