import moment from "moment";
import { CircularProgress, Box, CustomInfiniteScroll } from "@toolkit/ui";
import { FC } from "react";
import { useChatListHooks } from "./ChatList.hooks";
import { ChatListEmpty } from "./ChatListEmpty";
import { ChatListItem } from "./ChatListItem";
import { makeStyles } from "@toolkit/ui";
import { ChatViewDate } from "../ChatView";
import { useChatSender } from "../ChatProvider";

export const useChatListStyles = makeStyles()(() => ({
  root: {
    position: "relative",
    overflow: "auto",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column-reverse",
    minWidth: 280,
    overflowY: "auto",
    minHeight: "inherit",
    boxSizing: "border-box",
    padding: "16px 0",

    ".infinite-scroll-component": {
      gap: 16,
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type ChatListProps = {
  id?: string | null;
};

export const ChatList: FC<ChatListProps> = ({ id }) => {
  const { senderId } = useChatSender();
  const { classes } = useChatListStyles();
  const { data, loading, hasMore, hasError, isEmpty, handleFetchMore, handleRetry } = useChatListHooks(id);

  if (isEmpty) {
    return <ChatListEmpty isLoading={loading} hasError={hasError} onRetry={handleRetry} />;
  }

  return (
    <Box id='chats-component' className={classes.root}>
      <CustomInfiniteScroll
        dataLength={data.length}
        onFetchMore={handleFetchMore}
        onRefresh={handleRetry}
        hasMore={hasMore}
        scrollableTarget='chats-component'
        inverse={true}
        loader={
          <Box py={4} className={classes.loader}>
            <CircularProgress />
          </Box>
        }
      >
        {data.map((item, i) => {
          const nextMessage = data[i - 1];
          const prevMessage = data[i + 1];
          const isOutgoing = senderId === item.senderUser.id;
          const hasAvatar = nextMessage?.senderUser.id !== item.senderUser.id;
          const hasDate = nextMessage && !moment(item.createdAt).isSame(nextMessage.createdAt, "day");

          return (
            <>
              {hasDate && <ChatViewDate date={nextMessage.createdAt} />}
              <ChatListItem chat={item} hasAvatar={hasAvatar} isOutgoing={isOutgoing} />
              {!prevMessage && <ChatViewDate date={item.createdAt} />}
            </>
          );
        })}
      </CustomInfiniteScroll>
    </Box>
  );
};
