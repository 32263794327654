import { HealthPackageCard, ProductCardSkeleton } from "@/domains/marketplace-health-packages";
import { MarketplaceHealthPackageFilterInput, MarketplaceHealthPackageSortingInput } from "@/schema/types";
import { EmptyMessageLayout } from "@/shared/components";
import { useContainerHeight } from "@/shared/hooks";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomInfiniteScroll } from "@toolkit/ui";
import { isEmpty } from "lodash";
import { FC } from "react";
import { useHealthPackagesList } from "./useHealthPackagesList";
import { useStyles } from "./useHealthPackagesListStyle";
import { EmptyMarketplaceIcon } from "@/shared/icons";

type HealthPackagesListProps = {
  filters?: MarketplaceHealthPackageFilterInput;
  sortingInput?: MarketplaceHealthPackageSortingInput;
};

export const HealthPackagesList: FC<HealthPackagesListProps> = props => {
  const { filters, sortingInput } = props;
  const { healthPackages, isLoading, fetchMoreData, hasMore, handleRefresh } = useHealthPackagesList(filters, sortingInput);
  const { classes } = useStyles();
  const { containerRef, containerHeight } = useContainerHeight();
  const { t } = useTranslation("consumer");

  const message = isEmpty(filters) ? t("No health packages available") : t("Sorry, no result found");

  const subMessage = isEmpty(filters) ? "" : t("Try adjusting your search or filters");

  return (
    <>
      {isLoading &&
        !healthPackages.length &&
        Array.from({ length: 3 }).map((_, index) => (
          <Box key={`loader-${index}`} className={classes.cardWrapper}>
            <ProductCardSkeleton />
          </Box>
        ))}

      <Box ref={containerRef} className={classes.content}>
        <CustomInfiniteScroll
          dataLength={healthPackages?.length || 1}
          onFetchMore={fetchMoreData}
          hasMore={hasMore}
          loader={Array.from({ length: 2 }).map((_, index) => (
            <Box key={`loader-${index}`} className={classes.cardWrapper}>
              <ProductCardSkeleton />
            </Box>
          ))}
          height={(containerHeight || 15) - 10}
          onRefresh={handleRefresh}
        >
          {!isLoading && !healthPackages.length && (
            <Box className={classes.emptyPageContainer}>
              <EmptyMessageLayout icon={<EmptyMarketplaceIcon />} message={message} subMessage={subMessage} />
            </Box>
          )}

          {healthPackages?.length > 0 &&
            healthPackages.map(healthPackage => (
              <Box key={`healthPackage-${healthPackage?.id}`} className={classes.cardWrapper}>
                <HealthPackageCard healthPackage={healthPackage} />
              </Box>
            ))}
        </CustomInfiniteScroll>
      </Box>
    </>
  );
};
