export enum HealthParameterCodes {
  temperature = "PA-VS-001",
  heartRate = "PA-VS-002",
  respiratoryRate = "PA-VS-003",
  bloodPressureHigh = "PA-VS-004-Sys",
  bloodPressureLow = "PA-VS-004-Dia",
  oxygenSaturation = "PA-VS-005",
  bloodGlucose = "PA-GL-006",
  height = "PA-PE-001",
  weight = "PA-PE-002",
  bmi = "PA-PE-003",
  otoscopeVideo = "PA-PE-009",
  otoscopePicture = "PA-PE-009-Pic",
  stethoscopeLungSound = "PA-PE-011",
  stethoscopeHeartSound = "PA-PE-012",
  ecg = "PA-PE-016",
  sleepTime = "PA-SL-001",
  sleepCycles = "PA-SL-002",
  bee = "PA-CA-001",
  tdee = "PA-CA-002",
  // calorieDeficitNeeded = "PA-CA-003",
}
