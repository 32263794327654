import { FC } from "react";
import { BlobIconProps, BlobIconWrapper } from "./BlobIcon";

export const BlobERxIcon: FC<BlobIconProps> = props => {
  return (
    <BlobIconWrapper {...props}>
      <g xmlns='http://www.w3.org/2000/svg' id='Group_10149' data-name='Group 10149' transform='translate(14 16) scale(1.3)'>
        <path
          d='M21.565,12.555H19.682a.87.87,0,0,0-.763.453l-1.8,3.289h-.149l-3.02-5.718a3.7,3.7,0,0,0,2.109-3.5C16.064,4.526,14.332,3,11.443,3H6.869A.869.869,0,0,0,6,3.869V14.292a.869.869,0,0,0,.869.869H8.278a.869.869,0,0,0,.869-.869V11.041h1.861l4.05,7.11-3.065,5.037a.434.434,0,0,0,.372.659h1.789a.868.868,0,0,0,.744-.42L16.8,20.271h.149l1.891,3.154a.866.866,0,0,0,.744.422H21.54a.434.434,0,0,0,.367-.666l-3.156-5,3.179-4.958A.434.434,0,0,0,21.565,12.555ZM9.146,8.821V5.375H11A1.681,1.681,0,0,1,12.85,7.106a1.637,1.637,0,0,1-1.843,1.715Z'
          fill='#fff'
        ></path>
      </g>
    </BlobIconWrapper>
  );
};
