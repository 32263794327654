import { makeStyles } from "@toolkit/ui";

export const useMarketplaceItemAvatarStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "100%",
    borderRadius: "unset",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  healthPackageRoot: {
    backgroundColor: theme.palette.stale[600],
    "& svg": {
      width: "50%",
      height: "50%",
      color: theme.palette.common.white,
    },
    "& path": {
      fill: theme.palette.common.white,
    },
  },
  productRoot: {
    backgroundColor: theme.palette.common.white,
    "& svg": {
      width: "70%",
      height: "70%",
      color: theme.palette.primary.main,
      "& path": {
        fill: theme.palette.primary.main,
      },
    },
  },
  fallback: {},
}));
