import {
  MarketplaceProductFilterInput,
  MarketplaceProductSortingField,
  MarketplaceProductSortingInput,
  MarketplaceSortDirection,
} from "@/schema/types";
import { i18n } from "@toolkit/i18n";
import { IProductsListFiltersFormValues } from "./ProductsListFiltersFormSchema";

export const ConvertProductsFilterFormValuesToBackEndValues = (
  values: IProductsListFiltersFormValues
): { filters?: MarketplaceProductFilterInput; sortingFilters?: MarketplaceProductSortingInput } => {
  return {
    filters: {
      vendorId: values?.vendor?.value?.id,
      brandIds: values?.brands?.map(brand => brand?.value?.id || ""),
      priceRange: values?.price ? { min: values.price[0], max: values.price[1] } : undefined,
    },
    sortingFilters: {
      field: values?.sortingField,
      direction: MarketplaceSortDirection.Asc,
    },
  };
};

export const sortingOptions = [
  {
    get name() {
      return i18n.t("Date", { ns: "consumer" });
    },
    value: MarketplaceProductSortingField.Published,
  },
  {
    get name() {
      return i18n.t("Name", { ns: "consumer" });
    },
    value: MarketplaceProductSortingField.Name,
  },
  {
    get name() {
      return i18n.t("Arabic Name", { ns: "consumer" });
    },
    value: MarketplaceProductSortingField.NameAr,
  },
  {
    get name() {
      return i18n.t("Price", { ns: "consumer" });
    },
    value: MarketplaceProductSortingField.Price,
  },
];
