import { useCommonStyles } from "@/domains/appointment/containers/CommonContainer.styles";
import { CartSummaryBar } from "@/domains/marketplace/components/CartSummaryBar/CartSummaryBar";
import { CategoriesFilters } from "@/domains/marketplace/components/CategoriesFilters/CategoriesFilters";
import { MarketplaceHealthPackageFilterInput, MarketplaceHealthPackageSortingInput } from "@/schema/types";
import { useMarketplaceCartContext } from "@/shared/context";
import { Box } from "@toolkit/ui";
import { produce } from "immer";
import { FC, useState } from "react";
import { useAuth } from "react-oidc-context";
import { HealthPackagesList } from "../../components/HealthPackagesList/HealthPackagesList";
import { HealthPackagesListFiltersForm } from "../../forms/HealthPackagesListFilters/HealthPackagesListFiltersForm";
import { IHealthPackagesListFiltersFormValues } from "../../forms/HealthPackagesListFilters/HealthPackagesListFiltersFormSchema";
import { ConvertHealthPackagesFilterFormValuesToBackEndValues } from "../../forms/HealthPackagesListFilters/utils";

export const HealthPackagesListContainer: FC = () => {
  const { classes } = useCommonStyles();
  const [listInput, setListInput] = useState<{
    filters?: MarketplaceHealthPackageFilterInput;
    sortingFilters?: MarketplaceHealthPackageSortingInput;
  }>({});
  const { isAuthenticated } = useAuth();
  const { itemCounts } = useMarketplaceCartContext();

  const handleHealthPackagesFiltersFormSubmit = (values: IHealthPackagesListFiltersFormValues) => {
    const backendValues = ConvertHealthPackagesFilterFormValuesToBackEndValues(values);

    setListInput(
      produce(backendValues, draft => {
        draft.filters = draft.filters || { categoryId: undefined };
        draft.filters.categoryId = listInput?.filters?.categoryId;
      })
    );
  };

  const handleOnCategorySelect = (id?: string) => {
    setListInput(
      produce(listInput, draft => {
        draft.filters = draft.filters || { categoryId: undefined };
        draft.filters.categoryId = id;
      })
    );
  };

  const selectedCategories = listInput?.filters?.categoryId ? [listInput?.filters?.categoryId] : [];

  return (
    <Box className={classes.pageContainer}>
      <CategoriesFilters categoryType='healthPackages' selectedCategories={selectedCategories} onClick={handleOnCategorySelect} />
      <HealthPackagesListFiltersForm onSubmit={handleHealthPackagesFiltersFormSubmit} />
      <Box className={classes.pageContent} display='contents'>
        <HealthPackagesList sortingInput={listInput?.sortingFilters} filters={listInput?.filters} />
      </Box>
      {isAuthenticated && (itemCounts?.totalCount || 0) > 0 && (
        <Box className={classes.footer} bgcolor={"red"}>
          <Box width='calc(100% - 33px)'>
            <CartSummaryBar />
          </Box>
        </Box>
      )}
    </Box>
  );
};
