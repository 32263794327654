import { useMarketplaceCartContext, useMarketplaceWishlistContext } from "@/shared/context";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, RemoveIcon } from "@toolkit/ui";
import { FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { WishlistHealthPackageItem } from "../../types";
import { calculateDiscountedPrice } from "../../utils";
import { HealthPackageSelectedCard } from "../HealthPackageSelectedCard/HealthPackageSelectedCard";
import { useWishlistHealthPackageCardStyle } from "./useWishlistHealthPackageCardStyle";

type WishlistHealthPackageCardProps = {
  healthPackage?: WishlistHealthPackageItem;
};

export const WishlistHealthPackageCard: FC<WishlistHealthPackageCardProps> = props => {
  const { healthPackage } = props;
  const { classes } = useWishlistHealthPackageCardStyle();
  const navigate = useNavigate();

  const { t } = useTranslation("consumer");

  const { addItemToCart, cartItems, itemsLoadingTrackerMap: cartItemsLoadingTracker } = useMarketplaceCartContext();
  const { removeItem, itemsLoadingTrackerMap } = useMarketplaceWishlistContext();

  const handleAddToCart = () => {
    addItemToCart?.({
      healthPackageId: healthPackage?.id,
      quantity: 1,
    });
  };

  const handleRemove = () => {
    removeItem(healthPackage?.id || "", "healthPackage");
  };
  const handleOnClick = () => {
    navigate(routes.healthPackage.getRouteWithParams({ packageId: healthPackage?.id || "" }));
  };

  const handleStopPropagation = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <HealthPackageSelectedCard
      priceInfo={calculateDiscountedPrice(healthPackage?.price, healthPackage?.activeDiscount)}
      classes={{
        itemAvatarContainer: classes.itemAvatarContainer,
        infoContainer: classes.infoContainer,
      }}
      handleOnClick={handleOnClick}
      healthPackage={healthPackage}
      actionsNode={
        <Box className={classes.root} onClick={handleStopPropagation}>
          {cartItems?.healthPackage?.[healthPackage?.id || ""] ? (
            <Box className={classes.addedDisplay}>{t("Added")}</Box>
          ) : (
            <Button
              disabled={cartItemsLoadingTracker?.healthPackage?.[healthPackage?.id || ""]}
              className={classes.addButton}
              onClick={handleAddToCart}
            >
              {t("Add to Cart")}
            </Button>
          )}
          <Button
            startIcon={<RemoveIcon />}
            variant='outlined'
            className={classes.removeButton}
            disabled={itemsLoadingTrackerMap.healthPackage?.[healthPackage?.id || ""]}
            onClick={handleRemove}
          >
            {t("Remove")}
          </Button>
        </Box>
      }
    />
  );
};
