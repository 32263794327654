import { Section } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { useMediaQuery, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { HealthPackagesContainer } from "../../containers/HealthPackagesContainer/HealthPackagesContainer";
import { routes } from "../../routes";

export const MarketHealthPackagesSection: FC = () => {
  const { t } = useTranslation("consumer");
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const handleSeeAll = () => {
    navigate(routes.healthPackages.absRoute);
  };
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Section mt={1} title={t(isAuthenticated ? "Services for you" : "Services")} onSeeAll={handleSeeAll}>
      <HealthPackagesContainer hideNavBtns={isSmall} />
    </Section>
  );
};
