import { makeStyles } from "@toolkit/ui";

export const useHorizontalScrollerStyle = makeStyles()(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "100px",
  },
  contentContainer: {
    flex: 1,
    height: "100%",
    scrollbarWidth: "none",
    padding: 0,
    margin: 0,
  },
  navButtonWrapper: {
    width: "15px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  navButton: {
    width: "100%",
    "& svg": {
      height: "18px",
    },
  },
  startNavButton: {
    "& svg": {
      paddingInlineEnd: "-5px",
    },
  },
  endNavButton: {},
  skeletonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  emptyMessage: {},
}));
