import { InfoCardWithHeader } from "@/shared/components";
import { Box, makeStyles, Typography, useTheme } from "@toolkit/ui";
import { OrderMedicationsCardHeader } from "../OrderMedicationsCardHeader/OrderMedicationsCardHeader";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { MedicationBlobIcon } from "@/shared/icons";
import { PrescriptionsMedicationsSummary } from "../../others/types";

type PrescribedMedicationsProps = {
  medications?: PrescriptionsMedicationsSummary;
};

const useStyles = makeStyles()(theme => ({
  root: {
    ":not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  display: {
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  instructions: {
    color: theme.palette.stale.main,
  },
}));
export const PrescribedMedications: React.FC<PrescribedMedicationsProps> = props => {
  const { medications } = props;
  const theme = useTheme();
  const { classes } = useStyles();
  const { t } = useTranslation("consumer");
  return (
    <InfoCardWithHeader
      header={<OrderMedicationsCardHeader title={t("Prescribed Medications")} icon={<MedicationBlobIcon showBackground />} />}
    >
      <Typography fontSize={theme.mixins.fonts.fontSize.lg}>
        {medications?.map(medication => (
          <Box className={classes.root} key={`medication-${medication.id}`} mt={"10px"}>
            <Typography className={classes.display}>{medication.display}</Typography>
            <Typography className={classes.instructions}>
              {t("Doctor Instruction - {{instruction}}", {
                instruction: pickLocalizedValue(medication.instructions, medication?.arabicInstructions),
              })}
            </Typography>
          </Box>
        ))}
      </Typography>
    </InfoCardWithHeader>
  );
};
