import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { MarketplaceCartFragmentFragmentDoc } from '../../fragments/__generated__/MarketplaceCartFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceCartRemoveItemsMutationVariables = Types.Exact<{
  cartItemIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type MarketplaceCartRemoveItemsMutation = { __typename?: 'Mutation', marketplaceCartRemoveItems?: { __typename?: 'MarketplaceCart', id: string, totalPrice: any, totalDiscount: any, totalPriceWithVAT: any, totalVat: any, totalDeliveryPrice: any, items: Array<{ __typename?: 'MarketplaceCartItem', quantity: number, id: string, healthPackage?: { __typename?: 'MarketplaceHealthPackage', description?: string | null, descriptionAr?: string | null, id: string, mainImageUrl?: string | null, name?: string | null, nameAr?: string | null, price?: any | null, targetToAge?: number | null, shortDescriptionAr?: string | null, shortDescription?: string | null } | null, product?: { __typename?: 'MarketplaceProduct', id: string, mainImageUrl?: string | null, name?: string | null, nameAr?: string | null, price?: any | null, sku?: string | null, stockQuantity: number, shortDescription?: string | null, shortDescriptionAr?: string | null } | null }> } | null };


export const MarketplaceCartRemoveItemsDocument = gql`
    mutation MarketplaceCartRemoveItems($cartItemIds: [ID!]!) {
  marketplaceCartRemoveItems(cartItemIds: $cartItemIds) {
    ...MarketplaceCartFragment
  }
}
    ${MarketplaceCartFragmentFragmentDoc}`;
export type MarketplaceCartRemoveItemsMutationFn = Apollo.MutationFunction<MarketplaceCartRemoveItemsMutation, MarketplaceCartRemoveItemsMutationVariables>;

/**
 * __useMarketplaceCartRemoveItemsMutation__
 *
 * To run a mutation, you first call `useMarketplaceCartRemoveItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceCartRemoveItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceCartRemoveItemsMutation, { data, loading, error }] = useMarketplaceCartRemoveItemsMutation({
 *   variables: {
 *      cartItemIds: // value for 'cartItemIds'
 *   },
 * });
 */
export function useMarketplaceCartRemoveItemsMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceCartRemoveItemsMutation, MarketplaceCartRemoveItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceCartRemoveItemsMutation, MarketplaceCartRemoveItemsMutationVariables>(MarketplaceCartRemoveItemsDocument, options);
      }
export type MarketplaceCartRemoveItemsMutationHookResult = ReturnType<typeof useMarketplaceCartRemoveItemsMutation>;
export type MarketplaceCartRemoveItemsMutationResult = Apollo.MutationResult<MarketplaceCartRemoveItemsMutation>;
export type MarketplaceCartRemoveItemsMutationOptions = Apollo.BaseMutationOptions<MarketplaceCartRemoveItemsMutation, MarketplaceCartRemoveItemsMutationVariables>;