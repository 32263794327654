import { makeStyles } from "@toolkit/ui";

export const useMarketPlacePaymentSummaryStyles = makeStyles()(theme => ({
  root: {
    padding: 10,
    marginTop: 10,
  },
  summaryItemContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBlock: 10,
    ":not(:last-child)": {
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.divider,
    },
  },
  summaryItemLabel: {
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  total: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
