import { GenericFilterForm } from "@/shared/components";
import { VendorsAutocomplete } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import {
  IProductsListFiltersFormValues,
  productsListFiltersFormDefaultValues,
  ProductsListFiltersFormSchema,
} from "../ProductsListFiltersFormSchema";
import { ProductsListFiltersFormProps } from "../types";

export const VendorFilterForm: React.FC<ProductsListFiltersFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IProductsListFiltersFormValues>
      schema={ProductsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='vendor'
      dialogTitle={t("Provider")}
      formLabel={t("Provider")}
      render={name => <VendorsAutocomplete name={name} />}
      defaultValues={productsListFiltersFormDefaultValues}
    />
  );
};
