import { MarketplaceItemAvatar } from "@/domains/marketplace/components/MarketplaceItemAvatar/MarketplaceItemAvatar";
import { routes } from "@/domains/marketplace/routes";
import { useSiteSettingsGetQuery } from "@/gql";
import { useMarketplaceCartContext, useMarketplaceWishlistContext } from "@/shared/context";
import { HeartIcon } from "@/shared/icons";
import { pickLocalizedValue } from "@toolkit/i18n";
import { Box, Card, CustomIcon, IconButton, TruncateTypography, Typography } from "@toolkit/ui";
import { truncate } from "lodash";
import React from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { healthPackagesQueryNode } from "../../types";
import { useStyle } from "./HealthPackageCardStyle";

type HealthPackageCardProps = {
  healthPackage: healthPackagesQueryNode;
};
export const HealthPackageCard: React.FC<HealthPackageCardProps> = props => {
  const { healthPackage } = props;
  const { classes, cx } = useStyle();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const handleSeeDetailsPage = () => {
    healthPackage?.id && navigate(routes.healthPackage.getRouteWithParams({ packageId: healthPackage?.id }));
  };
  const siteCurrency = data?.siteSettings?.defaultCurrency;
  const currentPrice = healthPackage.activeDiscount?.amount
    ? healthPackage.price - healthPackage.activeDiscount?.amount
    : healthPackage.price;

  const { cartItems, toggleItemToCart, itemsLoadingTrackerMap, isCartLoading } = useMarketplaceCartContext();
  const { wishlistItems, toggleItem, itemsLoadingTrackerMap: wishListLoadingTracker, loadingWishlist } = useMarketplaceWishlistContext();
  const handleAddHealthPackageToCard = () => {
    healthPackage?.id &&
      toggleItemToCart?.({
        healthPackageId: healthPackage?.id,
        quantity: 1,
      });
  };
  const handleAddHealthPackageToWishlist = () => {
    healthPackage?.id &&
      toggleItem?.({
        healthPackageId: healthPackage?.id,
      });
  };
  return (
    <Card className={cx(classes.root)}>
      <Box onClick={handleSeeDetailsPage} className={cx(classes.cardImageContainer, classes.container__clickable)}>
        <MarketplaceItemAvatar
          className={classes.cardImageContainer}
          imageUrl={healthPackage?.mainImageUrl}
          itemName={pickLocalizedValue(healthPackage?.name, healthPackage?.nameAr) || ""}
          marketplaceItemType='healthPackage'
        />
      </Box>
      <Box onClick={handleSeeDetailsPage} className={cx(classes.cardDetailsContainer, classes.container__clickable)}>
        <TruncateTypography
          text={pickLocalizedValue(healthPackage?.name, healthPackage?.nameAr) || ""}
          className={classes.healthPackageName}
        />
        <TruncateTypography
          text={pickLocalizedValue(healthPackage?.vendor?.name, healthPackage?.vendor?.nameAr) || ""}
          className={classes.healthPackageVendor}
        />
        <Typography className={classes.healthPackageShortDescription}>
          {truncate(pickLocalizedValue(healthPackage?.shortDescription, healthPackage?.shortDescriptionAr) || "", { length: 65 })}
        </Typography>
      </Box>
      <Box className={cx(classes.cardActionContainer)}>
        <Box>
          <Box className={classes.priceContainer}>
            <Typography className={classes.currency}>{siteCurrency}</Typography>
            <Typography className={classes.price}>{currentPrice}</Typography>
          </Box>
          {(healthPackage.activeDiscount?.amount || healthPackage.activeDiscount?.percentage) && (
            <Box>
              <Typography className={classes.discountCurrency}>{siteCurrency}</Typography>
              <Typography className={classes.discountPrice}> {healthPackage?.price}</Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.cardActionButtonsContainer}>
          <IconButton
            onClick={handleAddHealthPackageToWishlist}
            disabled={wishListLoadingTracker.healthPackage?.[healthPackage?.id] || loadingWishlist || !isAuthenticated}
            className={cx(classes.actionsButton, wishlistItems?.healthPackage?.[healthPackage?.id] && classes.filledIcon)}
          >
            <HeartIcon />
          </IconButton>
          <IconButton
            disabled={itemsLoadingTrackerMap?.healthPackage?.[healthPackage?.id] || isCartLoading || !isAuthenticated}
            onClick={handleAddHealthPackageToCard}
            className={cx(classes.actionsButton, cartItems?.healthPackage?.[healthPackage?.id] && classes.filledIcon)}
          >
            <CustomIcon icon='cart' />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};
