import { CustomCard } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { SelectedCardProductPropsType } from "../../types";
import { PriceCalculationResult } from "../../utils";
import { PriceDisplay } from "../PriceDisplay/PriceDisplay";
import { ProductSelectedCard } from "../ProductSelectedCard/ProductSelectedCard";
import { useOrderProductsCardStyles } from "./OrderProductsCardStyles";

type OrderItem = SelectedCardProductPropsType & {
  priceInfo: PriceCalculationResult;
  priceForQuantity: PriceCalculationResult;
  quantity: number;
};

type OrderProductsCardProps = {
  products: OrderItem[];
  classes?: Partial<ReturnType<typeof useOrderProductsCardStyles>["classes"]>;
  isTitleHidden?: boolean;
};

export const OrderProductsCard: FC<OrderProductsCardProps> = props => {
  const { products, classes: _classes, isTitleHidden } = props;
  const { classes, cx } = useOrderProductsCardStyles();
  const { t } = useTranslation("consumer");

  return products?.length ? (
    <CustomCard className={cx(classes.root, _classes?.root)}>
      {!isTitleHidden && <Typography className={cx(classes.cardHeader, _classes?.cardHeader)}>{t("Health Products")}</Typography>}
      <Box className={cx(classes.cardContainer, _classes?.cardContainer)}>
        {products.map(product => (
          <ProductSelectedCard
            key={product?.id}
            classes={{
              card: cx(classes.itemCard, _classes?.itemCard),
              itemAvatarContainer: cx(classes.itemAvatarContainer, _classes?.itemAvatarContainer),
              infoContainer: cx(classes.infoContainer, _classes?.infoContainer),
              image: cx(classes.image, _classes?.image),
              imageRoot: cx(classes.imageRoot, _classes?.imageRoot),
              textColumn: cx(classes.textColumn, _classes?.textColumn),
            }}
            product={product}
            priceInfo={product.priceInfo}
            infoExtraNode={
              <>
                <Typography>{t("{{itemsCount}} Items", { itemsCount: product.quantity })}</Typography>
                <Box className={cx(classes.totalPrice, _classes?.totalPrice)}>
                  <PriceDisplay increaseFontSizeBy={0} amount={product?.priceForQuantity?.discountedPrice} type='discounted-price' />
                  {product?.priceForQuantity?.hasActiveDiscount && (
                    <PriceDisplay increaseFontSizeBy={0} amount={product?.priceForQuantity?.basePrice} type='base-price' />
                  )}
                </Box>
              </>
            }
          />
        ))}
      </Box>
    </CustomCard>
  ) : null;
};
