import { makeStyles } from "@toolkit/ui";

export const useDetailCardStyles = makeStyles()(theme => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "16px",
    rowGap: "4px",
    position: "relative",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardForSingleInfoWithIcon: {
    flexDirection: "row",
  },
  actionsContainer: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  contentContainer: {
    display: "contents",
  },
  iconContentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    rowGap: "4px",
  },
  title: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: 16,
  },
  content: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  labelSpan: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
