/* eslint-disable jsx-a11y/media-has-caption */
import { FC, useEffect, useRef } from "react";
import { makeStyles } from "@toolkit/ui";

export type AntMediaAudioProps = {
  id: string;
  stream: MediaStream;
  isMuted: boolean;
};

const useStyles = makeStyles()({
  root: {
    display: "none",
  },
});

export const AntMediaAudio: FC<AntMediaAudioProps> = ({ id, isMuted, stream }) => {
  const { classes } = useStyles();
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    audioRef.current.srcObject = stream ?? null;
  }, [stream]);

  if (!stream) {
    return null;
  }

  return <audio ref={audioRef} id={id} autoPlay muted={isMuted} className={classes.root} />;
};
