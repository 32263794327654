import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { MarketplaceOrderDetails } from "../../others/types";
import { InfoCardWithHeader } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { useMarketplaceOrderGeneralInfoCardStyle } from "../MarketplaceOrderGeneralInfoCards/useMarketplaceOrderGeneralInfoCards.styles";
import { OrderProductsCard } from "@/domains/marketplace/components/OrderProductsCard/OrderProductsCard";
import { calculateOrderPrice } from "@/domains/marketplace/utils";
import { ProductIcon } from "@/shared/icons";
import { MarketplaceOrderType } from "@/schema/types";
import { useOrderDetailsCardStyles } from "../OrderDetailsPaymentSummary/useOrderDetailsCardStyles.styles";

type OrderDetailsProductCardProps = {
  order: MarketplaceOrderDetails;
};

export const OrderDetailsProductCard: FC<OrderDetailsProductCardProps> = props => {
  const { order } = props;
  const { classes } = useOrderDetailsCardStyles();
  const { classes: headerClasses, cx } = useMarketplaceOrderGeneralInfoCardStyle();
  const { t } = useTranslation("consumer");
  return order.type === MarketplaceOrderType.Product ? (
    <InfoCardWithHeader
      mt={"10px"}
      classes={{
        header: cx(classes.headerContainer, classes.headerCentredContainer),
      }}
      header={
        <>
          <Box className={headerClasses.iconContainer}>
            <ProductIcon />
          </Box>
          <Typography className={classes.headerMainLabel}>{t("Health Products")}</Typography>
        </>
      }
    >
      <OrderProductsCard
        isTitleHidden
        classes={{
          root: classes.contentRoot,
        }}
        products={order?.orderLines.map(item => ({
          ...item?.product,
          id: item?.product?.id || "",
          quantity: item.quantity,
          priceInfo: calculateOrderPrice(item?.unitPriceNetAmount, item?.unitPriceDiscount),
          priceForQuantity: calculateOrderPrice(item?.unitPriceNetAmount, item?.unitPriceDiscount, item.quantity),
        }))}
      />
    </InfoCardWithHeader>
  ) : undefined;
};
