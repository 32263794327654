import { getPatientId } from "@/domains/patient";
import { PatientEligibleTeamFilterInput } from "@/schema/types";
import { useCommonInfiniteContainerStyles } from "@/shared/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { get } from "lodash";
import { FC, useState } from "react";
import { useAuth } from "react-oidc-context";
import { CareTeamsList } from "../../components/CareTeamsList/CareTeamsList";
import { CareTeamsListTabs } from "../../components/CareTeamsListTabs/CareTeamsListTabs";
import { CareTeamsListFiltersForm } from "../../forms/CareTeamsListFilters/CareTeamsListFiltersForm";
import { ICareTeamsListFiltersFormValues } from "../../forms/CareTeamsListFilters/CareTeamsListFiltersFormSchema";
import { convertCareTeamsFilterFormValuesToBackEndValues } from "../../forms/CareTeamsListFilters/utils";

export const NewCareTeamsListContainer: FC = () => {
  const { classes: commonContainerClasses } = useCommonInfiniteContainerStyles();
  const [filterValues, setFilterValues] = useState<PatientEligibleTeamFilterInput>();
  const { user } = useAuth();

  const patientId = getPatientId(get(user?.profile, "patient_id") as string)!;

  const handleCareTeamsListFiltersFormSubmit = (values: ICareTeamsListFiltersFormValues) => {
    setFilterValues({ ...convertCareTeamsFilterFormValuesToBackEndValues(values) });
  };

  const { t } = useTranslation("consumer");

  return (
    <Box className={commonContainerClasses.container}>
      <Typography variant='h6' className={commonContainerClasses.title}>
        {t("Connect to a Care Team")}
      </Typography>
      <CareTeamsListTabs />
      <Box className={commonContainerClasses.filterContainer}>
        <CareTeamsListFiltersForm onSubmit={handleCareTeamsListFiltersFormSubmit} />
      </Box>
      <CareTeamsList
        filters={{
          ...filterValues,
          isPatientEnrolled: false,
          isProgramLatestVersion: true,
        }}
        patientId={patientId}
        cardVariant='newCareTeam'
      />
    </Box>
  );
};
