import { AddressSelectionContainer } from "./containers/AddressSelectionContainer/AddressSelectionContainer";
import { DeliveryTimeSelectionContainer } from "./containers/DeliveryTimeSelectionContainer/DeliveryTimeSelectionContainer";
import { MedicationsOrdersContainer } from "./containers/MedicationsOrdersContainer/MedicationsOrdersContainer";
import { MedicationsTermsContainer } from "./containers/MedicationsTermsContainer/MedicationsTermsContainer";
import { OrderAndDeliverPrescriptionContainer } from "./containers/OrderAndDeliverPrescriptionContainer/OrderAndDeliverPrescriptionContainer";
import { OrderMedicationContainer } from "./containers/OrderMedicationContainer/OrderMedicationContainer";
import { PaymentSummaryContainer } from "./containers/PaymentSummary/PaymentSummary";
import { PrescriptionOrderDetailsContainer } from "./containers/PrescriptionOrderDetailsContainer/PrescriptionOrderDetailsContainer";
import { PrescriptionDetailsExternalUrlParams } from "./others/types";

export const orderMedications = "/order-prescription";

export const orderMedicationsRoutes = {
  root: {
    route: "",
    component: OrderMedicationContainer,
    get absRoute() {
      return `${orderMedications}${this.route}`;
    },
    getRouteWithParams({ referenceId }: { referenceId: string }) {
      return `${this.absRoute}?referenceId=${referenceId}`;
    },
    getExternalPickupSmsRouteRouteWithParams({ erx, nid, mobile, mid, oid, pid }: PrescriptionDetailsExternalUrlParams) {
      return `${this.absRoute}?erx=${erx}&nid=${nid}&mobile=${mobile}&mid=${mid}&oid=${oid}&pid=${pid}`;
    },
  },
  medicationsOrders: {
    route: "medications-orders",
    component: MedicationsOrdersContainer,
    get absRoute() {
      return `${orderMedications}/${this.route}`;
    },
  },
  orderAndDeliver: {
    route: "order-and-deliver",
    component: OrderAndDeliverPrescriptionContainer,
    get absRoute() {
      return `${orderMedications}/${this.route}`;
    },
  },
  addressSelection: {
    route: "address-selection",
    component: AddressSelectionContainer,
    get absRoute() {
      return `${orderMedications}/${this.route}`;
    },
  },
  deliveryTimeSelection: {
    route: "delivery-time-selection",
    component: DeliveryTimeSelectionContainer,
    get absRoute() {
      return `${orderMedications}/${this.route}`;
    },
  },
  prescriptionOrder: {
    route: "order",
    get absRoute() {
      return `${orderMedications}/${this.route}`;
    },
    getRouteWithParams({ orderId, prescriptionId }: { orderId: string; prescriptionId: string }) {
      return `${this.absRoute}?prescriptionId=${prescriptionId}&orderId=${orderId}`;
    },
    component: PrescriptionOrderDetailsContainer,
    getExternalPickupSmsRouteRouteWithParams({ erx, nid, mobile, mid, oid, pid }: PrescriptionDetailsExternalUrlParams) {
      return `${this.absRoute}?erx=${erx}&nid=${nid}&mobile=${mobile}&mid=${mid}&oid=${oid}&pid=${pid}`;
    },
  },
  paymentSummary: {
    route: "payment-summary",
    component: PaymentSummaryContainer,
    get absRoute() {
      return `${orderMedications}/${this.route}`;
    },
  },
  termsAndConditions: {
    route: "terms-and-conditions",
    component: MedicationsTermsContainer,
    get absRoute() {
      return `${orderMedications}/${this.route}`;
    },
  },
};
