import { H_OrderDirection, PatientHealthParameterSortField } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress, Typography, useTheme } from "@toolkit/ui";
import moment from "moment";
import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { usePatientHealthParameterListQuery } from "../../gql";
import { ActivityItemCodesEnum } from "../../types";
import { toNumber } from "lodash";

type CaloriesTargetProps = {
  patientId: string;
  date?: string;
};

export const CaloriesTarget: FC<CaloriesTargetProps> = ({ patientId, date = moment().format("YYYY-MM-DD") }) => {
  const { t } = useTranslation("consumer");
  const { isAuthenticated } = useAuth();
  const theme = useTheme();

  const { data, loading } = usePatientHealthParameterListQuery({
    variables: {
      first: 1,
      filter: {
        patientId: [patientId],
        codes: [ActivityItemCodesEnum.Daily_Calories_Burned],
        requestedDateRange: {
          gte: date,
          lte: moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD"),
        },
      },
      sortBy: {
        field: PatientHealthParameterSortField.ReadingDate,
        direction: H_OrderDirection.Desc,
      },
    },
    fetchPolicy: "no-cache",
    skip: !patientId || !isAuthenticated,
  });

  const weightData = data?.patientHealthParameters?.edges?.[0]?.node;
  const weightValue = (toNumber(weightData?.valueNumber) / 1000).toFixed(2);

  return (
    <Box>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress size={18} />
        </Box>
      ) : (
        <Box textAlign='start'>
          <Typography fontSize={theme.mixins.fonts.fontSize.md} fontWeight={theme.mixins.fonts.fontWeight.medium}>
            {weightValue || "N/A"}{" "}
            <Typography component='span' fontSize={theme.mixins.fonts.fontSize.xxs}>
              {t("KCAL")}
            </Typography>
          </Typography>
          <Typography fontSize={theme.mixins.fonts.fontSize.xxs}>{t("Calories Today")}</Typography>
        </Box>
      )}
    </Box>
  );
};
