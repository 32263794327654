import { useCallback, useContext, useEffect } from "react";
import { ChatContext } from "./ChatProvider";

export const useChatDraft = (chatGroupId: string | undefined | null, clearOnEmpty = true) => {
  const { draft, updateDraft } = useContext(ChatContext);
  const clearDraft = useCallback(() => updateDraft(undefined), [updateDraft]);

  useEffect(() => {
    if (clearOnEmpty || chatGroupId) {
      clearDraft();
    }
  }, [chatGroupId, clearDraft, clearOnEmpty]);

  return { draft, updateDraft, clearDraft };
};
