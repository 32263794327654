import { useCommonContainerStyles } from "@/shared/styles";
import { Box } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { OrderTrackingViewProps } from "../../others/types";
import { useHeaderContext } from "@/shared/context";
import { useTranslation } from "@toolkit/i18n";
import { WaitingForPharmacySelectionCard } from "../WaitingForPharmacySelectionCard/WaitingForPharmacySelectionCard";
import { OrderTrackingFooter } from "../OrderTrackingFooter/OrderTrackingFooter";

export const WaitingPharmacyPage: FC<OrderTrackingViewProps> = props => {
  const { order, prescription } = props;
  const { classes } = useCommonContainerStyles();
  const { setHeaderTitle } = useHeaderContext();
  const { t } = useTranslation("consumer");

  useEffect(() => {
    setHeaderTitle(t("Pharmacy Selecting"));
    () => setHeaderTitle("");
  }, [order]);
  return (
    <>
      <Box className={classes.pageContent}>
        <WaitingForPharmacySelectionCard />
      </Box>
      <OrderTrackingFooter order={order} prescription={prescription} />
    </>
  );
};
