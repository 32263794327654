import { useTranslation } from "@toolkit/i18n";
import { Box, IconButton, CircularProgress, TextField, InputAdornment, MuiSendIcon } from "@toolkit/ui";
import { ChangeEvent, FC, useCallback, useRef } from "react";
import { ChatDraft } from "../ChatDraft";
import { useChatInput } from "./ChatInput.hooks";
import { ChatInputAttachFile } from "./ChatInputAttachFile";
import { ChatInputRecordAudio } from "./ChatInputRecordAudio";
import { ChatMessageDraft } from "../ChatProvider";
import { useStyles } from "./ChatInput.styles";

type ChatInputProps = {
  id?: string | null;
};

export const ChatInput: FC<ChatInputProps> = ({ id }) => {
  const { t } = useTranslation();
  const {
    enabled,
    draft,
    loading,
    submitDraft,
    submitAttachment: handleSubmitAttachment,
    updateDraftText: onChangeText,
    clearDraft: handleClear,
  } = useChatInput(id);
  const { classes } = useStyles();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const handleChangeText = useCallback(
    async (e: ChangeEvent<HTMLTextAreaElement>) => {
      const text = e?.target?.value;
      if (draft?.message || text.trim()) {
        onChangeText(text);
      }
    },
    [draft?.message, onChangeText]
  );

  const handleSubmit = useCallback(
    (value?: ChatMessageDraft) => {
      submitDraft(value);
    },
    [submitDraft]
  );

  const handleSubmitEditing = useCallback(() => handleSubmit(), [handleSubmit]);

  if (!enabled) {
    return null;
  }

  return (
    <Box classes={classes.root}>
      <ChatDraft draft={draft} onClose={handleClear} />
      <Box className={classes.input}>
        <TextField
          inputRef={inputRef}
          placeholder={t("Type your message...")}
          value={draft?.message || ""}
          onChange={handleChangeText}
          disabled={loading}
          className={classes.textField}
          multiline
          maxRows={3}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <ChatInputAttachFile onSubmit={handleSubmitAttachment} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <ChatInputRecordAudio id={id} draft={draft} onSubmit={handleSubmitAttachment}>
                  <IconButton disabled={loading} onClick={handleSubmitEditing}>
                    {!loading ? <MuiSendIcon /> : <CircularProgress size={30} />}
                  </IconButton>
                </ChatInputRecordAudio>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};
