import { CircularIconButton, EmptyMessageDefault } from "@/shared/components";
import { ArrowIcon } from "@/shared/icons";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMarketplaceProductCategoriesQuery } from "../../../marketplace-products/gql/queries/__generated__/marketplaceProductCategories";
import { MarketplaceCategoryItemButton } from "../../components/MarketplaceCategoryItemButton/MarketplaceCategoryItemButton";
import { routes } from "../../routes";
import { ProductsCategoriesContainerSkeleton } from "./ProductsCategoriesContainerSkeleton";
import { useProductsCategoriesContainerStyle } from "./ProductsCategoriesContainerStyle";

type ProductsCategoriesContainerProps = {
  classes?: Partial<ReturnType<typeof useProductsCategoriesContainerStyle>["classes"]>;
  hasMoreButton?: boolean;
  countOfCategories?: number;
};

export const ProductCategoriesContainer: React.FC<ProductsCategoriesContainerProps> = props => {
  const { classes: _classes, hasMoreButton, countOfCategories = 9 } = props;
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const { data, loading: isLoading } = useMarketplaceProductCategoriesQuery({
    variables: {
      first: countOfCategories,
    },
  });
  const categories = data?.marketplaceProductCategories?.edges?.map(edge => edge?.node);
  const { classes, cx } = useProductsCategoriesContainerStyle();

  const handleSeeAll = () => {
    navigate(routes.products.absRoute);
  };

  const handleNavigateToProductsWithFilter = (categoryId: string) => {
    return () => navigate(routes.products.getRouteWithParams({ categoryId }));
  };

  return isLoading ? (
    <ProductsCategoriesContainerSkeleton classes={_classes} countOfCategories={countOfCategories} />
  ) : (
    <>
      {!!categories?.length && (
        <Box className={cx(classes?.categoriesContainer, _classes?.categoriesContainer)}>
          {categories?.map(category => (
            <MarketplaceCategoryItemButton
              key={category?.id}
              title={pickLocalizedValue(category?.name, category?.nameAr)}
              imgSrc={category?.image}
              onClick={handleNavigateToProductsWithFilter(category?.id || "")}
              classes={{
                root: cx(classes?.item__root, _classes?.item__root),
                button: cx(classes?.item__button, _classes?.item__button),
                icon: cx(classes?.item__icon, _classes?.item__icon),
                title: cx(classes?.item__title, _classes?.item__title),
              }}
            />
          ))}
          {hasMoreButton && (
            <Box className={cx(classes?.item__root, _classes?.item__root)}>
              <CircularIconButton
                onClick={handleSeeAll}
                className={cx(classes.seeAllButton, classes.item__button, _classes?.item__button, _classes?.seeAllButton)}
              >
                <ArrowIcon />
              </CircularIconButton>
              <Typography className={cx(classes?.item__title, _classes?.item__title)}>{t("All Categories")}</Typography>
            </Box>
          )}
        </Box>
      )}
      {!categories?.length && <EmptyMessageDefault message={t("No categories available.", { ns: "consumer" })} />}
    </>
  );
};
