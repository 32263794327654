import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IMediaRecorder, MediaRecorder, register } from "extendable-media-recorder";
import { connect } from "extendable-media-recorder-wav-encoder";

export function useAudioRecorder() {
  const [recordDuration, setRecordDuration] = useState(0);
  const [isRecording, setRecording] = useState(false);
  const [recorder, setRecorder] = useState<IMediaRecorder>();
  const chunks = useRef<Blob[]>([]);
  const isConnected = useRef(false);
  const recorderRef = useRef(recorder);

  // eslint-disable-next-line max-statements
  const startRecording = useCallback(async () => {
    const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    if (!audioStream) {
      return;
    }

    try {
      if (!isConnected.current) {
        await register(await connect());
        isConnected.current = true;
      }
    } catch (error) {
      console.error(error);
    }

    let mimeType: string | undefined = undefined;
    if (MediaRecorder.isTypeSupported("audio/wav")) {
      mimeType = "audio/wav";
    } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
      mimeType = "audio/mp4";
    } else if (MediaRecorder.isTypeSupported("audio/webm")) {
      mimeType = "audio/webm";
    }

    const mediaRecorder = new MediaRecorder(audioStream, mimeType ? { mimeType } : undefined);
    chunks.current = [];
    mediaRecorder.ondataavailable = ev => chunks.current.push(ev.data);
    mediaRecorder.onstart = () => setRecording(true);
    mediaRecorder.onstop = () => {
      chunks.current = [];
      audioStream.getTracks().map(track => track.stop());
      setRecording(false);
    };
    mediaRecorder.start();
    setRecorder(mediaRecorder);
    recorderRef.current = mediaRecorder;
  }, []);

  const stopRecording = useCallback(async () => {
    return new Promise<Blob | undefined>(resolve => {
      try {
        if (!recorder) {
          resolve(undefined);
          return;
        }
        const { onstop } = recorder;
        recorder.onstop = (e: Event) => {
          const data = chunks.current;
          onstop?.(e);
          resolve(data.length ? new Blob(data, { type: data[0].type }) : undefined);
        };
        recorder.stop();
      } finally {
        setRecording(false);
        setRecordDuration(0);
      }
    });
  }, [recorder]);

  const cancelRecording = useCallback(async () => {
    try {
      recorderRef.current?.stop();
    } finally {
      setRecording(false);
      setRecordDuration(0);
    }
  }, []);

  useEffect(() => {
    if (!recorder) {
      return;
    }

    return () => {
      if (recorder.state === "recording") {
        recorder.stop();
      }
    };
  }, [recorder]);

  useEffect(() => {
    if (isRecording) {
      const interval = setInterval(() => setRecordDuration(r => r + 1), 1000);
      return () => clearInterval(interval);
    }

    setRecordDuration(0);
  }, [isRecording]);

  const recordDurationText = useMemo(() => {
    const minutes = Math.floor(recordDuration / 60);
    const seconds = Math.floor(recordDuration % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }, [recordDuration]);

  return {
    recordEnabled: true,
    isRecording,
    recordDuration,
    recordDurationText,
    startRecording,
    stopRecording,
    cancelRecording,
  };
}
