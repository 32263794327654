import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type PrescriptionAddressFragmentFragment = { __typename?: 'PrescriptionAddress', id: string, area?: string | null, areaAr?: string | null, streetAddress1?: string | null, streetAddress1Ar?: string | null, streetAddress2?: string | null, streetAddress2Ar?: string | null, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, city?: string | null, region?: string | null, coordinates?: string | null, name?: string | null, nameAr?: string | null };

export const PrescriptionAddressFragmentFragmentDoc = gql`
    fragment PrescriptionAddressFragment on PrescriptionAddress {
  id
  area
  areaAr
  streetAddress1
  streetAddress1Ar
  streetAddress2
  streetAddress2Ar
  buildingName
  buildingNumber
  phone
  city
  region
  coordinates
  name
  nameAr
}
    `;