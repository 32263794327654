import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomCard, DotLoader, EmptyMessageLayout } from "@/shared/components";
import { OrderOnTheWayIcon } from "@/shared/icons";
import { useOrderOnTheWayCardStyles } from "./useOrderOnTheWayCard.styles";
import { ORDER_IN_PROGRESS_STATUSES, ORDER_PROGRESS_MESSAGES } from "../../others/constants";
import { OrderTrackingViewProps } from "../../others/types";

export const OrderOnTheWayCard: FC<OrderTrackingViewProps> = ({ order }) => {
  const { classes } = useOrderOnTheWayCardStyles();
  const { t } = useTranslation("consumer");
  return (
    <CustomCard className={classes.root}>
      <EmptyMessageLayout
        classes={{
          root: classes.emptyMessageLayout,
          iconContainer: classes.iconContainer,
          messageContainer: classes.messageContainer,
          subMessageContainer: classes.subMessageContainer,
        }}
        icon={<OrderOnTheWayIcon />}
        message={t(ORDER_PROGRESS_MESSAGES[order?.status ?? ""] || "-")}
      />
      {order?.status && ORDER_IN_PROGRESS_STATUSES?.includes(order?.status) ? <DotLoader mt={2} /> : null}
    </CustomCard>
  );
};
