import { FC, PropsWithChildren } from "react";
import { useDraggable } from "@dnd-kit/core";
import { Box, useTheme } from "@toolkit/ui";

type MeetingSelfViewDraggableProps = PropsWithChildren<{
  id: string;
  top?: number;
  left?: number;
}>;

export const MeetingSelfViewDraggable: FC<MeetingSelfViewDraggableProps> = ({ id, top, left, children }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({ id });
  const theme = useTheme();
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <Box
      ref={setNodeRef}
      sx={{
        ...style,
        position: "absolute",
        top,
        [theme.direction === "rtl" ? "right" : "left"]: left,
        zIndex: 10,
        cursor: "grab",
        ...(isDragging && {
          pointerEvents: "none",
          cursor: "grabbing",
        }),
      }}
    >
      <Box
        {...listeners}
        {...attributes}
        sx={{
          cursor: "grab",
          ...(isDragging && {
            pointerEvents: "none",
            cursor: "grabbing",
          }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
