import { PageTabs } from "@/shared/components";
import { Box, makeStyles } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { AppointmentHistoryTabsEnum } from "../../types/types";
import { appointmentsHistoryTabs } from "./tabs";

const useStyles = makeStyles()(theme => ({
  root: {
    marginBlockStart: "5px",
    marginBlockEnd: "10px",
    paddingInlineStart: "10px",
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "center",
    paddingInline: theme.spacing(2),
  },
}));

export const AppointmentsHistoryTabs: FC<{ currentTab: AppointmentHistoryTabsEnum }> = ({ currentTab }) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const handleOnTabClick = (event: React.BaseSyntheticEvent, index: number) => {
    event.preventDefault();
    const selectedTab = appointmentsHistoryTabs[index]?.routeParameterValue;
    navigate(routes.myAppointments.getRouteWithParams({ tab: selectedTab }));
  };

  const tabsLabels = appointmentsHistoryTabs.map(tab => tab.label);

  const getSelectedTabIndex = () => {
    const index = appointmentsHistoryTabs.findIndex(_tab => _tab.routeParameterValue === currentTab);
    return index === -1 ? 0 : index;
  };

  return (
    <Box className={classes.tabsContainer}>
      <PageTabs
        classes={{
          root: classes.root,
        }}
        activeTabIndex={getSelectedTabIndex()}
        onTabClick={handleOnTabClick}
        tabs={tabsLabels}
      />
    </Box>
  );
};
