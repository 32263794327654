import { GenericFilterForm } from "@/shared/components";
import { marketplaceGenderOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete } from "@toolkit/ui";
import {
  healthPackagesListFiltersFormDefaultValues,
  HealthPackagesListFiltersFormSchema,
  IHealthPackagesListFiltersFormValues,
} from "../HealthPackagesListFiltersFormSchema";
import { HealthPackagesListFiltersFormProps } from "../types";

export const GenderFilterForm: React.FC<HealthPackagesListFiltersFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IHealthPackagesListFiltersFormValues>
      schema={HealthPackagesListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='gender'
      dialogTitle={t("Gender")}
      formLabel={t("Gender")}
      render={name => <FormAutocomplete options={marketplaceGenderOptions} name={name as string} label={t("Gender")} />}
      defaultValues={healthPackagesListFiltersFormDefaultValues}
    />
  );
};
