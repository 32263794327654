import { AffectedType, VisitStatus } from "@/schema/types";
import { CircularIconButton, ReasonsBaseModal, ReasonsForm } from "@/shared/components";
import { CallIcon, ChatIcon, PhoneReceiverIcon } from "@/shared/icons";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import { FC } from "react";
import { GetVisitQuery } from "../../gql";
import { useCallPageActionsStyle } from "./CallPageActionsStyle";
import { useCallAction } from "./useCallAction";

type DefaultActionPageProps = {
  visit: GetVisitQuery["visit"];
};

export const DefaultActionPage: FC<DefaultActionPageProps> = props => {
  const { visit } = props;
  const {
    isCallEndSubmitting,
    open,
    reasonsModalRf,
    handleJoinNow,
    handleDecline,
    handleClose,
    isLoadingCancel,
    handleCancelVisit,
    handleConfirmCancel,
  } = useCallAction({ visit });
  const { classes, cx } = useCallPageActionsStyle();
  const { t } = useTranslation("consumer");
  return (
    <Box className={classes.defaultRoot}>
      <Box className={classes.defaultActionContainer}>
        <CircularIconButton disabled className={cx(classes.defaultButton)}>
          <ChatIcon />
        </CircularIconButton>
        <CircularIconButton
          onClick={handleDecline}
          disabled={isCallEndSubmitting}
          className={cx(classes.defaultButton, classes.declineButton)}
        >
          <PhoneReceiverIcon />
        </CircularIconButton>
      </Box>
      <Button
        disabled={!visit?.id || visit?.status === VisitStatus.ChatInProgress}
        onClick={handleJoinNow}
        startIcon={<CallIcon rotation={-90} />}
        className={classes.joinButton}
      >
        {t("Join now")}
      </Button>
      <ReasonsBaseModal
        isConfirmDisabled={isLoadingCancel}
        handleConfirm={handleConfirmCancel}
        open={open}
        onClose={handleClose}
        title={t("Cancellation Reasons")}
      >
        <ReasonsForm affectedType={AffectedType.Visit} type='cancellation' ref={reasonsModalRf} onSubmit={handleCancelVisit} skip={!open} />
      </ReasonsBaseModal>
    </Box>
  );
};
