import { Section } from "@/shared/components";
import { useTranslation } from "@toolkit/i18n";
import { useMediaQuery, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ProductsContainer } from "../../containers/ProductsContainer/ProductsContainer";
import { routes } from "../../routes";

export const ProductBestSellersSection: FC = () => {
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const handleSeeAll = () => {
    navigate(routes.products.absRoute);
  };
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Section mt={1} mb={2} title={t("Best Sellers")} onSeeAll={handleSeeAll}>
      <ProductsContainer variant='best-sellers' hideNavBtns={isSmall} />
    </Section>
  );
};
