import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    height: "543px",
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    paddingBlockEnd: "20px",
    [theme.breakpoints.up("sm")]: {
      gap: "15px",
    },
  },
  productCardWrapper: {
    [theme.breakpoints.up("sm")]: {
      width: "230px",
      height: "335px",
    },
  },
  card: {
    [theme.breakpoints.up("sm")]: {
      marginInlineEnd: 5,
    },
  },
  skeletonContainer: {
    [theme.breakpoints.up("sm")]: {
      gap: "16px",
    },
  },
}));
