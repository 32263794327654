import { AccountSettingsInformationQuery } from "./gql/queries";

export enum MyAccountsSettingsTabsEnum {
  accountInfo = "account-info",
  payment = "payment",
  addresses = "addresses",
}

export type AccountSettingsInformationType = AccountSettingsInformationQuery["me"];

export type AccountSettingsBodyProps = {
  accountSettingsInformation: AccountSettingsInformationType;
};
