import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Visit, VisitStatus } from "@/schema/types";

type CallVisitStatusTitleProps = {
  visit?: Visit;
};

export const CallVisitStatusTitle: FC<CallVisitStatusTitleProps> = ({ visit }) => {
  const { t } = useTranslation();

  switch (visit?.status) {
    case VisitStatus.Canceled:
    case VisitStatus.EndedByOneOfTheParticipants:
    case VisitStatus.CanceledByConsumer:
    case VisitStatus.PaymentFailed:
    case VisitStatus.PaymentTimedOut:
    case VisitStatus.Rejected:
    case VisitStatus.DoctorAnswerTimedOut: {
      return t("Call cancelled");
    }
    case VisitStatus.CallTimedOut: {
      return t("Call Timeout");
    }
    case VisitStatus.Incompleted:
    case VisitStatus.Completed:
      return t("Call completed");
    case VisitStatus.Assigned:
      return t("Call assigned");
    case VisitStatus.Accepted:
    case VisitStatus.ChatInProgress:
      return t("Call accepted");
    case VisitStatus.CallInProgress:
      return t("Call in progress");
    case VisitStatus.CallRejoinable:
      return t("Call ended");
    case VisitStatus.Pending:
    case VisitStatus.PaymentPending:
    case VisitStatus.PaymentSucceeded:
    default:
      return t("Call has not started");
  }
};
