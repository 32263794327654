import { makeStyles, useTheme } from "@toolkit/ui";

const useIconStyles = makeStyles()(() => {
  return {
    iconInClock: {
      padding: 4,
      borderWidth: 2,
      borderStyle: "solid",
      borderRadius: 25,
      backgroundColor: "white",
    },
  };
});

export const SleepIcon = () => {
  const theme = useTheme();
  const { classes: styles } = useIconStyles();

  return (
    <svg width='24' height='24' viewBox='0 0 30 30' fontSize={40} className={styles.iconInClock}>
      <circle cx={15} cy={15} r={15} stroke={theme.palette.primary.main} strokeWidth={0} fill='white' />

      <path
        id='icons8-moon'
        d='M19.74,18.929A10.558,10.558,0,0,1,10.313,3.588,10.57,10.57,0,1,0,23,18.411,10.55,10.55,0,0,1,19.74,18.929Z'
        transform='translate(2 2)'
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
