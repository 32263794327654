import { useMemo } from "react";
import { AnonymousChatGroup, ChatGroup, User } from "../../schema/types";
import { getAvatar } from "./util";

type AvatarProps = {
  value: Partial<ChatGroup | AnonymousChatGroup | User> | string | undefined;
};

export const useChatAvatar = ({ value }: AvatarProps) => {
  return useMemo(() => getAvatar(value), [value]);
};
