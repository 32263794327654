import { FC } from "react";
import { useChatPreviewHooks } from "./ChatPreview.hooks";
import { Box, makeStyles, Typography } from "@toolkit/ui";
import { Chat } from "@/schema/types";

export const useStyles = makeStyles()(theme => ({
  root: {
    maxHeight: 200,
    overflow: "auto",
    padding: "0 4px",
    display: "flex",
    borderLeft: `0.2rem solid ${theme.palette.stale[300]}`,
  },
  media: {
    maxWidth: 80,
    maxHeight: 120,
    marginRight: 8,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
}));

type ChatPreviewProps = {
  chat: Chat;
};

export const ChatPreview: FC<ChatPreviewProps> = ({ chat }) => {
  const { senderName, message } = useChatPreviewHooks(chat);
  const { classes, theme } = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        {senderName && <Typography color={theme.palette.secondary.main}>{senderName}</Typography>}
        <Typography>{message}</Typography>
      </Box>
    </Box>
  );
};
