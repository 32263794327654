import { SvgIconProps, SvgIcon, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const EmptyMarketplaceIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props?.fill || theme.palette.primary.main;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='156.304' height='155.607' viewBox='0 0 156.304 155.607'>
      <g id='Group_12156' data-name='Group 12156' transform='translate(-137 -230)'>
        <g id='result_v3' data-name='result v3' transform='translate(71.621 163.748)'>
          <path
            id='Path_12746'
            d='M143.807,218.7c-18.056-4.084-34.356-10.532-49.366-22-14.867-11.428-28.516-27.764-29.054-44.494-.358-16.694,12.288-33.711,26.08-47.074a137.945,137.945,0,0,1,46.142-30.523c17.769-7.344,39.013-12.145,53.522-4.442,14.437,7.774,22.14,28.051,26.474,46.823,4.406,18.736,5.41,35.968,2.185,53.88s-10.712,36.362-24.4,44.781c-13.685,8.526-33.5,6.95-51.587,3.045'
            transform='translate(0 0)'
            fill={fill}
            opacity='0.09'
          />
          <path
            id='Path_12899'
            data-name='Path 12899'
            d='M248.178,131.667v95.616a5.772,5.772,0,0,1-5.8,5.768H140.668a5.764,5.764,0,0,1-5.768-5.768V131.667a5.764,5.764,0,0,1,5.768-5.768H242.374a5.771,5.771,0,0,1,5.8,5.768Z'
            transform='translate(-44.615 -38.279)'
            fill='#fff'
          />
          <path
            id='Path_12900'
            data-name='Path 12900'
            d='M383.178,337.893v23.967a5.772,5.772,0,0,1-5.8,5.768H345.741A27.046,27.046,0,0,1,372.323,335.6,26.486,26.486,0,0,1,383.178,337.893Z'
            transform='translate(-179.615 -172.855)'
            fill='#d3d9ed'
          />
          <path
            id='Path_12901'
            data-name='Path 12901'
            d='M240.577,232.222H138.871a6.812,6.812,0,0,1-6.771-6.771v-95.58a6.781,6.781,0,0,1,6.735-6.771H240.541a6.765,6.765,0,0,1,6.771,6.771v95.616A6.7,6.7,0,0,1,240.577,232.222ZM138.871,125.07a4.8,4.8,0,0,0-4.8,4.8v95.616a4.8,4.8,0,0,0,4.8,4.8H240.577a4.8,4.8,0,0,0,4.8-4.8V129.871a4.8,4.8,0,0,0-4.8-4.8Z'
            transform='translate(-42.818 -36.482)'
            fill='#525682'
          />
          <path
            id='Path_12902'
            data-name='Path 12902'
            d='M248.178,131.667v7.093H134.9v-7.093a5.764,5.764,0,0,1,5.768-5.768H242.374a5.771,5.771,0,0,1,5.8,5.768Z'
            transform='translate(-44.615 -38.279)'
            fill={fill}
          />
          <circle
            id='Ellipse_2898'
            data-name='Ellipse 2898'
            cx='2.508'
            cy='2.508'
            r='2.508'
            transform='translate(96.482 91.526)'
            fill='#f5f5f5'
          />
          <circle
            id='Ellipse_2899'
            data-name='Ellipse 2899'
            cx='2.508'
            cy='2.508'
            r='2.508'
            transform='translate(105.94 91.526)'
            fill='#f5f5f5'
          />
          <circle
            id='Ellipse_2900'
            data-name='Ellipse 2900'
            cx='22.14'
            cy='22.14'
            r='22.14'
            transform='translate(170.568 167.653)'
            fill='#fff'
          />
          <path
            id='Path_12903'
            data-name='Path 12903'
            d='M379.343,392.685a23.143,23.143,0,1,1,23.143-23.143h0A23.178,23.178,0,0,1,379.343,392.685Zm0-44.279a21.172,21.172,0,1,0,21.172,21.172,21.191,21.191,0,0,0-21.172-21.172Z'
            transform='translate(-186.635 -179.786)'
            fill='#525682'
          />
          <g id='Group_11163' data-name='Group 11163' transform='translate(200.553 75.225)'>
            <path
              id='Path_12904'
              data-name='Path 12904'
              d='M463.341,104.771a.973.973,0,0,0-1.4,0l-2.973,2.973a.988.988,0,0,0,1.4,1.4l2.973-2.973A.973.973,0,0,0,463.341,104.771Z'
              transform='translate(-452.952 -99.755)'
              fill='#525682'
            />
            <path
              id='Path_12905'
              data-name='Path 12905'
              d='M443.667,91.3a.963.963,0,0,0-.967.967v4.191a.967.967,0,0,0,1.935,0V92.267A.963.963,0,0,0,443.667,91.3Z'
              transform='translate(-442.7 -91.3)'
              fill='#525682'
            />
            <path
              id='Path_12906'
              data-name='Path 12906'
              d='M474.259,127.9h-4.191a.967.967,0,1,0,0,1.935h4.191a.967.967,0,1,0,0-1.935Z'
              transform='translate(-459.642 -114.788)'
              fill='#525682'
            />
          </g>
          <path
            id='Path_12907'
            data-name='Path 12907'
            d='M107.781,428.362a3.081,3.081,0,1,1,3.081-3.081h0A3.058,3.058,0,0,1,107.781,428.362Zm0-4.191a1.111,1.111,0,1,0,1.111,1.111h0A1.116,1.116,0,0,0,107.781,424.17Z'
            transform='translate(-25.234 -228.431)'
            fill='#525682'
          />
          <path
            id='Path_12908'
            data-name='Path 12908'
            d='M145.627,454.719a4.209,4.209,0,1,1,4.191-4.227h0A4.228,4.228,0,0,1,145.627,454.719Zm0-6.484a2.257,2.257,0,1,0,2.257,2.257h0A2.271,2.271,0,0,0,145.627,448.235Z'
            transform='translate(-48.787 -243.897)'
            fill='#525682'
          />
          <g id='Group_11164' data-name='Group 11164' transform='translate(125.859 197.996)'>
            <path
              id='Path_12909'
              data-name='Path 12909'
              d='M283.1,463.67h-1.97V461.7h1.97Zm-5.23,0H275.9V461.7h1.97Zm-5.195,0h-1.97V461.7h1.97Zm-5.23,0h-1.97V461.7h1.97Zm-5.195,0h-1.97V461.7h1.97Zm-5.23,0h-1.97V461.7h1.97Zm-5.23,0h-1.97V461.7h1.97Zm-5.195,0h-1.97V461.7h1.97Zm-5.23,0h-1.97V461.7h1.97Zm-5.195,0H234.2V461.7h1.97Z'
              transform='translate(-234.2 -451.777)'
              fill='#a9b4d3'
            />
            <path
              id='Path_12910'
              data-name='Path 12910'
              d='M236.17,441.022H234.2v-1.97h1.97Zm0-5.23H234.2V434h1.97Z'
              transform='translate(-234.2 -434)'
              fill='#a9b4d3'
            />
          </g>
          <path
            id='Path_12911'
            data-name='Path 12911'
            d='M407,391.745h-5.553a11.781,11.781,0,0,1,4.908-9.565,3.673,3.673,0,0,0-4.263-5.983,3.635,3.635,0,0,0-1.54,2.973H395a9.207,9.207,0,1,1,14.581,7.523A6.212,6.212,0,0,0,407,391.745Z'
            transform='translate(-211.535 -194.931)'
            fill={fill}
          />
          <rect
            id='Rectangle_7025'
            data-name='Rectangle 7025'
            width='5.553'
            height='5.553'
            transform='translate(189.877 199.286)'
            fill={fill}
          />
          <g id='Group_11165' data-name='Group 11165' transform='translate(139.58 112.304)'>
            <rect
              id='Rectangle_7026'
              data-name='Rectangle 7026'
              width='48.722'
              height='4.514'
              transform='translate(0.107)'
              fill={fill}
              opacity='0.09'
            />
            <rect
              id='Rectangle_7027'
              data-name='Rectangle 7027'
              width='33.281'
              height='4.514'
              transform='translate(15.512 10.891)'
              fill={fill}
              opacity='0.09'
            />
            <rect
              id='Rectangle_7028'
              data-name='Rectangle 7028'
              width='12.969'
              height='4.514'
              transform='translate(0.107 10.891)'
              fill={fill}
              opacity='0.09'
            />
            <rect
              id='Rectangle_7029'
              data-name='Rectangle 7029'
              width='48.722'
              height='4.514'
              transform='translate(0.107 21.817)'
              fill={fill}
              opacity='0.09'
            />
            <rect
              id='Rectangle_7030'
              data-name='Rectangle 7030'
              width='22.964'
              height='4.514'
              transform='translate(0.107 32.744)'
              fill={fill}
              opacity='0.09'
            />
            <rect
              id='Rectangle_7031'
              data-name='Rectangle 7031'
              width='22.104'
              height='4.514'
              transform='translate(25.686 32.744)'
              fill={fill}
              opacity='0.09'
            />
            <path
              id='Path_12912'
              data-name='Path 12912'
              d='M291.595,327.9H276.548a4.048,4.048,0,0,1,0-8.1h15.046a4.048,4.048,0,0,1,0,8.1Z'
              transform='translate(-272.5 -275.019)'
              fill='#d3d9ed'
            />
          </g>
          <g id='Group_11166' data-name='Group 11166' transform='translate(97.486 115.313)'>
            <circle
              id='Ellipse_2901'
              data-name='Ellipse 2901'
              cx='8.061'
              cy='8.061'
              r='8.061'
              transform='translate(0 18.235)'
              fill={fill}
              opacity='0.09'
            />
            <path
              id='Path_12913'
              data-name='Path 12913'
              d='M168.765,203.2a2.448,2.448,0,0,0-2.221,1.433c-.752,1.684-2.436,5.374-2.436,5.374a.954.954,0,0,0-.107.43,4.516,4.516,0,0,0,2.794,4.191v16.623h-1.72a1.039,1.039,0,0,0,0,2.078h12.646a1.039,1.039,0,1,0,0-2.078h-8.777v-16.3A4.49,4.49,0,0,0,172.1,213.3a4.5,4.5,0,0,0,7.022,0,4.5,4.5,0,0,0,7.022,0,4.562,4.562,0,0,0,3.153,1.648v1.9a1.039,1.039,0,0,0,2.078,0v-2.221a4.553,4.553,0,0,0,2.794-4.191h0c0-.072-.036-.143-.036-.215a1.552,1.552,0,0,0-.036-.179h0s-1.684-3.69-2.436-5.374a2.427,2.427,0,0,0-2.221-1.433H168.765Zm0,2.114h20.671a.344.344,0,0,1,.322.215l2.329,5.123a2.458,2.458,0,0,1-4.908-.215,1.04,1.04,0,0,0-1.039-1.039,1.288,1.288,0,0,0-.394.072,1.016,1.016,0,0,0-.645.967,2.472,2.472,0,1,1-4.944,0,1.04,1.04,0,0,0-1.039-1.039,1.288,1.288,0,0,0-.394.072,1.016,1.016,0,0,0-.645.967,2.472,2.472,0,1,1-4.944,0,1.039,1.039,0,1,0-2.078,0,2.458,2.458,0,0,1-4.908.215l2.329-5.159A.319.319,0,0,1,168.765,205.314Zm6.126,11.93a2.353,2.353,0,0,0-1.72.716,2.413,2.413,0,0,0-.716,1.72v4.227a2.477,2.477,0,0,0,2.472,2.472h2.794a1.039,1.039,0,0,0,0-2.078h-2.794a.359.359,0,0,1-.358-.358v-4.227a.367.367,0,0,1,.358-.358h8.347a1.039,1.039,0,1,0,0-2.078h-8.383Zm14.294,2.83a5.082,5.082,0,0,0-3.547,1.469l-1.684,1.684-1.684,1.684a4.99,4.99,0,1,0,7.057,7.057l1.684-1.684L192.7,228.6a5.017,5.017,0,0,0,0-7.057A4.738,4.738,0,0,0,189.185,220.073Zm0,2.078a2.9,2.9,0,0,1,2.042,4.944l-.931.931-4.084-4.084.931-.931A2.91,2.91,0,0,1,189.185,222.151Zm-4.442,3.26,4.084,4.084-.931.931a2.855,2.855,0,0,1-4.084,0,2.886,2.886,0,0,1,0-4.084Z'
              transform='translate(-160.776 -203.2)'
              fill={fill}
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
