import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type MarketplaceWishlistHealthPackageDetailsFragmentFragment = { __typename?: 'MarketplaceHealthPackage', name?: string | null, nameAr?: string | null, mainImageUrl?: string | null, price?: any | null, description?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, category?: { __typename?: 'MarketplaceHealthPackageCategory', name?: string | null, nameAr?: string | null, id: string } | null, vendor: { __typename?: 'Vendor', name: string, nameAr?: string | null }, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, percentage?: number | null, type: Types.MarketplaceDiscountType } | null };

export const MarketplaceWishlistHealthPackageDetailsFragmentFragmentDoc = gql`
    fragment MarketplaceWishlistHealthPackageDetailsFragment on MarketplaceHealthPackage {
  category {
    name
    nameAr
    id
  }
  name
  nameAr
  mainImageUrl
  price
  description
  descriptionAr
  shortDescription
  shortDescriptionAr
  vendor {
    name
    nameAr
  }
  activeDiscount {
    amount
    percentage
    type
  }
}
    `;