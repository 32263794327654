import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { DoctorInfoFragmentFragmentDoc } from './doctorInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentsHistoryQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.AppointmentFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.AppointmentSortingInput>;
}>;


export type AppointmentsHistoryQuery = { __typename?: 'Query', appointments?: { __typename?: 'AppointmentCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, startCursor?: string | null, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'AppointmentCountableEdge', node: { __typename?: 'Appointment', id: string, created?: any | null, confirmedStartTime?: any | null, confirmedEndTime?: any | null, requestedBy?: Types.RequestedBy | null, paymentStatus?: Types.PaymentStatus | null, requestedTimeSlots?: Array<string | null> | null, status?: Types.AppointmentStatus | null, type?: Types.AppointmentType | null, suggestedTimeSlots?: Array<string | null> | null, visit?: { __typename?: 'Visit', id: string, status?: Types.VisitStatus | null, noteToPatient?: string | null, callId?: any | null, chatId?: any | null } | null, consumer?: { __typename?: 'Patient', id: string } | null, vendor?: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, logo?: string | null, isIntegrated: boolean } | null, doctor?: { __typename?: 'Doctor', id: string, yearsOfExperience?: number | null, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, gender?: Types.UserGender | null, photo?: string | null } | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, arabicDisplay?: string | null, display?: string | null } | null> | null, vendor: { __typename?: 'Vendor', id: string, logo?: string | null, name: string, nameAr?: string | null, isIntegrated: boolean }, languages?: Array<{ __typename?: 'Language', display: string, displayAr?: string | null, id: string } | null> | null } | null, paymentInfo?: { __typename?: 'AppointmentPaymentRequest', totalAmount?: number | null, priceAmountAfterVat?: number | null } | null } }> } | null };


export const AppointmentsHistoryDocument = gql`
    query AppointmentsHistory($after: String, $filter: AppointmentFilterInput, $first: Int, $before: String, $last: Int, $sortBy: AppointmentSortingInput) {
  appointments(
    after: $after
    filter: $filter
    first: $first
    before: $before
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    edges {
      node {
        id
        created
        confirmedStartTime
        confirmedEndTime
        requestedBy
        paymentStatus
        requestedTimeSlots
        status
        visit {
          id
          status
          noteToPatient
          callId
          chatId
        }
        consumer {
          id
        }
        vendor {
          id
          name
          nameAr
          logo
          isIntegrated
        }
        doctor {
          ...DoctorInfoFragment
        }
        type
        suggestedTimeSlots
        paymentInfo {
          totalAmount
          priceAmountAfterVat
        }
      }
    }
    totalCount
  }
}
    ${DoctorInfoFragmentFragmentDoc}`;

/**
 * __useAppointmentsHistoryQuery__
 *
 * To run a query within a React component, call `useAppointmentsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsHistoryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useAppointmentsHistoryQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentsHistoryQuery, AppointmentsHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentsHistoryQuery, AppointmentsHistoryQueryVariables>(AppointmentsHistoryDocument, options);
      }
export function useAppointmentsHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsHistoryQuery, AppointmentsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentsHistoryQuery, AppointmentsHistoryQueryVariables>(AppointmentsHistoryDocument, options);
        }
export function useAppointmentsHistorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AppointmentsHistoryQuery, AppointmentsHistoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppointmentsHistoryQuery, AppointmentsHistoryQueryVariables>(AppointmentsHistoryDocument, options);
        }
export type AppointmentsHistoryQueryHookResult = ReturnType<typeof useAppointmentsHistoryQuery>;
export type AppointmentsHistoryLazyQueryHookResult = ReturnType<typeof useAppointmentsHistoryLazyQuery>;
export type AppointmentsHistorySuspenseQueryHookResult = ReturnType<typeof useAppointmentsHistorySuspenseQuery>;
export type AppointmentsHistoryQueryResult = Apollo.QueryResult<AppointmentsHistoryQuery, AppointmentsHistoryQueryVariables>;