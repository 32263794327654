import { useMyMarketplaceWishlistQuery } from "@/gql";
import {
  MarketplaceWishlistAddItemMutationVariables,
  useMarketplaceWishlistAddItemMutation,
  useMarketplaceWishlistRemoveItemsMutation,
} from "@/gql/mutations";
import { MarketplaceWishlistInput } from "@/schema/types";
import { NetworkStatus } from "@apollo/client";
import { formatGraphQLError } from "@toolkit/apollo";
import { useAddToast } from "@toolkit/ui";
import { find } from "lodash";
import { createContext, FC, PropsWithChildren, useMemo, useReducer } from "react";
import { useAuth } from "react-oidc-context";
import { marketplaceItemType } from "../marketplaceCartContext/types";
import { WishlistContextProps } from "./types";
import { generateWishlistItemsHashMap, itemsLoadingTrackerMapReducer } from "./utils";

export const WishlistContext = createContext<WishlistContextProps | undefined>(undefined);

export const WishlistProvider: FC<PropsWithChildren> = ({ children }) => {
  const { failed } = useAddToast();
  const { isAuthenticated } = useAuth();

  const { data, refetch, networkStatus } = useMyMarketplaceWishlistQuery({
    skip: !isAuthenticated,
    fetchPolicy: "cache-and-network",
  });

  const wishlistItems = useMemo(() => generateWishlistItemsHashMap(data?.me?.marketplaceWishlist), [data]);

  const [itemsLoadingTrackerMap, dispatch] = useReducer(itemsLoadingTrackerMapReducer, {
    product: {},
    healthPackage: {},
  });

  const [addItemToWish] = useMarketplaceWishlistAddItemMutation({
    onCompleted: (productAdded, clientOptions) => {
      if (productAdded?.marketplaceWishlistAddItem?.id) {
        refetch();
        const variables = clientOptions?.variables as MarketplaceWishlistAddItemMutationVariables;
        const type = variables?.input?.productId ? "product" : "healthPackage";
        const id = variables?.input?.productId || variables?.input?.healthPackageId;
        dispatch({
          type: "SET_LOADED",
          payload: {
            id: id || "",
            category: type,
          },
        });
      }
    },
    onError: ({ graphQLErrors }, clientOption) => {
      failed(formatGraphQLError(graphQLErrors));
      const variables = clientOption?.variables as MarketplaceWishlistAddItemMutationVariables;
      const type = variables?.input?.productId ? "product" : "healthPackage";
      const id = variables?.input?.productId || variables?.input?.healthPackageId;
      dispatch({
        type: "SET_LOADED",
        payload: {
          id: id || "",
          category: type,
        },
      });
    },
  });

  const [removeItemFromWishlist] = useMarketplaceWishlistRemoveItemsMutation({
    onCompleted: () => refetch(),
    onError: ({ graphQLErrors }, clientOptions) => {
      failed(formatGraphQLError(graphQLErrors));
      const variables = clientOptions?.variables as MarketplaceWishlistAddItemMutationVariables;
      const type = variables?.input?.productId ? "product" : "healthPackage";
      const id = variables?.input?.productId || variables?.input?.healthPackageId;
      dispatch({
        type: "SET_LOADED",
        payload: {
          id: id || "",
          category: type,
        },
      });
    },
  });

  const toggleItem = (input: MarketplaceWishlistInput | undefined) => {
    const type = input?.productId ? "product" : "healthPackage";
    const id = input?.productId || input?.healthPackageId;
    if (!id) return;
    if (wishlistItems[type][id]) {
      const item = find(
        data?.me?.marketplaceWishlist?.items,
        item => (type === "product" && item?.product?.id === id) || (type === "healthPackage" && item?.healthPackage?.id === id)
      );
      if (!item) return;
      removeItemFromWishlist({
        variables: {
          wishlistItemIds: [item?.id],
        },
      }).then(res => {
        res?.data?.marketplaceWishlistRemoveItems?.id && dispatch({ type: "SET_LOADED", payload: { id, category: type } });
      }),
        dispatch({
          type: "SET_LOADING",
          payload: {
            id,
            category: type,
          },
        });
    } else {
      addItemToWish({
        variables: {
          input,
        },
      });
      dispatch({
        type: "SET_LOADING",
        payload: {
          id,
          category: type,
        },
      });
    }
  };

  const addItem = async (id: string, type: marketplaceItemType) => {
    const input = type === "product" ? { productId: id } : { healthPackageId: id };

    dispatch({ type: "SET_LOADING", payload: { id, category: type } });

    try {
      const response = await addItemToWish({
        variables: { input },
      });

      response?.data?.marketplaceWishlistAddItem?.id && dispatch({ type: "SET_LOADED", payload: { id, category: type } });

      return response;
    } finally {
      dispatch({ type: "SET_LOADED", payload: { id, category: type } });
    }
  };

  const removeItem = (id: string, type: marketplaceItemType) => {
    const item = find(
      data?.me?.marketplaceWishlist?.items,
      item => (type === "product" && item?.product?.id === id) || (type === "healthPackage" && item?.healthPackage?.id === id)
    );
    if (!item) return;
    removeItemFromWishlist({
      variables: {
        wishlistItemIds: [item?.id],
      },
    }).then(res => {
      res?.data?.marketplaceWishlistRemoveItems?.id && dispatch({ type: "SET_LOADED", payload: { id, category: type } });
    }),
      dispatch({
        type: "SET_LOADING",
        payload: {
          id,
          category: type,
        },
      });
  };

  return (
    <WishlistContext.Provider
      value={{
        dataWishlist: data?.me?.marketplaceWishlist?.items,
        wishlistItems,
        toggleItem,
        removeItem,
        addItem,
        itemsLoadingTrackerMap,
        loadingWishlist: networkStatus === NetworkStatus.loading,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};
