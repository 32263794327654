import { useMemo } from "react";
import { CallParticipant } from "../AntMedia/types";
import { toBase64 } from "@toolkit/core";
import { Meeting } from "@/schema/types";

export function useCallParticipants({
  meeting,
  userId,
  displayName,
}: {
  meeting?: Meeting | undefined;
  userId?: number;
  displayName?: string;
}) {
  const userIdentity = useMemo(() => (userId ? toBase64(`User:${userId}`) : undefined), [userId]);

  const currentParticipant = useMemo<CallParticipant>(
    () => ({
      id: userIdentity,
      displayName,
      info: {
        __typename: "User",
        fullName: displayName,
      },
      userIdentity: userIdentity,
      isCurrentUser: true,
      state: { audioMuted: true, videoMuted: true, screenMuted: true },
    }),
    [displayName, userIdentity]
  );

  const participants = useMemo<CallParticipant[]>(() => {
    if (!meeting?.participants.length) {
      return currentParticipant ? [currentParticipant] : [];
    }

    return meeting.participants.map(p => ({
      id: p.id,
      displayName: p.fullName!,
      info: p,
      userIdentity: p.id,
      isCurrentUser: p.id === userIdentity,
      state: { audioMuted: true, videoMuted: true, screenMuted: true },
    }));
  }, [currentParticipant, meeting?.participants, userIdentity]);

  return {
    participants,
    currentParticipant,
  };
}
