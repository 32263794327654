import { weightLossPlanCalculationRoutes } from "@/domains/digital-twin-weight-loss-calculator/routes";
import { MainPageLayout } from "@/shared/components";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const WeightLossCalculatorRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageLayout containerBreakpoint='sm' />}>
        <Route path={weightLossPlanCalculationRoutes.root.route} Component={weightLossPlanCalculationRoutes.root.component} />
        <Route path={weightLossPlanCalculationRoutes.form.route} Component={weightLossPlanCalculationRoutes.form.component} />
      </Route>
    </Routes>
  );
};
