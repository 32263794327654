import { VisitStatus } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useRef } from "react";

import { usePatientVisitCancelMutation, useVisitCallEndMutation, useVisitCallRejoinMutation } from "@/domains/VirtualVisit/gql";
import { openMeetingPlatformLink, ReJoinableVisitStatuses } from "@/domains/VirtualVisit/utils";
import { ReasonsFormRef } from "@/shared/components";
import { useOpenState } from "@toolkit/core";
import { GetVisitQuery } from "../../gql";

type UseVisitStartingNowCardProps = {
  visit?: GetVisitQuery["visit"];
};

export const useCallAction = ({ visit }: UseVisitStartingNowCardProps) => {
  const { succeeded, failed } = useAddToast();
  const { open, handleClose, handleOpen } = useOpenState();
  const reasonsModalRf = useRef<ReasonsFormRef>(null);
  const { t } = useTranslation("consumer");

  const [visitCallEnd, { loading: isCallEndSubmitting }] = useVisitCallEndMutation({
    onCompleted: request => {
      const visitErrors = request?.visitCallEnd?.visitErrors;
      if (visitErrors?.length === 0) {
        succeeded(t("Visit call ended successfully"));
      } else {
        formatMessageErrors(visitErrors);
        failed(t("Visit Call End Failed"));
      }
    },
    update: cache => {
      const normalizedId = cache.identify({ id: visit?.id, __typename: "Visit" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const [rejoinCallMutation] = useVisitCallRejoinMutation({});

  const [patientVisitCancel, { loading: isLoadingCancel }] = usePatientVisitCancelMutation({
    onCompleted: request => {
      const visitErrors = request?.patientVisitCancel?.visitErrors;
      if (visitErrors?.length === 0) {
        succeeded(t("Patient visit successfully cancelled"));
        handleClose();
      } else {
        failed(t(formatMessageErrors(visitErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    // eslint-disable-next-line sonarjs/no-identical-functions
    update: cache => {
      const normalizedId = cache.identify({ id: visit?.id, __typename: "Visit" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const handleJoin = (id?: string | null) => {
    id && openMeetingPlatformLink(id);
  };

  const handleJoinNow = () => {
    if (visit?.status === VisitStatus.CallInProgress && visit?.id) {
      openMeetingPlatformLink(visit?.id);
    } else if (visit?.status && ReJoinableVisitStatuses.includes(visit?.status)) {
      rejoinCallMutation({
        variables: { visitId: visit?.id || "" },
        onCompleted: request => {
          const visitResponse = request?.visitCallRejoin;
          if (visitResponse?.visitErrors?.length === 0) {
            failed(formatMessageErrors(visitResponse?.visitErrors));
          } else {
            handleJoin(visitResponse?.visit?.id);
          }
        },
        onError: ({ graphQLErrors }) => {
          failed(formatGraphQLError(graphQLErrors));
        },
      });
    } else if (visit?.id) {
      handleJoin(visit?.id);
    }
  };

  const handleDecline = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (visit?.status === VisitStatus.ChatInProgress || visit?.status === VisitStatus.Assigned) {
      handleOpen();
    } else
      visitCallEnd({
        variables: { visitId: visit?.id || "" },
      });
  };

  const handleCancelVisit = (reason: string) => {
    patientVisitCancel({
      variables: {
        patientVisitCancelId: visit?.id || "",
        input: {
          cancellationReason: reason,
        },
      },
    });
  };

  const handleConfirmCancel = () => {
    reasonsModalRf.current?.submit();
  };

  return {
    isCallEndSubmitting,
    open,
    reasonsModalRf,
    handleJoinNow,
    handleDecline,
    handleClose,
    isLoadingCancel,
    handleCancelVisit,
    handleConfirmCancel,
  };
};
