import { useContext } from "react";
import { MarketplaceCartContext } from "./MarketplaceCartContext";

export const useMarketplaceCartContext = () => {
  const context = useContext(MarketplaceCartContext);
  if (!context) {
    throw new Error("useMarketplaceCartContext must be used within a CartProvider");
  }
  return context;
};
