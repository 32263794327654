import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type MarketplaceWishlistProductDetailsFragmentFragment = { __typename?: 'MarketplaceProduct', price?: any | null, name?: string | null, nameAr?: string | null, mainImageUrl?: string | null, description?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, vendor: { __typename?: 'Vendor', name: string, nameAr?: string | null }, category?: { __typename?: 'MarketplaceProductCategory', id: string, name?: string | null, nameAr?: string | null } | null, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, percentage?: number | null, type: Types.MarketplaceDiscountType } | null };

export const MarketplaceWishlistProductDetailsFragmentFragmentDoc = gql`
    fragment MarketplaceWishlistProductDetailsFragment on MarketplaceProduct {
  vendor {
    name
    nameAr
  }
  price
  name
  nameAr
  mainImageUrl
  description
  descriptionAr
  shortDescription
  shortDescriptionAr
  category {
    id
    name
    nameAr
  }
  activeDiscount {
    amount
    percentage
    type
  }
}
    `;