import { SvgIconProps, SvgIcon } from "@toolkit/ui";
import { FC } from "react";

export const EmptyOrderIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='120' height='120' viewBox='0 0 120 120' {...props}>
      <defs>
        <style>
          {`
            .cls-1 {
              fill: #f5f5f5;
            }

            .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11 {
              stroke-width: 0px;
            }

            .cls-2 {
              fill: #f7f8fc;
            }

            .cls-2, .cls-8, .cls-9, .cls-10, .cls-12 {
              isolation: isolate;
            }

            .cls-3 {
              fill: #525682;
            }

            .cls-4 {
              fill: #535783;
            }

            .cls-5 {
              fill: #2b3078;
            }

            .cls-6, .cls-9 {
              fill: #a9b4d3;
            }

            .cls-7 {
              fill: #283275;
            }

            .cls-8, .cls-9 {
              mix-blend-mode: multiply;
            }

            .cls-8, .cls-10 {
              fill: #ebecf7;
            }

            .cls-9 {
              opacity: 0.4;
            }

            .cls-11 {
              fill: #fff;
            }
          `}
        </style>
      </defs>
      <path
        id='Path_12746'
        data-name='Path 12746'
        className='cls-10'
        d='M61.6,119.7c-14.2-3.2-27-8.3-38.8-17.3C11.1,93.5.4,80.7,0,67.5c-.3-13.1,9.7-26.5,20.5-37,10.3-10.4,22.7-18.5,36.2-24,14-5.8,30.6-9.5,42-3.5,11.3,6.1,17.4,22,20.8,36.8,3.5,14.7,4.2,28.3,1.7,42.3s-8.4,28.6-19.2,35.2c-10.7,6.7-26.3,5.5-40.5,2.4'
      />
      <g className='cls-12'>
        <g id='Purchase'>
          <path
            className='cls-8'
            d='M89.3,14.8v75.6c0,3.5-2.9,6.4-6.4,6.4h-35.9c-3.5,0-6.4-2.9-6.4-6.4V14.8c0-3.5,2.9-6.4,6.4-6.4h35.9c3.5,0,6.4,2.9,6.4,6.4h0Z'
          />
          <path
            className='cls-11'
            d='M85.5,15.3v74.5c0,1.7-1.4,3.1-3.1,3.1h-34.9c-1.7,0-3.1-1.4-3.1-3.1V15.3c0-1.7,1.4-3.1,3.1-3.1h7.5c1.1,0,1.9.9,1.9,1.9h0c0,1.1.9,1.9,1.9,1.9,0,0,0,0,0,0h12.1c1.1,0,1.9-.9,1.9-1.9h0c0-1.1.9-1.9,1.9-1.9h7.5c1.7,0,3.1,1.4,3.1,3.1h0Z'
          />
          <path
            className='cls-10'
            d='M89.3,80.2v10.2c0,3.5-2.9,6.4-6.4,6.4h-33.7v-2.7c0-2.9,1.1-5.7,3.1-7.9l4.5-5v-13.4c0-4.7,3.7-8.6,8.4-8.7s8.6,3.7,8.7,8.4c0,.1,0,.2,0,.3v8c1.7.1,3.4.8,4.7,1.9,3-.8,6.2.1,8.3,2.5.8-.1,1.7-.1,2.5,0Z'
          />
          <path
            className='cls-3'
            d='M82.9,97.6h-35.9c-4,0-7.2-3.2-7.2-7.2V14.8c0-4,3.2-7.2,7.2-7.2h35.9c4,0,7.2,3.2,7.2,7.2v75.6c0,4-3.2,7.2-7.2,7.2ZM47,9.2c-3.1,0-5.6,2.5-5.6,5.6v75.6c0,3.1,2.5,5.6,5.6,5.6h35.9c3.1,0,5.6-2.5,5.6-5.6V14.8c0-3.1-2.5-5.6-5.6-5.6h-35.9Z'
          />
          <path
            className='cls-5'
            d='M71.1,33.3v2.8h-2.8v-3.1c0-1.8-1.5-3.2-3.2-3.2-1.8,0-3.2,1.5-3.2,3.2v3.1h-2.8v-2.8c0-3.3,2.7-6,6-6s6,2.7,6,6Z'
          />
          <path className='cls-5' d='M75.2,36.1h-20.5l-2.5,20.9h25.5l-2.5-20.9Z' />
          <rect className='cls-1' x='59.1' y='36.1' width='2.7' height='2.5' />
          <circle className='cls-1' cx='74.6' cy='57' r='6.6' />
          <path
            className='cls-5'
            d='M74.6,64.4c-4.1,0-7.4-3.3-7.4-7.4s3.3-7.4,7.4-7.4,7.4,3.3,7.4,7.4h0c0,4.1-3.3,7.4-7.4,7.4ZM74.6,51.2c-3.2,0-5.8,2.6-5.8,5.8s2.6,5.8,5.8,5.8,5.8-2.6,5.8-5.8h0c0-3.2-2.6-5.8-5.8-5.8Z'
          />
          <rect className='cls-5' x='73.6' y='54.1' width='2.1' height='5.8' />
          <rect className='cls-5' x='71.7' y='56' width='5.8' height='2.1' />
          <rect className='cls-1' x='68.4' y='36.1' width='2.7' height='2.5' />
          <path
            id='Path_23403'
            data-name='Path 23403'
            className='cls-10'
            d='M93.5,98.7l-20.7-.5c-3-10.4.6-22.6,15.7-24.7,0,0,.6,25.2.6,25.2h4.3Z'
          />
          <path
            className='cls-2'
            d='M65.3,63.5c2.3,0,4.3,1.9,4.3,4.3v16c.1-2,1.9-3.6,4-3.5,1.9.1,3.3,1.6,3.5,3.5v1.7c.1-2.1,1.9-3.6,3.9-3.5,1.9.1,3.4,1.6,3.5,3.5v2.7h0c0-2.1,1.8-3.7,3.8-3.6,2,0,3.6,1.7,3.6,3.6v10.4c0,2.6-.7,5.2-2,7.5l-6.6,11.3,1.6,8h-22.9v-7.6s-7.6-13.5-7.6-13.5c-.6-1.1-.9-2.3-.9-3.6v-6.5c0-1.8.7-3.6,1.9-5l5.7-6.2v-15.1c0-2.3,1.9-4.3,4.3-4.3h0Z'
          />
          <path className='cls-9' d='M57.4,88.2v5.2c0,.6.2,1.3.6,1.8l3.2,4.7v-17.1l-2.9,3.2c-.5.6-.8,1.4-.8,2.2Z' />
          <path
            className='cls-3'
            d='M84.2,125.5l-1.7-8.3,6.8-11.6c1.2-2.2,1.8-4.6,1.8-7.1v-10.4c0-1.6-1.4-2.9-3-2.9-1.6,0-2.8,1.3-2.9,2.9h-1.6v-2.7c-.1-1.6-1.5-2.8-3.1-2.7-1.5,0-2.6,1.3-2.7,2.7v2.6h-1.6v-4.3c-.1-1.6-1.5-2.8-3.1-2.7-1.4.1-2.6,1.3-2.7,2.7v4.3h-1.6v-20.3c0-1.9-1.5-3.5-3.5-3.5s-3.5,1.5-3.5,3.5v32.1h-1.6v-15l-4.3,4.7c-1.1,1.2-1.7,2.8-1.7,4.5v6.5c0,1.1.3,2.2.8,3.2l7.7,13.7v7.8h-1.6v-7.4s-7.5-13.4-7.5-13.4c-.7-1.2-1-2.6-1-4v-6.5c0-2,.8-4,2.1-5.5l5.5-6v-14.8c0-2.8,2.3-5,5-5s5,2.3,5,5v12.6c1.9-1.6,4.8-1.4,6.4.5.3.4.6.9.8,1.4,1.8-1.8,4.6-1.7,6.4,0,.7.7,1.1,1.5,1.3,2.5,2.9-2.5,7.5-.6,7.5,3.4v10.4c0,2.8-.7,5.5-2.1,7.9l-6.5,11.1,1.6,7.7-1.6.3Z'
          />
          <path
            className='cls-6'
            d='M50.2,103.8h-1.6v-1.6h1.6v1.6ZM45.9,103.8h-1.6v-1.6h1.6v1.6ZM41.7,103.8h-1.6v-1.6h1.6v1.6ZM37.4,103.8c-.6,0-1.2,0-1.7,0l.3-1.6c.5,0,1,0,1.5,0v1.6ZM32.9,102.3c-.5-.4-.8-.9-1.1-1.5l1.4-.7c.2.4.5.8.8,1.1l-1.1,1.1ZM32.9,97.8h-1.6v-1.6h1.6v1.6ZM32.9,93.6h-1.6v-1.6h1.6v1.6ZM32.9,89.3h-1.6v-1.6h1.6v1.6ZM32.9,85.1h-1.6v-1.6h1.6v1.6ZM32.9,80.8h-1.6v-1.6h1.6v1.6ZM32.9,76.6h-1.6v-1.6h1.6v1.6ZM32.9,72.3h-1.6v-1.6h1.6v1.6ZM32.9,68.1h-1.6v-1.6h1.6v1.6ZM32.9,63.8h-1.6v-1.6h1.6v1.6ZM32.9,59.6h-1.6v-1.6h1.6v1.6ZM32.9,55.3h-1.6v-1.6h1.6v1.6ZM33,51.3l-1.6-.3c.1-.6.4-1.2.7-1.7l1.3.9c-.2.4-.4.8-.5,1.2h0ZM35.1,48.6l-.7-1.5c.6-.3,1.2-.4,1.8-.4v1.6c-.3,0-.8.1-1.2.3h0Z'
          />
          <path className='cls-6' d='M50.2,48.3h-1.6v-1.6h1.6v1.6ZM46,48.3h-1.6v-1.6h1.6v1.6Z' />
          <path
            className='cls-3'
            d='M98.6,61.3c.6-1.7,2.4-2.6,4-2,0,0,0,0,0,0,2.7.9,3,4.6.4,5.9-1.6.8-3.5.2-4.3-1.4-.4-.8-.4-1.6-.2-2.4ZM100.2,61.8c-.3.8.2,1.7,1,2,.8.3,1.7-.2,2-1,.3-.8-.2-1.7-1-2-.8-.3-1.7.2-2,1,0,0,0,0,0,0Z'
          />
          <path
            className='cls-3'
            d='M108.7,73.6c.4-1.3,1.8-2,3.1-1.6,1.3.4,2,1.8,1.6,3.1-.2.6-.7,1.1-1.2,1.4-1.2.6-2.7.1-3.3-1.1-.3-.6-.3-1.3-.1-1.9ZM111,73.6c-.5,0-.9.4-.9.9,0,.5.4.9.9.9.1,0,.3,0,.4,0,.4-.2.6-.7.4-1.1-.1-.3-.5-.5-.8-.5h0Z'
          />
          <g>
            <rect className='cls-3' x='30.6' y='30.8' width='4.3' height='1.6' rx='.8' ry='.8' />
            <rect
              className='cls-3'
              x='32.2'
              y='36.5'
              width='4.3'
              height='1.6'
              rx='.8'
              ry='.8'
              transform='translate(-16.3 35.2) rotate(-45)'
            />
            <rect
              className='cls-3'
              x='33.3'
              y='23.9'
              width='1.6'
              height='4.3'
              rx='.8'
              ry='.8'
              transform='translate(-8.4 31.7) rotate(-45)'
            />
            <rect className='cls-3' x='94.6' y='30.5' width='4.3' height='1.6' rx='.8' ry='.8' />
            <rect
              className='cls-3'
              x='94.4'
              y='34.8'
              width='1.6'
              height='4.3'
              rx='.8'
              ry='.8'
              transform='translate(1.7 78.2) rotate(-45)'
            />
            <rect className='cls-3' x='93.3' y='25' width='4.3' height='1.6' rx='.8' ry='.8' transform='translate(9.7 75.1) rotate(-45)' />
          </g>
        </g>
      </g>
      <g id='Group_15477' data-name='Group 15477'>
        <circle id='Ellipse_3395' data-name='Ellipse 3395' className='cls-11' cx='92.7' cy='93.6' r='17.6' />
        <path
          id='Path_23404'
          data-name='Path 23404'
          className='cls-4'
          d='M93.1,112.2c-10.1.2-18.6-7.8-18.8-18-.2-10.1,7.8-18.6,18-18.8,10.1-.2,18.6,7.8,18.8,18h0c.2,10.1-7.8,18.5-18,18.8ZM92.4,77.1c-9.3.2-16.7,7.9-16.5,17.2.2,9.3,7.9,16.7,17.2,16.5,9.3-.2,16.7-7.9,16.5-17.2-.2-9.3-7.9-16.6-17.2-16.5Z'
        />
        <g id='Group_15476' data-name='Group 15476'>
          <rect
            id='Rectangle_10078'
            data-name='Rectangle 10078'
            className='cls-7'
            x='83.3'
            y='90.7'
            width='18.8'
            height='6.4'
            transform='translate(-39.2 95.8) rotate(-46.2)'
          />
          <rect
            id='Rectangle_10079'
            data-name='Rectangle 10079'
            className='cls-7'
            x='89.5'
            y='84.5'
            width='6.4'
            height='18.8'
            transform='translate(-39.2 95.8) rotate(-46.2)'
          />
        </g>
      </g>
    </SvgIcon>
  );
};
