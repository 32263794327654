import { MarketplaceItemAvatar } from "@/domains/marketplace/components/MarketplaceItemAvatar/MarketplaceItemAvatar";
import { PriceDisplay } from "@/domains/marketplace/components/PriceDisplay/PriceDisplay";
import { routes } from "@/domains/marketplace/routes";
import { calculateDiscountedPrice } from "@/domains/marketplace/utils";
import { useMarketplaceCartContext, useMarketplaceWishlistContext } from "@/shared/context";
import { HeartIcon } from "@/shared/icons";
import { pickLocalizedValue } from "@toolkit/i18n";
import { Box, Card, CustomIcon, IconButton, TruncateTypography, Typography } from "@toolkit/ui";
import React from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { productsQueryNode } from "../../types";
import { useStyle } from "./ProductCardStyle";

type ProductCardProps = {
  product: productsQueryNode;
  smallSizeOnly?: boolean;
};
export const ProductCard: React.FC<ProductCardProps> = props => {
  const { product, smallSizeOnly = true } = props;
  const { classes, cx } = useStyle();
  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  const { basePrice, discountedPrice } = calculateDiscountedPrice(product?.price, product?.activeDiscount);
  const handleSeeDetailsPage = () => {
    product?.id && navigate(routes.product.getRouteWithParams({ productId: product?.id }));
  };
  const { cartItems, toggleItemToCart, itemsLoadingTrackerMap, isCartLoading } = useMarketplaceCartContext();
  const { wishlistItems, toggleItem, itemsLoadingTrackerMap: wishListLoadingTracker, loadingWishlist } = useMarketplaceWishlistContext();
  const handleAddProductToCard = () => {
    product?.id &&
      toggleItemToCart?.({
        productId: product?.id,
        quantity: 1,
      });
  };
  const handleAddProductToWishlist = () => {
    product?.id &&
      toggleItem?.({
        productId: product?.id,
      });
  };

  return (
    <Card className={cx(classes.root, !smallSizeOnly && classes.root__lg)}>
      <Box className={cx(classes.cardTitleContainer)}>
        <TruncateTypography text={pickLocalizedValue(product?.vendor?.name, product?.vendor?.nameAr) || ""} />
      </Box>
      <Box
        onClick={handleSeeDetailsPage}
        className={cx(classes.cardImageContainer, !smallSizeOnly && classes.cardImageContainer__lg, classes.container__clickable)}
      >
        <MarketplaceItemAvatar
          className={classes.imge}
          imageUrl={product?.mainImageUrl}
          itemName={pickLocalizedValue(product?.name, product?.nameAr) || ""}
          marketplaceItemType='product'
        />
      </Box>
      <Box onClick={handleSeeDetailsPage} className={cx(classes.cardDetailsContainer, classes.container__clickable)}>
        <Typography className={classes.productCategory}>
          {<TruncateTypography maxWidth='100%' text={pickLocalizedValue(product?.category?.name, product?.category?.nameAr) || "\u00A0"} />}
        </Typography>
        <TruncateTypography
          maxWidth='100%'
          text={pickLocalizedValue(product?.name, product?.nameAr) || ""}
          className={classes.productName}
        />
        <TruncateTypography
          maxLines={3}
          className={cx(classes?.productShortDescription)}
          text={pickLocalizedValue(product?.shortDescription, product?.shortDescriptionAr) || ""}
        />
        <Typography className={classes.productShortDescription}></Typography>
      </Box>
      <Box className={cx(classes.cardActionContainer)}>
        <Box>
          <PriceDisplay amount={discountedPrice} />
          {(product.activeDiscount?.amount || product.activeDiscount?.percentage) && <PriceDisplay amount={basePrice} type='base-price' />}
        </Box>
        <Box className={classes.cardActionButtonsContainer}>
          <IconButton
            onClick={handleAddProductToWishlist}
            disabled={wishListLoadingTracker.product?.[product?.id] || loadingWishlist || !isAuthenticated}
            className={cx(classes.actionsButton, wishlistItems?.product?.[product?.id] && classes.filledIcon)}
          >
            <HeartIcon />
          </IconButton>
          <IconButton
            disabled={itemsLoadingTrackerMap?.["product"]?.[product?.id] || isCartLoading || !isAuthenticated}
            onClick={handleAddProductToCard}
            className={cx(classes.actionsButton, cartItems?.product?.[product?.id] && classes.filledIcon)}
          >
            <CustomIcon icon='cart' />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};
6;
