import { Typography } from "@toolkit/ui";
import { useTranslation } from "@/i18n/i18n.config";
import { CareJourneyTeams } from "../CareJourneyTeams/CareJourneyTeams";
import { CareJourneyStatistics, GuidedCareJourney, ProviderGuidedCareProgramTeamMember, Vendor } from "@/schema/types";
import { CareJourneyDetailsHeader } from "./CareJourneyDetailsHeader";
import { CareJourneyCircularProgressWithLabel } from "../CareJourneyCircularProgress/CareJourneyCircularProgressWithLabel";
import { CareJourneyActivitiesList } from "../CareJourneyActivities/CareJourneyActivitiesList";
import { FC } from "react";
import { useCareJourneyDetailsHook } from "./useCareJourneyDetailsHook";
import { useStyles } from "./CareJourneyDetailsStyle";

export const CareJourneyDetails: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { careJourney, careJourneyStatistics, journeyId, loading } = useCareJourneyDetailsHook();

  return (
    <>
      <CareJourneyDetailsHeader isLoading={loading} careJourney={careJourney as GuidedCareJourney} />
      <CareJourneyCircularProgressWithLabel careJourneyStatistics={careJourneyStatistics as CareJourneyStatistics} />
      <Typography className={classes.careTeamText}>{t("Care Team")}</Typography>
      <CareJourneyTeams
        isLoading={loading}
        teamMembers={careJourney?.providerGuidedCareHealthProgramTeam?.teamMembers as ProviderGuidedCareProgramTeamMember[]}
        provider={careJourney?.providerGuidedCareHealthProgramTeam?.provider as Vendor}
      />
      <Typography className={classes.careTeamText}>{t("Journey Activities")}</Typography>
      <CareJourneyActivitiesList guidedCareJourneyId={journeyId!} />
    </>
  );
};
