import { useTranslation } from "@toolkit/i18n";
import { Button, LocationNewIcon, makeStyles } from "@toolkit/ui";
import { FC, ReactNode } from "react";
import { MapCoordinates } from "../../types";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
type SeeLocationOnTheMapProps = {
  label?: string;
  icon?: ReactNode;
  coordinates: MapCoordinates;
};
const useStyles = makeStyles()(theme => ({
  button: {
    color: theme.palette.secondary.main,
    margin: 0,
    padding: 0,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textDecoration: "underline",
    height: "auto",
    "& path": {
      fill: theme.palette.secondary.main,
    },
    "& svg": {
      height: 20,
      width: 16,
    },
  },
  startIcon: {
    margin: 0,
    marginInlineEnd: 4,
  },
}));
export const SeeLocationOnTheMap: FC<SeeLocationOnTheMapProps> = props => {
  const { label, icon } = props;
  const { classes } = useStyles();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const isDisabled = !props.coordinates || !props.coordinates.lat || !props.coordinates.lng;
  const onClick = () => {
    navigate(routes?.mapDisplay?.getRouteWithParams({ coordinates: props.coordinates }));
  };
  return (
    <Button
      classes={{ startIcon: classes.startIcon }}
      onClick={onClick}
      className={classes.button}
      variant='text'
      disabled={isDisabled}
      startIcon={icon || <LocationNewIcon />}
    >
      {label || t("See on the Map")}
    </Button>
  );
};
