/* eslint-disable max-statements */
import { MutableRefObject, useCallback, useEffect, useState } from "react";

import { Adaptor, ExecutedResponse } from "./types";
import { formatExecutedFailure, getUserMedia } from "./utils";

type MutedParam = boolean | ((muted: boolean) => boolean);

export const useMuteAudio = ({ adaptorRef, localStream }: { adaptorRef: MutableRefObject<Adaptor | null>; localStream: MediaStream }) => {
  const [audioMuted, setAudioMuted] = useState(true);
  const audioTrack = localStream.getAudioTracks().find(t => t.readyState !== "ended");

  const muteAudio = useCallback(
    async (value: MutedParam): Promise<ExecutedResponse> => {
      try {
        if (!localStream) {
          return { success: false };
        }

        const muted = typeof value === "function" ? value(audioMuted === undefined ? true : !!audioMuted) : value;
        let [track] = localStream.getAudioTracks();
        if (!track || track.readyState === "ended") {
          const result = await getUserMedia({ audio: true });
          if (!result.success) {
            return result;
          }

          track && localStream.removeTrack(track);
          [track] = result.stream.getAudioTracks();
          localStream.addTrack(track);
          adaptorRef.current?.replaceTrack(track);
        }

        setAudioMuted(muted);
        track.enabled = !muted;
        muted && track.stop();
        return { success: true };
      } catch (error) {
        console.error("Meeting: Unable mute audio due to the error", error);
        return formatExecutedFailure(error);
      }
    },
    [localStream, audioMuted, adaptorRef]
  );

  useEffect(() => {
    if (!audioTrack) {
      return;
    }

    const onEnded = () => {
      localStream.removeTrack(audioTrack);
      setAudioMuted(true);
    };

    audioTrack.addEventListener("ended", onEnded);
    return () => {
      audioTrack.removeEventListener("ended", onEnded);
    };
  }, [adaptorRef, localStream, audioTrack]);

  return { muteAudio, audioMuted: audioMuted !== false };
};
