import { useContext, useMemo } from "react";
import { ChatContext } from "./ChatProvider";
import { toBase64 } from "@toolkit/core";

export const useChatSender = () => {
  const { senderId, accessToken } = useContext(ChatContext);

  const value = useMemo(() => (senderId ? toBase64(`User:${senderId}`) : undefined), [senderId]);

  return { senderId: value, accessToken };
};
