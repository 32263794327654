/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-statements */
import { useCallback, useState } from "react";

import { ConnectionState, ExecutedResponse } from "./types";
import { useZoom } from "./useZoom";
import { formatExecutedFailure, getUserMedia, startAudio } from "./utils";

type MutedParam = boolean | ((muted: boolean) => boolean);

export const useMuteAudio = () => {
  const { mediaStream, currentUser, connectionState } = useZoom();
  const [localStream, setLocalStream] = useState<MediaStream>();
  const { userId, muted: audioMuted } = currentUser || { muted: !localStream || localStream.getAudioTracks()[0]?.muted };

  const muteAudio = useCallback(
    async (value: MutedParam, options: any = {}): Promise<ExecutedResponse> => {
      try {
        const muted = typeof value === "function" ? value(audioMuted === undefined ? true : !!audioMuted) : value;
        if (connectionState !== ConnectionState.Connected) {
          if (muted) {
            localStream?.getTracks().forEach(t => t.stop());
            setLocalStream(undefined);
            return { success: true };
          } else {
            const result = await getUserMedia({ audio: true });
            setLocalStream(result.success ? result.stream : undefined);
            return result;
          }
        }

        if (!userId) {
          return { type: "USER_NOT_CONNECTED" };
        }
        if (!mediaStream) {
          return { type: "CLIENT_NOT_READY" };
        }

        if (!muted) {
          if (audioMuted === undefined) {
            const audioResult = await startAudio(mediaStream, { mute: muted });
            if (!audioResult.success) {
              await startAudio(mediaStream, { speakerOnly: true });
              console.error("Meeting: Unable mute audio due to the error", audioResult);
              return audioResult;
            }

            await mediaStream.startAudio({ mute: muted });
          } else {
            await mediaStream.unmuteAudio();
          }
        } else {
          await mediaStream.muteAudio();
        }

        const microphoneId = options?.constraints?.audio?.deviceId;
        if (microphoneId) {
          await mediaStream.switchMicrophone(microphoneId);
        }

        return { success: true };
      } catch (error) {
        console.error("Meeting: Unable mute audio due to the error", error);
        return formatExecutedFailure(error);
      }
    },
    [audioMuted, connectionState, localStream, mediaStream, userId]
  );

  return { muteAudio, audioMuted: audioMuted !== false, audioStream: localStream };
};
