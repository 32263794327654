import { Box, makeStyles, Typography } from "@toolkit/ui";
import { FC } from "react";

export const BadgedResult: FC<{
  badgeValue: string;
  badgeColor: string;
  badgeTextColor?: string;
  description: string;
  descriptionColor?: string;
}> = props => {
  const { classes, theme } = useBadgedResultStyle();
  const {
    badgeValue,
    badgeColor,
    badgeTextColor = "white",
    description,
    descriptionColor = props.badgeColor || theme.palette.text.primary,
  } = props;
  return (
    <Box className={classes.root}>
      <Typography
        className={classes.resultBadge}
        sx={{
          backgroundColor: badgeColor,
          borderColor: badgeColor,
          color: badgeTextColor,
        }}
      >
        {badgeValue}
      </Typography>
      <Typography
        className={classes.resultExplanation}
        sx={{
          color: descriptionColor,
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export const useBadgedResultStyle = makeStyles()(theme => {
  return {
    root: {
      direction: "ltr",
      padding: 8,
      flexDirection: "row",
      display: "flex",
      columnGap: 8,
    },
    resultBadge: {
      borderRadius: 25,
      borderWidth: 1,
      borderStyle: "solid",
      padding: 4,
      width: 70,
      textAlign: "center",
      display: "flex",
      paddingVertical: 8,
      alignSelf: "center",
      alignContent: "center",
      justifyContent: "center",
      marginVertical: "auto",
    },
    resultExplanation: {
      fontSize: theme.mixins.fonts.fontSize.md,
      display: "flex",
      alignSelf: "center",
      flex: 1,
    },
  };
});
