import { ExpandableCard } from "@/shared/components";
import { ExpandableCardProps } from "@/shared/components/ExpandableCard/ExpandableCard";
import { useInfoCardWithHeaderStyles } from "./useInfoCardWithHeader.styles";
import { FC } from "react";

export interface InfoCardWithHeaderProps extends Omit<ExpandableCardProps, "defaultCollapsed" | "hideCollapse" | "classes"> {
  header: React.ReactNode;
  classes?: Partial<ReturnType<typeof useInfoCardWithHeaderStyles>["classes"]>;
}

export const InfoCardWithHeader: FC<InfoCardWithHeaderProps> = ({ header, ...props }) => {
  const { classes: _classes } = props;
  const { classes } = useInfoCardWithHeaderStyles();
  return <ExpandableCard {...props} hideCollapse classes={{ ...classes, ..._classes }} header={header} />;
};
