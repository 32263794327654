import { makeStyles } from "@toolkit/ui";

export const useOrderHealthPackagesCardStyles = makeStyles()(theme => ({
  root: {
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    padding: 15,
    marginTop: 10,
  },
  cardHeader: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  itemCard: {
    boxShadow: "none",
    backgroundColor: "inherit",
    borderBottom: "1px solid",
    borderColor: theme.palette.divider,
    borderRadius: 0,
    paddingBottom: 10,
    ":last-child": {
      borderBottom: "none",
    },
  },
  itemAvatarContainer: {
    flex: 1,
  },
  infoContainer: {
    display: "flex",
    flex: 3,
    padding: "0",
    flexDirection: "row",
    position: "relative",
    height: "100%",
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  imageRoot: {
    border: "1px solid",
    borderColor: theme.palette.divider,
    borderRadius: "10px",
    padding: 0,
    width: "90px",
    height: "90px",
    maxHeight: "140px",
    maxWidth: "140px",
  },
  totalPrice: {
    position: "absolute",
    bottom: "30px",
    right: 0,
  },
  textColumn: {},
}));
