import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const MyPreviousJourneyIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='17.623' height='18.576' viewBox='0 0 17.623 18.576'>
      <g id='Group_15517' data-name='Group 15517' transform='translate(-68.5 -490.5)'>
        <g id='Group_15519' data-name='Group 15519' transform='translate(68.5 490.5)'>
          <g id='Group_15518' data-name='Group 15518'>
            <g id='Group_15517-2' data-name='Group 15517'>
              <g id='icons8-journey_1_' data-name='icons8-journey (1)'>
                <path
                  id='Path_23473'
                  data-name='Path 23473'
                  d='M34.881,9.263a2.382,2.382,0,0,1,0-4.763.714.714,0,0,1,0,1.429.953.953,0,1,0,.953.953V6.739a.714.714,0,1,1,1.429,0v.143a2.4,2.4,0,0,1-2.381,2.381Z'
                  transform='translate(-19.64 -4.5)'
                  fill={fill}
                />
                <path
                  id='Path_23474'
                  data-name='Path 23474'
                  d='M35.024,30.763h-.143a2.373,2.373,0,1,1,2.381-2.381.714.714,0,1,1-1.429,0,.953.953,0,1,0-.953.953h.143a.714.714,0,0,1,0,1.429Z'
                  transform='translate(-19.64 -15.76)'
                  fill={fill}
                />
                <path
                  id='Path_23475'
                  data-name='Path 23475'
                  d='M7.881,38.263a.714.714,0,1,1,0-1.429.953.953,0,1,0-.953-.953.714.714,0,0,1-1.429,0,2.373,2.373,0,1,1,2.381,2.381Z'
                  transform='translate(-5.5 -19.687)'
                  fill={fill}
                />
                <path
                  id='Path_23476'
                  data-name='Path 23476'
                  d='M7.881,16.263A2.4,2.4,0,0,1,5.5,13.881a.714.714,0,1,1,1.429,0,.953.953,0,1,0,.953-.953.714.714,0,0,1,0-1.429,2.382,2.382,0,0,1,0,4.763Z'
                  transform='translate(-5.5 -8.166)'
                  fill={fill}
                />
                <path
                  id='Path_23477'
                  data-name='Path 23477'
                  d='M21.24,36.6H13.714a.714.714,0,1,1,0-1.429H21.24a2.753,2.753,0,0,0,2.346-1.329.714.714,0,1,1,1.215.752,4.279,4.279,0,0,1-1.495,1.452A4.142,4.142,0,0,1,21.24,36.6Z'
                  transform='translate(-9.428 -19.687)'
                  fill={fill}
                />
                <path
                  id='Path_23478'
                  data-name='Path 23478'
                  d='M22.47,23.406a.714.714,0,0,1-.6-.325,2.775,2.775,0,0,0-2.306-1.247H13.61a4.127,4.127,0,0,1-3.735-2.3.714.714,0,1,1,1.278-.639,2.693,2.693,0,0,0,2.457,1.51h5.954a4.2,4.2,0,0,1,3.5,1.9.715.715,0,0,1-.6,1.1Z'
                  transform='translate(-7.752 -11.832)'
                  fill={fill}
                />
                <path
                  id='Path_23479'
                  data-name='Path 23479'
                  d='M10.914,11.048A.715.715,0,0,1,10.3,9.966,4.111,4.111,0,0,1,13.82,8H21.3a.714.714,0,0,1,0,1.429H13.82A2.675,2.675,0,0,0,11.527,10.7.714.714,0,0,1,10.914,11.048Z'
                  transform='translate(-7.961 -6.333)'
                  fill={fill}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
